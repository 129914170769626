import { HttpClient } from 'aurelia-fetch-client';
import { ValidationRules } from 'aurelia-validation';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, IMenuGroup, IFile, FileFlow, EnumerationTypeService, EnumerationType } from 'digiwall-lib';
import { autoinject, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import Cropper from 'cropperjs';

@autoinject
export class MyEntreprise extends EntityDetailViewModelBase<Merlin.Web.Model.ApplicationParameter> {
  public documentTitle: string = this.i18n.tr("applicationparameters.myEntreprise");
  public ressourceName: string = Constants.EntityTypeNames.ApplicationParameter;
  public get ribbonHeaderText() {
    return this.i18n.tr("applicationparameters.myEntreprise")
  }

  public Constants: any = Constants;
  private companyTypeService: EnumerationTypeService;
  private languageService: EnumerationTypeService;
  public addresses: Array<Merlin.Web.Model.Address> = [];

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient) {
    super(router, logger);
    this.companyTypeService = new EnumerationTypeService(Constants.EnumerationTypes.CompanyType);
    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);
    super.initialize(new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter));
  }

  public async activate(params: any) {
    let id: number = params.param1;
    await super.activate(params);
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id, 'address.locality', 'address.country', 'defaultLanguage', 'companyType');
    this.controller.addObject(this.entity);
    if (this.entity.address != null) {
      this.addresses.push(this.entity.address);
    }
  }



  public addCallbackAddress(address: Merlin.Web.Model.Address) {
    this.addresses.push(address);
  }

  public async removeCallbackAddress(address: Merlin.Web.Model.Address) {
    this.addresses.splice(0);
    this.entity = await this.service.getEntityById(this.entity.id, 'address', 'defaultLanguage', 'companyType');
    if (this.entity.address != null) {
      this.addresses.push(this.entity.address);
    }
  }

  public async beforeSave() {
    if (this.addresses.length >= 1) {
      this.entity.address = this.addresses[0];
    }
    return true
  }
}
