import { bindable } from "aurelia-templating";

import { Merlin } from 'generated';
import * as Constants from '../../constants';
import { ServiceBase } from "digiwall-lib";
import { bindingMode } from "aurelia-framework";

export class ProjectInternalTeam {

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  public entity: Merlin.Web.Model.ProjectUsers;

  private merlinUserService: ServiceBase<Merlin.Web.Model.MerlinUser>;

  constructor() {
    this.merlinUserService = new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser);
  }

}
