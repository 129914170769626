import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';


@autoinject
export class RealizationSummary {
  projectId: any;
  constructor(public router: Router,) {

  }

  public async activate(params: any) {
    this.projectId = params.projectId;

  }

}
