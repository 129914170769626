import { RowNode } from "ag-grid-community";
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import IPriceOfferLineCommentApiService from "services/price-offer-line-comment-api-service";

@autoinject
export class AgCellCommentDialog {

  public colField: string;
  public rowNode: RowNode;
  public api: IPriceOfferLineCommentApiService;

  constructor(private dialogController: DialogController) {

  }

  async activate(params) {
    this.colField = params.colField;
    this.rowNode = params.rowNode;
    this.api = params.api;
  }

  private async save() {
    await this.dialogController.close(true);
  }

  private async close() {
    await this.dialogController.close(false);
  }

  public cancel() {
  }

  private edit() {
  }

  private delete() {
  }
}
