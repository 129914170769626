import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, IMenuGroup } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject, BindingEngine, computedFrom, viewResources } from 'aurelia-framework';
import * as Constants from '../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';


@autoinject
export class MarketTypeDetail extends EntityDetailViewModelBase<Merlin.Web.Model.MarketType> {
  private marketTypeAliasService: ServiceBase<Merlin.Web.Model.MarketTypeAlias>;
  private menuItems: Array<IMenuGroup>;
  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);

    this.marketTypeAliasService = new ServiceBase<Merlin.Web.Model.MarketTypeAlias>(Constants.EntityTypeNames.MarketTypeAlias);
    super.initialize(new ServiceBase<Merlin.Web.Model.MarketType>(Constants.EntityTypeNames.MarketType));

  }

  public async activate(params) {
    let id = params.param1;
    await super.activate(params);
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id, 'marketTypeAliases');
    this.controller.addObject(this.entity);
    this.setMenuItems();
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.save"),
            icon: "digi-save-3-line",
            handler: () => {
              this.save();
            },
            disabled: () => !this.canSave
          },
          {
            label: this.i18n.tr("menu.cancel"),
            icon: "digi-close-circle-line",
            handler: () => {
              this.cancel();
            }
          },
          {
            label: this.i18n.tr("menu.back"),
            icon: "digi-arrow-go-back-line",
            handler: () => {
              this.navigateBack();
            }
          }
        ]
      }
    ];
  }
  public documentTitle: string = this.i18n.tr("markettype.addMarketTypeAlias");
  public get ribbonHeaderText() {
    return this.i18n.tr("markettype.markettype")
  }
  public ressourceName = Constants.EntityTypeNames.ThirdParty;

}
