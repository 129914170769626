import { autoinject } from 'aurelia-framework';

@autoinject()
export class EditableCustomAttribute {
    constructor(private element: Element) { }

    valueChanged(newValue, oldValue) {                
        let localValue = newValue == 'true' || newValue === true;
        this.setFieldsAvailability(!localValue);
    }

    setFieldsAvailability(value) {
        this.setElementDisabledAttribute("input", value);
        this.setElementDisabledAttribute("input[type=radio]", value);
        this.setElementDisabledAttribute("input[type=checkbox]", value);
        this.setElementDisabledAttribute("select", value);
        this.setElementDisabledAttribute("textarea", value);
        this.setElementDisabledAttribute("button", value);
        this.setElementDisabledAttribute(".btn", value);
        this.setElementDisabledAttribute(".navigation-text", value);
    }
    setElementDisabledAttribute(elementSelector, value) {
        $(this.element).find(elementSelector).each(function (index, elt) {
            var $elt = $(elt);
            $elt.attr('disabled', value);
            var localValue = value;
        });
    }
}

