.theme-light {
  .floating-filter {
    &__header {
      display: contents;
      font-weight: 600;
      font-size: 14px;
      padding: 5px;
      font-family: "Inter", sans-serif;
      > p {
        padding: 15px 10px 0px 10px;
        margin: 0;
        display: block;
      }
    }

    &__body {
      outline: none;
      padding: 15px 10px 15px 10px;
      &__boolean {
        display: grid;
      }

      &__user {
        &__radio {
          padding: 10px 0;
        }
        &__list {
          &__elem:first-child {
            margin-top: 0;
          }
        }
      }

      &__date {
        &__radio {
          display: grid;
          padding-top: 10px;
        }
        &__check {
          display: grid;
          padding: 10px 0;
        }
        &__nullable {
          display: grid;
        }
        &__picker {
          width: 200px;
          .ui-field__label {
            display: none;
          }
          ui-range-picker {
            width: 100%;
            .ui-field__wrapper > div > .ui-dropdown__link {
              padding: 0 10px;
            }
          }
          ui-date-picker {
            width: 150px;
          }
        }
      }

      &__enum {
        &__list {
          &__elem {
            margin: 0;
            padding-top: 10px;
            > ui-checkbox {
              margin-top: 0;
            }
          }
        }
      }

      &__many-to-many {
        &__list {
          &__elem {
            margin: 5px 0 5px 0;
            > ui-checkbox {
              margin-top: 0;
            }
          }
        }
      }

      &__number {
        display: grid;
        grid-template-areas: "from to" "empty empty";
        grid-template-columns: 50% 50%;
        width: 300px;
        row-gap: 10px;
        padding-bottom: 10px;
        > .ui-field {
          display: flex;
          flex-direction: column;
          &:first-child {
            margin-right: 5px;
            grid-area: from;
          }
          &:nth-child(2) {
            grid-area: to;
          }
          > ui-input {
            min-height: 2.6rem;
          }
          > .ui-field__label {
            //padding: 10px;
            top: -6px;
            color: $blue-mid;
          }

          > .ui-field__label::after {
            content: none;
          }
        }
        > :nth-child(3) {
          grid-area: empty;
        }
      }

      &__one-to-many {
        &__list {
          &__elem {
            margin: 5px 0 5px 0;
            > ui-checkbox {
              margin-top: 0;
            }
          }
        }
      }

      &__user {
        &__list {
          &__elem {
            margin: 5px 0 5px 0;
            > ui-checkbox {
              margin-top: 0;
            }
          }
        }
      }

      &__search {
        ui-field.ui-field {
          grid-template-columns: auto;

          label {
            display: none;
          }
        }

        ui-input {
          border-radius: 50px;
          padding: 6px 30px 6px 10px;
          height: 36px !important;
          border: solid 1px $purple-light;
          &:hover {
            border: solid 1px $purple-dark;
          }
          > .ui-input__addon {
            padding: 0;
            > ui-icon {
              padding-right: 10px;
            }
          }
          > input {
            padding: 0;
            height: auto;
            &::placeholder {
              color: $gray-light !important;
            }
          }
        }

        .ui-btn__wrapper {
          margin: 5% 0;
          > .ui-btn__inner {
            box-shadow: none;
            border: solid 1px $gray-lighter;
            > .ui-btn {
              box-shadow: none;
              height: 25px;
              &:hover {
                color: $blue-mid;
              }
            }
          }
        }
      }
    }

    &__footer {
      background-color: $background-footer;
      > .ui-btn__wrapper {
        width: 100%;
        height: 100%;
        margin: 0;
        height: 48px;
        > .ui-btn__inner {
          box-shadow: none;
          background-color: $background-footer !important;
          border-radius: 0;
          > .ui-btn {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            color: $gray-mid;
            padding: 0px 15px;
            &:hover {
              color: $black-100;
            }
            > .ui-btn__icon {
              padding: 0;
            }
            > .ui-btn__label {
              padding-left: 7px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .dropdown-content.floating-filter {
    &__container {
      border-radius: $radius;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
  }

  .ag-theme-material {
    .ag-menu {
      background-color: white;
      border-radius: 12px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15) !important;
      .ag-menu-header {
        display: none;
      }
      .ag-menu-body {
        padding: 0;
        .ag-filter {
          margin: 0;
          > div > .floating-filter__container > .floating-filter__body > .floating-filter__body__number {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
/*
.theme-light .ui-btn[data-active="true"], .theme-light .ui-btn[data-open="true"], .theme-light .ui-btn:active{
  background-color: $purple-lightest!important;
  color: $blue-mid!important;
}
*/
/*
.theme-light .ui-btn__group.ui-theme--secondary .ui-btn__inner:hover,
.theme-light .ui-btn__group.ui-theme--secondary .ui-btn__inner:focus-within,
.theme-light .ui-btn__wrapper.ui-theme--secondary .ui-btn__inner:hover,
.theme-light .ui-btn__wrapper.ui-theme--secondary .ui-btn__inner:focus-within {
  background-color: transparent;
  color: $blue-mid;
}
*/
.theme-light .floating-filter__body__search .ui-btn__wrapper {
  display: flex;
  .ui-btn__inner {
    display: flex;
    width: 100%;
    > .ui-btn {
      color: $gray-light;
      width: 100%;
      &:hover {
        background-color: $purple-lightest;
        color: $blue-mid;
      }
    }
  }
}
