.theme-light {
  .view-aside {
    display: grid;
    grid-template-columns: auto 400px;
    grid-template-areas: "body aside";
    margin: 0px;
    padding: 0px !important;
    overflow: hidden; // Sert pour voir les 3 petits point des listes

    .body-content {
      grid-area: body;
      //margin-left: -40px !important;
      margin-top: 0px !important;
      > ui-panel.ui-panel {
        > .ui-panel__header {
          border: none;
          padding-bottom: 0px;
        }
        padding-left: 0px;
        > .ui-panel__body {
          > ui-button {
            margin-left: 40px;
          }
        }
      }
    }

    .aside-content {
      overflow: auto;
      grid-area: aside;
      border-left: 1px solid $gray-lighter;
      margin-top: 0px !important;
      > ui-panel.ui-panel {
        padding: 0px;
        > .ui-panel__header {
          border: none;
          padding-bottom: 0px;
        }
        > .ui-panel__body {
          padding: 0;
          > .row {
            border-bottom: 1px solid $gray-lighter;
            margin: 5px 0px !important;
            padding: 25px 0px;
          }
        }
      }
    }
  }

  .metering-expand {
    .view-aside {
      grid-template-columns: auto;
      grid-template-areas: "body";
    }
  }
}
