import { HttpClient } from 'aurelia-fetch-client';
import { I18N } from 'aurelia-i18n';
import { autoinject } from "aurelia-framework";
import { Box } from "digiwall-lib";
import * as Constants from '../constants';
import { Merlin } from '../generated';
import { RowNode } from 'ag-grid-community';

export default interface IPriceOfferLineCommentApiService {
  createOrUpdate(lineId: number, commentDTO: Merlin.Web.Model.PriceOfferLineCommentDTO): Promise<boolean>;
  delete(commentId: string): Promise<boolean>;
  get(line: any, fieldId: string): Promise<Array<Merlin.Web.Model.PriceOfferLineCommentDTO>>;
}

@autoinject
export class PriceOfferLineCommentApiService implements IPriceOfferLineCommentApiService {

  constructor(private box: Box, private i18n: I18N, private httpClient: HttpClient) { }

  public async get(line: any, fieldId: string): Promise<Array<Merlin.Web.Model.PriceOfferLineCommentDTO>> {
    let fields = [];
    let lineId = (line as any).id;
    fields.push(fieldId);
    let requestUri = Constants.Application.WorkQuotationController.GetComment.format(lineId) + "?fields=" + JSON.stringify(fields);
    let response = await this.httpClient.get(requestUri);
    let json: Merlin.Web.Model.PriceOfferLineCommentDTO[] = null;
    if (response.ok && response.status == 200) {
      json = await response.json();
    }
    return json;
  }

  public async createOrUpdate(lineId: number, commentDTO: Merlin.Web.Model.PriceOfferLineCommentDTO): Promise<boolean> {
    let requestUri = Constants.Application.WorkQuotationController.CreateOrUpdateComment.format(lineId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(commentDTO));
    return Promise.resolve(response.ok);
  }

  public async delete(commentId: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      let requestUri = Constants.Application.WorkQuotationController.DeleteComment.format(commentId);
      let response = await this.httpClient.delete(requestUri);
      resolve(response.ok);
    });
  }
}

@autoinject
export class SellingInvoiceLineCommentApiService implements IPriceOfferLineCommentApiService {

  constructor(private box: Box, private i18n: I18N, private httpClient: HttpClient) { }

  public async get(line: any, fieldId: string): Promise<Array<Merlin.Web.Model.PriceOfferLineCommentDTO>> {
    let fields = [];
    let lineId = (line as any).id;
    fields.push(fieldId);
    let requestUri = Constants.Application.InvoiceController.GetComment.format(lineId) + "?fields=" + JSON.stringify(fields);
    let response = await this.httpClient.get(requestUri);
    let json: Merlin.Web.Model.PriceOfferLineCommentDTO[] = null;
    if (response.ok && response.status == 200) {
      json = await response.json();
    }
    return json;
  }

  public async createOrUpdate(lineId: number, commentDTO: Merlin.Web.Model.PriceOfferLineCommentDTO): Promise<boolean> {
    let requestUri = Constants.Application.InvoiceController.CreateOrUpdateComment.format(lineId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(commentDTO));
    return Promise.resolve(response.ok);
  }

  public async delete(commentId: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      let requestUri = Constants.Application.InvoiceController.DeleteComment.format(commentId);
      let response = await this.httpClient.delete(requestUri);
      resolve(response.ok);
    });
  }
}
