import { autoinject, bindable, customElement } from "aurelia-framework";
import { IFile } from "digiwall-lib";
import { NotificationLanguageDTO } from "email-sender/email-sender";
import { Merlin } from "generated";
import * as Constants from './../../../../../constants';

@autoinject
@customElement("amendment-step-3")
export class AmendmentStep3 {
  @bindable
  public notifLang: Map<number, NotificationLanguageDTO> = new Map<number, NotificationLanguageDTO>();

  public Constants = Constants;
  @bindable
  public receiverIds: Array<number> = [];

  @bindable
  public companyIds: Array<number> = [];

  @bindable
  public files: Array<IFile> = [];

  @bindable
  public projectId;

  @bindable
  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest;

  private emailContext;
  constructor() {

  }

  bind() {
    this.emailContext = Constants.EmailContextId.SupplierAmendment;
    this.projectId = parseInt(this.projectId.toString()); //Make sure projectId is a number
  }
}
