import { UIInternal } from "digiwall-lib";
import * as Constants from '../../constants';
import { AddItems } from './add-items/add-items';
import { MeteringOverlayComp } from 'resources/metering/metering-overlay-comp';
import { MeteringFullWidthCellRenderer } from 'resources/renderer/metering-full-width-cell-renderer';


export class VersionOfferOverlayComp extends MeteringOverlayComp {
  setupContentGui(): string {
    let html: string;
    if (this.hasFilterApply || this.priceOfferLinesGrid.versionHasLines) {
      html = `<template><div>${this.i18n.tr("importmetering.noSearchResult")}</div></template>`;
    } else {
      html = `
      <template>
        <div>
          <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addPost" icon="digi-flashlight-line" click.delegate="addPost()"></ui-button>
          <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addItems" icon="digi-database-2-line" click.delegate="addItems()"></ui-button>
          <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addChapter" icon="digi-list-check" click.delegate="addChapter()"></ui-button>
          <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addComment" icon="digi-chat-4-line" click.delegate="addComment()"></ui-button>
        </div>
      </template>`;
    }
    return html
  }

  async addPost() {
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    let ids = await this.dataLineApi.create(parseInt(this.priceOfferLinesGrid.versionId), 0, Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Up);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, true);
  }

  async addChapter() {
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    let ids = await this.dataLineApi.create(parseInt(this.priceOfferLinesGrid.versionId), 0, Constants.PriceOfferLineCategory.Chapter, Constants.PriceOfferLineMoveAction.Up);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, true);
  }

  async addComment() {
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    let ids = await this.dataLineApi.create(parseInt(this.priceOfferLinesGrid.versionId), 0, Constants.PriceOfferLineCategory.Comment, Constants.PriceOfferLineMoveAction.Up);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, true);
  }

  async addItems() {
    await this.box.showCustomDialog(AddItems, 0, null, {
      model: {
        action: Constants.PriceOfferLineMoveAction.Up,
        workQuotationVersionId: this.priceOfferLinesGrid.versionId,
        api: this.dataLineApi
      },
      size: "xl"
    }).whenClosed((result) => {
      if (result.output) {
        UIInternal.broadcast(LINE_HAS_BEEN_ADDED);
        this.priceOfferLinesGrid.refreshServerSideRows(result.output, true);
      }
    });
  }
}
export const LINE_HAS_BEEN_ADDED: string = "LINE_HAS_BEEN_ADDED";
export class FullWidthCellRenderer extends MeteringFullWidthCellRenderer {
  setupContentGui(): string {
    const template =
      `<ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addPost" icon="digi-flashlight-line" click.delegate="addPost()"></ui-button>
      <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addItems" icon="digi-database-2-line" click.delegate="addItems()"></ui-button>
      <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addChapter" icon="digi-list-check" click.delegate="addChapter()"></ui-button>
      <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addComment" icon="digi-chat-4-line" click.delegate="addComment()"></ui-button>`;

    return template;
  }

  async addPost() {
    this.listTree.gridOptions.api.showLoadingOverlay();
    let lastChapterId = await this.getLastLineChapterId();
    let ids = await this.apiService.create(this.versionId, lastChapterId ?? this.getLastRowIdLevelOne(), Constants.PriceOfferLineCategory.Data, lastChapterId == null ? Constants.PriceOfferLineMoveAction.Down : Constants.PriceOfferLineMoveAction.Into);
    this.listTree.gridOptions.api.hideOverlay();

    this.listTree.refreshServerSideRows(ids, true);
    this.afterAdd(lastChapterId);
  }

  async addChapter() {
    this.listTree.gridOptions.api.showLoadingOverlay();
    let ids = await this.apiService.create(this.versionId, this.getLastRowIdLevelOne(), Constants.PriceOfferLineCategory.Chapter, Constants.PriceOfferLineMoveAction.Down);
    this.listTree.gridOptions.api.hideOverlay();

    this.listTree.refreshServerSideRows(ids, true);
  }

  async addComment() {
    this.listTree.gridOptions.api.showLoadingOverlay();
    let lastChapterId = await this.getLastLineChapterId();
    let ids = await this.apiService.create(this.versionId, lastChapterId ?? this.getLastRowIdLevelOne(), Constants.PriceOfferLineCategory.Comment, lastChapterId == null ? Constants.PriceOfferLineMoveAction.Down : Constants.PriceOfferLineMoveAction.Into);
    this.listTree.gridOptions.api.hideOverlay();

    this.listTree.refreshServerSideRows(ids, true);
    this.afterAdd(lastChapterId);
  }

  async addItems() {
    let lastChapterId = await this.getLastLineChapterId();
    await this.box.showCustomDialog(AddItems, lastChapterId ?? this.getLastRowIdLevelOne(), null, {
      model: {
        action: lastChapterId == null ? Constants.PriceOfferLineMoveAction.Down : Constants.PriceOfferLineMoveAction.Into,
        workQuotationVersionId: this.versionId,
        api: this.apiService
      },
      size: "xl"
    }).whenClosed((result) => {
      if (result.output) {
        UIInternal.broadcast(LINE_HAS_BEEN_ADDED);
        this.listTree.refreshServerSideRows(result.output, true);
        this.afterAdd(lastChapterId);
      }
    });
  }

  private getLastRowIdLevelOne() {
    return this.listTree.gridOptions.api.getRenderedNodes().filter(x => x.data.parentId == null && x.data.uniqueId != 0).last().data.id;
  }

  private async getLastLineChapterId() {
    return await this.apiService.getLastChapterId(this.versionId);
  }

  private afterAdd(lastChapterId: number) {
    setTimeout(() => {
      if (lastChapterId != null) {
        let targetNode = this.listTree.gridOptions.api.getRowNode(lastChapterId.toString());
        targetNode.setExpanded(true);
      }
    }, 200)
  }
}
