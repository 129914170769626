import * as Constants from '../../../constants';
import { autoinject, bindable, customElement } from "aurelia-framework";
import { Box, DateUtils, ServiceBase } from "digiwall-lib";
import { UIDrop } from "digiwall-lib/dist/typings/ui/shared/ui-drop";
import { Merlin } from "generated";
import { CSS_CLASS_AG_CELL } from "../ag-cell-comment/ag-cell-comment-service";
import { Router } from 'aurelia-router';
import { ContactPersonDetail } from 'contact-persons/contact-person-detail';
import { ItemContractList } from 'items/item-contract-list/item-contract-list';

@autoinject
@customElement("ag-cell-item-tooltip")
export class AgCellItemTooltip {
  private item: Merlin.Web.Model.Item;

  @bindable
  public itemId: number;
  @bindable
  public quantity: number;

  protected tooltipDropEl: UIDrop;
  agCell: any;

  pa: number;
  datePA: Date;

  constructor(private viewEl: Element, private router: Router, private dateUtils: DateUtils, private box: Box) {
  }

  async attached() {
    if (this.viewEl == null) return;
    this.agCell = this.viewEl.closest(`.${CSS_CLASS_AG_CELL}`);
    if (this.agCell == null) {
      this.agCell = this.viewEl.parentElement;
    }

  }

  detached() { }

  private navigateToItem() {
    window.open('items/' + this.itemId, "_blank")
  }

  private async toggleBox(event: MouseEvent = null) {
    if (this.itemId != null) {
      this.item = await new ServiceBase<Merlin.Web.Model.Item>(Constants.EntityTypeNames.Item).getEntityById(this.itemId, "itemType", "itemFamilies.itemFamily", 'itemPrices.itemPriceFromDates');
      if (this.quantity != null) {
        if (this.item.itemTypeId == Constants.ItemType.Supply) {
          let currentPrice = this.item.itemPrices.filter(x => x.fromQuantity < this.quantity).sortBy("fromQuantity", false);
          if (currentPrice.length > 0) {
            let priceFromDate = (currentPrice[0] as Merlin.Web.Model.ItemPrice).itemPriceFromDates.filter(x => this.dateUtils.isBefore(x.fromDate, new Date())).sortBy('fromDate', false);
            if (priceFromDate.length > 0) {
              this.pa = (priceFromDate[0] as Merlin.Web.Model.ItemPriceFromDate).unitBuyingPrice;
              this.datePA = (priceFromDate[0] as Merlin.Web.Model.ItemPriceFromDate).fromDate;
            }
          }
        }
      }
    }
    this.tooltipDropEl.toggleDrop();
    if (typeof event?.stopImmediatePropagation == "function") event.stopImmediatePropagation();
  }

  private async close() {
    await this.toggleBox();
  }

  private async showPriceHistory() {
    await this.close();
    await this.box.showCustomDialog(ItemContractList, 0, null, {
      model: {
        itemId: this.itemId
      },
      size: "xl"
    })
  }
}
