@import "./../css/scss/variables";

.email-sender {
  .horizontal-margin {
    width: 70%;
    margin: 0 auto;
  }
  // width: 70%;
  //margin: 0 auto;
  .row {
    ui-field {
      grid-template-columns: 100px auto;
      gap: 15px;
      .ui-field__label {
        //position: inherit;
        //left: 0;
        padding: 0;
        height: 100% !important;
        align-items: center;
        display: flex;
        justify-content: end;
        > span {
          color: $gray-mid;
        }
      }
      > ui-select > .select2 > .selection > .select2-selection {
        .select2-selection__rendered {
          .ui-tag {
            gap: 10px;
            border: solid 1px $gray-lighter;
            box-shadow: none;
            > .ui-tag__label {
              padding: 0;
              color: $black-100;
              font-size: 14px;
            }
            > .ui-tag__icon {
              padding: 3px;
              > div {
                height: 24px;
                .user-avatar {
                  margin: 0;
                  width: 24px;
                  height: 24px;
                }
              }
            }
            > .ui-tag__close {
              padding: 0;
              padding-right: 8px;
              .digi-cross {
                font-size: 10px;
                color: $blue-darkest;
              }
            }
          }
        }
      }
    }
    > .email-sender__message {
      .ui-field {
        align-items: flex-start;
        .ui-field__label {
          margin-top: 16px;
          align-items: start;
        }
      }
      .info-readonly {
        .tox-sidebar-wrap {
          &:hover {
            border-bottom: 1px solid $gray-lighter !important;
          }
        }
      }
    }
    > div {
      > .email-sender__file {
        margin-right: 0.75rem;
        float: right;
      }
      > .email-sender__file__readonly {
        grid-template-columns: 3fr 1fr;
        margin-right: 0.75rem;
        float: right;
      }
      > .ui-field {
        padding-bottom: 15px;
        .ui-field__label {
          //color: $gray-mid;
          > span {
            //color: $gray-mid;
          }
        }
        .fake-input {
          border-bottom: solid 1px $gray-lighter;
          padding-bottom: 8px;
          //margin-left: 5px;
          margin-left: 0;
          display: flex;
          height: 2.6rem;
          flex: 1rem;
          .email-sender__person-tag {
            width: fit-content;
            margin-left: 0.5rem;
          }
        }
        .ui-select {
          > .select2 {
            > .selection {
              display: flex;
              justify-content: space-between;
              align-items: center;
              > span {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
        .ui-select,
        .ui-input {
          border: none;
          border-bottom: solid 1px $gray-lighter !important;
          border-radius: 0;
          &:hover {
            border-bottom: solid 1px $gray-mid !important;
          }
          &:focus {
            border-color: $blue-mid !important;
          }
        }
      }

      > .info-readonly {
        //display: block;
        padding: 0;
        //margin-left: 46px;
        //margin-bottom: 15px;
        //border-bottom: solid 1px $gray-very-light;
        margin-bottom: 0;
        display: grid !important;
        margin-left: 50px;
        align-items: center;
        grid-template-columns: none !important;
        gap: 0;
        &.object-border {
          // border-bottom: solid 1px $gray-lighter !important;
          .ui-input--readonly {
            border-bottom: solid 1px $gray-lighter !important;
            &:hover {
              border-bottom: solid 1px $gray-lighter !important;
            }
          }
        }
        > .container-readonly {
          display: flex;
        }
        > .ui-field__label,
        .ui-input--readonly {
          padding: 0;
        }
        > .ui-field__label {
          justify-content: start;
          > span {
            color: $blue-mid;
          }
        }
        .ui-input--readonly {
          background-color: transparent;
          //border-bottom: solid 1px $gray-lighter !important;
          border: none !important;
          &:hover {
            border: none !important;
          }
          input {
            font-weight: 600;
            color: $black-100 !important;
          }
        }
      }
      > .email-sender__readonly-to-from {
        // display: flex;
        display: grid;
        grid-template-columns: 40px auto;
        gap: 10px;
        img.user-avatar {
          margin-right: 10px;
          width: 36px;
          height: 36px;
          border-radius: 36px;
        }
        > avatar {
          margin-right: 10px;
          height: 36px;
          > img {
            width: 36px;
            height: 36px;
          }
        }
        > .email-sender__readonly-to-from__name {
          margin-top: 5px;
          border-bottom: solid 1px $gray-lighter;
          padding-bottom: 10px;
          width: 100%;
          margin-bottom: 5px;
          > span {
            font-size: 17px;
          }
          > .email-sender__at {
            display: flex;
            padding-top: 5px;
            > span,
            div {
              font-size: 13px;
              color: $gray-mid;
            }
            > span {
              margin-right: 5px;
            }
          }
        }
      }
    }
    ui-select.email-sender__to-selector.ui-select.ui-input--valid.ui-select--multiple.ui-input--disabled {
      border: none !important;
      border-bottom: solid 1px #dddddd !important;
      border-radius: 0 !important;
      span > ul > li {
        grid-template-columns: 1fr;
        > ui-button {
          display: none;
        }
      }
    }
    ui-select.email-sender__to-selector.ui-select {
      span.select2.select2-container.select2-container--default {
        > span.selection {
          > span.select2-selection.select2-selection--multiple {
            > ul.select2-selection__rendered {
              > li.select2-selection__choice {
                display: flex;
                flex-direction: row-reverse;
                background-color: transparent !important;
                border: solid 1px $gray-lighter;
                border-radius: 30px !important;
                gap: 10px;
                padding: 0 5px;
                > avatar {
                  height: 24px;
                  img {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__content__person-select {
    display: flex;
    align-items: center;
    img.user-avatar {
      margin-right: 10px;
      width: 30px;
      height: 30px;
      border-radius: 30px;
    }
    > avatar {
      height: 30px;
      img {
        width: 30px;
        height: 30px;
      }
    }
    > div {
      display: grid;
      margin-left: 8px;
      .email-sender__person-select {
        &__name,
        &__email {
          line-height: initial;
        }
        &__name {
          font-weight: 600;
        }
      }
    }
  }
  &__files {
    display: flex;
    justify-content: flex-end;
  }
  &__file,
  &__file__readonly {
    background-color: $gray-lightest;
    border-radius: 8px;
    padding: 10px;
    max-width: 350px;
    display: grid;
    grid-template-columns: 3fr 1fr 25px;
    align-items: center;
    margin: 0px 5px;
    > ui-button > .ui-btn__inner > .ui-btn > .ui-btn__icon > .digi-cross {
      font-size: 12px;
    }
    &__readonly {
      margin-left: 57px;
    }
    > .email-sender__file__name {
      color: $blue-mid;
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
    }
    > span {
      font-weight: 600;
      margin: 0 5px;
      max-width: 100px;
    }
    > ui-button > .ui-btn__inner > .ui-btn {
      .ui-btn__icon {
        padding: 0;
      }
      .ui-icon {
        font-size: 14px;
      }
    }
  }
  &__file__readonly {
    grid-template-columns: 3fr 1fr;
  }
  &__files-box {
    z-index: 1500;
  }
  &__person-tag {
    display: flex;
    align-items: center;
    border: solid 1px $gray-lighter;
    border-radius: 60px;
    padding: 2px;
    width: fit-content;
    img.user-avatar {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      border-radius: 24px;
    }
    > avatar {
      height: 24px;
      > img {
        width: 24px;
        height: 24px;
      }
    }
    > span {
      font-size: 16px;
      padding-left: 8px;
      color: $black-100;
    }
    > span:last-child {
      padding-left: 4px;
      padding-right: 15px;
    }
    > .ui-btn__wrapper {
      margin-left: 15px;
      .ui-btn__inner {
        box-shadow: none !important;
        > .ui-btn {
          background-color: transparent;
          box-shadow: none;
          &:hover {
            background-color: transparent;
          }
          > .ui-btn__icon {
            padding: 0;
            padding-right: 5px;
            > .ui-icon {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  &__document-button {
    display: flex;
    flex-direction: row-reverse;
  }

  &__person-to {
    padding: 0px 2px;
    gap: 2px;
    display: flex;
  }
  div.row > div.email-sender__template-with-language {
    display: flex;
    > ui-field.ui-field {
      grid-template-columns: 100px auto;
      > label {
        margin-right: 10%;
      }
      > div {
        display: flex;
      }
      .container-readonly {
        ui-select > .select2 > .selection > .select2-selection--clearable {
          align-items: center;
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;
          height: inherit;
          > .select2-selection__clear {
            top: 3px;
          }
        }
      }
    }
    > .ui-btn__wrapper {
      max-height: 2.8rem;
      margin-left: 10px;
    }
    > .info-readonly {
      ui-button {
        display: none;
      }
    }
  }
}

.theme-light .tribute-container {
  overflow: auto;

  ul {
    margin-top: 0;

    li {
      border: none;
    }
  }
}

/*ux-dialog {
  ux-dialog-body
    > compose
    > .ui-page
    > .ui-page__body
    > ui-content
    > .row
    > .email-sender
    > div
    > .row
    > div
    > .info-readonly {
    .ui-input {
      padding: 0;
    }
    .ui-field__label {
      color: $gray-mid;
      > span {
        color: $gray-mid;
      }
    }
  }
  ux-dialog-footer {
    text-align: right;
  }
}*/
