import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from "aurelia-framework";
import { BaseApiService } from "./base-api-service";
import * as Constants from '../constants';
import { Box, GlobalLoaderService } from "digiwall-lib";
import { I18N } from 'aurelia-i18n';

@autoinject
export class ReconciliationApiService extends BaseApiService {
  constructor(private httpClient: HttpClient, private gls: GlobalLoaderService, public i18n: I18N, public box: Box) {
    super(i18n, box);
  }


  async linkTransactionToInvoice(transactionId: number, invoiceId: number) {
    this.gls.allow();
    let result = await this.httpClient.post(Constants.Application.ReconciliationController.LinkInvoice.format(transactionId.toString(), invoiceId.toString()))
    if (result.ok) {
      return true;
    }
    return false;
  }

  async linkTransactionToInvoices(transactionId: number, invoiceId: Array<number>) {
    this.gls.allow();
    let result = await this.httpClient.post(Constants.Application.ReconciliationController.LinkInvoices.format(transactionId.toString()), JSON.stringify(invoiceId))
    if (result.ok) {
      return true;
    }
    return false;
  }

  async unlinkTransactionToInvoice(reconciliationId: number) {
    this.gls.allow();
    let result = await this.httpClient.delete(Constants.Application.ReconciliationController.UnlinkInvoice.format(reconciliationId.toString()))
    if (result.ok) {
      return true;
    }
    return false;
  }

}
