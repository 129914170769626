import { PriceOfferLineCategory, RefToDisplay } from './../../../constants';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { Merlin } from 'generated';
import { autoinject, BindingEngine, Container, Disposable, getChangeRecords, observable, TaskQueue } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { CustomLogger, EnumerationType, EnumerationTypeService, FieldType, ServiceBase, GlobalLoaderService, UIInternal, IMenuItem, IMenuItems } from "digiwall-lib";
import * as Constants from '../../../constants';
import * as toastr from 'toastr';
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';
import { BuyingComparisonService, IComparisonService, OfferComparisonService, ThirdPartyComparison } from 'services/offer-comparison-api-service';
import { I18N } from 'aurelia-i18n';
import { ColDef, ColGroupDef, RowNode, ServerSideTransaction } from 'ag-grid-community';
import CommentRenderer from 'resources/renderer/comment-renderer';
import { AgCellCommentService } from 'resources/elements/ag-cell-comment/ag-cell-comment-service';
import { ComparaisonPriceOfferLineCommentApiService } from '../../../services/comparison-price-offer-line-comment-api-service';
import { DataFormat } from 'select2';
import { IdsToCompareSingleton } from 'resources/utilities/IdsToCompareSingleton';
import { ProjectApiService } from 'services/project-api-service';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { ContractSupplierOfferApiService } from 'services/contract-supplier-offer-api-service';
import { ThirdPartyContractDetail } from 'projects/buying/third-party-contract/third-party-contract-detail';

export const COMPARAISON_REFRESH_HEADER = "COMPARAISON_REFRESH_HEADER";

@autoinject
export class OfferComparison {

  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "originalProposedQuantity", visibility: false },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: true },
      { colId: "marginCoefficient", visibility: true },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: true },
      { colId: "supplierId", visibility: true },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: false },
    ];

  public getGridMenuItems = (params) => [
    {
      group: "1",
      hiddenLabel: true,
      items: [
        {
          label: this.i18n.tr("metering.unselectPrice"),
          icon: "digi-forbid-line",
          disabled: () => {
            // if (params.data.priceOfferLineCategoryId == PriceOfferLineCategory.Data) {
            return params.data?.supplierId == null;
            // }
            // return !params.data.isSelected;
          },
          handler: async () => {
            await this.unSelectPrice(params)
          }
        },
      ]
    },

  ];

  public canEditCell() { return false; };
  public triggerExpand() {
    this.screenExpand = !this.screenExpand;
  }
  public screenExpand: boolean = false;

  public requestSupplierOfferService: ServiceBase<Merlin.Web.Model.RequestSupplierOffer>;
  public supplierOfferService: ServiceBase<Merlin.Web.Model.BuyingSupplierOffer>;
  public activityService: EnumerationTypeService;

  @observable
  public requestSupplierOfferSelected: DataFormat | number = null;

  public supplierSelecteds: Array<number>;
  public allPossibleSupplier: Array<ThirdPartyComparison>;

  public activitySelecteds: Array<number>;
  public allPossibleActivity: Array<EnumerationType>;

  public workQuotationVersionId: number;
  public projectId: number;

  public displayGrid: boolean = false

  @observable
  public grid: PriceOfferLinesGrid;

  private fromParams: boolean = false;

  private disposables: Array<Disposable> = [];

  private offerComparisonService: IComparisonService;

  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number;
  private showPU: boolean = false;

  private buyingContractService: ServiceBase<Merlin.Web.Model.BuyingContract>

  constructor(public logger: CustomLogger, public router: Router, private i18n: I18N, private taskQueue: TaskQueue, private commentService: ComparaisonPriceOfferLineCommentApiService, private bindingEngine: BindingEngine, private globalLoaderService: GlobalLoaderService, private idsToCompareSingleton: IdsToCompareSingleton, private projectApiService: ProjectApiService, private contractSupplierOfferVersionApiService: ContractSupplierOfferApiService) {
    // this.requestSupplierOfferService = new ServiceBase<Merlin.Web.Model.RequestSupplierOffer>(Constants.EntityTypeNames.RequestSupplierOffer);
    this.activityService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.collectionObserver(this.activitySelecteds).subscribe(changeRecords => {
        if (this.activitySelecteds.length > 0) {
          this.supplierSelecteds.splice(0);
        }
      })
    );
    UIInternal.subscribe(COMPARAISON_REFRESH_HEADER, () => {
      if (this.grid?.gridOptions.api.getDisplayedRowCount() != 0) {
        this.grid?.gridOptions.api.refreshHeader();
      }
    });
  }

  private async getThirdParties(): Promise<Array<ThirdPartyComparison>> {
    return await this.offerComparisonService.getThirdParty(this.workQuotationVersionId, this.projectId, (typeof this.requestSupplierOfferSelected === "object" ? parseInt(this.requestSupplierOfferSelected?.id.toString()) : parseInt(this.requestSupplierOfferSelected.toString())))
  }

  public async activate(params: any) {
    this.getShowPU();
    this.workQuotationVersionId = params.workQuotationVersionId;
    this.projectId = params.projectId;
    if (this.workQuotationVersionId != null) {
      this.offerComparisonService = Container.instance.get(OfferComparisonService);
      this.requestSupplierOfferService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer>(Constants.EntityTypeNames.WorkQuotationVersionRequestSupplierOffer);
    }
    else if (this.projectId != null) {
      this.offerComparisonService = Container.instance.get(BuyingComparisonService);
      this.requestSupplierOfferService = new ServiceBase<Merlin.Web.Model.BuyingRequestSupplierOffer>(Constants.EntityTypeNames.BuyingRequestSupplierOffer);
      this.buyingContractService = new ServiceBase<Merlin.Web.Model.BuyingContract>(Constants.EntityTypeNames.BuyingContract);
      this.supplierOfferService = new ServiceBase<Merlin.Web.Model.BuyingSupplierOffer>(Constants.EntityTypeNames.BuyingSupplierOffer);
    }

    this.requestSupplierOfferService.gridDataSource.queryParameters = { workQuotationVersionId: this.workQuotationVersionId, hasOneOrMoreOffer: true, projectId: this.projectId };

    this.supplierSelecteds = new Array();
    if (this.allPossibleSupplier == null) {
      this.allPossibleSupplier = new Array<ThirdPartyComparison>();
    }


    this.allPossibleActivity = await this.activityService.getEntities(null, null, { category: Constants.EnumerationTypes.ActivityCode, workQuotationVersionId: this.workQuotationVersionId, projectId: this.projectId })
    this.activitySelecteds = new Array();

    if (params.requestOfferId != null) {
      let request = await this.requestSupplierOfferService.firstEntity(new Predicate("id", FilterQueryOp.Equals, params.requestOfferId));
      this.fromParams = true;
      this.requestSupplierOfferSelected = { text: request.requestOfferName, id: params.requestOfferId };
    } else {
      let supplier = await this.getThirdParties();
      this.allPossibleSupplier.push(...supplier);
    }

    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = precisionParameter.nbDecimalForQuantityDisplay;
  }

  public async requestSupplierOfferSelectedChanged(newVal, oldVal) {
    if (newVal != oldVal) {
      if (this.allPossibleSupplier == null) {
        this.allPossibleSupplier = new Array<ThirdPartyComparison>();
      }
      if (this.supplierSelecteds == null) {
        this.supplierSelecteds = new Array();
      }
      this.supplierSelecteds.splice(0);
      this.allPossibleSupplier.splice(0);

      if (newVal != oldVal && newVal != null) {
        if (typeof newVal === "object") {
          newVal = parseInt(newVal.id);
        }

        this.allPossibleSupplier.push(...await this.getThirdParties());
        if (!this.fromParams) {
          this.allPossibleSupplier.forEach(supplier => {
            this.supplierSelecteds.push(supplier.id);
          });
        }
        else {
          let selectedIds = this.idsToCompareSingleton.getIds();
          this.allPossibleSupplier
            .filter(supplier => selectedIds.find(x => x == supplier.id))
            .forEach(supplier => {
              this.supplierSelecteds.push(supplier.id);
            });
        }
      } else {
        this.allPossibleSupplier.push(...await this.getThirdParties());
      }

      if (this.fromParams && this.supplierSelecteds.length >= 2) {
        this.displayComparison()
      }
    }
  }

  private firstDisplay = true;

  public displayComparison() {
    if (this.activitySelecteds.length == 0 && this.supplierSelecteds.length < 1) {
      toastr.error(this.i18n.tr("offercomparison.supplierError"));
      return false;
    }
    if (this.requestSupplierOfferSelected != null) {
      this.columnVisible.find(x => x.colId === "originalProposedQuantity").visibility = true;
    }
    else {
      this.columnVisible.find(x => x.colId === "originalProposedQuantity").visibility = false;
    }

    this.displayGrid = true;

    if (!this.firstDisplay) {
      this.removeSuppliersColumns();
      this.grid.gridOptions.api.setColumnDefs(this.grid.getDataGridColumns());
    }

    this.taskQueue.queueTask(async () => {
      if (this.requestSupplierOfferSelected != null) {
        if (typeof this.requestSupplierOfferSelected === "object") {
          //Id can be either string or number, making sure that he is a number
          this.offerComparisonService.requestSupplierOfferId = parseInt(this.requestSupplierOfferSelected.id.toString());
        }
        else {
          this.offerComparisonService.requestSupplierOfferId = this.requestSupplierOfferSelected as unknown as number;
        }
      } else {
        this.offerComparisonService.requestSupplierOfferId = null;
      }

      if (this.supplierSelecteds.length > 0) {
        this.offerComparisonService.supplierIds = this.supplierSelecteds;
      }
      if (this.activitySelecteds.length > 0) {
        this.offerComparisonService.activitiesIds = this.activitySelecteds
      }

      if (this.grid.listTreeData != null) {
        this.grid.listTreeData.onDataLoaded = async () => {
          this.createColumns();
          this.getMeteringTotalPrices();
        };
      }


      if (!this.firstDisplay) {
        this.grid.refreshServerSideRows(null, true);
        this.getMeteringTotalPrices();
      }

      this.firstDisplay = false;
    });
  }

  getShowPU() {
    let localStorage = window.localStorage.getItem('comparisonSetting');
    let json = JSON.parse(localStorage);
    if (json != null) {
      this.showPU = json.showPU;
    }
  }

  saveShowPU() {
    let comparisonSetting = {
      showPU: this.showPU
    };
    window.localStorage.setItem('comparisonSetting', JSON.stringify(comparisonSetting));
  }
  async getMeteringTotalPrices() {
    let requestSupplierOfferSelectedId: number;
    if ((this.requestSupplierOfferSelected as DataFormat).id) {
      requestSupplierOfferSelectedId = parseInt((this.requestSupplierOfferSelected as DataFormat).id?.toString());
    } else {
      requestSupplierOfferSelectedId = (this.requestSupplierOfferSelected as number);
    }
    let data = await this.offerComparisonService.getMeteringTotalPricesData(this.getVersionOrProjectId(), this.activitySelecteds, this.supplierSelecteds, requestSupplierOfferSelectedId);
    this.grid.gridOptions.api!.setPinnedBottomRowData([data]);
  }

  private removeSuppliersColumns(): void {
    let colDefs: Array<ColDef<any>> = this.grid.gridOptions.api.getColumnDefs();
    for (let supName of this.offerComparisonService.suppliers) {
      let cdi = colDefs.findIndex(cd => cd.colId == supName);
      if (cdi != null) {
        colDefs.splice(cdi, 1);
      }
    }
    this.grid.gridOptions.api.setColumnDefs(colDefs);
  }

  private createColumns(): void {
    let colDefs: Array<ColDef<any> | ColGroupDef<any>> = this.grid.gridOptions.api.getColumnDefs();
    this.offerComparisonService.suppliers.sort((a, b) => {
      return a.key == 0 ? 1 : b.key == 0 ? -1 : 0;
    })
      .forEach(supName => {
        if (colDefs.some(cd => cd.headerName == supName.key)) return;
        if (this.showPU && (supName.key != "0" || supName.key != 0)) {
          this.createColumnWithGroup(colDefs, supName);
        } else {
          this.createColumnWithoutGroup(colDefs, supName);
        }
      }
      );

    this.grid.gridOptions.api.setColumnDefs(colDefs);
  }

  private getCellCssClass(params) {
    let cssClass = "";
    let hasContract = params?.data?.supplierPrices[params.colDef.field]?.hasContract ?? false;
    if (hasContract) cssClass += "has-contract";

    if (params.colDef.field == "0") {
      if (params.data.priceOfferLineCategoryId == PriceOfferLineCategory.Chapter) {
        return cssClass + " supplier-cell-total-chapiter";
      }
      return cssClass + " supplier-cell-total-data";
    }
    return cssClass + " metering-cell-description";
  }

  private createColumnWithoutGroup(colDefs: (ColDef<any> | ColGroupDef<any>)[], supName: any) {
    colDefs.push({
      headerName: supName.key,
      colId: supName.key,
      headerComponent: "merlinHtmlHeaderRenderer",
      headerComponentParams: {
        getHtml: (currentThis) => {
          return this.getHeaderHtml(currentThis);
        },
        checkedChanged: async (event, params, removeLink: boolean) => {
          await this.headerSelectedChanged(params, removeLink);
        },
        display: supName.value
      },
      headerClass: (params) => {
        if ((params.colDef as any).colId == "0") {
          return "supplier-cell-total";
        }
        return '';
      },
      field: supName.key,
      type: FieldType.Number,
      suppressMenu: true,
      sortable: false,
      suppressMovable: true,
      cellClass: (params) => {
        return this.getCellCssClass(params);
      },
      cellRenderer: 'customHtmlRendererEditor',
      cellRendererParams: {
        getHtml: (currentThis) => {
          return this.getTotalCellHtml(currentThis, supName);
        },
        supplierField: supName,
        canComment: true,
        checkedChanged: async (event, params, removeLink: boolean) => {
          this.grid.gridOptions.api.showLoadingOverlay();
          await this.selectedDataChanged(params, removeLink);
          this.grid.gridOptions.api.hideOverlay();
        }
      },
      editable: false,
      cellRendererSelector: (params) => {
        if (params.colDef.field != "0") {
          return {
            "component": CommentRenderer,
            "params": {
              ...params,
              "baseRenderer": params.colDef.cellRenderer,
              "components": this.grid.gridOptions.components,
              "commentService": new AgCellCommentService(["supplierPrices", params.colDef.field, "additionalCommentMultiline"], ",", this.grid, this.commentService, true, this.isCellCommented)
            }
          };
        }
      },
      showRowGroup: this.i18n.tr("offercomparison.suppliers"),
    } as ColDef);
  }

  private createColumnWithGroup(colDefs: (ColDef<any> | ColGroupDef<any>)[], supName: any) {
    colDefs.push({
      headerName: supName.key,
      headerGroupComponent: "merlinHtmlHeaderRenderer",
      headerGroupComponentParams: {
        getHtml: (currentThis) => {
          return this.getHeaderHtml(currentThis);
        },
        checkedChanged: async (event, params, removeLink: boolean) => {
          await this.headerSelectedChanged(params, removeLink);
        },
        display: supName.value
      },
      children: [
        {
          colId: supName.key,
          headerName: this.i18n.tr('offercomparison.unitPrice'),
          field: supName.key,
          cellRenderer: 'customHtmlRendererEditor',
          cellClass: (params) => {
            return this.getCellCssClass(params);
          },
          cellRendererParams: {
            getHtml: (currentThis) => {
              UIInternal.broadcast(COMPARAISON_REFRESH_HEADER);
              let value: number;
              let supplierField = currentThis.params.colDef.field;
              let data = currentThis.params?.data?.supplierPrices?.[supplierField];
              let result = `<div class="supplier-cell-value-unit-price`;
              let hasContract: boolean = currentThis.params?.data?.supplierPrices[supplierField]?.hasContract ?? false;
              if (data != null) {
                if (data.unitPrice != null) {
                  value = (!isNaN(data.unitPrice) ? new Intl.NumberFormat(this.grid.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.unitPrice) : data.unitPrice);
                }
              }

              result += `"><div>`;
              if (currentThis.params.data.id != 0) {
                let quantity = data?.quantityTypeModified;
                if (quantity != null) {
                  result += `<span ui-tooltip="value:${quantity}"><ui-icon icon="digi-alert-line"></ui-icon></span>`;
                }
                if (data?.authorNewQuantity) {
                  let tooltip = this.i18n.tr('offercomparison.newQuantity');
                  result += `<span ui-tooltip="value:${tooltip}"><ui-icon icon="digi-group-line"></ui-icon></span>`;
                }
              }
              result += ` </div><div class="`;
              if (data?.isMinValue && currentThis.params.colDef.field != "0") {
                result += " supplier-min-value";
              }
              result += `">`;
              if (value != null) {
                result += `${value}`;
              }

              result += ` </div>
                          </div>`;
              return result;
            },
            supplierField: supName,
            canComment: true,
            checkedChanged: async (event, params, removeLink: boolean) => {
              this.grid.gridOptions.api.showLoadingOverlay();
              await this.selectedDataChanged(params, removeLink);
              this.grid.gridOptions.api.hideOverlay();
            }
          },
        },
        {
          colId: supName.key,
          headerName: this.i18n.tr('offercomparison.totalPrice'),
          field: supName.key,
          type: FieldType.Number,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          cellClass: (params) => {
            return this.getCellCssClass(params);
          },
          cellRenderer: 'customHtmlRendererEditor',
          cellRendererParams: {
            getHtml: (currentThis) => {
              return this.getTotalCellHtml(currentThis, supName);
            },
            supplierField: supName,
            canComment: true,
            checkedChanged: async (event, params, removeLink: boolean) => {
              this.grid.gridOptions.api.showLoadingOverlay();
              await this.selectedDataChanged(params, removeLink);
              this.grid.gridOptions.api.hideOverlay();
            }
          },
          editable: false,
          cellRendererSelector: (params) => {
            if (params.colDef.field != "0") {
              return {
                "component": CommentRenderer,
                "params": {
                  ...params,
                  "baseRenderer": params.colDef.cellRenderer,
                  "components": this.grid.gridOptions.components,
                  "commentService": new AgCellCommentService(["supplierPrices", params.colDef.field, "additionalCommentMultiline"], ",", this.grid, this.commentService, true, this.isCellCommented)
                }
              };
            }
          },
          showRowGroup: this.i18n.tr("offercomparison.suppliers"),
        } as ColDef
      ]
    });
  }

  private getHeaderHtml(currentThis: any) {
    let id = currentThis.params.displayName;
    let name = currentThis.params.display;
    let hasContract: boolean = false;
    let nodeofColumn: Array<number> = [];
    currentThis.params.api.forEachNode(x => {
      if (!hasContract) {
        hasContract = x?.data?.supplierPrices[currentThis.params.displayName]?.hasContract ?? false;
      }
      if (x?.data?.lineLevel == 1) {
        let selectionState = x?.data?.supplierPrices[currentThis.params.displayName].selectionState;
        if (selectionState != null) {
          nodeofColumn.push(selectionState);
        }
      }
    });

    let none = nodeofColumn.every(x => x == Constants.NodeSelectionState.None);
    let all = nodeofColumn.every(x => x == Constants.NodeSelectionState.All);

    let result = `<div class="ag-header supplier-header-cell-value">
                  <div>${name}</div>`;
    if (!hasContract) {
      if (none) {
        result +=
          `<div class="supplier-cell-value-button" if.bind="params.displayName != 0">
                        <ui-button class="supplier-cell-value-button" icon="digi-checkbox-blank-line" type="tool" click.delegate="params.checkedChanged(null, params, false)">
                        </ui-button>
                      </div>`;
      } else if (all) {
        result +=
          `<div class="supplier-cell-value-button" if.bind="params.displayName != 0">
                        <ui-button class="supplier-cell-value-button" icon="digi-check-full" type="tool" click.delegate="params.checkedChanged(null, params, true)">
                        </ui-button>
                      </div>`;
      } else {
        result +=
          `<div class="supplier-cell-value-button" if.bind="params.displayName != 0">
                        <ui-button class="supplier-cell-value-button" icon="digi-checkbox-square" type="tool" click.delegate="params.checkedChanged(null, params, false)">
                        </ui-button>
                      </div>`;
      }
    }
    result += '</div>';
    return result;
  }

  private getTotalCellHtml(currentThis: any, supName: any) {
    UIInternal.broadcast(COMPARAISON_REFRESH_HEADER);
    let value: number;
    let supplierField = currentThis.params.colDef.field;
    let data = currentThis.params?.data?.supplierPrices?.[supplierField];
    let result = `<div class="supplier-cell"><div class="supplier-cell-value">`;
    let hasContract: boolean = currentThis.params?.data?.supplierPrices[supplierField]?.hasContract ?? false;

    if (data != null) {
      if (data.totalPrice != null) {
        value = (!isNaN(data.totalPrice) ? new Intl.NumberFormat(this.grid.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.totalPrice) : data.totalPrice);
      }
    }

    //result += `"><div>`;
    result += `<div class="supplier-cell-icon">`;
    if (currentThis.params.data.id != 0) {
      let quantity = data?.quantityTypeModified;
      if (quantity != null) {
        result += `<span ui-tooltip="value:${quantity}"><ui-icon icon="digi-alert-line"></ui-icon> </span>`;
      }
      if (data?.authorNewQuantity) {
        let tooltip = this.i18n.tr('offercomparison.newQuantity');
        result += `<span ui-tooltip="value:${tooltip}"><ui-icon icon="digi-group-line"></ui-icon> </span>`;
      }
    }
    result += "</div>";
    if (value != null) {
      result += ` <div class="`;
      if (data?.isMinValue && currentThis.params.colDef.field != "0") {
        result += " supplier-min-value";
      }
      if (currentThis.params.colDef.field == "0") {
        result += " supplier-total";
      }
      result += `">`;
      result += `${value}</div></div>`;
      let name = currentThis.params?.data.id;
      if (currentThis.params.colDef.field != "0" && currentThis.params.data.id != 0 && !hasContract) {
        if (currentThis.params?.data.priceOfferLineCategoryId == PriceOfferLineCategory.Chapter || (currentThis.params?.data.priceOfferLineCategoryId == PriceOfferLineCategory.Data && (currentThis.params?.data.hasChildrenDetailed || currentThis.params?.data.hasChildrenSubItem))) {
          switch (currentThis.params?.data.supplierPrices[supName.key].selectionState) {
            case Constants.NodeSelectionState.All: {
              result += `<span class="supplier-cell-value-button">
                          <ui-button icon="digi-check-full" type="tool" click.delegate="params.checkedChanged(null, params, true)">
                          </ui-button>
                            </span>`;
              break;
            }
            case Constants.NodeSelectionState.Partial: {
              result += `<span class="supplier-cell-value-button">
                          <ui-button icon="digi-checkbox-square" type="tool" click.delegate="params.checkedChanged(null, params, false)">
                          </ui-button>
                            </span>`;
              break;
            }
            case Constants.NodeSelectionState.None:
            default: {
              result += `<span class="supplier-cell-value-button">
                          <ui-button icon="digi-checkbox-blank-line" type="tool" click.delegate="params.checkedChanged(null, params, false)">
                          </ui-button>
                            </span>`;
              break;
            }
          }
        } else if (currentThis.params.data.disabledComparisonLine) {
          result += `<span class="supplier-cell-value-radio-disabled"><ui-icon icon="digi-round"></ui-icon></span>`;
        }
        else {
          result += `<div class="supplier-cell-value-radio">
                      <ui-radio on-change.call="params.checkedChanged($event, params, ${data.selected})" checked='${data.selected ? data.supplierOfferLineId : null}' model="${data.supplierOfferLineId}" name="${name}"></ui-radio>
                    </div></div>`;
        }
      }
    }
    else {
      result += `</div></div>`;
    }

    return result;
  }

  private isCellCommented(data: any, colField: string): boolean {
    let tempProperty = null;
    if (data.id == 0) {
      return null;
    }
    ["supplierPrices", colField, "additionalCommentMultiline"].forEach(element => {
      if (tempProperty == null) {
        tempProperty = data;
      }
      if (tempProperty?.hasOwnProperty(element)) {
        tempProperty = tempProperty[element]
      }

    });
    return tempProperty != null;
  }

  private getDBId(id): number {
    let lineId: string = id;

    if (id.toString().includes("_")) {
      lineId = id.toString().split("_")[0].split('-')[1];
    }
    return parseInt(lineId);
  }

  private getVersionOrProjectId() {
    return this.workQuotationVersionId != null ? this.workQuotationVersionId : this.projectId
  }

  selectDataInProcess: boolean = false;

  private async selectedDataChanged(params, removeLink: boolean) {
    if (!this.selectDataInProcess) {
      this.selectDataInProcess = true;
      if (!removeLink) {
        let currentSelected = params.supplierField;
        let currentLineData = params?.data;
        let currentSupplierData = currentLineData?.supplierPrices?.[currentSelected.key];
        this.globalLoaderService.allow(true, 1);
        let ids: Array<number> = await this.offerComparisonService.createLinkBetweenPOLAndSOL(this.getVersionOrProjectId(), currentSupplierData.supplierOfferLineId, params.data.proposedQuantity, null);
        await this.refreshGrid(ids);

        this.getMeteringTotalPrices();
      }
      else {
        await this.unSelectPrice(params);
      }
      this.selectDataInProcess = false;
    }

  }
  getSiblingNodes(id: number) {
    let siblingNodes = new Array<RowNode<any>>();
    this.grid.gridOptions.api.forEachNode(x => {
      let nodeId = x.data.uniqueId;
      if (typeof (nodeId) == 'string') {
        let dbId = this.getDBId(nodeId);
        if (id == dbId) {
          siblingNodes.push(x);
        }
      }
    });
    return siblingNodes;
  }

  private removeNode(selected, transactions: ServerSideTransaction[]) {
    let parentRoute = selected.parent?.getRoute();
    let tr;
    if ((tr = transactions.find(t => t.route == parentRoute)) != null) {
      tr.remove.push(selected.data);
    } else {
      transactions.push({ route: parentRoute, remove: [selected.data] });
    }
  }

  private async headerSelectedChanged(params, removeLink: boolean) {
    this.globalLoaderService.allow();
    let ids: Array<number>;
    if (!removeLink) {
      ids = await this.offerComparisonService.createLinkBetweenPOLAndSOL(this.getVersionOrProjectId(), null, null, params.displayName);
    }
    else {
      ids = await this.offerComparisonService.unSelectPriceOfSupplier(this.getVersionOrProjectId());
    }
    await this.refreshGrid(ids);
    this.grid.gridOptions.api.refreshHeader();
    this.getMeteringTotalPrices();
  }

  public canComment(coldDef: ColDef) {
    return false;
  }

  private gridChanged() {
    if (this.grid != null) {
      this.disposables.push(this.bindingEngine.propertyObserver(this.grid, "listTreeData").subscribe((newVal, oldVal) => {

        this.grid.listTreeData.onDataLoaded = async () => {
          this.createColumns();
          this.getMeteringTotalPrices();
        };
      }))
    }

  }

  deactivate() {
    this.disposables.forEach(x => x.dispose());
  }

  async unSelectPrice(params) {
    let result = await this.offerComparisonService.unSelectPrice(this.getVersionOrProjectId(), params.data.id);
    if (result.length > 0) {

      await this.refreshGrid(result, true);
      this.getMeteringTotalPrices();
    }
    else {
      this.logger.LogError(this.i18n.tr("offercomparison.nothingToUnselectError"), null, null, true);
    }

  }

  private async refreshGrid(ids: Array<number>, unSelect: boolean = false) {
    let toRefresh = [];
    for (const id of ids) {
      let siblingNodes = this.getSiblingNodes(id);
      if (siblingNodes?.length > 1) {
        let currentRoute = siblingNodes[0].parent.getRoute();
        this.globalLoaderService.allow(true, 1);
        const newLines = await this.offerComparisonService.fetch(this.getVersionOrProjectId(), [id], false, this.grid.listTreeData.refToDisplay);

        const agGridTransaction: ServerSideTransaction = {};
        agGridTransaction.update = [];
        agGridTransaction.remove = [];
        agGridTransaction.route = currentRoute;


        siblingNodes.forEach(siblingNode => {
          const uniqueId = siblingNode.data.uniqueId;
          const updatedLine = newLines.find(x => x.uniqueId == uniqueId);
          if (updatedLine != null) {
            agGridTransaction.update.push(updatedLine);
          } else {
            agGridTransaction.remove.push(siblingNode.data);
          }

        });
        const transactionResult = this.grid.gridOptions.api.applyServerSideTransaction(agGridTransaction);
        this.grid.gridOptions.api.redrawRows({
          "rowNodes": transactionResult.update
        });
        this.grid.gridOptions.api.flashCells({ rowNodes: transactionResult.update });

      }
      else {
        if (unSelect) {
          let node = this.grid.listTreeData.gridOptions.api.getRowNode(id.toString());
          if (node != null) {
            node.data.selected = null;
          }
        }
        toRefresh.push(id);
      }
    }

    if (toRefresh?.length > 0) {
      this.grid.refreshVisibleNodes(toRefresh);
    }
  }

  internalCustomSettingMenuItems(): IMenuItems[] {
    return [
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {

            label: this.i18n.tr("offercomparison.showPU"),
            hidden: () => this.showPU,
            handler: () => {
              this.showPU = true;
              this.saveShowPU();
              this.displayComparison();
            }
          },
          {
            label: this.i18n.tr("offercomparison.hidePU"),
            hidden: () => !this.showPU,
            handler: () => {
              this.showPU = false;
              this.saveShowPU();
              this.displayComparison();
            }
          }

        ]
      },
    ];
  }

  private async createContracts() {
    let p1 = new Predicate("buyingRequestToSupplier.buyingRequestSupplierOfferId", FilterQueryOp.Equals, this.requestSupplierOfferSelected)
    let supplierOfferDisplayed = await this.supplierOfferService.getEntities(p1);
    let p2 = new Predicate("projectId", FilterQueryOp.Equals, this.projectId);
    p2 = p2.and(new Predicate("statusId", FilterQueryOp.Equals, Constants.ThirdPartyContractStatusId.Draft));
    let draftContracts = await this.buyingContractService.getEntities(p2);
    let draftContractToCreate = draftContracts.filter(x => supplierOfferDisplayed.some(y => y.id == x.supplierOfferId));
    if (draftContractToCreate.length == 0) {
      this.logger.LogError(this.i18n.tr("offercomparison.noContractToCreate"), null, null, true);
      return
    }
    for (const draft of draftContractToCreate) {
      await this.contractSupplierOfferVersionApiService.save(this.projectId, draft.supplierOfferId, await ThirdPartyContractDetail.getSupplierTypeId(draft.supplierOfferId));
    }
    toastr.success(this.i18n.tr("offercomparison.successContractCreation"))
  }
}
