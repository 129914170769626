import { EventAggregator } from 'aurelia-event-aggregator';
import { RouterConfiguration, Router } from 'aurelia-router';
import { Box, ServiceBase, UIInternal } from 'digiwall-lib';
import * as Constants from '../../constants';
import { Merlin } from 'generated';
import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { activationStrategy } from 'aurelia-router';
import { IndexHelper } from 'resources/elements/index-helper';

@autoinject
export class PreparationIndex {
  router: Router;
  entityId: number = null;
  priceOfferId: number;
  projectService: ServiceBase<Merlin.Web.Model.Project>;
  refreshMenu = true;

  get baseUrl() {
    return this.router.parent.currentInstruction?.fragment;
  };

  constructor(private i18n: I18N) {
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  attached() {
    UIInternal.subscribe('router:navigation:complete', () => {
      IndexHelper.setFocusRoute(this.router);
    });
  }
  async activate(params) {
    this.entityId = params.projectId;
    if (this.entityId > 0) {
      let ent = await this.projectService.getEntityById(this.entityId);
      this._ribbonHeaderText = ent.projectName + ' (' + this.entityId + ')';
    } else {
      this._ribbonHeaderText = this.i18n.tr('project.newProject');
    }

    this.router.routes.forEach(r => r.disabled = this.entityId < 0);
  }

  private _ribbonHeaderText: string;
  @computedFrom("_ribbonHeaderText")
  public get ribbonHeaderText(): string {
    return this._ribbonHeaderText;
  }

  get currentRoute() {
    return this.router?.currentInstruction?.config.route;
  }

  configureRouter(config: RouterConfiguration, router: Router, params, routeConfig, currentInstruction): void {
    this.router = router;

    config.map([
      {
        route: "",
        redirect: 'summary'
      },
      {
        route: "summary",
        moduleId: "projects/preparation/preparation-summary", //TODO à remplacer
        name: "summary",
        title: this.i18n.tr('preparationindex.summary'),
        nav: true
      },
      {
        route: "module-tasks",
        moduleId: "module-tasks/module-task",
        name: "module-tasks",
        title: this.i18n.tr('preparationindex.tasks'),
        nav: true,
        activationStrategy: activationStrategy.replace,
        params: {
          projectPhaseId: Constants.ProjectPhaseId.Preparation
        }
      },
      {
        route: "tasks",
        moduleId: "module-tasks/module-task",
        name: "task",
        title: this.i18n.tr('preparationindex.tasks'),
        nav: false,
        activationStrategy: activationStrategy.replace,
        params: {
          projectPhaseId: Constants.ProjectPhaseId.Preparation
        }
      },
      {
        route: "document",
        moduleId: "documents/document-list", //TODO faire fonctionner
        name: "document",
        title: this.i18n.tr('preparationindex.documents'),
        nav: true,
        params: {
          projectPhaseId: Constants.ProjectPhaseId.Preparation
        }
      }
    ]);
  }

  public goToFirstDevis() {
    this.router.navigate(`quote`);
  }

}
