import { I18N } from 'aurelia-i18n';
import { ServiceBase, UITreeNode } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../../generated";
import * as Constants from '../../constants';
import { DialogController } from 'aurelia-dialog';

@autoinject
export class ItemFamilyTreeView {
  private entity: Merlin.Web.Model.Item;
  private title = this.i18n.tr('item.itemFamilies');

  private articleFamilies: Array<Merlin.Web.Model.ItemFamily>;
  private articleFamiliesTree: UITreeNode[] = [];
  private selectedTreeValues: string[] = [];

  private articleFamilyService: ServiceBase<Merlin.Web.Model.ItemFamily>;
  private articleArticleFamilyService: ServiceBase<Merlin.Web.Model.ItemItemFamily>;
  private itemService: ServiceBase<Merlin.Web.Model.Item>;

  constructor(private dialogController: DialogController, private i18n: I18N) {
    this.articleFamilyService = new ServiceBase<Merlin.Web.Model.ItemFamily>(Constants.EntityTypeNames.ItemFamily);
    this.articleArticleFamilyService = new ServiceBase<Merlin.Web.Model.ItemItemFamily>(Constants.EntityTypeNames.ItemItemFamily);
    this.itemService = new ServiceBase<Merlin.Web.Model.Item>(Constants.EntityTypeNames.Item);
  }

  public async activate(params: any) {
    this.entity = params.entity;
    await this.refreshTreeValues();
    this.refreshSelectedTreeValues();
  }

  public async refreshTreeValues() {
    this.articleFamilies = null;
    this.articleFamilies = await this.articleFamilyService.getEntities(null, null, null);
    this.articleFamiliesTree = [];
    this.articleFamilies.filter(x => x.mainFamilyId == null).forEach(articleFamily => {
      let children: Array<UITreeNode> = null;
      if (articleFamily.subFamilies != null && articleFamily.subFamilies.length > 0) {
        children = this.getChildren(articleFamily);
      }
      let articleFamilyTreeItem: UITreeNode = new UITreeNode({
        id: articleFamily.id.toString(),
        label: articleFamily.familyName._translation,
        expanded: false,
        leaf: (children == null),
        children: children
      });
      this.articleFamiliesTree.push(articleFamilyTreeItem);
    });
  }

  public refreshSelectedTreeValues(): void {
    if (this.entity.itemFamilies != null) {
      this.entity.itemFamilies.forEach(articleFamily => {
        this.selectedTreeValues.push(articleFamily.itemFamilyId.toString());
      });
    }
  }

  public getChildren(parentArticleFamily: Merlin.Web.Model.ItemFamily): Array<UITreeNode> {
    let articleFamilies: Array<UITreeNode> = new Array<UITreeNode>();
    parentArticleFamily.subFamilies.forEach(articleFamily => {
      let children: Array<UITreeNode> = null;
      if (articleFamily.subFamilies != null && articleFamily.subFamilies.length > 0) {
        children = this.getChildren(articleFamily)
      }
      let articleFamilyTreeItem: UITreeNode = new UITreeNode({
        id: articleFamily.id.toString(),
        label: articleFamily.familyName._translation,
        expanded: false,
        leaf: (children == null),
        children: children
      });
      articleFamilies.push(articleFamilyTreeItem);
    });
    return articleFamilies;
  }

  public async ok() {
    if (this.selectedTreeValues != null) {
      let parsedSelectedTreeValues: Array<number> = this.selectedTreeValues.map(x => parseInt(x));
      for (let index = 0; index < parsedSelectedTreeValues.length; index++) {
        let articleFamilyId: number = parsedSelectedTreeValues[index];
        if (!this.entity.itemFamilies.some(x => x.itemFamilyId == articleFamilyId)) {
          let articleArticleFamily = await this.articleArticleFamilyService.createEntity();
          articleArticleFamily.itemId = this.entity.id;
          articleArticleFamily.itemFamilyId = articleFamilyId;
        }
      }
      let articleArticleFamiliesToDelete = this.entity.itemFamilies.filter(x => !parsedSelectedTreeValues.some(y => y == x.itemFamilyId));
      let toDeleteLength: number = articleArticleFamiliesToDelete.length;
      while (toDeleteLength > 0) {
        let articleArticleFamilyToDelete = articleArticleFamiliesToDelete[toDeleteLength - 1];
        if (articleArticleFamilyToDelete.entityAspect.entityState.isAdded()) {
          articleArticleFamilyToDelete.entityAspect.setDetached();
        }
        else {
          articleArticleFamilyToDelete.entityAspect.setDeleted();
        }
        toDeleteLength--;
      }
    }
    this.dialogController.ok();
  }

  public async close() {
    await this.dialogController.close(true);
  }
} 
