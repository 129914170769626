@import "../../css/scss/variables";
.ui-horizontal-menu {
  background-color: $white;
  &__subGroup,
  &__child {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 34px;
    height: inherit;
    border-bottom: 1px solid $gray-very-light;
    > div {
      margin: 0 1em;
      color: $gray-light;
      padding: 20px;
      &.selected {
        color: black;
        border-bottom: solid 1px $blue-mid;
      }
      &:hover {
        color: $black-100;
      }
    }
  }
  &__child {
    > div {
      padding: 11px !important;
    }
  }
}
