import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { Box } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { BulkUpdateOperation, IPriceOfferLineGridService, MeteringTotalPrices, RequestFilterPriceOfferLine } from './i-data-line-api-service';
import { PriceOfferLineFromItem } from 'projects/quotes/add-items/add-items';
import { JSONPatchOperation } from './json-patch-operation';
import * as toastr from 'toastr';
import { RefToDisplay } from '../constants';


@autoinject
export class ContractSupplierOfferApiService implements IPriceOfferLineGridService {
  public contractId: number;
  public supplierOfferId: number;
  public showAmendment: boolean = false;
  protected callback: () => void;
  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N) { }

  createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  subscribe(callback: () => void) {
    this.callback = callback;
  }

  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async children(contractId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.ContractController.PriceOfferLineGrid_GetChildren.format(this.contractId.toString(), lineId.toString(), displayHidden ? 'true' : 'false', refToDisplay.toString(), this.showAmendment.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }

  public async filter(contractId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = ""
    if (this.supplierOfferId == null || this.supplierOfferId == 0)
      requestUri = Constants.Application.ContractController.PriceOfferLineGrid_Filter.format(this.contractId.toString(), this.showAmendment.toString());
    else
      requestUri = Constants.Application.ContractController.PriceOfferLineGrid_FilterDraftContract.format(this.supplierOfferId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let requestUri = Constants.Application.ContractController.Fetch.format(this.contractId.toString(), refToDisplay.toString(), this.showAmendment.toString());
    let result = await this.httpClient.post(requestUri, JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }
  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.ContractController.Patch.format(this.contractId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }
  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.ContractController.BulkPatch.format(versionId.toString());
    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }
  public async save(projectId: number, supplierOfferId: number, supplierTypeId: number): Promise<string> {
    let requestUri = Constants.Application.ContractController.CreateContract.format(projectId.toString(), supplierOfferId.toString(), supplierTypeId.toString());
    let response = await this.httpClient.post(requestUri);
    if (response.ok) {
      return await response.json();
    } else {
      let errMess = await response.json();
      toastr.error(this.i18n.tr(errMess));
    }
  }

  public async getTotalPrice(id: number) {
    let query = await this.httpClient.get(Constants.Application.ContractController.PriceOfferLineGrid_TotalPrice.format(id.toString(), this.showAmendment.toString()));
    return await query.json();
  }

  public async getTotalPriceDraft(supplierOfferId: string) {
    let query = await this.httpClient.get(Constants.Application.ContractController.PriceOfferLineGrid_TotalPriceDraft.format(supplierOfferId));
    return await query.json();
  }

  public async canCreateAmendment(id: number): Promise<boolean> {
    let query = await this.httpClient.get(Constants.Application.ContractController.CanCreateAmendment.format(id.toString()));
    return await query.json();
  }

  public async getThirdPartyType(supplierOfferId: number): Promise<Array<number>> {
    let query = await this.httpClient.get(Constants.Application.ContractController.GetThirdPartyType.format(supplierOfferId.toString()));
    return await query.json();
  }

  async recomputeLines(versionId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.get(Constants.Application.ContractController.RecomputeLines.format(versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  async getMeteringTotalPrices(versionId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.get(Constants.Application.ContractController.GetMeteringTotalPrices.format(versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

}


