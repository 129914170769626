import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, FileFlow, EnumerationTypeService, Various, IMenuGroup, IAnchorTab, PictureHelper, UIInternal } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { ValidationRules } from 'aurelia-validation';
import { EmailTemplateApiService, ITemplateApiService } from "services/email-template-api-service";
import Tribute, { TributeItem } from "tributejs";

@autoinject
export class EmailTemplateDetail extends EntityDetailViewModelBase<Merlin.Web.Model.EmailTemplateMerlin> {
  public Constants = Constants;
  public ressourceName: string = Constants.EntityTypeNames.EmailTemplateMerlin;
  //private fileType = Constants.EnumerationTypes.FileType;

  private emailTemplateService: ServiceBase<Merlin.Web.Model.EmailTemplate>;
  private notificationContentTypeService: EnumerationTypeService;
  private languageService: EnumerationTypeService;
  //private emailTemplateFileService: ServiceBase<Merlin.Web.Model.EmailTemplateFile>;
  private fileTypeService: EnumerationTypeService;
  private emailTypeService: ServiceBase<Merlin.Web.Model.EmailType>;
  private emailTemplateFileService: ServiceBase<Merlin.Web.Model.EmailTemplateFile>;
  private menuItems: Array<IMenuGroup>;
  public tabList: Array<IAnchorTab>;
  private canEditEmailType: boolean = true;

  private emailTemplateApiService: EmailTemplateApiService;
  private emailTokenService: ServiceBase<Merlin.Web.Model.MergeContentToken>;
  private emailTokens: Array<Merlin.Web.Model.MergeContentToken>;


  private inputSubject: HTMLElement;
  private inputSubjectTributeInstance: Tribute<any>;

  private columnsInfo: Array<{ columnName: string, columnVisibility: boolean }> = [
    { columnName: 'createdTime', columnVisibility: false },
  ]



  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private httpClient: HttpClient, private fileHelper: PictureHelper) {
    super(router, logger);
    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);
    this.emailTypeService = new ServiceBase(Constants.EntityTypeNames.EmailType);
    this.emailTypeService.gridDataSource.expands = ["type"];
    this.emailTemplateService = new ServiceBase(Constants.EntityTypeNames.EmailTemplate);
    this.fileTypeService = new EnumerationTypeService(Constants.EnumerationTypes.FileType);
    this.emailTemplateFileService = new ServiceBase(Constants.EntityTypeNames.EmailTemplateFile);
    this.emailTemplateApiService = new EmailTemplateApiService(httpClient);
    this.emailTokenService = new ServiceBase<Merlin.Web.Model.MergeContentToken>(Constants.EntityTypeNames.MergeContentToken);

    super.initialize(new ServiceBase<Merlin.Web.Model.EmailTemplateMerlin>(Constants.EntityTypeNames.EmailTemplateMerlin));

    ValidationRules.customRule('title-validation', async (value, obj) => {
      if (value != null && value.trim() != "") {
        return true;
      }
      return false;
    }, 'emailtemplatemerlin.titleError');
  }

  public async activate(params) {
    let id = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();

      this.entity.emailTemplate = await this.emailTemplateService.createEntity();
      this.entity.emailTemplate.notificationContentTypeId = 1;

      this.entity.active = true;

      if (params.emailTypeId != null) {
        this.canEditEmailType = false;
        this.entity.emailTypeId = params.emailTypeId;
      }
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'emailTemplate.emailTemplateFiles.fileType', 'emailTemplate.language', 'emailType.type');
      // await this.getEmailTokens();
    }

    this.controller.addObject(this.entity);

    this.setTabList();
    this.setMenuItems();

    UIInternal.queueTask(() => {
      this.initInputSubject();
    });


    ValidationRules
      .ensure('emailTitle')
      .satisfiesRule('title-validation')
      .on(this.entity.emailTemplate);
  }

  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: "tab1",
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      },
      {
        id: "tab2",
        name: this.i18n.tr("groupTabPanel.files"),
        isVisible: true
      }
    );
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      },
    ];
  }
  @computedFrom('editingMode', 'entity.emailTemplate', 'entity.emailTemplate.language', 'entity.emailType')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("emailtemplatemerlin.emailtemplatemerlin");
    }
    else {
      if (this.entity)
        return this.entity.emailType.type.denomination._translation + " - " + this.entity.emailTemplate.language.denomination._translation;
    }
  }

  public async beforeSave() {
    let validationResult = await this.controller.validate();
    if (!validationResult.valid) {
      validationResult.results.forEach(r => {
        this.logError(r.message, r, (r.message != null));
      });
      return false;
    }

    let p1 = new Predicate("emailTemplate.languageId", FilterQueryOp.Equals, this.entity.emailTemplate.languageId);
    let p2 = new Predicate("emailTypeId", FilterQueryOp.Equals, this.entity.emailTypeId);
    let template = await this.service.firstEntity(p1.and(p2));
    if (template && template.id != this.entity.id) {
      this.logError("emailtemplatemerlin.errorTemplateAlreadyExist", null);
      return false;
    }
    return true
  }

  private async initInputSubject() {
    if (this.inputSubject?.firstElementChild == null) return;
    if (this.inputSubjectTributeInstance != null) {
      this.inputSubjectTributeInstance.detach(this.inputSubject.firstElementChild);
    }
    let vals = await this.emailTemplateApiService.getTokens(this.entity.emailType.emailContextId)
    this.inputSubjectTributeInstance = new Tribute({
      trigger: '@',
      values: (text, cb) => {
        cb(vals.map(x => {
          return { key: x.id, value: x }
        }));
      },
      selectTemplate: (item) => {
        return item.original.value.value;
      },
      noMatchTemplate: () => '',
      itemClass: "ql-mention-list-item",
      menuItemTemplate: (item) => {
        return '<span class="placeholder-name">' + item.original.value.label + '</span> <span class="placeholder-key">' + item.original.value.value + "</span>"
      }
    });

    this.inputSubjectTributeInstance.attach(this.inputSubject.firstElementChild);
  }

  public async uploadedFile(fileUploaded: FileFlow) {
    let fileEntity: Merlin.Web.Model.EmailTemplateFile = fileUploaded.linkEntity;
    fileEntity.url = fileUploaded.url;
    if (this.editingMode == EditingModeEnum.Update) {
      fileEntity.fileType = await this.fileTypeService.getDefault();
      await this.emailTemplateFileService.saveEntity(fileEntity);
    }
  }

  public async uploadFile(file: FileFlow) {
    let fileEntity: Merlin.Web.Model.EmailTemplateFile = await this.emailTemplateFileService.createEntity();
    fileEntity.emailTemplateId = this.entity.emailTemplate.id;
    fileEntity.name = file.name;
    fileEntity.size = file.size;

    this.entity.emailTemplate.emailTemplateFiles.push(fileEntity);
    return fileEntity;
  }

  public deleteFile(file: Merlin.Web.Model.EmailTemplateFile) {
    this.emailTemplateFileService.deleteEntities([file], true);
  }

  public getFileMenuItem(currentThis: Merlin.Web.Model.EmailTemplateFile) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.download"),
            icon: "digi-download",
            handler: async () => {
              this.fileHelper.downloadFile(currentThis);
            }
          },
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: async () => {
              await this.deleteFile(currentThis);
            }
          }
        ]
      }
    ]
  }
}
