import { ServiceBase } from "digiwall-lib";
import { Merlin } from '../generated';
import * as Constants from '../constants';
import { singleton } from "aurelia-framework";

@singleton()
export class MerlinUserApiService {

  private userService: ServiceBase<Merlin.Web.Model.MerlinUser>;
  private userList: Array<Merlin.Web.Model.MerlinUser> = [];

  constructor() {
    this.userService = new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser);
  }

  public async GetAllEntitiesFromMemory(): Promise<Array<Merlin.Web.Model.MerlinUser>> {
    if (this.userList == null || this.userList.length == 0) {
      this.userList = await this.userService.getAllEntities();
    }
    return this.userList;
  }
}
