import moment = require('moment-timezone');
import { toRegex } from "diacritic-regex";
import { valueConverter } from 'aurelia-binding';


export class FormatHourValueConverter {
  toView(value: Date, format = 'HH:mm') {
    if (value) {
      return moment.utc(value).tz(moment.tz.guess()).format(format);
      // moment.tz.setDefault(moment.tz.guess());
      // return moment.utc(value).format('HH:mm');
    }
  }
}

export class SuffixValueConverter {
  toView(value, suffix) {
    if (value) {
      return value + suffix;
    }
    else {
      return '';
    }
  }
}



export class UnitConvertionValueConverter {
  toView(value: number) {
    if (value != 0) {
      value = 1 / value;
    }
    return value;
  }

  fromView(value: number) {
    if (value != 0) {
      value = 1 / value;
    }
    return value;
  }
}

export class HighlightSearchTextValueConverter {
  toView(value: string, search: string) {
    if (value == null) return '';
    if (search == null || search.trim().length == 0) return value;
    const regExp = new RegExp(toRegex()(search.trim().replace(/\/|\\/g, '')), 'igu');// Use toRegex to manage diacritics
    const regExpResult = regExp.exec(value);
    if (regExpResult) {
      regExpResult.forEach(r => {
        value = value.replaceAll(r, `<span class='cell-highlight-text'>${regExpResult.toString()}</span>`)
      });
    }
    return value;
  }
}

export class SortArrayValueConverter {
  toView(array, propertyName: string, direction: string = 'asc') {
    if (array != null && Array.isArray(array)) {
      let factor = direction === 'asc' ? 1 : -1;
      if (propertyName.includes('.')) {
        let propertyNames: Array<string> = propertyName.split('.');
        return array.slice(0).sort((a, b) => {
          return (a[propertyNames[0]][propertyNames[1]] - b[propertyNames[0]][propertyNames[1]]) * factor;
        });
      }
      return array.slice(0).sort((a, b) => {
        return (a[propertyName] - b[propertyName]) * factor;
      });
    }
  }
}

export class DigitValueConverter {
  toView(value: number, nbDigit: number) {
    if (value != null && value.toString().length > 0 && typeof value == "number") {
      return (value as Number).toLocaleString('de-DE', {
        minimumFractionDigits: nbDigit,
        maximumFractionDigits: nbDigit
      });
    }
    else {
      return '';
    }
  }
}

export class FormatLocalDateValueConverter {
  toView(value, format) {
    if (!value) {
      return null;
    }
    let m = moment.utc(value).local();
    if (m.isValid()) {
      return m.format(format);
    }
    if (!m.isValid()) {
      return m;
    }
    if (m.year() <= 1970) {
      return "";
    }
    return m;
  }
}

@valueConverter("merlinCurrency")
export class CurrencyValueConverter {
  toView(value: number, maxValue?: number) {
    if (value != null) {
      return value.toLocaleString('de-DE', {
        minimumFractionDigits: maxValue != null ? maxValue : 2,
        maximumFractionDigits: maxValue != null ? maxValue : 2,
      }) + ' €';
    }
    else {
      return '';
    }
  }
}
