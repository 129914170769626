import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ViewModelBase, CustomLogger, IFile, FileFlow, Box, PictureHelper, GlobalLoaderService } from 'digiwall-lib';
import * as Constants from '../../constants';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';


@autoinject
export class ImportFile extends ViewModelBase {
  ribbonHeaderText: string = "";
  ressourceName: string = "";
  Constants: any = Constants;

  public supplierOfferId: number;
  public files: IFile[] = [];

  private columnsInfo: Array<{ columnName: string, columnVisibility: boolean }> = [
    { columnName: 'description', columnVisibility: false },
    { columnName: 'fileTypeId', columnVisibility: false },
    { columnName: 'size', columnVisibility: false },
  ]

  private currentFile: IFile;
  type: DocumentTypeDiscriminator;
  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private fileHelper: PictureHelper, private globalLoaderService: GlobalLoaderService) {
    super(router, logger);
  }

  public getFileMenuItem(currentThis: IFile) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.download"),
            icon: "digi-download",
            handler: async () => {
              this.fileHelper.downloadFile(currentThis);
            }
          }
        ]
      }
    ]
  }

  async activate(params: any) {
    await super.activate(params);
    this.type = params.type;
    this.supplierOfferId = params.param1;
  }

  uploadFile(file: FileFlow) {
    this.files.splice(0);
  }

  uploadedFile(file: FileFlow) {
    this.currentFile = { name: file.name, url: file.url, fileTypeId: null, description: null, size: null } as IFile;
    this.files.push(this.currentFile);
  }

  async import(): Promise<boolean> {
    if (this.currentFile != null) {
      let requestUri = Constants.Application.SupplierOfferController.ImportXls.format(this.type.toString(), this.supplierOfferId.toString());
      this.globalLoaderService.allow();
      let response = await this.httpClient.post(requestUri, JSON.stringify(new Object({ name: this.currentFile.name, url: this.currentFile.url })));
      if (response.ok) {
        let json: SupplierOfferExcelResult = await response.json();
        if (json.hasError || json.nbTotalImportedLines == null || json.nbTotalLines == null) {
          this.logError(this.i18n.tr("supplieroffer.notMerlinFormatError"), null, true);
          return false;
        } else {
          this.box.showNotification(this.i18n.tr("supplieroffer.nbImportedLine", { nbTotalImportedLines: json.nbTotalImportedLines, nbTotalLines: json.nbTotalLines }), null, null, null, null);
        }
        return true;
      }
      return false;
    }
  }
}

export interface SupplierOfferExcelResult {
  hasError: boolean;
  nbTotalLines: number;
  nbTotalImportedLines: number;
}
