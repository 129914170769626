import { HttpClient } from 'aurelia-fetch-client';
import { DataFormat } from 'select2';
import { autoinject, bindable, Container, customElement, observable } from "aurelia-framework";
import { ServiceBase } from "digiwall-lib";
import { Merlin } from "generated";
import * as Constants from '../../../../constants';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { ContentTemplateApiService } from 'services/email-template-api-service';

@autoinject
@customElement("send-offer-step-1")
export class SendOfferStep1 {
  @bindable
  public hasCoverLetter: boolean;
  @bindable
  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest;
  @bindable
  public coverLetterContent: string;
  @bindable
  public communicationLanguageId: number;
  @bindable
  public signatoryIds: number[] = [];
  @bindable
  public contentContext: number = Constants.ContentTypeId.ClientOfferCoverLetter;

  @observable
  private selectedCoverLetterTemplate: DataFormat = { id: "", text: "" }
  private contentTemplateApiService: ContentTemplateApiService

  private coverLetterService: ServiceBase<Merlin.Web.Model.ContentTemplate>;
  private merlinUserService: ServiceBase<Merlin.Web.Model.MerlinUser>;
  public allPossibleUsers: Array<Merlin.Web.Model.MerlinUser>;

  private showQuill: boolean = true;

  constructor() {
    this.coverLetterService = new ServiceBase<Merlin.Web.Model.ContentTemplate>(Constants.EntityTypeNames.ContentTemplate);
    this.merlinUserService = new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser);
    this.contentTemplateApiService = new ContentTemplateApiService(Container.instance.get(HttpClient));
    this.contentTemplateApiService.previewRequestParams = {};
  }

  async bind() {
    this.coverLetterService.gridDataSource.queryParameters = { contentTypeId: this.contentContext }
    let p1 = new Predicate("isDefault", FilterQueryOp.Equals, true);
    let p2 = new Predicate("contentTypeId", FilterQueryOp.Equals, this.contentContext);
    let defaultCoverLetters = await this.coverLetterService.getEntities(p1.and(p2));
    if (defaultCoverLetters != null && defaultCoverLetters.length > 0 && !this.coverLetterContent) {
      this.selectedCoverLetterTemplate.id = defaultCoverLetters[0].id;
      this.selectedCoverLetterTemplate.text = defaultCoverLetters[0].templateNameML._translation;
      this.selectedCoverLetterTemplateChanged(defaultCoverLetters[0].id)
    }

    this.allPossibleUsers = await this.merlinUserService.getAllEntities();

    this.contentTemplateApiService.previewRequestParams = this.previewRequestParams;
    this.contentTemplateApiService.contentTypeId = this.contentContext;
  }

  private async selectedCoverLetterTemplateChanged(newVal) {
    this.showQuill = false;
    let contentHtml;
    if (this.contentTemplateApiService != null && newVal != null) {
      if (isNumber(newVal)) {
        contentHtml = (await this.coverLetterService.getEntityById(newVal)).contentHtmlML;
        this.contentTemplateApiService.previewRequestParams.emailContextId = newVal;
      }
      if (newVal.id) {
        contentHtml = (await this.coverLetterService.getEntityById(parseInt(newVal.id))).contentHtmlML;
        this.contentTemplateApiService.previewRequestParams.emailContextId = newVal.id;
      }

      switch (this.communicationLanguageId) {
        case 1:
          if (contentHtml.lang1 != null) {
            this.coverLetterContent = contentHtml.lang1;
            break;
          }
        case 2:
          if (contentHtml.lang2 != null) {
            this.coverLetterContent = contentHtml.lang2;
            break;
          }
        case 3:
          if (contentHtml.lang3 != null) {
            this.coverLetterContent = contentHtml.lang3;
            break;
          }
        default:
          this.coverLetterContent = contentHtml._translation;
          break;
      }
      this.contentTemplateApiService.previewRequestParams.contentHtml = this.coverLetterContent;
    }
    this.showQuill = true;
  }
}
