import { EventAggregator } from 'aurelia-event-aggregator';
import { DataFormat } from 'select2';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, EntityDetailViewModelBase, ServiceBase, Various, UIInternal, GlobalLoaderService } from 'digiwall-lib';
import { autoinject, PassThroughSlot } from "aurelia-framework";
import { Merlin } from 'generated';
import * as Constants from '../constants';
import { DialogController, DialogService } from 'aurelia-dialog';
import { isThisSecond } from 'date-fns';


@autoinject
export class EditWorkQuotationVersion {

  private workQuotationVersionService: ServiceBase<Merlin.Web.Model.WorkQuotationVersion>;
  private entity: Merlin.Web.Model.WorkQuotationVersion;

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController, private httpClient: HttpClient, private globalLoaderService: GlobalLoaderService) {
    this.workQuotationVersionService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersion>(Constants.EntityTypeNames.WorkQuotationVersion);
  }

  async activate(params: { workQuotationVersionId: number }) {
    this.entity = await this.workQuotationVersionService.getEntityById(params.workQuotationVersionId);
  }

  async close() {
    await this.dialogController.close(false);
  }

  async save() {
    await this.workQuotationVersionService.saveEntity(this.entity);
    UIInternal.broadcast(Constants.MerlinCustomEvent.VersionNameChange, { id: this.entity.id, text: this.entity.fullName, isCurrentVersion: this.entity.isCurrentVersion });
    this.dialogController.ok(this.entity);
  }

}
