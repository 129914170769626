import { ListViewModelBase, CustomLogger, ServiceBase, SelectAllHeaderComponent, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { Merlin } from 'generated';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class ItemFamilyList extends ListViewModelBase<Merlin.Web.Model.ItemFamily> {
  public ressourceName: string = Constants.EntityTypeNames.Item;
  public isSuperAdmin: boolean;

  public buttonLabel: string = this.i18n.tr("itemfamily.addItemFamily");
  public pathDetail: string;


  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.ItemFamily>(Constants.EntityTypeNames.ItemFamily));
    this.service.gridDataSource.expands = ['mainFamily.hierarchy'];
  }

  public getDetailsUrl(self: any, entity: Merlin.Web.Model.ItemFamily): string {
    if (entity?.id) {
      return (this.pathDetail + entity.id);
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = '/item-families/';
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("itemfamily.familyName") + this.i18n.tr("general.inBracketsFR"),
        field: "familyName.lang1",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("itemfamily.familyName") + this.i18n.tr("general.inBracketsNL"),
        field: "familyName.lang2",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("itemfamily.familyName") + this.i18n.tr("general.inBracketsEN"),
        field: "familyName.lang3",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("itemfamily.mainFamilyId"),
        field: "mainFamily.hierarchy._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
} 
