@import "../css/scss/variables";
@keyframes slideaway {
  from {
    display: none;
    transform: translateY(-40px);
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.parameters-header {
  margin: 0 40px;
  border-bottom: 1px solid #dddddd;
  padding: 10px 0;
  font-size: 40px;
  font-weight: 300;
}

.parameters-content.menu-collapsed {
  grid-template-columns: 0px auto;
}

.parameters-content {
  margin: 0 40px;
  display: grid;
  grid-template-areas: "menu routers";
  grid-template-columns: 250px auto;
  transition: all 0.25s ease;
  height: calc(100% - 70px);

  > .parameters-content-route {
    overflow: scroll;
    grid-area: menu;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-right: 1px solid #dddddd;
    padding-top: 20px;
    .collapse-button {
      display: flex;
      flex-direction: row;
    }
    .parameters-content-route-name {
      cursor: pointer;
    }
    > .group {
      > div:first-child {
        display: grid;
        grid-template-columns: 18px auto 30px;
        align-items: center;
      }
      > .group-child {
        opacity: 1;
        display: flex;
        flex-direction: column;
        animation: slideaway 200ms;
        > .sub-route {
          margin: 5px 0px 5px 18px;
          &.sub-route-active {
            color: $primary;
          }
        }
      }
      &.group-focus,
      &.group-children-selected {
        > div:first-child {
          color: $primary;
        }
      }
      &.group-children-selected {
        > div:first-child {
          background-color: $gray-very-light;
          border-radius: 6px;
        }
      }
    }

    .group,
    .sub-route {
      > span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
  > router-view {
    grid-area: routers;
  }
}
