import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from "aurelia-framework";
import * as Constants from '../constants';
import { BaseApiService } from './base-api-service';
import { I18N } from 'aurelia-i18n';
import { Box } from 'digiwall-lib';

@autoinject
export class MerlinApiService extends BaseApiService {
  /**
   *
   */
  constructor(private httpClient: HttpClient, public i18n: I18N, public box: Box) {
    super(i18n, box);
  }

  public async calcMathFormula(formula: string, rejectErrors = false, alertErrors = false): Promise<number | null> {
    return new Promise((resolve, reject) => {
      if (formula != null && typeof formula != 'string') {
        reject("Typeof Formula must be a string.");
        return;
      }
      if (formula == null || formula.length == 0) {
        resolve(null);
        return;
      }

      let formattedFormula = formula.replace(/[.]/g, ',');

      this.httpClient.post(Constants.Application.CalcMathFormula + "?returnErrors=" + (rejectErrors || alertErrors ? "true" : "false"), JSON.stringify(formattedFormula))
        .then(async response => {
          if (response.ok) {
            resolve(await response.json());
          }
          else {
            if (alertErrors)
              this.manageError(response);
            if (rejectErrors)
              reject(await this.getErrorMessage(response));
            else
              reject();
          }
        });
    });
  }
}
