import { HttpClient } from 'aurelia-fetch-client';
import { BindingEngine, Disposable } from 'aurelia-framework';
import { Box } from 'digiwall-lib';
import { autoinject } from 'aurelia-dependency-injection';
import { DialogController } from 'aurelia-dialog';
import { I18N } from 'aurelia-i18n';
import { ActionDialogBoxInputParameters, DialogBoxViewModel, GlobalLoaderService } from 'digiwall-lib';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { FiltersUtils } from 'resources/utilities/filter-utils';
import { IDataLineApiService } from "services/i-data-line-api-service";
import { ProjectApiService } from 'services/project-api-service';
import * as Constants from '../../../constants';
import { RefToDisplay } from '../../../constants';
import { Router } from 'aurelia-router';
import * as toastr from 'toastr';

@autoinject
export class UpdateItems {
  public apiService: IDataLineApiService
  public title: string
  public items: Array<any> = [];

  public columnVisible: Array<ColumnVisible>;
  nbDecimalForPriceDisplay: number;
  nbDecimalForQuantityDisplay: number;
  projectId: number;
  parentId: number;
  updateItemType: UpdateItemType = UpdateItemType.Other;
  agGridViewModule: string;

  private oldBuyingPrice;
  private oldCoef;
  private oldSellingPrice;
  private oldUnitPrice;
  private originalFilterFn: (versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay) => Promise<any>;
  disposables: Array<Disposable> = [];

  private isUpdatingPrices: boolean = false;

  constructor(public router: Router, public i18n: I18N, private dialogController: DialogController, private projectApiService: ProjectApiService, public filtersUtils: FiltersUtils, public gls: GlobalLoaderService, public box: Box, public bindingEngine: BindingEngine, public httpClient: HttpClient) {
  }

  public async activate(params: any) {
    this.items.push({
      buyingUnitPrice: params.data.buyingUnitPrice,
      marginCoefficient: params.data.marginCoefficient,
      definedSellingUnitPrice: params.data.definedSellingUnitPrice,
      supplierPrice: params.data.supplierPrice,
      lineDescription: params.data.lineDescription,
      unitTranslatedString: params.data.unitTranslatedString,
      proposedQuantity: params.data.proposedQuantity,
      supplierQuantity: params.data.supplierQuantity,
      totalBuyingPrice: params.data.totalBuyingPrice,
      totalSellingPrice: params.data.totalSellingPrice,
      supplierTotal: params.data.supplierTotal,
      itemId: params.data.itemId
    });
    this.oldBuyingPrice = this.items[0].buyingUnitPrice;
    this.oldCoef = this.items[0].marginCoefficient;
    this.oldSellingPrice = this.items[0].definedSellingUnitPrice;
    this.oldUnitPrice = this.items[0].supplierPrice;

    this.title = this.i18n.tr("item.changeArticlePrice");
    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.projectId = params.projectId;
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = precisionParameter.nbDecimalForQuantityDisplay
    this.parentId = params.parentId;
    this.apiService = params.api;
    this.columnVisible = params.columnVisible;
    this.agGridViewModule = params.agGridViewModule;
    if (params.updateItemType)
      this.updateItemType = params.updateItemType;

    this.originalFilterFn = this.apiService.filter;
    this.apiService.filter = async (versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay) => {
      await this.filtersUtils.forceRelationFilterValues(filterParams, "itemId", [this.items[0].itemId], Constants.EntityTypeNames.Item);
      return this.originalFilterFn.call(this.apiService, versionId, filterParams, quickFilter, displayMode, displayHidden, refToDisplay);
    }

    if (this.updateItemType == UpdateItemType.Other) {
      this.disposables.push(this.bindingEngine.propertyObserver(this.items[0], "buyingUnitPrice").subscribe(async (newVal, oldVal) => await this.updateSellingPrice(newVal, oldVal)));
      this.disposables.push(this.bindingEngine.propertyObserver(this.items[0], "marginCoefficient").subscribe(async (newVal, oldVal) => await this.updateSellingPrice(newVal, oldVal)));
      this.disposables.push(this.bindingEngine.propertyObserver(this.items[0], "definedSellingUnitPrice").subscribe(async (newVal, oldVal) => await this.updateCoef(newVal, oldVal)));
    }
    else if (this.updateItemType == UpdateItemType.Metering) {
      this.disposables.push(this.bindingEngine.propertyObserver(this.items[0], "buyingUnitPrice").subscribe(async (newVal, oldVal) => await this.computeTotalBuying()));
    }
    else if (this.updateItemType == UpdateItemType.Supplier) {
      this.disposables.push(this.bindingEngine.propertyObserver(this.items[0], "supplierPrice").subscribe(async (newVal, oldVal) => await this.computeSupplierTotal()));
    }
  }

  public getGridMenuItems = () => [];

  async close() {
    this.apiService.filter = this.originalFilterFn
    await this.dialogController.close(false);
  }

  public detached() {
    this.disposables?.forEach(x => x.dispose());
  }

  async save() {
    if (await this.questionUpdateItems()) {
      let events = [];
      let lines = await this.apiService.filter(this.parentId, {}, "", Constants.TreeDisplayMode.Flat, false, Constants.RefToDisplay.MerlinRef);
      let ids = lines.map(x => x.id);

      if (this.items[0].buyingUnitPrice != this.oldBuyingPrice) {
        events.push(... this.setUpdateEvents(ids, "buyingUnitPrice", this.items[0].buyingUnitPrice))
      }
      if (this.items[0].marginCoefficient != this.oldCoef) {
        events.push(... this.setUpdateEvents(ids, "marginCoefficient", this.items[0].marginCoefficient))
      }
      if (this.items[0].definedSellingUnitPrice != this.oldSellingPrice) {
        events.push(... this.setUpdateEvents(ids, "definedSellingUnitPrice", this.items[0].definedSellingUnitPrice))
      }
      if (this.items[0].supplierPrice != this.oldUnitPrice) {
        events.push(... this.setUpdateEvents(ids, "supplierPrice", this.items[0].supplierPrice))
      }

      this.gls.allow("item.updatingItems", 0);
      let result = await this.apiService.bulkPatch(this.parentId, events);
      this.apiService.filter = this.originalFilterFn
      this.dialogController.close(true, result);
    }
  }

  private setUpdateEvents(ids: Array<number>, propertyName: string, value) {
    let events = [];
    ids.forEach(id => {
      events.push({ lineId: id, propertyName: propertyName, propertyValue: value })
    });
    return events;
  }

  async questionUpdateItems() {
    let result: boolean = false;
    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.yes", { ns: "common" }),
      title: this.i18n.tr("general.yes", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      }
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.no", { ns: "common" }),
      title: this.i18n.tr("general.no", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      }
    };
    await this.box.showQuestion(this.i18n.tr('item.updateItemsQuestion'), this.i18n.tr('menu.question'), [buttonYes, buttonNo]).whenClosed(
      async (resultQuestion) => {
        result = resultQuestion.output
      }
    )
    return result
  }

  private async updateSellingPrice(newVal, oldVal) {
    if (!this.isUpdatingPrices && newVal != null && newVal != oldVal) {
      this.isUpdatingPrices = true;
      let response = await this.httpClient.post(Constants.Application.PricesServiceController.ComputePrices, JSON.stringify({ buyingPrice: this.items[0].buyingUnitPrice ?? 0, sellingPrice: null, margin: this.items[0].marginCoefficient ?? 0 }));

      if (response.ok) {
        let result = await response.json();
        this.items[0].definedSellingUnitPrice = result.sellingPrice;
        await this.computeTotalBuying();
        await this.computeTotalSelling();
      }
      else {
        toastr.error(await response.text());
      }

      setTimeout(() => {
        this.isUpdatingPrices = false;
      }, 1);
    }
  }

  private async updateCoef(newVal, oldVal) {
    if (!this.isUpdatingPrices && newVal != null && newVal != oldVal) {
      this.isUpdatingPrices = true;
      let response = await this.httpClient.post(Constants.Application.PricesServiceController.ComputePrices, JSON.stringify({ buyingPrice: this.items[0].buyingUnitPrice ?? 0, sellingPrice: this.items[0].definedSellingUnitPrice ?? 0, margin: null }));
      if (response.ok) {
        let result = await response.json();
        this.items[0].marginCoefficient = result.margin;
        await this.computeTotalSelling();
      }
      else {
        toastr.error(await response.text());
      }

      setTimeout(() => {
        this.isUpdatingPrices = false;
      }, 1);
    }
  }

  private async computeTotalBuying() {
    let response = await this.httpClient.get(Constants.Application.PricesServiceController.GetMultiplyDecimal.format(this.items[0].buyingUnitPrice.toString(), this.items[0].proposedQuantity.toString()));
    if (response.ok) {
      this.items[0].totalBuyingPrice = await response.json();
    }
    else {
      toastr.error(await response.text());
    }
  }

  private async computeTotalSelling() {
    let response = await this.httpClient.get(Constants.Application.PricesServiceController.GetMultiplyDecimal.format(this.items[0].definedSellingUnitPrice.toString(), this.items[0].proposedQuantity.toString()));
    if (response.ok) {
      this.items[0].totalSellingPrice = await response.json();
    }
    else {
      toastr.error(await response.text());
    }
  }

  private async computeSupplierTotal() {
    let response = await this.httpClient.get(Constants.Application.PricesServiceController.GetMultiplyDecimal.format(this.items[0].supplierPrice.toString(), this.items[0].supplierQuantity.toString()));
    if (response.ok) {
      this.items[0].supplierTotal = await response.json();
    }
    else {
      toastr.error(await response.text());
    }
  }
}

export enum UpdateItemType {
  Other = 0,
  Metering = 1,
  Supplier = 2
}
