import { Container, autoinject, bindable, bindingMode, customElement } from "aurelia-framework";
import { Merlin } from "generated";
import { DocumentService } from "./document-service";
import { DialogService } from "aurelia-dialog";
import { AddDocument } from "./add-document";
import { I18N } from 'aurelia-i18n';
import { Box, PictureHelper, CustomLogger } from 'digiwall-lib';
import { EditDocument } from './edit-document';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';
import * as Constants from '../constants';
import { HttpClient } from 'aurelia-fetch-client';

@autoinject
@customElement("ui-document-file")
export class UIDocumentFile {

  @bindable({ bindingMode: bindingMode.twoWay })
  public documents: Array<Merlin.Web.Model.Document>;

  @bindable
  public itemId: number;
  @bindable
  public taskId: number;

  @bindable({ bindingMode: bindingMode.oneTime })
  refreshDocumentList: () => {};
  @bindable({ bindingMode: bindingMode.oneTime })
  deleteFile: (document) => {};

  constructor(private fileHelper: PictureHelper, private i18n: I18N, private box: Box) {

  }

  public getMenuItemsDocument(currentThis: Merlin.Web.Model.Document) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.edit"),
            icon: "digi-edit",
            handler: async () => {
              this.editDocument(currentThis);
            }
          },
          {
            label: this.i18n.tr("menu.download"),
            icon: "digi-download",
            handler: async () => {
              this.fileHelper.downloadFile(currentThis);
            }
          },
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: async () => {
              await this.deleteFile(currentThis);
            }
          }
        ]
      }
    ]
  }


  async addDocuments() {
    let documentService = new DocumentService(
      Container.instance.get(HttpClient),
      Container.instance.get(I18N),
      Container.instance.get(Box),
      Container.instance.get(CustomLogger),
    )
    documentService.itemId = this.itemId;
    documentService.taskId = this.taskId;
    let dialogService = Container.instance.get(DialogService);
    let folderName = ""
    let documentModuleId
    if (this.itemId != null) {
      folderName = "items/" + this.itemId;
      documentModuleId = Constants.DocumentModuleId.Article
    } else if (this.taskId != null) {
      folderName = "tasks/" + this.taskId;
      documentModuleId = Constants.DocumentModuleId.Project
    }

    await dialogService.open({
      viewModel: AddDocument,
      model: {
        service: documentService,
        folderName: folderName,
        documentModuleId: documentModuleId
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("z-index-level-4");
        dialogOverlay.classList.add("z-index-level-4");
      },
    }).whenClosed(async result => {
      this.refreshDocumentList();
    });
  }


  previewDocument(document: Merlin.Web.Model.Document) {
    this.fileHelper.previewFile(document);
  }

  editDocument(document: Merlin.Web.Model.Document) {
    let documentService = new DocumentService(
      Container.instance.get(HttpClient),
      Container.instance.get(I18N),
      Container.instance.get(Box),
      Container.instance.get(CustomLogger),
    )
    documentService.itemId = this.itemId;
    documentService.taskId = this.taskId;
    this.box.showEditDialog(EditDocument, document.id, this.i18n.tr('document.editDocument'),
      {
        canSave: false,
        size: "md",
        model: { id: document.id, service: documentService },
        actions: [
          EditDialogAction.GetSaveAction(this.i18n)
        ],
        overlayClass: "z-index-level-3",
        containerClass: "z-index-level-3"
      }).whenClosed(result => {
        if (!result.wasCancelled) {
          this.refreshDocumentList();
        }
      });
  }
}
