import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { GlobalLoaderService, IMenuItems } from "digiwall-lib";
import * as Constants from '../../../constants';
import { ICellRendererParams, RowNode } from "ag-grid-community";
import { IDataLineApiService } from "services/i-data-line-api-service";
import { PriceOfferLinesGrid } from "price-offer-lines/price-offer-lines-grid";

@autoinject
export class MenuMove {
  constructor() { }
  public getMenu(params: ICellRendererParams, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, listIsFlat: boolean, versionId: string): IMenuItems {
    let i18n = Container.instance.get(I18N);
    let menuItem = {
      id: Constants.CommonMeterginDataLineMenuItems.MOVES,
      icon: "digi-arrow-up-down-line",
      label: i18n.tr("versionmetering.move"),
      disabled: () => {
        return this.cantMoveToThisNode(params.node.id, priceOfferLinesGrid, listIsFlat) || (this.cantMoveToThisNodeUpDown(params.node.id, priceOfferLinesGrid) && this.cantMoveToThisNodeChildren(params.node.id, priceOfferLinesGrid));
      },
      items: [
        {
          id: Constants.CommonMeterginDataLineMenuItems.MOVE_UP,
          label: i18n.tr("versionmetering.moveUp"),
          icon: "digi-arrow-up-line",
          handler: async () => {
            await this.moveNode(params.node.id, Constants.PriceOfferLineMoveAction.Up, priceOfferLinesGrid, dataLineApiService, versionId, listIsFlat);
          },
          disabled: () => {
            return this.cantMoveToThisNode(params.node.id, priceOfferLinesGrid, listIsFlat) || this.cantMoveToThisNodeUpDown(params.node.id, priceOfferLinesGrid);
          }
        },
        {
          id: Constants.CommonMeterginDataLineMenuItems.MOVE_DOWN,
          label: i18n.tr("versionmetering.moveToChildren"),
          icon: "digi-arrow-right-line",
          handler: async () => {
            await this.moveNode(params.node.id, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, dataLineApiService, versionId, listIsFlat);
          },
          disabled: () => {
            return this.cantMoveToThisNode(params.node.id, priceOfferLinesGrid, listIsFlat) || this.cantMoveToThisNodeChildren(params.node.id, priceOfferLinesGrid);
          }

        },
        {
          id: Constants.CommonMeterginDataLineMenuItems.MOVE_CHILD,
          label: i18n.tr("versionmetering.moveDown"),
          icon: "digi-arrow-down-line",
          handler: async () => {
            await this.moveNode(params.node.id, Constants.PriceOfferLineMoveAction.Down, priceOfferLinesGrid, dataLineApiService, versionId, listIsFlat);
          },
          disabled: () => {
            return this.cantMoveToThisNode(params.node.id, priceOfferLinesGrid, listIsFlat) || this.cantMoveToThisNodeUpDown(params.node.id, priceOfferLinesGrid);
          }
        }
      ],
    };
    return menuItem
  }

  private cantMoveToThisNodeUpDown(targetRowId: string, priceOfferLinesGrid: PriceOfferLinesGrid) {
    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId);
    if (!targetNode) {
      console.warn('Target node not found!');
      return true;
    }

    if (targetNode.data.isDetailedLine) {
      return true;
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && targetNode.parent.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return true;
    }

    return false;
  }

  private cantMoveToThisNodeChildren(targetRowId: string, priceOfferLinesGrid: PriceOfferLinesGrid) {
    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId);
    if (!targetNode) {
      console.warn('Target node not found!');
      return true;
    }

    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return !targetNode.data.hasChildrenSubItem;
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && targetNode.parent.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return true;
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && targetNode.parent.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) {
      return true;
    }

    return false;
  }

  private cantMoveToThisNode(targetRowId: string, priceOfferLinesGrid: PriceOfferLinesGrid, listIsFlat: boolean) {
    // if (listIsFlat)
    //   return true;

    let selectedNodes = priceOfferLinesGrid.gridOptions.api.getSelectedNodes();
    if (selectedNodes?.length == 0) {
      return true;
    }
    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId);
    if (!targetNode) {
      console.warn('Target node not found!');
      return true;
    }

    let hasSameParent = false;
    selectedNodes.forEach(selectedNode => {
      if (selectedNode.data.id == targetNode.data.id) {
        hasSameParent = true;
      }
    });
    return hasSameParent;
  }


  private async moveNode(targetRowId: string, action: Constants.PriceOfferLineMoveAction, priceOfferLinesGrid: PriceOfferLinesGrid, dataLineApiService: IDataLineApiService, versionId: string, listIsFlat: boolean) {
    let selectedNodes = priceOfferLinesGrid.gridOptions.api.getSelectedNodes();

    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId)!;
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    // Unselect all nodes.
    selectedNodes.forEach(n => n.data.isSelected = false);
    priceOfferLinesGrid.gridOptions.api.redrawRows({ rowNodes: selectedNodes });

    // Remove nodes who move inner itself or own children.
    let targetParentsIds: Array<number> = this.parentsIds(targetNode).map(n => n.data.id);
    selectedNodes = selectedNodes.filter(n => targetParentsIds.find(tp => tp == n.data.id) == null);

    if (action == Constants.PriceOfferLineMoveAction.Into && targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && selectedNodes.findIndex(x => x.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) != -1) {
      console.warn('Cannot move chapter into data');
      return;
    }

    let globalLoaderService = Container.instance.get(GlobalLoaderService);
    // Move nodes
    globalLoaderService.allow(true, 100);

    let ids = await dataLineApiService.move(parseInt(versionId), selectedNodes.map(n => n.data.id), targetNode.data.id, action);
    priceOfferLinesGrid.gridOptions.api.hideOverlay();

    // Apply all remove transactions in grid to avoid duplicated row ids when refreshing modified rows.
    let transactions = [];
    selectedNodes.forEach(selected => {
      let parentRoute = selected.parent?.getRoute();
      let tr;
      if ((tr = transactions.find(t => t.route == parentRoute)) != null) {
        tr.remove.push(selected.data)
      } else {
        transactions.push({ route: parentRoute, remove: [selected.data] });
      }
    });

    transactions.forEach(tr => priceOfferLinesGrid.gridOptions.api.applyServerSideTransaction(tr));

    // Refresh modified rows
    priceOfferLinesGrid.refreshServerSideRows(ids, targetNode.data.parentId == null || selectedNodes.some(n => n.data.parentId == null) || listIsFlat);

    if (action == Constants.PriceOfferLineMoveAction.Into) {
      targetNode.setExpanded(true);
    }
  }

  private parentsIds(node: RowNode): Array<RowNode> {
    let nodes = [];
    while (node != null) {
      if (node.parent != null && node.parent.data != null) {
        nodes.push(node.parent);
        node = node.parent;
      } else {
        node = null;
      }
    }
    return nodes;
  }
}
