import { IGetRowsParams } from "ag-grid-community";
import { toRegex } from "diacritic-regex";
import { Merlin } from 'generated';

export class CustomThirdPartiesSourceService {
  private values: Array<Merlin.Web.Model.ThirdParty>;

  constructor(values: Array<Merlin.Web.Model.ThirdParty>) {
    this.values = values ?? [];
  }
  async getRowsForSelect2(params: IGetRowsParams, filterText: string, filterProperties: string[]) {
    const distinct = (value, index, self) => self.indexOf(value) === index;
    if (filterText && filterProperties) {
      let result = [];
      filterProperties.forEach(prop => {
        result = result.concat(this.values.filter(v => {
          const regExp = new RegExp(toRegex()(filterText.trim()), 'igu');// Use toRegex to manage diacritics
          return regExp.exec(v[prop]) != null;
        }));
      });
      params.successCallback(result.filter(distinct), result.length);
    } else {
      params.successCallback(this.values.filter(distinct), this.values.length);
    }
  }
}
