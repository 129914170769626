// div[slot="ribbon-content"],
// .nav-wrapper form {
//   min-height: $ribbon-height;
// }

div[slot="ribbon-content"] {
  //   padding-right: 10px;
  //   padding-left: 10px;
  //   div.row {
  //     margin-top: 15px;
  //   }
  //   div.row,
  //   div.row > div {
  //     list-style-type: none;
  //   }
  //   div.row > div {
  //     display: inline-block;
  //     margin-left: 10px;
  //   }
  //   div.row > div:first-child {
  //     margin-left: 0;
  //   }
  //   div.row > div:last-child {
  //     margin-right: 0;
  //   }
  .page-title {
    font-weight: 300;
    font-size: 40px !important;
  }
  //   div.row a.btn-floating {
  //     margin-left: 10px;
  //   }
}

// /* Dans une boite de dialogue: masquer les éléments du ribbon
//    qui sont destinés uniquement à être affichés dans le header */
// ux-dialog div[slot="ribbon-content"] {
//   display: none;
// }
