@import "../css/scss/variables";

ul.navigation-detail {
  display: flex;
  padding-left: 34px !important;
  color: $gray-light;
  border-bottom: 1px solid $purple-lighter;
  margin: 0 !important;
  > li {
    padding: 15px 20px;
    &:not(.disabled):hover {
      color: $black-100;
    }
    &.disabled {
      background-color: unset;
    }
  }
  & > li.active {
    color: $black-100;
    border-bottom: solid 1px $blue-mid;
  }
  ~ ui-field {
    width: 150px !important;
    margin-left: 40px;
    position: absolute;
    border-right: 1px solid $gray-very-light;
    padding-right: 5px;
    padding: 0;
    > ui-select {
      border: none;
      padding-bottom: 0;
    }
  }
}

.project-logo {
  position: absolute;
  top: 85px;
  right: 80px;
  z-index: 1;
  padding: 10px;
  width: 120px;
  height: 120px;
}
