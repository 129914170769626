import { HttpClient } from 'aurelia-fetch-client';
import { Merlin } from "./../generated";
import * as Constants from '../constants';
import moment = require('moment');
import { Container } from 'aurelia-dependency-injection';

export class ItemPrice {

  public static getPrice(item: Merlin.Web.Model.Item, proposedQuantity: number, unitId: number = null) {
    if (item.itemTypeId == Constants.ItemType.Composed) {
      let composition = item.itemCompositions.filter(x => x.fromQuantity <= proposedQuantity).sort((a, b) => b.fromQuantity - a.fromQuantity);
      if (composition != null && composition.length > 0) {
        return composition[0];
      }
    } else {
      let itemPrices = item.itemPrices.sort((a, b) => a.fromQuantity < b.fromQuantity ? 1 : -1);

      itemPrices = itemPrices.filter(x => x.fromQuantity <= proposedQuantity);

      if (unitId != null) {
        itemPrices = itemPrices.filter(x => x.unitId == unitId);
      }
      if (itemPrices != null && itemPrices.length > 0) {
        return itemPrices[0];
      }
      return null;
    }
  }

  public static getCurrentPriceFromDate(itemPrice: Merlin.Web.Model.ItemPrice, acceptFutur: boolean = false) {
    let today = new Date();
    let priceFromDates = itemPrice.itemPriceFromDates.filter(x => moment(x.fromDate).isSameOrBefore(today, 'day'));
    priceFromDates = priceFromDates.sort((a, b) => a.fromDate < b.fromDate ? 1 : -1);
    if (acceptFutur && priceFromDates.length == 0 && itemPrice.itemPriceFromDates.length > 0) {
      priceFromDates.push(itemPrice.itemPriceFromDates.sort((a, b) => moment(a.fromDate).isSameOrAfter(b.fromDate, 'day') ? 1 : -1)[0]);
    }
    if (priceFromDates.length > 0) {
      return priceFromDates[0];
    }
    return null;
  }

  public static async getMarginCoef(buyingPrice: number = 0, sellingPrice: number = 0): Promise<number> {
    let requestBody = {
      buyingPrice: buyingPrice,
      sellingPrice: sellingPrice
    } as Merlin.Web.Model.PriceComputeRequest;

    let httpClient = Container.instance.get(HttpClient);
    let response = await httpClient.post(Constants.Application.PricesServiceController.ComputePrices, JSON.stringify(requestBody));
    if (response.ok) {
      let result: Merlin.Web.Model.PriceComputeRequest = await response.json();
      return result.margin;
    } else {
      return 1;
    }
  }

  public static async getSellingPrice(buyingPrice: number = 0, marginCoeff: number = 0): Promise<number> {
    let requestBody = {
      buyingPrice: buyingPrice,
      margin: marginCoeff
    } as Merlin.Web.Model.PriceComputeRequest;

    let httpClient = Container.instance.get(HttpClient);
    let response = await httpClient.post(Constants.Application.PricesServiceController.ComputePrices, JSON.stringify(requestBody));
    if (response.ok) {
      let result: Merlin.Web.Model.PriceComputeRequest = await response.json();
      return result.sellingPrice;
    } else {
      return 0;
    }
  }

}
