import { autoinject } from 'aurelia-framework';
import { RouteConfig } from 'aurelia-router';
import { AuthService, DataGridSettings, Datacontext, UserProfile } from 'digiwall-lib';

@autoinject
export class UserSettingService {
  userProfile: UserProfile;
  userSetting: MerlinUserSettings;

  constructor(private datacontext: Datacontext, private authService: AuthService) {

  }

  async getUserSetting(): Promise<MerlinUserSettings> {
    this.userProfile = await this.datacontext.getCurrentUserProfile();
    this.userSetting = this.userProfile.settings as MerlinUserSettings;
    if (this.userSetting.customSettings == null) {
      this.userSetting.customSettings = this.intiCustomSetting();
      await this.datacontext.saveUserSettings(this.userProfile);
    }
    return this.userSetting;
  }

  intiCustomSetting(): MerlinSettings {
    return {
      taskSetting: {
        filterTaskSelected: FilterTask.myTask,
        fullScreen: true,
        showClassicList: false
      } as TaskSettings
    } as MerlinSettings;
  }
  async save() {
    await this.datacontext.saveUserSettings(this.userProfile);
    this.authService.currentUser = this.userProfile;
  }
  async setTaskSortBy(value: string) {
    this.userSetting.customSettings.taskSetting.taskSortBy = value;
    await this.save();
  }
  async setfilterTaskSelected(number: FilterTask) {
    this.userSetting.customSettings.taskSetting.filterTaskSelected = number;
    await this.save();
  }

  async setTodoSelected(value: boolean) {
    this.userSetting.customSettings.taskSetting.toDoSelected = value;
    await this.save();

  }
  async setInProcessSelected(value: boolean) {
    this.userSetting.customSettings.taskSetting.inProcessSelected = value;
    await this.save();

  }
  async setDoneSelected(value: boolean) {
    this.userSetting.customSettings.taskSetting.doneSelected = value
    await this.save();

  }
  async setCancelSelected(value: boolean) {
    this.userSetting.customSettings.taskSetting.cancelSelected = value;
    await this.save();
  }
  async setFullScreen(value: boolean) {
    this.userSetting.customSettings.taskSetting.fullScreen = value;
    await this.save();
  }
  async setShowClassicList(value: boolean) {
    this.userSetting.customSettings.taskSetting.showClassicList = value;
    await this.save();
  }

}

export interface MerlinUserSettings {
  customSearchList?: RouteConfig[];
  dataGridSettings?: DataGridSettings;
  customSettings?: MerlinSettings;
}

export interface MerlinSettings {
  taskSetting: TaskSettings;
}

export interface TaskSettings {
  filterTaskSelected: FilterTask;
  toDoSelected: boolean;
  inProcessSelected: boolean;
  doneSelected: boolean;
  cancelSelected: boolean;
  fullScreen: boolean;
  showClassicList: boolean
  taskSortBy: string;
}

export enum FilterTask {
  myTask,
  assignedTask,
  allTask
}

export enum FilterContract {
  signedContract = 1,
  proposedContract
}
