import { HttpClient } from 'aurelia-fetch-client';
import { ValidationRules } from 'aurelia-validation';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, IMenuGroup, IFile, FileFlow, UIInternal } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import Cropper from 'cropperjs';
import { IPlaceholderItem } from 'services/email-template-api-service';
import Tribute, { TributeItem } from "tributejs";

@autoinject
export class ApplicationParameterDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ApplicationParameter> {
  public ressourceName: string = Constants.EntityTypeNames.ApplicationParameter;
  private merlinUserService: ServiceBase<Merlin.Web.Model.MerlinUser>;
  private menuItems: Array<IMenuGroup>;
  public cropper: Cropper;
  private cropperActive: boolean = false;
  private editingLogoUrl: string

  private clientColumns: Merlin.Web.Model.ApplicationParameterProgressStatementDocument[];
  private subContractorColumns: Merlin.Web.Model.ApplicationParameterProgressStatementDocument[];
  private emailTokenService: ServiceBase<Merlin.Web.Model.MergeContentToken>;

  private months: Array<{ id: number, label: string }>;

  private inputNumInvoice: HTMLElement;
  private inputNumInvoiceTributeInstance: Tribute<any>;

  private inputNumCreditNote: HTMLElement;
  private inputNumCreditNoteTributeInstance: Tribute<any>;

  private inputProgressStatementLabelForInvoice: HTMLElement;
  private inputProgressStatementLabelForInvoiceTributeInstance: Tribute<any>;

  private inputProgressStatementLabelForBillingVoucher: HTMLElement;
  private inputProgressStatementLabelForBillingVoucherTributeInstance: Tribute<any>;

  private unitService: ServiceBase<Merlin.Web.Model.Unit>;

  public files: IFile[] = [];
  private currentFile: IFile;

  private columnsInfo: Array<{ columnName: string, columnVisibility: boolean }> = [
    { columnName: 'description', columnVisibility: false },
    { columnName: 'fileTypeId', columnVisibility: false },
    { columnName: 'size', columnVisibility: false },
  ]

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient) {
    super(router, logger);
    this.merlinUserService = new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser);
    this.emailTokenService = new ServiceBase<Merlin.Web.Model.MergeContentToken>(Constants.EntityTypeNames.MergeContentToken);
    this.unitService = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    super.initialize(new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter));
  }

  public documentTitle: string = this.i18n.tr("applicationparameters.applicationparameters");

  public get ribbonHeaderText() {
    return this.i18n.tr("applicationparameters.applicationparameters")
  }

  public async activate(params: any) {
    let id: number = params.param1;
    await super.activate(params);
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id, 'progressStatementDocumentColumns.progressStatementQuantityType', 'defaultUnitForProgressStatement');
    this.controller.addObject(this.entity);
    this.loadGeneralConditionsFile();

    this.clientColumns = this.entity.progressStatementDocumentColumns.filter(x => x.progressStatementType == ProgressStatementType.Client);
    this.subContractorColumns = this.entity.progressStatementDocumentColumns.filter(x => x.progressStatementType == ProgressStatementType.SubContractor);

    ValidationRules.customRule('validation-repartition-step', (value, obj) => {
      return (100 % value === 0);
    }, 'applicationparameters.priceOfferLineDefaultPriceRepartitionStepError');

    ValidationRules
      .ensure('priceOfferLineDefaultPriceRepartitionStep')
      .satisfiesRule('validation-repartition-step')
      .on(this.entity);

    this.initMonths();
    UIInternal.queueTask(async () => {
      await this.initTributes();
    });
  }

  loadGeneralConditionsFile() {
    if (this.entity.generalConditionsFileUrl?.trim().length > 0) {
      this.currentFile = { name: this.entity.generalConditionsFileName, url: this.entity.generalConditionsFileUrl, fileTypeId: null, description: null, size: null } as IFile;
      this.files.push(this.currentFile);
    }
  }

  async uploadedFile(file: FileFlow) {
    this.currentFile = { name: file.name, url: file.url, fileTypeId: null, description: null, size: null } as IFile;
    this.entity.generalConditionsFileName = file.name;
    this.entity.generalConditionsFileUrl = file.url;
    await this.saveCurrentEntity(true);
    this.files.splice(0);
    this.files.push(this.currentFile);
  }

  async deleteFile() {
    this.entity.generalConditionsFileName = null;
    this.entity.generalConditionsFileUrl = null;
    this.saveCurrentEntity(true);
    this.files.splice(0);
    this.currentFile = null;
  }

  public downloadFile() {
    this.router.navigate(this.entity.generalConditionsFileUrl, { replace: false });
  }

  public getFileMenuItem(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.deleteFile();
            }
          },
          {
            label: this.i18n.tr("menu.download"),
            icon: "digi-download",
            handler: () => {
              this.downloadFile();
            }
          }
        ]
      }
    ];
  }

  private initMonths() {
    this.months = [
      {
        id: Month.January,
        label: this.i18n.tr("month.january")
      },
      {
        id: Month.February,
        label: this.i18n.tr("month.february")
      },
      {
        id: Month.March,
        label: this.i18n.tr("month.march")
      },
      {
        id: Month.April,
        label: this.i18n.tr("month.april")
      },
      {
        id: Month.May,
        label: this.i18n.tr("month.may")
      },
      {
        id: Month.June,
        label: this.i18n.tr("month.june")
      },
      {
        id: Month.July,
        label: this.i18n.tr("month.july")
      },
      {
        id: Month.August,
        label: this.i18n.tr("month.august")
      },
      {
        id: Month.September,
        label: this.i18n.tr("month.september")
      },
      {
        id: Month.October,
        label: this.i18n.tr("month.october")
      },
      {
        id: Month.November,
        label: this.i18n.tr("month.november")
      },
      {
        id: Month.December,
        label: this.i18n.tr("month.december")
      },
    ];
  }

  private async initTributes() {
    await this.initTributeForInput(this.inputNumInvoice, this.inputNumInvoiceTributeInstance, { isInvoice: true });
    await this.initTributeForInput(this.inputNumCreditNote, this.inputNumCreditNoteTributeInstance, { isInvoice: true });
    await this.initTributeForInput(this.inputProgressStatementLabelForInvoice.childNodes.item(7).childNodes.item(1) as HTMLElement, this.inputProgressStatementLabelForInvoiceTributeInstance, { isInvoice: true, forProgressStatement: true });
    await this.initTributeForInput(this.inputProgressStatementLabelForBillingVoucher.childNodes.item(7).childNodes.item(1) as HTMLElement, this.inputProgressStatementLabelForBillingVoucherTributeInstance, { isInvoice: true, forProgressStatement: true });
  }

  private async initTributeForInput(input: HTMLElement, tributes: Tribute<any>, params: any) {
    if (input?.firstElementChild == null) return;
    if (tributes != null) {
      tributes.detach(input.firstElementChild);
    }
    let vals = await this.getTokens(params);
    tributes = new Tribute({
      trigger: '@',
      values: (text, cb) => {
        cb(vals.map(x => {
          return { key: x.id, value: x }
        }));
      },
      selectTemplate: (item) => {
        return item.original.value.value;
      },
      noMatchTemplate: () => '',
      itemClass: "ql-mention-list-item",
      menuItemTemplate: (item) => {
        return '<span class="placeholder-name">' + item.original.value.label + '</span> <span class="placeholder-key">' + item.original.value.value + "</span>"
      }
    });

    tributes.attach(input.firstElementChild);
  }

  private async getTokens(params: any): Promise<Array<IPlaceholderItem>> {
    return (await this.emailTokenService.getEntities(null, null, params)).map((i, index) => {
      return {
        id: (index + 1).toString(),
        value: `${i.tokenName}`,
        label: i.tokenDescription._translation
      } as IPlaceholderItem
    });
  }
}

export enum ProgressStatementType {
  Client = "Client",
  SubContractor = "SubContractor"
}

export enum Month {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December
}
