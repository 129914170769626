import { bindable, autoinject, customElement } from 'aurelia-framework';
import { Router, RouteConfig } from 'aurelia-router';
import { Datacontext, Box } from 'digiwall-lib';

@autoinject
@customElement('menu-headline')
export class MenuHeadline {
  @bindable routes: RouteConfig[];
  @bindable icon: string;
  constructor(private router: Router, private context: Datacontext, private box: Box) {
  }
  activateCurrentMenu(r: RouteConfig, isActive: boolean) {
    if (r.settings &&
      r.settings.param1 == '-100' &&
      this.router &&
      this.router.currentInstruction &&
      this.router.currentInstruction.params.param1 != '-100') return '';

    return isActive ? 'active' : '';
  }
}
