import { MerlinUserApiService } from '../../../services/merlin-user-api-service';
import { RowNode } from 'ag-grid-community';
import { I18N } from 'aurelia-i18n';
import { autoinject, bindable, observable, TaskQueue } from "aurelia-framework";
import { AuthService, IMenuGroup } from "digiwall-lib";
import IPriceOfferLineCommentApiService, { PriceOfferLineCommentApiService } from '../../../services/price-offer-line-comment-api-service';
import { Merlin } from './../../../generated.d';
import moment = require('moment');


@autoinject
export class AgCellCommentBox {
  @bindable public guid: string;
  @bindable public colField: string;
  @bindable public rowNode: RowNode;
  @bindable
  public api: IPriceOfferLineCommentApiService;

  @bindable public onSave = () => { };
  @bindable public onCancel = () => { };
  @bindable public onEdit = () => { };
  @bindable public onDelete = () => { };
  @bindable public onClose = () => { };

  @bindable private debug: boolean;

  @bindable public readonly: boolean = false;

  private commentValue: string = "";
  private commentValueBkp: string;
  private menuItems: Array<IMenuGroup>;
  private isEditing = false;
  private isSaving = false;
  private dto: Merlin.Web.Model.PriceOfferLineCommentDTO;
  private userList: Array<Merlin.Web.Model.MerlinUser> = [];

  @observable
  private lastUser: Merlin.Web.Model.MerlinUser;
  private textarea: Element;// view-ref

  constructor(private viewEl: Element, private i18n: I18N, private taskQueue: TaskQueue, private authService: AuthService, private apiUser: MerlinUserApiService) {
    this.setMenuItems();

  }

  async attached() {
    this.userList = await this.apiUser.GetAllEntitiesFromMemory();
    this.viewEl.id = this.guid;
    this.commentValueBkp = this.commentValue + "";
    this.isEditing = false;
    this.isSaving = false;

    await this.getComment();
  }

  detached() {
    if (this.debug) console.log("AgCellCommentBox : detached");
    if (this.isEditing && !this.isSaving)
      this.commentValue = this.commentValueBkp + "";
    if (this.dto?.id == null) this.dto = null;
  }

  /**
   * Set menus items.
   *
   * @private
   * @memberof AgCellCommentBox
   */
  private setMenuItems(): void {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.edit"),
            icon: "digi-pencil",
            handler: () => {
              this.onClickEdit();
            }
          },
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.onClickDelete();
            }
          }
        ]
      }
    ];
  }

  /**
   * Find the current comment based on rowNode.data.Id and colField, create it ortherwise.
   *
   * @private
   * @return {*}  {Promise<void>}
   * @memberof AgCellCommentBox
   */
  private async getComment(): Promise<void> {
    this.isSaving = true;
    let dtos = await this.api.get(this.rowNode.data, this.colField);
    if (dtos != null && dtos.length > 0) this.dto = dtos[0];

    if (this.dto == null) {
      this.dto = {
        id: null,
        priceOfferLineId: this.rowNode.data.id,
        commentMultiline: "",
        fieldId: this.colField,
        lastModification: moment(moment.now()).toDate(),
        merlinUserId: this.authService.currentUser.id
      } as Merlin.Web.Model.PriceOfferLineCommentDTO;
      this.isEditing = true;
      this.lastUser = this.userList.find(u => u.id == this.authService.currentUser.id);
      this.textareaFocus();
    } else {
      this.isEditing = false;

      if (this.dto.merlinUserId != null)
        this.lastUser = this.userList.find(u => u.id == this.dto.merlinUserId);
    }

    this.commentValue = this.dto.commentMultiline + "";
    this.commentValueBkp = this.commentValue + "";
    this.isSaving = false;
    return;
  }

  /**
   * Focus the textarea
   *
   * @private
   * @memberof AgCellCommentBox
   */
  private textareaFocus(): void {
    this.taskQueue.queueTask(() => {
      if (this.textarea?.getElementsByTagName != null) {
        let ta = this.textarea.getElementsByTagName('textarea');
        if (ta.length && ta[0]?.focus != null) ta[0].focus();
      }
    });
  }

  /**
   *
   *
   * @private
   * @return {*} 
   * @memberof AgCellCommentBox
   */
  private async onClickSave() {
    if (this.commentValue == this.commentValueBkp) {
      this.onClickCancel();
      return;
    };

    this.isSaving = true;
    this.dto.commentMultiline = this.commentValue;

    await this.api.createOrUpdate(this.rowNode.data.id, this.dto);
    await this.getComment();

    this.isSaving = false;
    this.isEditing = false;
    this.commentValueBkp = this.commentValue + "";
    if (this.debug) console.log('AgCellCommentBox : save', this.onSave);
    if (typeof this.onSave == 'function') this.onSave();
  }

  /**
   *
   *
   * @private
   * @memberof AgCellCommentBox
   */
  private onClickCancel() {
    this.commentValue = this.commentValueBkp + "";
    this.isEditing = false;
    if (this.debug) console.log('AgCellCommentBox : cancel', this.onCancel);
    if (typeof this.onCancel == 'function') this.onCancel();

    // On creation of a comment, close the box if cancel
    if (this.dto?.id == null && typeof this.onClose == 'function') {
      this.onClose();
      this.dto = null;
    }
  }

  /**
   *
   *
   * @private
   * @memberof AgCellCommentBox
   */
  private onClickEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.textareaFocus();
    }

    if (this.debug) console.log('AgCellCommentBox : edit', this.onEdit);
    if (typeof this.onEdit == 'function') this.onEdit();
  }

  /**
   *
   *
   * @private
   * @memberof AgCellCommentBox
   */
  private onClickDelete() {
    this.isEditing = false;
    this.isSaving = true;
    this.api.delete(this.dto.id).then((value: boolean) => {
      if (value) {
        if (this.debug) console.log('AgCellCommentBox : onDelete', this.onDelete);
        if (typeof this.onDelete == 'function') this.onDelete();
        this.dto = this.commentValueBkp = this.commentValue = null;
      }
      this.isSaving = false;
    });
  }


}
