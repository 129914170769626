import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Various, IMenuGroup } from 'digiwall-lib';
import { autoinject, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { Merlin } from 'generated';

@autoinject
export class VATLevelDetail extends EntityDetailViewModelBase<Merlin.Web.Model.VATLevel> {
  public ressourceName: string = Constants.EntityTypeNames.VATLevel;
  private menuItems: Array<IMenuGroup>;
  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.VATLevel>(Constants.EntityTypeNames.VATLevel));
  }

  @computedFrom('editingMode', 'entity.code')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("vatlevel.addVatlevel");
    }
    else {
      return this.entity.code;
    }
  }

  public async activate(params: any) {
    let id = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      if (params.callback != null) params.callback(this.entity);
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
      this.controller.addObject(this.entity);
    }
    this.setMenuItems();
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      },
    ];
  }
}
