@import "../../css/scss/variables";
.theme-light ui-rating {
  height: 0;
  display: block;
  .rate {
    display: inline-block;
    border: 0;
    position: relative;
    padding-right: 20px;
    /* Hide radio */
    > input {
      display: none;
    }
    /* Order correctly by floating highest to the right */
    > label {
      float: right;
      color: $gray-lighter;
    }
    /* The star of the show */
    > label:before {
      display: inline-block;
      font-size: 1.5rem;
      padding: 0.3rem 0rem;
      margin: 0;
      cursor: pointer;
      font-family: "Merlin-Icons" !important;
      content: "\ee27 "; /* full star */
    }
    /* Half star trick */
    .half:before {
      content: "\ee2d "; /* half star no outline */
      position: absolute;
      padding-left: 0.4rem;
      left: -6px;
    }
    /* Click + hover color */
    input:checked ~ label, /* color current and previous stars on checked */
    label:hover, label:hover ~ label {
      color: #f1e20a;
    } /* color previous stars on hover */

    /* Hover highlights */
    input:checked + label:hover, input:checked ~ label:hover, /* highlight current and previous stars */
    input:checked ~ label:hover ~ label, /* highlight previous selected stars for new rating */
    label:hover ~ input:checked ~ label /* highlight previous selected stars */ {
      color: #d8b30d;
    }
  }
}
