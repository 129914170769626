ui-table {
  > .ui-table {
    .ui-table__header__cell {
      > div > .ui-table__header__cell__label {
        color: $blue-mid;
        font-weight: 400;
        text-transform: none;
      }
    }
    > thead > tr > .ui-table__header__cell__checkable {
      ui-checkbox > .ui-option__control > input[data-checked="__2__"] ~ .ui-icon:nth-of-type(2) {
        font-size: 1rem;
      }
    }

    > tbody > tr {
      > td {
        min-width: 50px;
        ui-field {
          padding: 0;
          .ui-select {
            margin-bottom: 0 !important;
            > .select2 > .selection > .select2-selection {
              align-items: center;
              .select2-selection__rendered {
                height: fit-content !important;
              }
            }
          }
        }
        .ui-input--invalid {
          margin-bottom: 0;
          background-color: $red-light !important;
          border: none;
          .ui-input__error {
            display: none;
          }
        }
        .ui-input--disabled {
          border: none !important;
        }
        > a {
          height: 100%;
          width: 100%;
          line-height: 50px;
          display: flex !important;
          align-items: center !important;

          > ui-button > .ui-btn__inner > .ui-btn > .ui-btn__icon > .ui-icon.digi-more-line,
          ui-button > .ui-btn__inner > .ui-btn > .ui-btn__icon > .ui-icon.digi-more-2-line {
            display: none;
          }
        }
        > div {
          height: 50px;
          display: flex;
          align-items: center;
          > div {
            width: 100%;
            padding: 0;
            > ui-input {
              padding: 0 15px;
              height: 50px;
              border: none;
              border-radius: 0;
            }
            // .ui-input--invalid {
            //   background-color: $red-light;
            // }
          }
          ui-button > .ui-btn__inner > .ui-btn > .ui-btn__icon > .ui-icon.digi-more-2-line,
          ui-button > .ui-btn__inner > .ui-btn > .ui-btn__icon > .ui-icon.digi-more-line {
            display: none;
          }
        }
        &:first-child {
          > div,
          a {
            width: 35px;
            &:hover {
              text-decoration: none;
            }
            > ui-button {
              width: 35px;
              height: 35px;
              border: none !important;
              &:hover {
                background-color: $purple-lightest !important;
              }
              &:focus-within {
                background-color: transparent;
              }
              .ui-btn__inner {
                color: $blue-darkest !important;
                &:hover {
                  color: $primary !important;
                }
                .ui-btn {
                  padding: 0;
                  &:active {
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
      &:hover {
        td > div > ui-button > .ui-btn__inner > .ui-btn > .ui-btn__icon > .ui-icon.digi-more-2-line,
        td > div > ui-button > .ui-btn__inner > .ui-btn > .ui-btn__icon > .ui-icon.digi-more-line,
        td > a > ui-button > .ui-btn__inner > .ui-btn > .ui-btn__icon > .ui-icon.digi-more-2-line,
        td > a > ui-button > .ui-btn__inner > .ui-btn > .ui-btn__icon > .ui-icon.digi-more-line {
          display: block;
        }
      }
    }
  }
}

.theme-light .ui-btn[data-active="true"],
.theme-light .ui-btn[data-open="true"],
.theme-light .ui-btn:active {
  box-shadow: none !important;
}

.ui-table__header {
  background-color: transparent !important;
  border-color: $gray-very-light !important;
  padding: 0;
  > div {
    padding: 15px;

    > span {
      color: $blue-mid;
      font-weight: 400;
      font-size: 13px;
      text-transform: none;
    }
  }
}

.theme-light .ui-table__cell {
  padding: 0;
  height: 50px;
  overflow: hidden !important;
  > div {
    > ui-field {
      .ui-select {
        border: none;
        height: 50px;
        padding: 0 15px;
        border-radius: 0;
        .select2-container {
          height: 50px;
        }
        > span > span > span {
          display: flex;
          .select2-selection__rendered {
            padding: 0 !important;
            height: 50px !important;

            .select2-selection__placeholder {
              font-size: 14px;
            }
          }

          .select2-selection__arrow {
            height: 50px !important;
            display: grid;
            position: absolute;
            top: 3px;
            right: 1px;
            margin-top: -2px;
            > b {
              width: 8px;
              height: 8px;
              transform: rotate(45deg);
              border: none;
              border-bottom: solid 2px $blue-darkest;
              border-right: solid 2px $blue-darkest;
            }
          }
        }
      }
      .ui-input--invalid {
        background-color: $red-light;
        > span {
          .select2-selection__placeholder {
            color: $red-warning;
          }
        }
        > span > span > span > .select2-selection__arrow > b {
          border-color: rgba(255, 0, 0, 1) transparent transparent !important;
        }
      }

      .ui-input__valid {
        color: $green-valid !important;
        font-size: 22px;
        margin-left: 5px;
      }
    }
    > span {
      padding: 0 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    > ui-button {
      &:hover,
      &:active {
        background-color: $purple-lighter;
        border-radius: 50px;
        .ui-btn__inner {
          color: $blue-mid;
        }
      }
      .ui-btn__inner {
        box-shadow: none !important;
        .ui-btn {
          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            box-shadow: none;
          }
        }
      }
    }
    ui-select > .select2 > .selection {
      display: grid;
      align-items: center;
      height: 100%;
    }
  }
}

.theme-light .ui-table__cell,
.theme-light .ui-table__header__cell {
  border-bottom: none;
}

.ui-table.ui-table-with-border {
  border-collapse: separate;
  > thead > tr {
    border-bottom: none;
    > th {
      border-bottom: none;
      &:first-child {
        border-bottom: none;
        box-shadow: none;
      }
    }
  }
  > tbody > tr {
    border-bottom: none;
    &:nth-last-child(1) {
      .ui-table__cell {
        border-bottom: solid 1px $gray-very-light !important;
        &:first-child {
          border-bottom: none !important;
        }
      }
    }
    .ui-table__cell {
      border-bottom: none;
      border-right: none;
      &:first-child {
        border: none;
        > a > ui-button {
          > .ui-btn__inner {
            box-shadow: none;
            > .ui-btn {
              &:focus-within {
                background-color: transparent !important;
              }
              &:hover {
                background-color: transparent !important;
              }
              &:active {
                background-color: transparent !important;
                box-shadow: none !important;
              }
            }
          }
          &:hover,
          &:active {
            background: $purple-lighter !important;
            border-radius: 60px;
            .ui-btn__inner > .ui-btn {
              color: $blue-mid;
            }
          }
        }
      }
      > a > span {
        padding: 0 10px;
      }
      &:last-child {
        border-right: solid 1px $gray-very-light !important;
      }
      > div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}

ui-table.ui-table > tbody > tr {
  td {
    &:first-child {
      > div {
        width: 40px;
        > ui-button {
          width: 35px;
        }
      }
    }
  }
}

ui-table.ui-table-without-first-button {
  > table.ui-table.ui-table-with-border {
    > tbody {
      > tr {
        &:nth-child(2) {
          > td.ui-table__cell:first-child {
            border-top: 1px solid $gray-very-light !important;
          }
        }
        > td.ui-table__cell:first-child {
          border-left: 1px solid $gray-very-light;
          border-bottom: 1px solid $gray-very-light !important;
        }
      }
    }
  }
}
