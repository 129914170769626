import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, IAnchorTab, ServiceBase, Various } from 'digiwall-lib';
import * as Constants from '../constants';
import { Merlin } from 'generated';

@autoinject
export class BankAccountDetail extends EntityDetailViewModelBase<Merlin.Web.Model.BankAccount> {
  public ressourceName: string = Constants.EntityTypeNames.BankAccount;

  public get documentTitle() {
    return this.entity.iBAN;
  }

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.BankAccount>(Constants.EntityTypeNames.BankAccount));
  }

  public async activate(params: any) {
    super.activate(params);
    let id: number = params.param1;

    if (id != Various.NewId) {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
    }
    this.setTabList();
  }
  public tabList: Array<IAnchorTab>;
  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: "tab1",
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      }
    )
  }
}
