import { inject, noView, ViewCompiler, ViewSlot, Container, ViewResources, bindable, autoinject, View, observable, containerless  } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';

@noView
@autoinject
@containerless
export class InlineView {

    @bindable
    @observable
    content = null;
    test: any;
    contentChanged(newVal, oldVal) {
        this.generateRibbon();
    }
    contextChanged(newVal, oldVal) {
        this.generateRibbon();
    }
    @bindable context = null;      
      
    constructor(private viewCompiler: ViewCompiler, private viewSlot: ViewSlot, private container: Container, private viewResources: ViewResources) {
    }
    bindingContext;
    attached() {
        // Compile an html template, dom fragment or string into ViewFactory instance, capable of instantiating Views.        
    }

    bind(bindingContext) {
        this.bindingContext = bindingContext;
    }

    generateRibbon() {
        var viewFactory = this.viewCompiler.compile('<template><span class="app-bar-element">${ribbonHeaderText}</span>' + this.content + '</template>', this.viewResources);

        // Creates a view or returns one from the internal cache, if available
        var view = viewFactory.create(this.container);

        // Bind the view and it's children
        view.bind(this.context);
        this.test = view.fragment;

        // Add the view to the slot
        this.viewSlot.removeAll();
        this.viewSlot.add(view);

        // Trigger the attach for the slot and its children.
        this.viewSlot.attached();
    }
}