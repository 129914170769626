@import "../css/scss/variables";

.document-list {
  > div.document-body {
    margin-left: 40px;
    display: grid;
    grid-template-columns: 200px auto;
    overflow: hidden;

    .document-annex ui-toggle label {
      padding-top: 8px;
      > span {
        font-size: 14px;
      }
      > span:first-child {
        display: none;
      }
    }

    .document-visiblefromlancelot ui-toggle label {
      padding-top: 8px;
      > span {
        font-size: 14px;
      }
      > span:first-child {
        display: none;
      }
    }

    > div.projectPhase {
      > div {
        padding: 15px 10px;
        border-bottom: 1px solid $gray-very-light;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        &.selected {
          border-left: 4px solid $blue-mid;
        }
      }
    }
    > div:not(:first-child) {
      display: grid;
      grid-template-rows: 55px auto;
      grid-template-areas: "summary" "ag-grid";
      > summary-filter {
        grid-area: summary;
      }
      > ag-grid-aurelia {
        grid-area: ag-grid;
      }
    }
  }
  .grid-footer-bar {
    display: flex;
  }
}

ux-dialog {
  > ux-dialog-header.document-dialog {
    padding: 10px 15px 0px 15px;
    .dialog-header-content {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
