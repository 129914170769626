import { HttpClient } from 'aurelia-fetch-client';
import { ColDef } from 'ag-grid-community';
import { Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, FieldType, IMenuItems, ListViewModelBase } from "digiwall-lib"
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class BankAccountList extends ListViewModelBase<Merlin.Web.Model.BankAccount> {
  public ressourceName: string = Constants.EntityTypeNames.Document;

  private menuItems: Array<IMenuItems>;


  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.BankAccount>(Constants.EntityTypeNames.BankAccount));

    this.setMenuItems();
  }

  public getDetailsUrl(self, entity: Merlin.Web.Model.BankAccount) {
    if (entity && entity.id) {
      var url = '/bankaccounts/' + entity.id;
      return url;
    }
    return null;
  }
  public async syncBankAccounts() {
    let result = await this.httpClient.post(Constants.Application.PontoController.SyncBankAccounts)
    if (result.ok) {
      this.gridOptions.api.onFilterChanged();
    }

  }
  public attached() {
    const channel = new BroadcastChannel('arthur-channel');
    channel.onmessage = async (msg) => {
      if (msg.data.pontoLinkedSuccessfuly === true || msg.data === true) {
        this.box.showNotification('bankaccount.pontoConnected', 'Ponto');
        await this.syncBankAccounts();
        this.logger.Log('bankaccount.accountSynchronized', null, null, true);
      }
      else {
        this.box.showError('bankaccount.pontoError', 'Ponto');
      }
    };
    return super.attached();
  }
  public async onboard() {
    let result = await this.httpClient.get(Constants.Application.PontoController.Onboarding)

    if (result.ok) {
      var content = await result.text();
      if (content) {
        window.open(content, 'blank');
      } else {
        this.box.showError('bankaccount.pontoNotEnabled', 'Ponto');
      }
    }

  }
  public getDataGridColumns() {
    super.getDataGridColumns();
    let defs: ColDef[] = [
      {
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererParams: {
          i18n: this.i18n,
          gridOptions: (this as any).gridOptions,
          router: this.router,
          service: this.service,
          pathDetail: (this as any).pathDetail,
          listViewModel: this,
          menuItems: this.getMenuItems,
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        sortable: false,
        resizable: false,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        pinned: "left",
      },
      new GridHelperMerlin().selectedColDef(this),
      {
        headerName: '',
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        sortable: false,
        resizable: false,
        filter: false,
        field: "logoUri",
        type: FieldType.Html,
        valueGetter: (value) => {
          const uri = value?.data?.logoUri;
          if (uri == null) return null;
          return `<img style='max-height: 100%; vertical-align: middle' src='${uri}'></img>`;
        }
      },
      {
        headerName: 'BIC',
        field: "bIC",
        type: FieldType.String
      },
      {
        headerName: 'IBAN',
        field: "iBAN",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("bankaccount.institutionName"),
        field: "institutionName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("bankaccount.currentBalance"),
        field: "currentBalance",
        type: FieldType.Currency
      }
    ];
    return defs;
  }

  getMenuItems() {
    return this.menuItems;
  }

  private setMenuItems(): void {
    this.menuItems = [
      {
        label: this.i18n.tr("bankaccount.connect"),
        icon: "mdi mdi-bank-plus",
        handler: () => {
          this.onboard();
        }
      }
    ];
  }
}
