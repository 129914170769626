import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Various, IMenuItems } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../../../generated";
import { ColDef, FilterManager, ICellRendererParams } from "ag-grid-community";
import * as Constants from '../../../constants';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import CustomButtonRenderer from 'resources/renderer/custom-button-renderer';


@autoinject
export class ProgressStatementSubContractorList extends ListViewModelBase<Merlin.Web.Model.ProgressStatementView> {
  constructor(router: Router, logger: CustomLogger) {

    super(router, logger, new ServiceBase<Merlin.Web.Model.ProgressStatementView>(Constants.EntityTypeNames.ProgressStatementView));
    this.service.gridDataSource.expands = ["subContractor", "status"];
  }

  async activate(params) {
    await super.activate(params);
    this.service.gridDataSource.queryParameters = { projectId: params.projectId, isSubcontractor: true };
  }

  public ressourceName: string = Constants.EntityTypeNames.ProgressStatementView;

  public getDetailsUrl(self, entity: Merlin.Web.Model.ProgressStatementView) {
    if (entity?.id) {
      return 'progress-statement-subcontractor/' + entity.id + "?subContractorId=" + entity.subContractorId;
    }
    return null;
  }

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...this.contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("progressstatement.numProgressStatement"),
        field: "numProgressStatement",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("progressstatement.acceptationDate"),
        field: "acceptationDate",
        type: FieldType.Date,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("progressstatement.periodStartDate"),
        field: "periodStartDate",
        type: FieldType.Date,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("progressstatement.periodEndDate"),
        field: "periodEndDate",
        type: FieldType.Date,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("progressstatement.isFinal"),
        field: "isFinal",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("progressstatement.invitationToInvoiceSentDate"),
        field: "invitationToInvoiceSentDate",
        type: FieldType.Date,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("progressstatement.subContractorId"),
        field: "subContractor.fullName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("progressstatement.statusId"),
        field: "status.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ProgressStatementVersionStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ProgressStatementVersionStatus,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("progressstatementversion.billingInvoiceId"),
        field: "invoiceNumber._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }

  public getMenuItems(params: ICellRendererParams): Array<IMenuItems> {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [{
          label: (params as any).i18n.tr("menu.open"),
          icon: "digi-open",
          handler: () => {
            let url = (params as any).pathDetail + params.data.id;
            if ((<any>params).router?.currentInstruction?.queryString?.length > 0) {
              url += '?' + (<any>params).router.currentInstruction.queryString;
            }
            (params as any).router.navigate(url);
          }
        }]
      },
      {
        label: (params as any).i18n.tr("menu.delete"),
        icon: "digi-delete-bin-line",
        handler: async () => {
          let result = await (params as any).service.deleteEntities([params.data], true);
          if (result) {
            (params as any).gridOptions.api.forEachNode((rowNode) => {
              if (rowNode.data != null)
                if (rowNode.data.isSelected == true)
                  rowNode.data.isSelected = false;
            });
            (params as any).gridOptions.api.refreshInfiniteCache();
          }
        },
        disabled: () => {
          return params.data.acceptationDate != null
        }
      }];
  }
  public contextMenuColdDef(parentThis: ListViewModelBase<Merlin.Web.Model.ProgressStatementView>): ColDef[] {
    (parentThis as any).gridOptions.components.customButtonRenderer = CustomButtonRenderer;
    let result: Array<ColDef> = [
      {
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererParams: {
          i18n: parentThis.i18n,
          gridOptions: (parentThis as any).gridOptions,
          router: parentThis.router,
          service: parentThis.service,
          pathDetail: (parentThis as any).pathDetail,
          menuItems: this.getMenuItems,
          disabledMenuItem: (parentThis as any).isSelectable
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        sortable: false,
        resizable: false,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        pinned: "left",
      }];
    result.push(new GridHelperMerlin().selectedColDef(parentThis));
    return result;

  }
} 
