import * as Constants from '../../constants';
import { RefToDisplay } from '../../constants';

export abstract class IListTreeDataGridService {
  abstract fetch(id: number, ids: number[], displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<Array<any>>;
  abstract children(id: number, lineId: number, displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<Array<any>>;
  abstract filter(id: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>>;
  abstract select(lineId: number, filterModel?: { [key: string]: any; });
  abstract unselect(lineId: number, filterModel?: { [key: string]: any; });
}
