import { PriceRepartitionService } from 'services/price-repartition-service';
import { Merlin } from 'generated';
import { HttpClient } from 'aurelia-fetch-client';
import { AgGridViewService, CustomLogger, EntityDetailViewModelBase, EnumerationType, IMenuGroup, IMenuItem, IMenuItems, ServiceBase, GlobalLoaderService, UIInternal, } from 'digiwall-lib';
import * as Constants from '../../constants';
import * as breeze from 'breeze-client';
import { Router } from 'aurelia-router';
import { IDataLineApiService, MeteringTotalPrices } from 'services/i-data-line-api-service';
import { AddWorkQuotationVersion } from 'work-quotation-versions/add-work-quotation-version';
import { EditWorkQuotationVersion } from 'work-quotation-versions/edit-work-quotation-version';
import { PriceOfferLineCommentApiService } from 'services/price-offer-line-comment-api-service';
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';
import { MeteringMenuItems } from 'resources/metering/metering-menu-items';
import { LINE_HAS_BEEN_ADDED } from './version-offer-overlay-comp';

export abstract class CommomMetering<T extends breeze.Entity> extends EntityDetailViewModelBase<T> {
  public workQuotationVersionId: string;
  public priceOfferVersionId: string;
  public projectId: string;

  public footer: MeteringTotalPrices;
  public screenExpand: boolean = false;
  public priceOfferLinesGrid: PriceOfferLinesGrid;
  public priceOfferLineTypeList: Array<EnumerationType>;
  public listIsFlat: boolean = false;
  public gridViewService: any;
  public projectService: ServiceBase<Merlin.Web.Model.Project>;

  async canDeactivate(): Promise<any> {
    this.hasChanges = false;
    await super.canDeactivate();
  }

  constructor(router: Router, logger: CustomLogger, protected httpClient: HttpClient, protected dataLineApiService: IDataLineApiService, protected globalLoaderService: GlobalLoaderService, protected priceOfferLineCommentApiService: PriceOfferLineCommentApiService, protected priceRepartitionService: PriceRepartitionService) {
    super(router, logger);
    this.gridViewService = new AgGridViewService();
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  async activate(params) {
    UIInternal.subscribe(LINE_HAS_BEEN_ADDED, async () => {
      await this.getMeteringTotalPrices()
    })
  }

  public priceOfferLinesGridCellValueChanged(colField: string) {
    if (colField == null || colField.trim().length == 0) return;
    switch (colField) {
      case "lineDescription":
      case "additionalDescriptionMultiline":
      case "quantityTypeId":
      case "unitId":
      case "activityCodeId":
      case "tags":
      case "priceOfferLineFileCount":
        return;// Don't getMeteringTotalPrices for these properties
    }

    this.getMeteringTotalPrices();
  }

  /**
   * Return true if the given cell field can be edited based on business rules (see #9666), false otherwise.
   *
   * @private
   * @param {*} line
   * @param {string} colField
   * @return {*}  {boolean}
   * @memberof CommomMetering
   */
  public canEditCell(line: { priceOfferLineCategoryId: number, hasChildren?: boolean, hasChildrenSubItem?: boolean, hasChildrenDetailed: boolean, useInCompleteness: boolean, isSubItem: boolean, isDisplayHidden: boolean, isDisplayHiddenInherit: boolean, lineTypeId: number }, colField: string): boolean {
    if (line == null || colField == null) return false;
    switch (colField) {
      case "quantityTypeId":// not "QuantityTypeTranslatedString":
      case "unitId":// not "UnitTranslatedString":
      case "unitQuantityFormulaDetailItemComposed":
      case "requestedQuantity":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && (line.useInCompleteness || line.hasChildrenSubItem)

      case "proposedQuantity":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && (line.useInCompleteness || line.hasChildrenSubItem) && line.lineTypeId != Constants.PriceOfferLineType.FixedPrice;
      case "itemDeliveryTypeId": // not "ItemDeliveryTypeTranslatedString":
      case "activityCodeId": // not "ActivityCodeTranslatedString":

        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          || line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      case "buyingUnitPrice":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          && line.useInCompleteness && !line.isDisplayHidden && !line.isDisplayHiddenInherit && line.lineTypeId != Constants.PriceOfferLineType.FixedPrice
      case "definedSellingUnitPrice":
      case 'totalSellingPrice':
      case "definedDirectMarginPrice":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          && !line.isSubItem && !line.isDisplayHidden && !line.isDisplayHiddenInherit && line.lineTypeId != Constants.PriceOfferLineType.FixedPrice;
      case "marginCoefficient":
        return line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Comment && !line.isSubItem;
      default:
        return true;
    }
  }

  public async getMeteringTotalPrices() {
    if (!(typeof this.dataLineApiService.getMeteringTotalPrices == "function")) {
      console.warn('API getMeteringTotalPrices not implemented !');
      return;
    }
    let id = this.workQuotationVersionId;
    if (this.priceOfferVersionId != null) {
      id = this.priceOfferVersionId;
    }
    this.footer = await this.dataLineApiService.getMeteringTotalPrices(parseInt(id));

  }

  public async computeMeteringTotalPrice(id: string, dataLineApiService: IDataLineApiService, _this: any) {
    _this.footer = await dataLineApiService.getMeteringTotalPrices(parseInt(id));
  }

  public triggerExpand() {
    this.screenExpand = !this.screenExpand;
  }

  /**
   * Return a list of all parents of the given node.
   *
   * @private
   * @param {RowNode} node
   * @return {*}  {Array<RowNode>}
   * @memberof VersionMetering
   */



  private getVersionId() {
    let versionId = this.workQuotationVersionId;
    if (this.priceOfferVersionId != null) {
      versionId = this.priceOfferVersionId;
    }
    return versionId;
  }


  public setMenuItemDisabled(items: Array<IMenuGroup>, menuId: Constants.CommonMeterginDataLineMenuItems, hidden: boolean = true): void {
    let item = this.findMenuItem(items, menuId);
    if (item) {
      item.disabled = () => hidden;
    }// else console.log("ITEM NOT FOUND", menuId)
  }

  private findMenuItem(items: Array<IMenuItems>, menuId: Constants.CommonMeterginDataLineMenuItems): IMenuItem {
    if (Array.isArray(items)) {
      for (let item of items) {
        if (typeof item == 'string') continue;
        if ((<IMenuItem>item).id == menuId)
          return item as IMenuItem;
        if (Array.isArray((<IMenuGroup>item).items)) {
          let found = this.findMenuItem((<IMenuGroup>item).items, menuId);
          if (found) return found;
        }
      }
    }
  }
}


