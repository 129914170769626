import { ServiceBase, CustomLogger, EntityDetailViewModelBase, Datacontext, PlaceAutocompleteConfig, PlaceAutocompleteModel, EnumerationType, EnumerationTypeService, Country, Locality, AuthService } from 'digiwall-lib';
import { autoinject, bindable, customElement, bindingMode, observable, BindingEngine } from 'aurelia-framework';
import * as Constants from '../constants';
import { Router } from 'aurelia-router';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { Merlin } from 'generated';
import { ValidationRules } from 'aurelia-validation';

@customElement('merlin-address-detail')
@autoinject
export class MerlinAddressDetail extends EntityDetailViewModelBase<Merlin.Web.Model.Address> {
  public ressourceName: string = Constants.EntityTypeNames.Address;
  public documentTitle = '';

  private placeAutocompleteConfig: PlaceAutocompleteConfig = { country: [], language: 'fr' };

  @observable()
  private placeAutocompleteModel: PlaceAutocompleteModel;
  public async placeAutocompleteModelChanged() {
    await this.managePlaceAutocompleteModel(this.placeAutocompleteModel);
  }

  private countryService: ServiceBase<Country>;
  private localityService: ServiceBase<Locality>;
  private addressTypeService: EnumerationTypeService;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  public entities: Array<Merlin.Web.Model.Address>;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  public canAddNewAddress: boolean = true;

  private belgiumCountryId: number;
  @bindable
  public addCallback: (address: { address: Merlin.Web.Model.Address }) => void;
  @bindable
  public removeCallback: (address: { address: Merlin.Web.Model.Address }) => void;
  @bindable
  public uniqueAddress: boolean = false;
  @bindable
  public firstAddressIsMain: boolean = false
  private latitude: number;
  private longitude: number;
  private mainAddressId: number;
  private MAIN_ADDRESS = Constants.AddressType.Main;

  @bindable
  private isCompany: boolean = false;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, public datacontext: Datacontext, authService: AuthService) {
    super(router, logger);
    this.countryService = new ServiceBase<Country>(Constants.EntityTypeNames.Country);
    this.localityService = new ServiceBase<Locality>(Constants.EntityTypeNames.Locality);
    this.addressTypeService = new EnumerationTypeService(Constants.EnumerationTypes.AddressType);
    super.initialize(new ServiceBase<Merlin.Web.Model.Address>(Constants.EntityTypeNames.Address));
    this.placeAutocompleteConfig.language = authService?.currentUser?.language;

    ValidationRules.customRule('validation-street-number', async (value, obj) => {
      if (value != null && value.trim() != "") {
        return true;
      }
      return false;
    }, 'address.errorStreetNumber');
    ValidationRules.customRule('validation-locality', async (value, obj) => {
      if (value != null) {
        if (isNaN(value)) {
          return value.trim() != ""
        }
        return true;
      }
      return false;
    }, 'address.errorLocality');
  }

  async entitiesChanged(newValue: Array<Merlin.Web.Model.Address>, oldValue: Array<Merlin.Web.Model.Address>) {
    if (newValue != oldValue) {
      this.belgiumCountryId = (await this.countryService.getEntities(new Predicate('code', FilterQueryOp.Equals, 'BE'), null, { $top: 1 }))[0].id;
      this.localityService.gridDataSource.queryParameters = { countryId: this.belgiumCountryId };

      newValue.forEach(entity => {
        this.disposables.push(
          this.bindingEngine.propertyObserver(entity, "countryId").subscribe(async (newVal: number, oldVal: number) => {
            if (newVal != oldVal && newVal != this.belgiumCountryId && entity.localityId != null) {
              let locality = await this.localityService.getEntityById(entity.localityId);
              if (locality != null) {
                entity.postalCode = locality.zipCode;
                entity.city = locality.name._translation;
              }
            }
          }),
          this.bindingEngine.propertyObserver(entity, "addressTypeId").subscribe(async (newVal, oldVal) => {
            if (newVal != oldVal) {
              this.mainAddressId = this.entities.filter(x => x.addressTypeId == Constants.AddressType.Main)[0]?.id;
              this.addressTypeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.AddressType, hasAlreadyMainAddress: this.mainAddressId != null };
            }
          })
        )
        ValidationRules
          .ensure('streetMain')
          .satisfiesRule('validation-street-number')
          .ensure('localityId')
          .satisfiesRule('validation-locality').when(() => entity.countryId == this.belgiumCountryId)
          .on(entity);
      });
      this.mainAddressId = this.entities.filter(x => x.addressTypeId == Constants.AddressType.Main)[0]?.id;
      this.addressTypeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.AddressType, hasAlreadyMainAddress: this.mainAddressId != null };
      newValue.map(x => x.visible = false);
      if (newValue[0] != null) {
        newValue[0].visible = true;
        this.latitude = newValue[0].latitude;
        this.longitude = newValue[0].longitude;
      }
    }
  }


  /**
   * Bind this entity (address) model to the PlaceAutocompleteModel.
   * Bind also to LocalityService and CountryService.
   *
   * @private
   * @param {PlaceAutocompleteModel} model
   * @memberof AddressDetail
   */
  private async managePlaceAutocompleteModel(model: PlaceAutocompleteModel) {
    if (model != null) {
      // Search locality by postalCode then by country
      let locality: Locality = null;
      let entity = this.entities.find(x => x.visible);

      if (model.postalCode != null) {
        let localities = await this.localityService.getEntities(new Predicate("zipCode", FilterQueryOp.Equals, model.postalCode));
        if (localities.length > 0) {
          if (localities.length > 1) {
            if (model.locality != null) {
              locality = localities.find(x => x.name._translation.toLowerCase() == model.locality.toLowerCase());
            }
            else {
              locality = localities.find(x => x.communeId == null) ?? localities[0];
            }
          }
          else {
            locality = localities[0];
          }
        }
      }
      else if (model.locality != null) {
        locality = await this.localityService.firstEntity(new Predicate(`name.lang${this.datacontext.currentLanguage.columnIndex}`, FilterQueryOp.Equals, model.locality));
      }

      if (locality != null) {
        entity.localityId = locality.id;
        entity.locality = locality;
      }
      else if (entity.locality != null) {
        entity.locality = null;
        entity.localityId = null;
      }

      entity.streetMain = model.longRoute;
      entity.postalCode = model.postalCode;
      entity.city = model.locality;

      // Search for country
      if (model.countryCode != null) {
        let country = await this.countryService.firstEntity(new Predicate('code', FilterQueryOp.Equals, model.countryCode.toUpperCase()), null)
        if (country != null) {
          entity.countryId = country.id;
        } else {
          entity.countryId = null;
        }
      } else {
        entity.countryId = null;
      }

      // Set lat and lng
      if (model.latitude != null && !isNaN(model.latitude)) entity.latitude = model.latitude;
      if (model.longitude != null && !isNaN(model.longitude)) entity.longitude = model.longitude;
      this.latitude = entity.latitude;
      this.longitude = entity.longitude;
    }
  }

  showDetail(entity: Merlin.Web.Model.Address) {
    this.entities.forEach(x => x.visible = false);
    entity.visible = true;
    this.latitude = entity.latitude;
    this.longitude = entity.longitude;
  }

  hideDetail(entity: Merlin.Web.Model.Address) {
    entity.visible = false;
  }

  async addAddress() {
    let newAddress = await this.service.createEntity();
    newAddress.countryId = this.belgiumCountryId;
    if (this.firstAddressIsMain && this.mainAddressId == null) {
      newAddress.addressTypeId = Constants.AddressType.Main;
      newAddress.addressType = await this.addressTypeService.getEntityById(Constants.AddressType.Main);
      this.mainAddressId = newAddress.id;
      this.addressTypeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.AddressType, hasAlreadyMainAddress: true };
    }
    this.entities.forEach(x => x.visible = false);
    if (this.addCallback) {
      this.addCallback({ address: newAddress });
    }
    newAddress.visible = true;
    this.latitude = newAddress.latitude;
    this.longitude = newAddress.longitude;
  }

  async deleteAddress(address: Merlin.Web.Model.Address) {
    if (address.entityAspect.entityState.isAdded()) {
      address.entityAspect.setDetached();
      if (this.entities.length > 0) {
        let firstEntity = this.entities[0];
        firstEntity.visible = true;
        this.latitude = firstEntity.latitude;
        this.longitude = firstEntity.longitude;
      }

      if (address.addressTypeId == Constants.AddressType.Main) {
        this.mainAddressId = null
        this.addressTypeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.AddressType, hasAlreadyMainAddress: false };
      }
    } else {
      let isDeleted = await this.service.deleteEntities([address], true);
      if (isDeleted) {
        if (this.entities.length > 0) {
          let firstEntity = this.entities[0];
          firstEntity.visible = true;
          this.latitude = firstEntity.latitude;
          this.longitude = firstEntity.longitude;
        }
        if (this.removeCallback) {
          this.removeCallback({ address: address });
        }
        if (address.addressTypeId == Constants.AddressType.Main) {
          this.mainAddressId = null
          this.addressTypeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.AddressType, hasAlreadyMainAddress: false };
        }
      }
    }
  }
}
