@import "../css/scss/variables";
#toggle_imposed_date label span:first-child {
  display: none;
}
ag-grid-aurelia .ag-cell.previous-price-error {
  background-color: $red-mid !important;
}
.ag-theme-material .ag-row .ag-row-error {
  background-color: $red-light;
}
