import { Merlin } from 'generated';
import { bindable, customElement } from "aurelia-templating";
import { EnumerationTypeService, ServiceBase } from 'digiwall-lib';
import * as Constants from '../constants';
import { BindingEngine, computedFrom, Disposable } from 'aurelia-binding';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { autoinject } from 'aurelia-framework';
import { ValidateEvent, ValidationRules } from 'aurelia-validation';

@customElement('merlin-third-party')
@autoinject
export class MerlinThirdParty {
  @bindable
  public entity: Merlin.Web.Model.ThirdParty
  @bindable
  public disableIsCompany: boolean = false

  private civilityService: EnumerationTypeService;
  private companyTypeService: EnumerationTypeService;
  private languageService: EnumerationTypeService;
  private thirdPartyTypeService: EnumerationTypeService;
  private thirdPartyTagService: EnumerationTypeService;
  private partnerTypeService: EnumerationTypeService;
  private activityCodeService: ServiceBase<Merlin.Web.Model.EnumerationTypeMerlin>;

  disposables: Array<Disposable>;

  @bindable
  private isSupplier: boolean = false;
  @bindable
  private isPartner: boolean = false;

  constructor(private bindingEngine: BindingEngine) {
    this.civilityService = new EnumerationTypeService(Constants.EnumerationTypes.Civility);
    this.companyTypeService = new EnumerationTypeService(Constants.EnumerationTypes.CompanyType);
    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);
    this.thirdPartyTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ThirdPartyType);
    this.thirdPartyTagService = new EnumerationTypeService(Constants.EnumerationTypes.ThirdPartyTag);
    this.partnerTypeService = new EnumerationTypeService(Constants.EnumerationTypes.PartnerType);
    this.activityCodeService = new ServiceBase<Merlin.Web.Model.EnumerationTypeMerlin>(Constants.EntityTypeNames.EnumerationTypeMerlin);
    this.activityCodeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.ActivityCode, onlyActifs: true };
    this.disposables = new Array<Disposable>();
  }

  public async attached() {
    this.disposables.push(
      this.bindingEngine.collectionObserver(this.entity.thirdPartyTypes).subscribe(() => {
        if (this.entity.thirdPartyTypes.length > 0) {
          this.computeType();
        } else {
          this.isPartner = false;
          this.isSupplier = false;
        }
      })
    );
    this.computeType();
  }

  detached() {
    this.disposables?.forEach(x => x.dispose());
  }

  public computeType() {
    this.isPartner = this.entity.thirdPartyTypes.some(x => x.thirdPartyTypeEnumId == Constants.ThirdPartyType.Partner);
    this.isSupplier = this.entity.thirdPartyTypes.some(x => x.thirdPartyTypeEnumId == Constants.ThirdPartyType.Supplier || x.thirdPartyTypeEnumId == Constants.ThirdPartyType.SubContractor);
    if (this.entity.thirdPartyTypes.some(x => x.thirdPartyTypeEnumId == Constants.ThirdPartyType.Client)) {
      this.thirdPartyTypeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.ThirdPartyType, isClient: true }
    }
    else if (this.entity.thirdPartyTypes.some(x => x.thirdPartyTypeEnumId == Constants.ThirdPartyType.Lead)) {
      this.thirdPartyTypeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.ThirdPartyType, isLead: true }
    }
    else {
      this.thirdPartyTypeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.ThirdPartyType }
    }
    if (this.entity.thirdPartyTypes.some(x => x.thirdPartyTypeEnumId == Constants.ThirdPartyType.SubContractor)) {
      ValidationRules
        .ensure('uniqueCode')
        .required()
        .on(this.entity)
    } else {
      ValidationRules.off(this.entity);
    }
  }

  @computedFrom("entity.thirdPartyTypes.length")
  public get isTemporaryCompany() {
    let temporaryCompanySelected = this.entity.thirdPartyTypes.filter(x => x.thirdPartyTypeEnumId == Constants.ThirdPartyType.TemporaryCompany).length != 0;
    if (temporaryCompanySelected) {
      this.thirdPartyTypeService.gridDataSource.customSelect2Predicates = () => {
        return new Predicate("id", FilterQueryOp.Equals, Constants.ThirdPartyType.TemporaryCompany);
      }
    } else {
      if (this.entity.thirdPartyTypes.length == 0) {
        this.thirdPartyTypeService.gridDataSource.customSelect2Predicates = () => {
          return null;
        }
      } else {
        this.thirdPartyTypeService.gridDataSource.customSelect2Predicates = () => {
          return new Predicate("id", FilterQueryOp.NotEquals, Constants.ThirdPartyType.TemporaryCompany);
        }
      }
    }
    return temporaryCompanySelected;
  }
}
