import { AddRequestSupplierOffer } from '../../request-supplier-offers/add-request-supplier-offer';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, IMenuGroup, EnumerationTypeService, ActionDialogBoxInputParameters, DialogBoxViewModel, GlobalLoaderService, ServiceBase, ImportService, ImportMode } from "digiwall-lib";
import { Merlin } from "../../generated";
import * as Constants from '../../constants';
import { autoinject } from 'aurelia-framework';
import { WorkQuotationVersionApiService } from 'services/work-quotation-version-api-service';
import { CommomMetering } from './common-metering';
import { FullWidthCellRenderer, VersionOfferOverlayComp } from './version-offer-overlay-comp';
import { PriceOfferLineCommentApiService } from 'services/price-offer-line-comment-api-service';
import { ProjectApiService } from 'services/project-api-service';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { PriceRepartitionService } from 'services/price-repartition-service';
import { ImportPopUp } from './import-pop-up/import-pop-up';
import { ImportContext } from '../../constants';
import { MeteringMenuItems } from 'resources/metering/metering-menu-items';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';
import { ICellRendererParams } from 'ag-grid-community';
import { UpdateItems, UpdateItemType } from './update-items/update-items';
import { VersionMenuItems } from './version-menu-items';


@autoinject
export class VersionMetering extends CommomMetering<Merlin.Web.Model.WorkQuotationVersion> {
  private menuItems: Array<IMenuGroup>;

  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: false },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: false },
      { colId: "marginCoefficient", visibility: false },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: false },
      { colId: "tags", visibility: true },
      { colId: "features", visibility: true },
      { colId: "supplierId", visibility: true },
      { colId: "supplierPrice", visibility: false },
      { colId: "supplierQuantity", visibility: false },
      { colId: "supplierQuantityTypeId", visibility: false },
      { colId: "supplierTotal", visibility: false },
      { colId: "supplierComment", visibility: false },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: false },
      { colId: "indirectCostFromPAR", visibility: true },
      { colId: "unitQuantityDetailItemComposed", visibility: true },
      { colId: "unitQuantityFormulaDetailItemComposed", visibility: true }
    ];

  constructor(public router: Router, logger: CustomLogger, httpClient: HttpClient, private workQuotationVersionApiService: WorkQuotationVersionApiService, globalLoaderService: GlobalLoaderService, priceOfferLineCommentApiService: PriceOfferLineCommentApiService, private projectApiService: ProjectApiService, priceRepartitionService: PriceRepartitionService, private importService: ImportService, private meteringMenuItems: MeteringMenuItems) {
    super(router, logger, httpClient, workQuotationVersionApiService, globalLoaderService, priceOfferLineCommentApiService, priceRepartitionService);
    super.initialize(new ServiceBase<Merlin.Web.Model.WorkQuotationVersion>(Constants.EntityTypeNames.WorkQuotationVersion));
  }

  getOverlayComponent() {
    return VersionOfferOverlayComp;
  }
  getBottomRowRenderer() {
    if (!this.entity.needToBeValidated)
      return FullWidthCellRenderer;
    else
      return null;
  }
  get ribbonHeaderText(): string {
    let title = this.i18n.tr('metering.meterings');
    if (this.entity.needToBeValidated) {
      title += " - " + this.i18n.tr('workquotationversion.needToBeValidated');
    }
    return title;
  }

  documentTitle = this.i18n.tr('metering.meterings') + "&&aaaaa";

  public ressourceName: string = Constants.EntityTypeNames.PriceOfferVersion;

  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number

  private showGrid: boolean = true;

  public async activate(params) {
    if (params.workQuotationVersionId == "auto") return;
    if (params != null) {
      this.projectId = params.projectId;
      this.workQuotationVersionId = params.workQuotationVersionId;
      this.entity = await this.service.getEntityById(parseInt(this.workQuotationVersionId));
    }

    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = precisionParameter.nbDecimalForQuantityDisplay;

    this.setMenuItems();
    this.getMeteringTotalPrices();
    this.priceOfferLineTypeList = await (new EnumerationTypeService(Constants.EnumerationTypes.PriceOfferLineType)).getAll();
    super.activate(params);
  }
  async deactivate() {
    await this.priceOfferLinesGrid.deactivate()
  }

  private setMenuItems(): void {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          ...VersionMenuItems.getMenuItems(parseInt(this.projectId), this.entity.id, null, this.entity.isCurrentVersion),
          {
            label: this.i18n.tr("importmetering.importFile"),
            icon: "digi-file-download-line",
            handler: async () => {
              await this.importFile();
            }
          },
          {
            label: this.i18n.tr("requestsupplieroffer.addrequestsupplieroffer"),
            icon: "digi-file-transfer-line",
            handler: () => {
              this.addRequestSupplierOffer();
            },
            disabled: this.entity.needToBeValidated
          },
          {
            label: this.i18n.tr("priceofferversion.createPriceOfferVersion"),
            icon: "digi-add-circle-line",
            handler: async () => {
              let url = this.router.generate("price-offer-version-detail", { priceOfferVersionId: -100 });
              if (this.router?.currentInstruction?.queryString?.length > 0) {
                url += '?' + this.router.currentInstruction.queryString;
              }
              this.router.navigate(url);
            },
            disabled: this.entity.needToBeValidated
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("workquotationversion.validateImport"),
            icon: "digi-shield-check-line",
            handler: async () => {
              await this.validatedImport();
            },
            disabled: !this.entity.needToBeValidated
          }
        ]
      },
      {
        group: "3",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.deleteQuote();
            },
            disabled: true
          }
        ]
      },

    ];
  }

  private async importFile() {
    let result = await this.httpClient.get(Constants.Application.WorkQuotationController.HasLineFromStatus.format(this.workQuotationVersionId));
    if (result.ok) {
      let hasLineFromFile = await result.json();

      let session = await this.importService.getInProcessSession(ImportContext.Metering, parseInt(this.workQuotationVersionId));

      if (session != null) {
        let buttonYes: ActionDialogBoxInputParameters =
        {
          label: this.i18n.tr("general.yes", { ns: "common" }),
          title: this.i18n.tr("general.yes", { ns: "common" }),
          theme: 'primary',
          type: 'solid',
          disabled: false,
          fn: async (thisBox: DialogBoxViewModel) => {
            await thisBox.controller.ok(true);
          }
        };
        let buttonNo: ActionDialogBoxInputParameters =
        {
          label: this.i18n.tr("general.no", { ns: "common" }),
          title: this.i18n.tr("general.no", { ns: "common" }),
          theme: 'dark',
          type: 'ghost',
          disabled: false,
          fn: async (thisBox: DialogBoxViewModel) => {
            await thisBox.controller.ok(false);
          }
        };
        await this.box.showQuestion(this.i18n.tr("importmetering.sessionInProcess"), null, [buttonNo, buttonYes]).whenClosed(async result => {
          if (!result.wasCancelled) {
            if (result.output) {
              this.navigateToImport(session.id);
            } else {
              await this.importService.cancelSession(session.id);
              this.checkExisteSession();
            }
          }
        });
      } else {
        this.checkExisteSession()
      }
    }
  }

  private async checkExisteSession() {
    let existSession = await this.importService.existNotCancelSession(ImportContext.Metering, parseInt(this.workQuotationVersionId));
    if (existSession) {
      this.newImportMode();
    } else {
      this.navigateToImport();
    }
  }

  public newImportMode() {
    this.box.showEditDialog(ImportPopUp, parseInt(this.workQuotationVersionId), this.i18n.tr("importdata.import"), {
      canSave: false,
      actions: [
        {
          label: this.i18n.tr("importdata.import"),
          title: this.i18n.tr("importdata.import"),
          theme: 'primary',
          type: 'solid',
          disabled: false,
          fn: async (thisBox: DialogBoxViewModel) => {
            await thisBox.customVM.currentViewModel.ok();
          }
        }
      ]
    }).whenClosed(async (resultBox) => {
      if (!resultBox.wasCancelled) {
        let output = resultBox.output;
        switch (output.excelModeType) {
          case Constants.ImportMetering.Replace:
            try {
              await this.workQuotationVersionApiService.deleteSheets(parseInt(this.workQuotationVersionId), (typeof output.fileSelected === "object") ? output.fileSelected.id : output.fileSelected, output.sheetSelecteds);
              this.navigateToImport(null, ImportMode.NewFile);
            } catch (e) {
              this.priceOfferLinesGrid.refreshServerSideRows([]);
            }
            break;
          case Constants.ImportMetering.NewFile:
            this.navigateToImport(null, ImportMode.NewFile);
            break;
          case Constants.ImportMetering.NewSheet:
            this.navigateToImport(null, ImportMode.NewSheet, (typeof output.fileSelected === "object") ? output.fileSelected.id : output.fileSelected);
            break;
        }
      }
    });
  }
  public getGridMenuItems(params: ICellRendererParams): Array<IMenuGroup> {
    if (this.entity.needToBeValidated) {
      return [
        {
          group: "1",
          hiddenLabel: true,
          items: [
            this.meteringMenuItems.getMove(params, this.dataLineApiService, this.priceOfferLinesGrid, this.listIsFlat, this.workQuotationVersionId)
          ],
        },
        {
          group: "2",
          hiddenLabel: true,
          items: [
            this.meteringMenuItems.getLineCategory(params, this.workQuotationVersionApiService, this.workQuotationVersionId, this.priceOfferLinesGrid),
            this.meteringMenuItems.getTypeItems(params, this.priceOfferLineTypeList, this.dataLineApiService, this.workQuotationVersionId, this.priceOfferLinesGrid, this.computeMeteringTotalPrice, this)
          ]
        },
        {
          group: "3",
          hiddenLabel: true,
          items: [
            this.meteringMenuItems.getDisplayHidden(params, this.dataLineApiService, this.priceOfferLinesGrid, this.workQuotationVersionId)
          ]
        },
      ]
    }
    return [
      this.meteringMenuItems.getAdd(params, this.priceOfferLinesGrid, this.dataLineApiService, this.workQuotationVersionId, this.workQuotationVersionId, this.priceOfferVersionId, this.computeMeteringTotalPrice, this),
      {
        group: "1",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getDuplicate(params, this.dataLineApiService, this.priceOfferLinesGrid, this.workQuotationVersionId, this.computeMeteringTotalPrice, this),
          this.meteringMenuItems.getCopy(params, this.priceOfferLinesGrid, Constants.LocalStorageName.WorkQuotation),
          this.meteringMenuItems.getPaste(params, Constants.LocalStorageName.WorkQuotation, this.priceOfferLinesGrid, this.dataLineApiService, this.workQuotationVersionId, this.computeMeteringTotalPrice, this),
          this.meteringMenuItems.getMove(params, this.dataLineApiService, this.priceOfferLinesGrid, this.listIsFlat, this.workQuotationVersionId)
        ],
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getLineCategory(params, this.workQuotationVersionApiService, this.workQuotationVersionId, this.priceOfferLinesGrid),
          this.meteringMenuItems.getTypeItems(params, this.priceOfferLineTypeList, this.dataLineApiService, this.workQuotationVersionId, this.priceOfferLinesGrid, this.computeMeteringTotalPrice, this),
          //this.meteringMenuItems.getIsFixedPrice(params, this.dataLineApiService, this.priceOfferLinesGrid, this.workQuotationVersionId),
          this.meteringMenuItems.getUpdateItems(params, this.dataLineApiService, this.priceOfferLinesGrid, this.projectId, this.workQuotationVersionId, this.columnVisible, UpdateItemType.Metering, this.router.currentInstruction.config.moduleId, false, this.computeMeteringTotalPrice, this),
          this.meteringMenuItems.getAddDocument(params, this.projectService, this.priceOfferLinesGrid, this.projectId, this.workQuotationVersionId, this.priceOfferVersionId)
        ]
      },
      {
        group: "5",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getCommentCell(params, this.priceOfferLineCommentApiService, this.priceOfferLinesGrid)
        ]
      },
      {
        group: "3",
        hiddenLabel: true,
        items: [
          ...this.meteringMenuItems.getPriceRepartition(params, this.priceRepartitionService, DocumentTypeDiscriminator.WorkQuotationVersion, this.workQuotationVersionId, this.priceOfferLinesGrid, this.computeMeteringTotalPrice, this),
          this.meteringMenuItems.getDisplayHidden(params, this.dataLineApiService, this.priceOfferLinesGrid, this.workQuotationVersionId)
        ]
      },
      {
        group: "4",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getDeleteLine(params, this.dataLineApiService, this.priceOfferLinesGrid, this.workQuotationVersionId, this.computeMeteringTotalPrice, this)
        ]
      }
    ]
  }

  public async validatedImport() {
    if (await this.questionValidateVersion()) {
      this.showGrid = false;
      await this.workQuotationVersionApiService.validateLastImport(this.entity.id);
      this.entity = await this.service.getEntityById(parseInt(this.workQuotationVersionId));
      this.setMenuItems();
      this.showGrid = true;
    }
  }

  async questionValidateVersion() {
    let result: boolean = false;
    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.yes", { ns: "common" }),
      title: this.i18n.tr("general.yes", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      }
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.no", { ns: "common" }),
      title: this.i18n.tr("general.no", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      }
    };
    await this.box.showQuestion(this.i18n.tr('workquotationversion.validateVersionQuestion'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
      async (resultQuestion) => {
        result = resultQuestion.output
      }
    )
    return result
  }

  public override canEditCell(line: { priceOfferLineCategoryId: number; hasChildren?: boolean; hasChildrenSubItem?: boolean; hasChildrenDetailed: boolean; useInCompleteness: boolean; isSubItem: boolean; isDisplayHidden: boolean; isDisplayHiddenInherit: boolean; lineTypeId: number }, colField: string): boolean {
    if (this.entity.needToBeValidated) {
      switch (colField) {
        case "quantityTypeId":
        case "unitId":
        case "requestedQuantity":
          return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && (line.useInCompleteness || line.hasChildrenSubItem)
        default:
          return false;
      }
    }
    else {
      return super.canEditCell(line, colField);
    }
  }

  private navigateToImport(sessionId: number = null, importMode: number = ImportMode.NewFile, fileSessionId: number = null) {
    this.router.navigateToRoute('import-data', {
      "context": ImportContext.Metering,
      "customParameter": this.workQuotationVersionId,
      "workQuotationVersionId": this.workQuotationVersionId,
      "errorView": "projects/quotes/import-metering-error-table.html",
      configView: "projects/quotes/metering-config.html",
      sessionId: sessionId,
      importMode: importMode,
      fileSessionId: fileSessionId,
      disclaimerView: "projects/quotes/metering-import-disclaimer.html",
      importStep6View: "projects/quotes/import-step-6.html",
      lastStepFooterView: "projects/quotes/last-step-footer-view.html"
    });
  }

  private deleteQuote() {
    console.log("Delete metering: plus de contenu quand on aura des infos sur qu'est-ce qu'on doit réellement supprimer");
  }

  public addRequestSupplierOffer() {
    this.box.showCustomDialog(
      AddRequestSupplierOffer, { workQuotationVersionId: parseInt(this.workQuotationVersionId), projectId: parseInt(this.projectId) } as any, this.i18n.tr('requestsupplieroffer.addrequestsupplieroffer'),
      {
        canSave: false,
        size: 'all',
        containerClass: "container-add-request-supplier-offer",
        callback: (currentEntity: Merlin.Web.Model.RequestSupplierOffer) => {
          (currentEntity as Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer).workQuotationVersionId = parseInt(this.workQuotationVersionId);
          (currentEntity as Merlin.Web.Model.BuyingRequestSupplierOffer).projectId = parseInt(this.projectId);
        }
      }).whenClosed(result => {
        if (!result.wasCancelled) {
          let newRequestSupplierOffer = result.output?.newRequestSupplierOffer;
          if (newRequestSupplierOffer) {
            this.addRequestSupplierOffer();
          }
        }
      });
  }
}
