import { BindingEngine, autoinject, observable } from "aurelia-framework";

import * as Constants from '../../../constants';
import { DialogController } from "aurelia-dialog";
import { ImportService, ImportFileSessions, ImportFileAndSession, ImportSession, SheetInfo } from "digiwall-lib";
import { DataFormat } from "select2";
import { ImportContext } from "../../../constants";

@autoinject
export class ImportPopUp {
  private REPLACE = Constants.ImportMetering.Replace;
  private NEW_FILE = Constants.ImportMetering.NewFile;
  private NEW_SHEET = Constants.ImportMetering.NewSheet;

  @observable
  private excelModelType: number = this.REPLACE;
  private customParameter: number;
  public sheetSelecteds: Array<number> = new Array();

  private files: Array<ImportFileAndSession>;
  private sheets: Array<SheetInfo>;
  private showSheet: boolean;
  @observable
  private fileSelected: DataFormat;

  constructor(private dialogController: DialogController, private importService: ImportService) {

  }

  fileSelectedChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      this.getSessionByFile((typeof this.fileSelected === "object" ? parseInt(this.fileSelected?.id.toString()) : parseInt((this.fileSelected as any).toString())));
    }
  }



  async activate(params) {
    this.customParameter = params.param1;
    this.files = await this.importService.getFileAndSession(ImportContext.Metering, this.customParameter);
    if (this.files.length == 1) {
      this.fileSelected = { id: this.files[0].id, text: this.files[0].fileName };
      this.getSessionByFile(parseInt(this.fileSelected.id.toString()));
    }
  }


  getSessionByFile(fileId: number) {
    this.showSheet = false;
    this.sheetSelecteds.splice(0);
    let file = this.files.find(x => x.id == fileId);
    this.sheets = file.sheets;
    if (this.sheets.length == 1) {
      this.sheetSelecteds.push(this.sheets[0].index);
    }
    setTimeout(() => this.showSheet = true, 1);
  }

  public ok() {
    this.dialogController.ok({
      excelModeType: this.excelModelType,
      fileSelected: this.fileSelected,
      sheetSelecteds: this.sheetSelecteds
    });
  }
}
