@import "../css/main";

.transaction__cell {
  &--amount {
    &.positive {
      color: blue;

      & .ag-cell-value > span:before {
        content: "+";
      }
    }

    &.negative {
      color: red;

      & .ag-cell-value > span:before {
        content: "-";
      }
    }

    & .ag-cell-value > span:before {
      margin-right: 0.2rem;
    }
  }
}

ui-page.transaction-detail {
  ui-button.status {
    & > div.ui-btn__inner,
    & > div > a {
      background-color: inherit !important;
      color: inherit !important;
    }
  }

  .transaction__panel__grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-auto-flow: column;
    grid-column-gap: 1rem;

    & .ui-panel {
      height: 100%;
    }
  }

  ui-panel.panel__amount {
    .ui-panel__header {
      div[slot="header-title"] {
        grid-area: title;
        text-align: center;
        justify-self: center;

        h3 {
          font-size: 1rem;
          margin-block: 10px;
          font-weight: $font-weight-lighter;
        }

        h1 {
          margin-block: 10px;
          font-size: 1.4rem;
          font-weight: 400;
        }
      }

      background-color: var(--primary, $primary);
      color: var(--primary-reverse, $primary-reverse);
    }
  }

  ui-panel.general__infos {
    .ui-panel__body {
      padding-block: 5px;
    }

    span {
      display: block;
    }

    p:first-of-type {
      margin-top: 5px;
    }

    p:last-of-type {
      margin-bottom: 5px;
    }
  }

  .transaction__panel__grid__body {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;

    & > .arrow {
      color: $primary;
    }

    & > div {
      justify-self: center;
      align-self: center;
      text-align: center;
    }

    & p {
      margin-block: 0.2rem;

      & > img {
        height: 50px;
      }
    }
  }
  .ui-table__reconciled__column {
    span:nth-child(1) {
      color: lighten($color: blue, $amount: 10);
    }
    span:nth-child(2) {
      margin-inline: 0.2rem;
    }
  }
}
