import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, EnumerationType, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef, RowNode } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class EnumerationTypeList extends ListViewModelBase<EnumerationType> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<EnumerationType>(Constants.EntityTypeNames.EnumerationType));
  }
  public ressourceName: string = Constants.EntityTypeNames.EnumerationType;

  private paramType: string;
  private paramRoute: string;
  private isCloseEnum: boolean = false;
  public buttonLabel: string;

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public async activate(params): Promise<void> {
    this.paramType = params.param2;
    this.service.gridDataSource.queryParameters = { category: this.paramType };
    switch (this.paramType) {
      case Constants.EnumerationTypes.Civility.toString():
        this.paramRoute = "civility";
        this.buttonLabel = this.i18n.tr("enumerationtype.addCivility");
        break;

      case Constants.EnumerationTypes.CompanyType.toString():
        this.paramRoute = "company-type";
        this.buttonLabel = this.i18n.tr("enumerationtype.addCompanyType");
        break;

      case Constants.EnumerationTypes.UnitType.toString():
        this.paramRoute = 'unit-type';
        this.buttonLabel = this.i18n.tr("enumerationtype.addUnitType");
        break;

      case Constants.EnumerationTypes.PriceOfferStatus.toString():
        this.paramRoute = 'price-offer-status';
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.ThirdPartyType.toString():
        this.paramRoute = 'third-party-type';
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.PartnerType.toString():
        this.paramRoute = 'partner-type';
        this.buttonLabel = this.i18n.tr("enumerationtype.addPartnerType");
        break;

      case Constants.EnumerationTypes.ThirdPartyTag.toString():
        this.paramRoute = 'third-party-tag';
        this.buttonLabel = this.i18n.tr("enumerationtype.addThirdPartyTag");
        break;

      case Constants.EnumerationTypes.CompanyFunction.toString():
        this.paramRoute = 'company-function';
        this.buttonLabel = this.i18n.tr("enumerationtype.addCompanyFunction");
        break;

      case Constants.EnumerationTypes.ItemFeature.toString():
        this.paramRoute = 'item-feature';
        this.buttonLabel = this.i18n.tr("enumerationtype.addItemFeature");
        break;

      case Constants.EnumerationTypes.ContainerType.toString():
        this.paramRoute = 'container-type';
        this.buttonLabel = this.i18n.tr("enumerationtype.addContainerType");
        break;

      case Constants.EnumerationTypes.UnitPriceSourceType.toString():
        this.paramRoute = 'unit-price-source-type';
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.PriceOfferLineType.toString():
        this.paramRoute = 'price-offer-line-type';
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.ItemDeliveryType.toString():
        this.paramRoute = 'item-delivery-type';
        this.buttonLabel = this.i18n.tr("enumerationtype.addItemDeliveryType");
        break;

      case Constants.EnumerationTypes.ActivityCode.toString():
        this.paramRoute = 'activity-code';
        this.buttonLabel = this.i18n.tr("enumerationtype.addActivityCode");
        break;

      case Constants.EnumerationTypes.EmailType.toString():
        this.paramRoute = 'email-type';
        this.buttonLabel = this.i18n.tr("enumerationtype.addEmailType");
        break;

      case Constants.EnumerationTypes.FileType.toString():
        this.paramRoute = 'file-type';
        this.buttonLabel = this.i18n.tr("enumerationtype.addFileType");
        break;

      case Constants.EnumerationTypes.TaskType.toString():
        this.paramRoute = 'task-type';
        this.buttonLabel = this.i18n.tr("enumerationtype.addTaskType");
        break;

      case Constants.EnumerationTypes.TaskCriticityLevel.toString():
        this.paramRoute = 'task-criticity-level';
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.PriceOfferLineTag.toString():
        this.paramRoute = 'price-offer-line-tag';
        this.buttonLabel = this.i18n.tr("enumerationtype.addPriceOfferLineTag");
        break;

      case Constants.EnumerationTypes.TaskTag.toString():
        this.paramRoute = 'task-tag';
        this.buttonLabel = this.i18n.tr("enumerationtype.addTaskTag");
        break;
      case Constants.EnumerationTypes.DocumentTag.toString():
        this.paramRoute = 'document-tag';
        this.buttonLabel = this.i18n.tr("enumerationtype.addDocumentTag");
        break;
      case Constants.EnumerationTypes.UserFunctions.toString():
        this.paramRoute = 'document-tag';
        this.buttonLabel = this.i18n.tr("enumerationtype.addUserFunction");
        break;

      case Constants.EnumerationTypes.DocumentType.toString():
        this.paramRoute = 'document-tag';
        this.buttonLabel = this.i18n.tr("enumerationtype.addDocumentType");
        break;

      case Constants.EnumerationTypes.ProjectExternalRole.toString():
        this.paramRoute = 'project-external-role';
        this.buttonLabel = this.i18n.tr("enumerationtype.addProjectExternalRole");
        break;

      case Constants.EnumerationTypes.ProjectPartnerRole.toString():
        this.paramRoute = 'project-partner-role';
        this.buttonLabel = this.i18n.tr("enumerationtype.addProjectPartnerRole");
        break;

      case Constants.EnumerationTypes.ProjectInternalRole.toString():
        this.paramRoute = 'project-internal-role';
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.ProjectCancellationReason.toString():
        this.paramRoute = 'project-cancellation-reason';
        this.buttonLabel = this.i18n.tr("enumerationtype.addProjectCancellationReason");
        break;

      case Constants.EnumerationTypes.ProjectStatus.toString():
        this.paramRoute = 'project-status';
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.ProjectType.toString():
        this.paramRoute = 'project-type';
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.PriceOfferMobilizationDelay.toString():
        this.paramRoute = 'price-offer-mobilization-delay';
        this.buttonLabel = this.i18n.tr("enumerationtype.addPriceOfferMobilizationDelay");
        break;

      case Constants.EnumerationTypes.PriceOfferPaymentDelay.toString():
        this.paramRoute = 'price-offer-payment-delay';
        this.buttonLabel = this.i18n.tr("enumerationtype.addPriceOfferPaymentDelay");
        break;

      case Constants.EnumerationTypes.PriceOfferDeposit.toString():
        this.paramRoute = 'price-offer-deposit';
        this.buttonLabel = this.i18n.tr("enumerationtype.addPriceOfferDeposit");
        break;

      case Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic.toString():
        this.paramRoute = 'price-offer-price-revision-technic';
        this.buttonLabel = this.i18n.tr("enumerationtype.addPriceOfferPriceRevisionTechnic");
        break;

      default:
        break;
    }
  }

  public getDetailsUrl(self, entity: EnumerationType) {
    if (entity?.id) {
      return this.router.baseUrl + `/enumeration-types/${this.paramRoute}/${this.paramType}/${entity.id}`;
    }
    return null;
  }

  public addEnumerationType() {
    this.router.navigate(this.router.baseUrl + `/enumeration-types/${this.paramRoute}/${this.paramType}/-100`);
  }

  protected isSelectable(entity: EnumerationType): boolean {
    return !entity?.uniqueCode;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsFR"),
        field: "denomination.lang1",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsNL"),
        field: "denomination.lang2",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsEN"),
        field: "denomination.lang3",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.active"),
        field: "active",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.default"),
        field: "default",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
}
