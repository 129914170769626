import { DataFormat } from 'select2';
import { GenerationApiParams } from './../send-offer';
import { RefToDisplay, EntityTypeNames } from './../../../../constants';
import { I18N } from 'aurelia-i18n';
import { ReportResult } from '../../../../services/report-api-service';
import { autoinject, bindable, customElement, observable } from "aurelia-framework";
import { GlobalLoaderService, IFile, PictureHelper, ServiceBase } from 'digiwall-lib';
import { GenerationOptionGroup } from '../send-offer';
import { Merlin } from 'generated';

@autoinject
@customElement("send-offer-step-3")
export class SendOfferStep3 {
  private pfdHasInternalModel: boolean = false;
  private includeTotalInLetter: boolean = false;

  @bindable
  private hasGeneratedFiles: boolean = false;
  @bindable
  private files: IFile[] = [];
  @bindable
  private generationOptions: GenerationOptionGroup[];
  @bindable
  private projectId: number;
  @bindable
  private hasSignatoryIntern: boolean = false;
  @bindable
  private hasSignatoryExtern: boolean = false;

  private architectSelected: number
  private internSelected: number

  private architectService: ServiceBase<Merlin.Web.Model.ThirdParty>
  private internService: ServiceBase<Merlin.Web.Model.MerlinUser>

  private selectedRefToDisplay: RefToDisplay = RefToDisplay.MerlinRef;

  constructor(private pictureHelper: PictureHelper, private gls: GlobalLoaderService, private i18n: I18N) {
    this.architectService = new ServiceBase<Merlin.Web.Model.ThirdParty>(EntityTypeNames.ThirdParty)
    this.internService = new ServiceBase<Merlin.Web.Model.MerlinUser>(EntityTypeNames.MerlinUser)
  }

  async bind() {
    this.architectService.gridDataSource.queryParameters = { projectId: this.projectId, externalTeam: true }
    this.internService.gridDataSource.queryParameters = { projectId: this.projectId }
    var defaultRefToDisplayForPdf = (await new ServiceBase<Merlin.Web.Model.ApplicationParameter>(EntityTypeNames.ApplicationParameter).getEntityById(1)).defaultRefToDisplayForPdf;
    this.selectedRefToDisplay = RefToDisplay[defaultRefToDisplayForPdf as keyof typeof RefToDisplay]
  }

  selectOption(optionId: number, groupId: number) {
    let group = this.generationOptions[this.generationOptions.findIndex(x => x.id == groupId)];
    let previousSelected = group.items.find(x => x.selected)
    group.items.forEach(item => {
      item.selected = false;
    });
    if (previousSelected == null || previousSelected.id != optionId)
      group.items[group.items.findIndex(x => x.id == optionId)].selected = true;
  }

  addFile(reportResult: ReportResult) {
    this.files.push({
      name: reportResult.name,
      url: reportResult.url,
      description: '',
      fileTypeId: 1,
      fileType: undefined,
      size: null,
      id: 0,
      entityAspect: null,
      entityType: null,
      createdById: 0,
      createdBy: undefined,
      createdTime: undefined,
      updatedById: 0,
      updatedBy: undefined,
      updatedTime: undefined
    });
  }


  private async generateFiles() {
    this.hasGeneratedFiles = false;

    let data: GenerationApiParams = {
      includeTotalInLetter: this.includeTotalInLetter,
      selectedRefToDisplay: this.selectedRefToDisplay,
      currentSelectionState: {},
      architectSelectedId: this.architectSelected,
      internSelectedId: this.internSelected
    }

    this.generationOptions.forEach(option => {
      data.currentSelectionState[option.id] = option.items.find(x => x.selected)?.id ?? null
    });

    this.files.splice(0);

    let arrayOfPromise = [] as Promise<ReportResult>[];

    this.generationOptions.forEach(async option => {
      let index = option.items.findIndex(x => x.selected);
      if (index == -1) {
        if (option.defaultHandler != null) {
          arrayOfPromise.push(option.defaultHandler(data));
        }
      }
      else {
        arrayOfPromise.push(option.items[index].handler(data));
      }
    });

    this.gls.allow(this.i18n.tr("priceofferversion.filesGenerating"), 0);
    var result = await Promise.all(arrayOfPromise)
    result.forEach(x => this.addFile(x));
    this.hasGeneratedFiles = true;
  }
}


