import * as SignaturePad from 'signature_pad';
import { bindable, bindingMode, observable, autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

@autoinject
export class SignatureComponent {
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  private imageBase64: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  private signatureUrl: string;

  constructor(private i18n: I18N) {
  }

  private canvas: HTMLCanvasElement;
  private signaturePad: SignaturePad.default;
  private canvasWidth: number = 500;
  private canvasHeight: number = 250;
  private imageBase64Save: string;

  public attached() {
    this.signaturePad = new SignaturePad.default(this.canvas);
    // this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;
    this.canvas.width = this.canvas.parentElement.clientWidth;
    this.imageBase64Save = this.imageBase64;
    this.signaturePad.fromDataURL(this.imageBase64);
    //this.signaturePad.fromDataURL(this.signatureUrl);
    this.signaturePad.onEnd = () => { this.savePNG() };
  }

  public savePNG() {
    if (!this.signaturePad.isEmpty()) {
      this.imageBase64 = this.signaturePad.toDataURL('image/png', 1);
    }
  }

  public undo() {
    let data = this.signaturePad.toData();
    if (data) {
      data.pop();
      this.signaturePad.fromData(data);
    }
    this.savePNG();
  }

  public clear() {
    this.signaturePad.clear();
    this.imageBase64 = null;
  }

  public reset() {
    this.signaturePad.clear();
    this.signaturePad.fromDataURL(this.imageBase64Save);
    this.savePNG();
  }

}
