@import "../css/scss/variables";
.third-party-logo {
  position: absolute;
  top: 85px;
  right: 80px;
  z-index: 1;
  padding: 10px;
  width: 120px;
  height: 120px;
}

.image-editor {
  .editor-button {
    margin-top: 5px;
  }
}

// .user-picture {
//   .avatar {
//     border-radius: 0;
//   }
// }
