@import "../../css/scss/variables";

.dialog-container-debug-total-price > div > div > ux-dialog {
  background-color: $black-0 !important;
  padding: 0;
  border-radius: $radius;
  width: 550px;
  > ux-dialog-header > div.dialog-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > h4 {
      font-weight: 600;
      margin: 0;
      margin-left: 14px;
    }
  }
  > ux-dialog-body {
    margin: 30px;
    margin-top: 0;
    padding: 0;
    .grid {
      display: grid;
      grid-template-columns: 8fr 10fr 1fr 10fr;
      gap: 10px;
    }

    .row {
      display: contents;
      color: $green-valid;
      &::after {
        content: none !important;
      }
      &.invalid {
        color: $red-warning;
      }
    }

    span {
      padding: 5px; /* Optional: Add some padding to the spans */
    }

    .ui-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }
  > ux-dialog-footer {
    display: flex;
    flex-direction: row-reverse;
    background-color: $background-footer;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    padding: 10px 30px;
  }
}
