import { autoinject, BindingEngine } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { CustomLogger, EntityDetailViewModelBase, ServiceBase } from "digiwall-lib";
import { Merlin } from "generated";
import * as Constants from '../../constants';
import { DocumentTypeDiscriminator } from "request-supplier-offers/request-supplier-offer-detail";

@autoinject
export class AddSupplierToRequest extends EntityDetailViewModelBase<Merlin.Web.Model.RequestToSupplier> {
  documentTitle: string = "";
  get ressourceName(): string {
    return Constants.EntityTypeNames.RequestToSupplier;
  }

  private supplierService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  private contactPersonService: ServiceBase<Merlin.Web.Model.ThirdParty>;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
    this.supplierService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.supplierService.gridDataSource.queryParameters = { thirdPartyTypeId: Constants.ThirdPartyType.Supplier, supplierDeliveryId: Constants.SupplierDeliveryId.SubContractor };
    this.contactPersonService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
  }

  public async activate(params) {
    if (params.type != null) {
      if (params.type == DocumentTypeDiscriminator.WorkQuotationVersion) {
        super.initialize(new ServiceBase<Merlin.Web.Model.WorkQuotationVersionRequestToSupplier>(Constants.EntityTypeNames.WorkQuotationVersionRequestToSupplier));
      } else if (params.type == DocumentTypeDiscriminator.Buying) {
        super.initialize(new ServiceBase<Merlin.Web.Model.BuyingRequestToSupplier>(Constants.EntityTypeNames.BuyingRequestToSupplier));
      }
    }
    this.entity = await this.service.createEntity();
    this.entity.statusId = Constants.RequestToSupplierStatusId.NotFollowed;
    if (params.callback) params.callback(this.entity);
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.entity, "toSupplierOrSubContractorId").subscribe((newVal, oldVal) => {
        if (newVal != oldVal) {
          // this.entity.toSupplierContactPersonId = null;
          this.entity.toSupplierContactPersons.splice(0);
          this.contactPersonService.gridDataSource.queryParameters = { companyId: this.entity.toSupplierOrSubContractorId };
        }
      })
    )
  }
}
