import { ListViewModelBase, CustomLogger, ServiceBase, SelectAllHeaderComponent, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';

import { Merlin } from 'generated';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class VATLevelList extends ListViewModelBase<Merlin.Web.Model.VATLevel> {
  public ressourceName: string = Constants.EntityTypeNames.VATLevel;
  public buttonLabel: string = this.i18n.tr("vatlevel.addVatlevel");
  public pathDetail: string;


  constructor(router: Router, logger: CustomLogger) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.VATLevel>(Constants.EntityTypeNames.VATLevel));
  }

  public getDetailsUrl(_self: any, entity: Merlin.Web.Model.VATLevel) {
    if (entity?.id) {
      return (this.pathDetail + entity.id);
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = this.router.baseUrl + '/vat-levels/';

    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("vatlevel.code"),
        field: "code",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("vatlevel.percentage"),
        field: "percentage",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("vatlevel.isDefaultVAT"),
        field: "isDefaultVAT",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];

    return defs;
  }
} 
