import { autoinject, Container } from 'aurelia-framework';
import { ICellRendererParams } from "ag-grid-community";
import { ActionDialogBoxInputParameters, Box, DialogBoxViewModel, EnumerationType, GlobalLoaderService, IMenuGroup, IMenuItems, KeyboardShortcut, ServiceBase } from "digiwall-lib";
import * as Constants from '../../constants';
import { I18N } from 'aurelia-i18n';
import { IMeterigSwitchType, MenuLineCategory } from './metering-menu-items/menu-line-category';
import { IDataLineApiService, IPriceOfferLineGridService, MeteringTotalPrices } from 'services/i-data-line-api-service';
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';
import { MenuDeleteLine } from './metering-menu-items/menu-delete-line';
import { MenuPriceRepartition } from './metering-menu-items/menu-price-repartition';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';
import { PriceRepartitionService } from 'services/price-repartition-service';
import { AgCellCommentDialog } from 'resources/elements/ag-cell-comment/ag-cell-comment-dialog';
import IPriceOfferLineCommentApiService, { PriceOfferLineCommentApiService } from 'services/price-offer-line-comment-api-service';
import { param } from 'jquery';
import { Merlin } from 'generated';
import { MenuPostType } from './metering-menu-items/menu-post-type';
import { MenuAddDocument } from './metering-menu-items/menu-add-document';
import { MenuAdd } from './metering-menu-items/menu-add';
import { MenuPaste } from './metering-menu-items/menu-paste';
import { MenuMove } from './metering-menu-items/menu-move';
import { UpdateItems, UpdateItemType } from 'projects/quotes/update-items/update-items';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { Router } from 'aurelia-router';

@autoinject
export class MeteringMenuItems {

  constructor(private menuDeleteLine: MenuDeleteLine, private menuPriceRepartition: MenuPriceRepartition, private menuPostType: MenuPostType, private menuAdd: MenuAdd, private menuPaste: MenuPaste, private menuMove: MenuMove) { }

  public getLineCategory(params: ICellRendererParams, dataLineApiService: IMeterigSwitchType, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid): IMenuItems {
    return MenuLineCategory.getMenu(params, dataLineApiService, versionId, priceOfferLinesGrid);
  }

  public getDeleteLine(params: ICellRendererParams, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): IMenuItems {
    return this.menuDeleteLine.getMenu(params, dataLineApiService, priceOfferLinesGrid, versionId, getMeteringTotalPrices, _this);
  }

  public getDisplayHidden(params: ICellRendererParams, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string): IMenuItems {
    let i18n = Container.instance.get(I18N);
    let menuItems = {
      id: Constants.CommonMeterginDataLineMenuItems.HIDE,
      label: params.node.data.isDisplayHidden ? i18n.tr("versionmetering.displayHiddenFalse") : i18n.tr("versionmetering.displayHiddenTrue"),
      icon: "digi-eye-line",
      hidden: () => params.node.data.isSubItem,
      disabled: () => params.node.data.isDisplayHiddenInherit || params.node.data.buyingUnitPrice != null || params.node.data.definedSellingUnitPrice != null,
      handler: async () => {
        await this.changeIsDisplayHidden(params.node.data.id, params.node.data.isDisplayHidden, dataLineApiService, priceOfferLinesGrid, versionId);
      }
    }
    return menuItems;
  }

  /*public getIsFixedPrice(params: ICellRendererParams, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string): IMenuItems {
    let i18n = Container.instance.get(I18N);
    let menuItems = {
      label: params.node.data.isFixedPrice ? i18n.tr("metering.freePrice") : i18n.tr("metering.fixPrice"),
      icon: params.node.data.isFixedPrice ? "digi-lock-unlock-line" : "digi-lock-line",
      disabled: () => params.node.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Data,
      handler: async () => {
        await this.changeIsFixedPrice(params.node.data.id, params.node.data.isFixedPrice, dataLineApiService, priceOfferLinesGrid, versionId);
      }
    }
    return menuItems;
  }*/

  public getPriceRepartition(params: ICellRendererParams, priceRepartitionService: PriceRepartitionService, documentTypeDiscriminator: DocumentTypeDiscriminator, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): IMenuItems[] {
    return this.menuPriceRepartition.getMenuItems(params, priceRepartitionService, documentTypeDiscriminator, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this);
  }
  public getCommentCell(params: ICellRendererParams, priceOfferLineCommentApiService: IPriceOfferLineCommentApiService, priceOfferLinesGrid: PriceOfferLinesGrid): IMenuItems {
    let i18n = Container.instance.get(I18N);
    let menuItem = {
      id: Constants.CommonMeterginDataLineMenuItems.COMMENT_CELL,
      label: i18n.tr("versionmetering.commentCell"),
      icon: "digi-comment",
      disabled: () => !params.column.getColDef().cellRendererParams.canComment || priceOfferLinesGrid.listTreeData.hasComment(params.column.getColDef(), params.node.data),
      handler: async () => {

        await this.commentCell(params, priceOfferLineCommentApiService, priceOfferLinesGrid)
      }
    }
    return menuItem
  }

  public getAddDocument(params: ICellRendererParams, projectService: ServiceBase<Merlin.Web.Model.Project>, priceOfferLinesGrid: PriceOfferLinesGrid, projectId: string, workQuotationVersionId: string, priceOfferVersionId: string): IMenuItems {
    return MenuAddDocument.getMenu(params, projectService, priceOfferLinesGrid, projectId, workQuotationVersionId, priceOfferVersionId);
  }

  public getTypeItems(params: ICellRendererParams, priceOfferLineTypeList: Array<EnumerationType>, dataLineApiService: IDataLineApiService, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): IMenuItems {
    return this.menuPostType.getMenuItems(params, priceOfferLineTypeList, dataLineApiService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this);
  }

  public getAdd(params: ICellRendererParams, priceOfferLinesGrid: PriceOfferLinesGrid, dataLineApiService: IDataLineApiService, versionId: string, workQuotationVersionId: string, priceOfferVersionId: string, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any, hideDetail: boolean = false): IMenuGroup {
    return this.menuAdd.getMenu(params, priceOfferLinesGrid, dataLineApiService, versionId, workQuotationVersionId, priceOfferVersionId, getMeteringTotalPrices, _this, hideDetail);
  }

  public getDuplicate(params: ICellRendererParams, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): IMenuItems {
    let i18n = Container.instance.get(I18N);
    let menuItem = {
      id: Constants.CommonMeterginDataLineMenuItems.DUPLICATE,
      label: i18n.tr("versionmetering.duplicate"),
      icon: "digi-duplicate",
      handler: () => {
        this.duplicateLine(params.node.id, dataLineApiService, priceOfferLinesGrid, versionId, getMeteringTotalPrices, _this);
      }
    };
    return menuItem;
  }

  public getCopy(params: ICellRendererParams, priceOfferLinesGrid: PriceOfferLinesGrid, localeStorageName: Constants.LocalStorageName): IMenuItems {
    let i18n = Container.instance.get(I18N);

    let menuItem = {
      id: Constants.CommonMeterginDataLineMenuItems.COPY,
      label: i18n.tr("versionmetering.copy"),
      icon: "digi-copy",
      handler: () => {
        this.copySelectedLine(priceOfferLinesGrid, localeStorageName);
      },
      disabled: () => {
        return priceOfferLinesGrid.gridOptions.api.getSelectedNodes().length == 0
      }
    };
    return menuItem
  }


  public getPaste(params: ICellRendererParams, localeStorageName: Constants.LocalStorageName, priceOfferLinesGrid: PriceOfferLinesGrid, dataLineApiService: IDataLineApiService, versionId: string, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): IMenuItems {
    return this.menuPaste.getMenu(params, localeStorageName, priceOfferLinesGrid, versionId, dataLineApiService, getMeteringTotalPrices, _this);
  }

  public getMove(params: ICellRendererParams, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, listIsFlat: boolean, versionId: string) {
    return this.menuMove.getMenu(params, dataLineApiService, priceOfferLinesGrid, listIsFlat, versionId);
  }


  private copySelectedLine(priceOfferLinesGrid: PriceOfferLinesGrid, localeStorageName: Constants.LocalStorageName) {
    let selectedNodes = priceOfferLinesGrid.gridOptions.api.getSelectedNodes();
    let lines = selectedNodes.map(x => { return { id: x.data.id, categoryId: x.data.priceOfferLineCategoryId } });

    localStorage.setItem(localeStorageName, JSON.stringify(lines))

    priceOfferLinesGrid.gridOptions.api.deselectAll();
    selectedNodes.forEach(n => n.data.isSelected = false);
  }

  private async changeIsDisplayHidden(id: number, oldIsDisplayHidden: boolean, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string) {
    var notValidLines = priceOfferLinesGrid.gridOptions.api.getSelectedNodes().filter(x => x.data.isSubItem || x.data.isDisplayHiddenInherit || x.data.buyingUnitPrice != null || x.data.definedSellingUnitPrice != null);
    if (notValidLines.length == 0) {
      await this.changeIsDisplayHiddenCompute(id, oldIsDisplayHidden, dataLineApiService, priceOfferLinesGrid, versionId);
    }
    else {
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: priceOfferLinesGrid.i18n.tr("general.yes", { ns: "common" }),
        title: priceOfferLinesGrid.i18n.tr("general.yes", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok();
        },
        keyboardShortcut: KeyboardShortcut.Enter
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: priceOfferLinesGrid.i18n.tr("general.no", { ns: "common" }),
        title: priceOfferLinesGrid.i18n.tr("general.no", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.cancel(false);
        },
        keyboardShortcut: KeyboardShortcut.Escape
      };
      await priceOfferLinesGrid.box.showQuestion(priceOfferLinesGrid.i18n.tr('versionmetering.displayHiddenQuestion'), priceOfferLinesGrid.i18n.tr('menu.question'), [buttonNo, buttonYes])
        .whenClosed(async result => {
          if (!result.wasCancelled) {
            await this.changeIsDisplayHiddenCompute(id, oldIsDisplayHidden, dataLineApiService, priceOfferLinesGrid, versionId);
          }
        });
    }
  }

  private async changeIsDisplayHiddenCompute(id: number, oldIsDisplayHidden: boolean, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string) {
    let ids = priceOfferLinesGrid.gridOptions.api.getSelectedNodes().filter(x => !(x.data.isSubItem || x.data.isDisplayHiddenInherit || x.data.buyingUnitPrice != null || x.data.definedSellingUnitPrice != null)).map(x => x.data.id);
    if (ids.length == 0 || ids.findIndex(x => x == id) == -1)
      ids.push(id);

    if (ids.length == 1) {
      let result = await dataLineApiService.patch(parseInt(versionId), ids[0], "isDisplayHidden", !oldIsDisplayHidden);
      priceOfferLinesGrid.refreshServerSideRows(result, true);
    }
    else {
      let events: {
        lineId: number,
        propertyName: string,
        propertyValue: any
      }[] = ids.map(id => {
        return {
          lineId: id,
          propertyName: "isDisplayHidden",
          propertyValue: !oldIsDisplayHidden
        }
      });
      let result = await dataLineApiService.bulkPatch(parseInt(versionId), events);
      priceOfferLinesGrid.refreshServerSideRows(result, true);
    }
  }

  // private async changeIsFixedPrice(id: number, oldIsFixedPrice: boolean, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string) {
  //   let result = await dataLineApiService.patch(parseInt(versionId), id, "isFixedPrice", !oldIsFixedPrice);
  //   priceOfferLinesGrid.refreshServerSideRows(result, false, priceOfferLinesGrid.flattenList);
  // }

  private async commentCell(params, priceOfferLineCommentApiService: IPriceOfferLineCommentApiService, priceOfferLinesGrid: PriceOfferLinesGrid) {
    let i18n = Container.instance.get(I18N);
    let box = Container.instance.get(Box);

    box.showCustomDialog(AgCellCommentDialog, -100, i18n.tr("priceofferline.commentMultiline"),
      {
        canSave: false,
        size: "xl",
        model: {
          rowNode: params.node,
          colField: params.column.colId,
          api: priceOfferLineCommentApiService
        }
      }).whenClosed(async result => {
        if (!result?.wasCancelled) {
          await priceOfferLinesGrid.refreshVisibleNodes([params.node.id], false);
        }
      });
  }

  private async duplicateLine(targetRowId: string, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any) {
    if (!(typeof dataLineApiService.duplicateLine == "function")) {
      console.warn('API duplicateLine not implemented !');
      return;
    }

    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId);
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    targetNode.data.isSelected = false;
    let globalLoaderService = Container.instance.get(GlobalLoaderService);

    globalLoaderService.allow();
    priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    let ids = await dataLineApiService.duplicateLine(parseInt(versionId), parseInt(targetNode.data.id));
    priceOfferLinesGrid.gridOptions.api.hideOverlay();

    priceOfferLinesGrid.refreshServerSideRows(ids, targetNode.data.parentId == null);
    getMeteringTotalPrices(versionId, dataLineApiService, _this);
  }

  public getUpdateItems(params: ICellRendererParams, dataLineApiService: IDataLineApiService | IPriceOfferLineGridService, priceOfferLinesGrid: PriceOfferLinesGrid, projectId: string, versionId: string, columnVisible: Array<ColumnVisible>, updateItemType: UpdateItemType, agGridViewModule: string, disabled: boolean, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any) {
    let i18n = Container.instance.get(I18N);
    let box = Container.instance.get(Box);

    return {
      label: i18n.tr("versionmetering.changeArticlePrice"),
      icon: "digi-edit-circle-line",
      disabled: params.node.data?.itemId == null || params.node.data?.hasChildren || disabled,
      handler: async () => {
        let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(params.node.id);
        if (!targetNode) {
          console.warn('Target node not found!');
          return;
        }

        await box.showCustomDialog(UpdateItems, params.node.data.id, null, {
          model: {
            data: targetNode.data,
            api: dataLineApiService,
            projectId: projectId,
            parentId: versionId,
            columnVisible: columnVisible,
            updateItemType: updateItemType,
            agGridViewModule: agGridViewModule
          },
          size: "xl"
        }).whenClosed(async (result) => {
          if (!result.wasCancelled) {
            priceOfferLinesGrid.refreshVisibleNodes(result.output);
            await getMeteringTotalPrices(versionId, dataLineApiService as IDataLineApiService, _this);
          }
        });
      }
    }
  }
}



