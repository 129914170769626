import { AgGridView, EntityTypeNames, FieldType, UserListBase, Various } from 'digiwall-lib';
import { autoinject, useView } from 'aurelia-framework';
import { Merlin } from "../generated";
import * as Constants from '../constants';
import moment from 'moment';
import { ColDef } from 'ag-grid-community';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
@useView("./user-list.html")
export class UserList extends UserListBase<Merlin.Web.Model.MerlinUser> {
  public ressourceName: string = Constants.EntityTypeNames.MerlinUser;
  public pathDetail: string;

  public get ribbonHeaderText() {
    return this.i18n.tr("common:user.users");
  }

  constructor() {
    super('single', Constants.EntityTypeNames.MerlinUser);
  }

  public getDetailsUrl(self, entity: Merlin.Web.Model.MerlinUser) {
    if (entity?.id) {
      return this.pathDetail + entity.id;
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = 'parameters/users/';
    super.initializeGridOptions(false, false);
  }

  public afterSetAgGridView(agGridView: any) {
    this.usedView = agGridView
  }

  public getDataGridColumns(): any {
    let defs: ColDef<any>[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr(`user.${this.authService.usernameType}`, { ns: 'common' }),
        field: "username",
        type: FieldType.String,
        sort: 'asc',
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("common:user.lastName", { ns: 'common' }),
        field: "lastName",
        type: FieldType.String,
        sort: 'asc',
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("common:user.firstName", { ns: 'common' }),
        field: "firstName",
        type: FieldType.String,
        sort: 'asc',
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("common:user.disabled", { ns: 'common' }),
        field: "disabled",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("role.roles", { ns: 'common' }),
        field: "roles.role.name._translation",
        cellRenderer: 'manyToManyTagRenderer',
        type: FieldType.ManyToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          serviceName: EntityTypeNames.Role,
        },
        filterParams: {
          serviceName: EntityTypeNames.Role,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs
  }

} 
