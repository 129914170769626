@import "../../css/scss/variables";

ul.navigation-detail2 {
  > ui-field {
    max-width: 250px !important;
    //position: absolute;
    border-right: 1px solid $gray-very-light;
    padding-right: 5px;
    padding: 0;
    display: block;
    position: relative;
    > ui-select {
      border: none;
      padding-bottom: 0;
      height: auto !important;
      > .select2-container {
        padding: 0 10px;
        min-height: inherit !important;
        &:hover {
          background-color: $purple-lightest;
          > .selection > .select2-selection > .select2-selection__arrow {
            b {
              border-color: $blue-mid !important;
            }
          }
        }
        > .selection > .select2-selection > .select2-selection__arrow {
          right: 12px;
        }
      }
    }
    .select2-selection.select2-selection--single {
      overflow: hidden;
    }
  }
}
