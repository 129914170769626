import { HttpClient } from 'aurelia-fetch-client';
import { CustomLogger, FileFlow, ServiceBase } from 'digiwall-lib';
import * as Constants from '../../../constants';
import * as breeze from 'breeze-client';
import { autoinject, bindable, customElement, observable } from "aurelia-framework";
import Cropper from 'cropperjs';
import { DialogService } from 'aurelia-dialog';
import { CropperDialog } from './cropper-dialog';

@customElement("ui-cropper-picture")
@autoinject
export class UICropperPicture {
  @bindable
  public pictureUrl: string

  @bindable
  public backUpUrl: string

  @bindable
  public service: ServiceBase<breeze.Entity>;

  @bindable
  public mode: UICropperPictureMode;

  @bindable
  public folderName: string;


  public cropper: Cropper;
  private cropperActive: boolean = false;
  private editingPictureUrl: string

  constructor(private logger: CustomLogger, private httpClient: HttpClient, private dialogService: DialogService) {

  }

  bind() {
    if (this.backUpUrl == null) {
      this.backUpUrl = Constants.Asset.PLACEHOLDER_IMAGE;
    }
  }

  public async editPicture() {
    await this.dialogService.open({
      viewModel: CropperDialog,
      model: {
        mode: this.mode,
        folderName: this.folderName,
        pictureUrl: this.editingPictureUrl
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("z-index-level-4");
        dialogOverlay.classList.add("z-index-level-4");
      },
    }).whenClosed(async result => {
      if (!result.wasCancelled) {
        this.pictureUrl = result.output;
        await this.service.save(false);
      }
    });
  }

  public async pictureUploaded(fileUploaded: FileFlow) {
    this.editingPictureUrl = fileUploaded.url;
    await this.editPicture();

  }

  public async deletePicture() {
    this.pictureUrl = null;
    setTimeout(async () => {
      await this.service.save(true);
    }, 1);
  }
}


export enum UICropperPictureMode {
  Round,
  Rectangle
}
