.ux-add-files {
  max-width: 1000px !important;
  > ux-dialog-header {
    > .grid-dialog-header__icon > .digi-file-add-line {
      &::before {
        content: "\ec43";
      }
    }
    .bold-text {
      font-weight: 600;
    }
  }
  > ux-dialog-body.step2 {
    overflow: scroll;
    height: 500px;
  }
  > ux-dialog-body {
    ui-alert {
      > .ui-alert {
        border-left: none;
        > .ui-alert-container {
          align-items: center;
          > ui-icon::before {
            content: "\e95b";
          }
        }
      }
    }
    ui-field > ui-select.ui-select--multiple {
      .select2 > .selection > .select2-selection {
        .select2-selection__choice > ui-button.select2-selection__choice__remove {
          .digi-cross {
            font-size: 10px !important;
          }
        }
      }
    }
    > .gap-10 {
      display: flex;
      gap: 10px;
    }
  }
  > ux-dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div:last-child {
      gap: 10px;
      display: flex;
      align-items: center;
    }
  }
}
