import { FilterQueryOp, Predicate } from 'breeze-client';
import { autoinject, Container } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Box, CustomLogger, EnumerationTypeService, FloatingBoxViewModel, PictureHelper, ServiceBase } from 'digiwall-lib';
import { Merlin } from 'generated';
import * as Constants from '../constants';
import { DocumentService } from 'documents/document-service';
import { AddDocument } from 'documents/add-document';
import { DialogService } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';

@autoinject
export class EditPriceOfferLineFile extends FloatingBoxViewModel {
  private Constants: any = Constants;
  private fileTypeService: EnumerationTypeService;
  private priceOfferLineDocumentService: ServiceBase<Merlin.Web.Model.PriceOfferLineDocument>;
  private selectedFiles: Array<Merlin.Web.Model.PriceOfferLineDocument> = [];
  public projectService: ServiceBase<Merlin.Web.Model.Project>;

  documents: any[];
  predicateLine: Predicate;


  constructor(private id: number, private readonlyDocument: boolean, private i18n: I18N, private fileHelper: PictureHelper, private projectId: number, private priceOfferVersionId: number, private workQuotationVersionId: number) {
    super('./edit-price-offer-line-file.html', 'md');
    this.priceOfferLineDocumentService = new ServiceBase<Merlin.Web.Model.PriceOfferLineDocument>(Constants.EntityTypeNames.PriceOfferLineDocument);
    this.fileTypeService = new EnumerationTypeService(Constants.EnumerationTypes.FileType);
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  public async activate(params) {
    // this.projectId = params.projectId;
    // this.versionId = params.versionId;
    await this.loadDocuments();
  }

  private async loadDocuments() {
    this.predicateLine = new Predicate("lineId", FilterQueryOp.Equals, this.id);
    let docs = await this.priceOfferLineDocumentService.getEntities(this.predicateLine, ['document.fileType', 'document.createdBy']);
    this.documents = docs.map(x => x.document);
  }

  public async deleteFile(file: Merlin.Web.Model.Document) {
    let p1 = new Predicate("documentId", FilterQueryOp.Equals, file.id);
    let doc = await this.priceOfferLineDocumentService.firstEntity(p1.and(this.predicateLine));
    await this.priceOfferLineDocumentService.deleteEntities([doc], true);
  }

  public download() {
    this.selectedFiles?.forEach(file => {
      this.fileHelper.downloadFile(file.document);
    });
  }

  public async close() {
    await this.dialogController.close(true);
  }

  public async confirme() {
    await this.dialogController.ok();
  }


  async addDocument() {
    let documentService = new DocumentService(
      Container.instance.get(HttpClient),
      Container.instance.get(I18N),
      Container.instance.get(Box),
      Container.instance.get(CustomLogger),
    )
    documentService.projectId = this.projectId;
    documentService.priceOfferVersionId = this.priceOfferVersionId;
    documentService.workQuotationVersionId = this.workQuotationVersionId;
    documentService.lineId = this.id;
    documentService.projectPhaseId = (this.priceOfferVersionId != null || this.workQuotationVersionId != null) ? Constants.ProjectPhaseId.PriceOffer : Constants.ProjectPhaseId.Buying;
    let dialogService = Container.instance.get(DialogService);
    let defaultLanguageId = (await this.projectService.getEntityById(this.projectId)).communicationLanguageId;

    await dialogService.open({
      viewModel: AddDocument,
      model: {
        service: documentService,
        folderName: "project/" + this.projectId + (this.priceOfferVersionId != null ? "/workQuotationVersion/" + this.priceOfferVersionId : "") + "/line/" + documentService.lineId,
        defaultLanguageId: defaultLanguageId,
        documentModuleId: Constants.DocumentModuleId.Project
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("z-index-level-4");
        dialogOverlay.classList.add("z-index-level-4");
      },
    }).whenClosed(async result => {
      let docs = await this.priceOfferLineDocumentService.getEntities(new Predicate("priceOfferLineId", FilterQueryOp.Equals, this.id), ['document.fileType', 'document.createdBy']);
      this.documents.splice(0);
      this.documents.push(...docs.map(x => x.document));
    });
  }
}
