import { autoinject, Container } from "aurelia-framework";/*  */
import { AuthService } from "digiwall-lib";
import { Merlin } from "generated";
import { NotificationApiService, NotificationDTO } from "services/notification-api-service";
import * as Constants from '../../constants';
import { I18N } from "aurelia-i18n";

@autoinject
export class NotificationInternalTeamService {
  private previousProjectInternalTeam: PreviousInternalTeam;

  constructor(private notificationService: NotificationApiService, private authService: AuthService, private i18n: I18N) { }

  public setPreviousInternalTeams(projectUsers: Merlin.Web.Model.ProjectUsers) {
    if (projectUsers != null) {
      this.previousProjectInternalTeam = {
        projectUserSalesId: projectUsers.projectUserSalesId,
        projectUserPreparationId: projectUsers.projectUserPreparationId,
        projectUserSecretaryId: projectUsers.projectUserSecretaryId,
        projectUserFinanceId: projectUsers.projectUserFinanceId,
        projectUserExecutiveManagementId: projectUsers.projectUserExecutiveManagementId,
        projectUserTechnicalManagementId: projectUsers.projectUserTechnicalManagementId,
        projectUserSecurityId: projectUsers.projectUserSecurityId,
        projectUserPriceOffer: projectUsers.projectUserPriceOffer.map(x => x.merlinUserId),
        projectUserBuyings: projectUsers.projectUserBuyings.map(x => x.merlinUserId),
        projectUserExecutions: projectUsers.projectUserExecutions.map(x => x.merlinUserId)
      }
    }
  }

  public async sendNotificationToInternalTeam(project: Merlin.Web.Model.Project) {
    let users: Merlin.Web.Model.MerlinUser[] = []
    if ((this.previousProjectInternalTeam == null && project.projectUsers.projectUserSales != null) || (this.previousProjectInternalTeam != null && this.previousProjectInternalTeam.projectUserSalesId != project.projectUsers.projectUserSalesId)) {
      users.push(project.projectUsers.projectUserSales);
    }
    if ((this.previousProjectInternalTeam == null && project.projectUsers.projectUserPreparation != null) || (this.previousProjectInternalTeam != null && this.previousProjectInternalTeam.projectUserPreparationId != project.projectUsers.projectUserPreparationId)) {
      users.push(project.projectUsers.projectUserPreparation);
    }
    if ((this.previousProjectInternalTeam == null && project.projectUsers.projectUserSecretary != null) || (this.previousProjectInternalTeam != null && this.previousProjectInternalTeam.projectUserSecretaryId != project.projectUsers.projectUserSecretaryId)) {
      users.push(project.projectUsers.projectUserSecretary);
    }
    if ((this.previousProjectInternalTeam == null && project.projectUsers.projectUserFinance != null) || (this.previousProjectInternalTeam != null && this.previousProjectInternalTeam.projectUserFinanceId != project.projectUsers.projectUserFinanceId)) {
      users.push(project.projectUsers.projectUserFinance);
    }
    if ((this.previousProjectInternalTeam == null && project.projectUsers.projectUserExecutiveManagement != null) || (this.previousProjectInternalTeam != null && this.previousProjectInternalTeam.projectUserExecutiveManagementId != project.projectUsers.projectUserExecutiveManagementId)) {
      users.push(project.projectUsers.projectUserExecutiveManagement);
    }
    if ((this.previousProjectInternalTeam == null && project.projectUsers.projectUserTechnicalManagement != null) || (this.previousProjectInternalTeam != null && this.previousProjectInternalTeam.projectUserTechnicalManagementId != project.projectUsers.projectUserTechnicalManagementId)) {
      users.push(project.projectUsers.projectUserTechnicalManagement);
    }
    if ((this.previousProjectInternalTeam == null && project.projectUsers.projectUserSecurity != null) || (this.previousProjectInternalTeam != null && this.previousProjectInternalTeam.projectUserSecurityId != project.projectUsers.projectUserSecurityId)) {
      users.push(project.projectUsers.projectUserSecurity);
    }

    if (this.previousProjectInternalTeam == null) {
      users.push(...project.projectUsers.projectUserPriceOffer.map(x => x.merlinUser));
      users.push(...project.projectUsers.projectUserBuyings.map(x => x.merlinUser));
      users.push(...project.projectUsers.projectUserExecutions.map(x => x.merlinUser));
    }
    else {
      users.push(...project.projectUsers.projectUserPriceOffer.filter(x => !this.previousProjectInternalTeam.projectUserPriceOffer.includes(x.merlinUserId)).map(x => x.merlinUser));
      users.push(...project.projectUsers.projectUserBuyings.filter(x => !this.previousProjectInternalTeam.projectUserBuyings.includes(x.merlinUserId)).map(x => x.merlinUser));
      users.push(...project.projectUsers.projectUserExecutions.filter(x => !this.previousProjectInternalTeam.projectUserExecutions.includes(x.merlinUserId)).map(x => x.merlinUser));
    }
    await this.sendNotificationProjectTeamAsssignement(users.filter(this.onlyUnique), project);

    this.setPreviousInternalTeams(project.projectUsers);
  }

  private onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  private async sendNotificationProjectTeamAsssignement(users: Merlin.Web.Model.MerlinUser[], project: Merlin.Web.Model.Project) {
    let notifications: NotificationDTO[] = []
    users.forEach(user => {
      notifications.push({
        title: project.projectName,
        message: this.i18n.tr("project.addedToTeam", { lng: user.languageCode }) + " " + project.projectName.toUpperCase(),
        notifiedById: this.authService.currentUser.id,
        notifiedUserId: user.id,
        urlRedirection: `/projects/${project.id}`,
        notificationTypeId: Constants.NotificationTypeId.ProjectTeamAsssignement
      })
    });
    await this.notificationService.createNotifications(...notifications)
  }
}



export interface PreviousInternalTeam {
  projectUserSalesId: number;
  projectUserPreparationId: number;
  projectUserSecretaryId: number;
  projectUserFinanceId: number;
  projectUserExecutiveManagementId: number;
  projectUserTechnicalManagementId: number;
  projectUserSecurityId: number;
  projectUserPriceOffer: Array<number>;
  projectUserBuyings: Array<number>;
  projectUserExecutions: Array<number>;
}
