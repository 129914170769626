@import "./../../../css/scss/variables";

.select-mapping-inject {
  display: grid;
  grid-template-columns: 50% 50%;
  .import-preview {
    // padding-right: 10px !important;
    height: 99%;
    margin-left: 10px;
  }
  .import-content {
    // flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    > div {
      overflow: hidden;
      .ui-input {
        padding: 0 10px;
      }
    }
    .import-content-mapping {
      display: grid;
      align-items: center;
      grid-template-columns: auto 230px;
      grid-template-areas: "label firstValue";
      border: 1px solid $gray-very-light;
      border-bottom: none;
      > div {
        &:first-child {
          grid-area: label;
          margin-left: 10px;
        }
        &:last-child {
          grid-area: firstValue;
          flex: 200px;
          height: 100%;
          display: flex;
          align-items: center;
          border-left: 1px solid $gray-very-light;
        }
        > ui-select {
          border-color: transparent;
          &.ui-select--focus {
            border-color: transparent !important;
          }
          > .select2-container span.select2-selection .select2-selection__clear {
            margin-top: 4.5px;
            height: 25px;
          }
        }
      }
      &:last-child {
        border-bottom: 1px solid $gray-very-light;
      }
    }
  }
  .import-header {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;
    margin-bottom: 38px;
    > .import-header__label {
      font-weight: 300;
      font-size: 28px;
      line-height: 36px;
      color: $black-100;
    }
    > .import-header__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $black-100;
    }
  }
}
