.theme-light {
  ui-page {
    ui-field {
      ui-textarea {
        height: calc(5 * 2.6rem) !important;
        &.ui-input {
          align-items: start;
          > textarea {
            height: 100% !important;
            padding-right: 0px !important;
          }
        }
        &.ui-input--textarea {
          grid-template-areas: "addon-start error input clear addon-end";
        }
      }
    }
  }
}
