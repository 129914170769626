import { DialogController } from 'aurelia-dialog';
import { ContactPersonDetail } from 'contact-persons/contact-person-detail';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, EnumerationTypeService, Language, Country, Various, IAnchorTab, IMenuGroup, DialogBoxViewModel, ActionDialogBoxInputParameters, FileFlow, EnumerationType } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';
import { EmailSenderBox } from 'email-sender/email-sender-box';
import MerlinBreezeModel from 'model';
import environment from 'environment';
import { ThirdPartyApiService } from 'services/third-party-api-service';

@autoinject
export class ThirdPartyDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ThirdParty> {
  public Constants: any = Constants;

  private menuItems: Array<IMenuGroup>;
  private menuItemsContactPerson: Array<IMenuGroup>;

  private addressTypeService: EnumerationTypeService;
  private addressService: ServiceBase<Merlin.Web.Model.Address>;
  private countryService: ServiceBase<Country>;
  private contactPersonService: ServiceBase<Merlin.Web.Model.ContactPerson>;

  private clientAddress: Merlin.Web.Model.Address = null;
  private loadingAddresses: boolean = true;
  private loadingContactPersons: boolean = true;

  public isFromPopup: boolean = false;

  public tabList: Array<IAnchorTab>;

  //private hasAlreadyMain : boolean = false;
  private thirdPartyPredicate: Predicate;

  public lancelotInviteEnabled: boolean;


  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController, private thirdPartyApiService: ThirdPartyApiService) {
    super(router, logger);
    this.addressService = new ServiceBase<Merlin.Web.Model.Address>(Constants.EntityTypeNames.Address);
    this.addressTypeService = new EnumerationTypeService(Constants.EnumerationTypes.AddressType);
    this.countryService = new ServiceBase<Country>(Constants.EntityTypeNames.Country);
    this.contactPersonService = new ServiceBase<Merlin.Web.Model.ContactPerson>(Constants.EntityTypeNames.ContactPerson);

    this.lancelotInviteEnabled = environment.lancelotInviteEnabled;

    super.initialize(new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty));
  }

  public async activate(params) {
    let id = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ spokenLanguageId: null, companyTypeId: null, civilityId: null, underVat: true });
      this.entity.isCompany = true;
      this.entity.active = true;
      if (params.callback) params.callback(this);
    } else {
      this.editingMode = EditingModeEnum.Update;
      await this.loadEntity(id);
      this.thirdPartyPredicate = new Predicate("thirdPartyId", FilterQueryOp.Equals, this.entity.id);
      this.controller.addObject(this.entity);
    }
    this.fetchAddresses();
    if (this.entity.isCompany) {
      this.fetchContactPersons();
    }
    else {
      this.fetchLinkedCompany();
    }
    this.setTabList();
    this.setMenuItems();
  }

  private async loadEntity(id: number) {
    this.entity = await this.service.getEntityById(id, 'civility', 'companyType', 'spokenLanguage', 'thirdPartyTypes.thirdPartyTypeEnum', 'thirdPartyTags.thirdPartyTagEnum', 'addresses', 'thirdPartyActivityCodes.activityCode', 'subType', 'lancelotInvitations.status');
  }

  @computedFrom("entity.logoURl")
  public get currentLogo(): string {
    return (this.entity.logoURl ? this.entity.logoURl : MerlinBreezeModel.noPictureContent);
  }

  public pictureUploaded(fileUploaded: FileFlow) {
    this.entity.logoURl = fileUploaded.url;
  }
  public deletePicture() {
    this.entity.logoURl = null;
  }
  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: "tab1",
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      },
      {
        id: "tab2",
        name: this.i18n.tr("groupTabPanel.addresses"),
        isVisible: !this.isFromPopup && !this.isCreationMode
      },
      {
        id: "tab3",
        name: this.i18n.tr("groupTabPanel.contactPersons"),
        isVisible: this.entity.isCompany && !this.isCreationMode
      },
      {
        id: "tab4",
        name: this.i18n.tr("groupTabPanel.linkedCompany"),
        isVisible: !this.entity.isCompany && !this.isCreationMode
      }
    );
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("notificationsent.send"),
            icon: "digi-mail-send-line",
            handler: () => {
              this.sendEmail();
            }
          },
          {
            label: this.i18n.tr("menu.inviteCustomerToLancelot"),
            icon: "digi-mail",
            hidden: !this.lancelotInviteEnabled,
            disabled: this.entity.emailAddress === null || this.entity.emailAddress === '' || this.hasAlreadyBeenInvited() === true,
            handler: async () => {
              await this.inviteCustomerToLancelot();
            }
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      }
    ];
  }

  @computedFrom('editingMode', 'entity.lastNameCompanyName')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("thirdparty.addThirdParty");
    }
    else {
      return this.entity.lastNameCompanyName;
    }
  }

  public ressourceName: string = Constants.EntityTypeNames.ThirdParty;

  public get hasAlreadyMain(): boolean {
    return this.entity.addresses.some(x => x.addressTypeId == Constants.AddressType.Main);
  }



  //#region address
  public async fetchAddresses() {
    if (this.loadingAddresses) {
      await this.addressService.getEntities(this.thirdPartyPredicate, ['country', 'locality', 'addressType']);
      this.loadingAddresses = false;
    }
  }

  public async deleteAddress(address: Merlin.Web.Model.Address) {
    await this.addressService.deleteEntities([address], true);
  }

  public addCallbackAddress(address: Merlin.Web.Model.Address) {
    address.thirdPartyId = this.entity.id;
    address.thirdParty = this.entity;
  }
  //#endregion address


  //#region contact person
  public getContactMenuItem(currentThis: any) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.edit"),
            icon: "digi-edit",
            handler: async () => {
              await this.goToContactPerson(currentThis);
            }
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: async () => {
              await this.contactPersonService.deleteEntities([currentThis], true);
            }
          }
        ]
      }
    ]
  }

  public async fetchContactPersons() {
    if (this.loadingContactPersons) {
      await this.contactPersonService.getEntities(new Predicate("companyId", FilterQueryOp.Equals, this.entity.id), ['person.civility', 'companyFunction', 'contactPersonRoles.role']);
      this.loadingContactPersons = false;
    }
  }

  public async addContactPerson() {
    this.box.showEditDialog(ContactPersonDetail, -100, this.i18n.tr('contactperson.addContactPerson'), (VM: ContactPersonDetail) => {
      VM.companyId = this.entity.id;
    },
      {
        canSave: false,
        actions: [
          EditDialogAction.GetSaveAction(this.i18n)
        ]
      });
  }

  public async goToContactPerson(contactPerson: Merlin.Web.Model.ContactPerson) {
    //this.router.navigate(this.router.baseUrl + `/third-parties/${contactPerson.personId}`);
    this.box.showEditDialog(ContactPersonDetail, contactPerson.id, this.i18n.tr('contactperson.contactPersons'), (VM: ContactPersonDetail) => {
      VM.companyId = this.entity.id;
    },
      {
        canSave: false,
        actions: [
          EditDialogAction.GetSaveAction(this.i18n)
        ]
      });
  }
  //#endregion

  //#region linked company
  public getLinkedCompanyMenuItem(currentThis: any) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.edit"),
            icon: "digi-edit",
            handler: async () => {
              await this.goToLinkedCompany(currentThis);
            }
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: async () => {
              await this.contactPersonService.deleteEntities([currentThis], true);
            }
          }
        ]
      }
    ]
  }

  private loadingLinkedCompany: boolean = true
  public async fetchLinkedCompany() {
    if (this.loadingLinkedCompany) {
      await this.contactPersonService.getEntities(new Predicate("personId", FilterQueryOp.Equals, this.entity.id), ['company.addresses', 'companyFunction', 'contactPersonRoles.role']);
      this.loadingLinkedCompany = false;
    }
  }

  public async addLinkedCompany() {
    this.box.showEditDialog(ContactPersonDetail, -100, this.i18n.tr('contactperson.linkedCompany'), (VM: ContactPersonDetail) => {
      VM.personId = this.entity.id;
    },
      {
        canSave: false,
        actions: [
          EditDialogAction.GetSaveAction(this.i18n)
        ]
      });
  }

  public async goToLinkedCompany(contactPerson: Merlin.Web.Model.ContactPerson) {
    //this.router.navigate(this.router.baseUrl + `/third-parties/${contactPerson.companyId}`);
    this.box.showEditDialog(ContactPersonDetail, contactPerson.id, this.i18n.tr('contactperson.linkedCompany'), (VM: ContactPersonDetail) => {
      VM.personId = this.entity.id;
    },
      {
        canSave: false,
        actions: [
          EditDialogAction.GetSaveAction(this.i18n)
        ]
      });
  }

  //#endregion

  //#region Lancelot
  public async inviteCustomerToLancelot() {
    await this.box.showQuestion(this.i18n.tr("thirdparty.wantToInvite") + this.entity.emailAddress + "?", this.i18n.tr("thirdparty.invitation"),
      [
        {
          label: this.i18n.tr('general.yes'),
          fn: async (dialogBox) => {
            let result = await this.thirdPartyApiService.inviteThirdParty(this.entity.id);
            if (result) {
              this.log("person.successfullyInvited", null, true);
              await this.loadEntity(this.entity.id);
            }
            else {
              this.logError("common:serverExceptions.unattendedError", null, true);
            }

            dialogBox.controller.ok();
          }
        },
        {
          label: this.i18n.tr('general.no'),
          fn: (dialogBox) => {
            dialogBox.controller.ok();
          }
        }
      ]);
  }


  public async cancelInvitation(invitation: Merlin.Web.Model.LancelotInvitation) {
    let mainEmail = (await this.service.getEntityById(this.entity.id)).emailAddress;
    await this.box.showQuestion(this.i18n.tr("thirdparty.cancelInvite") + mainEmail + "?", this.i18n.tr("general.cancel"),
      [
        {
          label: this.i18n.tr('general.yes'),
          fn: async (dialogBox) => {
            let result = await this.thirdPartyApiService.cancelInvite(this.entity.id, invitation.id);
            if (result) {
              this.log("thirdparty.inviteCancelled", null, true);
            }
            else {
              this.logError("common:serverExceptions.unattendedError", null, true);
            }

            dialogBox.controller.ok();
          }
        },
        {
          label: this.i18n.tr('general.no'),
          fn: (dialogBox) => {
            dialogBox.controller.ok();
          }
        }
      ]);
  }


  public getInvitationMenuItem(currentThis: Merlin.Web.Model.LancelotInvitation) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("general.cancel"),
            icon: "digi-trash",
            disabled: () => !(currentThis.statusId == Constants.LancelotInvitationStatusId.Pending || currentThis.statusId === Constants.LancelotInvitationStatusId.Sent),
            handler: async () => {
              await this.cancelInvitation(currentThis);
            }
          }
        ]
      }
    ]
  }

  public hasAlreadyBeenInvited() {
    return !!this.entity.lancelotInvitations.find(x => x.statusId === Constants.LancelotInvitationStatusId.Pending || x.statusId === Constants.LancelotInvitationStatusId.Accepted || x.statusId === Constants.LancelotInvitationStatusId.Sent);
  }


  //#endregion lancelot

  //#region email
  public async sendEmail() {
    await this.box.showEditDialog(EmailSenderBox, null, this.i18n.tr("notificationsent.emailGeneration"), null, {
      model: {
        idsToSend: !this.entity.isCompany ? [this.entity.id] : null,
        emailContext: Constants.EmailContextId.ThirdParty,
        disableTo: !this.entity.isCompany,
        companyId: this.entity.isCompany ? this.entity.id : null,
        previewRequestParams: { thirdPartyId: this.entity.id } as Merlin.Web.Model.EmailTemplatePreviewRequest,
      },
      canSave: false,
      actions: [
        {
          label: this.i18n.tr("notificationsent.sendEmail"),
          title: this.i18n.tr("notificationsent.sendEmail"),
          theme: "primary",
          type: "solid",
          closeDialog: false,
          fn: async (dialogBoxViewModel: DialogBoxViewModel) => {
            await dialogBoxViewModel.customVM.currentViewModel.sendEmail();
          }
        } as ActionDialogBoxInputParameters
      ],
      size: 'all',
    });
  }
  //#endregion email

  public saveCurrentEntity(silentSave?: boolean): Promise<Merlin.Web.Model.ThirdParty> {
    return super.saveCurrentEntity(silentSave);
  }

}
