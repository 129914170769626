// ux-dialog-header .dialog-header-content {
//   display: flex;
//   flex-direction: row-reverse;
// }
// ux-dialog-body {
//   padding: 0 30px !important;
//   custom-ag-grid {
//     > div {
//       margin: 0 40px;
//     }
//   }
// }
.dialog-articles-history .dialog-header-content {
  display: flex;
  flex-direction: row-reverse;
}
