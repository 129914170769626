import { RowNode } from 'ag-grid-community';
import { AgCellComment } from './ag-cell-comment';
import { UIInternal } from 'digiwall-lib';
import { TemplatingEngine, View, ViewSlot } from 'aurelia-framework';
import { ColDef } from 'ag-grid-community';
import IGridCellCommentable from './grid-cell-commentable.interface';
import IPriceOfferLineCommentApiService from 'services/price-offer-line-comment-api-service';

export const DATA_ATTRIBUTE_AG_ROW_ID = "row-id";
export const DATA_ATTRIBUTE_AG_ARIA_COLINDEX = "aria-colindex";
export const CSS_CLASS_AG_ROW = "ag-row";
export const CSS_CLASS_AG_ROW_DISABLED = "ag-row-disabled";
export const CSS_CLASS_AG_CELL = "ag-cell";
export const ELEMENT_TAG_AG_CELL_COMMENT = "ag-cell-comment";
export const CSS_CLASS_AG_CELL_COMMENT_DISABLED = "ag-cell-comment-disabled";
export const ELEMENT_TAG_AG_CELL_COMMENT_BOX = "ag-cell-comment-box";
export const CSS_CLASS_AG_CELL_COMMENT_CELL_HOVER = "ag-cell-comment-cell-hover";
export const CSS_CLASS_AG_CELL_COMMENT_COMMENTED = "ag-cell-comment-commented";
export const CSS_CLASS_UI_DROP = "ui-drop";

export class AgCellCommentService {
  private initCellsTimerId;
  private initialized = false;
  private debug = false;
  agCellCommentComponents: Map<HTMLDivElement, View> = new Map<HTMLDivElement, View>();

  /**
   * Creates an instance of AgCellCommentService.
   * @param {TemplatingEngine} templatingEngine
   * @param {string} [fieldsListDataProperty="FieldsComments"] Data property name of the fields list containing a comment.
   * @param {string} [fieldsListDataPropertySeparator=","] Data property fields list separator.
   * @memberof AgCellCommentService
   */
  constructor(
    private fieldsListDataProperty: string | Array<string> = "fieldsComments",
    private fieldsListDataPropertySeparator: string = ",",
    private agGridInstance: IGridCellCommentable, public apiService: IPriceOfferLineCommentApiService,
    private readonly = false,
    private customIsCellCommented: Function = null) {

    if (this.debug) console.log("AgCellCommentService : constructor");
    if (!(this.initialized = !(fieldsListDataProperty?.length == 0 || fieldsListDataPropertySeparator?.trim().length == 0))) {
      console.warn('AgCellCommentService: `fieldsListDataProperty` and `fieldsListDataPropertySeparator` cannot be null or empty !');
    }
  }

  /**
   * Check if the given gridInstance implements the IGridCellCommentable interface.
   *
   * @private
   * @param {*} gridInstance
   * @return {*}  {gridInstance is IGridCellCommentable}
   * @memberof AgCellCommentService
   */
  private implementIGridCellCommentable(gridInstance: any): gridInstance is IGridCellCommentable {
    return gridInstance != null && "refreshVisibleNodes" in gridInstance && "gridOptions" in gridInstance && "gridRef" in gridInstance;
  }
  /**
   * Append a ag-cell-comment in each cell if the colDef.cellRendererParams.canComment of the cell's column is true.
   *
   * @param {HTMLElement} cell The ag-grid cell to append the ag-cell-comment
   * @param {RowNode} node The ag-grid node
   * @param {ColDef} colDef The ag-grid definition of the column
   * @return {ViewSlot} ViewSlot containing ag-cell-comment
   * @memberof AgCellCommentService
   */
  public initCell(cell: HTMLElement, node: RowNode<any>, colDef: ColDef) {
    if (this.debug) console.log("AgCellCommentService : initCell");
    if (!this.initialized) return;
    if (!this.implementIGridCellCommentable(this.agGridInstance)) {
      console.warn('AgCellCommentService: gridInstance must implement IGridCellCommentable.');
      return;
    }
    const agCellComment = UIInternal.compileTemplate(`
          <template>
            <ag-cell-comment 
              row-node.bind="rowNode" 
              col-def.bind="colDef" 
              grid-instance.bind="gridInstance"
              fields-list-data-property.bind="fieldsListDataProperty"
              fields-list-data-property-separator.bind="fieldsListDataPropertySeparator"
              api.bind="apiService"
              readonly.bind="readonly"
              is-cell-commented.bind="customIsCellCommented">
              </ag-cell-comment>
          </template>`, this, {
      rowNode: node,
      colDef: colDef,
      gridInstance: this.agGridInstance,
      fieldsListDataProperty: this.fieldsListDataProperty,
      fieldsListDataPropertySeparator: this.fieldsListDataPropertySeparator,
      apiService: this.apiService,
      readonly: this.readonly,
      customIsCellCommented: this.customIsCellCommented
    }) as View;

    let slot = new ViewSlot(cell, true);
    slot.add(agCellComment);
    slot.attached();
    return slot;
  }
}
