
import { ColDef } from 'ag-grid-community';
import * as Constants from '../../constants';
import { autoinject } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { CustomLogger, FieldType, ListViewModelBase, ServiceBase, Various } from "digiwall-lib";
import { Merlin } from "generated";
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { DialogController } from 'aurelia-dialog';

@autoinject
export class ItemContractList extends ListViewModelBase<Merlin.Web.Model.ThirdPartyContractLine> {
  get ressourceName(): string {
    throw new Error("Method not implemented.");
  }
  get ribbonHeaderText() {
    return this.i18n.tr("item.priceHistory")
  }
  private itemId: number;
  private viewModule: string;
  private title: string;

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.ThirdPartyContractLine>(Constants.EntityTypeNames.ThirdPartyContractLine));
    this.service.gridDataSource.expands = ['buyingLine.project', 'thirdPartyContract.supplierOrSubcontractor', 'buyingLine.unit'];

  }

  public async activate(params: any) {
    this.itemId = params.itemId;
    this.service.gridDataSource.queryParameters = { itemId: this.itemId }
  }

  public getDetailsUrl(self: any, entity: Merlin.Web.Model.ThirdPartyContractLine): string {
    if (entity?.id) {
      this.close();
      return '/projects/' + entity.buyingLine.projectId + '/buying/contract/' + entity.thirdPartyContractId
    }
    return null;
  }

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
    this.gridOptions.components = {
      ...this.gridOptions.components
    };
  }

  public getDataGridColumns() {
    let unitService: ServiceBase<Merlin.Web.Model.Unit> = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    let defs: ColDef[] = [
      {
        headerName: this.i18n.tr("thirdpartycontractline.projectName"),
        field: "buyingLine.project.projectName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdpartycontractline.numContract"),
        field: "thirdPartyContract.numContract",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdpartycontractline.supplierOrSubcontractor"),
        field: "thirdPartyContract.supplierOrSubcontractor.fullName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdpartycontractline.signedDate"),
        field: "thirdPartyContract.signedDate",
        type: FieldType.Date,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdpartycontractline.unitName"),
        field: "buyingLine.unit.unitName._translation",
        type: FieldType.OneToMany,
        filterParams: {
          service: unitService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: unitService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdpartycontractline.quantity"),
        field: "quantity",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdpartycontractline.unitPrice"),
        field: "unitPrice",
        type: FieldType.Currency,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },

      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
  async close() {
    await this.dialogController.close(true);
  }
}
