import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../../constants';
import { IAnchorTab, ServiceBase, TranslatedProperty } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { Merlin } from 'generated';
import { ProjectApiService } from 'services/project-api-service';

@autoinject
export class BuyingSummary {
  projectId: any;
  summary: BuyingSummary;
  tabList: IAnchorTab[];
  private nbDecimalForPriceDisplay: number

  constructor(public router: Router, public httpClient: HttpClient, public i18n: I18N, private projectApiService: ProjectApiService) {

  }

  public async activate(params: any) {
    this.projectId = params.projectId;
    let summaryResult = await this.httpClient.get(Constants.Application.BuyingController.Summary.format(this.projectId));
    if (summaryResult.ok) {
      this.summary = await summaryResult.json();
    }
    this.setTabList();

    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
  }

  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: "tab1",
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      },
      {
        id: "tab2",
        name: this.i18n.tr("groupTabPanel.completeness"),
        isVisible: true
      },
      {
        id: "tab3",
        name: this.i18n.tr("groupTabPanel.buyingAmount"),
        isVisible: true
      },
    );
  }

}

export interface BuyingSummary {
  totalPrice: number; //Total PR Devis des lignes

  totalBoughtValidated: number; //Somme PR des lignes avec statut accepté
  buyingGoalValidated: number; //Somme des PR Devis
  profitPercentageValidated: number | null;
  profitPercentageValidatedIsPositive: boolean;

  totalInProcess: number; //Somme PR des lignes avec statut in process
  buyingGoalInProcess: number; //Somme des PR Devis
  profitPercentageInProcess: number | null; //Perc entre les 2 montants 
  profitPercentageInProcessIsPositive: boolean

  buyingCompleteness: BuyingCompletenessByActivity[];
  buyingPurchaseAmounts: BuyingPurchaseAmountByActivity[];
}

export interface BuyingCompletenessByActivity {
  activityName: TranslatedProperty;
  activityId: number;
  nbLineByActivity: number;
  nbLineInOfferRequest: number;
  nbLineInSupplierOffer: number;
  nbLineInContractInProcess: number;
  nbLineInContractAccepted: number;
}

export interface BuyingPurchaseAmountByActivity {
  activityName: TranslatedProperty;
  activityId: number;
  toBuy: number;
  buyingInProcess: number;
  buyingValidated: number;
  profitOnPurchase: number;
  profitOnPurchaseIsPositive: boolean
}
