@import "../../../../css/scss/variables";

body.ux-dialog-open ux-dialog-container.amendment-creation > div > div > compose > ux-dialog {
  border-radius: $radius;
  background-color: $white !important;
  border: 5px solid $blue-darkest;
  border-left: 0px;
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
  ux-dialog-header {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    padding: 0 50px;
    padding-top: 40px;
    padding-bottom: 20px;
    > .dialog-header-content {
      display: flex;
      justify-content: space-between;
      > div {
        font-weight: 300;
        font-size: 40px;
        line-height: 58px;
      }
      > ui-button {
        height: 34px;
      }
    }
  }
}
