@import "../css/scss/variables";
.summary {
  > .row.summary > .ui-panel {
    .ui-panel__header {
      margin-bottom: 50px;
    }
    .ui-panel__body {
      margin: 0 !important;
      width: 100% !important;
      > .row > .col {
        padding: 0;
        padding-left: 50px;
        border-right: solid 1px $gray-lighter;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          margin-right: 0;
          border-right: none;
        }
        > .ui-field {
          p {
            margin: 0;
            margin: 15px 0;
          }
        }
      }
      .ui-field {
        padding: 0;
        position: initial;
        > label {
          background: transparent;
          position: initial;
        }
      }
    }
  }
  ui-field.label-top > .ui-field__label {
    padding-left: 0 !important;
  }
  .group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    .price-group {
      flex-grow: 1;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-start;
      padding: 40px 60px;
      gap: 36px;

      border: 1px solid $gray-very-light;
      border-radius: $radius;
      &-header {
        label.ui-field__label {
          font-size: 14px;
        }
        ui-field {
          min-width: 200px;
          p {
            font-weight: 400;
            font-size: 36px;
            color: $black-100;
            margin: 0;
            line-height: 52px;
          }
        }
      }
      &-content {
        ui-field {
          align-items: center;
          display: grid;
          grid-template-columns: auto auto;
          padding: 0px;
          padding-bottom: 5px;
          gap: 20px;
          margin-bottom: 5px;
          > .ui-field__label {
            padding-left: 0 !important;
          }
          &.gray-label > label {
            color: $gray-mid;
            width: auto !important;
            > span {
              color: $gray-mid;
            }
          }
          > p {
            margin: 0 !important;
            text-align: right;
          }
        }
      }
    }
  }
  table.completeness {
    thead {
      tr {
        border-bottom: none;
        th {
          font-weight: 400;
        }
      }
      tr:first-child {
        th {
          font-size: 14px;
          line-height: 22px;
          color: $blue-mid;
        }
      }
      tr:last-child {
        th:not(:first-child) {
          font-size: 12px;
          line-height: 15px;
          color: $gray-mid;
          border-bottom: 1px solid $gray-very-light;
        }
        th:nth-child(2),
        th:nth-child(5) {
          border-left: 1px solid $gray-very-light;
        }
      }
    }
    tbody {
      tr {
        td {
          color: $black-100;
          border-right: 1px solid $gray-very-light;
          padding: 14px 0px;
        }
        td:first-child {
          font-size: 15px;
          line-height: 30px;
        }
        td:not(:first-child) {
          font-size: 14px;
          line-height: 22px;
          padding-left: 8px;
        }
      }
    }
  }

  .positive-perc {
    color: $green-valid !important;
  }
  .negative-perc {
    color: $red-warning !important;
  }
}
