import { autoinject } from "aurelia-framework";
import { FloatingBoxViewModel, ServiceBase } from "digiwall-lib";
import { Merlin } from "generated";
import * as Constants from '../../../constants';

@autoinject
export class SynchroSelect extends FloatingBoxViewModel {

  public thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  public subContractorSelecteds: Array<number>;
  public allSubContractor: Array<Merlin.Web.Model.ThirdParty>;

  constructor(private projectId: number) {
    super('./synchro-select.html', 'md');
    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
  }

  async activate(params) {
    this.allSubContractor = await this.thirdPartyService.getEntities(null, null, { projectId: this.projectId })
    this.subContractorSelecteds = new Array();
  }

  public async confirm() {
    await this.dialogController.ok(this.subContractorSelecteds);
  }
}

