import { autoinject, computedFrom, observable } from "aurelia-framework";
import { HttpClient } from 'aurelia-fetch-client';
import { I18N } from "aurelia-i18n";
import { Router } from 'aurelia-router';
import { FilterQueryOp, Predicate } from "breeze-client";
import { CustomLogger, EntityDetailViewModelBase, EnumerationTypeService, FileFlow, IMenuGroup, Language, Locality, ServiceBase, UIInternal, ViewModelBase } from "digiwall-lib";
import { Merlin } from "generated";
import moment from "moment";
import * as Constants from '../constants';
import Cropper from 'cropperjs';
import * as toastr from 'toastr';

@autoinject
export class MyAccount extends EntityDetailViewModelBase<Merlin.Web.Model.MerlinUser> {
  @computedFrom('entity.id')
  get documentTitle(): string {
    return this.i18n.tr("myaccount.myaccount")
  }
  get ressourceName(): string {
    return Constants.EntityTypeNames.MerlinUser;
  }

  private localityService: ServiceBase<Locality>;
  private languageService: ServiceBase<Language>;
  private languageEnumService: EnumerationTypeService;
  public languages: Array<any>;
  public cropper: Cropper;
  private cropperActive: boolean = false;
  private readonlyMode: boolean = true;
  private userLanguageLabel: string = "";
  private menuItems: Array<IMenuGroup>;

  // private remoteFileServicePicture: string = Constants.Application.RemoteServiceName + Constants.Application.RemoteFileServicePicture;

  @observable
  private selectedLanguage: any;


  constructor(router: Router, logger: CustomLogger, i18n: I18N, private httpClient: HttpClient) {
    super(router, logger);
    this.localityService = new ServiceBase<Locality>(Constants.EntityTypeNames.Locality);
    this.languageService = new ServiceBase<Language>(Constants.EntityTypeNames.Language);
    this.languageEnumService = new EnumerationTypeService(Constants.EnumerationTypes.Language);
    super.initialize(new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser))
  }

  public async activate(params) {
    let id = this.authService.currentUser.id;
    this.entity = await this.service.getEntityById(id, 'locality');
    this.languages = await this.languageEnumService.getEntities(null, null, { category: Constants.EnumerationTypes.Language });
    let p1 = new Predicate("code", FilterQueryOp.Equals, this.entity.languageCode);
    this.selectedLanguage = (await this.languageService.firstEntity(p1)).columnIndex;

    this.userLanguageLabel = await this.getUserLanguageLabel();
    this.setMenuItems();
  }

  async getUserLanguageLabel() {
    return this.getLanguageLabel(await this.languageEnumService.firstEntity(new Predicate("id", FilterQueryOp.Equals, this.entity.languageId), null, { category: Constants.EnumerationTypes.Language }));
  }

  getLanguageLabel(lang) {
    return this.i18n.tr("general." + lang.uniqueCode[0].toLowerCase() + lang.uniqueCode.substr(1));
  }

  async selectedLanguageChanged(newVal, oldVal) {
    if (newVal != oldVal && newVal != null) {
      let p1 = new Predicate("columnIndex", FilterQueryOp.Equals, newVal);
      let lang = await this.languageService.firstEntity(p1);
      if (lang != null) {
        this.switchLanguage(lang.code);
      }
    }
  }

  public editPicture() {
    this.cropperActive = true;
    const image = document.getElementById('cropper') as HTMLImageElement;
    this.cropper = new Cropper(image, {
      aspectRatio: 1,
      viewMode: 2,
      dragMode: 'move',
      background: true,
    });
  }


  private async cancelFn() {
    await super.cancel();
    this.readonlyMode = true;
  }

  private async saveFn() {
    super.save().then(() => this.readonlyMode = true);
  }

  private cancelEditPicture() {
    this.cropper.destroy();
    this.cropperActive = false;
  }

  public async pictureUploaded(fileUploaded: FileFlow) {
    this.entity.picture = fileUploaded.url;
    this.editPicture();
  }

  public async deletePicture() {
    this.entity.picture = null;
    await this.save();
  }

  public updateProfile() {
    this.readonlyMode = false;
  }

  protected switchLanguage(language: string) {
    if (this.isValidLanguage(language)) {
      this.i18n.setLocale(language);
      moment.locale(language);
      this.eventAggregator.publish('changeLanguage');
    }
  }

  private isValidLanguage(language: string): boolean {
    return (language == 'fr' || language == 'nl' || language == 'en');
  }

  protected async resetPassword() {
    await this.httpClient.post(this.getResetPasswordUrl(), JSON.stringify({ Username: this.entity.username }));
    toastr.success(this.i18n.tr("common:user.resetPasswordSuccessEmail"));
  }

  protected getResetPasswordUrl(): string {
    return `${this.config.globalConfig.remoteServiceName}/api/auth/ResetPassword`;
  }

  private disconnect() {
    this.authService.logout();
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("myaccount.emptyPreference"),
            icon: "digi-delete-bin-2-line",
            handler: () => {
              window.localStorage.clear();
            }
          },
          {
            label: this.i18n.tr("myaccount.exportPreference"),
            icon: " digi-export-csv",
            handler: () => {
              var json = JSON.stringify(window.localStorage);
              var file = new Blob([json], { type: ".txt" });
              var a = document.createElement("a"),
                url = URL.createObjectURL(file);
              a.href = url;
              a.download = "localstorage-" + this.entity.fullName;
              document.body.appendChild(a);
              a.click();
              setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }, 0);
            }
          }
        ]
      }
    ];
  }

}
