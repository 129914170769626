import { RefToDisplay } from '../constants';
import { GlobalLoaderService, KeyboardShortcut } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { JSONPatchOperation } from './json-patch-operation';
import * as Constants from '../constants';
import { I18N } from 'aurelia-i18n';
import { FiltersUtils } from 'resources/utilities/filter-utils';
import { ActionDialogBoxInputParameters, Box, CustomLogger, DialogBoxViewModel } from 'digiwall-lib';
import { PriceOfferLineFromItem } from '../projects/quotes/add-items/add-items';
import { IMeterigSwitchType } from 'resources/metering/metering-menu-items/menu-line-category';
import { IDataLineApiService, RequestFilterPriceOfferLine, RequestCreatePriceOfferLine, RequestMovePriceOfferLine, ImportSessionResponse, MeteringTotalPrices, BulkUpdateOperation } from './i-data-line-api-service';

@autoinject
export class WorkQuotationVersionApiService implements IDataLineApiService, IMeterigSwitchType {

  protected callback: () => void;
  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, protected filtersUtils: FiltersUtils, protected globalLoaderService: GlobalLoaderService, private logger: CustomLogger) { }
  async getLastChapterId(versionId: number): Promise<number> {
    let query = await this.httpClient.get(Constants.Application.WorkQuotationController.GetLastChapterId.format(versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }
  computePAR?(versionId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  deletePAR?(versionId: number, lineId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  async recomputeLines(versionId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.post(Constants.Application.WorkQuotationController.RecomputeLines.format(versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  async switchToPost(versionId: number, lineId: number): Promise<void> {
    let requestUri = Constants.Application.WorkQuotationController.ChangeCategory.format(versionId.toString(), lineId.toString(), Constants.PriceOfferLineCategory.Data.toString());
    let response = await this.httpClient.post(requestUri);
  }
  async switchToChapter(versionId: number, lineId: number): Promise<void> {
    let requestUri = Constants.Application.WorkQuotationController.ChangeCategory.format(versionId.toString(), lineId.toString(), Constants.PriceOfferLineCategory.Chapter.toString());
    let response = await this.httpClient.post(requestUri);
  }
  async switchToComment(versionId: number, lineId: number): Promise<void> {
    let requestUri = Constants.Application.WorkQuotationController.ChangeCategory.format(versionId.toString(), lineId.toString(), Constants.PriceOfferLineCategory.Comment.toString());
    let response = await this.httpClient.post(requestUri);
  }

  subscribe(callback: () => void) {
    this.callback = callback;
  }

  async getSessions(id: number) {
    const mappingResult = await this.httpClient.get(Constants.Application.WorkQuotationController.GetImportSessions.format(id.toString()));
    if (mappingResult) {
      const mapping = await mappingResult.json();
      return mapping;
    }
    return null;
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.WorkQuotationController.GetChildren.format(versionId.toString(), lineId.toString(), displayHidden.toString(), refToDisplay.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.WorkQuotationController.GetAll.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let result = await this.httpClient.post(Constants.Application.WorkQuotationController.GetByIds.format(versionId.toString(), displayHidden.toString(), refToDisplay.toString()), JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.WorkQuotationController.Patch.format(versionId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }
  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.WorkQuotationController.BulkPatch.format(versionId.toString());
    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }
  public async duplicateLine(versionId: number, lineId: number): Promise<Array<number>> {
    let requestUri = Constants.Application.WorkQuotationController.DuplicateLine.format(versionId.toString(), lineId.toString());
    let response = await this.httpClient.post(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }

  public async create(versionId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {
    let requestCreatePriceOfferLine: RequestCreatePriceOfferLine = {
      targetId: targetId,
      categoryId: categoryId,
      fK: versionId,
      action: action
    };

    let requestUri = Constants.Application.WorkQuotationController.CreateLine.format(versionId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(requestCreatePriceOfferLine));
    if (query.ok) {
      return await query.json();
    }
    return [];
  }

  public async move(versionId: number, lineIds: Array<number>, targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {

    let requestMovePriceOfferLine: RequestMovePriceOfferLine = {
      action: moveType,
      targetId: targetId,
      priceOfferLineIds: lineIds
    };

    let requestUri = Constants.Application.WorkQuotationController.Move.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestMovePriceOfferLine));
    if (response.ok) {
      return await response.json();
    }

    return [];
  }

  async paste(versionId: number, lineIds: number[], targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<number[]> {
    let requestMovePriceOfferLine: RequestMovePriceOfferLine = {
      action: moveType,
      targetId: targetId,
      priceOfferLineIds: lineIds
    };

    let requestUri = Constants.Application.WorkQuotationController.Paste.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestMovePriceOfferLine));
    if (response.ok) {
      return await response.json();
    }

    return [];
  }

  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async delete(versionId: number, lineIds: Array<number>): Promise<Array<number>> {
    let result = await this.canDelete(versionId, lineIds);
    if (result) {
      let msg = lineIds.length == 1 ? this.i18n.tr('menu.delete_confirmation') : this.i18n.tr('menu.delete_confirmation_plural', { count: lineIds.length });
      let ids = [];

      let buttonYes: ActionDialogBoxInputParameters = {
        label: this.i18n.tr("general.ok", { ns: "common" }),
        title: this.i18n.tr("general.ok", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(true);
        },
        keyboardShortcut: KeyboardShortcut.Enter
      };
      let buttonNo: ActionDialogBoxInputParameters = {
        label: this.i18n.tr("general.cancel", { ns: "common" }),
        title: this.i18n.tr("general.cancel", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(false);
        },
        keyboardShortcut: KeyboardShortcut.Escape
      };
      await this.box.showQuestion(msg, this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes])
        .whenClosed(async (result) => {
          if (!result.wasCancelled && result.output) {
            let requestUri = Constants.Application.WorkQuotationController.DeleteLine.format(versionId.toString());
            let response = await this.httpClient.delete(requestUri, JSON.stringify(lineIds));
            if (response.ok) {
              let temp = await response.json();
              ids.push(...temp);
            }
          }
        });
      return ids;
    } else {
      this.box.showWarning(this.i18n.tr('versionmetering.notCanDelete'), this.i18n.tr('general.warningTitle'));
      return [];
    }
  }

  private async canDelete(versionId: number, lineIds: Array<number>): Promise<boolean> {
    let requestUri = Constants.Application.WorkQuotationController.CanDelete.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(lineIds));
    if (response.ok) {
      return await response.json();
    }
  }

  async hasCompleteImportMetering(priceOfferVersionId: string): Promise<ImportSessionResponse> {
    const currentSessionResult = await this.httpClient.get(Constants.Application.WorkQuotationVersionApiV1 + "/" + priceOfferVersionId + '/import-session');
    if (currentSessionResult) {
      const hasCompleteMetering: ImportSessionResponse = await currentSessionResult.json();
      return hasCompleteMetering;
    }
    return null;
  }
  public async versionHasLines(versionId: number): Promise<boolean> {
    let requestUri = Constants.Application.WorkQuotationController.VersionHasLine.format(versionId.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }
  async createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<Array<number>> {
    let requestUri = Constants.Application.WorkQuotationController.CreateFromItems.format(versionId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(selectedItems));

    if (query.ok) {
      return await query.json();
    }
    return null;
  }

  async getMeteringTotalPrices(versionId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.get(Constants.Application.WorkQuotationController.PriceOfferVersionTotal.format(versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  async createVariantGroup(versionId: number, lineIds: number[]): Promise<Array<number>> {
    let query = await this.httpClient.post(Constants.Application.WorkQuotationController.CreateNewVariantGroup.format(versionId.toString()), JSON.stringify(lineIds));
    if (query.ok) {
      return await query.json();
    }
  }
  async deleteVariantGroup(versionId: number, lineId: number): Promise<Array<number>> {
    let query = await this.httpClient.delete(Constants.Application.WorkQuotationController.DeleteVariantGroup.format(versionId.toString(), lineId.toString()));
    if (query.ok) {
      return await query.json();
    }
  }

  async deleteSheets(versionId: number, fileId: number, sheets: Array<number>) {
    this.globalLoaderService.allow();
    let query = await this.httpClient.post(Constants.Application.WorkQuotationController.DeleteSheets.format(versionId.toString(), fileId.toString()), JSON.stringify(sheets));
    if (query.ok) {
      return;
    }
  }
  async validateLastImport(versionId: number) {
    this.globalLoaderService.allow();
    let query = await this.httpClient.post(Constants.Application.WorkQuotationController.ValidateLastImport.format(versionId.toString()));
    if (query.ok) {
      return;
    }
  }
}
