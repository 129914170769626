import { HttpClient } from 'aurelia-fetch-client';
import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Various, EnumerationTypeService, ActionDialogBoxInputParameters, DialogBoxViewModel, GlobalLoaderService } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import * as toastr from 'toastr';
import { DocumentTypeDiscriminator } from './request-supplier-offer-detail';
import CustomButtonRenderer from 'resources/renderer/custom-button-renderer';

@autoinject
export class RequestSupplierOfferList extends ListViewModelBase<Merlin.Web.Model.RequestSupplierOffer> {
  workQuotationVersionId: any;
  projectId: any;
  public pathDetail: string;

  type: DocumentTypeDiscriminator;

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private gls: GlobalLoaderService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.RequestSupplierOffer>(Constants.EntityTypeNames.RequestSupplierOffer));
  }

  public ressourceName: string = Constants.EntityTypeNames.RequestSupplierOffer;
  private activityCodeService: EnumerationTypeService;
  public buttonLabel: string = this.i18n.tr("requestsupplieroffer.addRequestsupplieroffer");
  public disabledButton: boolean = false;

  public initializeGridOptions() {
    this.pathDetail = 'request-supplier-offer-detail/';
    super.initializeGridOptions(false, false);
  }
  public async activate(params: any): Promise<void> {
    await super.activate(params);


    this.workQuotationVersionId = params.workQuotationVersionId;
    if (this.workQuotationVersionId != null) {
      this.service = new ServiceBase<Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer>(Constants.EntityTypeNames.WorkQuotationVersionRequestSupplierOffer)
      this.service.gridDataSource.queryParameters = { workQuotationVersionId: params.workQuotationVersionId, onlyCreated: true };
      this.type = DocumentTypeDiscriminator.WorkQuotationVersion;
      let wqService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersion>(Constants.EntityTypeNames.WorkQuotationVersion);
      let wq = await wqService.getEntityById(this.workQuotationVersionId);
      this.disabledButton = wq.needToBeValidated;
    }

    this.projectId = params.projectId;
    if (this.projectId != null) {
      if (this.service == null || this.service.entityTypeName == "RequestSupplierOffer") {
        this.service = new ServiceBase<Merlin.Web.Model.BuyingRequestSupplierOffer>(Constants.EntityTypeNames.BuyingRequestSupplierOffer);
        this.service.gridDataSource.queryParameters = { projectId: params.projectId, onlyCreated: true };
        this.type = DocumentTypeDiscriminator.Buying;
      }
    }
    this.service.gridDataSource.expands = ['requestSupplierOfferActivityCodes.activityCode'];
  }

  public getDetailsUrl(self, entity: Merlin.Web.Model.RequestSupplierOffer) {
    if (entity?.id) {
      let url = this.pathDetail + entity.id;
      if (this.router?.currentInstruction?.queryString?.length > 0) {
        url += '?' + this.router.currentInstruction.queryString;
      }
      return url;
    }
    return null;
  }

  public addRequestSupplierOffer() {
    this.router.navigateToRoute("add-offer", { workQuotationVersionId: this.workQuotationVersionId, projectId: this.projectId });
  }

  public getDataGridColumns() {
    this.activityCodeService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    this.gridOptions.components.customButtonRenderer = CustomButtonRenderer;
    let defs: ColDef[] = [
      {
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererParams: {
          i18n: this.i18n,
          gridOptions: (this as any).gridOptions,
          router: this.router,
          service: this.service,
          pathDetail: (this as any).pathDetail,
          menuItems: (params: ICellRendererParams) => this.getMenuItems != null ? this.getMenuItems(params) : [],
          disabledMenuItem: (this as any).isSelectable,
          list: (this as any),
          canComment: false
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        sortable: false,
        resizable: false,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        pinned: "left",
      },
      new GridHelperMerlin().selectedColDef(this),
      {
        headerName: this.i18n.tr("requestsupplieroffer.id"),
        field: "requestOfferID",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      // {
      //   headerName: this.i18n.tr("requestsupplieroffer.requestOfferTypeID"),
      //   field: "requestOfferType.denomination._translation",
      //   type: FieldType.Enumeration,
      //   filterParams: {
      //     category: Constants.EnumerationTypes.SupplierOfferType,
      //   },
      //   floatingFilterComponentParams: {
      //     suppressFilterButton: true,
      //     category: Constants.EnumerationTypes.SupplierOfferType,
      //   },
      //   showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      // },
      {
        headerName: this.i18n.tr("requestsupplieroffer.requestOfferName"),
        field: "requestOfferName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("requestsupplieroffer.offerSendingDeadlineDate"),
        field: "offerSendingDeadlineDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("requestsupplieroffer.requestReminderPlannedDate"),
        field: "requestReminderPlannedDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("requestsupplieroffer.nbRequestToSupplierSent"),
        field: "nbRequestToSupplierSent",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("requestsupplieroffer.nbOfferCreated"),
        field: "nbOfferCreated",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("requestsupplieroffer.requestSupplierOfferActivityCodes"),
        field: "requestSupplierOfferActivityCodes.activityCode.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.activityCodeService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.activityCodeService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },

      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }

  public getMenuItems(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [{
          label: (params as any).i18n.tr("menu.open"),
          icon: "digi-open",
          handler: () => {
            let url = (params as any).pathDetail + params.data.id;
            if ((<any>params).router?.currentInstruction?.queryString?.length > 0) {
              url += '?' + (<any>params).router.currentInstruction.queryString;
            }
            (params as any).router.navigate(url);
          }
        }]
      },
      {
        label: (params as any).i18n.tr("menu.delete"),
        icon: "digi-delete-bin-line",
        handler: async () => {
          let isDeleted = await this.deleteRequestSupplierOffer(params.data);
          if (isDeleted) {
            (params as any).gridOptions.api.refreshInfiniteCache();
          }
        }
      }];
  }

  public async deleteRequestSupplierOffer(currentThis: Merlin.Web.Model.RequestSupplierOffer) {

    let responseCanDelete = await this.httpClient.get(Constants.Application.RequestSupplierController.CanDeleteRequestSupplierOffer.format(this.type.toString(), currentThis.id.toString(), currentThis.id.toString()));
    if (responseCanDelete.ok) {
      let canDelete = await responseCanDelete.json()
      if (canDelete) {
        let buttonYes: ActionDialogBoxInputParameters =
        {
          label: this.i18n.tr("general.yes", { ns: "common" }),
          title: this.i18n.tr("general.yes", { ns: "common" }),
          theme: 'primary',
          type: 'solid',
          disabled: false,
          fn: (thisBox: DialogBoxViewModel) => {
            thisBox.controller.ok(true);
          }
        };
        let buttonNo: ActionDialogBoxInputParameters =
        {
          label: this.i18n.tr("general.no", { ns: "common" }),
          title: this.i18n.tr("general.no", { ns: "common" }),
          theme: 'dark',
          type: 'ghost',
          disabled: false,
          fn: (thisBox: DialogBoxViewModel) => {
            thisBox.controller.ok(false);
          }
        };
        await this.box.showQuestion(this.i18n.tr('requestsupplieroffer.confirmDelete'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
          async (result) => {
            if (!result.wasCancelled) {
              if (result.output == true) {
                this.gls.allow();
                let response = await this.httpClient.delete(Constants.Application.RequestSupplierController.Delete.format(this.type.toString(), currentThis.id.toString(), currentThis.id.toString()));
                if (response.ok) {
                  this.gridOptions.api.onFilterChanged();
                }
                else {
                  this.logger.LogError(this.i18n.tr(await response.json()), null, null, true)
                }
              }
            }
          }
        )
      } else {
        this.box.showWarning(this.i18n.tr('requestsupplieroffer.notCanDeleteRequestSupplierOffer'), this.i18n.tr('general.warningTitle'));
        return [];
      }
    }
  }
} 
