import { ILoadingOverlayComp } from "ag-grid-community";
import { createOverrideContext } from "aurelia-binding";
import { Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { View, ViewSlot, ViewCompiler, ViewResources } from "aurelia-templating";
import { Box } from "digiwall-lib";
import { AddItems } from "projects/quotes/add-items/add-items";
import { ItemCompositionApiService } from "services/item-api-service";
import * as Constants from '../constants';
import { ModuleListTreeData } from "module-list-tree-data/module-list-tree-data";
import { ItemComposition } from "./item-composition";

export class CompositionOverlayComp implements ILoadingOverlayComp {
  protected eGui: HTMLElement;
  view: View;
  viewSlot: ViewSlot;
  hasFilterApply: boolean;
  noDataLabel: string;
  i18n: I18N;
  listTreeData: ModuleListTreeData;
  itemCompositionController: ItemComposition;
  private itemCompositionApiService: ItemCompositionApiService;
  box: Box;

  init(params) {
    this.listTreeData = params.listTreeData;
    this.noDataLabel = params.noDataLabel;
    this.itemCompositionController = params.itemCompositionController;
    
    let filterModel = params.api?.getFilterModel();
    this.hasFilterApply = filterModel != null && Object.keys(filterModel).length != 0;
    
    this.i18n = Container.instance.get(I18N);
    this.itemCompositionApiService = Container.instance.get(ItemCompositionApiService);
    this.box = Container.instance.get(Box);
    
    this.setupGui();
  }

  public async setupGui() {

    this.eGui = document.createElement('div');
    this.eGui.style.width = '100%';
    this.eGui.style.height = '100%';

    let html: string;
    if (this.hasFilterApply) {
      html = `<template><div>${this.i18n.tr("importmetering.noSearchResult")}</div></template>`;
    } else {
      html = `
      <template>
        <div>
          <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addItems" icon="digi-add-circle-line" click.delegate="addItems()"></ui-button>
        </div>
      </template>`;
    }

    let viewCompiler = Container.instance.get(ViewCompiler);
    let viewResources = Container.instance.get(ViewResources);
    let viewFactory = viewCompiler.compile(html, viewResources);
    this.view = viewFactory.create(Container.instance);
    this.viewSlot = new ViewSlot(this.eGui, true);
    this.viewSlot.add(this.view);
    this.view.bind(this, createOverrideContext(this));
    this.viewSlot.attached();
  }
  getGui(): HTMLElement {
    return this.eGui;
  }

  async addItems() {
    await this.box.showCustomDialog(AddItems, 0, null, {
      model: {
        action: Constants.PriceOfferLineMoveAction.Up,
        itemCompostionId: this.listTreeData.parentId,
        api: this.itemCompositionApiService
      },
      size: "xl"
    }).whenClosed(async (result) => {
      if (result.output) {
        this.listTreeData.refreshServerSideRows(result.output, true);
        this.itemCompositionController.afterAddItems();
      }
    });
  }
}
