import { I18N } from 'aurelia-i18n';

import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import { createOverrideContext, Container, ViewResources, autoinject } from 'aurelia-framework';
import { View, ViewCompiler, ViewSlot } from "aurelia-templating";
import { IMenuItem } from 'digiwall-lib';
import { AgCellCommentService } from 'resources/elements/ag-cell-comment/ag-cell-comment-service';
import { PriceOfferLineCommentApiService } from 'services/price-offer-line-comment-api-service';

@autoinject
export default class CommentRenderer implements ICellRendererComp {
  protected eGui: HTMLElement;

  protected view: View;
  protected viewSlot: ViewSlot;
  protected params: ICellRendererParams;
  private menuItems: Array<IMenuItem>;
  public i18n: I18N;
  baseRenderer: any;
  component: any;
  private baseRendererObj: ICellRendererComp;
  commentService: AgCellCommentService;
  commentSlot: ViewSlot;
  private debug = false;

  constructor() { }

  getGui(): HTMLElement {
    if (this.debug) console.log("CommentRenderer : getGui");
    let gui = document.createElement('div') as HTMLElement;

    if (this.baseRendererObj != null) {
      gui = this.baseRendererObj.getGui();
    }
    else {
      let val = this.params.getValue();
      if (val != null && this.params.formatValue != null && typeof this.params.formatValue == 'function') {
        val = this.params.formatValue(val);
      }
      gui.append(val ?? '');
    }
    // else if (this.params.valueFormatted != null) {
    //   gui.append(this.params.valueFormatted);
    // }
    this.commentSlot = this.commentService?.initCell(gui, this.params.node, this.params.colDef);
    return gui;
  }

  destroy?(): void {
    if (this.debug) console.log("CommentRenderer : destroy");
    this.commentSlot?.detached();
    this.commentSlot?.unbind();
    this.commentSlot?.removeAll(true, false);

    this.baseRendererObj?.destroy();
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    if (this.debug) console.log("CommentRenderer : refresh");
    return this.baseRendererObj?.refresh(params);
  }

  public init(params: ICellRendererParams & { baseRenderer: string, components: { [p: string]: any; }, commentService: AgCellCommentService }) {
    if (this.debug) console.log("CommentRenderer : init");
    this.params = params;

    if (params.baseRenderer != null) {
      this.baseRenderer = params.baseRenderer;
      this.component = params.components[this.baseRenderer];
      this.baseRendererObj = new this.component();
      this.baseRendererObj.init(params);
    }
    this.commentService = params.commentService;
  }
}
