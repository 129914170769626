import { Router } from 'aurelia-router';
import { Container, autoinject } from "aurelia-framework";
import { DocumentService } from "documents/document-service";
import * as Constants from '../../constants';
import { HttpClient } from 'aurelia-fetch-client';
import { Box, CustomLogger, ServiceBase } from "digiwall-lib";
import { I18N } from "aurelia-i18n";
import { DialogService } from "aurelia-dialog";
import { Merlin } from "generated";
import { PriceOfferLinesGrid } from "price-offer-lines/price-offer-lines-grid";
import { AddDocument } from "documents/add-document";
import { IPriceOfferLineGridService } from "services/i-data-line-api-service";
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';

@autoinject
export abstract class BuyingCommonMetering {

  public projectId: number;
  public projectService: ServiceBase<Merlin.Web.Model.Project>;
  public priceOfferLinesGrid: PriceOfferLinesGrid;
  public screenExpand: boolean;

  constructor(public dialogService: DialogService, public box: Box, public apiService: IPriceOfferLineGridService, public router: Router) {
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }



  public priceOfferLinesGridCellValueChanged(colField: string) {
    if (colField == null || colField.trim().length == 0) return;
    switch (colField) {
      case "lineDescription":
      case "additionalDescriptionMultiline":
      case "quantityTypeId":
      case "unitId":
      case "activityCodeId":
      case "tags":
      case "priceOfferLineFileCount":
        return;// Don't getMeteringTotalPrices for these properties
    }

    this.getMeteringTotalPrices();
  }

  public triggerExpand() {
    this.screenExpand = !this.screenExpand;
  }

  protected async addPriceOfferLineDocument(priceOfferLineId: string) {
    let documentService = new DocumentService(
      Container.instance.get(HttpClient),
      Container.instance.get(I18N),
      Container.instance.get(Box),
      Container.instance.get(CustomLogger),
    )
    documentService.projectId = this.projectId;
    documentService.lineId = parseInt(priceOfferLineId);
    documentService.projectPhaseId = Constants.ProjectPhaseId.Buying;
    let defaultLanguageId = (await this.projectService.getEntityById(documentService.projectId)).communicationLanguageId;
    await this.dialogService.open({
      viewModel: AddDocument,
      model: {
        service: documentService,
        folderName: "project/" + this.projectId + "/line/" + priceOfferLineId,
        defaultLanguageId: defaultLanguageId,
        documentModuleId: Constants.DocumentModuleId.Project
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("z-index-level-4");
        dialogOverlay.classList.add("z-index-level-4");
      },
    }).whenClosed(result => {
      this.priceOfferLinesGrid.refreshVisibleNodes([parseInt(priceOfferLineId)]);
    });

  }

  abstract getMeteringTotalPrices();
  abstract canEditCell(line, colField);
}
