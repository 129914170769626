@import "../../../css/scss/variables";
.supplier-cell {
  line-height: initial;
  display: grid;
  gap: 5px;
  grid-template-columns: auto;
  align-items: center;
  height: inherit;
}
.metering-cell-description {
  .supplier-cell {
    line-height: initial;
    display: grid;
    gap: 5px;
    grid-template-columns: auto 29px;
    align-items: center;
    height: inherit;
    > .supplier-cell-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: inherit;
    }
    > .supplier-cell-value-button > ui-button {
      background-color: transparent;
      .ui-icon {
        font-size: 14px;
      }
    }
    > .supplier-cell-value-radio {
      padding-left: 0;
      > ui-radio > label > input[type="radio"]:not(:checked):not(:disabled) {
        border-radius: 100px;
        appearance: none;
        margin-bottom: 0;
        width: 13px;
        height: 13px;
        border: $blue-mid 1px solid;
        background: white;
      }
    }
  }
}
.has-contract {
  opacity: $ag-row-disabled-opacity;
}

.supplier-cell-value-unit-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}

.supplier-cell-value-button {
  ui-button {
    .ui-icon {
      font-size: 15px !important;
    }
  }
}

.supplier-cell-value-button {
  > ui-button {
    //border-radius: 24px;
    border: none !important;
    .ui-btn__icon {
      padding: 0.3em;
      > ui-icon {
        color: $blue-mid;
      }
    }
  }
}

.supplier-cell-value-radio {
  padding-left: 5px;
}

.supplier-cell-value-radio-disabled {
  padding-right: 13px;
  padding-left: 13px;
  font-size: 0.8rem;
  color: $black-100;
  opacity: 0.5;
  cursor: not-allowed;
}

.supplier-min-value {
  color: #15ce1e;
}

.supplier-header-cell-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-right: 3px;
  padding-bottom: 5px;
  padding-left: 5px;
  margin-right: 10px;
  height: 100%;
  &:hover {
    background: $purple-lightest !important;
    border-radius: 60px;
  }
}

.ag-header-active {
  .supplier-header-cell-value {
    background: $purple-lightest !important;
    border-radius: 60px;
  }
}

.supplier-cell-total-chapiter,
.ag-theme-material .ag-floating-bottom .ag-row > .ag-cell.supplier-cell-total-data {
  background-color: $purple-dark !important;
  color: $black-0;
}

.supplier-cell-total-data {
  background-color: $purple-lighter;
  color: $blue-mid;
}
.ag-theme-material {
  .ag-floating-bottom {
    border-top: none;
    > .ag-pinned-left-floating-bottom {
      .ag-cell-value:not(.ag-selection) {
        > div {
          display: none;
        }
      }
      .ag-cell-value.ag-selection {
        > div > span {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 15px;
          font-weight: 600;
        }
      }
    }

    .ag-row {
      > .ag-cell {
        background-color: $purple-lightest;
        border-top: 1px solid $black-100;
      }
    }
  }
}

.ag-header.supplier-header-cell-value {
  .supplier-cell-value-button {
    ui-button {
      margin-right: 14px;
    }
  }
}
