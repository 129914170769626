import { I18N } from 'aurelia-i18n';

import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import { createOverrideContext, Container, ViewResources, autoinject, observable } from 'aurelia-framework';
import { View, ViewCompiler, ViewSlot } from "aurelia-templating";

@autoinject
export default class MeteringPriceOfferLineReferenceRenderer implements ICellRendererComp {
  protected eGui: HTMLElement;

  protected view: View;
  protected viewSlot: ViewSlot;
  protected params: ICellRendererParams;
  public i18n: I18N;

  @observable checked = false;
  private checkedChanged(newValue, oldValue) {
    // console.log('checked change', newValue);
  }

  @observable toogled = false;
  private toogledChanged(newValue, oldValue) {
    // console.log('toogled Changed', newValue);

  }

  constructor() { }

  public init(params: ICellRendererParams,) {
    this.params = params;
    this.setupGui();
  }

  public setupGui() {
    this.eGui = document.createElement('div');
    this.eGui.style.width = '100%';
    this.eGui.style.height = '100%';
    this.eGui.style.display = 'flex';

    let content: string = this.setupContentGui();
    let html: string = `<template>${content}</template>`;

    this.i18n = Container.instance.get(I18N);
    let viewCompiler = Container.instance.get(ViewCompiler);
    let viewResources = Container.instance.get(ViewResources);
    let viewFactory = viewCompiler.compile(html, viewResources);
    this.view = viewFactory.create(Container.instance);
    this.viewSlot = new ViewSlot(this.eGui, true);
    this.viewSlot.add(this.view);
    this.view.bind(this, createOverrideContext(this));
    this.viewSlot.attached();
  }


  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params): boolean {
    return true;
  }

  public destroy() {
    if (this.view) this.view.unbind();
    if (this.viewSlot) this.viewSlot.detached();
  }

  public getValueToDisplay() {
    return this.params.valueFormatted ? this.params.valueFormatted : this.params.value;
  }

  public setupContentGui(): string {
    if (this.params?.data == null) return null;

    let content = '';
    // Checkbox
    content += '<div><ui-checkbox checked.bind="checked"></ui-checkbox></div>';

    // Arrow
    if (this.params.data.hasChildren)
      content += '<div click.delegate="toogled = !toogled"><i class="digi-arrow-down-s-line"></i></div>';
    else
      content += '<div></div>';

    // MerlinRef
    content += '<div>' + this.params.data.merlinRef + '</div>';

    // LineDescription
    content += '<div>' + this.params.data.lineDescription + '</div>';

    // PostType
    content += '<div></div>';

    // IsActive
    content += '<div></div>';

    return content;
  }

}
