import * as Constants from '../../constants';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { JSONPatchOperation } from 'services/json-patch-operation';


@autoinject
export class BuyingSummary {
  projectId: any;
  activityPlanningDates: Array<ActivitPlanningDates>;
  private ribbonHeaderText = this.i18n.tr("buyingindex.planning");

  constructor(public router: Router, private httpClient: HttpClient, private i18n: I18N) {

  }

  public async activate(params: any) {
    this.projectId = params.projectId;

    let response = await this.httpClient.get(Constants.Application.BuyingController.GetAll.format(this.projectId.toString()));
    if (response.ok) {
      this.activityPlanningDates = await response.json();
    }
  }

  async dateChanged(propertyValue, propertyName: string, id: number) {
    await this.httpClient.patch(Constants.Application.BuyingController.Patch.format(this.projectId.toString(), id.toString()),
      JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
  }

}

export interface ActivitPlanningDates {
  id: number;
  activityCodeId: number;
  activityCodeTranslated: string;
  contractDeadlineDate: Date | string | null;
  deliveryDeadlineDate: Date | string | null;
  clientValidationDeadlineDate: Date | string | null;
}
