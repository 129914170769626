import { DialogService } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { RouterConfiguration } from 'aurelia-router';
import { Config, Datacontext, EntityManager, BaseApp, AuthService, BreezeModel, CustomLogger, UIMenuGroupConfig, MenuGroupInstance, Box } from 'digiwall-lib';
import 'jquery';
import { Container, autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import * as Constants from './constants';
import { Merlin } from 'generated';
import { UIHorizontalMenu } from 'resources/elements/ui-horizontal-menu';
import "ag-grid-enterprise";
import "quill-mention";
import { LicenseManager } from 'ag-grid-enterprise';
import * as environment from './environment';


@autoinject
export class App extends BaseApp {
  public isCondensed: boolean
  public version: String = "0.0.0";

  private newAuthorizedRoutes: Array<MenuGroupInstance>;

  constructor(context: Datacontext, config: Config, eventAggregator: EventAggregator, breezeModel: BreezeModel, authService: AuthService, entityManager: EntityManager, private logger: CustomLogger, private datacontext: Datacontext, httpClient: HttpClient, dialogService: DialogService) {
    super(context, config, eventAggregator, breezeModel, authService, entityManager, httpClient, dialogService, null);
    httpClient.configure(config => {
      config.withInterceptor({
        response: async (response: Response, request?: Request) => {
          if (response.status == 400) {
            let text = await response.json();
            let box = Container.instance.get(Box);
            box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
          }

          return response;
        }
      })
    })
  }

  public async attached() {
    this.version = environment.version;
    console.log("Version: ", this.version);
  }

  public groupIcons: Map<string, UIMenuGroupConfig> = new Map<string, UIMenuGroupConfig>([
    [Constants.Routes.MenuGroupTitle.Users, { icon: "digi-user" }],
    [Constants.Routes.MenuGroupTitle.Projects, { icon: "digi-community" }],
    [Constants.Routes.MenuGroupTitle.Data, { icon: "digi-database", defaultSubGroup: Constants.Routes.MenuSubGroup.Items }],
    [Constants.Routes.MenuGroupTitle.ImportMetering, { icon: "digi-folder" }],
    [Constants.Routes.MenuGroupTitle.ModuleTask, { icon: "digi-checkbox-multiple-line", isBottom: false }],
    [Constants.Routes.MenuGroupTitle.Invoice, { icon: "digi-line-chart-line", isBottom: false }],
    [Constants.Routes.MenuGroupTitle.Tasks, { icon: "digi-checkbox-multiple", isBottom: false }],
    [Constants.Routes.MenuGroupTitle.Parameters, { icon: "digi-settings-3-line", isBottom: false }],


    [Constants.Routes.MenuGroupTitle.ThirdParties, { icon: "digi-group", isDefault: true }],
    [Constants.Routes.MenuGroupTitle.EnumerationTypes, { icon: "digi-database", isBottom: true }],
    [Constants.Routes.MenuGroupTitle.NotificationsSent, { icon: " digi-mail-send-line", isBottom: true }],
    [Constants.Routes.MenuGroupTitle.MyAccount, { icon: "digi-account-circle", isBottom: true }],
  ]
  );

  public async initialize() {
    let license = 'Using_this_AG_Grid_Enterprise_key_( AG-047512 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Merlin Software )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 10 September 2024 )____[v2]_MTcyNTkyMjgwMDAwMA==dbc38a2a5c87693d4f32ae25d2116e49';
    LicenseManager.setLicenseKey(license);
    await super.initialize();
  }


  public async configureRouter(config: RouterConfiguration, router: Router) {
    await super.configureRouter(config, router);
  }

  protected isValidLanguage(language: string): boolean {
    return (language == 'fr' || language == 'nl' || language == 'en');
  }

  private horizontalMenu: UIHorizontalMenu;
  public onMenuViewChildren(menu: MenuGroupInstance) {
    this.horizontalMenu.onViewChildren(menu);
  }
}
