//import { ItemPrice } from 'utils/item-price';
import { ListViewModelBase, CustomLogger, ServiceBase, SelectAllHeaderComponent, EnumerationTypeService, FieldType, ValueSelected, EnumSelected, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { Merlin } from 'generated';
import { DialogService } from 'aurelia-dialog';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class ItemList extends ListViewModelBase<Merlin.Web.Model.Item> {
  public ressourceName: string = Constants.EntityTypeNames.Item;
  public buttonLabel: string = this.i18n.tr("item.addItem");
  public pathDetail: string;


  public today = new Date();

  public itemDeliveryService: EnumerationTypeService;
  public itemFeaturesService: EnumerationTypeService;

  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private dialogService: DialogService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.Item>(Constants.EntityTypeNames.Item));
    this.service.gridDataSource.expands = ['itemType', 'itemDeliveryTypes.itemDeliveryEnum', 'unitType', 'defaultUnit', 'itemPrices.itemPriceFromDates', 'itemCompositions', 'features.itemFeatureEnum', 'activityCodes.activityCodeEnum'];
  }

  public getDetailsUrl(self: any, entity: Merlin.Web.Model.Item): string {
    if (entity?.id) {
      return (this.pathDetail + entity.id);
    }
    return null;
  }

  private getDefaultPrice(item: Merlin.Web.Model.Item) {
    if (item != null) {
      if (item.itemTypeId == Constants.ItemType.Composed) {
        if (item.itemCompositions != null && item.itemCompositions.length > 0) {
          let composition = item.itemCompositions.find(x => x.isDefaultPrice);
          if (composition != null) {
            return composition.totalSellingPrice?.toString() ?? "";
          }
        }
      } else {
        if (item.itemPrices != null && item.itemPrices.length > 0) {
          let defaultUnitPrice = item.itemPrices.find(x => x.isDefaultPrice);
          if (defaultUnitPrice != null) {
            return defaultUnitPrice.currentBuyingPrice.toString() ?? "";
          }
        }
      }
    }
    return "";
  }

  public initializeGridOptions() {
    this.pathDetail = '/items/';
    super.initializeGridOptions(false, false);
    this.gridOptions.components = {
      ...this.gridOptions.components
    };
  }

  /*public importArticleCSV() {
    this.dialogService.open({ viewModel: ImportCSV, model: { type: Constants.EntityTypeNames.Item } }).whenClosed((result) => {
      if (!result.wasCancelled && result.output as boolean) {
        this.gridOptions.api.refreshInfiniteCache();
      }
    });
  }*/


  public getDataGridColumns() {
    this.itemDeliveryService = new EnumerationTypeService(Constants.EnumerationTypes.ItemDeliveryType);
    this.itemFeaturesService = new EnumerationTypeService(Constants.EnumerationTypes.ItemFeature);
    let activityCodeService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("item.articleCode"),
        field: "articleCode",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.description"),
        field: "description._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.descriptions")
      },
      {
        headerName: this.i18n.tr("item.detailedDescriptionMultiline"),
        field: "detailedDescriptionMultiline._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.descriptions")
      },
      {
        headerName: this.i18n.tr("item.secondaryDescriptionMultiline"),
        field: "secondaryDescriptionMultiline._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.descriptions")
      },
      {
        headerName: this.i18n.tr("item.isDeprecated"),
        field: "isDeprecated",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.supplierWarrantyInMonth"),
        field: "supplierWarrantyInMonth",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.clientWarrantyInMonth"),
        field: "clientWarrantyInMonth",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.itemTypeId"),
        field: "itemType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ItemType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ItemType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.activityCodes"),
        field: "activityCodes.activityCodeEnum.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: activityCodeService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: activityCodeService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.itemDeliveryTypes"),
        field: "itemDeliveryTypes.itemDeliveryEnum.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.itemDeliveryService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.itemDeliveryService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.unitTypeId"),
        field: "unitType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.UnitType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.UnitType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.defaultUnitId"),
        field: "defaultUnit.unitName._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.defaultBuyingPrice"),
        field: "defaultBuyingPrice",
        type: FieldType.Currency,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.defaultSellingPrice"),
        field: "defaultSellingPrice",
        type: FieldType.Currency,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.features"),
        field: "features.itemFeatureEnum.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.itemFeaturesService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.itemFeaturesService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }

  importItem() {
    this.manager.clear(); //Clear cache breeze for entities delete in backend
    this.router.navigateToRoute('import-data', { "context": "item", "errorView": "items/import-item-error-table.html", configView: "items/item-config.html" });
  }
} 
