
import { autoinject, bindable, customElement } from "aurelia-framework";
import { IFile } from "digiwall-lib";
import { NotificationLanguageDTO } from "email-sender/email-sender";
import { Merlin } from "generated";
import * as Constants from '../../../../constants';

@autoinject
@customElement("send-offer-step-4")
export class SendOfferStep4 {
  @bindable
  public notifLang: Map<number, NotificationLanguageDTO> = new Map<number, NotificationLanguageDTO>();

  @bindable
  public receiverIds: Array<number> = [];

  @bindable
  public companyIds: Array<number> = [];

  @bindable
  public files: Array<IFile> = [];

  @bindable
  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest;

  @bindable
  public projectId: number

  @bindable
  private emailContext = Constants.EmailContextId.Project;
  constructor() {

  }
}
