@import "../../css/scss/variables";

ui-page.ui-page-particular-conditions-list {

  > .ui-page__ribbon-content{ 
    padding: 0px 40px !important;
  }

  ui-content > .row {
    margin: 0!important;
  }
  
  div[slot="ribbon-content"] {
    margin: 10px 0;
  }

  [slot="ui-button-detail__left"] {
    display: flex;
    gap: 20px;
  }

  ui-panel {
    width: 100%;

    .ui-panel__header {
      padding-bottom: 1px;
    }

    .ui-panel__body {
      margin: 0 0 0 -20px;
    }

    flow-uploader {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    
      .flow_uploader__loader {
        top: -20px;
      }
    }
    
    
    
  }
  
}
