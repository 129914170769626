import { autoinject } from 'aurelia-framework';

@autoinject
export class IconButtonCustomAttribute {
    constructor(private element: Element) {

    }
    value;
    bind() {    
        this.element.setAttribute("data-role", "iconbutton");
        this.element.setAttribute("data-iconbutton-text", this.value);
    }
}