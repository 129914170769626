.disclaimer {
  h1,
  h2 {
    color: #333;
  }
  ul {
    list-style: inside;
    padding-left: 20px;
    &.list_decimal {
      list-style-type: decimal;
    }
  }
  ul li {
    margin-bottom: 10px;
  }
  .section {
    margin-bottom: 20px;
  }
}
