import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';

@autoinject
export class PopUpMenuItem {
  private date: Date;
  private title: string;


  constructor(private controller: DialogController) {

  }
  activate(params) {
    this.title = params.title;
    this.date = params.date;
  }

  ok() {
    this.controller.ok(this.date);
  }

}
