import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { Box } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { IPriceOfferLineGridService, RequestFilterPriceOfferLine } from './i-data-line-api-service';
import { PriceOfferLineFromItem } from 'projects/quotes/add-items/add-items';


@autoinject
export class AmendmentApiService implements IPriceOfferLineGridService {
  protected callback: () => void;
  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N) { }

  public contractId: number;
  public projectId: number;
  public dataLines: Array<any>;

  createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<number[]> | Error {
    throw new Error('Method not implemented.');
  }
  children(id: number, lineId: number, displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<any[]> {
    throw new Error('Method not implemented.');
  }
  async filter(id: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<any[]> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.AmendmentController.Filter.format(this.projectId.toString(), this.contractId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }
  public async select(lineId: number) {
    if (this.dataLines != null) {
      let currentRow = this.dataLines?.find(x => x.id == lineId);
      this.selectChildren(currentRow);
      return [lineId];
    }
    return null;
  }

  private selectChildren(line) {
    let children = this.dataLines?.filter(x => x.parentId == line.id);
    children?.forEach(child => {
      child.isSelected = true;
      if (child.hasChildren) {
        this.selectChildren(child);
      }
    });
  }

  private unselectChildren(line) {
    let children = this.dataLines?.filter(x => x.parentId == line.id);
    children?.forEach(child => {
      child.isSelected = false;
      if (child.hasChildren) {
        this.unselectChildren(child);
      }
    });
  }

  public async unselect(lineId: number) {
    if (this.dataLines != null) {
      let currentRow = this.dataLines?.find(x => x.id == lineId);
      this.unselectChildren(currentRow);
      return [lineId];
    }
    return null;
  }
  fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<any[]> {
    throw new Error('Method not implemented.');
  }

  async createAmendment(projectId: number, contractId: number, statusId: number, ids: number[]) {
    let requestUri = Constants.Application.AmendmentController.CreateAmendment.format(projectId.toString(), contractId.toString(), statusId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(ids));
    if (response.ok) {
      return await response.json();
    }
  }

  async getValidatedOriginalContractId(projectId: number, lineId: number) {
    let requestUri = Constants.Application.AmendmentController.GetValidatedOriginalContractId.format(projectId.toString(), lineId.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }

  async setSendIds(projectId: number, contractId: number, ids: number[]) {
    let requestUri = Constants.Application.AmendmentController.SetSendIds.format(projectId.toString(), contractId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(ids));
    if (!response.ok) {
      return response.body;
    }
    else {
      return;
    }
  }

}
