import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, FieldType, Various, IMenuItems, GlobalLoaderService, Box } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../../../generated";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import * as Constants from '../../../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { ProjectApiService } from 'services/project-api-service';
import { MeteringMenuItems } from 'resources/metering/metering-menu-items';
import { RealizationApiService } from 'services/realization-api-service';

@autoinject
export class AdditionalWorkList extends ListViewModelBase<Merlin.Web.Model.ClientAdditionalWorkView> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private projectApiService: ProjectApiService, private meteringMenuItems: MeteringMenuItems, public realizationApiService: RealizationApiService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.ClientAdditionalWorkView>(Constants.EntityTypeNames.ClientAdditionalWorkView));
    this.service.gridDataSource.expands = ['status'];
  }

  public ressourceName: string = Constants.EntityTypeNames.AdditionalWork;

  public pathDetail: string = "additional-work/";
  private nbDecimalForPriceDisplay: number;
  projectId: number;

  public getDetailsUrl(_self, entity: Merlin.Web.Model.ClientAdditionalWorkView) {
    if (entity?.id) {
      return this.pathDetail + entity.id;
    }
    return null;
  }
  async activate(params: any): Promise<void> {
    await super.activate(params);
    this.projectId = parseInt(params.projectId);
    this.service.gridDataSource.queryParameters = { projectId: this.projectId };

    let precisionParameter = await this.projectApiService.getPrecisionParameter(this.projectId);
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.realizationApiService.projectId = this.projectId;
  }

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  createClientAdditionalWork() {
    this.router.navigateToRoute('additional-work-detail', { projectId: this.projectId, additionalWorkId: -100, clientAdditionalWorkVersionId: Various.NewId });
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      {
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererParams: {
          i18n: this.i18n,
          gridOptions: (this as any).gridOptions,
          router: this.router,
          service: this.service,
          pathDetail: (this as any).pathDetail,
          menuItems: this.getMenuItems,
          disabledMenuItem: (this as any).isSelectable,
          // getStatus: this.getStatus,
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        sortable: false,
        resizable: false,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        pinned: "left",
      },
      new GridHelperMerlin().selectedColDef(this),
      {
        headerName: this.i18n.tr("clientadditionalwork.numAdditionalWork"),
        field: "numAdditionalWork",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        filter: FieldType.String
      },
      {
        headerName: this.i18n.tr("clientadditionalwork.version"),
        field: "versionId",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        filter: FieldType.Number
      },
      {
        headerName: this.i18n.tr("clientadditionalwork.acceptationDate"),
        field: "acceptationDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("clientadditionalwork.statusId"),
        field: "status.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ClientAdditionalWorkStatusType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ClientAdditionalWorkStatusType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("clientadditionalwork.totalBuyingPrice"),
        field: "totalBuyingPrice",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("clientadditionalwork.totalSellingPrice"),
        field: "totalSellingPrice",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("clientadditionalwork.description"),
        field: "description",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        filter: FieldType.String
      },
      {
        headerName: this.i18n.tr("clientadditionalwork.validUntilDate"),
        field: "validUntilDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("clientadditionalwork.additionalDelayNbDays"),
        field: "additionalDelayNbDays",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        filter: FieldType.Number
      },

      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }

  public getMenuItems(params: ICellRendererParams): Array<IMenuItems> {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [{
          label: (params as any).i18n.tr("menu.open"),
          icon: "digi-open",
          handler: () => {
            let url = "client-additional-work/" + params.data.id;
            if ((<any>params).router?.currentInstruction?.queryString?.length > 0) {
              url += '?' + (<any>params).router.currentInstruction.queryString;
            }
            (params as any).router.navigate(url);
          }
        }]
      },
      // {
      //   group: "2",
      //   hiddenLabel: true,
      //   items: [{
      //     label: (params as any).i18n.tr("clientadditionalwork.status"),
      //     icon: "digi-open",
      //     items: (params as any).getStatus(params?.node?.data?.statusId, params)
      //   }]
      // }
    ];
  }

  // private getStatus(currentStatusId: number, params): IMenuItems[] {
  //   return [
  //     {
  //       label: this.i18n.tr("clientadditionalwork.statusInProcess"),
  //       disabled: () => currentStatusId != Constants.ClientAdditionalWorkStatusTypeId.Draft,
  //       handler: async () => {
  //         await new ClientAdditionalWorkService(Container.instance.get(HttpClient), Container.instance.get(GlobalLoaderService), Container.instance.get(Box), Container.instance.get(I18N)).changeStatus(params.node.data.id, Constants.ClientAdditionalWorkStatusTypeId.InProcess);
  //         params.api.onFilterChanged()
  //       }
  //     },
  //     {
  //       label: this.i18n.tr("clientadditionalwork.statusValidate"),
  //       disabled: () => currentStatusId != Constants.ClientAdditionalWorkStatusTypeId.InProcess,
  //       handler: async () => {
  //         await new ClientAdditionalWorkService(Container.instance.get(HttpClient), Container.instance.get(GlobalLoaderService), Container.instance.get(Box), Container.instance.get(I18N)).changeStatus(params.node.data.id, Constants.ClientAdditionalWorkStatusTypeId.Validated);
  //         params.api.onFilterChanged()
  //       }
  //     },
  //     {
  //       label: this.i18n.tr("clientadditionalwork.statusRejected"),
  //       disabled: () => currentStatusId != Constants.ClientAdditionalWorkStatusTypeId.InProcess,
  //       handler: async () => {
  //         await new ClientAdditionalWorkService(Container.instance.get(HttpClient), Container.instance.get(GlobalLoaderService), Container.instance.get(Box), Container.instance.get(I18N)).changeStatus(params.node.data.id, Constants.ClientAdditionalWorkStatusTypeId.Rejected);
  //         params.api.onFilterChanged()
  //       }
  //     }
  //   ]
  // }
}

