import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { JSONPatchOperation } from './json-patch-operation';
import * as Constants from '../constants';
import { ActionDialogBoxInputParameters, Box, CustomLogger, DialogBoxViewModel, GlobalLoaderService, KeyboardShortcut } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { RefToDisplay } from '../constants';
import { MeteringTotalPrices, IPriceOfferLineGridService, IDataLineApiService, RequestCreatePriceOfferLine, RequestFilterPriceOfferLine, BulkUpdateOperation } from './i-data-line-api-service';
import { PriceOfferLineFromItem } from 'projects/quotes/add-items/add-items';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';
import { Logger } from 'ag-grid-community';

@autoinject
export class SupplierOfferApiService implements IDataLineApiService {
  protected callback: () => void;
  type: DocumentTypeDiscriminator;
  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, private logger: CustomLogger, private globalLoaderService: GlobalLoaderService) { }
  getLastChapterId(versionId: number): Promise<number> {
    throw new Error('Method not implemented.');
  }

  async recomputeLines(versionId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.get(Constants.Application.SupplierOfferController.RecomputeLines.format(this.type.toString(), versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  async createSupplierOffer(requestToSupplierId: number, subContractorId: number): Promise<number> {
    this.globalLoaderService.allow();
    const supplierOfferResult = await this.httpClient.put(Constants.Application.SupplierOfferController.CreateSupplierOffer.format(this.type.toString(), subContractorId.toString(), requestToSupplierId.toString()));
    if (supplierOfferResult.ok) {
      const supplierOffer = await supplierOfferResult.json();
      return supplierOffer;
    }
    return null;
  }

  async duplicateLine?(versionId: number, lineId: number): Promise<number[]> {
    let requestUri = Constants.Application.SupplierOfferController.DuplicateLine.format(this.type.toString(), versionId.toString(), lineId.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }
  async create(versionId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<number[]> {
    let requestCreatePriceOfferLine: RequestCreatePriceOfferLine = {
      targetId: targetId,
      categoryId: categoryId,
      fK: versionId,
      action: action
    };
    let requestUri = Constants.Application.SupplierOfferController.CreateLine.format(this.type.toString(), versionId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(requestCreatePriceOfferLine));
    if (query.ok) {
      return await query.json();
    }
    return [];
  }
  move(versionId: number, lineIds: number[], targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<number[]> {
    throw new Error('Method not implemented.');
  }
  paste(versionId: number, lineIds: number[], targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<number[]> {
    throw new Error('Method not implemented.');
  }
  async delete(versionId: number, lineIds: number[]): Promise<number[]> {
    let result = await this.canDelete(versionId, lineIds);
    if (result) {
      let msg = lineIds.length == 1 ? this.i18n.tr('menu.delete_confirmation') : this.i18n.tr('menu.delete_confirmation_plural', { count: lineIds.length });
      let ids = [];

      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.ok", { ns: "common" }),
        title: this.i18n.tr("general.ok", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(true);
        },
        keyboardShortcut: KeyboardShortcut.Enter
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.cancel", { ns: "common" }),
        title: this.i18n.tr("general.cancel", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(false);
        },
        keyboardShortcut: KeyboardShortcut.Escape
      };
      await this.box.showQuestion(msg, this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes])
        .whenClosed(async (result) => {
          if (!result.wasCancelled && result.output) {
            let requestUri = Constants.Application.SupplierOfferController.DeleteLine.format(this.type.toString(), versionId.toString());
            let response = await this.httpClient.delete(requestUri, JSON.stringify(lineIds));
            if (response.ok) {
              let temp = await response.json();
              ids.push(...temp);
            } else {
              if (response.status == 400) {
                this.logger.LogError(this.i18n.tr('supplieroffer.notCanDeleteLink'), null, null, true);
              }
            }
          }
        });
      return ids;
    } else {
      this.box.showWarning(this.i18n.tr('supplieroffer.notCanDeleteLine'), this.i18n.tr('general.warningTitle'));
      return [];
    }
  }

  private async canDelete(versionId: number, lineIds: Array<number>): Promise<boolean> {
    let requestUri = Constants.Application.SupplierOfferController.CanDeleteLines.format(this.type.toString(), versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(lineIds));
    if (response.ok) {
      return await response.json();
    }
  }
  computePAR?(versionId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  deletePAR?(versionId: number, lineId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  versionHasLines?(versionId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  createVariantGroup?(versionId: number, lineIds: number[]): Promise<number[]> {
    throw new Error('Method not implemented.');
  }
  deleteVariantGroup?(versionId: number, lineId: number): Promise<number[]> {
    throw new Error('Method not implemented.');
  }
  async createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<Array<number>> {
    let requestUri = Constants.Application.SupplierOfferController.CreateFromItems.format(this.type.toString(), versionId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(selectedItems));

    if (query.ok) {
      return await query.json();
    }
    return null;
  }
  subscribe(callback: () => void) {
    this.callback = callback;
  }


  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.SupplierOfferController.GetChildren.format(this.type.toString(), versionId.toString(), lineId.toString(), displayHidden.toString(), refToDisplay.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.SupplierOfferController.GetAll.format(this.type.toString(), versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let result = await this.httpClient.post(Constants.Application.SupplierOfferController.GetByIds.format(this.type.toString(), versionId.toString(), refToDisplay.toString()), JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }
  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.SupplierOfferController.Patch.format(this.type.toString(),
      (this.type == DocumentTypeDiscriminator.WorkQuotationVersion ? 'work-quotation-version' : 'buying'),
      versionId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    }
    return [];
  }


  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.SupplierOfferController.BulkPatch.format(this.type.toString(),
      (this.type == DocumentTypeDiscriminator.WorkQuotationVersion ? 'work-quotation-version' : 'buying'),
      versionId.toString());
    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }

  public async patchSource(supplierId: number, source: number): Promise<boolean> {
    let requestUri = Constants.Application.SupplierOfferController.PatchSource.format(this.type.toString(), supplierId.toString(), source.toString());
    let result = await this.httpClient.patch(requestUri);
    return result.ok;
  }

  async getMeteringTotalPrices(supplierOfferId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.get(Constants.Application.SupplierOfferController.PriceOfferVersionTotal.format(this.type.toString(), supplierOfferId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

}

// export class RequestMovePriceOfferLine {
//   public action: Constants.PriceOfferLineMoveAction;
//   public targetId: number;
//   public priceOfferLineIds: Array<number>;
// }

// export class RequestCreatePriceOfferLine {
//   public action: Constants.PriceOfferLineMoveAction;
//   public targetId: number;
//   public categoryId: number;
// }

// export class RequestFilterPriceOfferLine {
//   public filterModel: any;
//   public quickFilter: string;
//   public displayMode: Constants.TreeDisplayMode;
//   public displayHidden: boolean;
// }
