@import "../../../css/scss/variables";

$trans: all 0.1s ease;

ag-cell-item-tooltip {
  .ag-cell-item-tooltip-handler {
    > ui-icon {
      color: $blue-mid;
      width: 25px;
    }
  }
  &:hover > .ag-cell-item-tooltip-handler {
    background-color: $purple-lighter;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
  }
}

.ui-viewport__floating-container > .ui-drop > .ui-drop-item-tooltip {
  min-width: 300px;
  max-width: 300px;
  padding: 15px 10px !important;
  .ag-cell-item-tooltip {
    ui-field {
      ui-input,
      ui-select {
        margin-bottom: 8px;
      }
    }
    a {
      //background-color: $blue-mid;
      //color: white;
      // padding: 8px 15px;
      // border-radius: 5px;
      // display: block;
      // text-align: center;
    }
    > ui-button {
      width: 100%;
    }
    .pa {
      padding-left: 0.25rem;
      line-height: 1.5rem;
      height: 2.6rem;
      display: flex;
      align-items: center;
    }
  }
  .detail-tag {
    background-color: #efefef;
    color: #7d7d7d;
    padding: 5px 15px;
    border-radius: 20px;
    line-height: 15px;
  }
}

// .ag-cell-item-tooltip {
//   display: flex;
//   flex-direction: column;
// }
