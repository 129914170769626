import { autoinject, bindable, customElement } from "aurelia-framework";
import { IFile } from "digiwall-lib";
import { NotificationLanguageDTO } from "email-sender/email-sender";
import { Merlin } from "generated";
import * as Constants from '../../constants';

@autoinject
@customElement("add-request-supplier-offer-step-5")
export class AddRequestSupplierOfferStep5 {
  @bindable
  public entity: Merlin.Web.Model.RequestSupplierOffer;
  @bindable
  public notifLang: Map<number, NotificationLanguageDTO> = new Map<number, NotificationLanguageDTO>();

  @bindable
  public isReadonly: boolean = false;

  public Constants = Constants;
  @bindable
  public receiverIds: Array<number> = [];

  @bindable
  public companyIds: Array<number> = [];

  @bindable
  public files: Array<IFile> = [];

  @bindable
  public projectId;

  @bindable
  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest;

  private emailContext;
  constructor() {

  }

  bind() {
    if ((this.entity as any).workQuotationVersionId != null) {
      this.emailContext = Constants.EmailContextId.SupplierOfferRequest;
    } else if ((this.entity as any).projectId != null) {
      this.emailContext = Constants.EmailContextId.SupplierOfferRequestForBuying;
    }
    this.projectId = parseInt(this.projectId.toString()); //Make sure projectId is a number
  }
}
