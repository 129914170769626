@import "../../../css/scss/variables";
.send-offer-body {
  * {
    -webkit-user-select: none; /* Safari */
    user-select: none;
  }

  step-timeline + div {
    margin: 40px 15px 0px 15px;
  }

  ui-segmented-control.segmented-control .selector {
    background-color: $blue-mid;
  }

  .ui-field-segmented-control {
    width: 340px !important;
  }
  send-offer-step-3 {
    h2 {
      border-bottom: 1px solid $gray-lighter;
      padding-bottom: 1.4rem;
    }

    .main-container {
      max-width: 1000px;
      margin: auto;

      .send-offer-3 {
        &__btn-container {
          display: flex;
          margin: 2rem 0;
          color: $blue-mid;

          &__first,
          &__group {
            display: inherit;
            gap: 1rem 4rem;
            flex-wrap: wrap;

            label {
              width: 100%;
              font-size: 1rem;
              font-weight: 500;
            }
          }

          &__first {
            padding-right: 4rem;
          }

          &__group {
            border-left: 2px solid $gray-very-light;
            padding-left: 4rem;
          }
        }

        &__btn {
          flex: 1;
          padding: 1.5rem 1rem;
          border-radius: 15px;
          background: transparent;
          border: 2px solid $gray-very-light;
          text-align: center;
          width: 250px;
          color: $primary;

          &.active {
            border-color: $blue-mid;
          }

          a {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }

          &__icon {
            font-size: 3rem;
            padding: 0.5rem 1rem 1rem 1rem;
            background: $purple-lightest;
            border-radius: 15px;
          }

          &__title {
            font-size: 1.3rem;
            font-weight: 500;
          }

          &__text {
            color: $gray-mid;
          }
        }

        &__files {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        &__file {
          background-color: $gray-lightest;
          border-radius: 8px;
          padding: 10px;
          display: block;
          align-items: center;
          margin: 0px 5px;

          > ui-button > .ui-btn__inner > .ui-btn > .ui-btn__icon > .digi-cross {
            font-size: 12px;
          }

          > .send-offer-3__file__name {
            color: $blue-mid;
            font-weight: 400;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 0;
          }
          > span {
            font-weight: 600;
            margin: 0 5px;
            max-width: 100px;
          }
          > ui-button > .ui-btn__inner > .ui-btn {
            .ui-btn__icon {
              padding: 0;
            }
            .ui-icon {
              font-size: 14px;
            }
          }
        }
      }
    } // EO .main-container
  }
}
