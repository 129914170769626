import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import Cropper from 'cropperjs';
import { UICropperPictureMode } from "./ui-cropper-picture";
import { HttpClient } from 'aurelia-fetch-client';
import * as Constants from '../../../constants';
import { CustomLogger, RemoteFileStorage, UIInternal } from "digiwall-lib";

@autoinject
export class CropperDialog {
  title: string = this.i18n.tr("document.addDocuments");

  public cropper: Cropper;

  public mode: UICropperPictureMode;
  public folderName: string;
  public pictureUrl: string

  constructor(private logger: CustomLogger, private controller: DialogController, private i18n: I18N, private httpClient: HttpClient, private fileStorageService: RemoteFileStorage.Service) {

  }

  activate(params) {
    if (params.mode != null)
      this.mode = params.mode

    if (params.folderName)
      this.folderName = params.folderName

    if (params.pictureUrl)
      this.pictureUrl = params.pictureUrl

    if (this.pictureUrl && this.folderName && this.mode != null) {
      UIInternal.queueTask(() => this.editPicture());
    }
    else {
      this.controller.cancel();
    }
  }

  public editPicture() {
    const image = document.getElementById('cropper') as HTMLImageElement;
    image.onload = (ev) => {
      if (this.mode == UICropperPictureMode.Round) {
        this.cropper = new Cropper(image, {
          aspectRatio: 1,
          viewMode: 0,
          dragMode: 'move',
          background: true,
        });
      } else if (this.mode == UICropperPictureMode.Rectangle) {
        this.cropper = new Cropper(image, {
          aspectRatio: 4 / 3,
          viewMode: 0,
          dragMode: 'move',
          background: true,
          zoomable: true,
          minCropBoxWidth: 75,
          minContainerWidth: 150
        });
      }
    };
  }

  private async saveEditedPicture() {
    let temp = this.cropper.getCroppedCanvas({
      fillColor: '#fff'
    })?.toDataURL("image/png");

    let name = this.folderName + new Date().toDateString() + "-logo-picture.png";
    let url = await this.fileStorageService.uploadBase64(name, temp);
    if (url != null) {
      this.pictureUrl = url;
      this.cropper.destroy();
      this.controller.close(true, this.pictureUrl);
    } else {
      this.logger.LogError("Error while uploading picture", null, null, true);
    }

    // let response = await this.httpClient.post(Constants.Application.RemoteServiceName + Constants.Application.RemoteFileServicePicture + '?fileName=' + name + "-logo-picture.png", JSON.stringify(temp));
    // if (!response.ok) {
    //   this.logger.LogError(await response.text(), null, null, true);
    // } else {
    //   this.pictureUrl = (await response.json()).url;
    //   this.cropper.destroy();
    //   this.controller.close(true, this.pictureUrl);
    // }
  }

  private cancelEditPicture() {
    this.cropper.destroy();
    this.controller.cancel();
  }
}
