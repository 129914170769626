@import "../../../css/scss/variables";

$trans: all 0.1s ease;

div.ag-cell:hover .ag-cell-comment-handler {
  background: linear-gradient(45deg, transparent 0 50%, $purple-light 50% 100%);
}

ag-cell-comment {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  .ag-cell-comment-handler {
    position: absolute;
    right: 0;
    background: transparent;
    top: 0;
    width: 12px;
    height: 12px;
    transition: $trans;
  }
  div.ag-cell:hover & .ag-cell-comment-handler {
    background: linear-gradient(45deg, transparent 0 50%, $purple-light 50% 100%);
  }
  &.ag-cell-comment-cell-hover .ag-cell-comment-handler {
    //background: linear-gradient(45deg, transparent 0 50%, $purple-light 50% 100%);
  }

  &.ag-cell-comment-commented .ag-cell-comment-handler {
    background: linear-gradient(45deg, transparent 0 50%, $purple-dark 50% 100%) !important;
  }

  &.ag-cell-comment-disabled { opacity: $ag-row-disabled-opacity; }


  &:hover .ag-cell-comment-handler {
    width: 22px;
    height: 22px;
    cursor: pointer;
    transition: $trans;
  }
  
}

