import { GridOptions } from 'ag-grid-community';
import { HttpClient } from 'aurelia-fetch-client';
import { I18N } from 'aurelia-i18n';
import { Container } from 'aurelia-framework';
import * as Constants from '../../../constants';
import { Box, CustomLogger, EnumerationType, IMenuItems, ServiceBase } from 'digiwall-lib';
import * as toastr from 'toastr';
import { DialogService } from 'aurelia-dialog';
import { AddDocument } from 'documents/add-document';
import { DocumentService } from 'documents/document-service';
import { Merlin } from 'generated';

export class ThirdPartyContractMenuItems {
  public static getStatusMenuItems(data, thirdPartyContractStatus: Array<EnumerationType>, gridOptions: GridOptions, service: ServiceBase<Merlin.Web.Model.ThirdPartyContract>): IMenuItems[] {
    let i18n = Container.instance.get(I18N);
    let httpClient = Container.instance.get(HttpClient);
    if (thirdPartyContractStatus == null) return [];
    return [
      {
        label: thirdPartyContractStatus[thirdPartyContractStatus.findIndex(x => x.id == Constants.ThirdPartyContractStatusId.InProcess)].denomination._translation,
        handler: async () => {
          if (data == null || (data.thirdPartyContractId == null && data.id == null)) return;
          let requestUri = Constants.Application.ContractController.PatchStatus.format(data.thirdPartyContractId != null ? data.thirdPartyContractId.toString() : data.id.toString(), thirdPartyContractStatus[thirdPartyContractStatus.findIndex(x => x.id == Constants.ThirdPartyContractStatusId.InProcess)].id.toString());
          let response = await httpClient.patch(requestUri);
          if (response.ok) {
            if (gridOptions != null) gridOptions.api.refreshInfiniteCache();
            if (service != null) data = await service.getEntityById(data.id, 'specificConditions.contentTemplate');
          } else {
            let errMess = await response.json();
            toastr.error(i18n.tr(errMess));
          }
        },
        disabled: () => {
          if (data.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract) return false;
          return true;
        }
      },
      {
        label: thirdPartyContractStatus[thirdPartyContractStatus.findIndex(x => x.id == Constants.ThirdPartyContractStatusId.Validated)].denomination._translation,
        handler: async () => {
          if (data == null || (data.thirdPartyContractId == null && data.id == null)) return;
          let requestUri = Constants.Application.ContractController.PatchStatus.format(data.thirdPartyContractId != null ? data.thirdPartyContractId.toString() : data.id.toString(), thirdPartyContractStatus[thirdPartyContractStatus.findIndex(x => x.id == Constants.ThirdPartyContractStatusId.Validated)].id.toString());
          let response = await httpClient.patch(requestUri);
          if (response.ok) {
            if (gridOptions != null) gridOptions.api.refreshInfiniteCache();
            if (service != null) data = await service.getEntityById(data.id, 'specificConditions.contentTemplate');
            await this.addFiles(data);
          } else {
            let errMess = await response.json();
            toastr.error(i18n.tr(errMess));
          }
        },
        disabled: () => {
          if (data.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract) return false;
          if (data.statusId == Constants.ThirdPartyContractStatusId.Draft || data.statusId == Constants.ThirdPartyContractStatusId.InProcess
            || data.thirdPartyContractStatusId == Constants.ThirdPartyContractStatusId.Draft || data.thirdPartyContractStatusId == Constants.ThirdPartyContractStatusId.InProcess) return false;
          return true;
        }
      },
      {
        label: thirdPartyContractStatus[thirdPartyContractStatus.findIndex(x => x.id == Constants.ThirdPartyContractStatusId.Cancelled)].denomination._translation,
        handler: async () => {
          if (data == null || (data.thirdPartyContractId == null && data.id == null)) return;
          let requestUri = Constants.Application.ContractController.PatchStatus.format(data.thirdPartyContractId != null ? data.thirdPartyContractId.toString() : data.id.toString(), thirdPartyContractStatus[thirdPartyContractStatus.findIndex(x => x.id == Constants.ThirdPartyContractStatusId.Cancelled)].id.toString());
          let response = await httpClient.patch(requestUri);
          if (response.ok) {
            if (gridOptions != null) gridOptions.api.refreshInfiniteCache();
            if (service != null) data = await service.getEntityById(data.id, 'specificConditions.contentTemplate');
          } else {
            let errMess = await response.json();
            toastr.error(i18n.tr(errMess));
          }
        },
        disabled: () => {
          if (data.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract) return false;
          if (data.statusId == Constants.ThirdPartyContractStatusId.Draft || data.statusId == Constants.ThirdPartyContractStatusId.InProcess
            || data.thirdPartyContractStatusId == Constants.ThirdPartyContractStatusId.Draft || data.thirdPartyContractStatusId == Constants.ThirdPartyContractStatusId.InProcess) return false;
          return true;
        }
      }
    ];
  }

  private static async addFiles(data) {
    let documentService = new DocumentService(
      Container.instance.get(HttpClient),
      Container.instance.get(I18N),
      Container.instance.get(Box),
      Container.instance.get(CustomLogger),
    )
    documentService.projectId = data.projectId;
    documentService.projectPhaseId = Constants.ProjectPhaseId.Buying;
    let dialogService = Container.instance.get(DialogService);
    let folderName = "contract/" + data.projectId + "/" + data.id;
    await dialogService.open({
      viewModel: AddDocument,
      model: {
        service: documentService,
        folderName: folderName,
        documentModuleId: Constants.DocumentModuleId.Project,
        fileTypeId: Constants.FileTypeId.Contract,
        documentStatusId: Constants.DocumentStatusId.Signed
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("z-index-level-4");
        dialogOverlay.classList.add("z-index-level-4");
      },
    }).whenClosed(async result => {

    });
  }
}
