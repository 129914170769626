import { GlobalLoaderService } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from "aurelia-framework";
import * as Constants from '../constants';
import { GridOptions } from 'ag-grid-community';
import { Merlin } from 'generated';
import { WorkQuotationVersionApiService } from './work-quotation-version-api-service';
import { RequestFilterPriceOfferLine } from './i-data-line-api-service';

@autoinject
export class RequestSupplierApiService {
  constructor(private httpClient: HttpClient, private gls: GlobalLoaderService) {

  }

  public async setStatus(type: any, id: number, statusId: number) {
    this.gls.allow();
    await this.httpClient.post(Constants.Application.RequestSupplierController.SetStatus.format(type.toString(), id.toString(), statusId.toString()));

  }

  public async deleteRequestToSupplier(type, requestSupplierOfferId, id) {
    this.gls.allow();
    return await this.httpClient.delete(Constants.Application.RequestSupplierController.DeleteRequestToSupplier.format(type.toString(), requestSupplierOfferId.toString(), id.toString()));
  }

  public async SetSupplierSource(type, supplierOfferId) {
    this.gls.allow();
    return await this.httpClient.get(Constants.Application.RequestSupplierController.SetSuppliersSource.format(type.toString(), supplierOfferId.toString()));
  }

  public async GetThirdPartyToSelect(type, requestSupplierOfferId) {
    this.gls.allow();
    let response = await this.httpClient.get(Constants.Application.RequestSupplierController.GetThirdPartyToSelect.format(type.toString(), requestSupplierOfferId.toString()));
    if (response.ok) {
      let json = await response.json();
      return json;
    }
    else {
      return null;
    }
  }
}

@autoinject
export class RequestSupplierOfferServiceStep1 extends WorkQuotationVersionApiService {
  public entity: Merlin.Web.Model.RequestSupplierOffer
  public dataLines: Array<any>;
  type: number;
  public select(lineId: number, filterModel?: { [key: string]: any; }) {
    return this.selectOrUnselect(lineId, 'select', filterModel);
  }
  public unselect(lineId: number, filterModel?: { [key: string]: any; }) {
    return this.selectOrUnselect(lineId, 'unselect', filterModel);
  }
  private async selectOrUnselect(lineId: number, operation: 'select' | 'unselect', filterModel?: { [key: string]: any; }) {
    try {
      let requestParams: RequestFilterPriceOfferLine = {
        filterModel: filterModel,
        quickFilter: null,
        displayMode: Constants.TreeDisplayMode.Tree,
        displayHidden: false,
        refToDisplay: Constants.RefToDisplay.MerlinRef
      };
      let requestUri = Constants.Application.RequestSupplierController.AddLine.format(this.type.toString(), this.entity.id.toString(), lineId.toString());
      let response: Response;
      if (operation == 'select') {
        this.globalLoaderService.allow(true, 500);
        response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
        if (this.callback != null) {
          this.callback();
        }
        this.selectChildren(this.dataLines?.find(x => x.id == lineId));
      }
      else {
        this.globalLoaderService.allow(true, 500);
        response = await this.httpClient.delete(requestUri, JSON.stringify(requestParams));
        this.callback();
        this.unselectChildren(this.dataLines?.find(x => x.id == lineId));
      }

      if (response.ok) {
        let json = await response.json();
        return json;
      }
      return null;
    }
    catch {
      return null;
    }
  }

  public async children(versionId: number, lineId: number, displayHidden = false, refToDisplay: Constants.RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.RequestSupplierController.GetChildren.format(this.type.toString(), this.entity.id.toString(), lineId.toString(), displayHidden.toString(), refToDisplay.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<Array<any>> {

    await this.filtersUtils.forceEnumFilterValues(filterParams, "itemDeliveryTypeId", [Constants.ItemDeliveryType.SubContractor, Constants.ItemDeliveryType.Supplier], Constants.EnumerationTypes.ItemDeliveryType);

    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    console.log(this.type, this.entity);
    let requestUri = Constants.Application.RequestSupplierController.GetAll.format(this.type.toString(), this.entity.id.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: Constants.RefToDisplay) {
    let result = await this.httpClient.post(Constants.Application.RequestSupplierController.GetByIds.format(this.type.toString(), this.entity.id.toString(), refToDisplay.toString()), JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  private selectChildren(line) {
    let children = this.dataLines?.filter(x => x.parentId == line.id);
    children?.forEach(child => {
      child.isSelected = true;
      if (child.hasChildren) {
        this.selectChildren(child);
      }
    })
  }

  private unselectChildren(line) {
    let children = this.dataLines?.filter(x => x.parentId == line.id);
    children?.forEach(child => {
      child.isSelected = false;
      if (child.hasChildren) {
        this.unselectChildren(child);
      }
    })
  }

}


@autoinject
export class RequestSupplierOfferService2Step1 extends WorkQuotationVersionApiService {
  public entity: Merlin.Web.Model.RequestSupplierOffer
  type: number;


  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.RequestSupplierController.GetSelectedLines.format(this.type.toString(), this.entity.id.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

}
