@import "../css/scss/variables";
/*--- UI FILE ---*/
.theme-light {
  ui-files {
    > div {
      > div > div {
        grid-template-areas: "dropzone" "table" "endAction";
        // display: flex;
        // flex-direction: column;
        > div {
          display: flex;
          flex-direction: column;
          padding: 3rem 0px;
          background: transparent;
          align-items: center;
          ui-button {
            > .ui-btn__inner {
              border: 1px $purple-lighter solid;
              box-shadow: none;
              border-radius: 60px;
              height: 44px;
              padding: 0px 20px;
              .ui-btn__icon {
                color: $blue-mid;
              }
            }
            &:hover {
              .ui-btn__inner {
                background-color: $blue-mid;
                color: $white;
              }
              .ui-btn__icon {
                color: $white;
              }
            }
          }
        }
        .ui-file-custom-action {
          grid-area: endAction;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          padding: 0;
          padding-top: 3rem;
        }
      }
      > compose {
        .ui-files-actions {
          display: none;
        }
        ui-table > .ui-table {
          border-collapse: separate;
          border-spacing: 10px;
          > thead > tr {
            border-bottom: none;
            > th {
              border-bottom: none;
              background-color: transparent;
              .ui-table__header__cell__label {
                color: $blue-mid;
                font-weight: 400;
                > span {
                  color: $blue-mid;
                }
              }
            }
          }
          > tbody > tr {
            border-bottom: none;
            > .ui-table__cell {
              &:first-child {
                border-bottom: none;
                > div {
                  padding-left: 0;
                }
              }
              border-bottom: solid 1px $gray-lighter;
              ui-enumeration-select > ui-select {
                background-color: transparent;
              }
              ui-input,
              ui-select {
                border: none;
                > span > span > span > .select2-selection__clear {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
    table {
      > thead {
        > tr > th {
          color: $blue-mid;
          > div {
            span ~ span {
              display: none;
            }
          }
        }
      }
    }
    .dropzone.hover {
      background-color: $purple-lightest !important;
    }
    .dropzone.hover.in {
      background-color: $purple-lighter !important;
      .dropzone-content {
        background-color: $purple-lighter;
      }
    }
    .dropzone {
      border: 1px dashed $gray-light;
      border-radius: 8px;
      .dropzone-content {
        background-color: $purple-lightest;
        border-radius: 8px;
        gap: 1rem;
        flex-direction: column;
        > div > ui-button {
          background-color: $white !important;
          box-shadow: none !important;
          color: $blue-darkest !important;
          border: solid 1px $purple-lighter !important;
          border-radius: 60px;
          .ui-btn__icon {
            padding: 0;
            padding-right: 10px;
            color: $blue-mid;
          }
          &:hover {
            border: solid 1px $purple-mid !important;
          }
        }
      }
    }
  }
}
