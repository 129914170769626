import { HttpClient } from 'aurelia-fetch-client';
import { I18N } from 'aurelia-i18n';
import { autoinject, Container } from "aurelia-framework";
import { Box, CustomLogger, IMenuGroup, StepElement } from "digiwall-lib";
import { AmendmentApiService } from "services/amendment-api-service";
import { DialogController } from 'aurelia-dialog';
import * as Constants from './../../../../constants';
import { Merlin } from "generated";
import { NotificationLanguageDTO } from 'email-sender/email-sender';

@autoinject
export class AmendmentCreation {
  private projectId: number;
  private contractId: number;
  private selectedLines: Array<any> = [];
  public stepElements: Array<StepElement> = [
    {
      id: "selection",
      step: 1,
      label: this.i18n.tr("amendment.step1"),
      disabledClick: () => { return false }
    },
    {
      id: "supplier",
      step: 2,
      label: this.i18n.tr("amendment.step2"),
      disabledClick: () => { return false; }
    },
    {
      id: "mail",
      step: 3,
      label: this.i18n.tr("amendment.step3"),
      disabledClick: () => { return false; }
    }
  ] as Array<StepElement>;
  public currentElement: StepElement;

  private notification: Map<number, NotificationLanguageDTO> = new Map<number, NotificationLanguageDTO>();
  public selectedThirdParties: Array<number> = [];
  private companyIds: Array<number> = [];
  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest = {};

  constructor(private i18n: I18N, private amendmentApiService: AmendmentApiService, private dialogController: DialogController, private httpClient: HttpClient, private logger: CustomLogger) {

  }

  public async activate(params: any) {
    this.stepElements[0].isActif = true;
    this.currentElement = this.stepElements[0];

    this.projectId = params.param1.projectId;
    this.contractId = params.param1.contractId;
    this.amendmentApiService.dataLines = null;
    this.amendmentApiService.projectId = this.projectId;
    this.amendmentApiService.contractId = this.contractId;
    this.previewRequestParams = { projectId: this.projectId, contractId: this.contractId };
  }

  public async createAmendment(statusId: number) {
    let lineSelectedIds = this.selectedLines.map(x => x.id);
    return await this.amendmentApiService.createAmendment(this.projectId, this.contractId, statusId, lineSelectedIds);
  }

  public afterStep1() {
    this.nextStep();
  }

  public async stopCreation() {
    await this.createAmendment(Constants.ThirdPartyContractStatusId.Draft);
    await this.ok();
  }

  public async afterStep2() {
    this.nextStep();
  }

  public async afterStep3() {
    await this.sendEmail();
  }

  private nextStep() {
    let newIndex = this.stepElements.findIndex(x => x == this.currentElement) + 1;
    this.currentElement.isValidStep = true;
    let newCurrentElement = this.stepElements[newIndex]
    if (newCurrentElement) {
      this.currentElement = newCurrentElement;
      this.currentElement.isActif = true;
    }
  }

  async ok() {
    await this.dialogController.close(true);
  }

  async close() {
    await this.dialogController.close(false);
  }

  private async sendEmail() {
    let result = await this.httpClient.post(Constants.Application.EmailController.CreateEmail, JSON.stringify({ IdsToSend: this.selectedThirdParties, NotifsLang: Object.fromEntries(this.notification.entries()), PreviewRequestParams: this.previewRequestParams }));
    if (result.ok) {
      let output = await result.json();
      let newContractId = await this.createAmendment(Constants.ThirdPartyContractStatusId.InProcess);
      await this.amendmentApiService.setSendIds(this.projectId, newContractId, this.selectedThirdParties);
      this.ok();
    }
    else {
      let error = await result.text();
      this.logger.LogError(this.i18n.tr("requestsupplieroffer.ccError", { mail: error }), null, null, true)
    }
  }

  public static getCreateAmendmentMenuItem(projectId: number, lineId: number = null, contractId: number = null, disabled: boolean | (() => boolean), afterCreation: () => Promise<void> | void): IMenuGroup {
    let i18n = Container.instance.get(I18N);

    return {
      group: "amendment",
      hiddenLabel: true,
      items: [
        {
          label: i18n.tr("versionmetering.sentAmendmentProposition"),
          icon: "digi-file-text-line",
          disabled: disabled,
          handler: async () => {
            await AmendmentCreation.createAmendment(projectId, lineId, contractId, afterCreation)
          }
        }
      ]
    }
  }

  public static async createAmendment(projectId: number, lineId: number = null, contractId: number = null, afterCreation: () => Promise<void> | void) {
    let amendmentApiService = Container.instance.get(AmendmentApiService);
    let i18n = Container.instance.get(I18N);
    let box = Container.instance.get(Box);
    let logger = Container.instance.get(CustomLogger);

    if (contractId == null && lineId != null) {
      contractId = await amendmentApiService.getValidatedOriginalContractId(projectId, lineId);
    }
    else if (lineId == null && contractId == null) {
      logger.LogError("Error to find original contract", null, null, true)
    }
    await box.showCustomDialog(
      AmendmentCreation,
      {
        projectId: projectId,
        contractId: contractId
      } as any,
      i18n.tr('amendment.addAmendment'),
      {
        canSave: false,
        size: 'all',
        containerClass: "amendment-creation",
      }
    ).whenClosed(async result => {
      if (!result.wasCancelled)
        await afterCreation();
      //this.priceOfferLinesGrid.listTreeData.refreshServerSideRows(null, true, true);
    })
  }
}
