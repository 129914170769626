import { singleton } from "aurelia-framework";

@singleton(false)
export class IdsToCompareSingleton {
  private selectedIds: Array<number> = new Array<number>();

  public setIds(ids: Array<number>) {
    this.selectedIds.splice(0);
    this.selectedIds.push(...ids);
  }

  public getIds(): Array<number> {
    return this.selectedIds;
  }
}
