.ui-viewport-center {
  width: 100%;
  .ui-horizontal-menu__subGroup > div,
  .ui-horizontal-menu__child > div {
    cursor: pointer;
  }
}

.navbar__menu__item {
  > a.isSelected,
  > div.isSelected,
  > a:hover,
  > div:hover {
    font-weight: normal;
  }
}
