import { autoinject } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { FloatingBoxViewModel } from "digiwall-lib";
import { ProjectResumeExtern } from "../project-resume";

@autoinject
export class FloatingExtern extends FloatingBoxViewModel {

  constructor(private projectResumeExtern: ProjectResumeExtern, private navigateToEditProject: () => void, private router: Router) {
    super('./floating-extern.html', 'sm');

  }

  async activate(config: any): Promise<void> {
    await super.activate(config);
  }
  public async close() {

    await this.dialogController.close(false);
  }
}
