import { ContentTemplateApiService } from './../services/email-template-api-service';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Various, IMenuGroup, EnumerationTypeService, EnumerationType } from 'digiwall-lib';
import { autoinject, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { HttpClient } from 'aurelia-fetch-client';

@autoinject
export class ContentTemplateDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ContentTemplate> {
  public ressourceName: string = Constants.EntityTypeNames.ContentTemplate;
  public menuItems: Array<IMenuGroup>;
  public contentTypeService: EnumerationTypeService;
  public contentTemplateApiService: ContentTemplateApiService;


  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient) {
    super(router, logger);
    this.contentTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ContentType);
    this.contentTemplateApiService = new ContentTemplateApiService(httpClient);
    super.initialize(new ServiceBase<Merlin.Web.Model.ContentTemplate>(Constants.EntityTypeNames.ContentTemplate));
  }

  @computedFrom('editingMode', 'entity.templateNameML._translation')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("contenttemplate.contenttemplate");
    }
    else {
      return this.entity.templateNameML._translation ?? "";
    }
  }

  public async activate(params: any) {
    let id: number = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({
        isActif: true,
        isDefault: false
      });
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'contentType');
      this.controller.addObject(this.entity);
    }
    this.setMenuItems();
  }
  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      },
    ];
  }

}
