@import "./../../../css/scss/variables";

.contract-list {
  padding-left: 15px;
  .main-contrat {
    cursor: pointer;
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
  .amendment {
    padding-left: 15px;
    padding-top: 10px;
    cursor: pointer;
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
  ui-icon {
    padding-right: 10px;
  }
  .selected {
    color: $primary;
  }
}
