.theme-light body.ux-dialog-open {
  ux-dialog-overlay.active {
    background-color: black;
    opacity: 0.5;
  }
  ux-dialog-container {
    > div {
      height: 100%;
      > div {
        height: 100%;
        display: flex;
        ux-dialog {
          &.questionBox,
          &.notificationBox,
          &.warningBox,
          &.errorBox {
            border-top-left-radius: $radius;
            border-top-right-radius: $radius;
            border-bottom-left-radius: $radius;
            border-bottom-right-radius: $radius;
            box-shadow: none;
            > ux-dialog-header {
              border-top: none;
              .dialog-header-content > .grid-dialog-header {
                .grid-dialog-header__close {
                  .ui-icon {
                    font-size: 1em;
                  }
                }
                .grid-dialog-header__icon {
                  color: $black-100;
                  .ui-icon {
                    font-size: 1.8em;
                  }
                }
              }
            }
            > ux-dialog-body {
              .message {
                white-space: pre-wrap;
              }
            }
            > ux-dialog-footer {
              background-color: $background-footer;
              border-bottom-left-radius: $radius;
              border-bottom-right-radius: $radius;
            }
          }
          &.editDialog {
            border: 5px solid $blue-darkest;
            border-left: 0px;
            border-top-right-radius: $radius;
            border-bottom-right-radius: $radius;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            ux-dialog-header {
              border-top-right-radius: $radius;
              .dialog-header-content > .grid-dialog-header > .grid-dialog-header__close {
                .ui-icon {
                  font-size: 1em;
                }
              }
            }
            ux-dialog-footer {
              background-color: $background-footer;
              border-bottom-right-radius: $radius;
              box-shadow: none;
            }
          }
        }
        /* ux-dialog,
        ux-dialog.editDialog > ux-dialog,
        > ux-dialog.editDialog {
          border: 5px solid $blue-darkest;
          border-left: none;
          background-color: $white !important;
          padding: 0;
          // border-radius: $radius;
          border-top-right-radius: $radius;
          border-top-left-radius: $radius;
          border-bottom-right-radius: $radius;
          border-bottom-left-radius: $radius;
          > ux-dialog-header {
            padding: 30px 30px 0px 30px;
            // border-top-left-radius: $radius;
            border-top-right-radius: $radius;

            > .dialog-header-content {
              > ui-button {
                display: contents;
              }
            }
          }
          > ux-dialog-body {
            padding: 30px;
            > compose > .ui-page > .ui-page__body > .ui-section__content > .row {
              div > .ui-panel > .ui-panel__body {
                overflow: hidden;
                > .row > div > .ui-field > .ui-field__wrapper {
                  flex: 1 1 1rem;
                  .ui-select {
                    border: none;
                  }
                  .ui-input__addon {
                    border: none;
                    border-left: solid 1px $gray-lighter;
                  }
                }
              }
              .ui-panel > .ui-panel__body > ui-table > .ui-table > tbody > tr {
                > .ui-table__cell {
                  &:first-child {
                    > div > ui-button > .ui-btn__inner > .ui-btn {
                      &:hover {
                        border-radius: 50px;
                        background-color: $purple-lighter;
                      }
                    }
                  }
                }
              }
            }
          }
          > ux-dialog-footer {
            background-color: $background-footer;
            border-bottom-left-radius: $radius;
            border-bottom-right-radius: $radius;
            padding-right: 30px;
            padding-left: 30px;
            box-shadow: none;
          }
          &.questionBox,
          &.notificationBox,
          &.warningBox,
          &.errorBox {
            width: 35% !important;
            border: none;
            > ux-dialog-header {
              .dialog-header-content > .grid-dialog-header > .grid-dialog-header__close > ui-button {
                .ui-icon {
                  font-size: 1em;
                }
              }
            }
            > ux-dialog-body {
              // border-top-left-radius: $radius;
              border-top-right-radius: $radius;
              padding-bottom: 0;
              padding-top: 15px;
            }
            > ux-dialog-footer {
              display: flex;
              flex-direction: row-reverse;
            }
          }
        }
        > ux-dialog.ui-dialog-right {
          border-top-right-radius: $radius;
          border-bottom-right-radius: $radius;
          border: 5px solid $blue-darkest;
          border-left: 0px;
          > ux-dialog-header {
            //border-top-right-radius: $radius;
            //border-top-left-radius: $radius;
          }
          > ux-dialog-footer {
            //border-bottom-right-radius: $radius;
            //border-bottom-left-radius: $radius;
          }
        }*/
      }
    }
  }
}

ux-dialog-container {
  .ux-add-files {
    max-width: 750px;
    background-color: $black-0 !important;
    border-radius: $radius;
    > ux-dialog-body {
      padding: 15px;
      padding-top: 5px;
      > .ui-btn__wrapper > .ui-btn__inner {
        box-shadow: none;
        background-color: transparent;
        padding-bottom: 5px;
        > .ui-btn {
          &:active {
            background-color: transparent;
            box-shadow: none;
          }
        }
      }
    }
    > ux-dialog-footer {
      background-color: $purple-very-light;
      border-bottom-right-radius: $radius;
      border-bottom-left-radius: $radius;
    }
  }
  ux-dialog {
    > ux-dialog-header {
      border-top: none;
    }
  }

  .ag-grid-view-list > div > div {
    border-bottom: none !important;
  }
}

.floating-box > div > div floating-box-view.header-footer-fixed {
  > .header-footer-fixed__footer > .floating-footer-align-right {
    justify-content: flex-end;
  }
  > .header-footer-fixed__content {
    div > div > .number-filter-input > div {
      padding: 0;
      &:first-child {
        padding-right: 0.75rem;
      }
    }
    .header-footer-fixed > .header-footer-fixed__content > div > .group > .row {
      margin: 0 !important;
    }
  }
}

@media only screen and (max-width: 992px) {
  ux-dialog-container {
    > div {
      padding: 10px;
      > div {
        width: initial;
      }
    }
  }
  ux-dialog {
    width: 100%;
  }
}

ux-dialog-container .header-footer-fixed {
  .header-footer-fixed__content ui-files {
    compose > .ui-files-actions {
      display: none;
    }
    .dropzone {
      border: none;
      padding: 0;
    }
  }
  .header-footer-fixed__content {
    padding: 0 40px;
    > .row > div,
    .row > div > div {
      padding: 0;
    }
  }
  .header-footer-fixed__footer {
    display: flex;
    align-items: center;
    justify-content: end;
    > span {
      gap: 30px;
    }
    .floating-footer-align-right {
      gap: 30px;
    }
  }
}

.container-notification-view-box {
  > div {
    > div {
      > ux-dialog {
        > ux-dialog-header {
          > button {
            display: none;
          }
        }
      }
    }
  }
}

.container-input-view-box {
  > div {
    > div {
      > ux-dialog {
        border-radius: $radius;
        position: relative;
        height: fit-content;
        > ux-dialog-header {
          border-top-left-radius: $radius;
        }
        > ux-dialog-body {
          > ui-panel.ui-panel {
            > .ui-panel__header {
              border-bottom: none;
            }
            > .ui-panel__body {
              padding-top: 0;
            }
          }
        }
        > ux-dialog-footer {
          border-bottom-left-radius: $radius;
        }
      }
    }
  }
}
