ui-button {
  &.ui-theme--primary {
    border-radius: $radius !important;
    font-size: 15px !important;
    .ui-btn {
      font-size: 14px;
      .ui-btn__icon {
        padding: 0;
        // padding-right: 10px;
      }
      .ui-btn__label {
        padding-left: 10px;
      }
    }
    &[data-type="solid"] {
      background-color: $blue-mid !important;
      color: $black-0;
      border: solid 1px transparent !important;
      &:hover {
        background-color: $merlin-blue-dark !important;
      }
      .ui-btn {
        &:focus-within,
        &:hover,
        &:active {
          background-color: transparent !important;
        }
        > .ui-btn__icon {
          color: $black-0;
        }
      }
      &[data-disabled="true"] {
        background-color: $purple-lighter !important;
        color: $purple-mid !important;
        &:hover {
          background-color: $purple-lighter !important;
        }
      }
    }
    &[data-type="outline"] {
      background-color: $black-0 !important;
      border: solid 1px $purple-lighter !important;
      color: $blue-darkest !important;
      &:hover {
        border: solid 1px $purple-dark !important;
        background-color: $black-0 !important;
      }
      .ui-btn {
        &:active {
          background-color: transparent;
        }
        .ui-btn__icon {
          color: $blue-mid;
        }
      }
    }
  }
  &.ui-theme--secondary {
    &[data-type="ghost"] {
      color: $gray-mid !important;
      &:hover,
      &:focus-within {
        color: $black-100 !important;
      }
      .ui-btn {
        padding: 0;
      }
    }
  }
  &[data-type="ghost"] {
    .ui-btn {
      padding: 0 !important;
    }
  }
  &[data-type="tool"] {
    border: solid 1px $purple-light !important;
    background-color: $white;
    &:hover {
      background-color: transparent !important;
    }
    .ui-btn__inner {
      //color: $blue-darkest !important;
      &:hover {
        color: $primary !important;
      }
      .ui-btn {
        padding: 0;
      }
    }
  }
  .ui-btn__inner > .ui-btn {
    padding: 10px 20px;
    &:active {
      background-color: transparent !important;
    }
    .ui-btn__icon > .ui-icon {
      font-size: 20px;
      padding: 0;
    }
    .ui-btn__label {
      font-weight: 400;
      line-height: initial;
    }
  }
}
