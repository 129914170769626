import { Container } from 'aurelia-framework';
import { Merlin, } from './../../generated.d';
import { I18N } from 'aurelia-i18n';
import { IMenuItems, UIInternal } from "digiwall-lib";
import { DialogService } from 'aurelia-dialog';
import { AddWorkQuotationVersion } from 'work-quotation-versions/add-work-quotation-version';
import { EditWorkQuotationVersion } from 'work-quotation-versions/edit-work-quotation-version';
import { ProjectApiService } from 'services/project-api-service';
import * as Constants from '../../constants';

export class VersionMenuItems {
  public static getMenuItems(projectId: number, workQuotationVersionId: number, workQuotationVersion: Merlin.Web.Model.WorkQuotationVersion, isCurrentVersion: boolean): IMenuItems[] {
    let i18n = Container.instance.get(I18N);
    return [{
      label: i18n.tr("workquotationversion.addWorkQuotationVersion"),
      icon: "digi-add-circle-line",
      handler: () => {
        this.addWorkQuotationVersion(projectId);
      }
    },
    {
      label: i18n.tr("workquotationversion.editWorkQuotationVersionTitle"),
      icon: "digi-edit",
      handler: async () => {
        let result = await this.editWorkQuotationVersion(workQuotationVersionId);
        if (workQuotationVersion != null) {
          workQuotationVersion.quotationVersionName = result.fullName;
        }
      }
    },
    {
      label: i18n.tr("workquotationversion.changeCurrentVersion"),
      icon: "digi-shield-star-line",
      disabled: () => isCurrentVersion,
      handler: async () => {
        await this.changeCurrentVersion(projectId, workQuotationVersionId);

      }
    }]
  }

  public static async addWorkQuotationVersion(projectId) {
    let dialogService = Container.instance.get(DialogService)
    let result = await dialogService.open({
      viewModel: AddWorkQuotationVersion,
      model: {
        projectId: projectId
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("dialog-container-add-work-quotation-version");
      },
    });
    if (!result.wasCancelled) {
      const closeResult = await result.closeResult;
      return closeResult.output;
    }
    return null;
  }

  private static async editWorkQuotationVersion(workQuotationVersionId) {
    let dialogService = Container.instance.get(DialogService);
    let result = await dialogService.open({
      viewModel: EditWorkQuotationVersion,
      model: {
        workQuotationVersionId: workQuotationVersionId
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("dialog-container-add-work-quotation-version");
      },
    });
    if (!result.wasCancelled) {
      const closeResult = await result.closeResult;
      return closeResult.output;
    }
    return null;
  }

  private static async changeCurrentVersion(projectId, workQuotationVersionId) {
    let projectApiService = Container.instance.get(ProjectApiService);
    let version = await projectApiService.changeCurrentVersion(projectId, workQuotationVersionId);
    UIInternal.broadcast(Constants.MerlinCustomEvent.CurrentVersionChange, { id: version.id, text: version.fullName, isCurrentVersion: version.isCurrentVersion });
  }
}
