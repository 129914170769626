@import "./../../css/scss/variables";

ui-page.project-resume {
  .page-title {
    display: flex;
    gap: 15px;
  }
  .page-title:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    color: $blue-mid;
  }
  ui-content {
    margin: 0 40px;

    .container-project-resume-el {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 10px;
      margin: 10px 0;
      > div {
        .resume {
          &-part1 {
            display: grid;
            grid-template-columns: 80px auto 150px;
            gap: 30px;
            padding: 10px;
            border: solid 1px $gray-lighter;
            border-radius: $radius;
            .resume-el {
              &-img {
                img {
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                }
              }
              &-desc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 5px;
                > span {
                  &:first-child {
                    font-size: 25px;
                    font-weight: 600;
                  }
                }
              }
              &-tag {
                text-align: right;
              }
            }
          }
          &-part2 {
            margin: 10px 0;
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 10px;
            > div {
              border: solid 1px $gray-lighter;
              border-radius: $radius;
            }
            &-graph {
              display: grid;
              grid-template-columns: 1fr auto;
              > div {
                padding: 20px;
                &:first-child {
                  background-color: $purple-lightest;
                  border-top-left-radius: $radius;
                  border-bottom-left-radius: $radius;
                  > div:not(.part2-graph) {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    ui-icon {
                      color: $merlin-blue-dark;
                    }
                    span {
                      font-size: 16px;
                      font-weight: 600;
                    }
                  }
                  .part2-graph {
                    /* FAKE GRAPH */
                    .graph {
                      ul:not(.graph-el) {
                        margin: 30px 0;
                        li {
                          color: $purple-mid;
                          font-size: 10px;
                          font-weight: 500;
                          padding-top: 38px;
                          padding-bottom: 11px;
                          border-bottom: dashed 1px $purple-mid;
                        }
                      }
                      .graph-el {
                        display: flex;
                        gap: 20px;
                        margin: 0 40px;
                        position: relative;
                        li {
                          width: 20px;
                          position: relative;
                          max-width: 16px;
                          > div {
                            width: 6px;
                            position: absolute;
                            bottom: 0;
                            border-radius: $radius;
                            &:first-child {
                              background-color: $purple-mid;
                            }
                            &:last-child {
                              background-color: $merlin-blue-dark;
                              right: 0;
                            }
                            > .circle {
                              width: 24px;
                              height: 24px;
                              border: solid 1px $blue-mid;
                              position: absolute;
                              top: -12px;
                              left: -9px;
                              border-radius: 50%;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              > div {
                                width: 8px;
                                height: 8px;
                                background-color: $blue-mid;
                                border-radius: 50%;
                              }
                            }
                            > .tooltip {
                              background-color: $white;
                              border-radius: 6px;
                              padding: 10px 16px;
                              box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
                              font-size: 12px;
                              font-weight: 400;
                              position: absolute;
                              width: max-content;
                              top: -40px;
                              left: 20px;
                            }
                          }
                          &:nth-child(1) {
                            > div {
                              &:first-child {
                                height: 55px;
                              }
                              &:last-child {
                                height: 24px;
                              }
                            }
                          }
                          &:nth-child(2) {
                            > div {
                              &:first-child {
                                height: 160px;
                              }
                              &:last-child {
                                height: 87px;
                              }
                            }
                          }
                          &:nth-child(3) {
                            > div {
                              &:first-child {
                                height: 44px;
                              }
                              &:last-child {
                                height: 208px;
                              }
                            }
                          }
                          &:nth-child(4) {
                            > div {
                              &:first-child {
                                height: 145px;
                              }
                              &:last-child {
                                height: 128px;
                              }
                            }
                          }
                          &:nth-child(5) {
                            > div {
                              &:first-child {
                                height: 90px;
                              }
                              &:last-child {
                                height: 23px;
                              }
                            }
                          }
                          &:nth-child(6) {
                            > div {
                              &:first-child {
                                height: 69px;
                              }
                              &:last-child {
                                height: 53px;
                              }
                            }
                          }
                          &:nth-child(7) {
                            > div {
                              &:first-child {
                                height: 118px;
                              }
                              &:last-child {
                                height: 53px;
                              }
                            }
                          }
                          &:nth-child(8) {
                            > div {
                              &:first-child {
                                height: 243px;
                              }
                              &:last-child {
                                height: 113px;
                              }
                            }
                          }
                          &:nth-child(9) {
                            > div {
                              &:first-child {
                                height: 56px;
                              }
                              &:last-child {
                                height: 23px;
                              }
                            }
                          }
                          &:nth-child(10) {
                            > div {
                              &:first-child {
                                height: 141px;
                              }
                              &:last-child {
                                height: 93px;
                              }
                            }
                          }
                          &:nth-child(11) {
                            > div {
                              &:first-child {
                                height: 102px;
                              }
                              &:last-child {
                                height: 28px;
                              }
                            }
                          }
                        }
                      }
                    }
                    .graph-footer {
                      display: grid;
                      grid-template-columns: 1fr auto auto;
                      gap: 30px;
                      margin-top: 10px;
                      ui-toggle {
                        margin-top: 0;
                        .ui-option__control {
                          span {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 15px;
                          }
                          .ui-option__toggle {
                            width: 28px;
                            height: 16px;
                            background-color: $gray-lighter !important;
                            &::after {
                              height: 12px;
                              width: 12px;
                              margin-left: 4px;
                              margin-top: 3.5px;
                            }
                          }
                        }
                      }
                      > div {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        .graph-footer-round {
                          width: 8px;
                          height: 8px;
                          border-radius: 8px;
                          background-color: $gray-darker;
                          &.purple {
                            background-color: $purple-mid;
                          }
                        }
                      }
                    }
                  }
                }
                &:last-child {
                  display: grid;
                  padding: 20px;
                  padding-bottom: 50px;
                  position: relative;
                  > div {
                    &:first-child {
                      margin-top: 40px;
                      display: flex;
                      flex-direction: column;
                      gap: 15px;
                      border-bottom: solid 1px $gray-lighter;
                      span {
                        &:first-child {
                          font-size: 14px;
                          font-weight: 400;
                          color: $blue-mid;
                        }
                        &:last-child {
                          font-size: 36px;
                          font-weight: 300;
                        }
                      }
                    }
                    &:last-child {
                      margin-top: 20px;
                      gap: 25px;
                      display: flex;
                      flex-direction: column;
                      > div {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        > span {
                          color: $blue-mid;
                          font-size: 12px;
                          font-weight: 400;
                        }
                        > div {
                          display: flex;
                          align-items: center;
                          gap: 5px;
                          > ui-icon {
                            color: $white;
                            font-size: 16px;
                            padding: 2px;
                            border-radius: 50%;
                            &.digi-arrow-right-up-line {
                              background-color: $green-valid;
                            }
                            &.digi-arrow-right-down-line {
                              background-color: $red-warning;
                            }
                          }
                          > span {
                            font-size: 20px;
                            font-weight: 400;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &-detail {
              border: none !important;
              display: grid;
              gap: 10px;
              > div {
                border: solid 1px $gray-lighter;
                border-radius: $radius;
                padding: 20px;
                position: relative;
                > div:first-child {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  ui-icon {
                    color: $blue-mid;
                    font-size: 18px;
                  }
                  span {
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
                > .date {
                  display: flex;
                  flex-direction: column;
                  margin-top: 15px;
                  span {
                    &:first-child {
                      color: $blue-mid;
                    }
                    font-size: 14px;
                    font-weight: 400;
                  }
                }
                ul {
                  margin: 0;
                  margin-top: 15px;
                  display: flex;
                  align-items: center;
                  li {
                    img {
                      width: 24px;
                      height: 24px;
                      border-radius: 50%;
                    }
                    margin-left: -6px;
                    &:first-child {
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }
          &-part3 {
            display: grid;
            grid-template-columns: auto auto;
            gap: 10px;
            > div {
              border: solid 1px $gray-lighter;
              border-radius: $radius;
              padding: 20px;
              display: flex;
              flex-direction: column;
              gap: 20px;
              position: relative;
              > div:first-child {
                display: flex;
                align-items: center;
                gap: 10px;
                ui-icon {
                  color: $blue-mid;
                  font-size: 18px;
                }
                span {
                  font-size: 16px;
                  font-weight: 600;
                }
              }
              > .resume-externe {
                display: flex;
                flex-direction: column;
                > span {
                  font-size: 14px;
                  line-height: 21px;
                  font-weight: 400;
                  &:first-child {
                    color: $blue-mid;
                  }
                  &:nth-child(2) {
                    font-size: 16px;
                    line-height: 28px;
                  }
                  &:last-child {
                    color: $gray-mid;
                  }
                }
              }
            }
          }
        }
        &.resume-aside {
          border-left: solid 1px $gray-lighter;
          > div {
            padding: 15px;
            padding-right: 0;
            border-bottom: solid 1px $gray-lighter;
            display: flex;
            gap: 10px;
            &:not(:first-child) {
              position: relative;
              padding-bottom: 50px;
            }
            &:nth-child(5),
            &:last-child {
              padding-bottom: 20px;
            }
            > div {
              &:first-child {
                ui-icon {
                  font-size: 18px;
                  color: $blue-mid;
                }
              }
              &:nth-child(2) {
                display: flex;
                flex-direction: column;
                gap: 10px;
                > span {
                  &:first-child {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                  }
                  &.adresse {
                    text-decoration-line: underline;
                  }
                }
              }
              &.company {
                > span > div {
                  display: flex;
                  flex-direction: column;
                  span {
                    &:first-child {
                      color: $blue-mid;
                    }
                  }
                }
              }
            }
          }
        }
      }
      span.placeholder {
        color: $gray-mid;
        font-style: italic;
      }
    }
  }

  ui-button[data-type="tool"].btn-floating-project {
    border: solid 1px $gray-lighter !important;
    position: absolute;
    padding: 5px;
    border-radius: 50%;
    bottom: 10px;
    right: 10px;
    .ui-btn__icon {
      padding: 0;
      .ui-icon {
        font-size: 24px !important;
      }
    }
    &.btn-floating-aside {
      padding: 0;
    }
    &:hover {
      border-color: $blue-mid !important;
    }
  }
}

ux-dialog-container.floating-box > div > div > floating-box-view.floating-project {
  .header-footer-fixed__header {
    padding: 30px;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    .project-resume-title {
      display: grid;
      grid-template-columns: 1fr auto;
      > div {
        &:first-child {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        &:last-child {
          ui-button {
            padding-right: 10px;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
    .header-footer-fixed__header__title {
      > div {
        display: grid;

        ui-icon {
          color: $blue-mid;
          font-size: 24px;
        }
        h5 {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
        }
      }
    }
    .header-footer-fixed__header__actions {
      ui-button {
        .ui-icon {
          font-size: 12px;
        }
      }
    }
  }
  .header-footer-fixed__content {
    padding: 40px 30px;
    .floating-project {
      &-chiffre {
        > div {
          border-bottom: solid 1px $gray-lighter;
          padding: 20px 0;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border-bottom: none;
          }
          > div {
            &.offre {
              display: flex;
              flex-direction: column;
              gap: 10px;
            }
            > span {
              &:not(.number) {
                color: $blue-mid;
              }
              &:last-child {
                font-size: 36px;
                font-weight: 300;
                line-height: 48px;
              }
            }
            &.container-chiffre {
              gap: 25px;
              display: flex;
              flex-direction: column;
              div {
                gap: 10px;
                display: flex;
                flex-direction: column;
                > span {
                  &:not(.number) {
                    color: $blue-mid;
                  }
                }
                .chiffres {
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  gap: 5px;
                  ui-icon {
                    font-size: 18px;
                    border-radius: 50%;
                    color: $white;
                    padding: 2px;
                    &.digi-arrow-right-up-line {
                      background: $green-valid;
                    }
                    &.digi-arrow-right-down-line {
                      background: $red-warning;
                    }
                  }
                  span {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 28px;
                  }
                }
              }
            }
          }
          &.avancement-client {
            .title-floating {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 10px;
            }
            > div:not(.title-floating) {
              margin-bottom: 15px;
              > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                > span {
                  &:first-child {
                    font-size: 16px;
                    line-height: 28px;
                  }
                  &:last-child {
                    color: $gray-mid;
                  }
                }
              }
            }
          }
        }
      }
      &-interne {
        > div {
          display: flex;
          gap: 5px;
          padding: 20px 0;
          border-bottom: solid 1px $gray-lighter;
          &:first-child {
            padding-top: 0;
          }
          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
          > div {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .name {
              display: flex;
              flex-direction: column;
              gap: 0;
              span {
                &:first-child {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 28px;
                }
                &:last-child {
                  font-size: 12px;
                  color: $gray-mid;
                }
              }
            }
            .content {
              display: flex;
              justify-content: space-between;
              gap: 40px;
              div {
                display: flex;
                align-items: center;
                gap: 5px;
                > ui-icon {
                  font-size: 18px;
                  color: $blue-mid;
                }
              }
            }
          }
        }
      }
      &-dates {
        > div {
          border-top: solid 1px $gray-lighter;
          border-bottom: solid 1px $gray-lighter;
          padding: 20px 0;
          > .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            padding-bottom: 10px;
          }
          > div {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            > span {
              &:first-child {
                color: $blue-mid;
              }
            }
          }
        }
      }
      &-partenaires,
      &-externe {
        > div {
          border-bottom: solid 1px $gray-lighter;
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          > span {
            &:first-child {
              font-size: 16px;
              font-weight: 400;
              line-height: 28px;
            }
            &.role {
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              color: $gray-mid;
            }
          }
          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            > div {
              display: flex;
              align-items: center;
              gap: 5px;
              .ui-icon {
                font-size: 18px;
                color: $blue-mid;
              }
            }
          }
        }
      }
      &-adresse {
        .img-adresse {
          // background-image: url("https://www.google.com/maps/d/thumbnail?mid=1an_emRFEx-j7IBNh6W2UqpkBJXc&hl=fr");
          // background-repeat: no-repeat;
          // background-position: center;
          // background-size: cover;
          height: 240px;
          width: 100%;
          margin-bottom: 40px;
        }
        > div {
          > span {
            &:first-child {
              color: $blue-mid;
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
            }
          }
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          &.ville {
            display: grid;
            grid-template-columns: auto auto;
            gap: 16px;
            > div {
              display: flex;
              flex-direction: column;
              gap: 5px;
              > span {
                &:first-child {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 15px;
                  color: $blue-mid;
                }
              }
            }
          }
        }
      }
      &-company {
        > div {
          border-top: solid 1px $gray-lighter;
          border-bottom: solid 1px $gray-lighter;
          padding: 20px 0;
          > .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            padding-bottom: 10px;
          }
          > .container {
            &-infos {
              display: flex;
              flex-direction: column;
              gap: 20px;
              > div {
                display: flex;
                flex-direction: column;
                gap: 5px;
                span {
                  &:first-child {
                    color: $blue-mid;
                  }
                }
              }
            }
            &-contact {
              > div {
                border-bottom: solid 1px $gray-lighter;
                padding: 20px 0;
                display: flex;
                flex-direction: column;
                gap: 15px;
                &:last-child {
                  border-bottom: none;
                  padding-bottom: 0;
                }
                > div {
                  display: flex;
                  flex-direction: column;
                  > span {
                    &:first-child {
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 28px;
                    }
                    &.role {
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 21px;
                      color: $gray-mid;
                    }
                  }
                  &:last-child {
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 40px;
                    > div {
                      display: flex;
                      align-items: center;
                      gap: 5px;
                      > ui-icon {
                        color: $blue-mid;
                        font-size: 18px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .header-footer-fixed__footer {
    display: none;
  }
}

@media screen and (min-width: 1500px) {
  ui-content.project-resume {
    .container-project-resume-el {
      gap: 30px;
      //grid-template-columns: auto 400px;
      > div {
        .resume {
          &-part1 {
            padding: 20px 36px !important;
            grid-template-columns: 100px auto 150px;
            .resume-el {
              &-img {
                img {
                  width: 120px;
                  height: 120px;
                }
              }
              &-desc {
                span {
                  &:first-child {
                    font-size: 30px;
                  }
                }
              }
            }
          }
          &-part2 {
            &-graph {
              > div {
                padding: 30px 36px;
                &:first-child {
                  > div:not(.part2-graph) {
                    span {
                      font-size: 20px;
                    }
                  }
                  .part2-graph {
                    .graph-footer {
                      margin-top: 25px;
                    }
                  }
                }
                &:last-child {
                  padding: 20px 30px;
                }
              }
            }
            &-detail {
              > div {
                padding: 30px;
                > div:first-child {
                  > ui-icon {
                    font-size: 24px;
                  }
                  > span {
                    font-size: 20px;
                  }
                }
              }
            }
          }
          &-part3 {
            > div {
              padding: 30px;
              > div:first-child {
                ui-icon {
                  font-size: 24px;
                }
                span {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
      .resume-aside {
        > div {
          padding: 20px;
        }
      }
    }
  }
}
