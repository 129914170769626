@import "../css/scss/variables";

merlin-address-detail {
  .address-info {
    padding-left: 0;
    .addresses {
      margin-bottom: 40px;
      .address {
        border-bottom: 1px solid $gray-very-light !important;
        padding: 15px 0;
        > div > .row > .col {
          padding: 0;
        }
        .address-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          > ui-button {
            background-color: $purple-very-light;
            border-radius: 60px;
            width: 28px;
            height: 28px;
            border: none !important;
            &:hover {
              background-color: $purple-very-light !important;
            }
            .ui-btn__icon {
              padding: 0;
              color: $blue-mid;
            }
          }
        }
      }
    }
  }
  .address-map {
    height: 500px;
  }
}
