import { FilterQueryOp } from 'breeze-client';
import { Predicate } from 'breeze-client';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Language, EnumerationTypeService, Various, IMenuGroup, TranslatedPropertyRules, EnumerationType, TranslatedProperty, Config } from 'digiwall-lib';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { autoinject, BindingEngine, computedFrom, Container, Disposable } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';


@autoinject
export class UnitDetail extends EntityDetailViewModelBase<Merlin.Web.Model.Unit> {
  public ressourceName: string = Constants.EntityTypeNames.Unit;
  private unitTypeService: EnumerationTypeService;
  private convertionsService: ServiceBase<Merlin.Web.Model.UnitConvertion>;
  private unitService: ServiceBase<Merlin.Web.Model.Unit>;
  private unitConvertionDisposables: Array<Disposable>;
  private unitAliasService: ServiceBase<Merlin.Web.Model.UnitAlias>;
  private menuItems: Array<IMenuGroup>;
  allUnitName: Merlin.Web.Model.Unit[];
  allAlias: Merlin.Web.Model.UnitAlias[];
  private languages: Language[];

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, public config: Config) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit));
    this.unitTypeService = new EnumerationTypeService(Constants.EnumerationTypes.UnitType);
    this.unitService = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    this.convertionsService = new ServiceBase<Merlin.Web.Model.UnitConvertion>(Constants.EntityTypeNames.UnitConvertion);
    this.unitAliasService = new ServiceBase<Merlin.Web.Model.UnitAlias>(Constants.EntityTypeNames.UnitAlias);
    this.unitConvertionDisposables = new Array<Disposable>();
  }

  @computedFrom('editingMode', 'entity.unitName._translation')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("unit.unit");
    }
    else {
      return this.entity.unitName._translation;
    }
  }

  public async activate(params: any) {
    let id: number = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ unitTypeId: null });
      if (params.callback != null) params.callback(this.entity);
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'unitType', 'unitConvertionsFrom.toUnit', 'unitConvertionsTo.fromUnit', 'unitAliases');
      this.controller.addObject(this.entity);
      this.unitService.gridDataSource.queryParameters = { unitTypeId: this.entity.unitTypeId, unitId: this.entity.id, needConversion: true }
    }
    this.setMenuItems();
    this.languages = this.config.globalConfig.configuredLanguages || [];

    this.anyRequiredAndUnique('unitName')
      .withMessage('unit.unitNameRequired')
      .on(this.entity);
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      },
    ];
  }
  public getMenuItems(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.removeConvertion(params);
            }
          }
        ]
      }
    ];
  }

  //#region convertions
  public async removeConvertion(convertion: Merlin.Web.Model.UnitConvertion) {
    await this.convertionsService.deleteEntities([convertion], true);
  }

  public async addConvertion() {
    let newConvertion = await this.convertionsService.createEntity();
    newConvertion.convertionValue = 1;
    newConvertion.fromUnitId = this.entity.id;
  }
  //#endregion

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.entity, "unitTypeId").subscribe(async (newValue, oldValue) => {
        this.unitService.gridDataSource.queryParameters = { unitTypeId: newValue, unitId: this.entity.id }
        if (this.entity.unitConvertionsFrom.length + this.entity.unitConvertionsTo.length > 0) {
          await this.convertionsService.deleteEntities(this.entity.unitConvertionsFrom.concat(this.entity.unitConvertionsTo), true);
          if (this.entity.unitConvertionsFrom.length + this.entity.unitConvertionsTo.length == 0) {
            this.disposeUnitConvertionDisposables();
          }
        }
      })
    );

    this.unitConvertionDisposables.push(
      this.bindingEngine.collectionObserver(this.entity.unitConvertionsFrom).subscribe(async (changeRecords) => {
        if (changeRecords.length > 0) {
          this.disposables.push(
            this.bindingEngine.propertyObserver(this.entity.unitConvertionsFrom[changeRecords[0].index], "toUnitId").subscribe(async (newValue, oldValue) => {
              super.save(true);
            })
          )
        }
      })
    );

    this.entity.unitConvertionsFrom.forEach(x =>
      this.unitConvertionDisposables.push(
        this.bindingEngine.propertyObserver(x, "toUnitId").subscribe(async (newValue, oldValue) => {
          super.save(true);
        })
      )
    )

    this.entity.unitConvertionsTo.forEach(x =>
      this.unitConvertionDisposables.push(
        this.bindingEngine.propertyObserver(x, "fromUnitId").subscribe(async (newValue, oldValue) => {
          super.save(true);
        })
      )
    )
  }

  public detached() {
    this.disposeUnitConvertionDisposables();
    super.detached();
  }

  public disposeUnitConvertionDisposables() {
    if (this.unitConvertionDisposables && this.unitConvertionDisposables.length > 0) {
      this.unitConvertionDisposables.forEach((unitConvertionDisposable: Disposable) => {
        unitConvertionDisposable.dispose();
      });
    }
  }

  public async save(silentSave?: boolean, byPassLocking?: boolean, navigateBack?: boolean): Promise<any> {
    this.allUnitName = await this.service.getAllEntities();
    this.allAlias = await this.unitAliasService.getAllEntities();

    this.entity.unitAliases.forEach(alias => {
      this.controller.removeObject(alias);
    });

    this.entity.unitAliases.forEach(alias => {
      let temp = ValidationRules
        .ensure((u: Merlin.Web.Model.UnitAlias) => u.alias)
        .required()
        .satisfies((val, obj) => {
          let allUnitCount = 0
          let result = this.entity.unitAliases.filter(x => x != alias && x.alias == val);
          let allAlias = this.allAlias.filter(x => x != alias && x.alias == val);
          this.languages.forEach(language => {
            let tempLang = 'lang' + (language.columnIndex);
            allUnitCount += this.allUnitName.filter(x => x.id != this.entity.id && x.unitName[tempLang]?.toLowerCase() == val.toLowerCase()).length;
            if (allUnitCount > 0) {
              return false;
            }
          });
          return val.length > 0 && result.length == 0 && allAlias.length == 0 && allUnitCount == 0;
        })
        .withMessage(this.i18n.tr("unit.aliasUnique"))
        .on(alias).rules;
      this.controller.addObject(alias, temp);
    });
    await super.save()
  }

  public anyRequiredAndUnique(key: string) {
    return ValidationRules.ensure(key)
      .satisfies((value: TranslatedProperty) =>
        this.languages.reduce((valid: boolean, language: Language) => {
          let tempLang = 'lang' + (language.columnIndex);
          let count = this.allUnitName.filter(x => x.id != this.entity.id && x.unitName[tempLang]?.toLowerCase() == value[tempLang]?.toLowerCase()).length;
          count += this.allAlias.filter(x => x.unitId != this.entity.id && x.alias?.toLowerCase() == value[tempLang]?.toLowerCase()).length;
          return valid || (value[tempLang] && (value[tempLang] as string).length > 0 && count == 0)
        },
          false)
      );
  }
}


