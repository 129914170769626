import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, User, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class EmailTemplateMerlinList extends ListViewModelBase<Merlin.Web.Model.EmailTemplateMerlin> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.EmailTemplateMerlin>(Constants.EntityTypeNames.EmailTemplateMerlin));
    this.service.gridDataSource.expands = ['emailTemplate.language', 'emailType.type'];
  }

  public ressourceName: string = Constants.EntityTypeNames.EmailTemplateMerlin;
  public buttonLabel: string = this.i18n.tr("emailtemplatemerlin.addEmailTemplate");
  public pathDetail: string;

  public getDetailsUrl(self, entity: Merlin.Web.Model.EmailTemplateMerlin) {
    if (entity?.id) {
      return this.router.baseUrl + this.pathDetail + entity.id;
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = '/email-templates/';
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("emailtemplatemerlin.title"),
        field: "emailTemplate.emailTitle",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      // {
      //   headerName: this.i18n.tr("emailtemplatemerlin.contentHTML"),
      //   field: "emailTemplate.content",
      //   type: FieldType.Html,
      //   showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      // },
      {
        headerName: this.i18n.tr("emailtemplatemerlin.languageId"),
        field: "emailTemplate.language.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.Language,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.Language,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("emailtemplatemerlin.senderEmail"),
        field: "emailTemplate.fromEmailAddress",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("emailtemplatemerlin.senderName"),
        field: "emailTemplate.fromName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("emailtemplatemerlin.copyConformEmails"),
        field: "emailTemplate.ccEmailAddress",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("emailtemplatemerlin.hiddenCopyConformEmails"),
        field: "emailTemplate.bccEmailAddress",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("emailtemplatemerlin.emailTypeId"),
        field: "emailType.type.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.EmailType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.EmailType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
} 
