import { ICellEditorComp, ICellEditorParams } from "ag-grid-community";

export class ProposedQuantityEditor implements ICellEditorComp {
  eGui: HTMLInputElement;
  private fractionDigits: number = 2;
  private params: ICellEditorParams;
  getValue() {
    if (this.eGui.value == null || this.eGui.value.length == 0) return null;
    return this.eGui.value;
  }
  getGui() {
    return this.eGui;
  }
  afterGuiAttached() {
    this.eGui.focus();
  }
  init?(params: import("ag-grid-community").ICellEditorParams | any) {
    this.eGui = document.createElement('input');
    this.params = params;
    if (params.key == "Delete" || (params.charPress != null && !this.isEnterEditKey(params.charPress))) {
      this.eGui.value = params.charPress;
    } else {
      if (params.value !== undefined && params.value !== null) {
        this.eGui.value = params.value;
      }
    }
    this.eGui.addEventListener('keypress', (event) => {
      if (this.isEnterEditKey(event.key)) {
        this.eGui.focus();
        if (event.preventDefault) event.preventDefault();
      } else if (this.isKeyPressedNavigation(event)) {
        event.stopPropagation();
      }
    });

    if (params.fractionDigits != null) {
      this.fractionDigits = params.fractionDigits;
    }
  }
  isEnterEditKey(charStr: string) {
    return charStr?.toLowerCase() == "f2";
  }

  isKeyPressedNavigation(event) {
    return event.keyCode === 39
      || event.keyCode === 37;
  }
}
