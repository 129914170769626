import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, IAnchorTab, IMenuGroup, TranslatedProperty, ActionDialogBoxInputParameters, DialogBoxViewModel, GlobalLoaderService, EnumerationTypeService, EnumerationType } from 'digiwall-lib';
import * as Constants from '../../constants';
import { Merlin } from 'generated';
import { autoinject, computedFrom, observable } from 'aurelia-framework';
import moment from 'moment';
import { CommomMetering } from './common-metering';
import { WorkQuotationVersionApiService } from 'services/work-quotation-version-api-service';
import { FilterQueryOp, Predicate } from 'breeze-client';
import * as toastr from 'toastr';
import { StartProject } from 'utils/start-project';
import { PriceOfferLineCommentApiService } from 'services/price-offer-line-comment-api-service';
import { PriceRepartitionService } from 'services/price-repartition-service';
import { VersionMenuItems } from './version-menu-items';

@autoinject
export class VersionSummary extends CommomMetering<Merlin.Web.Model.WorkQuotationVersion> {
  public ressourceName: string = Constants.EntityTypeNames.WorkQuotationVersion;
  tabList: IAnchorTab[];
  nbDaysRemaining: any;
  menuItems: Array<IMenuGroup>;

  summary: WorkQuotationVersionSummary;
  activityCodesCompleteness: Array<ActivityCodeCompleteness>;
  activityCodesCompletenessDisplay: Array<ActivityCodeCompleteness>;
  loadingActivityCode: boolean;

  priceOfferStatusService: EnumerationTypeService;
  priceOfferStatus: EnumerationType

  buyingPriceCompleteness: Array<BuyingPriceCompleteness>;
  loadingBuyingPrice: boolean;
  @observable
  vueActivityCodeIncomplete: boolean = false;
  workQuotationVersionService: ServiceBase<Merlin.Web.Model.WorkQuotationVersion>;
  projectId: any;

  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number
  public projectService: ServiceBase<Merlin.Web.Model.Project>

  constructor(public router: Router, logger: CustomLogger, httpClient: HttpClient, priceOfferVersionApiService: WorkQuotationVersionApiService, globalLoaderService: GlobalLoaderService, priceOfferLineCommentApiService: PriceOfferLineCommentApiService, priceRepartitionService: PriceRepartitionService) {
    super(router, logger, httpClient, priceOfferVersionApiService, globalLoaderService, priceOfferLineCommentApiService, priceRepartitionService);
    super.initialize(new ServiceBase<Merlin.Web.Model.WorkQuotationVersion>(Constants.EntityTypeNames.WorkQuotationVersion));
    this.priceOfferStatusService = new EnumerationTypeService(Constants.EnumerationTypes.PriceOfferStatus);
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  public documentTitle = this.i18n.tr("workquotationversion.workquotationversion");

  public async activate(params: any) {
    await super.activate(params);
    this.projectId = params.projectId;
    let project = await this.projectService.getEntityById(this.projectId);
    if (project != null) {
      this.nbDecimalForPriceDisplay = project.nbDecimalForPriceDisplay;
      this.nbDecimalForQuantityDisplay = project.nbDecimalForQuantityDisplay;
    }

    if (params.workQuotationVersionId == null || params.workQuotationVersionId.length == 0) {
      let predicateProject = new Predicate("projectId", FilterQueryOp.Equals, this.projectId);
      this.entity = await this.service.firstEntity(predicateProject);
    } else {
      this.entity = await this.service.getEntityById(params.workQuotationVersionId, 'project', 'priceOfferVersions');
    }

    let uri = Constants.Application.WorkQuotationController.Summary.format(this.entity.id.toString());
    let result = await this.httpClient.get(uri);
    if (result.ok) {
      this.summary = await result.json();
    }
    if (this.summary.statusId != null) {
      this.priceOfferStatus = await this.priceOfferStatusService.getEntityById(this.summary.statusId);
    }
    this.getActivityCodes(params.workQuotationVersionId);
    this.getBuyingPrice(params.workQuotationVersionId)
    this.setTabList();
    this.setMenuItems();
  }

  private async getActivityCodes(workQuotationVersionId) {
    if (workQuotationVersionId != null) {
      this.loadingActivityCode = true;
      let uriActivityCodesCompleteness = Constants.Application.WorkQuotationController.ActivityCodesCompleteness.format(workQuotationVersionId);
      let resultActivityCode = await this.httpClient.get(uriActivityCodesCompleteness);
      if (resultActivityCode.ok) {
        this.activityCodesCompleteness = await resultActivityCode.json();
        this.switchVueActivtityCodeIncomplete();
      }
      this.loadingActivityCode = false;
    }
  }

  private async getBuyingPrice(workQuotationVersionId) {
    if (workQuotationVersionId != null) {
      this.loadingBuyingPrice = true;
      let uriBuyingPriceCompleteness = Constants.Application.WorkQuotationController.BuyingPriceCompleteness.format(workQuotationVersionId);
      let resulBuyingPrice = await this.httpClient.get(uriBuyingPriceCompleteness);
      if (resulBuyingPrice.ok) {
        this.buyingPriceCompleteness = await resulBuyingPrice.json();
      }
      this.loadingBuyingPrice = false;
    }
  }


  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: "tab1",
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      },
      {
        id: "tab2",
        name: this.i18n.tr("groupTabPanel.completeness"),
        isVisible: true
      },
      {
        id: "tab3",
        name: this.i18n.tr("groupTabPanel.compositionOfCostPrice"),
        isVisible: true
      }
    );
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: VersionMenuItems.getMenuItems(this.entity.projectId, this.entity.id, this.entity, this.entity.isCurrentVersion)
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("project.startProject"),
            icon: "digi-play-circle-line",
            handler: async () => {
              await StartProject.startProject(this.entity.projectId);
            },
            disabled: !this.entity.priceOfferVersions.some(x => x.priceOfferStatusId == Constants.PriceOfferStatusId.Accepted) || this.entity.project.projectStarted
          },
        ]
      }
    ];
  }
  @computedFrom("entity", "entity.fullName")
  get ribbonHeaderText(): string {
    return this.entity?.quotationVersionName;
  }



  vueActivityCodeIncompleteChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      this.switchVueActivtityCodeIncomplete();
    }
  }

  changeVueActivtityCodeIncomplete() {
    this.vueActivityCodeIncomplete = !this.vueActivityCodeIncomplete;
  }

  private switchVueActivtityCodeIncomplete() {
    if (this.activityCodesCompleteness != null) {
      if (this.activityCodesCompletenessDisplay == null) {
        this.activityCodesCompletenessDisplay = [];
      }
      this.activityCodesCompletenessDisplay.splice(0);
      if (this.vueActivityCodeIncomplete) {
        this.activityCodesCompletenessDisplay.push(...this.activityCodesCompleteness);
      } else {
        let temp = this.activityCodesCompleteness.filter(x => x.total != x.totalWithBuyingUnitPrice);
        this.activityCodesCompletenessDisplay.push(...temp);
      }
    }
  }
}


export interface WorkQuotationVersionSummary {
  nbPriceOfferLineDataWithPrice: number;
  nbTotalPriceOfferLineData: number;

  createdTime: Date | string;
  nbDaysRemaining: number;

  totalBuyingPriceExcludingVAT: number;
  ownWork: number;
  materials: number;
  subContractors: number;


  totalSellingPriceExcludingVAT: number | null;
  statusId: number | null;
  acceptedVersionName: string;
}

export interface ActivityCodeCompleteness {
  activityCodeId: number;
  workQuotationVersionId: number;
  activityCode: TranslatedProperty;
  totalWithBuyingUnitPrice: number;
  total: number;
  totalInternalWithBuyingUnitPrice: number;
  totalInternal: number;
  totalExtenalWithBuyingUnitPrice: number;
  totalExtenal: number;
  requestOfferBuyingUnitPrice: number;
  supplierOfferBuyingUnitPrice: number;
}

export interface BuyingPriceCompleteness {
  activityCodeId: number;
  workQuotationVersionId: number;
  activityCode: TranslatedProperty;
  total: number;
  totalOffer: number;
  totalManual: number;
  totalItem: number;
}

