import { RefToDisplay } from '../constants';
import { GridOptions } from 'ag-grid-community';
import { JSONPatchOperationType } from './json-patch-operation-type.enum';
import * as Constants from '../constants';
import { IListTreeDataGridService } from 'module-list-tree-data/service/module-list-tree-data-service';

export interface IPriceOfferLineGridService extends IListTreeDataGridService {
  createFromItems(versionId: number, selectedItems: import("../projects/quotes/add-items/add-items").PriceOfferLineFromItem[]): Promise<Array<number>> | Error;
  children(id: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>>;
  filter(id: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>>;
  select(lineId: number, filterModel?: { [key: string]: any; });
  unselect(lineId: number, filterModel?: { [key: string]: any; });
  fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>>;
}

export interface BulkUpdateOperation {
  lineId: number;
  propertyName: string;
  propertyValue: any;
}
export interface IDataLineApiService extends IPriceOfferLineGridService {
  children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>>;
  filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>>;
  patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>>;
  bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>>;
  duplicateLine?(versionId: number, lineId: number): Promise<Array<number>>;
  create(versionId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<Array<number>>;
  move(versionId: number, lineIds: Array<number>, targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<Array<number>>;
  paste(versionId: number, lineIds: Array<number>, targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<Array<number>>;
  subscribe(callback: () => void);
  delete(versionId: number, lineIds: Array<number>): Promise<Array<number>>;
  getLastChapterId(versionId: number): Promise<number>

  computePAR?(versionId: number): Promise<boolean>;
  deletePAR?(versionId: number, lineId: number): Promise<boolean>;

  versionHasLines?(versionId: number): Promise<boolean>;

  getMeteringTotalPrices?(versionId: number): Promise<MeteringTotalPrices>;
  createVariantGroup?(versionId: number, lineIds: number[]): Promise<Array<number>>;
  deleteVariantGroup?(versionId: number, lineId: number): Promise<Array<number>>;
  recomputeLines(versionId: number): Promise<MeteringTotalPrices>;
}

export interface ImportSessionResponse {
  currentImportSessionId: number | null;
  isCompleted: boolean | null;
}
export interface MeteringTotalPrices {
  totalSelling: number;
  totalBuying: number;
  percentageMargin: number;
  margin: number;
}
export interface ProgressStatementTotal {
  totalDeltaSelling: number;
  totalDoneSelling: number;
}




export class RequestMovePriceOfferLine {
  public action: Constants.PriceOfferLineMoveAction;
  public targetId: number;
  public priceOfferLineIds: Array<number>;
}

export class RequestCreatePriceOfferLine {
  public action: Constants.PriceOfferLineMoveAction;
  public targetId: number;
  public categoryId: number;
  public fK: number;
}

export class RequestFilterPriceOfferLine {
  public filterModel: any;
  public quickFilter: string;
  public displayMode: Constants.TreeDisplayMode;
  public displayHidden: boolean;
  public refToDisplay: RefToDisplay
}

export class RequestFilterComparaisonPriceOfferLine extends RequestFilterPriceOfferLine {
  public supplierIds: Array<number>;
  public requestSupplierOfferId: number;
  public activitiesIds: Array<number>;
  public lineIds: Array<number>;
}
