import { HttpClient } from 'aurelia-fetch-client';
import { DialogService, DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AuthService, CustomLogger, EnumerationTypeService, IFile, PictureHelper, ServiceBase } from "digiwall-lib";
import { Merlin } from "generated";
import * as Constants from '../constants';
import { NotificationLanguageDTO } from "./email-sender";
import * as toastr from 'toastr';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';

@autoinject
export class EmailSenderBox {
  public thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  public entity: Merlin.Web.Model.NotificationSent;
  public notifLang: Map<number, NotificationLanguageDTO> = new Map<number, NotificationLanguageDTO>();
  public isReadonly: boolean = false;
  public files: Array<IFile>;
  public emailContext: number;
  public selectedReceiversIds: Array<number> = [];
  public disableTo: boolean = false;
  public companyIds: Array<number> = [];
  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest;
  public projectId: number;
  type: DocumentTypeDiscriminator = DocumentTypeDiscriminator.WorkQuotationVersion;

  constructor(private dialogController: DialogController, private httpClient: HttpClient, private i18n: I18N, private logger: CustomLogger) {
  }

  async activate(params) {
    let languages = await new EnumerationTypeService(Constants.EnumerationTypes.Language).getEntities(null, null, { category: Constants.EnumerationTypes.Language });
    if (params.idsToSend != null) {
      this.selectedReceiversIds = params.idsToSend;
    }

    if (params.type != null) {
      this.type = params.type;
    }

    if (params.emailContext != null) {
      this.emailContext = params.emailContext;
    }
    if (params.entity != null) {
      languages.forEach(language => {
        this.notifLang.set(language.id, {
          to: params.entity.to,
          from: params.entity.from,
          fromName: params.entity.fromName,
          cc: params.entity.cc,
          subject: params.entity.subject,
          body: params.entity.body,
          templateId: params.entity.emailTemplateId,
          files: [],
          emailContextId: this.emailContext
        });
      });
      if (params.entity.notificationSentFiles != null && params.entity.notificationSentFiles.length > 0) {
        languages.forEach(language => {
          this.notifLang.get(language.id).files.push(...params.entity.notificationSentFiles.map((file) => {
            return {
              url: file.url,
              name: file.name,
              description: file.description,
              fileTypeId: file.fileTypeId,
              size: file.size,
              isFromTemplate: file.isFromTemplate,
              notificationSentId: file.notificationSentId
            }
          }));
        });
      }
    }
    else {
      languages.forEach(language => {
        this.notifLang.set(language.id, {
          to: null,
          from: null,
          cc: null,
          fromName: null,
          subject: null,
          body: null,
          templateId: null,
          files: [],
          emailContextId: this.emailContext
        });
      });
    }
    if (params.isReadonly != null) {
      this.isReadonly = params.isReadonly;
    }
    if (params.body != null) {
      languages.forEach(language => {
        this.notifLang.get(language.id).body = params.body;
      });
    }
    if (params.subject != null) {
      languages.forEach(language => {
        this.notifLang.get(language.id).subject = params.subject
      });
    }
    if (params.files != null) {
      languages.forEach(language => {
        this.notifLang.get(language.id).files.push(...params.files.map(file => {
          return {
            url: file.url,
            name: file.name,
            description: file.description,
            fileTypeId: file.fileTypeId,
            size: file.size,
            isFromTemplate: false,
            notificationSentId: null
          }
        }))
      });
    }
    if (params.disableTo != null) {
      this.disableTo = params.disableTo;
    }
    if (params.companyIds != null) {
      this.companyIds.push(...params.companyIds);
    }
    if (params.previewRequestParams != null) {
      this.previewRequestParams = params.previewRequestParams;
    }
    if (params.projectId != null) {
      this.projectId = params.projectId;
    }
  }

  private async sendEmail() {
    if (this.selectedReceiversIds.length == 0) {
      this.logger.LogError(this.i18n.tr("requestsupplieroffer.toIsRequired"), null, null, true);
      return false;
    }
    else {
      let response = await this.httpClient.post(Constants.Application.EmailController.CreateEmail, JSON.stringify({ idsToSend: this.selectedReceiversIds, notifsLang: Object.fromEntries(this.notifLang.entries()), previewRequestParams: this.previewRequestParams }));
      if (response.ok) {
        let result = await response.json();
        await this.dialogController.close(true, result);
        this.logger.Log(this.i18n.tr("requestsupplieroffer.mailSended"), null, null, true);
        return true;
      }
      else {
        let error = await response.text();
        this.logger.LogError(this.i18n.tr("requestsupplieroffer.ccError", { mail: error }), null, null, true)
        return false;
      }
    }
  }


  public async close() {
    await this.dialogController.close(false);
  }
}
