@import "../../../css/scss/variables";

ux-dialog {
  > ux-dialog-header {
    > div.dialog-header-content {
      > div.price-repartition-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  > ux-dialog-body {
    > div.line-repartition {
      margin-top: 20px;
      .button-and-error {
        display: flex;
        align-items: center;
        .icon-error {
          color: red;
        }
        > ui-field {
          gap: 5px;
          ui-button {
            height: 2.6em;
            margin: 0;
            width: 2.6em;
          }
        }
      }
    }
  }
  > ux-dialog-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}

ux-dialog.dialog-price-repartition {
  width: 35%;
  > ux-dialog-body {
    padding-top: 0 !important;
    > .row > .col {
      padding: 0;
    }
    .line-repartition {
      display: flex;
      align-items: center;
      .col > ui-field > .ui-field__label {
        display: none;
      }
      .button-and-error {
        ui-field {
          display: flex;
          margin-left: 5px;
          padding: 0;
          ui-button {
            width: 2.6rem;
            height: 2.6rem;
          }
        }
      }
    }
  }
}
