import { I18N } from 'aurelia-i18n';
import { ServiceBase, UITreeNode } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../../../../generated";
import * as Constants from '../../../../constants';
import { DialogController } from 'aurelia-dialog';
import { DataLineDTO } from 'services/price-repartition-service';

@autoinject
export class PriceOfferLineTreeView {
  private title = this.i18n.tr('versionmetering.selectPriceOfferLine');

  private dataLines: Array<DataLineDTO>;
  private priceOfferLineTree: UITreeNode[] = [];
  private selectedTreeValues: string[] = [];

  private workQuotationVersionId: number;
  private priceOfferVersionId: number;
  private oldValueSelectedId: number;
  private customNodesSortFunction: (nodeA: UITreeNode, nodeB: UITreeNode) => number;

  constructor(private dialogController: DialogController, private i18n: I18N) {
    this.customNodesSortFunction = (nodeA: UITreeNode, nodeB: UITreeNode) => {
      let merlinRefA = nodeA.model.fullName.split("-")[0];
      let merlinRefB = nodeA.model.fullName.split("-")[0];
      return merlinRefA.localeCompare(merlinRefB, undefined, { numeric: true, sensitivity: 'base' })
    };
  }

  public async activate(params: any) {
    this.workQuotationVersionId = params.workQuotationVersionId;
    this.priceOfferVersionId = params.priceOfferVersionId;
    this.dataLines = params.dataLines

    this.oldValueSelectedId = params.oldValueSelectedId;
    await this.refreshTreeValues();
    this.refreshSelectedTreeValues();
  }

  public async refreshTreeValues() {
    this.priceOfferLineTree = this.toTreeNodes(this.dataLines.filter(x => x.parentId == null));
  }

  private toTreeNodes(priceOfferLines: DataLineDTO[]): UITreeNode[] {
    let priceOfferLineTree: UITreeNode[] = [];
    priceOfferLines
      .forEach(priceOfferLine => {
        let children: Array<UITreeNode> = this.toTreeNodes(this.dataLines.filter(x => x.parentId == priceOfferLine.id));
        let articleFamilyTreeItem: UITreeNode = new UITreeNode({
          id: priceOfferLine.id.toString(),
          label: priceOfferLine.fullName,
          expanded: false,
          leaf: (children?.length == 0),
          children: children,
          model: priceOfferLine
        });
        priceOfferLineTree.push(articleFamilyTreeItem);
      });
    return priceOfferLineTree;
  }


  public refreshSelectedTreeValues(): void {
    if (this.oldValueSelectedId != null && this.oldValueSelectedId.toString().trim() != '') {
      this.selectedTreeValues.push(this.oldValueSelectedId.toString());
    }
  }

  public async ok() {
    let objectToReturn = [];
    if (this.selectedTreeValues.length != 0) {
      this.selectedTreeValues.forEach(value => {
        objectToReturn.push({ id: value, text: this.dataLines.find(x => x.id == parseInt(value)).fullName })
      });
    }

    this.dialogController.ok(objectToReturn);
  }

  public async close() {
    await this.dialogController.close(true);
  }
} 
