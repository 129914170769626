import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, FileFlow, EnumerationTypeService, Various, IMenuGroup } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';

@autoinject
export class CheckPointTemplateDetail extends EntityDetailViewModelBase<Merlin.Web.Model.CheckPointTemplate> {
  public Constants = Constants;
  public ressourceName: string = Constants.EntityTypeNames.CheckPointTemplate;
  private menuItems: Array<IMenuGroup>;

  private projectService: ServiceBase<Merlin.Web.Model.Project>;
  private activityCodeService: EnumerationTypeService;
  private checkPointProjectStageService: EnumerationTypeService;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private httpClient: HttpClient) {
    super(router, logger);

    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
    this.activityCodeService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    this.checkPointProjectStageService = new EnumerationTypeService(Constants.EnumerationTypes.CheckpointProjectStage);

    super.initialize(new ServiceBase<Merlin.Web.Model.CheckPointTemplate>(Constants.EntityTypeNames.CheckPointTemplate));
  }

  public async activate(params) {
    let id = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ projectStageId: null });
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'project', 'activityCode');

      this.controller.addObject(this.entity);
    }

    this.setMenuItems();
  }
  @computedFrom('editingMode', 'entity.checkpointName')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("checkpointtemplate.addCheckpointTemplate");
    }
    else {
      return this.entity.checkpointName._translation;
    }
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.save"),
            icon: "digi-save-3-line",
            handler: () => {
              this.save();
            },
            disabled: () => !this.canSave
          },
          {
            label: this.i18n.tr("menu.cancel"),
            icon: "digi-close-circle-line",
            handler: () => {
              this.cancel();
            }
          },
          {
            label: this.i18n.tr("menu.back"),
            icon: "digi-arrow-go-back-line",
            handler: () => {
              this.navigateBack();
            }
          }
        ]
      }
    ];
  }
}
