@import "../../css/scss/variables";

.theme-light {
  ui-page div.metering-footer {
    color: $black-100;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 53px;
    > div.metering-footer-right {
      display: flex;
      > div {
        padding: 0px 0px 0px 53px;
      }
    }
    .metering-footer-title,
    > div > .metering-footer-title {
      > .metering-footer-title-total-price {
        font-size: 12px;
        line-height: 15px;
        color: $blue-mid;
      }
      > .metering-footer-title-total {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .metering-footer-total,
    > div > .metering-footer-total {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
