.theme-light {
  label {
    white-space: normal;
    height: fit-content !important;
    position: inherit;
    padding: 0 0.25rem;
    text-align: left;
    color: $blue-mid;
    text-overflow: inherit;
    white-space: normal;
  }
  ui-select > .select2 > .selection {
    .select2-selection {
      .select2-selection__rendered {
        font-size: 14px;
      }
    }
  }
  ui-field.ui-field {
    &.ui-field--invalid {
      ui-field-wrapper {
        margin-bottom: 20px;
      }
    }
    > label {
      white-space: normal;
      height: fit-content !important;
      position: inherit;
      padding: 0 0.25rem;
      padding-bottom: 5px;
      text-align: left;
      &,
      span {
        color: $blue-mid;
        text-overflow: inherit;
        white-space: normal;
        &:first-child {
          overflow: initial;
        }
        &:last-child {
          color: $blue-mid;
        }
      }
    }
    ui-input {
      height: 2.6rem;
      padding: 0 10px;
      > input {
        height: 100%;
        padding: 0;
        font-size: 14px;
        &::placeholder {
          font-family: "Inter", sans-serif;
          color: $blue-darkest;
          font-size: 14px;
        }
      }
    }
    ui-field-wrapper > ui-input {
      height: 2.6rem !important;
    }
    > ui-field-wrapper > ui-input,
    ui-date-picker > ui-field-wrapper > ui-input,
    ui-range-picker > ui-field-wrapper > ui-input {
      height: initial;
    }
    > .ui-field-with-search-container > ui-select.ui-select--multiple {
      height: 2.6rem;
    }
    ui-select {
      padding: 0 10px;
      height: 2.6rem;
      > .select2 > .selection {
        display: grid;
        align-items: center;
        height: 100%;
        .select2-selection {
          min-height: 2.5rem;
          .select2-selection__rendered {
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 1px;
            font-size: 14px;
            > .select2-selection__choice {
              padding: 5px 10px;
              height: 2.1rem;
              //margin: 9px 0;
            }
          }
          .select2-selection__arrow {
            margin-top: -4px;
            > b {
              width: 8px;
              height: 8px;
              transform: rotate(45deg);
              border: none;
              border-bottom: solid 2px $blue-darkest !important;
              border-right: solid 2px $blue-darkest !important;
            }
          }
          &.select2-selection--multiple {
            align-items: center !important;
            .select2-search--inline {
              display: none;
            }
            &.select2-selection--clearable {
              > ui-button {
                height: 100%;
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .select2-container,
      .select2-container--default {
        border: none !important;
        min-height: 2.6rem;
        height: fit-content;
        > span.select2-selection--single {
          .select2-selection__rendered,
          .select2-selection__clear {
            min-height: 2.6rem;
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
        .selection {
          min-height: 2.5rem;
          overflow: hidden;
          align-items: center;
          .select2-selection--single {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: unset;
            .select2-selection__rendered {
              width: 90%;
            }
            &.select2-selection--clearable {
              flex-direction: row-reverse;
              .select2-selection__rendered {
                width: 100%;
              }
            }
          }
        }
        span.select2-selection--multiple,
        .selection > .select2-selection--multiple.select2-selection--clearable {
          align-items: start;
          overflow: hidden;
          height: inherit;
          min-height: 40px;
          .select2-selection__clear {
            margin-top: 0 !important;
            height: 2.6rem;
          }
          .select2-selection__rendered {
            gap: 5px !important;
            padding-top: 0;
          }

          .select2-selection__choice {
            //background-color: $purple-very-light !important;
            //color: $blue-darkest;
            border-radius: 6px !important;
          }
          .select2-selection__choice__remove {
            background-color: transparent !important;
            > span {
              //color: $blue-darkest;
            }
          }
        }
      }
    }
    ui-select.ui-select--multiple > .select2-container > .selection,
    ui-select.ui-select--multiple > .select2-container--default > .selection {
      align-items: start;
    }
    ui-select.ui-select.ui-input--valid {
      padding: 0 10px;
    }

    > ui-enumeration-select {
      ui-select {
        //margin-top: 9px;
        .select2 > .selection > .select2-selection {
          height: auto;
        }
      }
    }

    &.textarea-multilangue > ui-field-wrapper {
      display: grid;
      > ui-textarea {
        border-right: none;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        > .ui-input__clear {
          position: initial;
          padding-top: 10px;
          place-content: baseline;
          margin-left: 20px;
        }
        > textarea {
          overflow: auto;
          min-height: 10rem;
        }
      }
      > ui-dropdown {
        margin: 5px;
        position: absolute;
        bottom: 0;
        right: 0;
        > .ui-dropdown__link {
          border: solid 1px #d3d3d3;
          border-radius: 5px;
          > .ui-dropdown__label {
            padding: 5px 3px;
          }
        }
      }
    }
    .ui-field__wrapper > ui-textarea {
      padding: 0px 10px;
      //height: 2.4rem;
      > textarea {
        padding-right: 0 !important;
        height: 100% !important;
      }
    }
    ui-textarea {
      &.ui-input--textarea {
        > .ui-input__clear {
          position: initial;
          padding-top: 10px;
          place-content: baseline;
          margin-left: 15px;
        }
        > textarea {
          min-height: 10rem;
        }
      }
    }
    .ui-tag {
      width: fit-content;
    }
  }
  ui-field.ui-field--disabled {
    label span {
      color: $gray-light !important;
      &:last-child {
        color: $gray-light !important;
      }
    }
    ui-enumeration-select {
      ui-select.ui-select {
        border: none !important;
        .select2 {
          margin-top: 12px;
        }
      }
    }
  }
  ui-field.ui-field--readonly {
    .ui-input--readonly {
      background-color: transparent;
      border-bottom: none;
      padding-left: 0.25rem;
    }
  }
  ui-input,
  ui-select,
  ui-field-wrapper,
  .ui-input,
  .ui-select,
  .ui-field__wrapper {
    box-shadow: none;
    border-color: $gray-lighter;
    &:hover {
      border-color: $gray-mid;
      box-shadow: none;
    }
    &:focus-within,
    &:focus {
      border-color: $blue-mid;
      box-shadow: none;
    }
    &__clear {
      padding: 0;
      color: inherit;
      margin-left: 10px;
      .ui-icon {
        font-size: 10px;
      }
    }
    .select2 .selection .select2-selection--clearable ui-button.select2-selection__clear {
      .digi-cross {
        font-size: 10px;
      }
    }
  }
  ui-input,
  ui-field-wrapper,
  .ui-input,
  .ui-field__wrapper {
    grid-column: inherit;
  }
  .ui-input {
    padding: 0px 10px;
  }
}

ui-field-wrapper {
  // margin-bottom: 20px;
  //height: 2.6rem;
  > .ui-input--invalid {
    margin-bottom: 0 !important;
    > .ui-input__error > ul {
      position: absolute;
      bottom: -15px;
    }
  }
  > .ui-select .select2-container.select2-container--default {
    min-height: 2.5rem;
  }
}

ui-field > ui-field-wrapper {
  ui-select {
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }
}

.theme-light .select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: $purple-very-light !important;
  color: $blue-darkest !important;
  border-radius: 6px !important;
  padding: 5px 10px;
  height: 2.1rem;
  > .select2-selection__choice__remove {
    //background-color: transparent !important;
    border: none !important;
    .ui-btn__inner {
      &:hover {
        color: $black-100 !important;
      }
      ui-icon.digi-cross {
        font-size: 10px !important;
      }
    }
  }
}

.ui-select .select2-container span.select2-selection--multiple .select2-selection__rendered {
  padding-top: 3.5px;
}

.ui-input textarea {
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

ui-field[no-label].ui-field {
  grid-template-columns: auto;
}

.theme-light .ui-input input {
  height: 2.6rem;
}

.theme-light ui-field.label-top {
  display: block;
}

.theme-light ui-field.ui-field ui-select.ui-select--multiple {
  height: fit-content !important;
}

.theme-light ui-field.ui-field ui-select.ui-select--multiple .select2-container .selection,
.theme-light ui-field.ui-field ui-select.ui-select--multiple .select2-container--default .selection {
  height: fit-content;
  .select2-selection--multiple > .select2-selection__rendered {
    padding: 5px 0;
  }
}

.select2-container {
  border: none !important;
  .select2-dropdown {
    .select2-search--dropdown {
      margin: 10px;
      .select2-search__field {
        border-radius: 50px;
        border: transparent;
        &:focus {
          border: none !important;
          box-shadow: none !important;
        }
        &:focus-visible {
          outline: none !important;
        }
      }
    }
    .select2-results {
      margin: 10px;
    }
  }
}

.select2-container--open > .select2-dropdown > .select2-search--dropdown > .select2-search__field {
  border: none !important;
  &:focus-visible {
    border: none !important;
  }
}

.select2-container--open > .select2-dropdown {
  border-radius: $radius;
  .select2-results > .select2-results__options > .select2-results__option {
    font-size: 14px;
    &--highlighted,
    &--selected {
      background-color: $purple-lightest !important;
      color: $blue-mid !important;
    }
  }
}

.theme-light .select2-container .select2-selection--single .select2-selection__arrow,
.theme-light .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
  margin-top: -4px;
  > b {
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    border: none;
    border-bottom: solid 2px $blue-darkest;
    border-right: solid 2px $blue-darkest;
  }
}

.theme-light {
  .ui-input--disabled,
  .ui-select.ui-input--disabled {
    border: solid 1px $gray-lighter !important;
    background-color: transparent;
    > * {
      color: $gray-light !important;
    }
    .select2-container.select2-container--disabled .select2-selection--single,
    .select2-container.select2-container--disabled .selection .select2-selection {
      background-color: transparent;
      .select2-selection__rendered {
        background-color: transparent;
      }
    }
  }
  .ui-input--invalid {
    background-color: transparent !important;
    border: solid 1px $red-warning;
    margin-bottom: 20px;
    .ui-input__error {
      position: absolute;
      left: -10px;
      bottom: -10px;
      > svg,
      > ui-icon {
        display: none;
      }
      > ul {
        display: block;
        background-color: transparent;
        margin: 0;
        padding: 0;
        color: $red-warning;
      }
    }
  }
}

.ui-field-with-search-container {
  display: grid !important;
  grid-template-areas: "select icon";
  grid-template-columns: auto 10% !important;
  > ui-select {
    height: 2.6rem;
  }
}

* {
  &:focus-visible {
    outline: none !important;
    border: none !important;
  }
}

.col > ui-checkbox {
  height: 38px;
  display: flex;
  align-items: center;

  .ui-option__control {
    gap: 10px;
  }
}

.button-margin {
  position: absolute;
  bottom: 10px;
}

ui-input-phone {
  ui-select.ui-select {
    width: 80px;

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
      margin-top: -1px;
    }
  }

  // Center selection flags
  .select2-container .select2-selection,
  .select2-container .select2-selection__rendered {
    display: flex !important;
    align-items: center;
    height: 100% !important;
  }

  .ui-input__addon .ui-icon {
    margin-left: 0.24rem;

    &.digi-cross {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }
}

ui-input-phone {
  ui-select {
    height: 2.6rem;
  }
}

.theme-light .ui-select .select2-container span.select2-selection .select2-selection__clear {
  border: none !important;

  .ui-btn__inner {
    overflow: unset;
  }
}

ui-segmented-control {
  .segmented-control__control {
    padding: 10px;
    .segmented-control__label {
      padding-bottom: 0;
    }
  }
}

.ui-tag {
  box-shadow: none !important;
  .ui-tag__label {
    line-height: 15px;
  }
}

.select-all-container,
.ag-cell-value {
  ui-checkbox {
    label.ui-option__control {
      padding-bottom: 0;
    }
  }
}

ag-grid-aurelia {
  .select-all-container {
    height: inherit;
  }
}
