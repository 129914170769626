import { ListViewModelBase, CustomLogger, ServiceBase, SelectAllHeaderComponent, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { Merlin } from 'generated';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class ContentTemplateList extends ListViewModelBase<Merlin.Web.Model.ContentTemplate> {
  public ressourceName: string = Constants.EntityTypeNames.ContentTemplate;
  public pathDetail: string;


  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.ContentTemplate>(Constants.EntityTypeNames.ContentTemplate));
    this.service.gridDataSource.expands = ['contentType'];
  }

  public getDetailsUrl(self: any, entity: Merlin.Web.Model.ContentTemplate): string {
    if (entity?.id) {
      return (this.pathDetail + entity.id);
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = this.router.baseUrl + 'content-templates/';
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("contenttemplate.templateNameML"),
        field: "templateNameML._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("contenttemplate.contentTypeId"),
        field: "contentType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ContentType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ContentType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("contenttemplate.isActif"),
        field: "isActif",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("contenttemplate.isDefault"),
        field: "isDefault",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }

} 
