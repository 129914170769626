import { HttpClient } from 'aurelia-fetch-client';
import { BulkUpdateOperation, IPriceOfferLineGridService, MeteringTotalPrices, RequestCreatePriceOfferLine, RequestFilterPriceOfferLine } from './i-data-line-api-service';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { PriceOfferLineFromItem } from 'projects/quotes/add-items/add-items';
import { Box, GlobalLoaderService, CustomLogger } from 'digiwall-lib';
import { JSONPatchOperation } from './json-patch-operation';
import { I18N } from 'aurelia-i18n';
import { RefToDisplay } from '../constants';
import { version } from 'os';

@autoinject
export class ClientAdditionalWorkService implements IPriceOfferLineGridService {
  clientAdditionalWorkId: any;
  clientAdditionalWorkVersionId: any;
  public projectId: number;
  public dataLines: Array<any>;

  constructor(protected httpClient: HttpClient, private globalLoaderService: GlobalLoaderService, private box: Box, private i18n: I18N, private logger: CustomLogger) { }

  async deleteLine(lineId: number): Promise<Array<number>> {
    let result = await this.httpClient.delete(Constants.Application.AdditionalWorkController.DeleteLine.format(lineId.toString()))
    if (result.ok) {
      return await result.json();
    } else {
      let text = await result.text();
      this.logger.LogError(this.i18n.tr(text.slice(1, -1)), null, null, true);
    }
  }
  async freeLine(lineId: number): Promise<Array<number>> {
    let result = await this.httpClient.post(Constants.Application.AdditionalWorkController.FreeLine.format(lineId.toString()))
    if (result.ok) {
      return await result.json();
    } else {
      let text = await result.text();
      this.logger.LogError(this.i18n.tr(text.slice(1, -1)), null, null, true);
    }
  }
  async addItem(lineId: number, action: Constants.PriceOfferLineMoveAction) {
    let result = await this.httpClient.post(Constants.Application.AdditionalWorkController.AddItem.format(lineId.toString(), action.toString()))
    if (result.ok) {
      return await result.json();
    } else {
      let text = await result.text();
      this.logger.LogError(this.i18n.tr(text.slice(1, -1)), null, null, true);
    }
  }
  async addPostLine(lineId: number, action: Constants.PriceOfferLineMoveAction) {
    let requestCreatePriceOfferLine: RequestCreatePriceOfferLine = {
      targetId: lineId,
      categoryId: 0,
      fK: 0,
      action: action
    };
    let result = await this.httpClient.post(Constants.Application.AdditionalWorkController.AddPostLine.format(lineId.toString()), JSON.stringify(requestCreatePriceOfferLine))
    if (result.ok) {
      return await result.json();
    } else {
      let text = await result.text();
      this.logger.LogError(this.i18n.tr(text.slice(1, -1)), null, null, true);
    }
  }
  async setStatus(versionId: number, statusId: number) {
    let requestUri = Constants.Application.AdditionalWorkController.SetStatusVersion.format(versionId.toString(), statusId.toString());
    let query = await this.httpClient.post(requestUri);
    if (query.ok) {
      return true;
    } else {
      if (query.status == 400) {
        let text = await query.json();
        this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
        return false;
      }
    }
  }

  public async select(lineId: number) {
    if (this.dataLines != null) {
      let currentRow = this.dataLines?.find(x => x.id == lineId);
      this.selectChildren(currentRow);
      return [lineId];
    }
    return null;
  }

  private selectChildren(line) {
    let children = this.dataLines?.filter(x => x.parentId == line.id);
    children?.forEach(child => {
      child.isSelected = true;
      if (child.hasChildren) {
        this.selectChildren(child);
      }
    })
  }

  private unselectChildren(line) {
    let children = this.dataLines?.filter(x => x.parentId == line.id);
    children?.forEach(child => {
      child.isSelected = false;
      if (child.hasChildren) {
        this.unselectChildren(child);
      }
    })
  }

  public async unselect(lineId: number) {
    if (this.dataLines != null) {
      let currentRow = this.dataLines?.find(x => x.id == lineId);
      this.unselectChildren(currentRow);
      return [lineId];
    }
    return null;
  }

  async create(projectId: any, dto: AdditionalWorkCreationDTO) {
    let requestUri = Constants.Application.AdditionalWorkController.Create.format(this.projectId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(dto));
    if (query.ok) {
      return await query.json();
    }
  }


  async createAndAcceptedVersion(projectId, lineId: number) {
    let requestUri = Constants.Application.AdditionalWorkController.CreateAndAcceptedVersion.format(this.projectId.toString(), lineId.toString());
    let query = await this.httpClient.post(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async createLine(projectId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {
    let requestCreatePriceOfferLine: RequestCreatePriceOfferLine = {
      targetId: 0,
      categoryId: categoryId,
      fK: projectId,
      action: action
    };

    let requestUri = Constants.Application.AdditionalWorkController.CreateLine.format(projectId.toString(), this.clientAdditionalWorkId.toString());
    this.globalLoaderService.allow(true);
    let query = await this.httpClient.post(requestUri, JSON.stringify(requestCreatePriceOfferLine));
    if (query.ok) {
      return await query.json();
    }
    return [];
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.AdditionalWorkController.Children.format(this.projectId.toString(), lineId.toString(), displayHidden.toString(), refToDisplay.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = null;
    if (this.clientAdditionalWorkId == null) {
      if (this.dataLines != null) {
        return this.dataLines;
      }
      requestUri = Constants.Application.AdditionalWorkController.Filter.format(this.projectId.toString());
    } else {
      requestUri = Constants.Application.AdditionalWorkController.FilterClientAdditionalWork.format(this.projectId.toString(), this.clientAdditionalWorkVersionId.toString());
    }

    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.AdditionalWorkController.Patch.format(this.clientAdditionalWorkId, lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }
  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.AdditionalWorkController.BulkPatch.format(versionId.toString());
    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }

  async createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<Array<number>> {
    let requestUri = Constants.Application.AdditionalWorkController.CreateFromItems.format(this.projectId.toString(), this.clientAdditionalWorkVersionId.toString(), (versionId != null ? 0 : 1).toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(selectedItems));

    if (query.ok) {
      return await query.json();
    }
    return null;
  }

  async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let requestUri = Constants.Application.AdditionalWorkController.Fetch.format(this.clientAdditionalWorkId.toString(), refToDisplay.toString());
    let result = await this.httpClient.post(requestUri, JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  async removeLinkClientAdditionalWork(projectId: number, ClientAdditionalWorkId: number, lineId: number) {
    let requestUri = Constants.Application.AdditionalWorkController.RemoveLinkClientAdditionalWork.format(this.projectId.toString(), this.clientAdditionalWorkId.toString(), lineId.toString());
    let query = await this.httpClient.post(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  async removeLinks(lineIds: number[]) {
    let requestUri = Constants.Application.AdditionalWorkController.RemoveLinks.format(this.projectId.toString(), this.clientAdditionalWorkId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(lineIds));
    if (query.ok) {
      return await query.json();
    }
  }

  async transformToExtraLinkClientAdditionalWork(projectId: number, ClientAdditionalWorkId: number, lineId: number) {
    let requestUri = Constants.Application.AdditionalWorkController.TransformToExtraLinkClientAdditionalWork.format(this.projectId.toString(), this.clientAdditionalWorkId.toString(), lineId.toString());
    let query = await this.httpClient.post(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  async rejectLinkClientAdditionalWork(projectId: number, ClientAdditionalWorkId: number, lineId: number) {
    let requestUri = Constants.Application.AdditionalWorkController.RejectLinkClientAdditionalWork.format(this.projectId.toString(), this.clientAdditionalWorkId.toString(), lineId.toString());
    let query = await this.httpClient.post(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  async getMeteringTotalPrices(clientAdditionalWorkId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.get(Constants.Application.AdditionalWorkController.GetMeteringTotalPrices.format(clientAdditionalWorkId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  async changeStatus(clientAdditionalWorkId: number, statusId: number) {
    let requestUri = Constants.Application.AdditionalWorkController.ChangeStatus.format(clientAdditionalWorkId.toString(), statusId.toString());
    let result = await this.httpClient.post(requestUri);
    if (result.ok) {
      return true;
    }
    else {
      return null;
    }
  }

  async getHasAcceptedVersion(additionalWorkId: number): Promise<boolean> {
    let requestUri = Constants.Application.AdditionalWorkController.GetHasAcceptedVersion.format(additionalWorkId.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  async getHasDraftVersion(additionalWorkId: number): Promise<boolean> {
    let requestUri = Constants.Application.AdditionalWorkController.GetHasDraftVersion.format(additionalWorkId.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }


  async recomputeLines(versionId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.get(Constants.Application.AdditionalWorkController.RecomputeLines.format(versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

}

class AdditionalWorkCreationDTO {
  selectedLinesIds: number[];
  description: string;
  validUntilDate: Date | string | null;
  additionalDelayNbDays: number | null;
}
