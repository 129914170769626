import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, EnumerationType, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef, RowNode } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class EmailTypeList extends ListViewModelBase<Merlin.Web.Model.EmailType> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.EmailType>(Constants.EntityTypeNames.EmailType));
    this.service.gridDataSource.expands = ['type', 'emailContext'];
  }
  public ressourceName: string = Constants.EntityTypeNames.EnumerationType;
  public buttonLabel: string;

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  getDetailsUrl(self: any, entity: Merlin.Web.Model.EmailType): string {
    return 'email-type/' + entity.id;
  }

  public addEmailType() {
    return this.navigateTo('email-type/-100');
  }

  protected isSelectable(entity: Merlin.Web.Model.EmailType): boolean {
    return !entity?.type.uniqueCode;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsFR"),
        field: "type.denomination.lang1",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsNL"),
        field: "type.denomination.lang2",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsEN"),
        field: "type.denomination.lang3",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.active"),
        field: "type.active",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.default"),
        field: "type.default",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("emailtype.emailContextId"),
        field: "emailContext.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.EmailContext,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.EmailContext,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
}
