@import "./../../../css/scss/variables";

.ag-row.can-create-amendment {
  background-color: $purple-lighter !important;
}

.custom-filter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .tasks-filter-1 {
    margin: 0 40px;
  }

  .tasks-filter-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid $gray-lighter !important;
    > div.filter-counter {
      height: 40px;
      display: flex;
      align-items: center;
      gap: 20px;

      div {
        width: 220px;
        height: inherit;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $gray-light;
        gap: 5px;
        cursor: pointer;
        &.isSelected {
          border-bottom: 2px solid $primary;
          color: $black-100;
        }
        > span {
          padding-left: 20px;
        }
      }
    }
  }
}
