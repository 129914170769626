import { Container, autoinject, observable } from "aurelia-framework";
import { CustomLogger, EnumerationTypeService, ViewModelBase, ServiceBase, FileFlow, PictureHelper } from "digiwall-lib";
import { Merlin } from "../../generated";
import * as Constants from '../../constants';
import { Router } from 'aurelia-router';
import { I18N } from "aurelia-i18n";
import { FilterQueryOp, Predicate } from "breeze-client";
import { ValidationController } from "aurelia-validation";
import { toRegex } from "diacritic-regex";


@autoinject
export class ParticularConditionList extends ViewModelBase {
  public ressourceName: string = Constants.EntityTypeNames.ParticularCondition;
  public get ribbonHeaderText() {
    return this.i18n.tr("purchases.particularConditions.particularConditions");
  }

  private activityCodeService: EnumerationTypeService;

  public items: Array<Merlin.Web.Model.ParticularCondition> = [];
  private service: ServiceBase<Merlin.Web.Model.ParticularCondition>;
  public controller: ValidationController;

  @observable filter: string;
  async filterChanged(n, o) {
    if (n != o) {
      if (n?.trim().length > 0) {
        let items = await this.service.getEntitiesFromLocalCache(null, ['activityCode']);
        this.items.splice(0);
        items.filter(i => {
          let r1 = new RegExp(toRegex()(n), 'igu');
          let regExpResult1 = r1.exec(i.fileName);
          let regExpResult2 = r1.exec(i.activityCode?.denomination?._translation);
          return regExpResult1 || regExpResult2;
        })
          .forEach(i => this.items.push(i));
      } else {
        this.loadAllItems();
      }
    }
  }

  constructor(router: Router, logger: CustomLogger, public i18n: I18N, private fileHelper: PictureHelper) {
    super(router, logger);
    this.service = new ServiceBase<Merlin.Web.Model.ParticularCondition>(Constants.EntityTypeNames.ParticularCondition);
    this.activityCodeService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    this.activityCodeService.gridDataSource.customSelect2Predicates = () => {
      let pred = new Predicate('id', FilterQueryOp.NotEquals, null);
      this.items?.filter(i => i.activityCodeId != null).forEach(i => pred = pred.and(new Predicate('id', FilterQueryOp.NotEquals, i.activityCodeId)));
      return pred;
    };
    this.controller = Container.instance.get(ValidationController);
  }

  async activate(params: any) {
    await super.activate(params);
  }

  public async attached() {
    this.loadAllItems();
  }

  private async loadAllItems() {
    this.items.splice(0);
    let ents = await this.service.getAllEntities(...['activityCode']);
    ents.forEach(ent => this.items.push(ent));
  }

  public async addCondition() {
    let newCond = await this.service.createEntity();
    this.controller.addObject(newCond);
    this.items.push(newCond);
  }

  public async deleteCondition(currentThis: Merlin.Web.Model.ParticularCondition) {
    await this.service.deleteEntities([currentThis], false);
    let i = this.items.findIndex(i => i == currentThis);
    if (i > -1) {
      this.items.splice(i, 1);
    }
  }

  public async saveCondition(currentThis: Merlin.Web.Model.ParticularCondition) {
    let validResult = await this.controller.validate({ object: currentThis });
    if (validResult.valid && currentThis.fileUrl?.trim().length > 0 && currentThis.activityCodeId != null) {
      await this.service.saveEntity(currentThis);
    }
  }

  previewFile(currentThis: Merlin.Web.Model.ParticularCondition) {
    this.fileHelper.previewFile({ url: currentThis.fileUrl });
  }

  public async uploadFile(file: FileFlow) {
    //No action here, all action is in uploadedFile
  }

  public async uploadedFile(fileUploaded: FileFlow, currentThis: Merlin.Web.Model.ParticularCondition) {
    let file: FileFlow = (fileUploaded as any).fileUploaded
    currentThis.fileUrl = file.url;
    currentThis.fileName = file.name;
    await this.saveCondition(currentThis);
  }

  getMenuItem(currentThis: Merlin.Web.Model.ParticularCondition) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: async () => {
              await this.deleteCondition(currentThis);
            }
          }
        ]
      }
    ]
  }
}
