import { I18N } from 'aurelia-i18n';
import { ActionDialogBoxInputParameters, DialogBoxViewModel } from 'digiwall-lib';

export class EditDialogAction {
  public static GetSaveAction(i18n: I18N, closeIfMissingEntity: boolean = true) {
    return {
      label: i18n.tr("menu.save"),
      closeDialog: false,
      theme: "primary",
      type: "solid",
      fn: async (dialogBoxViewModel: DialogBoxViewModel) => {
        let entity = await dialogBoxViewModel.customVM.currentViewModel.saveCurrentEntity(false);
        if (closeIfMissingEntity || entity != null) {
          dialogBoxViewModel.controller.ok(entity);
        }
      }
    } as ActionDialogBoxInputParameters
  }

  public static GetSaveAllAction(i18n: I18N, closeIfMissingEntity: boolean = true) {
    return {
      label: i18n.tr("menu.save"),
      closeDialog: false,
      theme: "primary",
      type: "solid",
      fn: async (dialogBoxViewModel: DialogBoxViewModel) => {
        let entity = await dialogBoxViewModel.customVM.currentViewModel.save(false);
        if (closeIfMissingEntity || entity != null) {
          dialogBoxViewModel.controller.ok(entity);
        }
      }
    } as ActionDialogBoxInputParameters
  }
}
