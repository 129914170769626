@import "../css/scss/variables";

ui-files.general-conditions {
  thead {
    display: none;
  }
  tbody {
    td:nth-child(1),
    td:nth-child(2) {
      width: 50px;
    }
  }
}
ui-panel.no-border {
  ui-field.ui-field--disabled label span {
    color: $blue-mid !important;
  }
  > .ui-panel__header {
    border: none;
  }
}
