import { autoinject, bindable, customElement, observable } from "aurelia-framework";

@customElement("ui-footer-page")
@autoinject
export class UIFooterPage {
  @bindable
  saveFn: () => Promise<void>;

  @bindable
  cancelFn: () => Promise<void>;

  constructor() {

  }
}
