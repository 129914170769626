import { HttpClient } from 'aurelia-fetch-client';
import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { Box } from 'digiwall-lib';
import IPriceOfferLineCommentApiService from 'services/price-offer-line-comment-api-service';
import { Merlin } from '../generated';

@autoinject
export class ComparaisonPriceOfferLineCommentApiService implements IPriceOfferLineCommentApiService {

  constructor(private box: Box, private i18n: I18N, private httpClient: HttpClient) { }

  public async get(line: any, fieldId: string): Promise<Array<Merlin.Web.Model.PriceOfferLineCommentDTO>> {
    let comment = line.supplierPrices[fieldId].additionalCommentMultiline;
    let json: Merlin.Web.Model.PriceOfferLineCommentDTO[] = [];
    json.push({
      id: null,
      priceOfferLineId: null,
      commentMultiline: comment,
      fieldId: null,
      merlinUserId: null,
      lastModification: null
    })
    return json;
  }

  public async createOrUpdate(lineId: number, commentDTO: Merlin.Web.Model.PriceOfferLineCommentDTO): Promise<boolean> {
    throw new Error("Can no create comment");
  }

  public async delete(commentId: string): Promise<boolean> {
    throw new Error("Can no delete comment");
  }
}
