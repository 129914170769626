import { RefToDisplay } from './../constants';
import { CellValueChangedEvent, ColDef, ColumnState, GetContextMenuItemsParams, GridOptions, HeaderValueGetterFunc, ICellRendererComp, ICellRendererParams, ILoadingOverlayComp, IServerSideDatasource, IServerSideGetRowsParams, MenuItemDef, RowClassParams, RowGroupOpenedEvent, RowNode, ValueSetterParams, ViewportChangedEvent } from "ag-grid-community";
import { TaskQueue, autoinject, bindable, bindingMode, customElement } from "aurelia-framework";
import { Router } from "aurelia-router";
import { AgGridView, AgGridViewService, AuthService, Box, Config, CustomAgGridOptions, DataGridSetting, Datacontext, IMenuItems, CustomColDef, IMenuGroup, IMenuItem, GlobalLoaderService, UIInternal } from "digiwall-lib";
import IGridCellCommentable from "resources/elements/ag-cell-comment/grid-cell-commentable.interface";
import { IListTreeDataGridService } from "./service/module-list-tree-data-service";
import { HttpClient } from "aurelia-fetch-client";
import { I18N } from "aurelia-i18n";
import { HighlightSearchTextValueConverter } from "resources/value-converters";
import * as Constants from '../constants';
import * as toastr from 'toastr';
import CustomButtonRenderer from "resources/renderer/custom-button-renderer";
import { MeteringFocusCell } from "price-offer-lines/price-offer-lines-grid";
import CommentRenderer from 'resources/renderer/comment-renderer';
import { AgCellCommentService } from 'resources/elements/ag-cell-comment/ag-cell-comment-service';
import IPriceOfferLineCommentApiService from 'services/price-offer-line-comment-api-service';

@autoinject
@customElement("list-tree-data")
export class ModuleListTreeData implements IGridCellCommentable {
  @bindable
  public title: string;
  @bindable
  public parentId: number | string;
  @bindable
  public flattenList: boolean = false;
  @bindable
  public api: IListTreeDataGridService;
  @bindable
  public getOverlayComponent: ILoadingOverlayComp = null;
  @bindable
  public shouldExpand: boolean;
  @bindable
  public showExpand: boolean = true;
  @bindable({ bindingMode: bindingMode.toView })
  public gridOptions: GridOptions;
  @bindable
  public getHeaderMenuItems: Array<IMenuItems>;
  @bindable
  public quickFilter: string;
  @bindable
  public displayHiddenLine: boolean = false;
  @bindable
  public showDisplayHidden: boolean = true;
  @bindable
  public canFlattenList = true;
  @bindable
  public canUseFilter: boolean = true;
  @bindable
  public canUseParameters: boolean = true;
  @bindable
  public canUseView: boolean = true;
  @bindable
  public canNavigateBack: boolean = true;
  @bindable
  public getGroupKey: (data) => string;
  @bindable
  public placeholderSearch: string = this.i18n.tr('menu.search');
  @bindable
  public allowCommentOnCell: boolean = true;
  @bindable
  public canComment: (d: ColDef) => boolean;
  @bindable
  public commentService: IPriceOfferLineCommentApiService;
  public agCellCommentService: AgCellCommentService;

  @bindable
  public onGridReady = () => { };
  @bindable
  public onDataLoaded = () => { };
  @bindable
  public triggerExpand = () => { };
  @bindable
  public specificOnCellValueChanged: (event: CellValueChangedEvent) => Promise<any>;
  @bindable
  public getDataGridColumns: () => Array<CustomColDef>;
  @bindable
  public afterInitGridOption: (gridOptions: GridOptions) => {};
  @bindable
  public setDefaultColomnPinned: () => {};
  @bindable
  public onRowGroupOpened: (event: RowGroupOpenedEvent<any>) => {};
  @bindable
  public customSettingMenuItems: () => IMenuItems[];

  @bindable
  public noFetchChildren: boolean = false;

  @bindable
  private agGridViewModuleView: string;
  @bindable
  public refToDisplay: RefToDisplay = RefToDisplay.MerlinRef
  @bindable
  public showOriginalRefOption: boolean = false
  @bindable
  public showSignedRefOption: boolean = false
  @bindable
  public bottomRowRenderer: ICellRendererComp = null
  @bindable
  private debugMode: boolean = false;
  @bindable
  private recomputeLines: () => {};


  public gridRef: HTMLElement;
  public gridHeaderBar: any;

  private agGridViewList: Array<AgGridView>;
  public gridViewService: AgGridViewService;
  private showGrid = true;
  public entitiesCount: number = 0;
  private nodesToRedraw: Array<number> = [];
  public nodesToRefresh: Array<number> = [];
  private cellsToRefresh: Array<{ node: RowNode, colId: string }> = [];
  @bindable
  public rowToOpen: Array<number> = [];
  @bindable
  private meteringFocusCell: MeteringFocusCell = null;
  private expandedNodes: Array<number> = [];
  private manuallyHandleFetchChildren = false;

  private routeName: string;
  private highlightSearchTextValueConverter: HighlightSearchTextValueConverter;
  private flattenListParamsBackup: { colDef?: any, filterModel?: any, mustRestore: boolean } = { mustRestore: false };


  private onColumnResizedTimer;
  private resizeAgCellTimer;
  public screenExpand: boolean = false;
  private firstGridLoad = true;
  public dataLines: Array<any>;
  private searchLinesResult: Array<any>;
  @bindable
  public condensed = true;
  private keyDownFunction;
  private keyUpFunction;
  public isPressingShift: boolean = false;
  private lastRowCellSelected: any;


  public noDataLabel = this.i18n.tr("grid.noRowsToShow");

  private settingMenuItems: IMenuItems[] = []


  constructor(public router: Router, public httpClient: HttpClient, public i18n: I18N, public config: Config, public authService: AuthService, public datacontext: Datacontext, public box: Box, public taskQueue: TaskQueue, public element: Element, public globalLoaderService: GlobalLoaderService) {
    this.gridViewService = new AgGridViewService();
    this.highlightSearchTextValueConverter = new HighlightSearchTextValueConverter();
    this.keyDownFunction = (e) => this.handleKeyDown(e);
    this.keyUpFunction = () => this.handleKeyUp();
    document.addEventListener('keydown', this.keyDownFunction)
    document.addEventListener('keyup', this.keyUpFunction)
  }

  public innerTriggerExpand() {
    this.screenExpand = !this.screenExpand;
    this.triggerExpand();
    let scroll = document.getElementsByClassName("ui-scroll");
    if (scroll.length > 0) {
      if (this.screenExpand) {
        scroll[0].scrollTop = 0;
      }
      else {
        let scroll2 = document.getElementsByClassName("parent-tree-data");
        if (scroll2.length > 0) {
          scroll[0].scrollTo({
            top: scroll2[0].offsetTop,
            behavior: 'auto'
          })
        }
      }
    }
  }


  public quickFilterChanged(newValue: string, oldValue) {
    this.gridOptions.api.onFilterChanged();
  }

  detached() {
    document.removeEventListener("keyup", this.undoRedoListener);
    document.body.removeEventListener('click', this.clickOutsideListener);
    document.removeEventListener("keydown", this.handleShortcutsEvent);
    document.removeEventListener('keydown', this.keyDownFunction);
    document.removeEventListener('keyup', this.keyUpFunction);
  }

  async bind() {
    this.routeName = this.router.currentInstruction.config.route as string;
    const colsTypesDefs = CustomAgGridOptions.ColumnTypeDefinitions(this.i18n, this.config.globalConfig.defaultLocale);
    colsTypesDefs["Number"].cellEditor = 'numberCellEditor';
    this.gridOptions = {
      components: Object.assign({},
        CustomAgGridOptions.Components,
        {
          'customButtonRenderer': CustomButtonRenderer,
          "commentRenderer": CommentRenderer,
        }
      ),
      columnTypes: colsTypesDefs as any,
      defaultColDef: {
        resizable: true,
        sortable: false,
        editable: false,
        suppressMenu: true,
        menuTabs: ['filterMenuTab']
      },
      columnDefs: this.getDataGridColumns(),
      getRowHeight: params => {
        if (params?.node?.data?.isBottomRow === true) {
          return 90;
        }
        if (params?.node?.rowPinned == 'bottom') {
          return 60;
        }
        if (this.condensed) {
          return 30;
        }
        return 40;
      },
      headerHeight: 35,
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
      stopEditingWhenCellsLoseFocus: false,
      suppressRowClickSelection: true,
      // singleClickEdit: true,
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 20,
      enableGroupEdit: true,
      cellFlashDelay: 500,
      cellFadeDelay: 2000,
      tooltipShowDelay: 1000,
      enterMovesDownAfterEdit: true,
      enterMovesDown: true,
      suppressScrollWhenPopupsAreOpen: true,
      isFullWidthRow: (params) => {
        if (params.rowNode.data?.isBottomRow === true) {
          return true;
        }
      },
      fullWidthCellRenderer: this.bottomRowRenderer,
      fullWidthCellRendererParams: {
        listTree: this,
        apiService: this.api,
        versionId: this.parentId
      },
      onCellEditingStarted(event) {
        if ((event.colDef.cellRenderer == "enumerationRenderer" || event.colDef.filter == "customOneToManyFilter")) {
          setTimeout(() => {
            let selectSearchBar = document.getElementsByClassName("select2-search__field");
            if (event.event instanceof KeyboardEvent && event.event.key.length == 1 && selectSearchBar[0] != null)
              (selectSearchBar[0] as any).value = event.event.key
          }, 5);
        }
      },
      onCellValueChanged: async (event: CellValueChangedEvent) => this.onCellValueChanged(event),
      onModelUpdated: () => { this.resizeAgCellLastLeftPinned(); },
      onViewportChanged: (event: ViewportChangedEvent) => { this.resizeAgCellLastLeftPinned(); },
      onColumnResized: (event) => {
        if (this.flattenList || !event.finished) return;
        if (this.onColumnResizedTimer)
          clearTimeout(this.onColumnResizedTimer);

        this.onColumnResizedTimer = setTimeout(() => {
          this.gridOptions.api.redrawRows();
          this.resizeAgCellLastLeftPinned();
        }, 10);
      },
      onBodyScroll: () => { this.resizeAgCellLastLeftPinned(); },
      overlayNoRowsTemplate: this.noDataLabel,
      // Setup Tree Data
      treeData: !this.flattenList,
      groupDisplayType: 'custom',
      rowModelType: "serverSide",
      debounceVerticalScrollbar: true,
      isServerSideGroup: dataItem => !this.flattenList && dataItem.hasChildren,
      getServerSideGroupKey: dataItem => this.getGroupKey(dataItem),
      isServerSideGroupOpenByDefault: () => false,
      animateRows: true,
      rowBuffer: 200,
      getRowClass: (params: RowClassParams<any>) => {
        let classes = ""
        if (this.condensed) {
          classes += " condensed-row ";
        }
        if (this.flattenList) return classes;// Not needed when not in tree view mode.
        // Manually set the .ag-row-last-row class on the last rows of group, it's not well performed by ag-grid due to virtualization.
        if (!params.node.parent?.group && !params.node.parent?.expanded) return classes;

        let children = (<any>params.node?.parent?.childStore)?.nodesAfterSort as Array<RowNode>;
        if (children?.length) {
          let childIndex = children.findIndex(n => n == params.node);
          params.node.lastChild = childIndex == children.length - 1;
          if (params.node.lastChild) {
            // Don't use general class ag-row-last, because ag-grid remove it
            classes += 'ag-row-last-row ';
          }
        }

        return classes
      },
      serverSideFilterOnServer: true,
      serverSideFilterAllLevels: true,
      // E.O. Setup
      getContextMenuItems: (params: GetContextMenuItemsParams) => {
        if (params.node?.data?.id == 0 && params.node.data.uniqueId == 0) return null
        var items = params.api?.getColumnDef("menuIems")?.cellRendererParams?.menuItems(params, false);
        if (items != null) {
          let defs = this.toGridContextMenu(items, params.node);
          if (defs.last() == 'separator') {
            defs.splice(defs.length - 1);
          }
          return defs;
        }
        return null;
      },
      onRowSelected: (event) => {
        if (event.data && event.node && (event.event == null || ((event.event?.target as HTMLElement).classList.length > 0 && !((event.event.target as HTMLElement).classList.value.includes("ag-cell")) && (event.event.target as HTMLElement).parentElement?.classList.value.includes("cell-selected")))) {
          event.data.isSelected = event.node.isSelected();
        }
      },
      onRowGroupOpened: (event) => {
        this.onRowGroupOpened(event);
        // redraw tree lines
        if (event.node.level < 1) return;
        let nodes: RowNode[] = [];
        let parent = event.node;
        let secu = 0;
        while (parent != null && parent.level > 0 && secu < 20) {
          parent = parent.parent;
          nodes = nodes.concat((<any>parent?.childStore)?.nodesAfterSort[(<any>parent?.childStore)?.nodesAfterSort.length - 1]);
          secu++;
        }

        if (event.node.expanded) { // Opened
          if ((<any>event).node.childStore?.allRowNodes?.length < 2) {// refresh after fetch data
            this.cellsToRefresh = this.cellsToRefresh.concat(nodes.reverse().map(n => { return { node: n, colId: "IsSelected" } }));
          } else {// data allready loaded, refresh direct
            nodes.forEach(n => n.setData(n.data));
          }
        } else { // Closed
          event.api?.redrawRows({ rowNodes: nodes });
        }
      },
    };

    if (this.afterInitGridOption) {
      await this.afterInitGridOption(this.gridOptions);
    }
    this.agCellCommentService = new AgCellCommentService("fieldsComments", ",", this, this.commentService);
    if (this.allowCommentOnCell) {
      this.gridOptions.columnDefs.filter((d: ColDef) => d.cellRendererParams?.canComment != false).forEach((d: ColDef) => {
        if (d.cellRendererParams == null)
          d.cellRendererParams = {};
        d.cellRendererParams.canComment = true;
        d.cellRendererSelector = params => {
          let hasComment = this.hasComment(params.colDef, params.data)
          if (hasComment) {
            return {
              "component": CommentRenderer,
              "params": {
                ...params,
                "baseRenderer": params.colDef.cellRenderer,
                "components": this.gridOptions.components,
                "commentService": this.agCellCommentService
              }
            }
          }
        }
      });
    }

    this.gridOptions.onGridReady = async (event) => {
      this.gridOptions.api.setServerSideDatasource(this.createServerSideDatasource());

      if (this.firstGridLoad) {// Avoid to apply agGridView on triggerFlattenList
        this.firstGridLoad = false;
        this.listenClickOutsideGrid();
        this.listenUndoRedoLeaveCellEditing();
        if (this.canUseView) {
          await this.initAgGridView();
          await this.setAgGridView();
        }
      }

      if (this.setDefaultColomnPinned) {
        this.setDefaultColomnPinned();
      }

      if (typeof this.onGridReady == 'function') this.onGridReady();

      window.addEventListener('keydown', (event) => this.eventKey(event), true);
      document.addEventListener("keydown", this.handleShortcutsEvent, false);
    }

    this.setSettingMenuItems();

  }

  public hasComment(colDef, data) {
    let colName = colDef.field;
    return (data.fieldsComments as string)?.includes(colName) ?? false;
  }
  //#region Line ContextMenu
  private toGridContextMenu(menuGroups: Array<IMenuGroup>, node: RowNode): Array<MenuItemDef | string> {
    let items: Array<MenuItemDef | string> = [];
    menuGroups.forEach(group => {
      group.items.forEach((item: IMenuItems) => {// attention aux HIDDEN !!
        items = items.concat(this.fromItem(item as IMenuItem, node));
      });
      if (items.last() != 'separator') {
        items.push('separator');
      }
    })
    return items;
  }

  private fromItem(item: IMenuItems, node: RowNode): Array<MenuItemDef | string> {
    if ((typeof (<IMenuItem>item).hidden == 'function' && (<any>item).hidden() == true) || (<IMenuItem>item).hidden == true) {
      return null;
    }

    let itemDefs: Array<MenuItemDef | string> = [];

    if ((<any>item).group === undefined) { // IMenuItem
      // Set icon
      let iconEl: HTMLElement = null;
      if ((<IMenuItem>item).icon != null) {
        iconEl = document.createElement('ui-icon');
        iconEl.className = (<IMenuItem>item).icon;
      }

      // Set MenuItemDef
      let iDef: MenuItemDef = {
        name: (<IMenuItem>item).label,
        icon: iconEl,
        disabled: (typeof (<IMenuItem>item).disabled == 'function') ? (<any>item).disabled() : (<IMenuItem>item).disabled,
        cssClasses: (<IMenuItem>item).classes?.split(" ")
      };

      // Set action
      if (typeof (<IMenuItem>item).handler == 'function') {
        iDef.action = () => (<IMenuItem>item).handler(node.data);
      }

      // Set subitems
      if (isArray((<IMenuItem>item).items)) {
        (<IMenuItem>item).items.forEach(i => {
          if (iDef.subMenu == null) {
            iDef.subMenu = [];
          }
          iDef.subMenu = iDef.subMenu.concat(this.fromItem(i, node));
        });
      }

      itemDefs.push(iDef);
    }
    else if ((<any>item).group !== undefined) { // IMenuGroup
      (<IMenuGroup>item).items.forEach(item => {
        itemDefs = itemDefs.concat(this.fromItem(item, node));
      });
    }

    return itemDefs;
  }
  //#endregion

  private eventKey = (event) => {
    if (event.key == "Escape" && this.screenExpand) {
      this.innerTriggerExpand();
    }
  }

  /**
   * Due to special indenting for the left pinned rows in treeview, we have to recompute the last-left-pinned cell.
   *
   * @private
   * @memberof ModuleListTreeData
   */
  public resizeAgCellLastLeftPinned(): void {
    if (this.resizeAgCellTimer != null) clearTimeout(this.resizeAgCellTimer);
    if (this.flattenList) return;// Not needed when not in tree view

    this.resizeAgCellTimer = setTimeout(() => {
      if (this.gridRef == null) return;
      let cells = this.gridRef.getElementsByClassName("ag-cell-column-resize-pinned-left");
      if (cells.length) {
        for (let i = 0; i < cells.length; i++) {
          let cell: HTMLDivElement = cells[i] as HTMLDivElement;
          if (cell.classList.contains('last-cell-resized')) continue;
          let parentRow = cell.closest('div.ag-row');
          if (parentRow == null) continue;
          let level = 0;
          parentRow.classList.forEach(cssCl => {
            if (cssCl.match('ag-row-level-') != null) {
              level = parseInt(cssCl.replace('ag-row-level-', ''));
              return;
            }
          });
          if (level > 0) {
            cell.style.width = cell.getBoundingClientRect().width - (level * 34) + "px";
          }
          cell.classList.add('last-cell-resized');
        }
      }
    }, 10)
  }

  public changeDisplayHiddenLine() {
    this.displayHiddenLine = !this.displayHiddenLine;
    this.refreshServerSideRows([], true);
  }

  refToDisplayChanged() {
    this.refreshServerSideRows([], true);
  }

  /**
   * Trigger the boolean flattenList to change the display mode of the grid between Tree / Flat.
   *
   * @private
   * @memberof ModuleListTreeData
   */
  public triggerFlattenList() {
    this.flattenList = !this.flattenList;

    if (this.gridOptions != null) {
      this.flattenListParamsBackup = {
        mustRestore: true,
        colDef: this.gridOptions.api?.getColumnDefs(),
        filterModel: this.gridOptions.api?.getFilterModel()
      };

      this.showGrid = false;
      this.gridOptions.treeData = !this.flattenList;
      this.gridOptions.rowBuffer = this.flattenList ? 100 : 200;
      this.taskQueue.queueMicroTask(() => {
        this.showGrid = true;
      });
    }

  }

  /**
   * Call api to refresh data of the rendered nodes found in the given node ids.
   * This function is used in case of updating data only. 
   * In case of 
   *
   * @private
   * @param {number[]} ids
   * @param {boolean} [flashCells=true]
   * @return {*}  {Promise<Array<RowNode>>}
   * @memberof ModuleListTreeData
   */
  public async refreshVisibleNodes(ids: number[], flashCells = true): Promise<Array<RowNode>> {
    let nodes: Array<RowNode> = [];
    ids.forEach(id => {
      let node = this.gridOptions.api.getRowNode(id.toString());
      if (node != null)
        nodes.push(node);
    });
    let entities = await this.api.fetch(parseInt(this.parentId.toString()), nodes.map(n => n.data.id), this.displayHiddenLine, this.refToDisplay);
    if (entities != null) {
      entities.forEach(entity => {
        nodes.find(n => {
          if (n.data.uniqueId != null) {
            return n.data.uniqueId == entity.uniqueId;
          }
          return n.data.id == entity.id;
        })?.setData(entity)

        this.dataLines[this.dataLines.findIndex(x => x.uniqueId == entity.uniqueId)] = entity;
      });
      if (flashCells) this.gridOptions.api.flashCells({ rowNodes: nodes });
      return nodes;
    }
  }

  /**
   * Call the refreshServerSide of aggrid for the rendered rows.
   *
   * @private
   * @param {number[]} ids
   * @return {*}  {Array<RowNode>}
   * @memberof ModuleListTreeData
   */
  public refreshServerSideRows(ids: number[], refreshAllTable = false, forcePurge = false, flashCells = true): Array<RowNode> {
    if (ids == null) ids = [];
    if (this.gridOptions == null) return null;
    this.gridOptions.api.deselectAll();
    this.nodesToRedraw = [];
    this.expandedNodes = [];
    let nodes: Array<RowNode> = [];
    ids.forEach(id => {
      let node = this.gridOptions.api.getRowNode(id.toString());
      if (node != null) nodes.push(node);
    });
    this.expandedNodes = this.gridOptions.api.getRenderedNodes().filter(n => n.expanded).map(n => n.data.id);
    if (refreshAllTable) {
      this.gridOptions.api.refreshServerSide({ purge: true });
    } else {
      nodes
        .sort((a, b) => (<string>a.data.merlinRef)?.localeCompare((<string>b.data.merlinRef), undefined, { numeric: true, sensitivity: 'base' }))
        .forEach(node => {
          if (!this.flattenList) {
            this.nodesToRedraw.push(node.data.id);
          }
          let route: Array<string> = null;
          route = node.getRoute();
          if (route == null) {
            let parent = node.parent;
            while (route == null && parent != null) {
              route = parent.getRoute();
              if (route == null) {
                parent = parent.parent;
              }
            }
          }
          this.gridOptions.api.refreshServerSide({ route: route, purge: /*route == null ? true : */forcePurge });
        });
    }
    if (flashCells) this.gridOptions.api.flashCells({ rowNodes: nodes });
    return nodes;
  }


  private readonly clickOutsideListener = (event: Event) => {
    if (this.gridOptions?.api == null) return;
    let agGridContainer = (<HTMLElement>event.target).closest('ag-grid-aurelia');
    if (agGridContainer == null && document.body.contains(<HTMLElement>event.target) && !hasParent(event.target as HTMLElement, 'ui-calendar')) {
      this.gridOptions.api.stopEditing(false);
    }
  }

  private listenClickOutsideGrid(): void {
    document.body.addEventListener('click', this.clickOutsideListener);
  }

  private readonly undoRedoListener = (e: KeyboardEvent) => {
    if ((e.key.toLowerCase() == "z" || e.key.toLowerCase() == "y") && e.ctrlKey) {
      this.gridOptions.api?.stopEditing(true);
    }
  };

  /**
   * Because AgGrid don't stop editing on undo/redo, do it manually.
   * 
   * @memberof ModuleListTreeData
   */
  private listenUndoRedoLeaveCellEditing() {
    document.addEventListener("keyup", this.undoRedoListener);
  }

  //#region view
  public async initAgGridView() {
    if (this.router.currentInstruction.config.moduleId != null) {
      this.agGridViewList = new Array<AgGridView>();
      let defaultView = await this.gridViewService.getDefaultViewFromModuleId(this.router.currentInstruction.config.moduleId);
      if (defaultView == null) {
        defaultView = await this.gridViewService.createEntity({
          id: -100,
          name: this.i18n.tr("general.default", { ns: "common" }), moduleId: this.router.currentInstruction.config.moduleId,
          fixedFilters: false, columnState: JSON.stringify(this.gridOptions.columnApi.getColumnState()), filterModel: null,
          sortModel: null, visibility: "System", author: null, authorId: this.authService.currentUser.id,
          isDefaultView: true, createdBy: null, createdById: null, createdTime: null, updatedBy: null, updatedById: null,
          updatedTime: null, entityAspect: null, entityType: null, hide: false, used: false
        });
        defaultView.entityAspect.setDetached();
        defaultView.hide = false;
        defaultView.used = false;
      }
      this.agGridViewList.push(defaultView);
    }
  }

  public async setAgGridView() {
    let customGridSettings: any;
    if (this.authService.currentUser) {
      customGridSettings = this.authService.currentUser.settings.dataGridSettings[this.routeName];
    }
    if (customGridSettings != null) {
      if (this.router.currentInstruction.queryParams["usedViewId"] != null || customGridSettings.usedView?.id != null) {
        let usedView = await this.gridViewService.getEntityById(this.router.currentInstruction.queryParams["usedViewId"] ?? this.authService.currentUser.settings.dataGridSettings[this.routeName].usedView.id);
        if (usedView != null && this.agGridViewList != null) {
          usedView.used = true;
          this.agGridViewList.push(usedView);
          this.gridOptions.columnApi.applyColumnState({
            state: this.getNewColumnStates(JSON.parse(usedView.columnState)),
            defaultState: { sort: null },
            applyOrder: true
          });

          if (this.setDefaultColomnPinned) {
            this.setDefaultColomnPinned();
          }
          this.gridOptions.api.setFilterModel(JSON.parse(usedView.filterModel));
          (this.gridOptions as any).fixedFilters = usedView.fixedFilters;
        }
        else if (this.agGridViewList?.length > 0) {
          this.gridOptions.columnApi.applyColumnState({
            state: this.getNewColumnStates(JSON.parse(this.agGridViewList[0].columnState)),
            defaultState: { sort: null },
            applyOrder: true
          });

          if (this.setDefaultColomnPinned) {
            this.setDefaultColomnPinned();
          }
          this.gridOptions.api.setFilterModel(JSON.parse(this.agGridViewList[0].filterModel));
          (this.gridOptions as any).fixedFilters = this.agGridViewList[0].fixedFilters;
        }
      } else {
        if (customGridSettings.colsState != null && Array.isArray(customGridSettings.colsState)) {
          this.gridOptions.columnApi.applyColumnState({
            state: this.getNewColumnStates(customGridSettings.colsState),
            defaultState: { sort: null },
            applyOrder: true
          });

          if (this.setDefaultColomnPinned) {
            this.setDefaultColomnPinned();
          }
        }
        if (customGridSettings.filterModel) {
          this.gridOptions.api.setFilterModel(customGridSettings.filterModel);
        }
        (this.gridOptions as any).fixedFilters = customGridSettings.fixedFilters;
      }
      if (false == this.gridOptions?.columnApi?.getColumnState().some(x => x.sort != null)) {
        let state = (<ColDef[]>this.gridOptions?.columnDefs)
          .filter((colDef: ColDef) => colDef.sort != null)
          .map(({ field, sort, width, hide }) => { return { colId: field, sort, width, hide }; });

        this.gridOptions?.columnApi.applyColumnState({
          state: this.getNewColumnStates(state),
          defaultState: { sort: null },
          applyOrder: true
        });
        if (this.setDefaultColomnPinned) {
          this.setDefaultColomnPinned();
        }

      }
      (this.gridOptions as any).fixedFilters = (this.gridOptions as any).fixedFilters ?? false;
    } else if (this.agGridViewList?.length > 0) {
      this.gridOptions.columnApi.applyColumnState({
        state: this.getNewColumnStates(JSON.parse(this.agGridViewList[0].columnState) as ColumnState[]),
        defaultState: { sort: null },
        applyOrder: true
      });
      if (this.setDefaultColomnPinned) {
        this.setDefaultColomnPinned();
      }
      this.gridOptions.api.setFilterModel(JSON.parse(this.agGridViewList[0].filterModel));
      (this.gridOptions as any).fixedFilters = this.agGridViewList[0].fixedFilters;
    }
  }

  async afterSetAgGridView(agGridView) {
    if (this.setDefaultColomnPinned) {
      this.setDefaultColomnPinned();
    }
    this.gridOptions.api.redrawRows();
    this.resizeAgCellLastLeftPinned();
    await this.saveGridColumnsState();
  }

  public getNewColumnStates(columnStates: ColumnState[]): ColumnState[] {
    return columnStates.concat(this.gridOptions.columnApi.getColumnState().filter(s => false == columnStates.some(c => c.colId == s.colId)));
  }

  public get usedView() {
    let view = this.agGridViewList?.find(x => x.used);
    return (view != null && !view.isDefaultView) ? view : null;
  }

  public async saveGridColumnsState() {
    if (this.authService.currentUser) {
      // Selected view is the currently selected view. If no select, it is the default. If no views used, it is null.
      let selectedView = this.agGridViewList?.find(x => x.used) ?? this.agGridViewList?.find(x => x.isDefaultView);

      let currentSettings: DataGridSetting = {
        usedView: selectedView && selectedView.id > 0 ? { id: selectedView.id, name: selectedView.name } : null,
        colsState: (this.gridOptions.columnApi?.getColumnState() ?? [] as any),
        filterModel: JSON.parse(JSON.stringify(this.gridOptions.api?.getFilterModel()))
      };

      // If there is a view selected, only save cols, fixed and filter if different from view
      if (selectedView != null) {
        if (this.isColStateEqual(currentSettings, { colsState: JSON.parse(selectedView.columnState) })) {
          currentSettings.colsState = null;
        }
        if (this.isFilterModelEqual(currentSettings, { filterModel: JSON.parse(selectedView.filterModel) })) {
          currentSettings.filterModel = null;
        }
      }

      if (Object.keys(currentSettings.filterModel || {}).length == 0) {
        currentSettings.filterModel = null;
      }

      await this.saveUserSettings(currentSettings);
    }

  }

  protected isSavedDifferentFromCurrent(settingsA: DataGridSetting, settingsB: DataGridSetting) {
    return settingsA.usedView?.id != settingsB.usedView?.id
      || false == this.isColStateEqual(settingsA, settingsB)
      || false == this.isFilterModelEqual(settingsA, settingsB)
  }

  protected isColStateEqual(settingsA: DataGridSetting, settingsB: DataGridSetting) {
    return this.arrayOfObjectsMatch(settingsA.colsState ?? [], settingsB.colsState ?? [], ["width"]);
  }

  protected isFilterModelEqual(settingsA: DataGridSetting, settingsB: DataGridSetting) {
    return JSON.stringify(settingsA.filterModel ?? {}) == JSON.stringify(settingsB.filterModel ?? {});
  }

  protected async saveUserSettings(settings: DataGridSetting) {
    if (this.authService.currentUser) {
      this.authService.currentUser.settings.dataGridSettings[this.routeName] = settings;
      await this.datacontext.saveUserSettings(this.authService.currentUser);
    }
  }

  public arrayOfObjectsMatch(arr1: Array<Object>, arr2: Array<Object>, excludedProperties?: Array<string>) {
    return (arr1.length === arr2.length) && arr1.every((val, index) => {
      for (let key of Object.keys(val)) {
        if ((excludedProperties == null || !excludedProperties.includes(key)) && val[key] !== arr2[index][key]) {
          return false;
        }
      }
      return true;
    });
  }

  //#endregion

  private createServerSideDatasource() {
    const dataSource: IServerSideDatasource = {
      getRows: async (params: IServerSideGetRowsParams) => {
        let result = { rowData: null, rowCount: null };
        let shouldExpand = false;
        if (params.parentNode?.data?.id != null) { // Fetch by line
          let fetchResult;
          if ((!this.flattenList && !this.noFetchChildren && this.manuallyHandleFetchChildren) || this.flattenList) {
            fetchResult = await this.fetchChildren(params.parentNode.data.id);
          } else {
            fetchResult = { lines: this.dataLines.filter(x => x.parentId == params.parentNode.data.id), shouldExpand: false };
          }
          if (fetchResult?.lines != null) {
            result.rowData = fetchResult.lines;
            result.rowCount = fetchResult.lines.length;
            shouldExpand = fetchResult.shouldExpand;
            if (this.shouldExpand != null) shouldExpand = this.shouldExpand;
            UIInternal.broadcast(DATA_LOADED);
          } else {
            params.fail();
            return;
          }
        } else { // Initial fetch

          // Restore the filter model & colDefs if needed (needed when switing display mode from/to Tree/Flat)
          if (this.flattenListParamsBackup.mustRestore) {
            this.flattenListParamsBackup.mustRestore = false;
            this.gridOptions.api.setColumnDefs(this.flattenListParamsBackup.colDef);
            this.gridOptions.api.setFilterModel(this.flattenListParamsBackup.filterModel);
            params.request.filterModel = this.flattenListParamsBackup.filterModel;
          }

          shouldExpand = await this.fetchData(params.request.filterModel);
          if (this.shouldExpand != null) shouldExpand = this.shouldExpand;
          if ((!this.flattenList && !this.noFetchChildren && this.manuallyHandleFetchChildren) || this.flattenList) {
            result.rowData = this.dataLines;
            result.rowCount = this.dataLines.length;
          }
          else {
            let level1DataLine = this.dataLines.filter(x => x.lineLevel == 1)
            result.rowData = level1DataLine;
            result.rowCount = level1DataLine.length;
          }

          if (this.bottomRowRenderer != null && (result.rowCount > 0)) {
            const cloneLastRow = JSON.parse(JSON.stringify(result.rowData[result.rowCount - 1]));
            cloneLastRow.uniqueId = 0;
            cloneLastRow.id = 0;
            cloneLastRow.isBottomRow = true;
            result.rowData.push(cloneLastRow);
          }
          UIInternal.broadcast(DATA_LOADED);
        }
        params.success(result);

        // if (shouldExpand === true) {
        //   this.gridOptions.api.expandAll();
        // }

        // When refreshServerSideRows() is called, when must redraw/fetchData of some rows.
        if (this.nodesToRedraw.length > 0) {
          let nodes = [];
          for (let i = 0; i < this.nodesToRedraw.length; i++) {
            let nodeId = this.nodesToRedraw.splice(i, 1)?.[0];
            if (nodeId == null) continue;

            let node = this.gridOptions.api.getRowNode(nodeId.toString());
            if (node == null || node.data == null) continue;

            //if (node.data.parentId == null) {
            // Base node, fetch data then reset data of node and redraw it.
            let nodeData = await this.api.fetch(parseInt(this.parentId.toString()), [nodeId], this.displayHiddenLine, this.refToDisplay);
            if (nodeData?.[0] != null) node.setData(nodeData[0]);
            //}
            nodes.push(node);
          }

          setTimeout(() => {// Wait for grid rendering and animation finished before redrawing rows
            this.gridOptions.api.redrawRows({ rowNodes: nodes });
            this.resizeAgCellLastLeftPinned();
          }, 100);
        }

        // if (this.nodesToRefresh?.length > 0) {
        //   console.log('nodesToRefresh', this.nodesToRefresh);
        //   await this.refreshVisibleNodes(this.nodesToRefresh);
        //   this.nodesToRefresh = [];
        // }

        if (this.cellsToRefresh?.length > 0) {
          for (let ctr of this.cellsToRefresh) {
            let i = this.cellsToRefresh.findIndex(c => c.node.id == ctr.node.id);
            if (i > -1) this.cellsToRefresh.splice(i, 1);
            ctr.node.setData(ctr.node.data);
          }
        }
        if (!shouldExpand) {// If not expand all, expand some nodes
          this.expandedNodes
            .filter(nid => result.rowData.some(rd => rd.id == nid))
            .forEach(te => { this.gridOptions.api.getRowNode(te.toString())?.setExpanded(true); });
        }

        this.computeRowToOpen(this.rowToOpen);

        if (this.meteringFocusCell?.rowId != null && this.meteringFocusCell?.colId != null) {
          let getRowNode = this.gridOptions.api.getRowNode(this.meteringFocusCell.rowId.toString());
          if (getRowNode != null) {
            this.gridOptions.api.setFocusedCell(getRowNode.rowIndex, this.meteringFocusCell.colId);
            this.gridOptions.api.ensureIndexVisible(getRowNode.rowIndex, null);
            this.meteringFocusCell.rowId = null;
          }
        }
      },
    };

    return dataSource;
  }

  public computeRowToOpen(rowToOpen: number[]) {
    if (rowToOpen?.length > 0) {
      for (let row of rowToOpen) {
        let node = this.gridOptions.api.getRowNode(row.toString());
        if (node != null) {
          node.setExpanded(true);
          rowToOpen.remove(row);
        }
      }
    }
  }

  private async fetchData(filterParams: any = null): Promise<boolean> {
    if (!isNaN(parseInt(this.parentId.toString()))) {
      try {
        this.gridOptions.api.showLoadingOverlay();

        let displayMode = this.flattenList ? Constants.TreeDisplayMode.Flat : Constants.TreeDisplayMode.Tree;
        this.globalLoaderService.allow();
        let lines = await this.api.filter(parseInt(this.parentId.toString()), filterParams, this.quickFilter, displayMode, this.displayHiddenLine, this.refToDisplay);

        let filterOnColumns = Object.entries(filterParams).length > 0;
        if (displayMode == Constants.TreeDisplayMode.Tree && !this.noFetchChildren && !filterOnColumns && (this.quickFilter == null || this.quickFilter.trim().length <= 0)) {
          this.manuallyHandleFetchChildren = true;
          this.searchLinesResult = filterOnColumns ? null : lines;
          this.dataLines = lines.filter(l => l.lineLevel == 1);
          this.entitiesCount = lines.length;

          return true;
        } else {
          this.searchLinesResult = null;
          this.manuallyHandleFetchChildren = false;
          this.dataLines = lines;
          this.entitiesCount = this.dataLines.length;
          return false;
        }
      } catch (error) {
        this.dataLines = [];
        toastr.error("Error during getting data : " + error);
      } finally {
        this.gridOptions.api?.hideOverlay();

        if (typeof this.onDataLoaded == 'function') this.onDataLoaded();

        if (this.entitiesCount == 0 && this.gridOptions?.api != null) {
          this.gridOptions.api.showNoRowsOverlay();
        }
      }
    }
  }

  private async fetchChildren(id: number): Promise<{ lines: any[], shouldExpand: boolean }> {
    if (id != null) {
      let shouldExpand = false;
      if (this.manuallyHandleFetchChildren && this.searchLinesResult != null) {
        let lines = this.searchLinesResult.filter((l: any) => l.parentId == id);
        if (lines.length < 1) {
          lines = await this.api.children(parseInt(this.parentId.toString()), id, this.displayHiddenLine, this.refToDisplay);
        }
        else {
          shouldExpand = true;
        }
        return { lines, shouldExpand };
      } else {
        let lines = await this.api.children(parseInt(this.parentId.toString()), id, this.displayHiddenLine, this.refToDisplay);
        return { lines, shouldExpand };
      }
    }
    return null;
  }

  public async deactivate() {
    if (this.canUseView) {
      await this.saveGridColumnsState();
    }
    window.removeEventListener('keydown', this.eventKey, true);
  }

  public async onCellValueChanged(event: CellValueChangedEvent) {
    await this.specificOnCellValueChanged(event);
  }

  switchCondensed() {
    this.condensed = !this.condensed;
    this.gridOptions.api.refreshServerSide({ purge: true });
  }

  /**
   * Custom valueSetter for FieldType.Enumeration or FieldType.OneToMany,
   * needed to manage the Undo/Redo keyboard actions if a valueGetter is defined on the column definition.
   *
   * @private
   * @param {ValueSetterParams} params
   * @param {any[]} enumsList
   * @param {string[]} listFields
   * @return {*} 
   * @memberof PriceOfferLinesGrid
   */
  public static customEnumsValueSetter(params: ValueSetterParams, enumsList: any[], listFields: string[]) {
    if (typeof params.newValue == "string") {// Redo/Undo gives the string value
      if (params.newValue.trim().length == 0) {
        params.data[params.colDef.field] = null;
        return true;
      }
      let match = enumsList.find(enumItem => {
        let value = UIInternal.getValue(enumItem, listFields.join('.'));
        if (value != undefined) {
          return value == params.newValue;
        }
        return false;
      });
      if (match != null) {
        params.data[params.colDef.field] = match.id;
      }
    } else { // Set value by hand
      params.data[params.colDef.field] = params.newValue;
    }
    return true;
  }

  setSettingMenuItems() {
    let customSetting: IMenuItems[] = []
    if (this.customSettingMenuItems != null) {
      customSetting = this.customSettingMenuItems();
    }

    if (this.debugMode) {
      customSetting.push(
        {
          group: "debug",
          hiddenLabel: true,
          items: [
            {
              label: this.i18n.tr("metering.deactivateDebugMode"),
              hidden: !this.debugMode,
              icon: "digi-indeterminate-circle-line",
              handler: () => {
                this.debugMode = false;
                this.refreshServerSideRows(null, true, true);
                this.setSettingMenuItems();
              }
            },
            {
              label: this.i18n.tr("metering.forceRecompute"),
              hidden: !this.debugMode,
              icon: "digi-synchronization",
              handler: async () => {
                await this.recomputeLines();
              }
            }
          ]
        }
      );
    }
    else {
      customSetting.push({
        label: this.i18n.tr("metering.activateDebugMode"),
        hidden: this.debugMode,
        icon: "digi-bug-line",
        handler: () => {
          this.debugMode = true;
          this.refreshServerSideRows(null, true, true);
          this.setSettingMenuItems();
        }
      });
    }

    this.settingMenuItems = [
      ...customSetting
    ];
  }
  private handleShortcutsEvent = async (event: KeyboardEvent) => {
    await this.handleShortcuts.call(this, event);
  }
  protected async handleShortcuts(event: KeyboardEvent) {
    // if (((window.navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey) && event.key == "+")) {
    if (((event.ctrlKey || event.metaKey) && (event.key == "+" || (!window.navigator.platform.match("Mac") && event.keyCode == 187) || (window.navigator.platform.match("Mac") && event.keyCode == 191)))) {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.gridOptions.api.expandAll();
      return true;
    } else if (((event.ctrlKey || event.metaKey) && (event.key == "-" || (window.navigator.platform.match("Mac") && event.keyCode == 187)))) {
      event.stopImmediatePropagation();
      event.preventDefault();
      this.gridOptions.api.collapseAll();
      return true;
    }
    //for mac
    else if (((event.ctrlKey || event.metaKey) && event.key == "ArrowUp")) {
      event.stopImmediatePropagation();
      event.preventDefault();
      let firstRow = this.gridOptions.api.getFirstDisplayedRow();
      let node = this.gridOptions.api.getDisplayedRowAtIndex(firstRow);
      return true;
    }
    else if (((event.ctrlKey || event.metaKey) && event.key == "ArrowDown")) {
      event.stopImmediatePropagation();
      event.preventDefault();
      let lastRow = this.gridOptions.api.getLastDisplayedRow();
      let node = this.gridOptions.api.getDisplayedRowAtIndex(lastRow);
      return true;
    }
  }

  private handleKeyDown(e) {
    this.isPressingShift = e.key == "Shift";
  }

  private handleKeyUp() {
    this.isPressingShift = false
  }

  public shiftSelect(params) {
    if (this.isPressingShift && this.lastRowCellSelected != null) {
      const minIndex = Math.min(params.node.rowIndex, this.lastRowCellSelected.node.rowIndex);
      const maxIndex = Math.max(params.node.rowIndex, this.lastRowCellSelected.node.rowIndex);
      this.gridOptions.api.forEachNode(node => {
        if (this.lastRowCellSelected.node.rowIndex < params.node.rowIndex) { //Selection de haut en bas
          if (minIndex < node.rowIndex && node.rowIndex <= maxIndex)
            node.setSelected(this.lastRowCellSelected.node.isSelected());
        }
        else {
          if (minIndex <= node.rowIndex && node.rowIndex < maxIndex) //Selection de bas en hait
            node.setSelected(this.lastRowCellSelected.node.isSelected());
        }
      });
    }
    this.lastRowCellSelected = params;
  }

  public getPriceWithTooltipHtml(price, previousPrice, nbDecimalForPriceDisplay) {
    let html = "<div><span ";
    if (price) {
      if (previousPrice != null && price != previousPrice) {
        let tooltip = this.i18n.tr("metering.previousPrice") + new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: nbDecimalForPriceDisplay, maximumFractionDigits: nbDecimalForPriceDisplay }).format(previousPrice);
        html += `ui-tooltip="value:${tooltip}"`;
      }
      html += `>${(!isNaN(price) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: nbDecimalForPriceDisplay, maximumFractionDigits: nbDecimalForPriceDisplay }).format(price) : price)}</span>`;
    }
    html += '</div>';
    return html;
  }

}

export interface ColumnVisible {
  colId: string;
  visibility: boolean;
  overideHeaderValueGetter?: HeaderValueGetterFunc;
}

export const DATA_LOADED = "DATA_LOADED";
