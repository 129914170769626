import { autoinject, Container } from "aurelia-framework";/*  */
import { AuthService } from "digiwall-lib";
import { Merlin } from "generated";
import { NotificationApiService, NotificationDTO } from "services/notification-api-service";
import * as Constants from '../../constants';
import { I18N } from "aurelia-i18n";

@autoinject
export class NotificationTaskService {

  constructor(private notificationService: NotificationApiService, private authService: AuthService, private i18n: I18N) { }

  public async sendNotificationTaskAssignement(user: Merlin.Web.Model.MerlinUser, project: Merlin.Web.Model.Project, taskId: number, taskTitle: string) {
    let notifications: NotificationDTO[] = []
    notifications.push({
      title: project?.projectName ?? "",
      message: this.i18n.tr("taskjob.assignedTask", { lng: user.languageCode, taskName: taskTitle, userName: this.authService.currentUser.fullName }),
      notifiedById: this.authService.currentUser.id,
      notifiedUserId: user.id,
      urlRedirection: `/module-tasks?taskId=${taskId}`,
      notificationTypeId: Constants.NotificationTypeId.TaskResponsibleAssignement
    });
    await this.notificationService.createNotifications(...notifications)
  }

  public async sendNotificationTaskDone(notifiedUser: Merlin.Web.Model.MerlinUser, project: Merlin.Web.Model.Project, responsibleId: number, taskId: number) {
    let notifications: NotificationDTO[] = [];
    notifications.push({
      title: project?.projectName ?? "",
      message: this.i18n.tr("taskjob.taskIsDone", { lng: notifiedUser.languageCode }),
      notifiedById: responsibleId,
      notifiedUserId: notifiedUser.id,
      urlRedirection: `/module-tasks?taskId=${taskId}`,
      notificationTypeId: Constants.NotificationTypeId.TaskIsDone
    });

    await this.notificationService.createNotifications(...notifications)
  }
}
