@import "../css/scss/variables";
$border-color: $gray-lighter;
.progress-statement-column-order {
  .columns {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    > div.column {
      border-left: $border-color 1px solid;
      border-top: $border-color 1px solid;
      border-bottom: $border-color 1px solid;
      display: flex;
      flex-direction: column;
      align-items: center;
      .column-header {
        display: grid;
        height: 50px;
        width: 100%;
        grid-template-areas: "left order right";
        grid-template-columns: 50px 1fr 50px;
        justify-items: center;
        align-items: center;
        background-color: $purple-very-light;
        border-bottom: $border-color 1px solid;
        margin-bottom: 5px;
        .left-button {
          grid-area: left;
          margin: 5px;
          opacity: 0;
        }
        .order {
          grid-area: order;
          color: $blue-mid;
          margin: 0px 5px;
        }
        .right-button {
          grid-area: right;
          margin: 5px;
          opacity: 0;
        }
        &:hover {
          .right-button,
          .left-button {
            opacity: 100;
          }
        }
      }
      .column-select {
        padding: 5px;
        width: 100%;
        ui-field-wrapper {
          ui-select {
            border: none !important;
            background-color: transparent !important;
          }
          ui-input-addon {
            border-left: 1px solid $border-color;
            color: $red-warning;
            cursor: pointer;
          }
        }
      }
    }
    div.column-first {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      .column-header {
        border-top-left-radius: 5px;
      }
    }
    div.column-last {
      border-right: $border-color 1px solid;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      .column-header {
        border-top-right-radius: 5px;
      }
    }
    .add-button {
      height: 40px;
      width: 40px;
      margin-left: 20px;
    }
  }
}
