@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "scss/variables";

@import "../../node_modules/select2/src/scss/core";
@import "scss/_pagemenu";
@import "scss/_gallery";

$mdi-font-path: "fonts";
$mdi-font-size-base: 1em;

@import "../../node_modules/digiwall-lib/src/sass/digiwall-framework";
@import "../../node_modules/digiwall-lib/src/sass/flag-icons";
@import "scss/step-timeline";

.theme-light {
  @import "../../node_modules/digiwall-lib/src/sass/light-theme";
  @import "../../node_modules/digiwall-lib/src/sass/theme/select2";
}

@import "scss/_general";

@import "scss/_horizontal-menu";
@import "scss/_screen-list";
@import "scss/_floating-filter";
@import "scss/_ui-panel";
@import "scss/_list-filter";
// @import "scss/_import-metering";
@import "scss/_ux-dialog";
@import "scss/_project";
@import "scss/_forms";
@import "scss/_ui-files";
@import "scss/_ui-table";
@import "scss/_button";
@import "scss/_view-aside";
@import "scss/_navigation-detail2";
@import "scss/summary";
