.theme-light {
  .ui-panel {
    box-shadow: none;
    &__body {
      padding: 0;
      padding-top: 2rem;
    }
    &__header {
      border-bottom: $gray-lighter 1px solid;
      padding-bottom: 10px;
    }
    &:hover {
      box-shadow: none;
    }
  }
  .ui-header {
    &::after {
      border: none;
    }

    &__title {
      font-size: 30px;
      font-weight: 300;
      padding: 0;
      padding-top: 0.66667rem;
      margin-right: 0.75rem;
    }
  }
}
