@import "../css/scss/variables";

body.ux-dialog-open ux-dialog-container.container-add-request-supplier-offer > div > div > compose > ux-dialog {
  border-radius: $radius;
  background-color: $white !important;
  border: 5px solid $blue-darkest;
  border-left: 0px;
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
  ux-dialog-header {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    padding: 0 50px;
    padding-top: 40px;
    padding-bottom: 20px;
    > .dialog-header-content {
      display: flex;
      justify-content: space-between;
      > div {
        font-weight: 300;
        font-size: 40px;
        line-height: 58px;
      }
      > ui-button {
        height: 34px;
      }
    }
  }
  ux-dialog-footer {
    // padding: 0px 50px;
    // height: 64px;
    // display: flex;
    // flex-direction: row-reverse;
    // align-items: center;
    // justify-content: end;
    // background-color: $background-footer;
    // border-bottom-left-radius: $radius;
    // border-bottom-right-radius: $radius;
    // box-shadow: none;
    // gap: 20px;
  }
}
.floating-box {
  z-index: 1001 !important;
}
.overlay-floating-box {
  z-index: 1001 !important;
}

.supplier-offer {
  &__title {
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
    padding: 50px 0 15px 0;
  }
  &__step4 {
    &__content {
      display: flex;
      align-items: center;
      flex-direction: column;
      ui-panel {
        width: 100%;
        padding: 0;
      }
    }

    // ui-panel {
    //   width: 50%;
    // }
  }
  &__step5 {
    &__content {
      .horizontal-margin {
        width: 70%;
        margin: 0 auto;
        .row > div {
          .ui-field {
            padding-bottom: 15px;
            display: grid;
            grid-template-columns: 100px auto;
            .ui-field__label {
              color: $gray-mid;
              position: initial;
              //width: 10%;
              > span {
                color: $gray-mid;
              }
            }
            .ui-input--readonly {
              background-color: transparent;
              //border-bottom: none !important;
            }
            .fake-input {
              border-bottom: solid 1px $gray-lighter;
              padding-bottom: 8px;
              margin-left: 0;
              display: flex;
              flex: 1rem;
              .supplier-offer__step5__content__person-tag {
                width: fit-content;
                margin-left: 0.5rem;
              }
            }
            .ui-select,
            .ui-input {
              border: none;
              border-bottom: solid 1px $gray-lighter;
              border-radius: 0;
              &--focus {
                border-color: $gray-very-light !important;
              }
              /*> .select2 > .selection > span > .select2-selection__clear {
                display: none;
              }*/
            }
          }
          .info-readonly {
            display: block;
            padding-bottom: 0;
            .ui-field__label {
              color: $blue-mid;
              > span {
                color: $blue-mid;
              }
            }
            .ui-input--readonly {
              border: none !important;
              //border-bottom: solid 1px $gray-very-light !important;
              color: $black-100;
              > input {
                color: $black-100 !important;
              }
            }
          }
        }
        .row {
          &:nth-child(2) {
            > div {
              .ui-field > .ui-select {
                > .select2 > .selection > span > .select2-selection__clear {
                  display: none;
                }
              }
            }
          }
        }
        > .row > div > .info-readonly {
          display: block;
          padding: 0;
          margin-left: 46px;
          margin-bottom: 15px;
          border-bottom: solid 1px $gray-very-light;
          > .ui-field__label,
          .ui-input--readonly {
            padding: 0;
          }
          > .ui-field__label {
            > span {
              color: $blue-mid;
            }
          }
          > .ui-input--readonly > input {
            font-weight: 600;
          }
        }
        .row > .supplier-offer__step5__content__message {
          .ui-field {
            align-items: flex-start;
            .ui-field__label {
              margin-top: 16px;
            }
          }
        }
        > .row > div > .supplier-offer__step5__content__readonly-to-from {
          display: flex;
          > avatar {
            margin-right: 10px;
            height: 36px;
            > img {
              width: 36px;
              height: 36px;
            }
          }
          > .supplier-offer__step5__content__readonly-to-from__name {
            margin-top: 5px;
            border-bottom: solid 1px $gray-very-light;
            padding-bottom: 10px;
            width: 100%;
            margin-bottom: 5px;
            > span {
              font-size: 17px;
            }
            > .supplier-offer__step5__content__at {
              display: flex;
              > span,
              div {
                font-size: 13px;
                color: $gray-mid;
              }
              > span {
                margin-right: 5px;
              }
            }
          }
        }
      }
      &__person-select {
        display: flex;
        align-items: center;
        > avatar {
          height: 30px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        > div {
          display: grid;
          margin-left: 8px;
          .supplier-offer__step5__content__person-select {
            &__name,
            &__email {
              line-height: initial;
            }
            &__name {
              font-weight: 600;
            }
          }
        }
      }
      &__file {
        margin-left: 10%;
      }
      &__file,
      &__file__readonly {
        background-color: $gray-lightest;
        border-radius: 8px;
        padding: 10px;
        max-width: 380px;
        display: grid;
        grid-template-columns: auto auto 1fr;
        align-items: center;
        &__readonly {
          margin-left: 57px;
        }
        > .supplier-offer__step5__file__name {
          color: $blue-mid;
          font-weight: 400;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin: 0;
        }
        > span {
          font-weight: 600;
          margin: 0 10px;
        }
        > ui-button > .ui-btn__inner > .ui-btn {
          .ui-btn__icon {
            padding: 0;
          }
          .ui-icon {
            font-size: 14px;
          }
        }
      }
      &__files-box {
        z-index: 1500;
      }
      &__person-tag {
        display: flex;
        align-items: center;
        border: solid 1px $gray-lighter;
        border-radius: 60px;
        padding: 2px;
        > avatar {
          height: 24px;
          > img {
            width: 24px;
            height: 24px;
          }
        }
        > span {
          font-size: 16px;
          padding-left: 8px;
          color: $black-100;
        }
        > span:last-child {
          padding-left: 4px;
          padding-right: 15px;
        }
        > .ui-btn__wrapper {
          margin-left: 15px;
          .ui-btn__inner {
            box-shadow: none !important;
            > .ui-btn {
              background-color: transparent;
              box-shadow: none;
              &:hover {
                background-color: transparent;
              }
              > .ui-btn__icon {
                padding: 0;
                padding-right: 5px;
                > .ui-icon {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
      &__person-to {
        padding: 0px 2px;
        gap: 2px;
        display: flex;
      }
    }
  }
}

add-request-supplier-offer-step-1,
add-request-supplier-offer-step-2,
add-request-supplier-offer-step-3 {
  flex: 1;
}

.theme-light {
  ux-dialog > ux-dialog-body {
    background-color: transparent;
    display: flex;
    flex-direction: column;
  }
}

ui-page.add-request-supplier-offer {
  // > .ui-page__footer {
  //   padding: 20px 20px;
  //   background-color: $background-footer;
  //   > div {
  //     display: flex;
  //     gap: 10px;
  //     flex-direction: row-reverse;
  //   }
  // }
}

.add-request-supplier-offer-body {
  padding: 0 50px !important;
  > add-request-supplier-offer-step-1 > price-offer-lines-grid {
    flex: 1;
    margin-left: -40px;
    .grid-header-bar {
      margin: 0;
      > .grid-header-bar__btn > .between-header-grid > .filter-slot {
        margin-left: 0 !important;
      }
    }
    ag-grid-aurelia.datagrid.ag-theme-material {
      padding-right: 0px;
    }
  }
  > add-request-supplier-offer-step-2 > .ui-page > .ui-page__body > ui-content > custom-ag-grid {
    .grid-header-bar {
      margin: 0;
    }
    filter.between-header-grid .filter-slot {
      margin-left: 0;
      margin-right: 0;
      .filter-reset > ui-button > .ui-btn__inner {
        padding-left: 0;
      }
    }
    ag-grid-aurelia {
      padding-right: 0;
    }
    > div > .ag-theme-material > .ag-root-wrapper > .ag-root-wrapper-body > .ag-root > .ag-body-viewport {
      .ag-pinned-left-cols-container {
        > .ag-row {
          border: solid 1px $gray-very-light !important;
          border-right: solid 1px $gray-very-light !important;
          border-left: none !important;
          border-top: none !important;
          .ag-cell {
            border-left: solid 1px $gray-very-light !important;
            height: 40px;
            border-bottom: solid 1px $gray-very-light !important;
          }
        }
        > .ag-row-first > .ag-cell-last-left-pinned {
          border-top: solid 1px $gray-very-light !important;
        }
        > .ag-row-last {
          border-bottom: none !important;
        }
      }
      .ag-center-cols-clipper > .ag-center-cols-viewport > .ag-center-cols-container > .ag-row-first > .ag-cell,
      .ag-center-cols-clipper > .ag-center-cols-viewport > .ag-center-cols-container > .ag-row-last > .ag-cell {
        &:last-child {
          border-right: none !important;
        }
      }
    }
  }
  > add-request-supplier-offer-step-3 > div > ui-table {
    .ui-table.ui-table-with-border > tbody {
      tr {
        &:nth-last-child(2) {
          .ui-table__cell:first-child {
            border-bottom: solid 1px $gray-very-light !important;
          }
        }
        .ui-table__cell:first-child {
          border: solid 1px $gray-very-light !important;
          border-right: none !important;
          border-bottom: none !important;
        }
      }
    }
  }
}

.add-request-supplier-offer-footer {
  padding: 0px 50px;
  height: 64px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: end;
  background-color: $background-footer;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  box-shadow: none;
  gap: 20px;
}

add-request-supplier-offer-step-2 custom-ag-grid {
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-header-bar + summary-filter + div {
    height: 100%;
  }
}
