import { autoinject } from 'aurelia-framework';
import { AuthService } from 'digiwall-lib';

@autoinject
export class Callback {
  constructor(private auth: AuthService) {
    
  }
  attached() {
    this.auth.handleAuthentication();
  }
}
