import { autoinject, bindingMode } from "aurelia-framework";
import { customElement, bindable } from "aurelia-templating";

@customElement("ui-rating")
@autoinject
export class UIFooterPage {
  @bindable()
  public rating: number;

  constructor() {

  }

  handleClick(event) {
    let radio = event.currentTarget.previousElementSibling;
    if (parseInt(radio.attributes['model.one-time'].value) == this.rating) {
      this.rating = null;
    }
    else {
      return true
    }
  }
}
