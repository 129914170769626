import { I18N } from 'aurelia-i18n';

import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import { createOverrideContext, Container, ViewResources, autoinject } from 'aurelia-framework';
import { View, ViewCompiler, ViewSlot } from "aurelia-templating";
import { Box, CustomLogger, IMenuItem, PictureHelper } from 'digiwall-lib';
import { EditPriceOfferLineFile } from 'price-offer-line-files/edit-price-offer-line-file';
import { DialogService } from 'aurelia-dialog';

@autoinject
export default class MeteringPriceOfferLineFileRenderer implements ICellRendererComp {
  protected eGui: HTMLElement;

  protected view: View;
  protected viewSlot: ViewSlot;
  protected params: ICellRendererParams;
  public i18n: I18N;

  constructor() { }

  public init(params: ICellRendererParams) {
    this.params = params;
    this.setupGui();
  }

  public setupGui() {
    this.eGui = document.createElement('div');
    this.eGui.style.width = '100%';
    this.eGui.style.height = '100%';

    let content: string = this.setupContentGui();
    let html: string = `<template>${content}</template>`;

    this.i18n = Container.instance.get(I18N);
    let viewCompiler = Container.instance.get(ViewCompiler);
    let viewResources = Container.instance.get(ViewResources);
    let viewFactory = viewCompiler.compile(html, viewResources);
    this.view = viewFactory.create(Container.instance);
    this.viewSlot = new ViewSlot(this.eGui, true);
    this.viewSlot.add(this.view);
    this.view.bind(this, createOverrideContext(this));
    this.viewSlot.attached();
  }


  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params): boolean {
    return true;
  }

  public destroy() {
    if (this.view) this.view.unbind();
    if (this.viewSlot) this.viewSlot.detached();
  }

  public getValueToDisplay() {
    return this.params.valueFormatted ? this.params.valueFormatted : this.params.value;
  }

  public setupContentGui(): string {
    if (this.params?.value > 0) {
      return `
      <div >
        <ui-button icon="digi-file-3-line" type="tool" class='metering-btn-file' click.delegate="viewPriceOfferLineFile($this.params.data.id)" ui-badge.bind="$this.params.value">
        </ui-button>
      </div>`;
    }
    return '';
  }
  async viewPriceOfferLineFile(id: number) {
    if (this.params?.data?.id == null) return;
    let box = Container.instance.get(Box);
    let fileHelper = Container.instance.get(PictureHelper);
    let view = new EditPriceOfferLineFile(this.params.data.id, (this.params as any).colDef.cellRendererParams.readonlyDocument, this.i18n, fileHelper, (this.params as any).projectId, (this.params as any).priceOfferVersionId, (this.params as any).workQuotationVersionId);
    await box.showFloatingBox(view, false, false).whenClosed(() => {
      this.refreshRowData(this.params);
    });
  }

  public refreshRowData(params: any) {
    if (params.colDef?.cellRendererParams?.refreshRowData != null && typeof params.colDef?.cellRendererParams?.refreshRowData == "function") {
      params.colDef.cellRendererParams.refreshRowData(params);
    }
  }

}
