import { DataFormat } from 'select2';
import { BindingEngine, observable } from 'aurelia-framework';
import { Disposable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { HttpClient } from 'aurelia-fetch-client';
import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { FileFlow, UIFilesColumnInfo, EnumerationTypeService, EnumerationType, Language, AuthService, StepElement, ServiceBase } from 'digiwall-lib';
import { DocumentData, IDocumentService } from './document-service';
import * as Constants from '../constants';
import { Shared, Merlin } from 'generated';


@autoinject
export class AddDocument {
  title: string = this.i18n.tr("document.addDocuments")
  files: Array<DocumentData> = [];

  defaultLanguageId: number;
  folderName: string;
  service: IDocumentService;
  typeService: EnumerationTypeService;
  languageService: EnumerationTypeService;
  documentStatusService: EnumerationTypeService;
  tagService: EnumerationTypeService;
  thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>

  documentModuleId: number
  documentStatusId: number = Constants.DocumentStatusId.Definitive;
  fileTypeId: number

  @observable
  source: DataFormat

  fileIndex: number;
  currentFile: DocumentData;
  allLanguages: Array<EnumerationType>;
  allFileTypes: Array<EnumerationType>;
  allDocumentStatus: Array<EnumerationType>;
  disposables: Array<Disposable> = [];

  private isSaving: boolean = false

  public stepElements: Array<StepElement> = [
    {
      id: "add",
      step: 1,
      label: this.i18n.tr("document.selection")
    },
    {
      id: "documents",
      step: 2,
      label: this.i18n.tr("document.description")
    },
  ] as Array<StepElement>;
  public currentElement: StepElement;
  isLastFile: boolean;
  error: string = null;
  errorType: string = null;
  errorName: string = null;

  constructor(private controller: DialogController, private httpClient: HttpClient, private i18n: I18N, private bindingEngine: BindingEngine, private authService: AuthService) {
    this.typeService = new EnumerationTypeService(Constants.EnumerationTypes.FileType);
    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);
    this.tagService = new EnumerationTypeService(Constants.EnumerationTypes.DocumentTag);
    this.documentStatusService = new EnumerationTypeService(Constants.EnumerationTypes.DocumentStatus);
    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
  }

  async activate(params) {
    this.service = params.service;
    this.defaultLanguageId = params.defaultLanguageId ?? this.authService.currentUser.userData.languageId;
    this.folderName = params.folderName ?? "";
    if (this.service == null) {
      console.error("Service is required")
      this.controller.cancel();
    }

    if (params.documentModuleId) {
      this.documentModuleId = params.documentModuleId;
    }
    if (params.fileTypeId) {
      this.fileTypeId = params.fileTypeId
    }
    if (params.documentStatusId) {
      this.documentStatusId = params.documentStatusId;
    }

    let current = this.stepElements.find(x => x.step == 1);
    current.isActif = true;
    this.currentElement = current;
    this.allLanguages = await this.languageService.getAll();
    this.allFileTypes = await this.typeService.getAll();
    this.allDocumentStatus = await this.documentStatusService.getAll();
  }

  private columnsInfo: Array<UIFilesColumnInfo> = [
    {
      columnName: "fileTypeId",
      columnVisibility: false,
      width: 2
    },
    {
      columnName: "createdTime",
      columnVisibility: false,
      width: 1
    },
    {
      columnName: "description",
      columnVisibility: false,
      width: 1
    }
  ];

  public async uploadFile(file: FileFlow) {
    //No action here, all action is in uploadedFile
  }

  public async uploadedFile(fileUploaded: FileFlow) {
    let definitiveStatus: EnumerationType = await this.documentStatusService.getEntityById(this.documentStatusId)
    let fileType = null;
    if (this.fileTypeId != null) {
      fileType = await this.typeService.getEntityById(this.fileTypeId);
    }
    let fileEntity: DocumentData = {
      id: null,
      url: fileUploaded.url,
      name: fileUploaded.name,
      description: null,
      size: fileUploaded.size,
      fileTypeId: this.fileTypeId ?? null,
      fileType: (fileType != null ? {
        id: fileType.id,
        name: fileType.denomination._translation,
        textColor: fileType.textColor,
        backgroundColor: fileType.backgroundColor
      } : null),
      languageId: this.defaultLanguageId,
      language: null,
      documentTags: new Array(),
      documentModuleId: this.documentModuleId,
      documentModule: null,
      documentName: fileUploaded.name.substring(0, fileUploaded.name.lastIndexOf(".")),
      documentStatusId: definitiveStatus.id,
      documentStatus: {
        id: definitiveStatus.id,
        name: definitiveStatus.denomination._translation,
        textColor: definitiveStatus.textColor,
        backgroundColor: definitiveStatus.backgroundColor
      },
      receptionDate: new Date(),
      sourceId: null,
      toUseAsAnnex: null
    }
    this.setLanguage(fileEntity);
    this.disposables.push(
      this.bindingEngine.propertyObserver(fileEntity, 'languageId').subscribe((newValue, oldValue) => {
        if (newValue != oldValue) {
          this.setLanguage(fileEntity);
        }
      }),
      this.bindingEngine.propertyObserver(fileEntity, 'fileTypeId').subscribe((newValue, oldValue) => {
        if (newValue != oldValue) {
          this.setFileType(fileEntity);
        }
      }),
      this.bindingEngine.propertyObserver(fileEntity, 'documentStatusId').subscribe((newValue, oldValue) => {
        if (newValue != oldValue) {
          this.setDocumentStatus(fileEntity);
        }
      })
    )
    this.files.push(fileEntity);
  }

  private setLanguage(fileEntity: DocumentData) {
    let language = this.allLanguages.find(x => x.id == fileEntity.languageId);
    fileEntity.language = {
      id: language?.id,
      name: language?.denomination._translation,
      textColor: language?.textColor,
      backgroundColor: language?.backgroundColor
    } as Shared.Model.EnumerationDTO
  }

  private setFileType(fileEntity: DocumentData) {
    let fileType = this.allFileTypes.find(x => x.id == fileEntity.fileTypeId);
    fileEntity.fileType = {
      id: fileType?.id,
      name: fileType?.denomination._translation,
      textColor: fileType?.textColor,
      backgroundColor: fileType?.backgroundColor
    } as Shared.Model.EnumerationDTO
  }

  private setDocumentStatus(fileEntity: DocumentData) {
    let status = this.allDocumentStatus.find(x => x.id == fileEntity.documentStatusId);
    fileEntity.documentStatus = {
      id: status?.id,
      name: status?.denomination._translation,
      textColor: status?.textColor,
      backgroundColor: status?.backgroundColor
    } as Shared.Model.EnumerationDTO
  }

  private sourceChanged() {
    if (this.source != null) {
      if (isNumber(this.source)) {
        this.currentFile.sourceId = this.source;
      }
      else {
        this.currentFile.sourceId = parseInt(this.source.id.toString());
      }
    }
  }

  async save() {
    if (!this.hasError()) {
      this.isSaving = true;
      await this.service.createDocument(this.files);
      this.controller.ok();
    }
  }

  private hasError(): boolean {
    this.errorType = null;
    this.errorName = null
    if (this.currentFile.fileTypeId == null) {
      this.errorType = this.i18n.tr("document.fileTypeError");
    }
    if (this.currentFile.documentName == null || this.currentFile.documentName.trim().length == 0) {
      this.errorName = this.i18n.tr("document.documentNameError");
    }
    return this.errorType != null || this.errorName != null
  }

  nextFile() {
    if (!this.hasError()) {
      this.fileIndex = this.fileIndex + 1;
      this.setCurrentFile();
    }
  }

  prevFile() {
    this.fileIndex = this.fileIndex - 1;
    this.setCurrentFile();
  }

  confirme() {
    this.error = null
    if (this.files.length > 0) {
      let newIndex = this.stepElements.findIndex(x => x == this.currentElement) + 1;
      this.currentElement.isValidStep = true;
      let newCurrentElement = this.stepElements[newIndex]
      if (newCurrentElement) {
        this.currentElement = newCurrentElement;
        this.currentElement.isActif = true;
      }

      this.fileIndex = 0;
      this.setCurrentFile();
    }
    else {
      this.error = this.i18n.tr("document.fileRequired")
    }
  }

  setCurrentFile() {
    this.currentFile = this.files[this.fileIndex];
    this.isLastFile = this.fileIndex == (this.files.length - 1);
    (this.source as any) = this.currentFile.sourceId
  }

  detached() {
    this.disposables.forEach(x => x.dispose());
  }
}
