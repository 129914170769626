import * as Constants from '../constants';
import { DialogController, DialogService } from "aurelia-dialog";
import { autoinject, BindingEngine, computedFrom, Disposable, observable, TaskQueue } from "aurelia-framework";
import { bindable, customElement } from "aurelia-templating";
import { Predicate, FilterQueryOp } from "breeze-client";
import { AuthService, Box, EnumerationTypeService, GlobalLoaderService, IFile, IMenuGroup, PictureHelper, ServiceBase, UIInternal } from "digiwall-lib";
import { Merlin } from "generated";
import { DataFormat } from "select2";
import { AddNotificationSentFile } from './email-sender-file';
import { I18N } from 'aurelia-i18n';
import { EmailTemplateApiService } from 'services/email-template-api-service';
import { HttpClient } from 'aurelia-fetch-client';
import Tribute, { TributeItem } from "tributejs";
import { CustomThirdPartiesSourceService } from 'services/custom-third-party-source-service';
import { DocumentList } from 'documents/document-list';

@autoinject
@customElement('email-sender')
export class EmailSender {
  @bindable public isReadonly: boolean = false;
  @bindable public emailContext: number;
  @bindable public disableTo: boolean = false;
  @bindable public notifLang: Map<number, NotificationLanguageDTO> = new Map<number, NotificationLanguageDTO>();
  @bindable public selectedReceiversIds: Array<number> = new Array();
  @bindable public companyIds: Array<number> = new Array();
  @bindable public files: Array<IFile>;
  @bindable public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest;
  @bindable public projectId: number;

  private showQuill = true;

  private title = this.i18n.tr('notificationsent.notificationsent');

  private inputcc: HTMLElement;
  private get inputccValues() {
    let emails: string[] = [];
    if (this.notifLang != null)
      Array.from(this.notifLang.values()).forEach(nl => emails = emails.concat(nl.cc?.split(";").map(s => s.trim())))
    return emails;
  };
  private inputccTributeInstance: Tribute<any>;

  private inputSubject: HTMLElement;
  private inputSubjectTributeInstance: Tribute<any>;

  public languages: Array<any>;
  public emailTemplate: Merlin.Web.Model.EmailTemplateMerlin;
  public languageMenuItems: Array<IMenuGroup>;
  public disposables: Array<Disposable> = [];
  public toSelector: HTMLElement;

  public allPossibleReceivers: Array<Merlin.Web.Model.ThirdParty> = [];
  public selectedSender: Merlin.Web.Model.MerlinUser = null;
  private allPossibleSender: Array<Merlin.Web.Model.MerlinUser> = [];

  public merlinUserService: ServiceBase<Merlin.Web.Model.MerlinUser>;
  public emailTemplateService: ServiceBase<Merlin.Web.Model.EmailTemplateMerlin>;
  public notificationSentService: ServiceBase<Merlin.Web.Model.NotificationSent>;
  public notificationFileService: ServiceBase<Merlin.Web.Model.NotificationSentFile>;
  public emailTypeService: ServiceBase<Merlin.Web.Model.EmailType>;
  public languageService: EnumerationTypeService;
  public thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  public canChangeLang: boolean = false;
  private emailTemplateApiService: EmailTemplateApiService;
  private customProjectInternalThirdPartiesService: CustomThirdPartiesSourceService;

  @observable public selectedLanguageId: number;
  @observable public emailTypeSelected: DataFormat = { id: "", text: "" }
  @observable public selectedSenderId;


  //Check if service are loaded
  serviceLoadInProcess: boolean = true;
  loadingFile: boolean = false;

  constructor(private dialogService: DialogService, private authService: AuthService, private pictureHelper: PictureHelper, private dialogController: DialogController, private i18n: I18N, private bindingEngine: BindingEngine, httpClient: HttpClient, private box: Box, private gls: GlobalLoaderService) {
    this.merlinUserService = new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser);
    this.merlinUserService.gridDataSource.predicates = new Predicate('isSystemUser', FilterQueryOp.Equals, 'false');

    this.emailTemplateService = new ServiceBase<Merlin.Web.Model.EmailTemplateMerlin>(Constants.EntityTypeNames.EmailTemplateMerlin);
    this.emailTemplateService.gridDataSource.expands = ['emailTemplate.emailTemplateFiles'];

    this.emailTypeService = new ServiceBase<Merlin.Web.Model.EmailType>(Constants.EntityTypeNames.EmailType);

    this.notificationFileService = new ServiceBase<Merlin.Web.Model.NotificationSentFile>(Constants.EntityTypeNames.NotificationSentFile);
    this.notificationSentService = new ServiceBase<Merlin.Web.Model.NotificationSent>(Constants.EntityTypeNames.NotificationSent);

    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);

    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);

    this.emailTemplateApiService = new EmailTemplateApiService(httpClient);
  }

  bind() {
    this.serviceLoadInProcess = true;
  }

  async attached() {
    if (this.previewRequestParams != null) {
      if (this.emailContext != null && this.previewRequestParams.emailContextId == null) {
        this.previewRequestParams.emailContextId = this.emailContext;
      }
      this.emailTemplateApiService.previewRequestParams = this.previewRequestParams;
      if (this.selectedReceiversIds.length > 0) {
        this.previewRequestParams.toThirdPartyId = this.selectedReceiversIds[0];
      }
    }

    this.gls.allow(null, 0);
    Promise.all([this.init()]);
    this.disposables.push(this.bindingEngine.collectionObserver(this.selectedReceiversIds).subscribe((records) => {
      this.initSpokenLanguage();
      UIInternal.queueTask(() => {
        this.cleanCCValues();
        this.setAvatarToReceiver();
      });
      if (this.previewRequestParams != null && this.selectedReceiversIds.length > 0) {
        this.previewRequestParams.toThirdPartyId = this.selectedReceiversIds[0];
      }
    }))
  }

  private cleanCCValues() {
    let emails = this.allPossibleReceivers.filter(r => this.selectedReceiversIds.map(i => i.toString()).includes(r.id.toString())).map(r => r.emailAddress);
    Array.from(this.notifLang.entries()).forEach(nl => {
      let ccEmails = nl[1].cc?.split(';').map(x => x.trim());
      ccEmails?.forEach(m => {
        if (emails.includes(m)) {
          nl[1].cc = nl[1].cc.replace(m + ';', '');
        }
      });
    })
  }

  deactivate() {
    this.disposables.forEach(d => d.dispose());
  }

  async init() {
    if (this.serviceLoadInProcess) {
      this.allPossibleReceivers.splice(0);
      if (this.companyIds != null && this.companyIds.length > 0) {
        let tempList: Merlin.Web.Model.ThirdParty[] = [];
        let arrayOfPromise = [] as Promise<void>[];
        for (const companyId of this.companyIds) {
          arrayOfPromise.push(this.getThirdParty(companyId, tempList));
        }
        await Promise.all(arrayOfPromise);
        this.allPossibleReceivers.push(...(tempList.sort((a, b) => {
          return a.fullName.localeCompare(b.fullName);
        })));
      }
      else {
        this.allPossibleReceivers.push(...(await this.thirdPartyService.getEntities(null, null, { emailNotNull: true })).sort((a, b) => {
          return a.fullName.localeCompare(b.fullName);
        }));
      }

      this.emailTypeService.gridDataSource.expands = ['type'];
      this.emailTypeService.gridDataSource.queryParameters = { emailContextId: this.emailContext, onlyActive: true };

      this.languages = await this.languageService.getEntities(null, null, { category: Constants.EnumerationTypes.Language });
      this.initSpokenLanguage();
      let emailType = await this.emailTypeService.getEntities(null, this.emailTypeService.gridDataSource.expands, this.emailTypeService.gridDataSource.queryParameters);
      if (emailType.length == 1) {
        this.emailTypeSelected = { id: emailType[0].id, text: emailType[0].type.denomination._translation }
        await this.getTemplate(this.emailTypeSelected.id as number, this.selectedLanguageId)
      }

      this.allPossibleSender = await this.merlinUserService.getAllEntities();

      if (this.selectedSenderId == null) {
        this.selectedSenderId = this.authService.currentUser.userData.id;
      }
      this.selectedSender = await this.merlinUserService.getEntityById(this.selectedSenderId);

      let firstNotification = this.notifLang.values().next().value as NotificationLanguageDTO;

      if (firstNotification != null && firstNotification.templateId != null) {
        let template = await this.emailTemplateService.getEntityById(firstNotification.templateId, 'emailType');
        this.emailTypeSelected.id = template.emailTypeId.toString();
        this.emailTypeSelected.text = template.emailType.type.denomination._translation;
      }

      this.serviceLoadInProcess = false;
      UIInternal.queueTask(() => {
        this.configCustomProjectInternalThirdPartiesService();
        this.setAvatarToReceiver();
        this.initInputCCMentions();
        this.initInputSubject();
      });
    }
  }

  private async getThirdParty(companyId: number, tempList: Merlin.Web.Model.ThirdParty[]) {
    let thirdParties = (await this.thirdPartyService.getEntities(null, null, { companyId: companyId, emailNotNull: true }));
    thirdParties.forEach(third => {
      if (tempList.find(x => x.id == third.id) == null) {
        tempList.push(third);
      }
    });
    let company = await this.thirdPartyService.getEntityById(companyId);
    if (company.emailAddress) {
      tempList.push(company);
    }
  }

  private configCustomProjectInternalThirdPartiesService() {
    this.customProjectInternalThirdPartiesService = new CustomThirdPartiesSourceService(this.allPossibleReceivers);
  }

  private initInputCCMentions() {
    if (this.inputcc?.firstElementChild == null) return;
    if (this.inputccTributeInstance != null) {
      this.inputccTributeInstance.detach(this.inputcc.firstElementChild);
    }

    this.inputccTributeInstance = new Tribute({
      trigger: '@',
      values: (text, cb) => {
        let vals = this.allPossibleReceivers.filter(x => !this.selectedReceiversIds.map(i => i.toString()).includes(x.id.toString()) && !this.inputccValues?.includes(x.emailAddress));
        cb(this.thirdPartyListToTributeValues(vals));
      },
      selectTemplate: (item) => {
        return item.original.value + ";";
      },
      noMatchTemplate: () => '',
    });

    this.inputccTributeInstance.attach(this.inputcc.firstElementChild);
  }

  private thirdPartyListToTributeValues(values: Array<Merlin.Web.Model.ThirdParty>) {
    return values.map(x => {
      return { key: x.fullName, value: x.emailAddress };
    });
  }

  private async initInputSubject() {
    if (this.inputSubject?.firstElementChild == null) return;
    if (this.inputSubjectTributeInstance != null) {
      this.inputSubjectTributeInstance.detach(this.inputSubject.firstElementChild);
    }
    let vals = await this.emailTemplateApiService.getTokens(this.emailContext)
    this.inputSubjectTributeInstance = new Tribute({
      trigger: '@',
      values: (text, cb) => {
        cb(vals.map(x => {
          return { key: x.id, value: x }
        }));
      },
      selectTemplate: (item) => {
        return item.original.value.value;
      },
      noMatchTemplate: () => '',
      itemClass: "ql-mention-list-item",
      menuItemTemplate: (item) => {
        return '<span class="placeholder-name">' + item.original.value.label + '</span> <span class="placeholder-key">' + item.original.value.value + "</span>"
      }
    });

    this.inputSubjectTributeInstance.attach(this.inputSubject.firstElementChild);
  }

  private initSpokenLanguage() {
    this.setMenuItems();
    if (this.selectedReceiversIds.length == 0) {
      this.selectedLanguageId = this.authService.currentUser.userData.languageId;
    }
    else {
      this.selectedLanguageId = this.allPossibleReceivers.find((x) => this.selectedReceiversIds.find((y) => y == x.id))?.spokenLanguageId;
      if (this.selectedLanguageId == null) {
        this.selectedLanguageId = this.authService.currentUser.userData.languageId;
      }
    }
  }

  public async addFiles() {
    this.loadingFile = true;
    await this.dialogService.open({
      viewModel: AddNotificationSentFile,
      model: {
        files: this.notifLang.get(this.selectedLanguageId).files
      },
      lock: false
    }).whenClosed((result) => {
      if (!result.wasCancelled) {
        let newFileDto = (result.output).map((x: Merlin.Web.Model.NotificationSentFile) => {
          return {
            url: x.url,
            name: x.name,
            description: x.description,
            fileTypeId: x.fileTypeId,
            size: x.size,
            isFromTemplate: x.isFromTemplate,
            notificationSentId: x.notificationSentId
          } as NotificiationFileDTO
        });
        this.notifLang.get(this.selectedLanguageId).files.splice(0);
        this.notifLang.get(this.selectedLanguageId).files.push(...newFileDto);
        this.loadingFile = false;
      }
    })
  }

  public deleteFile(file: NotificiationFileDTO) {
    let notifFiles = this.notifLang.get(this.selectedLanguageId).files;
    notifFiles.splice(notifFiles.indexOf(file), 1);
  }

  public deleteFrom() {
    this.selectedSenderId = null;
    this.selectedSender = null;
  }

  @computedFrom('selectedLanguageId')
  public get getButtonLanguageLabel() {
    return this.languages?.filter(x => x.id == this.selectedLanguageId)[0]?.denomination._translation;
  }

  private setAvatarToReceiver() {
    setTimeout(() => {
      let list = this.toSelector?.querySelectorAll(".select2-selection__choice") ?? [];
      list.forEach(htmlReceiver => {
        let text = htmlReceiver.querySelector(".select2-selection__choice__display").innerHTML;
        let containtAvatarItem = false;
        for (let child of (htmlReceiver.children as any)) {
          if (child.nodeName == "AVATAR") {
            containtAvatarItem = true;
          }
        }
        if (!containtAvatarItem) {
          let avatar = UIInternal.compileTemplate(`<template><avatar name="${text}" random-background.one-time="true"></avatar></template>`);
          avatar.appendNodesTo(htmlReceiver);
        }
      });
    }, 1);
  }

  private setMenuItems() {
    let temp = this.createLanguageItem();
    this.canChangeLang = temp.length > 1;
    this.languageMenuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: temp
      }
    ];
  }

  private createLanguageItem() {
    let selectedReceveir = this.allPossibleReceivers.filter(x => this.selectedReceiversIds.find(y => y == x.id));
    let items = []
    this.languages.filter(x => selectedReceveir.find(y => y.spokenLanguageId == x.id)).forEach(lang => {
      items.push(
        {
          label: lang.denomination._translation,
          handler: () => {
            this.selectedLanguageId = lang.id;
          }
        }
      )
    });
    return items;
  }

  async emailTypeSelectedChanged(newVal, oldVal) {
    if (newVal != oldVal && newVal != null) {
      await this.setNewNotifLang();
    } else if (newVal == null) {
      await this.removeFileFromPreviousTemplate();
    }
  }

  async selectedLanguageIdChanged(newVal, oldVal) {
    if (newVal != oldVal && newVal != null /*&& this.emailTypeSelected != null && this.emailTypeSelected.id != ""*/) {
      await this.setNewNotifLang();
    }
  }

  async selectedSenderIdChanged(newVal, oldVal) {
    if (newVal != oldVal) {
      if (newVal != null) {
        this.selectedSender = this.allPossibleSender.find(x => x.id == newVal);
        if (this.selectedSender != null) {
          this.notifLang.forEach(x => {
            x.from = this.selectedSender.email;
            x.fromName = this.selectedSender.fullName;
          })

        }
      }
      else {
        this.notifLang.forEach(x => {
          x.from = null;
          x.fromName = null;
        })
        this.selectedSender = null;
      }
    }
  }

  private async setNewNotifLang() {
    if (this.selectedLanguageId != null && this.notifLang != null) {
      if (this.notifLang.get(this.selectedLanguageId) == null) {
        this.addNewNotifLang();
      }
      if (this.emailTypeSelected != null) {
        if (this.emailTypeSelected.id != null && this.emailTypeSelected.id != "") {
          await this.getTemplate(this.emailTypeSelected.id as number, this.selectedLanguageId);
        }
        else if (typeof this.emailTypeSelected === 'number') {
          await this.getTemplate(this.emailTypeSelected as number, this.selectedLanguageId);
        }
      }
    }
  }

  private addNewNotifLang() {
    this.notifLang.set(this.selectedLanguageId, {
      to: null,
      from: this.selectedSender != null ? this.selectedSender.email : null,
      fromName: this.selectedSender != null ? this.selectedSender.fullName : null,
      cc: null,
      subject: null,
      body: null,
      templateId: null,
      emailContextId: this.emailContext,
      files: new Array<NotificiationFileDTO>()
    });
    this.notifLang.get(this.selectedLanguageId).files.push(...this.files?.map((x: IFile) => {
      return {
        url: x.url,
        name: x.name,
        description: x.description,
        fileTypeId: x.fileTypeId,
        size: x.size,
        isFromTemplate: false,
        notificationSentId: null
      } as NotificiationFileDTO
    }));
  }

  public async getTemplate(emailTypeId: number, languageId: number) {
    if (this.emailTemplateService != null && emailTypeId != null && languageId != null) {
      this.showQuill = false;
      this.emailTemplate = await this.emailTemplateService.firstEntity(null, ['emailTemplate.emailTemplateFiles'], { isEmailSender: true, emailTypeId: emailTypeId, languageId: languageId });
      if (this.emailTemplate != null && this.emailTemplate.id != this.notifLang.get(this.selectedLanguageId).templateId) {
        await this.applyTemplate();
      }

      if (this.previewRequestParams != null) {
        this.previewRequestParams.emailContextId = this.emailContext;
      }
      UIInternal.queueTask(() => this.showQuill = true);
    }
  }

  public async applyTemplate() {
    this.loadingFile = true;
    let notif = this.notifLang.get(this.selectedLanguageId);
    if (notif != null) {
      notif.body = this.emailTemplate.emailTemplate?.content;
      notif.subject = this.emailTemplate.emailTemplate?.emailTitle;
      notif.cc = this.emailTemplate.emailTemplate?.ccEmailAddress;
      notif.templateId = this.emailTemplate.id;

      if (this.previewRequestParams != null) {
        this.previewRequestParams.contentHtml = notif.body;
      }

      if (notif.files.length > 0)
        await this.removeFileFromPreviousTemplate();

      for (const mailFile of this.emailTemplate.emailTemplate?.emailTemplateFiles) {
        let fileExist = notif.files.find(x => x.url == mailFile.url);
        if (fileExist == null) {
          let file: NotificiationFileDTO = {
            url: mailFile.url,
            name: mailFile.name,
            description: mailFile.description,
            size: mailFile.size,
            fileTypeId: mailFile.fileTypeId,
            isFromTemplate: true,
            notificationSentId: null
          }
          notif.files.push(file);
        }
      }
      setTimeout(() => {
        this.loadingFile = false;
      }, 1);
    }
  }

  private async removeFileFromPreviousTemplate() {
    this.notifLang.forEach(async notif => {
      let filesFromTemplate = notif.files.filter(x => x.isFromTemplate);
      if (filesFromTemplate.length > 0) {
        filesFromTemplate.forEach(file => {
          notif.files.splice(notif.files.indexOf(file), 1);
        });
      }
    });
  }

  @computedFrom('selectedLanguageId', 'emailTypeSelected', 'emailTypeSelected.id', 'loadingFile')
  get getNotifLang() {
    return this.notifLang.get(this.selectedLanguageId);
  }

  private addFilesFromDocument() {
    this.box.showCustomDialog(
      DocumentList,
      null,
      this.i18n.tr('document.documentList'),
      {
        canSave: false,
        size: "xl",
        model: {
          projectId: this.projectId,
          isDialogView: true
        }
      }).whenClosed((result) => {
        if (!result.wasCancelled && result.output.length > 0) {
          let files: NotificiationFileDTO[] = [];
          result.output.forEach((document: Merlin.Web.Model.Document) => {
            files.push({
              url: document.url,
              name: document.name,
              description: document.description,
              fileTypeId: document.fileTypeId,
              size: document.size,
              isFromTemplate: false,
              notificationSentId: null
            });
          });
          this.notifLang.forEach(notif => {
            notif.files.push(...files);
          })
        }
      });
  }
}

export class NotificationLanguageDTO {
  public to: string;
  public from: string;
  public fromName: string;
  public cc: string;
  public body: string;
  public subject: string;
  public templateId: number;
  public emailContextId: number;
  public files: Array<NotificiationFileDTO>;
}

export interface NotificiationFileDTO {
  url: string;
  name: string;
  description: string;
  fileTypeId: number | null;
  size: number;
  isFromTemplate: boolean;
  notificationSentId: number;
}
