import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, EnumerationTypeService, FileFlow, AuthService, Various, IMenuGroup } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { FamilyFamilyTreeView } from './family-family-tree-view/family-family-tree-view';

@autoinject
export class ItemFamilyDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ItemFamily> {
  public ressourceName: string = Constants.EntityTypeNames.ItemFamily;

  private itemFamilyService: ServiceBase<Merlin.Web.Model.ItemFamily>;
  private menuItems: Array<IMenuGroup>;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.ItemFamily>(Constants.EntityTypeNames.ItemFamily));
    this.itemFamilyService = new ServiceBase<Merlin.Web.Model.ItemFamily>(Constants.EntityTypeNames.ItemFamily);
  }
  @computedFrom('editingMode', 'entity.familyName._translation')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("itemfamily.itemfamily");
    }
    else {
      return this.entity.familyName._translation ?? "";
    }
  }

  public async activate(params: any) {
    let id: number = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, "mainFamily");
      this.controller.addObject(this.entity);
      this.itemFamilyService.gridDataSource.queryParameters = { itemFamilyId: this.entity.id };
    }
    this.setMenuItems();
  }
  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      },
    ];
  }

  public async openItemFamiliesTreeView() {
    this.dialogService.open({ viewModel: FamilyFamilyTreeView, model: this.entity, lock: true });
  }
}
