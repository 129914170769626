import { ServiceBase, UITreeNode } from 'digiwall-lib';
import { autoinject, BindingEngine } from 'aurelia-framework';
import { Merlin } from "../../generated";
import * as Constants from '../../constants';
import { DialogController } from 'aurelia-dialog';

@autoinject
export class FamilyFamilyTreeView {
  private entity: Merlin.Web.Model.ItemFamily

  private articleFamilies: Array<Merlin.Web.Model.ItemFamily>;
  private articleFamiliesTree: UITreeNode[] = [];
  private selectedTreeValues: string[] = [];

  private articleFamilyService: ServiceBase<Merlin.Web.Model.ItemFamily>;

  constructor(private dialogController: DialogController, private bindingEngine: BindingEngine) {
    this.articleFamilyService = new ServiceBase<Merlin.Web.Model.ItemFamily>(Constants.EntityTypeNames.ItemFamily);
  }

  public async activate(entity: Merlin.Web.Model.ItemFamily) {
    this.entity = entity;
    await this.refreshTreeValues();
    this.refreshSelectedTreeValues();
  }

  public async refreshTreeValues() {
    this.articleFamilies = null;
    this.articleFamilies = await this.articleFamilyService.getEntities(null, null, null);
    this.articleFamiliesTree = [];
    this.articleFamilies.filter(x => x.mainFamilyId == null && x.id != this.entity.id).forEach(articleFamily => {
      let children: Array<UITreeNode> = null;
      if(articleFamily.subFamilies != null && articleFamily.subFamilies.length > 0) {
        children = this.getChildren(articleFamily);
      }
      let articleFamilyTreeItem: UITreeNode = new UITreeNode({
        id: articleFamily.id.toString(),
        label: articleFamily.familyName._translation,
        expanded: true,
        disabled: true,
        leaf: (children == null),
        children: children
      });
      this.articleFamiliesTree.push(articleFamilyTreeItem);
    });
  }

  public refreshSelectedTreeValues(): void {
    if (this.entity.mainFamily != null) {
      this.selectedTreeValues.push(this.entity.mainFamilyId.toString());
    }
  }

  public getChildren(parentArticleFamily: Merlin.Web.Model.ItemFamily): Array<UITreeNode> {
    let articleFamilies: Array<UITreeNode> = new Array<UITreeNode>();
    parentArticleFamily.subFamilies.filter(x => x.id != this.entity.id).forEach(articleFamily => {
      let children: Array<UITreeNode> = null;
      if(articleFamily.subFamilies != null && articleFamily.subFamilies.length > 0) {
        children = this.getChildren(articleFamily)
      }
      let articleFamilyTreeItem: UITreeNode = new UITreeNode({
        id: articleFamily.id.toString(),
        label: articleFamily.familyName._translation,
        expanded: true,
        disabled: true,
        leaf: (children == null),
        children: children
      });
      articleFamilies.push(articleFamilyTreeItem);
    }); 
    return articleFamilies;
  }

  public async ok() {
    if(this.selectedTreeValues != null) {
      this.entity.mainFamilyId = parseInt(this.selectedTreeValues[0]);
      this.entity.mainFamily = await this.articleFamilyService.getEntityById(this.entity.mainFamilyId);
    }
    this.dialogController.ok();
  }
} 
