@import "../css/scss/variables";
ux-dialog-container.floating-box {
  border: 5px solid $blue-darkest;
  border-left: 0px;
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
  width: 20vw;
  min-width: 680px;
  > div > div {
    width: 100% !important;
    > floating-box-view {
      &.header-footer-fixed {
        > .header-footer-fixed {
          &__header {
            padding: 30px 40px;
            border-top-right-radius: $radius;
            .ui-btn__inner {
              box-shadow: none;
            }
          }
          &__content {
            padding: 0 40px;
            .header-footer-fixed {
              > .header-footer-fixed__header {
                > .input-search {
                  grid-template-columns: auto;
                }
              }
              &__content {
                > div > div > div > div > ui-checkbox {
                  .ui-option__control > .digi-checkbox-valid {
                    color: $blue-mid;
                  }
                }
              }
            }
            // .col-def-filter {
            //   &__label {
            //     font-weight: 400;
            //     font-size: 12px;
            //     line-height: 15px;
            //     color: $blue-mid;
            //   }
            //   ui-field:not(.filter-segmented-control) {
            //     align-items: center;
            //     > label {
            //       padding-left: 0;
            //       //padding-right: 0;
            //       text-transform: lowercase;
            //       text-transform: lowercase;
            //       text-overflow: clip;
            //       font-size: 12px;
            //     }
            //   }
            //   ui-date-picker {
            //     width: 100%;
            //   }
            //   .col {
            //     margin-left: 0px;
            //     padding-left: 5px;
            //     padding-right: 5px;
            //     padding-bottom: 0px;
            //   }
            //   .l8 > ui-field.ui-field {
            //     grid-template-columns: auto;
            //   }

            //   .l4:not(.col-def-filter__label) > ui-field.ui-field {
            //     grid-template-columns: 15% auto;
            //   }
            // }
            .current-view {
              background-color: $purple-lighter;
              border-radius: 8px;
            }
            .ag-grid-view-element {
              min-height: 47px;
              margin: 5px 0;
              .divider {
                background-color: $gray-lighter;
              }
              &:hover {
                background-color: $purple-lightest;
                border-radius: 8px;
                > div:nth-child(2) {
                  visibility: visible;
                }
              }
              > div {
                padding: 5px 10px;
              }
              &__name {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: $black-100;
              }
              &__visibility {
                padding: 2px 0px;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: $purple-dark;
              }
              > div:nth-child(2) {
                visibility: hidden;
                > ui-button {
                  border-radius: 60px;
                  border: none;
                  background-color: transparent !important;
                  transition: none !important;
                  .ui-btn__icon {
                    color: $blue-mid;
                  }
                  .ui-btn__inner {
                    box-shadow: none;
                  }
                  .ui-btn {
                    background-color: inherit;
                  }
                  &:hover {
                    background-color: $purple-lighter !important;
                  }
                }
              }
            }
          }
          &__footer {
            //height: 85px;
            padding: 20px 40px;
            background-color: $background-footer;
            border-bottom-right-radius: $radius;
            ui-button.result,
            ui-button.reset {
              box-shadow: none;
            }
            ui-button.result,
            ui-button.reset {
              box-shadow: none;
            }
            ui-button.result {
              background-color: $blue-mid;
              color: $purple-lightest;
              border-radius: 150px;
              font-size: 15px;
              font-weight: 400;
              height: 44px;
              &:hover {
                background-color: $merlin-blue-dark !important;
              }
              .ui-btn__inner {
                background-color: $blue-mid;
                border-radius: 150px;
                //padding: 0 20px;
                &:hover {
                  background-color: $merlin-blue-dark !important;
                }
                > .ui-btn {
                  .ui-btn__label,
                  .ui-btn__icon {
                    color: $black-0;
                  }
                  .ui-btn__icon {
                    padding-left: 0;
                  }
                }
              }

              .ui-btn__icon {
                color: $blue-mid;
                padding-left: 15px;
                padding-right: 10px;
              }
              .ui-btn__label {
                color: $blue-mid;
              }

              &:hover {
                background-color: $blue-mid;
                color: $purple-lightest;
                .ui-btn__inner {
                  background-color: $blue-mid;
                  color: $purple-lightest;
                }
                .ui-btn__icon {
                  color: $purple-lightest;
                }
                .ui-btn__label {
                  color: $purple-lightest;
                }
                > a {
                  background-color: $blue-mid;
                  color: $purple-lightest;
                }
              }
            }
          }
        }
      }
      &.view-parameter-box {
        > .header-footer-fixed__content {
          overflow: hidden !important;
          > .header-footer-fixed {
            > .header-footer-fixed__header {
              flex-direction: column;
              display: block;
            }
            > .header-footer-fixed__content > div {
              overflow: scroll !important;
              display: flex;
              flex-direction: column;
              row-gap: 30px;
              .group {
                gap: 30px;
                ui-checkbox {
                  > label {
                    > span {
                      color: $black-100;
                    }
                    > svg:last-of-type {
                      color: $blue-mid;
                    }
                  }
                }
              }
            }
            > .header-footer-fixed__content,
            .header-footer-fixed__content > div > div > .row > .col {
              padding: 0;
            }
          }
        }
      }
      &.ag-grid-view-box {
        .header-footer-fixed__content {
          > .header-footer-fixed {
            > div {
              > ui-field {
                grid-template-columns: auto;
                > ui-input {
                  border-radius: $radius;
                }
              }
            }
          }
          > .header-footer-fixed {
            > .header-footer-fixed__content {
              padding: 0;
            }
          }
        }
      }
    }
  }
  > div > div {
    width: 100% !important;
  }
  > div {
    padding: 0;
  }
}
.floating-box {
  z-index: 1001 !important;
}

.overlay-floating-box {
  opacity: 0 !important;
  z-index: 1001 !important;
}
.container-question-view-box,
.overlay-question-view-box {
  z-index: 1015 !important;
}

.overlay-ag-grid-view-detail,
.container-ag-grid-view-detail {
  z-index: 1010 !important;
}
.container-ag-grid-view-detail {
  z-index: 1010 !important;
  ux-dialog {
    background-color: $white !important;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: $radius;
    ux-dialog-header {
      //padding: 0px 10px 0px 30px;
      padding: 30px 40px;
      .grid-dialog-header {
        // grid-template-areas: "icon title close";
        grid-template-columns: 0 1fr auto;
        &__icon {
          display: none;
        }
        &__title {
          font-family: $font-family;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          color: $black-100;
        }
        &__close {
          display: block !important;
          .ui-btn__inner {
            box-shadow: none;
          }
        }
      }
    }
    ux-dialog-body {
      padding: 0px 30px;

      .ag-grid-view-detail {
        display: flex;
        flex-direction: column;
        gap: 15px;
        ui-field > label {
          font-family: $font-family;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          grid-template-columns: auto 1fr;
        }
      }
    }
    ux-dialog-footer.grid-dialog-footer {
      background-color: $background-footer;
      ui-button.left {
        display: none;
      }
      ui-button.right {
        float: none !important;
        > div.ui-btn__inner {
          background-color: $white !important;
          color: $blue-mid !important;
          border-radius: 60px;
          > a {
            .ui-btn__icon {
              display: none;
            }
            .ui-btn__label {
              padding-left: 10px;
            }
          }
        }
        > div.ui-btn__inner:hover {
          background-color: $blue-mid !important;
          color: $white !important;
          border-radius: 60px;
          > a {
            background-color: $blue-mid !important;
            color: $white !important;
          }
        }
      }
    }
  }
}

.theme-light .ui-btn__group.ui-theme--secondary .ui-btn__inner,
.theme-light .ui-btn__wrapper.ui-theme--secondary .ui-btn__inner {
  &:hover,
  &:focus-within {
    background-color: transparent !important;
  }
}

.button-action,
.button-view,
.button-space,
.button-add,
.chat_history_button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px $purple-light;
  border-radius: 8px;
  > ui-button[data-type="tool"] {
    border: none !important;
    border-left: solid 1px $purple-light !important;
    background-color: transparent !important;
    border-radius: 0;
    /*> .ui-btn__inner {
      &:focus-within,
      &:hover {
        background-color: transparent !important;
      }
      .ui-btn {
        color: $blue-darkest;
        &:hover,
        &:focus,
        &:focus-within {
          color: $blue-mid;
          background-color: transparent !important;
        }
      }
    }*/
    .ui-btn {
      padding: 0 !important;
    }
    &:first-child {
      border: none !important;
    }
  }
  .ui-btn__wrapper {
    margin: 0;
  }
}
