import { HttpClient } from 'aurelia-fetch-client';
import * as Constants from '../constants';
import { autoinject } from 'aurelia-framework';

@autoinject
export class NotificationApiService {

  constructor(private httpClient: HttpClient) {
  }

  async createNotifications(...notifications: NotificationDTO[]) {
    let query = await this.httpClient.post(Constants.Application.NotificationController.CreateNotifications, JSON.stringify(notifications));
    if (query.ok) {
      return true;
    }
    return false;
  }
}

export interface NotificationDTO {
  title: string;
  message: string;
  notifiedById: number;
  notifiedUserId: number;
  urlRedirection: string;
  notificationTypeId: number | null;
}
