import { I18N } from 'aurelia-i18n';
import { DialogController } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, Container, observable } from "aurelia-framework";
import { CustomLogger, GlobalLoaderService, HeaderLabel, ImportColumnMapping, ImportService, ImportSession, ImportFileAndSession } from "digiwall-lib";
import * as Constants from '../../../constants';

@autoinject
export class SelectMappingInject {
  // private columnsMapping: Array<ImportColumnMapping> = new Array();

  private sessionColumnsMapping: Array<{ sessionId: number; sheetNames: string; columnMapping: Array<ImportColumnMapping>; columns: Array<HeaderLabel> }> = new Array();
  private columns: Array<HeaderLabel> = new Array();
  private selectedSessionId: number

  httpClient: HttpClient;
  public sheets: any[] = new Array();
  public workQuotationVersionId: number;
  public includeInLetter: boolean = false

  @observable
  public selectedSheet;
  @observable
  public selectedFile;
  fileAndSessions: Array<ImportFileAndSession>;
  fileUrl: any;
  sessions: ImportSession[];

  constructor(private importService: ImportService, private dialogController: DialogController, private gls: GlobalLoaderService, private i18n: I18N, private logger: CustomLogger) {

  }

  async activate(params: any) {
    this.workQuotationVersionId = params.workQuotationVersionId;
    this.includeInLetter = params.includeInLetter
    this.httpClient = Container.instance.get(HttpClient);
    this.fileAndSessions = await this.importService.getFileAndSession(Constants.ImportContext.Metering, this.workQuotationVersionId);

    if (this.fileAndSessions.length == 0) {
      await this.dialogController.cancel();
      this.logger.LogError(this.i18n.tr("priceofferversion.noSessionError"), null, null, true);
    }
  }

  async selectedFileChanged() {
    await this.saveFileSession();
    let selectedFile: ImportFileAndSession = this.fileAndSessions.find(x => x.id == this.selectedFile)
    this.fileUrl = selectedFile.fileUrl;
    this.sheets = selectedFile.sheets;
    this.sessions = selectedFile.sessions;
    this.selectedSheet = null;
    this.sessionColumnsMapping.splice(0);
    this.loadingData = true;
    for (let session of selectedFile.sessions) {
      if (session.currentUIStep == 5) {
        let columnMapping = await this.getColumnMapping(session.id);
        let column = await this.importService.getHeaderLabel(session.id);
        this.sessionColumnsMapping.push({
          sessionId: session.id,
          sheetNames: (session.sheetsIndex.map(x => this.sheets.find(s => s.index == x).name).join(", ")),
          columnMapping: columnMapping,
          columns: column
        });
      }
    }
    this.loadingData = false;
  }

  private loadingData: boolean = true;

  async getColumnMapping(id: number) {
    this.gls.allow();
    const mappingResult = await this.httpClient.get(Constants.Application.WorkQuotationController.GetColumnsMapping.format(id.toString(), this.includeInLetter ? "true" : "false"));
    if (mappingResult) {
      const mapping = await mappingResult.json();
      return mapping;
    }
    return null;
  }
  async getSessions(id: number) {
    this.gls.allow();
    const mappingResult = await this.httpClient.get(Constants.Application.WorkQuotationController.GetImportSessions.format(id.toString()));
    if (mappingResult) {
      const mapping = await mappingResult.json();
      return mapping;
    }
    return null;
  }

  public async close() {
    await this.dialogController.close(false);
  }

  private async saveFileSession() {
    if (this.sessionColumnsMapping?.length > 0) {
      for (var session of this.sessionColumnsMapping) {
        await this.importService.saveMapping(session.sessionId, session.columnMapping);
      }
    }
  }

  public async confirm() {
    await this.saveFileSession()
    await this.dialogController.close(true);
  }
}
