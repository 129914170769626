@import "../../../css/scss/variables";

ux-dialog.dialog-articles {
  > ux-dialog-header {
    padding-bottom: 20px;
    border-bottom: solid 1px $purple-lighter;
    .dialog-header-content {
      display: flex;
      align-items: center;

      .title-search {
        flex: 1;
      }
    }
  }

  > ux-dialog-body {
    display: flex;
    flex-direction: column;
  }

  > ux-dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
}
