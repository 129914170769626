import { DialogController } from 'aurelia-dialog';
import { autoinject, computedFrom } from "aurelia-framework";
import { Box, CustomLogger, EditingModeEnum, EntityDetailViewModelBase, IMenuGroup, ServiceBase } from "digiwall-lib";
import { FloatingNumber } from "./floating-number/floating-number";
import { FloatingIntern } from "./floating-intern/floating-intern";
import { FloatingDate } from "./floating-date/floating-date";
import { FloatingPartners } from "./floating-partners/floating-partners";
import { FloatingExtern } from "./floating-extern/floating-extern";
import { FloatingAdress } from "./floating-adress/floating-adress";
import { FloatingCompany } from "./floating-company/floating-company";
import { FloatingDescription } from "./floating-description/floating-description";
import { Merlin } from 'generated';
import * as Constants from '../../constants';
import { Router } from 'aurelia-router';
import { ProjectApiService } from "services/project-api-service";
import moment = require('moment');

@autoinject
export class ProjectResume extends EntityDetailViewModelBase<Merlin.Web.Model.Project> {
  public ressourceName: string = Constants.EntityTypeNames.Project;

  projectResumeBase: ProjectResumeBase;
  projectResumeAddress: ProjectResumeAddress;
  projectResumeCompany: ProjectResumeCompany;
  projectResumeDate: ProjectResumeDates;
  projectResumeDescription: ProjectResumeDescription;
  projectResumeExtern: ProjectResumeExtern;
  projectResumeIntern: ProjectResumeIntern;
  projectResumeNumber: ProjectResumeNumber;
  projectResumePartner: ProjectResumePartner;
  extern: string;
  partners: string;
  private projectId: number

  private menuItems: Array<IMenuGroup>;

  private BACK_UP_URL = Constants.Asset.PLACEHOLDER_IMAGE;

  constructor(router: Router, logger: CustomLogger, private projectApiService: ProjectApiService, private dialogController: DialogController) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project));

  }
  async activate(params: any): Promise<void> {
    await super.activate(params);
    this.projectId = params.projectId;
    await this.getBaseInfo(this.projectId);
    await this.getEntity(this.projectId);
    await this.getAddress(this.projectId);
    await this.getCompany(this.projectId);
    await this.getDates(this.projectId);
    await this.getResumeExtern(this.projectId);
    await this.getResumeIntern(this.projectId);
    await this.getResumePartner(this.projectId);
    await this.getResumeNumber(this.projectId);

    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.edit"),
            icon: "digi-edit",
            handler: () => {
              this.navigateToEditProject();
            }
          }
        ]
      }
    ];
  }

  private async getEntity(projectId): Promise<any> {
    this.entity = await this.service.getEntityById(projectId, 'projectType');
    this.projectResumeDescription = {
      description: this.entity.projectDescriptionMultiline,
      projectType: this.entity.projectType?.denomination._translation
    }
  }

  private async getBaseInfo(projectId): Promise<any> {
    this.projectResumeBase = await this.projectApiService.getBaseInfo(projectId);
  }

  private async getAddress(projectId): Promise<any> {
    this.projectResumeAddress = await this.projectApiService.getAddress(projectId);
  }

  private async getCompany(projectId): Promise<any> {
    this.projectResumeCompany = await this.projectApiService.getCompany(projectId);
  }
  private async getDates(projectId): Promise<any> {
    this.projectResumeDate = await this.projectApiService.getDates(projectId);
  }
  private async getResumeExtern(projectId): Promise<any> {
    this.projectResumeExtern = await this.projectApiService.getResumeExtern(projectId);
    this.getExtern()
  }
  private async getResumeIntern(projectId): Promise<any> {
    this.projectResumeIntern = await this.projectApiService.getResumeIntern(projectId);
  }
  private async getResumePartner(projectId): Promise<any> {
    this.projectResumePartner = await this.projectApiService.getResumePartner(projectId);
    this.getPartners();
  }
  private async getResumeNumber(projectId): Promise<any> {
    this.projectResumeNumber = await this.projectApiService.getNumber(projectId);
  }
  @computedFrom('editingMode', 'entity.projectName')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("project.project");
    }
    else {
      return this.entity.projectName;
    }
  }

  public navigateToEditProject() {
    let url = this.router.generate("project-detail");
    this.router.navigate(url);
    this.dialogController.close(false);
  }

  public navigateToEditProjectInternalTeam() {
    let url = this.router.generate("project-detail");
    url += "?tab=" + Constants.ProjectDetailTab.InternalTeam
    this.router.navigate(url);
    this.dialogController.close(false);
  }

  public navigateToEditProjectExternalTeam() {
    let url = this.router.generate("project-detail");
    url += "?tab=" + Constants.ProjectDetailTab.ExternalTeam
    this.router.navigate(url);
    this.dialogController.close(false);
  }

  public navigateToEditProjectPartner() {
    let url = this.router.generate("project-detail");
    url += "?tab=" + Constants.ProjectDetailTab.ProjectPartners
    this.router.navigate(url);
    this.dialogController.close(false);
  }

  openFloatingNumber() {
    this.box.showFloatingBox(new FloatingNumber(this.navigateToEditProject, this.router), false, false);
  }
  openFloatingIntern() {
    this.box.showFloatingBox(new FloatingIntern(this.projectResumeIntern, this.navigateToEditProjectInternalTeam, this.router), false, false);
  }
  openFloatingDate() {
    this.box.showFloatingBox(new FloatingDate(this.projectResumeDate, this.navigateToEditProject, this.router), false, false);
  }
  openFloatingPartners() {
    this.box.showFloatingBox(new FloatingPartners(this.projectResumePartner, this.navigateToEditProjectPartner, this.router), false, false);
  }
  openFloatingExtern() {
    this.box.showFloatingBox(new FloatingExtern(this.projectResumeExtern, this.navigateToEditProjectExternalTeam, this.router), false, false);
  }
  openFloatingAdress() {
    this.box.showFloatingBox(new FloatingAdress(this.projectResumeAddress, this.navigateToEditProject, this.router), false, false);
  }
  openFloatingCompany() {
    this.box.showFloatingBox(new FloatingCompany(this.projectResumeCompany, this.navigateToEditProject, this.router), false, false);
  }
  openFloatingDescription() {
    this.box.showFloatingBox(new FloatingDescription(this.projectResumeDescription, this.navigateToEditProject, this.router), false, false);
  }

  getPartners() {
    this.partners = "";
    this.projectResumePartner.persons.forEach(person => {
      if (this.partners.length != 0) {
        this.partners += ", ";
      }
      this.partners += person.name;
    })
  }

  getExtern() {
    this.extern = "";
    this.projectResumeExtern.persons.forEach(person => {
      if (this.extern.length != 0) {
        this.extern += ", ";
      }
      this.extern += person.name;
    })
  }

  navigateToThirdParty() {
    window.open(`/third-parties/${this.entity.mainThirdPartyId}`, "_blank")
  }

  @computedFrom("projectResumeDate.offerDeadlineDate")
  get getUtcOfferDeadlineDate() {
    return moment.utc(this.projectResumeDate.offerDeadlineDate).local().format("DD/MM/YYYY HH:mm");
  }
}

export interface ProjectResumeBase {
  projectId: number;
  companyName: string;
  projectName: string;
  logoUrl: string;
  status: string;
  statusTextColor: string;
  statusBackgroundColor: string;
  language: string;
  refClient: string;
}

export class ProjectResumeAddress {
  street: string;
  localityName: string;
  zipCode: string;
  countryName: string;
  addressType: string;

  fullAddress: string;
  latitude: number | null;
  longitude: number | null;
}

export class ProjectResumeCompany {
  hasTemporaryCompany: boolean;
  temporaryCompanyName: string;
  partner: string;
  vatNumber: string;
  address: string;
  contactPersons: Array<ProjectResumePerson>;
}
export class ProjectResumePerson {
  name: string;
  role: string;
  phoneNumber: string;
  email: string;
}
export interface ProjectResumeDates {
  plannedWorkingSiteStartingDate: Date | string | null;
  workingSiteStartingDate: Date | string | null;
  estimatedProjectDurationInMonth: number | null;
  offerDeadlineDate: Date | string | null;
}
export class ProjectResumeDescription {
  projectType: string;
  description: string;
}
export class ProjectResumeExtern {
  persons: Array<ProjectResumePerson>;
}
export class ProjectResumeIntern {
  persons: Array<ProjectResumeInternalPerson>;
}
export class ProjectResumeInternalPerson extends ProjectResumePerson {
  avatarUrl: string;
}
export class ProjectResumeNumber {
  signedOffer: number
}
export class ProjectResumePartner {
  persons: Array<ProjectResumePerson>;
}

export interface PrecisionParameter {
  nbDecimalForQuantityDisplay: number;
  nbDecimalForPriceDisplay: number;
}
