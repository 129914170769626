@import "../css/scss/variables";

$description-content-height: 35px;

html {
  --ag-material-primary-color: $blue-mid;
  --ag-range-selection-border-color: $blue-mid;
  --ag-border-color: $blue-mid;
  --ag-line-height: 40px;
  --ag-row-group-indent-size: 30px;
  --ag-value-change-value-highlight-background-color: #eaf2fb;
  --ag-row-hover-color: $purple-lightest !important;
}

ui-content.ui-section__content {
  display: flex;
  flex-direction: column;

  div.row {
    margin-left: unset;
    margin-right: unset;
  }
}

price-offer-lines-grid {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  ag-grid-aurelia {
    flex: 1;
    overflow: auto;
    > .ag-root-wrapper
      > .ag-root-wrapper-body
      > .ag-root
      > .ag-body-viewport
      > .ag-pinned-left-cols-container
      > .ag-row {
      border-left-style: none;
    }
    .ag-cell {
      ui-toggle {
        label {
          padding: 0 !important;
        }
      }
      &.previous-price-error {
        background-color: $red-mid !important;
      }
      &.is-fixed-price {
        background-color: $gray-very-light;
        background-image: repeating-linear-gradient(
          45deg,
          transparent,
          transparent,
          5px,
          rgba(255, 255, 255, 0.5) 5px,
          rgba(255, 255, 255, 0.5) 10px
        );
      }
    }
  }

  .detail-tag,
  .repartition-tag,
  .addition-work,
  .extra {
    padding: 5px 15px;
    border-radius: 20px;
    line-height: 15px;
  }

  .detail-tag {
    background-color: #efefef;
    color: #7d7d7d;
  }

  .repartition-tag {
    background-color: #e5edf3;
    color: #014f86;
  }

  .addition-work {
    background-color: #4a9e95;
    color: $white;
  }

  .extra {
    background-color: #2c7da0;
    color: $white;
  }
}

ag-grid-aurelia.ag-theme-material.datagrid .ag-cell.ag-cell-not-inline-editing .ag-cell-wrapper {
  display: flex;
  align-items: center;
}

.metering-price-offer-lines {
  height: 100%;
  /*grid-header-bar {
    display: flex;
    > .grid-header-bar__btn {
      margin-top: 16px;
    }
  }*/

  /*price-offer-lines-grid > .between-header-grid {
    .filter-reset > ui-button > .ui-btn__inner {
      padding-left: 0;
    }
  }*/

  .ag-theme-material.datagrid {
    .ag-header > .ag-header-viewport > .ag-header-container > .ag-header-row > .ag-header-cell {
      border-bottom: none;
    }

    .ag-root-wrapper {
      &-body {
        .ag-root > .ag-body-viewport {
          .ag-pinned-left-cols-container {
            .ag-row {
              border-right: solid 1px var(--ag-border-color) !important;
              &.disabled-row {
                border-right: solid 1px
                  rgba(red($gray-very-light), green($gray-very-light), blue($gray-very-light), $ag-row-disabled-opacity) !important;
              }
              > .ag-cell {
                border-left: solid 1px var(--ag-border-color);
                padding: 0 15px;
                &:first-child {
                  border: none !important;
                  padding: 0;
                  background-color: white;
                }
              }
              > .ag-cell-focus {
                border: solid 1px $blue-mid !important;
              }
              &.condensed-row {
                .metering-line-type-description {
                  height: inherit;
                  .metering-description-before-toggle {
                    height: inherit;
                    > div.metering-line-type-description-overflow {
                      height: inherit;
                    }
                    > div:not(.metering-line-type-description-overflow) {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin: 2px 0px;
                      height: -webkit-fill-available;
                      line-height: inherit;
                    }
                  }
                }
                .metering-line-description {
                  line-height: 30px;
                }
                .ag-cell-inline-editing {
                  height: 30px;
                }
              }
            }
          }
          .ag-center-cols-clipper > .ag-center-cols-viewport > .ag-center-cols-container > .ag-row {
            > .ag-cell-focus {
              border: solid 1px $blue-mid !important;
            }
            .metering-cell-select {
              &:hover {
                background-color: $purple-lightest;
                color: $blue-mid;
              }
            }
          }
        }
      }
    }
    .ag-popup-child > div > .ui-field {
      border-right: solid 1px $gray-very-light;
      .ui-select > .select2 > .selection > .select2-selection > .select2-selection__rendered {
        font-size: 12px;
        color: $blue-mid;
      }
    }

    .ag-cell-wrapper {
      justify-content: left !important;
    }

    .ag-pinned-left-cols-container {
      @for $i from 1 through 20 {
        .ag-row-level-#{$i} .ag-cell:is(:nth-child(2), :nth-child(3), :nth-child(4)) {
          margin-left: calc((#{$i} * 34px));
        }
      }
    }

    .ag-row-group-indent-0 {
      height: 38px;
    }

    @for $i from 1 through 20 {
      .ag-row-group-indent-#{$i} {
        padding-left: 0;
      }
    }

    .condensed-row {
      .ag-cell-not-inline-editing {
        line-height: 30px !important;
      }

      ui-button.metering-btn-file {
        height: 25px;
        margin: 0;
        .ui-btn__inner {
          height: 30px;
          .ui-icon {
            font-size: 20px;
          }
          .ui-badge {
            min-width: 1rem;
            height: 1rem;
            bottom: -2px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .ag-text-area {
    box-shadow:
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px $blue-mid;
    padding: 10px 13px;
    font-family: inherit;
    background-color: #fafafa;

    .ag-text-area-input {
      min-height: 200px;
      border-bottom: none !important;
      font-family: inherit;
    }
  }
  .ui-field {
    padding: 0 !important;
  }
}

.ag-theme-material {
  font-family: inherit;
  font-size: 12px;

  .ag-row {
    &.ag-row-disabled {
      > .ag-cell {
        border-right: 1px solid $gray-very-light;
      }
      > .ag-cell:not(.metering-cell-description) {
        opacity: $ag-row-disabled-opacity;
      }
      > .ag-cell.metering-cell-description,
      > .ag-cell.cell-radio-button {
        border-bottom: solid 1px
          rgba(red($gray-very-light), green($gray-very-light), blue($gray-very-light), $ag-row-disabled-opacity) !important;
        border-right: solid 1px
          rgba(red($gray-very-light), green($gray-very-light), blue($gray-very-light), $ag-row-disabled-opacity) !important;

        .metering-description-before-toggle,
        .supplier-cell-value {
          opacity: $ag-row-disabled-opacity;
        }
        .metering-description-toggle,
        .supplier-cell-value-radio {
          opacity: 1;
        }
      }
    }

    &.ag-row-chapter {
      font-weight: 600;
    }
    &.ag-row-category-chapter {
      background-color: $purple-light;
    }

    &.ag-row-chapter-not-level-1 {
      > .ag-cell {
        background-color: $purple-very-light;
      }
    }
    &.ag-row-post {
      color: $black-100;
    }
    &.ag-row-post-detail {
      color: $merlin-blue-dark;
    }
    &.ag-row-error-light {
      > .ag-cell {
        background-color: $red-light;
      }
    }
    &.ag-row-error {
      > .ag-cell {
        background-color: $red-mid;
      }
    }
    &.ag-row-warning-light {
      > .ag-cell {
        background-color: $yellow-warning-light;
      }
    }
    &.ag-row-warning {
      > .ag-cell {
        background-color: $yellow-warning;
      }
    }
  }

  .ag-center-cols-container > .ag-row {
    &.disabled-row {
      border-bottom: solid 1px
        rgba(red($gray-very-light), green($gray-very-light), blue($gray-very-light), $ag-row-disabled-opacity) !important;
    }
  }
}

.ag-theme-material .ag-ltr .ag-cell {
  border-left: none !important;
}

.ag-cell:not(.metering-cell-tags-container) > div {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
}

.ag-cell.ag-selection {
  padding: 0 !important;
}

.ag-cell.metering-cell-tags-container {
  padding: 0 5px !important;

  > div {
    display: flex;
    vertical-align: middle;

    .ui-tag .ui-tag__label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.ag-row-level-1 > .ag-cell > * {
  border: none;
}

ag-grid-aurelia.ag-theme-material.datagrid .ag-cell.ag-cell-not-inline-editing .ag-cell-wrapper {
  display: flex;
  align-items: center;
}
.metering-line-description {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  > ui-icon {
    font-size: 1.2em;
    color: $purple-mid;
  }
  > div.metering-line-type-description-overflow {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    height: $description-content-height;
  }
}

.metering-line-type-description {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: $description-content-height;

  > ui-icon {
    font-size: 1.2em;
    color: $blue-mid;
  }

  .metering-description-before-toggle {
    display: flex;
    gap: 12px;
    align-items: center;
    overflow: hidden;
    flex: 1;
    // min-width: 90px;
  }

  .metering-description-toggle {
    display: flex;
    align-items: center;
  }

  div.metering-line-type-description-overflow {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 20px;
    height: $description-content-height;
  }
}
.metering-line-color {
  padding: 3px;
  height: 100%;
  position: absolute;
  left: 0;
}

.metering-line-type-normal {
  .metering-line-color {
    background-color: #6b7cea;
    opacity: 0.6;
  }
}
.metering-line-type-option {
  .metering-line-color {
    background-color: #a06ce1;
    opacity: 0.6;
  }
}
.metering-line-type-variant {
  .metering-line-color {
    background-color: #f2a74f;
    opacity: 0.6;
  }
}
.metering-line-type-omission {
  .metering-line-color {
    background-color: #f65f5f;
    opacity: 0.6;
  }
}
.metering-line-type-reduction {
  .metering-line-color {
    background-color: #15ce1e;
    opacity: 0.6;
  }
}

.metering-description-toggle {
  .ui-option__control > input:checked ~ .ui-option__toggle {
    background-color: $blue-mid;
  }
}
ui-button.metering-btn-file {
  display: flex;
  justify-content: end;
  align-items: center;
  border: solid 1px transparent !important;
  background-color: transparent !important;
  .ui-btn__inner {
    width: 50px;
  }
  &:hover {
    .ui-btn__icon > .ui-icon {
      transform: scale(1.3);
    }
  }
  .ui-btn__icon {
    color: $blue-mid;
    padding-bottom: 0.7em;
  }
}
.ui-select--focus {
  border-color: $blue-mid !important;
}

.supplier-quantity-modified {
  color: $merlin-orange-dark;
}

.inherit-coefficient {
  color: #9448bc;
}

.has-price-repartition {
  color: $gray-lighter;
}

.manual-coefficient {
  color: $blue-mid;
}

.has-price-repartition-error {
  color: $red-warning;
}

.inherit-enum {
  > div > .ui-tag {
    color: #9448bc;
  }
}

.metering-cell-inherit {
  color: $blue-mid;
  &.inherit-enum {
    color: #9448bc;
  }
}

.metering-expand {
  position: fixed;
  top: 0;
  right: 0;
  left: 238px;
  z-index: 150;
  border: 5px solid $blue-darkest;
  border-radius: 24px;
  > .ui-page__ribbon-content {
    display: none;
  }
  > .ui-page__body {
    background-color: $black-0;
    margin-right: 5px;
    margin-top: 5px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    > ui-content > .row {
      display: none;
    }
    > .metering-price-offer-lines > price-offer-lines-grid,
    ui-content > div.metering-price-offer-lines > price-offer-lines-grid {
      overflow: auto;

      .grid-header-bar > .left {
        display: none;
      }
      .ag-theme-material {
        .ag-root-wrapper > .ag-root-wrapper-body {
          height: 100%;
          min-height: initial;
        }
      }
    }
  }
}

.condensed
  > .ui-viewport
  > .ui-viewport__body
  > .ui-viewport-center
  > ui-tab-router-view
  > .ui-router-view
  > router-view
  > router-view
  > .ui-page.metering-expand,
.condensed
  > .ui-viewport
  > .ui-viewport__body
  > .ui-viewport-center
  > ui-tab-router-view
  > router-view
  > .ui-page.metering-expand {
  left: 60px;
}

.condensed-row .metering-line-color::before {
  height: calc(var(--parentTop) + 15px) !important;
}

// METERING LINE COLOR
$line-color: #6b7cea;

.ag-pinned-left-cols-container .ag-row:not(.ag-row-level-0) {
  &.ag-row-hover {
    &::before {
      background-color: transparent;
    }

    .ag-cell:not(:first-child) {
      background-color: var(--ag-row-hover-color, #fafafa);
    }
  }

  .ag-cell.ag-selection {
    overflow: visible;
  }

  .metering-line-color {
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: calc(50% - 5px);
      margin-left: -19px;
      border-color: $line-color;
      border-width: 1px;
      width: 18px;
      border-style: solid;
      border-bottom-left-radius: 5px;
      height: 10px;
      border-top: none;
      border-right: none;
      border-left: 1px solid transparent;
    }

    &::before {
      content: "";
      background-color: $line-color;
      width: 1px;
      position: absolute;
      top: -2px;
      margin-left: -18px;
      height: calc(100% + 2px);
    }
  }

  &.ag-row-last-row {
    .metering-line-color {
      &::before {
        content: "";
        background-color: $line-color;
        width: 1px;
        position: absolute;
        top: calc(-1 * var(--parentTop) - 2px);
        margin-left: -18px;
        height: calc(var(--parentTop) + 21px);
      }

      &::after {
        border-left: 1px solid $line-color;
        margin-left: -18px;
      }
    }
  }
}

.ag-cell-focus .metering-line-color::before,
.ag-cell-focus:not(.ag-row-last-row) .metering-line-color::after {
  margin-left: -19px !important;
}
// EO METERING LINE COLOR

.ag-cell-value[col-id="proposedQuantity"] {
  display: flex;
  align-items: center;
  > input {
    flex: 1;
  }
}

.metering-price-origin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > ui-icon {
    color: $blue-mid;
  }
  > span {
    &.span-tooltip {
      text-align: left;
      > ui-icon {
        color: $blue-mid;
      }
    }
    &:not(.span-tooltip) {
      width: 100%;
    }
  }
}

.ag-flat-list {
  .ag-cell-wrapper.ag-row-group-leaf-indent {
    margin-left: 0;
  }
}

$indent: 15px;
.ag-tree-list {
  .ag-ltr {
    .ag-group-contracted,
    .ag-group-expanded {
      margin-right: $indent;
    }
  }

  .ag-cell-wrapper.ag-row-group-leaf-indent {
    margin-left: calc(18px + #{$indent});
  }
}
