import { ICellRendererParams } from "ag-grid-community";
import * as Constants from '../../../constants';
import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { PriceOfferLinesGrid } from "price-offer-lines/price-offer-lines-grid";
import { IDataLineApiService, MeteringTotalPrices } from "services/i-data-line-api-service";
import { GlobalLoaderService } from "digiwall-lib";

@autoinject
export class MenuPaste {
  constructor() { }
  public getMenu(params: ICellRendererParams, localeStorageName: Constants.LocalStorageName, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string, dataLineApiService: IDataLineApiService, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any) {
    let i18n = Container.instance.get(I18N);

    let menuItem =
    {
      id: Constants.CommonMeterginDataLineMenuItems.PASTE,
      label: i18n.tr("versionmetering.paste"),
      icon: "digi-paste",
      disabled: () => {
        let ids = JSON.parse(localStorage.getItem(localeStorageName))
        return ids == null || ids?.length == 0

      },
      items: [
        {
          id: Constants.CommonMeterginDataLineMenuItems.MOVE_UP,
          label: i18n.tr("versionmetering.onTopOfLine"),
          icon: "digi-arrow-up-line",
          handler: async () => {
            await this.pasteLine(params.node.id, Constants.PriceOfferLineMoveAction.Up, priceOfferLinesGrid, localeStorageName, versionId, dataLineApiService, getMeteringTotalPrices, _this);
          },
          disabled: () => {
            return this.cannotPasteLine(params.node.id, Constants.PriceOfferLineMoveAction.Up, localeStorageName, priceOfferLinesGrid);
          }
        },
        {
          id: Constants.CommonMeterginDataLineMenuItems.MOVE_CHILD,
          label: i18n.tr("versionmetering.childOfLine"),
          icon: "digi-arrow-right-line",
          handler: async () => {
            await this.pasteLine(params.node.id, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, localeStorageName, versionId, dataLineApiService, getMeteringTotalPrices, _this);
          },
          disabled: () => {
            return this.cannotPasteLine(params.node.id, Constants.PriceOfferLineMoveAction.Into, localeStorageName, priceOfferLinesGrid);
          }

        },
        {
          id: Constants.CommonMeterginDataLineMenuItems.MOVE_DOWN,
          label: i18n.tr("versionmetering.belowLine"),
          icon: "digi-arrow-down-line",
          handler: async () => {
            await this.pasteLine(params.node.id, Constants.PriceOfferLineMoveAction.Down, priceOfferLinesGrid, localeStorageName, versionId, dataLineApiService, getMeteringTotalPrices, _this);
          },
          disabled: () => {
            return this.cannotPasteLine(params.node.id, Constants.PriceOfferLineMoveAction.Down, localeStorageName, priceOfferLinesGrid);
          }
        }
      ]
    };
    return menuItem
  }

  private async pasteLine(targetRowId: string, action: Constants.PriceOfferLineMoveAction, priceOfferLinesGrid: PriceOfferLinesGrid, localeStorageName: Constants.LocalStorageName, versionId: string, dataLineApiService: IDataLineApiService, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any) {
    let linesToPastes = this.getLineToPastes(localeStorageName);

    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId)!;
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }
    // Move nodes

    let globalLoaderService = Container.instance.get(GlobalLoaderService);

    globalLoaderService.allow(true, 100);

    let ids = await dataLineApiService.paste(parseInt(versionId), linesToPastes.map(x => x.id), targetNode.data.id, action);
    priceOfferLinesGrid.gridOptions.api.hideOverlay();

    // Refresh modified rows
    priceOfferLinesGrid.refreshServerSideRows(ids, targetNode.data.parentId == null);



    localStorage.removeItem(localeStorageName)
    getMeteringTotalPrices(versionId, dataLineApiService, _this);

    if (action == Constants.PriceOfferLineMoveAction.Into) {
      targetNode.setExpanded(true);
    }
  }


  private cannotPasteLine(targetRowId: string, action: Constants.PriceOfferLineMoveAction, localeStorageName: Constants.LocalStorageName, priceOfferLinesGrid: PriceOfferLinesGrid,) {
    let linesToPastes = this.getLineToPastes(localeStorageName);
    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId)!;
    if (!targetNode) {
      console.warn('Target node not found!');
      return true;
    }
    if (linesToPastes == null) {
      return true;
    }
    if (linesToPastes.find(x => x.categoryId == Constants.PriceOfferLineCategory.Chapter) != null && action == Constants.PriceOfferLineMoveAction.Into) {
      return true;
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && action == Constants.PriceOfferLineMoveAction.Into) {
      return true;
    }
    return false;
  }

  private getLineToPastes(localeStorageName: Constants.LocalStorageName): Array<{ id: number, categoryId: number }> {
    let linesToPaste = JSON.parse(localStorage.getItem(localeStorageName));
    return linesToPaste;
  }
}
