import { HttpClient } from 'aurelia-fetch-client';
import { Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { Box, CustomLogger, IMenuItems, ServiceBase } from 'digiwall-lib';
import { DocumentService } from "documents/document-service";
import { ICellRendererParams } from 'ag-grid-community';
import * as Constants from '../../../constants';
import { DialogService } from 'aurelia-dialog';
import { ProjectApiService } from 'services/project-api-service';
import { AddDocument } from 'documents/add-document';
import { Merlin } from 'generated';
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';

export class MenuAddDocument {
  public static getMenu(params: ICellRendererParams, projectService: ServiceBase<Merlin.Web.Model.Project>, priceOfferLinesGrid: PriceOfferLinesGrid, projectId: string, workQuotationVersionId: string, priceOfferVersionId: string): IMenuItems {
    let i18n = Container.instance.get(I18N);
    let menuItem = {
      id: Constants.CommonMeterginDataLineMenuItems.ADD_DOCUMENTS,
      label: i18n.tr("versionmetering.adddocument"),
      icon: "digi-folder-add-line",
      handler: () => {
        this.addPriceOfferLineDocument(params.node.data.id, projectService, priceOfferLinesGrid, projectId, workQuotationVersionId, priceOfferVersionId);
      }
    };
    return menuItem;
  }

  private static async addPriceOfferLineDocument(priceOfferLineId: string, projectService: ServiceBase<Merlin.Web.Model.Project>, priceOfferLinesGrid: PriceOfferLinesGrid, projectId: string, workQuotationVersionId: string, priceOfferVersionId: string) {
    let documentService = new DocumentService(
      Container.instance.get(HttpClient),
      Container.instance.get(I18N),
      Container.instance.get(Box),
      Container.instance.get(CustomLogger),
    )
    let dialogService = Container.instance.get(DialogService);
    documentService.projectId = parseInt(projectId);
    documentService.priceOfferVersionId = parseInt(priceOfferVersionId) ?? null;
    documentService.workQuotationVersionId = parseInt(workQuotationVersionId) ?? null;
    documentService.lineId = parseInt(priceOfferLineId);
    documentService.projectPhaseId = Constants.ProjectPhaseId.PriceOffer;
    let defaultLanguageId = (await projectService.getEntityById(parseInt(projectId))).communicationLanguageId;
    await dialogService.open({
      viewModel: AddDocument,
      model: {
        service: documentService,
        folderName: "project/" + projectId + "/workQuotationVersion/" + workQuotationVersionId + "/line/" + priceOfferLineId,
        defaultLanguageId: defaultLanguageId,
        documentModuleId: Constants.DocumentModuleId.Project
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("z-index-level-4");
        dialogOverlay.classList.add("z-index-level-4");
      },
    }).whenClosed(result => {
      priceOfferLinesGrid.refreshVisibleNodes([parseInt(priceOfferLineId)]);
    });

  }
}
