import { EventAggregator } from 'aurelia-event-aggregator';
import { DataFormat } from 'select2';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, EntityDetailViewModelBase, ServiceBase, Various, GlobalLoaderService } from 'digiwall-lib';
import { autoinject, PassThroughSlot } from "aurelia-framework";
import { Merlin } from 'generated';
import * as Constants from '../../constants';
import { DialogController, DialogService } from 'aurelia-dialog';
import { isThisSecond } from 'date-fns';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { MeteringTotalPrices } from 'services/i-data-line-api-service';


@autoinject
export class DebugTotalPricesError {
  private previousTotalPrices: MeteringTotalPrices;
  private newTotalPrices: MeteringTotalPrices;
  private nbDecimalForPriceDisplay: number;
  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController) {
  }


  async activate(params) {
    this.previousTotalPrices = params.previousTotalPrices;
    this.newTotalPrices = params.newTotalPrices;
    this.nbDecimalForPriceDisplay = params.nbDecimalForPriceDisplay
  }

  async close() {
    await this.dialogController.close(false);
  }
}
