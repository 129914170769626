import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { Merlin, Shared } from 'generated';
import * as Constants from '../constants';
import { ActionDialogBoxInputParameters, Box, CustomLogger, DialogBoxViewModel, KeyboardShortcut, ServiceBase } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { FilterQueryOp, Predicate } from 'breeze-client';

//#region common
export interface IDocumentService {
  saveDocument(entity: DocumentData);
  createDocument(documents: Array<DocumentData>);
  getDocument(id: number): Promise<DocumentData>;
}

export interface DocumentData {
  id: number | null;

  languageId: number | null;
  language: Shared.Model.EnumerationDTO;

  url: string;
  name: string;
  description: string;
  size: number;
  fileTypeId: number | null;
  fileType: Shared.Model.EnumerationDTO;

  documentModuleId: number;
  documentModule: Shared.Model.EnumerationDTO;
  documentName: string;
  documentStatusId: number;
  documentStatus: Shared.Model.EnumerationDTO;
  receptionDate: Date | string;
  sourceId: number | null;
  toUseAsAnnex: boolean | null;

  documentTags: Array<number>;
}

//#endregion

@autoinject
export class DocumentService implements IDocumentService {
  public projectId: number;
  public workQuotationVersionId: number | null;
  public priceOfferVersionId: number | null;
  // public versionId: number;
  public lineId: number;
  public projectPhaseId: number;
  public itemId: number;
  public taskId: number;


  public priceOfferLineDocumentService: ServiceBase<Merlin.Web.Model.PriceOfferLineDocument>;
  public projectDocumentService: ServiceBase<Merlin.Web.Model.ProjectDocument>;
  public priceOfferVersionDocumentService: ServiceBase<Merlin.Web.Model.PriceOfferVersionDocument>

  constructor(private httpClient: HttpClient, private i18n: I18N, private box: Box, private logger: CustomLogger) {
    this.priceOfferLineDocumentService = new ServiceBase<Merlin.Web.Model.PriceOfferLineDocument>(Constants.EntityTypeNames.PriceOfferLineDocument);
    this.projectDocumentService = new ServiceBase<Merlin.Web.Model.ProjectDocument>(Constants.EntityTypeNames.ProjectDocument);
    this.priceOfferVersionDocumentService = new ServiceBase<Merlin.Web.Model.PriceOfferVersionDocument>(Constants.EntityTypeNames.PriceOfferVersionDocument);
  }

  async createDocument(documents: Array<DocumentData>) {
    let params: RequestCreateDocumentParams = {
      projectId: this.projectId,
      // versionId: this.versionId,
      workQuotationVersionId: this.workQuotationVersionId,
      priceOfferVersionId: this.priceOfferVersionId,
      lineId: this.lineId,
      projectPhaseId: this.projectPhaseId,
      itemId: this.itemId,
      taskId: this.taskId,
      documentDatas: documents
    }

    let query = await this.httpClient.post(Constants.Application.DocumentController.Create, JSON.stringify(params));
    return query.ok;

  }

  async getDocument(id: number): Promise<DocumentData> {
    let requestUri = Constants.Application.DocumentController.GetDocument.format(id.toString());
    let result = await this.httpClient.get(requestUri);
    if (result.ok) {
      let documentData = await result.json();
      return documentData
    }

  }

  async saveDocument(document: DocumentData): Promise<boolean> {
    let query = await this.httpClient.put(Constants.Application.DocumentController.SaveDocument, JSON.stringify(document));
    return query.ok;
  }

  async delete(documentId: number, projectPhaseId?: number) {
    let msg = this.i18n.tr('menu.delete_confirmation');
    let ids = [];

    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.ok", { ns: "common" }),
      title: this.i18n.tr("general.ok", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      },
      keyboardShortcut: KeyboardShortcut.Enter
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.cancel", { ns: "common" }),
      title: this.i18n.tr("general.cancel", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      },
      keyboardShortcut: KeyboardShortcut.Escape
    };
    await this.box.showQuestion(msg, this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes])
      .whenClosed(async (result) => {
        if (!result.wasCancelled && result.output) {
          return await this.deleteDocument(documentId, projectPhaseId);
        }
      });
    return false;
  }

  public async deleteDocument(documentId: number, projectPhaseId: number) {
    let predicate = new Predicate("documentId", FilterQueryOp.Equals, documentId);
    if (projectPhaseId != null) {
      predicate = predicate.and(new Predicate("projectPhaseId", FilterQueryOp.Equals, projectPhaseId));
    }
    let projectDocuments = await this.projectDocumentService.getEntities(predicate);
    let priceOfferVersionDocuments = await this.priceOfferVersionDocumentService.getEntities(predicate);
    let priceOfferLineDocuments = await this.priceOfferLineDocumentService.getEntities(predicate);

    let isDeleted = false;
    if (projectDocuments.length != 0) {
      isDeleted = await this.projectDocumentService.deleteEntities(projectDocuments, true, null, true);
    }
    if (priceOfferVersionDocuments.length != 0) {
      isDeleted = await this.priceOfferVersionDocumentService.deleteEntities(priceOfferVersionDocuments, true, null, true);
    }
    if (priceOfferLineDocuments.length != 0) {
      isDeleted = await this.priceOfferLineDocumentService.deleteEntities(priceOfferLineDocuments, true, null, true);
    }
    return isDeleted;
  }
}

export interface RequestCreateDocumentParams {
  projectId: number | null;
  workQuotationVersionId: number | null;
  priceOfferVersionId: number | null;
  // versionId: number | null;
  lineId: number | null;
  projectPhaseId: number;
  itemId: number | null;
  taskId: number | null;
  documentDatas: Array<DocumentData>;
}


