import { Router } from 'aurelia-router';
import { Merlin } from 'generated';
import { I18N } from 'aurelia-i18n';
import { Container } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { ActionDialogBoxInputParameters, Box, DialogBoxViewModel, GlobalLoaderService, ServiceBase, UIInternal } from "digiwall-lib";
import * as toastr from 'toastr';
import * as Constants from '../constants';
import { ProjectTeams } from 'projects/project-teams';

export class StartProject {
  public static async startProject(projectId: number) {
    let box = Container.instance.get(Box);
    await this.startQuestion(projectId, box);
  }

  private static async startQuestion(projectId: number, box: Box) {
    let i18n = Container.instance.get(I18N);
    let isSaving: boolean = false;

    let startButton: ActionDialogBoxInputParameters =
    {
      label: i18n.tr("general.yes", { ns: "common" }),
      title: i18n.tr("general.yes", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: async (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok();
        if (!isSaving) {
          isSaving = true;
        }
      }
    };
    let buttonCancel: ActionDialogBoxInputParameters =
    {
      label: i18n.tr("general.no", { ns: "common" }),
      title: i18n.tr("general.no", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.cancel();
      }
    };
    await box.showQuestion(i18n.tr('project.startProjectQuestion'), i18n.tr('menu.question'), [buttonCancel, startButton])
      .whenClosed(async (result) => {
        if (!result.wasCancelled) {
          await this.checkTeamAreCompleted(projectId);
        }
      });
  }

  private static async checkTeamAreCompleted(projectId: number) {
    let box = Container.instance.get(Box);
    let teamsAreCompleted = await ProjectTeams.areProjectIdInternalTeamsCompleted(projectId);
    if (!teamsAreCompleted) {
      let view = new ProjectTeams(projectId);
      await box.showFloatingBox(view, false, false).whenClosed(async (result) => {
        if (!result.wasCancelled) {
          await this.startProjectRequest(projectId);
        }
      });
    } else {
      await this.startProjectRequest(projectId);
    }
  }

  private static async startProjectRequest(projectId: number) {
    let i18n = Container.instance.get(I18N);

    const router = Container.instance.get(Router);
    let httpClient = Container.instance.get(HttpClient);
    Container.instance.get(GlobalLoaderService).allow();
    let result = await httpClient.get(Constants.Application.ProjectController.StartProject.format(projectId.toString()));
    if (result.ok) {
      toastr.success(i18n.tr("project.projectStarted"));
      // Force refresh to enable some menu's
      router.navigateToRoute(
        router.currentInstruction.config.name,
        router.currentInstruction.params,
        { replace: true }
      );
    }
    else {
      toastr.error(await result.text());
    }
  }

}
