import { autoinject, bindable, BindingEngine, bindingMode, customElement, Disposable } from "aurelia-framework";
import { Router } from "aurelia-router";
import { sub } from "date-fns";
import de from "date-fns/esm/locale/de/index.js";
import { Config, MenuGroupInstance, UIInternal } from "digiwall-lib";

@customElement("ui-horizontal-menu")
@autoinject
export class UIHorizontalMenu {
  @bindable
  public router: Router;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  private newAuthorizedRoutes: Array<MenuGroupInstance>;

  private groupSelected: MenuGroupInstance;
  private subGroupSelected: MenuGroupInstance;
  private groupDisposable: Array<Disposable> = [];
  private subGroupDisposable: Array<Disposable> = [];

  constructor(private bindingEngine: BindingEngine, private config: Config) {

  }

  bind() {
    UIInternal.subscribe('router:navigation:complete', () => {
      this.selectedCurrentRoute();
    });
    setTimeout(() => {
      this.selectedCurrentRoute();
    }, 500);
  }
  private selectedCurrentRoute() {
    this.groupSelected = null;
    this.subGroupSelected = null;
    this.newAuthorizedRoutes?.forEach(menu => {
      let name: string;
      if (this.router.currentInstruction.config.nav == true) {
        name = this.router.currentInstruction.config.name;
      } else {
        name = this.router.currentInstruction.config.settings.parentName;
      }

      if (menu.children == null || menu.children.length == 0) {
        if (menu.route == this.router.currentInstruction.fragment || (name != null && menu.routeName == name)) {
          this.newAuthorizedRoutes.find(x => x.isSelected)?.setSelected(false);
          menu.setSelected();
        }
      } else {
        menu.children.forEach(subMenu => {
          if (subMenu.children == null || subMenu.children.length == 0) {
            if (subMenu.route == this.router.currentInstruction.fragment || (name != null && subMenu.routeName == name)) {
              this.newAuthorizedRoutes.find(x => x.isSelected)?.setSelected(false);
              this.groupSelected = menu;
              subMenu.setSelected();
            }
          } else {
            subMenu.children.forEach(children => {
              if (children.route == this.router.currentInstruction.fragment || (name != null && children.routeName == name)) {
                this.newAuthorizedRoutes.find(x => x.isSelected)?.setSelected(false);
                this.groupSelected = menu;
                this.subGroupSelected = subMenu;
                children.setSelected();
              }
            })
          }
        })
      }
    });
  }

  public onViewChildren(menu: MenuGroupInstance) {
    if (menu.level == this.config.menuConfig.maxLevelVerticalNavbar) {
      this.newAuthorizedRoutes.find(x => x.isSelected)?.setSelected(false);
      this.groupSelected = menu;
      if (menu.children?.length > 0) {
        let subGroupDefault = menu.children.find(x => x.isDefault);
        if (subGroupDefault == null) {
          subGroupDefault = menu.children[0];
        }
        this.subGroupSelected = subGroupDefault;
        if (subGroupDefault.children?.length > 0) {
          subGroupDefault.viewChildren = true;
          let defaultChild = subGroupDefault.children.find(x => x.isDefault);
          if (defaultChild == null) {
            defaultChild = subGroupDefault.children[0];

          }
          defaultChild.setSelected(true);
          this.router.navigate(defaultChild.route);

        } else {
          if (subGroupDefault.route != null) {
            subGroupDefault.setSelected(true);
            this.router.navigate(subGroupDefault.route);
          }
        }
      }
    } else {
      if (menu.viewChildren && menu.level > this.config.menuConfig.maxLevelVerticalNavbar) {
        this.subGroupSelected = menu;
      }
    }
  }

  selectedSubGroup(subGroup: MenuGroupInstance) {
    if (!subGroup.isSelected) {
      let findSelected = this.groupSelected.children.find(x => x.isSelected);
      if (findSelected != null) {
        findSelected.viewChildren = false;
        findSelected.setSelected(false);
      }
    }
    if (subGroup.children?.length > 0) {
      subGroup.viewChildren = true;
      this.subGroupSelected = subGroup;

      let defaultSubGroup = subGroup.children.find(x => x.isDefault);
      if (defaultSubGroup == null) {
        defaultSubGroup = subGroup.children[0];

      }
      defaultSubGroup.viewChildren = true;
      if (defaultSubGroup.children != null && defaultSubGroup.children.length > 0) {
        let defaultChild = defaultSubGroup.children.find(x => x.isDefault);
        if (defaultChild == null) {
          defaultChild = defaultSubGroup.children[0];
        }
        defaultChild.setSelected(true);
        this.router.navigate(defaultChild.route);
      } else {
        defaultSubGroup.setSelected(true);
        this.router.navigate(defaultSubGroup.route);
      }
    } else {
      subGroup.setSelected(true);
      this.router.navigate(subGroup.route);
    }

  }

  selectedChild(child: MenuGroupInstance) {
    if (!child.isSelected) {
      let findSelected = this.subGroupSelected.children.find(x => x.isSelected);
      if (findSelected != null) {
        findSelected.setSelected(false);
      }
    }
    child.setSelected(true);
    this.router.navigate(child.route);
  }
}
