import { ClientAdditionalWorkStatusTypeId } from './../../../../constants';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DataFormat } from 'select2';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, EntityDetailViewModelBase, ServiceBase, Various, GlobalLoaderService } from 'digiwall-lib';
import { autoinject, PassThroughSlot } from "aurelia-framework";
import { Merlin } from 'generated';
import * as Constants from '../../../../constants';
import { DialogController, DialogService } from 'aurelia-dialog';
import { isThisSecond } from 'date-fns';
import { FilterQueryOp, Predicate } from 'breeze-client';


@autoinject
export class AdditionalWorkClone {

  private clientAdditionalWorkVersionService: ServiceBase<Merlin.Web.Model.ClientAdditionalWorkVersion>;
  private clientAdditionalWorkVersionSelected: DataFormat;
  private clientAdditionalWorkVersionSelectedText: string;
  private projectId: number;
  private additionalWorkId: number;

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController, private httpClient: HttpClient, private globalLoaderService: GlobalLoaderService) {
    this.clientAdditionalWorkVersionService = new ServiceBase<Merlin.Web.Model.ClientAdditionalWorkVersion>(Constants.EntityTypeNames.ClientAdditionalWorkVersion);
  }

  documentTitle = "";
  ressourceName = "";

  async activate(params: { projectId: number, additionalWorkId: number, additionalWorkVersionId: number }) {
    if (params.projectId != null) {
      this.projectId = params.projectId;
      this.additionalWorkId = params.additionalWorkId;

      let projectPredicate = new Predicate("additionalWorkId", FilterQueryOp.Equals, this.additionalWorkId);
      let rejectPredicate = new Predicate("statusId", FilterQueryOp.Equals, ClientAdditionalWorkStatusTypeId.Rejected);
      let sendPredicate = new Predicate("statusId", FilterQueryOp.Equals, ClientAdditionalWorkStatusTypeId.Sent);
      this.clientAdditionalWorkVersionService.gridDataSource.customSelect2Predicates = () => {
        return projectPredicate.and(rejectPredicate.or(sendPredicate));
      }
      let currentAdditionalWork = await this.clientAdditionalWorkVersionService.getEntityById(params.additionalWorkVersionId);
      this.clientAdditionalWorkVersionSelected = { id: currentAdditionalWork.id, text: currentAdditionalWork.versionId.toString() };
    }
    else {
      return false;
    }

  }

  async close() {
    await this.dialogController.close(false);
  }

  async save() {
    if (this.clientAdditionalWorkVersionSelected != null) {
      this.globalLoaderService.allow();
      let response = await this.httpClient.post(Constants.Application.AdditionalWorkController.CloneVersion.format(this.clientAdditionalWorkVersionSelected.id.toString()));
      if (response.ok) {
        const newVersionId = await response.json();
        this.closeAndNavigateToNewVersion(newVersionId);
      }

    } else {
      this.closeAndNavigateToNewVersion();
    }
  }

  private async closeAndNavigateToNewVersion(newVersion = null) {
    if (newVersion != null) {
      this.dialogController.close(true, newVersion);
    }
    else {
      this.dialogController.close(false);
    }
  }
}
