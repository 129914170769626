import { Shared } from "generated";

export class TaskDTO {
  id: number;
  taskType: Shared.Model.EnumerationDTO;
  title: string;
  description: string;
  startDateTime: Date | string | null;
  deadlineDate: Date | string | null;
  taskStatus: Shared.Model.EnumerationDTO;
  taskResultComment: string;
  //taskCriticityLevelId: number | null;
  taskCriticityLevel: Shared.Model.EnumerationDTO;

  isSubTask: boolean;
  parentTaskId: number | null;

  responsibleId: number;
  responsibleUri: string;
  responsibleFullName: string;

  projectId: number;

  createdById: number | null;
  createdByFullName: string;
  createdByUri: string;
  createdTime: Date | string;

  updatedByFullName: string;
  updatedTime: Date | string;
  updatedById: number | null

  path: string;
  hasChildren: boolean;
  subTasks: number[];
  subTaskTitles: string[];
  taskTagIds: number[];
  taskTags: Shared.Model.EnumerationDTO[];
  taskJobFiles: TaskFileDTO[];
}

export interface TaskFileDTO {
  id: number;
  url: string;
  name: string;
  description: string;
  size: number;
  fileTypeId: number | null;
  fileTypeName: string;
  taskJobId: number | null;
}
