@import "./_variables";

ul.navigation-detail2 {
  display: flex;
  align-items: center;
  padding-left: 34px !important;
  color: $gray-light;
  border-bottom: 1px solid $purple-lighter;
  margin: 0 !important;
  gap: 20px;
  padding: 0px 0px 0px 42px;

  > li {
    padding: 10px;
    padding: 11px !important;
    &:hover {
      color: $black-100;
    }
  }
  & > li.active {
    color: $black-100;
    border-bottom: solid 1px $blue-mid;
  }
}
