import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Various, IMenuGroup } from 'digiwall-lib';
import { autoinject, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { TaskDetail } from 'tasks/task-detail';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';

@autoinject
export class WorkingSitePreparationTemplateDetail extends EntityDetailViewModelBase<Merlin.Web.Model.WorkingSitePreparationTemplate> {
  public ressourceName: string = Constants.EntityTypeNames.WorkingSitePreparationTemplate;
  private menuItems: Array<IMenuGroup>;
  private taskService: ServiceBase<Merlin.Web.Model.TaskJob>;


  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.WorkingSitePreparationTemplate>(Constants.EntityTypeNames.WorkingSitePreparationTemplate));
    this.taskService = new ServiceBase<Merlin.Web.Model.TaskJob>(Constants.EntityTypeNames.TaskJob);
  }

  @computedFrom('editingMode', 'entity.templateName')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("workingsitepreparationtemplate.addworkingsitepreparationtemplate");
    }
    else {
      return this.entity.templateName;
    }
  }

  public async activate(params: any) {
    let id = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.active = true;
      if (params.callback != null) params.callback(this.entity);
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'taskJobs.taskType', 'taskJobs.taskCriticityLevel', 'taskJobs.taskTags.taskTag');
      this.controller.addObject(this.entity);
    }
    this.setMenuItems();
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: async () => {
              await this.delete()
            }
          }
        ]
      },
    ];
  }

  getMenuItems(task: Merlin.Web.Model.TaskJob) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.edit"),
            icon: "digi-edit",
            handler: async () => {
              this.editTask(task);
            }
          },
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: async () => {
              await this.deleteTask(task);
            }
          }
        ]
      }
    ]
  }

  addTask() {
    this.box.showEditDialog(TaskDetail, Various.NewId, this.i18n.tr('taskjob.addTask'), (VM: TaskDetail) => {
      VM.entity.isTemplate = true;
      VM.entity.workingSitePreparationTemplateId = this.entity.id
    },
      {
        canSave: false,
        actions: [
          EditDialogAction.GetSaveAllAction(this.i18n)
        ]
      }).whenClosed(async result => {
        if (!result.wasCancelled) {

        }
      });
  }

  editTask(task: Merlin.Web.Model.TaskJob) {
    this.box.showEditDialog(TaskDetail, task.id, this.i18n.tr('taskjob.task'), null,
      {
        canSave: false,
        actions: [
          EditDialogAction.GetSaveAllAction(this.i18n)
        ]
      }).whenClosed(async result => {
        if (!result.wasCancelled) {

        }
      });
  }

  async deleteTask(task: Merlin.Web.Model.TaskJob) {
    await this.taskService.deleteEntities([task], true);
  }
}
