import { HttpClient } from 'aurelia-fetch-client';
import { RouterConfiguration, Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, UIInternal, GlobalLoaderService, ViewModelBase } from 'digiwall-lib';
import * as Constants from '../../constants';
import { Merlin } from 'generated';
import { observable } from 'aurelia-binding';
import { PLATFORM, autoinject } from 'aurelia-framework';
import { DataFormat } from 'select2';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { CommomMetering } from './common-metering';
import { WorkQuotationVersionApiService } from 'services/work-quotation-version-api-service';
import { PriceOfferLineCommentApiService } from 'services/price-offer-line-comment-api-service';
import { PriceRepartitionService } from 'services/price-repartition-service';
import { VersionMenuItems } from './version-menu-items';
import { IndexHelper } from 'resources/elements/index-helper';

@autoinject
export class VersionIndex extends CommomMetering<Merlin.Web.Model.WorkQuotationVersion> {
  resfrehMenu = true;
  @observable
  selectedQuote: {
    id: number | string;
    text: string;
    isCurrentVersion: boolean;
    selected?: boolean | undefined;
    disabled?: boolean | undefined;
  };
  workQuotationVersionService: ServiceBase<Merlin.Web.Model.WorkQuotationVersion>;
  projectId: string;

  documentTitle = "";
  ressourceName = "";

  /**
   *
   */
  constructor(public router: Router, logger: CustomLogger, httpClient: HttpClient, priceOfferVersionApiService: WorkQuotationVersionApiService, globalLoaderService: GlobalLoaderService, priceOfferLineCommentApiService: PriceOfferLineCommentApiService, priceRepartitionService: PriceRepartitionService) {
    super(router, logger, httpClient, priceOfferVersionApiService, globalLoaderService, priceOfferLineCommentApiService, priceRepartitionService);
    this.workQuotationVersionService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersion>(Constants.EntityTypeNames.WorkQuotationVersion);
  }
  async selectedQuoteChanged(newValue, oldValue) {
    if (newValue != null && newValue != oldValue && newValue.id > 0 && newValue?.id != oldValue?.id && this.router != null) {
      this.getCurrentVersion(newValue.id);

      let currentRouteName = this.router.currentInstruction?.config?.name;// ?? "summary";

      // If current routing is 'quote' without param versionId, replace the current navigation (see #13024)
      if (currentRouteName == null && this.router.parent.parent.isNavigating && newValue != null && document.location.href.match("quote/summary\\?projectId=") != null) {// navigate from project-index:quote, redirect with the newValue
        let uri = this.router.parent.generate("quote", { "workQuotationVersionId": newValue.id });
        this.router.parent.navigate(uri, { "replace": true });
        return;
      }

      if (this.router.isNavigating || this.router.parent.isNavigating || this.router.parent.parent.isNavigating) return;
      let uri = "";
      if (currentRouteName == null) {
        uri = this.router.parent.generate("quote", { "workQuotationVersionId": this.selectedQuote.id });
      } else {
        uri = this.router.generate("metering", { "workQuotationVersionId": this.selectedQuote.id });
        // uri = this.router.generate(currentRouteName, { "workQuotationVersionId": this.selectedQuote.id });
      }
      if (uri != document.location.pathname) {
        this.router.parent.navigate(uri, { "replace": false });
      }

    }
  }

  async getCurrentVersion(id) {
    const pov = await this.workQuotationVersionService.getEntityById(id);
    this.selectedQuote.isCurrentVersion = pov.isCurrentVersion;
  }

  attached() {
    UIInternal.subscribe('router:navigation:complete', () => {
      IndexHelper.setFocusRoute(this.router);
    });
  }

  async activate(params) {
    this.projectId = params.projectId;
    this.workQuotationVersionService.gridDataSource.queryParameters = { projectId: this.projectId };
    let predicateProject = new Predicate("projectId", FilterQueryOp.Equals, this.projectId);
    if (params.workQuotationVersionId == null || params.workQuotationVersionId.length == 0) {
      let predicateCurrent = new Predicate("isCurrentVersion", FilterQueryOp.Equals, true);
      let pov = await this.workQuotationVersionService.firstEntity(predicateProject.and(predicateCurrent));
      if (pov == null) {
        pov = (await this.workQuotationVersionService.getEntities(predicateProject.and(predicateCurrent))).last();
      }
      if (pov == null) {
        pov = (await this.workQuotationVersionService.firstEntity(predicateProject));
      }
      this.selectedQuote = { id: pov.id, text: pov.fullName, isCurrentVersion: pov.isCurrentVersion };
    }
    else {
      const pov = await this.workQuotationVersionService.getEntityById(params.workQuotationVersionId);
      this.selectedQuote = { id: pov.id, text: pov.fullName, isCurrentVersion: pov.isCurrentVersion };
    }

    UIInternal.subscribe(Constants.MerlinCustomEvent.VersionNameChange, (data) => {
      if (data != null && data.id == this.selectedQuote?.id) {
        this.selectedQuote.text = data.text;
        this.selectedQuote.isCurrentVersion = data.isCurrentVersion;
      }
    });
    UIInternal.subscribe(Constants.MerlinCustomEvent.CurrentVersionChange, (data) => {
      if (data != null && this.selectedQuote != null) {
        this.selectedQuote = data;
      }
    });
  }
  configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    let r = config.map([
      {
        route: "",
        redirect: 'summary'
      },
      {
        route: ["summary"],
        moduleId: "projects/quotes/version-summary",
        title: this.i18n.tr('versionindex.summary'),//"Résumé",
        name: "summary",
        nav: true
      },
      {
        route: "import-metering",
        moduleId: "import-metering/import-metering-detail",
        title: this.i18n.tr('versionindex.metering'),//"Métrés",
        nav: false,
        name: "import-metering",
        href: "auto/metering",
        focusRoute: "metering"
      },
      {
        route: "import-data",
        moduleId: PLATFORM.moduleName('digiwall-lib/import-data/import-data-index'),
        title: "Métrés",
        nav: false,
        name: "import-data",
        focusRoute: "metering"
      },
      {
        route: "metering",
        moduleId: "projects/quotes/version-metering",
        title: this.i18n.tr('versionindex.metering'),//"Métrés",
        nav: true,
        name: "metering"
      },
      {
        route: "request-supplier-offer",
        moduleId: "request-supplier-offers/request-supplier-offer-list",
        title: this.i18n.tr('requestsupplieroffer.requestsupplieroffers'),
        nav: true,
        name: "request-supplier-offer"
      },
      {
        route: "request-supplier-offer-detail/:param1",
        moduleId: "request-supplier-offers/request-supplier-offer-detail",
        title: this.i18n.tr('requestsupplieroffer.requestsupplieroffer'),
        nav: false,
        name: "request-supplier-offer-detail",
        focusRoute: "request-supplier-offer"
      },
      {
        route: "supplier-offer-detail/:requestToSupplierId/:supplierOfferId/:type",
        moduleId: "supplier-offers/supplier-offer-detail",
        title: this.i18n.tr('supplieroffer.supplieroffer'),
        nav: false,
        name: "supplier-offer-detail",
        focusRoute: "request-supplier-offer"
      },
      {
        route: "comparison",
        moduleId: "projects/quotes/offer-comparison/offer-comparison",
        title: this.i18n.tr('offercomparison.offercomparisons'),
        nav: true,
        name: "comparison"
      },
      {
        route: "offers",
        moduleId: "projects/quotes/price-offer-version-list",
        title: this.i18n.tr('versionindex.offers'),//"Offre",
        nav: true,
        name: "offers"
      },
      {
        route: "price-offer-version-detail/:priceOfferVersionId",
        moduleId: "projects/quotes/price-offer-version-detail",
        title: this.i18n.tr('versionindex.offer'),//"Offre",
        nav: false,
        name: "price-offer-version-detail",
        focusRoute: "offers"
      },
      {
        route: "document",
        moduleId: "documents/document-list",
        title: this.i18n.tr('versionindex.documents'),//"Document",
        nav: true,
        name: "document"
      },
      {
        route: "add-offer",
        moduleId: "request-supplier-offers/add-request-supplier-offer",
        name: "add-offer",
        title: this.i18n.tr('buyingindex.offerRequest'),
        nav: false,
        focusRoute: "request-supplier-offer"
      },
    ]);

  }
  private async addVersionPrivate() {
    let newVersion = await VersionMenuItems.addWorkQuotationVersion(this.projectId);
    if (newVersion != null) {
      this.selectedQuote = { id: newVersion.id, text: newVersion.fullName, isCurrentVersion: newVersion.isCurrentVersion };
    }
  }

}
