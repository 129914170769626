@import "../css/scss/variables";

list-tree-data {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  grid-header-bar.grid-header-bar {
    height: 70px !important;
    .header-title .ui-btn__wrapper {
      border: solid 1px $purple-lighter !important;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      margin-left: 15px;
      &:hover {
        background-color: $purple-lightest !important;
        border: solid 1px $purple-lightest !important;
      }
      > .ui-btn__inner {
        box-shadow: none;
        .ui-btn {
          box-shadow: none;
          background-color: transparent;
          left: 0;
          position: absolute;
          .ui-btn__icon {
            padding: 0;
            height: 39px;
            width: 39px;
            text-align: center;
            color: $black-100;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              color: $blue-mid;
            }
          }
        }
      }
    }
  }

  ag-grid-aurelia {
    flex: 1;
    overflow: auto;
    .ag-popup {
      .ag-popup-editor {
        height: 40px;
        ui-field.ui-field {
          padding: 0;
          ui-select {
            height: 40px;
            .select2-selection {
              height: 40px;
              .select2-selection__rendered {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }

    &.ag-theme-material.datagrid {
      > .ag-root-wrapper {
        > .ag-root-wrapper-body {
          > .ag-root {
            > .ag-body-viewport {
              > .ag-pinned-left-cols-container {
                @for $i from 1 through 20 {
                  .ag-row-level-#{$i} .ag-cell:is(:nth-child(2), :nth-child(3), :nth-child(4)) {
                    margin-left: calc((#{$i} * 34px));
                  }
                }
                > .ag-row {
                  border-left-style: none;
                  border-right: solid 1px var(--ag-border-color) !important;
                  &.disabled-row {
                    border-right: solid 1px
                      rgba(
                        red($gray-very-light),
                        green($gray-very-light),
                        blue($gray-very-light),
                        $ag-row-disabled-opacity
                      ) !important;
                  }
                  > .ag-cell {
                    border-left: solid 1px var(--ag-border-color);
                    padding: 0 15px;
                    &:first-child {
                      border: none !important;
                      padding: 0;
                      background-color: white;
                    }
                  }
                  > .ag-cell-focus {
                    border: solid 1px var(--primary, $primary) !important;
                  }
                  > .ag-cell-last-left-pinned:not(.ag-cell-focus) {
                    border-left: none !important;
                  }
                }
              }
              .ag-row {
                .ag-cell-center,
                .ag-cell-center > * {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .ag-cell.ag-cell-not-inline-editing {
                  .ag-cell-wrapper {
                    display: flex;
                    justify-content: left;
                  }
                }
                > .ag-cell-inline-editing {
                  height: 40px;

                  input {
                    text-align: inherit;
                    font-family: inherit;
                    font-size: inherit;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    box-shadow: none !important;
                    border-bottom: none !important;
                  }
                }
                .ui-tag {
                  > .ui-tag__icon {
                    font-size: x-small;
                  }
                }
                ui-checkbox > label {
                  grid-column-gap: 0;
                }
              }
            }
          }
        }
      }

      @for $i from 1 through 20 {
        .ag-row-group-indent-#{$i} {
          padding-left: 0;
        }
      }

      .cell-highlight-text {
        font-weight: bold;
        background-color: yellow;
      }
      .ag-full-width-row {
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
      }
    }

    .ag-menu {
      font-family: $font-family;
      font-size: 14px;

      .ag-menu-option {
        .ag-menu-option-part {
          // padding: 5px 5px;

          &.ag-menu-option-icon {
            // padding-left: 14px;
            color: $gray-light;
          }
        }

        &:hover,
        &:hover .ag-menu-option-icon {
          box-shadow: 0 0 1px 0 var(--menu-active-bg, transparent) inset;
          color: var(--menu-hover-color, #3c53e4);
          cursor: pointer;
        }
      }
    }
  }

  $indent: 15px;
  .ag-tree-list {
    .ag-ltr {
      .ag-group-contracted,
      .ag-group-expanded {
        margin-right: $indent;
      }
    }

    .ag-cell-wrapper.ag-row-group-leaf-indent {
      margin-left: calc(18px + #{$indent});
    }
  }
}

.ref-to-display-dropdown {
  > .row {
    &:first-child {
      > ui-icon {
        transform: rotate(90deg);
      }
    }
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    &.checked {
      background-color: $purple-light;
    }
    &:hover {
      > ui-icon {
        color: $blue-mid;
      }
      > ui-radio {
        > label > span {
          color: $blue-mid;
        }
      }
    }
    > ui-radio {
      > label > input {
        display: none;
      }
    }
  }
}
