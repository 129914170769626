import { HttpClient } from 'aurelia-fetch-client';
import { DataFormat } from 'select2';
import { autoinject, bindable, Container, customElement, observable } from "aurelia-framework";
import { ServiceBase } from "digiwall-lib";
import { Merlin } from "generated";
import * as Constants from '../../../../constants';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { ContentTemplateApiService } from 'services/email-template-api-service';

@autoinject
@customElement("send-offer-step-2")
export class SendOfferStep2 {
  @bindable
  public hasAnnex: boolean;
  @bindable
  public annexContentHtml: string;
  @bindable
  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest;
  @bindable
  public communicationLanguageId: number;
  @bindable
  public contentContext: number = Constants.ContentTypeId.AnnexOfferClient;

  @observable
  private selectedAnnexTemplate: DataFormat = { id: "", text: "" }
  private contentTemplateApiService: ContentTemplateApiService

  private annexOfferService: ServiceBase<Merlin.Web.Model.ContentTemplate>;

  private showQuill: boolean = true;

  constructor() {
    this.annexOfferService = new ServiceBase<Merlin.Web.Model.ContentTemplate>(Constants.EntityTypeNames.ContentTemplate);
    this.contentTemplateApiService = new ContentTemplateApiService(Container.instance.get(HttpClient));
    this.contentTemplateApiService.previewRequestParams = {};
  }

  async bind() {
    this.annexOfferService.gridDataSource.queryParameters = { contentTypeId: this.contentContext };
    let p1 = new Predicate("isDefault", FilterQueryOp.Equals, true);
    let p2 = new Predicate("contentTypeId", FilterQueryOp.Equals, this.contentContext);
    let defaultAnnexOffers = await this.annexOfferService.getEntities(p1.and(p2));
    if (defaultAnnexOffers != null && defaultAnnexOffers.length > 0 && !this.annexContentHtml) {
      this.selectedAnnexTemplate.id = defaultAnnexOffers[0].id;
      this.selectedAnnexTemplate.text = defaultAnnexOffers[0].templateNameML._translation;
      this.selectedAnnexTemplateChanged(defaultAnnexOffers[0].id)
    }
    this.contentTemplateApiService.previewRequestParams = this.previewRequestParams;
    this.contentTemplateApiService.contentTypeId = this.contentContext;
  }

  private async selectedAnnexTemplateChanged(newVal) {
    this.showQuill = false;
    let contentHtml;
    if (this.contentTemplateApiService != null && newVal != null) {
      if (isNumber(newVal)) {
        contentHtml = (await this.annexOfferService.getEntityById(newVal)).contentHtmlML;
        this.contentTemplateApiService.previewRequestParams.emailContextId = newVal;
      }
      if (newVal.id) {
        contentHtml = (await this.annexOfferService.getEntityById(parseInt(newVal.id))).contentHtmlML;
        this.contentTemplateApiService.previewRequestParams.emailContextId = newVal.id;
      }

      switch (this.communicationLanguageId) {
        case 1:
          if (contentHtml.lang1 != null) {
            this.annexContentHtml = contentHtml.lang1;
            break;
          }
        case 2:
          if (contentHtml.lang2 != null) {
            this.annexContentHtml = contentHtml.lang2;
            break;
          }
        case 3:
          if (contentHtml.lang3 != null) {
            this.annexContentHtml = contentHtml.lang3;
            break;
          }
        default:
          this.annexContentHtml = contentHtml._translation;
          break;
      }
      this.contentTemplateApiService.previewRequestParams.contentHtml = this.annexContentHtml;
    }
    this.showQuill = true;
  }
}
