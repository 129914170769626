@import "../../css/scss/variables";

.sub-group-title {
  color: $black-100;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  padding: 50px;
  padding-bottom: 0px;
}

@media screen and (max-width: 1100px) {
  .content-parameter {
    grid-template-columns: repeat(1, 300px);
  }
}

@media screen and (min-width: 1101px) and (max-width: 1500px) {
  .content-parameter {
    grid-template-columns: repeat(2, 300px);
  }
}

@media screen and (min-width: 1501px) and (max-width: 1900px) {
  .content-parameter {
    grid-template-columns: repeat(3, 300px);
  }
}

@media screen and (min-width: 1901px) {
  .content-parameter {
    grid-template-columns: repeat(4, 300px);
  }
}
.content-parameter {
  display: grid;
  padding: 50px 0px 50px 50px;
  overflow: scroll;
  height: 80vh;
  grid-auto-rows: min-content;

  .sub-route {
    border-radius: 6px;
    border: 1px solid #ddd;
    width: 270px;
    min-height: 140px;
    padding: 20px;
    margin: 0 15px 30px;
    overflow: hidden;
    > ui-icon {
      color: $primary;
      padding-bottom: 17px;
    }
    .title {
      color: black;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .description {
      color: #7d7d7d;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
    }
  }
}
