import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, singleton } from "aurelia-framework";
import * as Constants from '../constants';
import { Merlin } from "generated";
import { ServiceBase } from 'digiwall-lib';

export interface ITemplateApiService {
  previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest;
  getTokens(emailContextId: number): Promise<Array<IPlaceholderItem>>;
  getContent(previewRequestParams?: Merlin.Web.Model.EmailTemplatePreviewRequest): Promise<string>;
}

export interface IPlaceholderItem {
  id: string;
  value: string;
  label: string;
}

@autoinject()
export class EmailTemplateApiService implements ITemplateApiService {

  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest;
  private emailTokenService: ServiceBase<Merlin.Web.Model.MergeContentToken>;

  constructor(private httpClient: HttpClient) {
    this.emailTokenService = new ServiceBase<Merlin.Web.Model.MergeContentToken>(Constants.EntityTypeNames.MergeContentToken);
  }

  public async getTokens(emailContextId: number): Promise<Array<IPlaceholderItem>> {
    return (await this.emailTokenService.getEntities(null, null, { emailContextId: emailContextId })).map((i, index) => {
      return {
        id: (index + 1).toString(),
        value: `${i.tokenName}`,
        label: i.tokenDescription._translation
      } as IPlaceholderItem
    });
  }

  public async getContent(previewRequestParams?: Merlin.Web.Model.EmailTemplatePreviewRequest): Promise<string> {
    if (previewRequestParams == null) previewRequestParams = this.previewRequestParams;
    let bodyHtml = JSON.stringify(previewRequestParams.contentHtml);
    previewRequestParams.contentHtml = undefined;
    let requestUri = Constants.Application.RemoteServiceName + Constants.Application.ExternalCommunicationController.GetEmailContent;
    let response = await this.httpClient.post(requestUri + "?emailContextId=" + previewRequestParams.emailContextId + "&serializedParameters=" + JSON.stringify(previewRequestParams), bodyHtml);
    if (response.ok) {
      return await response.json();
    }
  }
}

@autoinject()
export class ContentTemplateApiService implements ITemplateApiService {

  public previewRequestParams: Merlin.Web.Model.EmailTemplatePreviewRequest = {};
  private emailTokenService: ServiceBase<Merlin.Web.Model.MergeContentToken>;
  public contentTypeId: number = Constants.ContentTypeId.ClientOfferCoverLetter;

  constructor(private httpClient: HttpClient) {
    this.emailTokenService = new ServiceBase<Merlin.Web.Model.MergeContentToken>(Constants.EntityTypeNames.MergeContentToken);
  }

  public async getTokens(typeId: number): Promise<Array<IPlaceholderItem>> {
    return (await this.emailTokenService.getEntities(null, null, { contentTypeId: typeId })).map((i, index) => {
      return {
        id: (index + 1).toString(),
        value: `${i.tokenName}`,
        label: i.tokenDescription._translation
      } as IPlaceholderItem
    });
  }

  public async getContent(previewRequestParams?: Merlin.Web.Model.EmailTemplatePreviewRequest): Promise<string> {
    if (previewRequestParams == null) previewRequestParams = this.previewRequestParams;
    let bodyHtml = JSON.stringify(previewRequestParams.contentHtml);
    previewRequestParams.contentHtml = undefined;
    let requestUri = Constants.Application.RemoteServiceName + Constants.Application.ExternalCommunicationController.GetTemplateContent;
    let response = await this.httpClient.post(requestUri + "?contentTemplateId=" + previewRequestParams.emailContextId + "&serializedParameters=" + JSON.stringify(previewRequestParams) + "&contentTypeId=" + this.contentTypeId, bodyHtml);
    if (response.ok) {
      return await response.json();
    }
  }
}
