
import { autoinject } from "aurelia-framework";
import { CustomLogger, EntityDetailViewModelBase, ServiceBase } from "digiwall-lib";
import { Merlin } from "generated";
import * as Constants from '../constants';
import { Router } from 'aurelia-router';
import { FilterQueryOp, Predicate } from "breeze-client";


@autoinject
export class DefaultInternalProjectUsersDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ProjectUsers> {
  public ressourceName: string = Constants.EntityTypeNames.ApplicationParameter;
  public documentTitle: string = this.i18n.tr("applicationparameters.defaultinternalprojectusers");

  private appParamsService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;

  public get ribbonHeaderText() {
    return this.i18n.tr("applicationparameters.defaultinternalprojectusers");
  }

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.ProjectUsers>(Constants.EntityTypeNames.ProjectUsers));
    this.appParamsService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
    // this.projectUsersService.gridDataSource.expands = ['projectUserSales.merlinUser'];
  }

  public async activate(params) {
    await super.activate(params);
    let appParams = await this.appParamsService.firstEntity();
    if (appParams == null) {
      console.error('ApplicationParameters is null !');
      return;
    }

    this.entity = await this.service.firstEntity(
      new Predicate('id', FilterQueryOp.Equals, appParams.defaultProjectUsersId),
      ['projectUserSales', 'projectUserPreparation', 'projectUserSecretary', 'projectUserFinance', 'projectUserExecutiveManagement', 'projectUserTechnicalManagement',
        'projectUserSecurity', 'projectUserPriceOffer.merlinUser', 'projectUserBuyings.merlinUser', 'projectUserExecutions.merlinUser']
    );

    if (this.entity == null) {
      this.entity = await this.service.createEntity();
      appParams.defaultProjectUsers = this.entity;
      await this.save(true);
    }
  }
}
