import { UIInternal } from 'digiwall-lib';
import * as Constants from '../../../constants';
import { AddItems } from "projects/quotes/add-items/add-items";
import { LINE_HAS_BEEN_ADDED } from 'projects/quotes/version-offer-overlay-comp';
import { MeteringOverlayComp } from "resources/metering/metering-overlay-comp";
import { ClientAdditionalWorkService } from 'services/client-addition-work-service';
import { MeteringFullWidthCellRenderer } from 'resources/renderer/metering-full-width-cell-renderer';

export class AdditionalWorkOverlayComp extends MeteringOverlayComp {
  setupContentGui(): string {
    let html: string;
    if (this.hasFilterApply || this.priceOfferLinesGrid.versionHasLines) {
      html = `<template><div>${this.i18n.tr("importmetering.noSearchResult")}</div></template>`;
    } else {
      html = `
      <template>
        <div>
          <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addPost" icon="digi-flashlight-line" click.delegate="addPost()"></ui-button>
          <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addItems" icon="digi-database-2-line" click.delegate="addItems()"></ui-button>
        </div>
      </template>`;
    }
    return html;
  }
  private async addPost() {
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();

    let ids = await (this.dataLineApi as unknown as ClientAdditionalWorkService).createLine(parseInt(this.priceOfferLinesGrid.projectId), null, Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, true);
  }

  private async addItems() {
    await this.box.showCustomDialog(AddItems, parseInt(this.priceOfferLinesGrid.projectId), null, {
      model: {
        action: Constants.PriceOfferLineMoveAction.Into,
        projectId: this.priceOfferLinesGrid.projectId,
        api: this.dataLineApi,
        parent: null
      },
      size: "xl"
    }).whenClosed((result) => {
      if (result.output) {
        UIInternal.broadcast(LINE_HAS_BEEN_ADDED);
        this.priceOfferLinesGrid.refreshServerSideRows(result.output, true);
      }
    });
  }
}

export class AdditionalWorkFullWidthCellRenderer extends MeteringFullWidthCellRenderer {
  private projectId: number;
  setupContentGui(): string {
    this.projectId = (this.apiService as unknown as ClientAdditionalWorkService).projectId;
    const template = `
          <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addPost" icon="digi-flashlight-line" click.delegate="addPost()"></ui-button>
          <ui-button type="outline" ui-theme="primary" t="[label]versionmetering.addItems" icon="digi-database-2-line" click.delegate="addItems()"></ui-button>`;

    return template;
  }
  private async addPost() {
    this.listTree.gridOptions.api.showLoadingOverlay();

    let ids = await (this.apiService as unknown as ClientAdditionalWorkService).createLine(this.projectId, null, Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into);
    this.listTree.gridOptions.api.hideOverlay();

    this.listTree.refreshServerSideRows(ids, true);
  }

  private async addItems() {
    await this.box.showCustomDialog(AddItems, this.projectId, null, {
      model: {
        action: Constants.PriceOfferLineMoveAction.Into,
        projectId: this.projectId,
        api: this.apiService,
        parent: null
      },
      size: "xl"
    }).whenClosed((result) => {
      if (result.output) {
        UIInternal.broadcast(LINE_HAS_BEEN_ADDED);
        this.listTree.refreshServerSideRows(result.output, true);
      }
    });
  }
}
