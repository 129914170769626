import { DialogController } from 'aurelia-dialog';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, PictureHelper, EnumerationTypeService, IMenuGroup } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';

@autoinject
export class NotificationSentDetail extends EntityDetailViewModelBase<Merlin.Web.Model.NotificationSent> {
  public ressourceName: string = Constants.EntityTypeNames.NotificationSent;
  public Constants: any = Constants;
  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController, private fileHelper: PictureHelper) {
    super(router, logger);
    this.emailTypeService = new EnumerationTypeService(Constants.EnumerationTypes.EmailType);
    this.emailTypeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.EmailType, onlyActifs: true };
    this.notificationSentFileService = new ServiceBase(Constants.EntityTypeNames.NotificationSentFile);
    super.initialize(new ServiceBase<Merlin.Web.Model.NotificationSent>(Constants.EntityTypeNames.NotificationSent));
  }
  private menuItems: Array<IMenuGroup>;
  private emailTypeService: EnumerationTypeService;

  private notificationSentFileService: ServiceBase<Merlin.Web.Model.NotificationSentFile>;

  private emailType: string;

  public async activate(params) {
    let id = params.param1;
    await super.activate(params);
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id, 'emailTemplate.emailType.type', 'notificationContentType', 'notificationSentFiles.fileType');
    this.controller.addObject(this.entity);
    this.setMenuItems();
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.back"),
            icon: "digi-arrow-go-back-line",
            handler: () => {
              this.navigateBack();
            }
          }
        ]
      }
    ];
  }

  @computedFrom('entity', 'entity.toName')
  public get documentTitle() {
    if (this.entity) {
      return this.entity.toName;
    }
  }

  public getFileMenuItem(currentThis: Merlin.Web.Model.NotificationSentFile) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.download"),
            icon: "digi-download",
            handler: async () => {
              this.fileHelper.downloadFile(currentThis);
            }
          },
        ]
      }
    ]
  }
}
