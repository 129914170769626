.project-index-select {
  width: 300px !important;
  > div.footer {
    background-color: $background-footer;
    padding: 10px 20px;
  }
  .select2-results__options {
    border-bottom: 1px solid $gray-very-light;
    &:last-child {
      border-bottom: none;
    }
    > .select2-results__option {
      background-color: transparent !important;

      .project-index-select-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $black-100;
        padding: 5px 10px;

        .createdTime {
          color: $gray-mid;
        }
      }
    }
    > .select2-results__option.select2-results__option--highlighted {
      > .project-index-select-item {
        background-color: $purple-lightest;
      }
    }
  }
}
