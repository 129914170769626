
import { EditableCallback, EditableCallbackParams } from 'ag-grid-community';
import { ColDef } from 'ag-grid-community';
import { RowNode } from 'ag-grid-community';
import { autoinject, bindingMode } from "aurelia-framework";
import { Subscription } from 'aurelia-event-aggregator';
import { bindable } from "aurelia-templating";
import { UIDrop } from 'digiwall-lib/dist/typings/ui/shared/ui-drop';
import { CSS_CLASS_AG_CELL, CSS_CLASS_AG_CELL_COMMENT_COMMENTED, CSS_CLASS_AG_ROW_DISABLED, CSS_CLASS_AG_CELL_COMMENT_DISABLED } from './ag-cell-comment-service';
import IGridCellCommentable from './grid-cell-commentable.interface';
import IPriceOfferLineCommentApiService from 'services/price-offer-line-comment-api-service';

@autoinject
export class AgCellComment {

  @bindable
  public api: IPriceOfferLineCommentApiService;

  @bindable
  public rowNode: RowNode;

  @bindable
  public colDef: ColDef;

  @bindable
  public gridInstance: IGridCellCommentable;

  @bindable({ defaultBindingMode: bindingMode.oneTime })
  public fieldsListDataProperty: string | Array<string> = 'FieldsComments';

  @bindable({ defaultBindingMode: bindingMode.oneTime })
  public fieldsListDataPropertySeparator = ',';

  @bindable
  public readonly: boolean = false;
  private hasValue: boolean = false;


  private colField: string;
  private clickSubscription: Array<Subscription> = [];


  private debug = false;// set true to activate console.log of events.
  protected commentDropEl: UIDrop;
  agCell: any;

  private manuallyChangeEditable = false;

  constructor(private viewEl: Element) { }

  attached() {
    this.initColField();
    this.setCommentedCSSClass();
    this.setDisabledCSSClass();

    if (!this.readonly && this.gridInstance == null) return;

    if (this.viewEl == null) return;
    this.agCell = this.viewEl.closest(`.${CSS_CLASS_AG_CELL}`);
    if (this.agCell == null) {
      this.agCell = this.viewEl.parentElement;
    }

    const initialCanClose = (this.commentDropEl as any).canClose;
    (this.commentDropEl as any).canClose = (t: Element) => {
      if (t == null || !hasParent(t, 'ui-menu__item')) {
        initialCanClose.call(this.commentDropEl, t);
      }
    }

    // Disable cell editing when mouse is hover Element.
    const originalEditableFunction: boolean | EditableCallback = this.colDef?.editable;
    this.viewEl.addEventListener('mouseenter', () => {
      let editable = originalEditableFunction;
      if (typeof editable == "function") {
        editable = editable({ data: this.rowNode.data, colDef: this.colDef } as EditableCallbackParams);
      }
      if (editable) {
        this.manuallyChangeEditable = true;
        this.setColDefEditable(false);
      }
    });
    this.viewEl.addEventListener('mouseleave', () => {
      if (this.manuallyChangeEditable) this.setColDefEditable(originalEditableFunction != null ? originalEditableFunction : true);
    });
  }

  detached() {
    if (this.debug) console.log("AgCellComment : detached");
  }

  /**
   * Set the css class ´CSS_CLASS_AG_CELL_COMMENT_COMMENTED´ if the cell is commented.
   *
   * @return {*}  {void}
   * @memberof AgCellComment
   */
  private setCommentedCSSClass(): void {
    if (this.colField?.trim().length == 0 || this.fieldsListDataProperty == null) return;

    if (this.isCellCommented == null) this.isCellCommented = this.internalIsCellCommented;
    if (!(typeof this.isCellCommented == 'function')) return;

    this.hasValue = this.isCellCommented(this.rowNode?.data, this.colField);

    if (this.hasValue) {
      this.viewEl.classList.add(CSS_CLASS_AG_CELL_COMMENT_COMMENTED);
    } else {
      this.viewEl.classList.remove(CSS_CLASS_AG_CELL_COMMENT_COMMENTED);
    }
  }

  @bindable
  public isCellCommented: Function;

  private internalIsCellCommented(data: any, colField: string): boolean {
    if (data == null || typeof this.fieldsListDataProperty != 'string' || (typeof data[this.fieldsListDataProperty] != 'string') || colField?.trim().length == 0) return false;
    let fields: Array<string> = (data[this.fieldsListDataProperty]).split(this.fieldsListDataPropertySeparator).map(v => v?.trim());
    return fields.some(f => f == colField);
  }

  /**
   * Set the css class ´CSS_CLASS_AG_CELL_COMMENT_DISABLED´ if the row is disabled.
   *
   * @private
   * @return {*}  {void}
   * @memberof AgCellComment
   */
  private setDisabledCSSClass(): void {
    if (this.viewEl == null) return;
    let closestDisabledRow = this.viewEl.closest(`.${CSS_CLASS_AG_ROW_DISABLED}`);
    if (closestDisabledRow != null) {
      this.viewEl.classList.add(CSS_CLASS_AG_CELL_COMMENT_DISABLED);
    } else {
      this.viewEl.classList.remove(CSS_CLASS_AG_CELL_COMMENT_DISABLED);
    }
  }

  /**
   * Set the colDef.editable.
   *
   * @private
   * @param {boolean} value
   * @return {*}  {void}
   * @memberof AgCellComment
   */
  private setColDefEditable(value: boolean | EditableCallback): void {
    this.colDef = this.gridInstance.gridOptions?.api.getColumnDef(this.colDef.field);
    if (this.colDef == null) return;
    this.colDef.editable = value;
  }

  /**
   * Set this colField based on the colDef.
   *
   * @private
   * @return {*} 
   * @memberof AgCellComment
   */
  private initColField() {
    if (this.colDef == null || this.rowNode == null) return;
    this.colField = this.colDef.field;
  }

  /**
   * Toggle the box.
   *
   * @private
   * @param {MouseEvent} [event=null]
   * @memberof AgCellComment
   */
  private toggleBox(event: MouseEvent = null) {
    this.commentDropEl.toggleDrop();
    if (typeof event?.stopImmediatePropagation == "function") event.stopImmediatePropagation();
  }

  private callRefreshVisibleNodes() {
    if (typeof this.gridInstance?.refreshVisibleNodes == 'function') {
      this.gridInstance.refreshVisibleNodes([parseInt(this.rowNode?.data?.id)], true)
    }
  }


  private save() {
    if (this.debug) console.log('AgCellComment : save');
    this.callRefreshVisibleNodes();
  }

  private close() {
    if (this.debug) console.log('AgCellComment : close');
    this.toggleBox();
  }

  public cancel() {
    if (this.debug) console.log('AgCellComment : cancel');
  }

  private edit() {
    if (this.debug) console.log('AgCellComment : edit');
  }

  private delete() {
    if (this.debug) console.log('AgCellComment : delete');
    this.toggleBox();
    this.callRefreshVisibleNodes();
  }

}


