import { I18N } from 'aurelia-i18n';

import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import { createOverrideContext, Container, ViewResources, autoinject } from 'aurelia-framework';
import { View, ViewCompiler, ViewSlot } from "aurelia-templating";
import { IMenuItem } from 'digiwall-lib';

@autoinject
export default class CustomButtonRenderer implements ICellRendererComp {
  protected eGui: HTMLElement;

  protected view: View;
  protected viewSlot: ViewSlot;
  protected params: ICellRendererParams;
  private menuItems: Array<IMenuItem>;
  public i18n: I18N;

  constructor() { }

  public init(params: ICellRendererParams,) {
    this.params = params;
    this.setupGui();
  }

  public setupGui() {
    this.eGui = document.createElement('div');
    this.eGui.style.width = '100%';
    this.eGui.style.height = '100%';

    let content: string = this.setupContentGui();
    let html: string = `<template>${content}</template>`;

    this.i18n = Container.instance.get(I18N);
    let viewCompiler = Container.instance.get(ViewCompiler);
    let viewResources = Container.instance.get(ViewResources);
    let viewFactory = viewCompiler.compile(html, viewResources);
    this.view = viewFactory.create(Container.instance);
    this.viewSlot = new ViewSlot(this.eGui, true);
    this.viewSlot.add(this.view);
    this.view.bind(this, createOverrideContext(this));
    this.viewSlot.attached();
  }


  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params): boolean {
    return true;
  }

  public destroy() {
    if (this.view) this.view.unbind();
    if (this.viewSlot) this.viewSlot.detached();
  }

  public getValueToDisplay() {
    return this.params.valueFormatted ? this.params.valueFormatted : this.params.value;
  }

  public setupContentGui(): string {
    if ((this.params as any).menuItems) {
      this.menuItems = (this.params as any).menuItems(this.params);
      return `<ui-button class="row-tools" icon="digi-more-line" type="tool" menu-items.one-way="menuItems" show-caret.bind="false"></ui-button>`
    }
    return '';
  }

}
