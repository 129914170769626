import { HttpClient } from 'aurelia-fetch-client';
import { BindingEngine, Disposable, autoinject, bindable, computedFrom, customElement } from "aurelia-framework";
import Quill, { QuillOptionsStatic } from "quill";
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import { ITemplateApiService, IPlaceholderItem } from "services/email-template-api-service";
import { Merlin } from "generated";
import * as Constants from '../../../constants';
import { I18N } from "aurelia-i18n";
import { DWQuill } from './dw-quill';
import { EnumerationType, EnumerationTypeService, IMenuGroup, TranslatedProperty } from 'digiwall-lib';

@autoinject()
@customElement("dw-quill-ml")
export class DWQuillML {
  @bindable
  private valueMl: TranslatedProperty;

  private languages: Array<EnumerationType>;
  private languageService: EnumerationTypeService;
  public selectedLanguageId: number;
  public Constants: any = Constants;
  public languageMenuItems: Array<IMenuGroup>;

  @bindable()
  value: string;

  @bindable()
  api: ITemplateApiService;

  @bindable()
  emailContextId: number;

  @bindable
  mode: 'edit' | 'preview' = 'edit';

  @bindable()
  canPreview?: boolean | null;

  @bindable()
  readonly = false;

  @bindable()
  addFiles: Function;

  constructor() {
    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);
  }

  bind() {
    this.setLanguageMenuItems();
  }

  @computedFrom('selectedLanguageId')
  public get getButtonLanguageLabel() {
    return this.languages?.filter(x => x.id == this.selectedLanguageId)[0]?.denomination._translation;
  }

  async setLanguageMenuItems() {
    this.languages = await this.languageService.getAll();
    this.selectedLanguageId = this.languages[0].id;
    this.languageMenuItems = [];
    let items = [];
    this.languages.forEach(language => {
      items.push({
        label: language.denomination._translation,
        handler: () => {
          this.selectedLanguageId = language.id;
        }
      })
    });
    this.languageMenuItems.push({
      group: "1",
      hiddenLabel: true,
      items: items
    });
  }
}
