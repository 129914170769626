import { I18N } from 'aurelia-i18n';
import { Predicate, FilterQueryOp, EntityManager } from 'breeze-client';
import { autoinject, bindable, computedFrom, Container, customElement } from "aurelia-framework";
import { GlobalLoaderService, ServiceBase, UIInternal } from "digiwall-lib";
import { Merlin } from "../../generated";
import * as Constants from '../../constants';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';

@autoinject
@customElement("add-request-supplier-offer-step-3")
export class AddRequestSupplierOfferStep3 {
  @bindable
  public entity: Merlin.Web.Model.RequestSupplierOffer;
  @bindable()
  private type: any;
  @bindable
  public selectedEntities: Array<Merlin.Web.Model.ThirdParty> = [];
  public firstTime: boolean = true;

  // public requestToSuppliers: Array<Merlin.Web.Model.RequestToSupplier>
  public requestToSupplierService: ServiceBase<Merlin.Web.Model.RequestToSupplier>;
  public contactPersonService: ServiceBase<Merlin.Web.Model.ContactPerson>;
  public thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  public requestToSupplierContactPersonService: ServiceBase<Merlin.Web.Model.RequestToSupplierContactPerson>;
  constructor(public gls: GlobalLoaderService, public i18n: I18N) {
    // this.requestToSupplierService = new ServiceBase<Merlin.Web.Model.RequestToSupplier>(Constants.EntityTypeNames.RequestToSupplier);
    this.contactPersonService = new ServiceBase<Merlin.Web.Model.ContactPerson>(Constants.EntityTypeNames.ContactPerson);
    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.requestToSupplierContactPersonService = new ServiceBase<Merlin.Web.Model.RequestToSupplierContactPerson>(Constants.EntityTypeNames.RequestToSupplierContactPerson);
  }

  async bind() {
    this.setType();
    this.gls.allow(this.i18n.tr("requestsupplieroffer.supplierOfferLoading"), 0);
    Promise.all([this.getRequestToSupplier()]);
  }

  private setType() {
    if (this.entity != null && this.type == null) {
      switch (this.entity.entityType.shortName) {
        case 'WorkQuotationVersionRequestSupplierOffer':
          this.type = DocumentTypeDiscriminator.WorkQuotationVersion;
          this.requestToSupplierService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersionRequestToSupplier>(Constants.EntityTypeNames.WorkQuotationVersionRequestToSupplier);
          break;
        case 'BuyingRequestSupplierOffer':
          this.type = DocumentTypeDiscriminator.Buying;
          this.requestToSupplierService = new ServiceBase<Merlin.Web.Model.BuyingRequestToSupplier>(Constants.EntityTypeNames.BuyingRequestToSupplier);
          break;
      }

    }
  }

  async entityChanged(newValue, oldVal) {
    this.setType();
    if (newValue != oldVal) {
      this.gls.allow(this.i18n.tr("requestsupplieroffer.supplierOfferLoading"), 0);
      Promise.all([this.getRequestToSupplier()]);
    }
  }

  async getRequestToSupplier() {
    if (this.type == DocumentTypeDiscriminator.WorkQuotationVersion) {
      if ((this.entity as Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer).workQuotationVersionRequestToSuppliers == null) {
        (this.entity as Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer).workQuotationVersionRequestToSuppliers = [];
      }
      (this.entity as Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer).workQuotationVersionRequestToSuppliers.splice(0);

      let p1 = new Predicate("workQuotationVersionRequestSupplierOfferId", FilterQueryOp.Equals, this.entity.id);
      p1 = p1.and(new Predicate("statusId", FilterQueryOp.Equals, Constants.RequestToSupplierStatusId.InProcess))
      let temprequestToSuppliers = await this.requestToSupplierService.getEntities(p1, ['toSupplierOrSubContractor.addresses'], { thirdPartyIds: this.selectedEntities.map(x => x.id) }) as any;
      let arrayOfPromise = [] as Promise<void>[];
      for (let x of temprequestToSuppliers) {
        arrayOfPromise.push(this.wkComputeContactPerson(x));
      };
      await Promise.all(arrayOfPromise);
      (this.entity as Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer).workQuotationVersionRequestToSuppliers.push(...temprequestToSuppliers);


    } else if (this.type == DocumentTypeDiscriminator.Buying) {
      if ((this.entity as Merlin.Web.Model.BuyingRequestSupplierOffer).buyingRequestToSuppliers == null) {
        (this.entity as Merlin.Web.Model.BuyingRequestSupplierOffer).buyingRequestToSuppliers = [];
      }
      (this.entity as Merlin.Web.Model.BuyingRequestSupplierOffer).buyingRequestToSuppliers.splice(0);

      let p1 = new Predicate("buyingRequestSupplierOfferId", FilterQueryOp.Equals, this.entity.id);
      p1 = p1.and(new Predicate("statusId", FilterQueryOp.Equals, Constants.RequestToSupplierStatusId.InProcess));
      let temprequestToSuppliers: Merlin.Web.Model.BuyingRequestToSupplier[] = (await this.requestToSupplierService.getEntities(p1, ['toSupplierOrSubContractor.addresses'], { thirdPartyIds: this.selectedEntities.map(x => x.id) }) as Merlin.Web.Model.BuyingRequestToSupplier[]);

      let arrayOfPromise = [] as Promise<void>[];
      for (let x of temprequestToSuppliers) {
        arrayOfPromise.push(this.buyingComputeContactPerson(x));
      };
      await Promise.all(arrayOfPromise);
      (this.entity as Merlin.Web.Model.BuyingRequestSupplierOffer).buyingRequestToSuppliers.push(...temprequestToSuppliers);
    }

    if (this.firstTime) {
      this.firstTime = false;
    }
  }

  private async buyingComputeContactPerson(x: Merlin.Web.Model.BuyingRequestToSupplier) {
    if (x.toSupplierContactPersons.length == 0 && this.firstTime) {
      let p1 = new Predicate("companyId", FilterQueryOp.Equals, x.toSupplierOrSubContractorId);
      let firstContact = await this.contactPersonService.firstEntity(p1, ['person'], { contactPersonRoleId: Constants.ContactPersonRoleId.RequestOffer, companyId: x.toSupplierOrSubContractorId, emailNotNull: true });
      if (firstContact == null) {
        firstContact = await this.contactPersonService.firstEntity(p1, ['person'], { companyId: x.toSupplierOrSubContractorId, emailNotNull: true });
      }
      if (firstContact != null) {
        let contact = await this.requestToSupplierContactPersonService.createEntity({
          toSupplierContactPersonId: firstContact.personId,
          toSupplierContactPerson: firstContact.person,
          requestToSupplierId: x.id
        });
        x.toSupplierContactPersons.push(contact);
      }
    }
    x.buyingRequestSupplierOfferId = this.entity.id;
    (x as any).service = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    (x as any).service.gridDataSource.queryParameters = { companyId: x.toSupplierOrSubContractorId, emailNotNull: true, includeCompany: true };
  }

  private async wkComputeContactPerson(requestSupplier: any) {
    if (requestSupplier.toSupplierContactPersons.length == 0 && this.firstTime) {
      let p1 = new Predicate("companyId", FilterQueryOp.Equals, requestSupplier.toSupplierOrSubContractorId);
      let firstContact = await this.contactPersonService.firstEntity(p1, ['person'], { contactPersonRoleId: Constants.ContactPersonRoleId.RequestOffer, companyId: requestSupplier.toSupplierOrSubContractorId, emailNotNull: true });
      if (firstContact == null) {
        firstContact = await this.contactPersonService.firstEntity(p1, ['person'], { companyId: requestSupplier.toSupplierOrSubContractorId, emailNotNull: true });
      }
      if (firstContact != null) {
        let contact = await this.requestToSupplierContactPersonService.createEntity({
          toSupplierContactPersonId: firstContact.personId,
          toSupplierContactPerson: firstContact.person,
          requestToSupplierId: requestSupplier.id
        });
        requestSupplier.toSupplierContactPersons.push(contact);
      }
    }
    requestSupplier.workQuotationVersionRequestSupplierOfferId = this.entity.id;
    (requestSupplier as any).service = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    (requestSupplier as any).service.gridDataSource.queryParameters = { companyId: requestSupplier.toSupplierOrSubContractorId, emailNotNull: true, includeCompany: true };
  }
}
