import { Config, DateUtils } from 'digiwall-lib';
import { ItemApiService } from '../services/item-api-service';
import { BindingEngine } from 'aurelia-binding';
import { ServiceBase, CustomLogger, EntityDetailViewModelBase } from 'digiwall-lib';
import { autoinject, customElement } from 'aurelia-framework';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { Router } from 'aurelia-router';
import { ItemPrice } from 'utils/item-price';
import moment = require('moment');

@customElement('item-price-from-date')
@autoinject
export class ItemPriceDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ItemPrice> {
  public ressourceName: string = Constants.EntityTypeNames.ItemCompositionLine;
  public documentTitle = this.i18n.tr("itemprice.itemprice");

  private itemPriceFromDateService: ServiceBase<Merlin.Web.Model.ItemPriceFromDate>;
  private unitService: ServiceBase<Merlin.Web.Model.Unit>;
  private applicationParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  private applicationParameter: Merlin.Web.Model.ApplicationParameter;

  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private itemApiService: ItemApiService, config: Config) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.ItemPrice>(Constants.EntityTypeNames.ItemPrice));

    this.itemPriceFromDateService = new ServiceBase<Merlin.Web.Model.ItemPriceFromDate>(Constants.EntityTypeNames.ItemPriceFromDate);
    this.unitService = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    this.applicationParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
  }

  public async activate(params) {
    await super.activate(params);
    this.entity = params.itemPrice;
    this.unitService.gridDataSource.queryParameters = { unitTypeId: params.unitTypeId };

    this.applicationParameter = await this.applicationParameterService.firstEntity(null);
    if (this.applicationParameter != null) {
      this.nbDecimalForPriceDisplay = this.applicationParameter.nbDecimalForPriceDisplay;
      this.nbDecimalForQuantityDisplay = this.applicationParameter.nbDecimalForQuantityDisplay;
    }
  }

  public getMenuItems(params: Merlin.Web.Model.ItemPriceFromDate) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.deleteItemPriceFromDate(params);
            },
            disabled: () => {
              return params.fromDate <= new Date() || this.entity.itemPriceFromDates.length <= 1;
            }
          }
        ]
      }
    ];
  }

  public async attached() {
  }

  detached() {
    this.disposeObservers();
    return super.detached();
  }

  private disposeObservers() {
    this.disposables.forEach(d => d.dispose());
  }

  public async addItemPriceFromDate() {
    const initialValues = await this.itemApiService.newItemPriceFromDate(this.entity.id);
    let newItemPriceFromDate: Merlin.Web.Model.ItemPriceFromDate = await this.itemPriceFromDateService.createEntity(initialValues);
    this.entity.itemPriceFromDates.push(newItemPriceFromDate);
    this.controller.addObject(newItemPriceFromDate);
    this.setItemPriceFromDateObservers(newItemPriceFromDate);
  }

  private setItemPriceFromDateObservers(itemPriceFromDate: Merlin.Web.Model.ItemPriceFromDate) {
    this.disposables.push(
      this.bindingEngine.propertyObserver(itemPriceFromDate, 'unitSellingPrice').subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue) {
          itemPriceFromDate.defaultMarginCoefficientPrice = await ItemPrice.getMarginCoef(itemPriceFromDate.unitBuyingPrice, itemPriceFromDate.unitSellingPrice);
        }
      }),
      this.bindingEngine.propertyObserver(itemPriceFromDate, 'unitBuyingPrice').subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue) {
          itemPriceFromDate.unitSellingPrice = await ItemPrice.getSellingPrice(itemPriceFromDate.unitBuyingPrice, itemPriceFromDate.defaultMarginCoefficientPrice);
        }
      }),
      this.bindingEngine.propertyObserver(itemPriceFromDate, 'defaultMarginCoefficientPrice').subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue) {
          itemPriceFromDate.unitSellingPrice = await ItemPrice.getSellingPrice(itemPriceFromDate.unitBuyingPrice, itemPriceFromDate.defaultMarginCoefficientPrice);
        }
      })
    );
  }

  public async deleteItemPriceFromDate(itemPriceFromDate: Merlin.Web.Model.ItemPriceFromDate) {
    if (this.entity.itemPriceFromDates.length > 1) {
      if (itemPriceFromDate.entityAspect.entityState.isAdded()) {
        itemPriceFromDate.entityAspect.setDetached();
      } else {
        await this.itemPriceFromDateService.deleteEntities([itemPriceFromDate], true);
      }
    }
  }

  public isToday(date) {
    return new DateUtils(this.config).isSameDay(date, new Date());
  }
}
