import { JSONPatchOperationType } from "./json-patch-operation-type.enum";

export class JSONPatchOperation {
  op: JSONPatchOperationType;
  path: string;
  value?: string;
  from?: string;

  public static operateReplace(propertyName: string, propertyValue: any): JSONPatchOperation[] {
    return [{
      op: JSONPatchOperationType.Replace,
      path: "/" + propertyName,
      value: propertyValue
    }];
  }
}
