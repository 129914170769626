@import "./../css/scss/variables";

.my-account-container > .ui-page__ribbon-content > div {
  border-bottom: solid 1px #dddddd;
  margin-bottom: 0;
  padding-bottom: 15px !important;
}

.my-account {
  display: flex;
  flex-direction: column;

  > .row > .horizontal-margin {
    .ui-panel__header {
      border: none;
      padding: 0;
    }
    .ui-panel__body {
      .row > .col {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        text-align: center;
        //width: 100%;
        > span {
          font-size: 20px;
        }
        .my-account-language {
          margin-bottom: 50px;
        }
        .my-account-locality,
        .my-account-language {
          font-size: 16px;
          color: $gray-light;
        }
        > ui-button {
          width: inherit;
          margin: 0 auto;
        }
        ui-segmented-control {
          width: 100%;
        }
        /*> ui-button.no-bg-button > div {
          border: none;
        }*/
        .ui-field {
          flex: inherit;
          .select2-selection__clear {
            width: fit-content !important;
          }
        }
      }
    }
  }

  .my-account-btn {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: auto !important;
    margin-bottom: 50px !important;

    > .col {
      min-width: 250px;
      display: flex;
      flex-direction: column;
    }
  }
  .editor-button {
    display: flex;
    justify-content: center;
  }
}
