import { autoinject, Container } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { FloatingBoxViewModel, ServiceBase } from 'digiwall-lib';
import { Merlin } from 'generated';
import * as Constants from '../constants';
import { ValidationController } from 'aurelia-validation';
import * as toastr from 'toastr';
import { NotificationInternalTeamService } from './internal-team/notification-internal-team-service';

@autoinject
export class ProjectTeams extends FloatingBoxViewModel {
  public entity: Merlin.Web.Model.Project;
  public projectService: ServiceBase<Merlin.Web.Model.Project>;
  private validationController: ValidationController
  private i18n = Container.instance.get(I18N);
  private notificationService = Container.instance.get(NotificationInternalTeamService);

  constructor(private projectId: number) {
    super('./project-teams.html', 'md');
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
    this.validationController = Container.instance.get(ValidationController);
  }
  public async activate(params) {
    this.entity = await this.projectService.getEntityById(this.projectId, 'projectUsers');
    this.validationController.addObject(this.entity);
    this.notificationService.setPreviousInternalTeams(this.entity.projectUsers)
  }

  public async close() {
    await this.dialogController.close(false);
  }

  public static async areProjectIdInternalTeamsCompleted(projectId: number) {
    let project = await new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project).getEntityById(projectId, 'projectUsers', 'projectUsers.projectUserPriceOffer', 'projectUsers.projectUserBuyings', 'projectUsers.projectUserExecutions');
    return ProjectTeams.areProjectInternalTeamsCompleted(project);
  }

  public static areProjectInternalTeamsCompleted(project: Merlin.Web.Model.Project) {
    if (project?.projectUsers == null) {
      console.error('project.projectUsers is null !');
      return false;
    }

    return project.projectUsers.projectUserSalesId != null
      && project.projectUsers.projectUserPreparationId != null
      && project.projectUsers.projectUserSecretaryId != null
      && project.projectUsers.projectUserFinanceId != null
      && project.projectUsers.projectUserExecutiveManagementId != null
      && project.projectUsers.projectUserTechnicalManagementId != null
      && project.projectUsers.projectUserSecurityId != null
      && (project.projectUsers.projectUserPriceOffer != null && project.projectUsers.projectUserPriceOffer.length > 0)
      && (project.projectUsers.projectUserBuyings != null && project.projectUsers.projectUserBuyings.length > 0)
      && (project.projectUsers.projectUserExecutions != null && project.projectUsers.projectUserExecutions.length > 0);
  }

  public async confirm() {
    if (!ProjectTeams.areProjectInternalTeamsCompleted(this.entity)) {
      toastr.error(this.i18n.tr('project.fillAllInternalTeams'))
    }
    else {
      await this.projectService.save();
      await this.notificationService.sendNotificationToInternalTeam(this.entity);
      await this.dialogController.ok();
    }
  }

}
