import { Predicate, FilterQueryOp } from 'breeze-client';
import { TaskDTO } from './model/module-task-model';
import { autoinject, BindingEngine, Container, createOverrideContext, Disposable, observable, TaskQueue, ViewCompiler, ViewResources, ViewSlot } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { activationStrategy, Router } from 'aurelia-router';
import { Config, AuthService, Datacontext, Box, CustomAgGridOptions, FieldType, EnumerationTypeService, AgGridView, AgGridViewService, DataGridSetting, IMenuItem, ServiceBase, User, ActionDialogBoxInputParameters, DialogBoxViewModel, Various, UIInternal, GlobalLoaderService, AGGridColumnsWidth, SelectAllHeaderComponent, CustomLogger, ValueSelected } from "digiwall-lib";
import { HighlightSearchTextValueConverter } from "resources/value-converters";
import { HttpClient } from 'aurelia-fetch-client';
import * as Constants from '../constants';
import { CellClickedEvent, ColDef, GridOptions, IServerSideDatasource, IServerSideGetRowsParams, RowClassParams, RowNode } from "ag-grid-community";
import { FilterTask, UserSettingService } from "./service/user-setting-service";
import { TaskApiService, TaskCounter } from "./service/task-api-service";
import * as toastr from 'toastr';
import CustomTaskAgGroupCellRenderer from './renderer/merlin-html-header-renderer';
import CustomButtonRenderer from 'resources/renderer/custom-button-renderer';
import { DialogService } from 'aurelia-dialog';
import { PopUpMenuItem } from './pop-up-menu-item/pop-up-menu-item';
import { TaskDetail } from 'tasks/task-detail';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';
import moment = require('moment');
import { DocumentService } from 'documents/document-service';
import { AddDocument } from 'documents/add-document';
import SelectionCellRenderer from 'resources/renderer/selection-cell-renderer';
import { debounce } from 'debounce';
import { NotificationTaskService } from './service/notification-task-service';
import { Merlin } from 'generated';

@autoinject

export class ModuleTask {

  public filterTask: number;

  public toDoSelected: boolean;
  public inProcessSelected: boolean;
  public doneSelected: boolean;
  public cancelSelected: boolean;

  public fullScreen: boolean

  public gridRef: HTMLElement;
  public gridOptions: GridOptions;

  public selectedTask: TaskDTO;
  private taskIdToSelect: number;

  @observable
  public quickFilter: string;

  private firstGridLoad = true;
  private highlightSearchTextValueConverter;
  private cellsToRefresh: Array<{ node: RowNode, colId: string }> = [];
  private tasks: Array<TaskDTO>;
  private searchTasksResult: Array<TaskDTO>;
  private manuallyHandleFetchChildren = false;
  private currentSort: string = 'createdTime';

  @observable
  private counter: TaskCounter;

  private showClassicList: boolean;
  public canSelectAll: boolean = true
  private calculatedHeight: string;

  private todoLabel: string;
  private inProcessLabel: string;

  readonly ROW_HEIGHT = 120;
  readonly ROW_HEIGHT_FULL_SCREEN = 77;

  //#region property vue
  private agGridViewList: Array<AgGridView>;
  private gridViewService: AgGridViewService;
  private usedView: AgGridView
  private routeName: string;
  allStatus: any;
  allPriority: any;
  allTaskType: any;
  allUser: User[];
  //#endregion
  projectId: number;
  projectPhaseId: number;
  private projectService: ServiceBase<Merlin.Web.Model.Project>;
  private userService: ServiceBase<Merlin.Web.Model.MerlinUser>;
  get agGridViewModuleView() {
    return this.showClassicList ? 'module-task-module-list' : 'module-task-module-hierarchy';
  }

  constructor(public router: Router, public httpClient: HttpClient, public i18n: I18N, public config: Config, public authService: AuthService, public api: TaskApiService, public datacontext: Datacontext, public box: Box, public taskQueue: TaskQueue, public element: Element, public globalLoaderService: GlobalLoaderService, public userSettingService: UserSettingService, private dialogService: DialogService, private notificationService: NotificationTaskService) {
    this.highlightSearchTextValueConverter = new HighlightSearchTextValueConverter();
    this.projectService = new ServiceBase(Constants.EntityTypeNames.Project);
    this.userService = new ServiceBase(Constants.EntityTypeNames.MerlinUser);
  }

  public quickFilterChanged(newValue, oldValue) {
    this.gridOptions.api.onFilterChanged();
  }

  activate(params) {
    if (!isNaN(params?.projectId)) {
      this.projectId = parseInt(params?.projectId);
    }
    if (!isNaN(params?.projectPhaseId)) {
      this.projectPhaseId = parseInt(params?.projectPhaseId);
    }

    this.api.projectId = this.projectId;
    if (this.projectId != null) {
      this.api.projectPhaseId = this.projectPhaseId != null ? this.projectPhaseId : Constants.ProjectPhaseId.Project;
    }
    if (params.taskId != null) {
      setTimeout(async () => {
        this.openTask(params.taskId)
      }, 500)
    }
  }

  counterChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      this.setLabel();
    }
  }

  async attached() {
    this.routeName = this.router.currentInstruction.config.route as string;

    if (this.routeName == "tasks/all" || this.routeName == "tasks" || this.routeName == "tasks/*childRoute") {
      this.showClassicList = true;
    }
    else {
      this.showClassicList = false
    }
    let statusService = new EnumerationTypeService(Constants.EnumerationTypes.TaskStatus);
    this.allStatus = await statusService.getAll();
    let priorityService = new EnumerationTypeService(Constants.EnumerationTypes.TaskCriticityLevel);
    this.allPriority = await priorityService.getAll();
    let taskTypeService = new EnumerationTypeService(Constants.EnumerationTypes.TaskType);
    this.allTaskType = await taskTypeService.getAll();
    let user = new ServiceBase<User>(Constants.EntityTypeNames.User);
    this.allUser = await user.getEntities(new Predicate("disabled", FilterQueryOp.Equals, false));
    this.gridViewService = new AgGridViewService();

    this.counter = await this.api.getCounter();

    // Set grid options
    await this.initGridOtions();

    if (this.showClassicList) {
      window.addEventListener('resize', debounce(() => {
        this.calculateBodyHeight();
      }));
    }
  }

  public async initGridOtions() {
    //this.showClassicList = (await this.userSettingService.getUserSetting()).customSettings.taskSetting.showClassicList;
    const colsTypesDefs = CustomAgGridOptions.ColumnTypeDefinitions(this.i18n, this.config.globalConfig.defaultLocale);
    colsTypesDefs["Number"].cellEditor = 'numberCellEditor';
    this.gridOptions = {
      components: Object.assign({},
        CustomAgGridOptions.Components,
        {
          'customTaskAgGroupCellRenderer': CustomTaskAgGroupCellRenderer,
          'customButtonRenderer': CustomButtonRenderer,
          'selectionCellRenderer': SelectionCellRenderer
        }
      ),
      columnTypes: colsTypesDefs as any,
      defaultColDef: {
        resizable: true,
        sortable: false,
        editable: false,
        suppressMenu: true,
        menuTabs: ['filterMenuTab']
      },
      columnDefs: this.getDataGridColumns(),
      headerHeight: this.showClassicList ? 40 : 0,
      getRowHeight: params => {
        if (this.fullScreen) {
          return this.showClassicList ? 40 : this.ROW_HEIGHT_FULL_SCREEN;
        } else {
          return this.ROW_HEIGHT;
        }
      },
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      stopEditingWhenCellsLoseFocus: false,
      singleClickEdit: true,
      undoRedoCellEditing: true,
      enableGroupEdit: true,
      cellFlashDelay: 500,
      cellFadeDelay: 2000,
      tooltipShowDelay: 1000,
      treeData: !this.showClassicList,
      groupDisplayType: 'custom',
      rowModelType: "serverSide",
      isServerSideGroup: dataItem => dataItem.hasChildren,
      //getDataPath: dataItem => dataItem.parentTaskId,
      getDataPath: dataItem => dataItem.path,
      getServerSideGroupKey: dataItem => dataItem.path,
      isServerSideGroupOpenByDefault: () => false,
      animateRows: true,
      rowBuffer: 200,
      getRowId: (params) => params.data.id,
      rowClassRules: {
        'task-row-open': (params) => {
          if (params.data == null || this?.selectedTask == null) {
            return false;
          }
          return params.data.id == this.selectedTask.id;
        },
      },
      getRowClass: (params: RowClassParams<any>) => {
        // Manually set the .ag-row-last-row class on the last rows of group, it's not well performed by ag-grid due to virtualization.        
        if (!params.node.parent?.group && !params.node.parent?.expanded) return "";
        let children = (<any>params.node?.parent?.childStore)?.nodesAfterSort as Array<RowNode>;
        if (children?.length) {
          let childIndex = children.findIndex(n => n == params.node);
          params.node.lastChild = childIndex == children.length - 1;
          if (params.node.lastChild) {
            // Don't use general class ag-row-last, because ag-grid remove it 
            return 'ag-row-last-row';
          }
        }
        return "";
      },
      serverSideFilterOnServer: true,
      serverSideFilterAllLevels: true,
      // E.O. Setup
      suppressContextMenu: true,
    };

    this.gridOptions.onGridReady = async (event) => {
      this.gridOptions.api.setServerSideDatasource(this.createServerSideDatasource());
      await this.initAgGridView();
      await this.setAgGridView();
    }
    if (this.showClassicList) {
      this.gridOptions.onCellClicked = (event?) => {
        this.openTask(parseInt(event.node.id));
      };
    }

    this.gridOptions.pagination = this.showClassicList;
    this.gridOptions.paginationAutoPageSize = this.showClassicList;

    await this.initCustomFilter();
    //if (this.showClassicList) {
    //this.gridOptions.paginationPageSize = 10;

    UIInternal.subscribe("Custom-Paging-Attached", () => this.calculateBodyHeight());
    setTimeout(() => {
      this.calculateBodyHeight();
    }, 1000)

    //}
  }

  public async onFilterChanged(event) {
    let filters = this.gridOptions.api.getFilterModel();
    await this.setStatusFromFilter(filters)
  }

  public async deactivate() {
    await this.saveGridColumnsState();
  }

  public async refreshData(params) {
    let task = null;
    if (params.id != null) {
      task = await this.fetchRowData(params.id);
      let previousNode = this.gridOptions.api?.getRowNode(this.selectedTask.id.toString());
      previousNode.setExpanded(true);
      this.selectedTask = task;
    }
    else {
      task = await this.fetchRowData(this.selectedTask.id);
      this.selectedTask = task;
    }

    setTimeout(() => {
      let node = this.gridOptions.api?.getRowNode(this.selectedTask.id.toString());
      if (node == null) return;

      if (params.purge) {
        if (node.group != true && task.hasChildren == true) {
          node.setGroup(true);
          node.setExpanded(true);
          this.gridOptions.api.redrawRows({ rowNodes: [node] });
        } else {
          let route = node.getRoute();
          this.gridOptions.api.refreshServerSide({ route: route, purge: true });
        }
      } else if (this.selectedTask.parentTaskId != null) {
        this.fetchRowData(this.selectedTask.parentTaskId);
      }

      this.reApplySort();
      this.refreshCounter();

      this.gridOptions.api?.deselectAll();
      node.setSelected(true);
    }, 100);
  }

  private sortBy(colId: string, redrawRows = true) {
    this.gridOptions?.columnApi?.applyColumnState({
      state: [{ colId: colId, sort: 'desc' }],
      defaultState: { sort: null }
    });
    if (redrawRows)
      this.gridOptions?.api?.redrawRows();
  }

  public applySort(): void {
    this.sortBy(this.currentSort);
    this.userSettingService.setTaskSortBy(this.currentSort);
  }

  private reApplySort() {
    this.sortBy('path', false);
    this.sortBy(this.currentSort);
  }

  public getDataGridColumns(): ColDef[] {
    let taskTagsService = new EnumerationTypeService(Constants.EnumerationTypes.TaskTag);
    let defs: ColDef[] = [
      {
        colId: "MenuIems",
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        suppressMovable: true,
        cellRendererParams: {
          canComment: false,
          i18n: this.i18n,
          gridOptions: this.gridOptions,
          router: this.router,
          menuItems: (params) => this.getGridMenuItems(params),
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        resizable: false,
        filter: false,
        pinned: "left",
        lockPosition: 'left'
      },
      {
        colId: "title",
        headerName: this.i18n.tr('moduletask.title'),
        field: "title",
        type: FieldType.String,
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      },
      {
        colId: "description",
        headerName: this.i18n.tr('moduletask.description'),
        field: "description",
        type: FieldType.String,
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      },
      {
        colId: "startDateTime",
        headerName: this.i18n.tr('moduletask.startDateTime'),
        field: "startDateTime",
        type: FieldType.Date,
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      },
      {
        colId: "deadlineDate",
        headerName: this.i18n.tr('moduletask.deadlineDate'),
        field: "deadlineDate",
        type: FieldType.Date,
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList,
        comparator(valueA, valueB, nodeA: RowNode, nodeB: RowNode, isDescending) {
          if (nodeA?.data == null || nodeB?.data == null) return 0;
          if (nodeA.data.deadlineDate == null) return -1;
          if (nodeB.data.deadlineDate == null) return 1;
          return isDescending ?
            (moment(nodeA.data.deadlineDate).isBefore(nodeB.data.deadlineDate) ? 1 : -1) :
            (moment(nodeA.data.deadlineDate).isAfter(nodeB.data.deadlineDate) ? 1 : -1)
        },
      },
      {
        colId: "taskResultComment",
        headerName: this.i18n.tr('moduletask.taskResultComment'),
        field: "taskResultComment",
        type: FieldType.String,
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      },
      {
        colId: "taskCriticityLevelId",
        headerName: this.i18n.tr('moduletask.taskCriticityLevelId'),
        field: "taskCriticityLevelId",
        type: FieldType.Enumeration,
        valueGetter: (params) => params?.data?.taskCriticityLevel.name,
        filterParams: {
          category: Constants.EnumerationTypes.TaskCriticityLevel,
          customdFieldPath: ['denomination', '_translation']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.TaskCriticityLevel,
          customdFieldPath: ['denomination', '_translation']
        },
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList,
        comparator(valueA: TaskDTO, valueB: TaskDTO, nodeA: RowNode, nodeB: RowNode, isDescending) {
          if (nodeA?.data == null || nodeB?.data == null) return 0;
          return isDescending ?
            nodeB.data.taskCriticityLevel.id - nodeA.data.taskCriticityLevel.id :
            nodeA.data.taskCriticityLevel.id - nodeB.data.taskCriticityLevel.id;
        },
      },
      {
        colId: "taskTypeId",
        headerName: this.i18n.tr('moduletask.taskTypeId'),
        field: "taskTypeId",
        type: FieldType.Enumeration,
        valueGetter: (params) => params?.data?.taskType.name,
        filterParams: {
          category: Constants.EnumerationTypes.TaskType,
          customdFieldPath: ['denomination', '_translation']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.TaskType,
          customdFieldPath: ['denomination', '_translation']
        },
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      },
      {
        colId: "taskStatusId",
        headerName: this.i18n.tr('moduletask.taskStatusId'),
        field: "taskStatusId",
        type: FieldType.Enumeration,
        valueGetter: (params) => params?.data?.taskStatus.name,
        filterParams: {
          category: Constants.EnumerationTypes.TaskStatus,
          customdFieldPath: ['denomination', '_translation']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.TaskStatus,
          customdFieldPath: ['denomination', '_translation']
        },
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      },
      {
        colId: "responsibleId",
        headerName: this.i18n.tr('moduletask.responsibleId'),
        field: "responsibleId",
        type: FieldType.User,
        valueGetter: (params) => params?.data?.responsibleFullName,
        filterParams: {
          userServiceName: Constants.EntityTypeNames.MerlinUser
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          userServiceName: Constants.EntityTypeNames.MerlinUser
        },
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      },
      // {
      //   colId: "taskTags",
      //   headerName: this.i18n.tr('moduletask.taskTags'),
      //   field: "taskTags",
      //   type: FieldType.Enumeration,
      //   filterParams: {
      //     service: taskTagsService,
      //     customdFieldPath: ['denomination', '_translation']
      //   },
      //   floatingFilterComponentParams: {
      //     suppressFilterButton: true,
      //     service: taskTagsService,
      //     customdFieldPath: ['denomination', '_translation']
      //   },
      //   suppressMenu: false,
      //   showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
      //   hide: !this.showClassicList
      // }
      {
        colId: "createdTime",
        headerName: this.i18n.tr("general.createdTime"),
        field: "createdTime",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormatWithHours
        },
        type: FieldType.Date,
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      },
      {
        colId: "updatedTime",
        headerName: this.i18n.tr("general.updatedTime"),
        field: "updatedTime",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormatWithHours
        },
        type: FieldType.Date,
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      },
      {
        colId: "createdById",
        headerName: this.i18n.tr("general.createdByFullName"),
        field: "createdById",
        type: FieldType.User,
        valueGetter: (params) => params?.data?.createdByFullName,
        filterParams: {
          userServiceName: Constants.EntityTypeNames.MerlinUser
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          userServiceName: Constants.EntityTypeNames.MerlinUser
        },
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      },
      {
        colId: "updatedById",
        headerName: this.i18n.tr("general.updatedByFullName"),
        field: "updatedById",
        type: FieldType.User,
        valueGetter: (params) => params?.data?.updatedByFullName,
        filterParams: {
          userServiceName: Constants.EntityTypeNames.MerlinUser
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          userServiceName: Constants.EntityTypeNames.MerlinUser
        },
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: !this.showClassicList
      }
    ];
    if (!this.showClassicList) {
      defs.push({
        colId: "path",
        headerName: 'path',
        field: "path",
        cellRenderer: 'customTaskAgGroupCellRenderer',
        cellRendererParams: {
          canComment: false,
          suppressCount: true,
          suppressDoubleClickExpand: true
        },
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        pinned: "left",
        lockPosition: 'left',
        onCellClicked: (event: CellClickedEvent) => {
          if ((event.event?.target as Element).nodeName == "UI-BUTTON") {
            return;
          }

          if (this.selectedTask == null || this.selectedTask.id != event.data.id) {
            this.selectedTask = event.data;

            this.gridOptions.api?.deselectAll();
            event.node?.setSelected(true);

            if (this.fullScreen) {
              this.switchFullScreen();
            }
          }
        },
        hide: this.showClassicList
      })
    }
    else {
      defs.push({
        headerName: "",
        field: "isSelected",
        maxWidth: AGGridColumnsWidth.IsSelected,
        minWidth: AGGridColumnsWidth.IsSelected,
        cellRenderer: "selectionCellRenderer",
        suppressMenu: true,
        sortable: false,
        resizable: false,
        suppressMovable: true,
        filter: false,
        pinned: "left",
        headerComponent: SelectAllHeaderComponent,
        headerComponentParams: { VM: this }
      })
    }
    return defs;
  }
  getGridMenuItems(params: any) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("moduletask.open"),
            icon: "digi-arrow-expand",
            handler: () => {
              this.openTask(params.node.id);
            }
          },
          {
            label: this.i18n.tr("moduletask.addSubTask"),
            icon: "digi-add-circle-line",
            disabled: () => params.data.parentTaskId != null,
            handler: () => {
              this.addSubTask(params.node.id);
            }
          },
          {
            label: this.i18n.tr("moduletask.changeStatus"),
            icon: "digi-price-tag-3-line",
            items: this.getStatusItems(params, params.node.id)
          },
          {
            label: this.i18n.tr("moduletask.changePriority"),
            icon: "digi-alarm-warning-line",
            items: this.getPriorityItems(params, params.node.id)
          },
          {
            label: this.i18n.tr("moduletask.changeTaskType"),
            icon: "digi-archive-line",
            items: this.getTaskTypeItems(params, params.node.id)
          },
          // {
          // On ne peut pas changer cette date à la main
          //   label: this.i18n.tr("moduletask.changeStartDate"),
          //   icon: "digi-calendar-line",
          //   handler: () => {
          //     this.openChangeStartDate(params, params.node.id);
          //   }
          // },
          {
            label: this.i18n.tr("moduletask.changeDeadlineDate"),
            icon: "digi-calendar-event-line",
            handler: () => {
              this.openDeadlineDate(params, params.node.id);
            }
          },
          {
            label: this.i18n.tr("moduletask.changeResponsible"),
            icon: "digi-account-circle-line",
            items: this.getResponsableItems(params, params.node.id)
          },
          {
            label: this.i18n.tr("moduletask.addFiles"),
            icon: "digi-folder-add-line",
            handler: () => {
              this.addFiles(params, params.node.id);
            }
          }
        ],
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("moduletask.delete"),
            icon: "digi-trash-full",
            handler: () => {
              this.deleteTask(params.node.id);
            }
          },
        ]
      }
    ]
  }

  async addFiles(params, id: number) {
    let documentService = new DocumentService(
      Container.instance.get(HttpClient),
      Container.instance.get(I18N),
      Container.instance.get(Box),
      Container.instance.get(CustomLogger),
    )
    documentService.taskId = params.data.id;
    let dialogService = Container.instance.get(DialogService);
    let folderName = "tasks/" + params.data.id;
    await dialogService.open({
      viewModel: AddDocument,
      model: {
        service: documentService,
        folderName: folderName,
        documentModuleId: Constants.DocumentModuleId.Project
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("z-index-level-4");
        dialogOverlay.classList.add("z-index-level-4");
      },
    }).whenClosed(async result => {
      await this.fetchRowData(params.data.id);
    });
  }

  getStatusItems(params, id: number) {
    let items: Array<IMenuItem> = new Array();
    this.allStatus.forEach(status => {
      items.push({
        label:
          `<div style="display:flex; gap:10px; align-items:center;justify-content: space-between;">
              <div style='background-color:${status.backgroundColor}; color:${status.textColor};padding: 5px 15px;border-radius: 20px;'>
              ${status.denomination._translation}
              </div>
            </div>`,
        handler: async () => {
          params.data.taskStatusId = status.id;
          await this.api.patch(id, "TaskStatusId", status.id);
          await this.fetchRowData(params.data.id);
          this.refreshCounter();
          if ((<TaskDTO>params.data).parentTaskId != null) {
            this.fetchRowData(params.data.parentTaskId);
          }
          if (status.id == Constants.TaskStatusId.Finalized) {
            let project = await this.projectService.getEntityById(this.selectedTask.projectId);
            let user = await this.userService.getEntityById(this.selectedTask.createdById);
            await this.notificationService.sendNotificationTaskDone(user, project, this.selectedTask.responsibleId, this.selectedTask.id);
          }
        }
      });
    });
    return items;
  }

  getPriorityItems(params, id: number) {
    let items: Array<IMenuItem> = new Array();
    this.allPriority.reverse().forEach(priority => {
      items.push({
        label:
          `<div style="display:flex; gap:10px; align-items:center;justify-content: space-between;">
              <div style='background-color:${priority.backgroundColor}; color:${priority.textColor};padding: 5px 15px;border-radius: 20px;'>
              ${priority.denomination._translation}
              </div>
            </div>`,
        handler: async () => {
          params.data.taskCriticityLevelId = priority.id;
          await this.api.patch(id, "TaskCriticityLevelId", priority.id);
          await this.fetchRowData(params.data.id);
          this.reApplySort();
        }
      });
    });
    return items;
  }

  getTaskTypeItems(params, id: number) {
    let items: Array<IMenuItem> = new Array();
    this.allTaskType.reverse().forEach(taskType => {
      items.push({
        label:
          `<div style="display:flex; gap:10px; align-items:center;justify-content: space-between;">
              <div style='background-color:${taskType.backgroundColor}; color:${taskType.textColor};padding: 5px 15px;border-radius: 20px;'>
              ${taskType.denomination._translation}
              </div>
            </div>`,
        handler: async () => {
          params.data.taskTypeId = taskType.id;
          await this.api.patch(id, "TaskTypeId", taskType.id);
          await this.fetchRowData(params.data.id);
        }
      });
    });
    return items;
  }

  getResponsableItems(params, id: number) {
    let items: Array<IMenuItem> = new Array();
    this.allUser.reverse().forEach(user => {
      items.push({
        label:
          `<div style="display:flex; gap:10px; align-items:center;">
              <img class="avatar" style="width:24px; height:24px;" src="${user.avatarUri}" />
              <div>${user.fullName}</div>
            </div>`,
        handler: async () => {
          params.data.responsibleId = user.id;
          await this.api.patch(id, "ResponsibleId", user.id);
          await this.fetchRowData(params.data.id);
          await this.refreshCounter();
          let project = await this.projectService.getEntityById(this.selectedTask.projectId);
          await this.notificationService.sendNotificationTaskAssignement(user as Merlin.Web.Model.MerlinUser, project, this.selectedTask.id, this.selectedTask.title);
        }
      });
    });
    return items;
  }

  createTask() {
    this.selectedTask = null;
    this.box.showEditDialog(TaskDetail, Various.NewId, this.i18n.tr('taskjob.addTask'), (VM: TaskDetail) => {
      VM.entity.projectId = this.projectId;
      if (this.projectPhaseId == null) {
        VM.entity.projectPhaseId = Constants.ProjectPhaseId.Project
      }
      else {
        VM.entity.projectPhaseId = this.projectPhaseId;
      }
    },
      {
        canSave: false,
        actions: [
          EditDialogAction.GetSaveAction(this.i18n)
        ]
      }).whenClosed(async result => {
        if (!result.wasCancelled) {
          this.refreshCounter();
          let task = (<TaskDTO>result.output);
          if (task != null) {
            this.taskIdToSelect = task.id;
            let user = await this.userService.getEntityById(task.responsibleId);
            let project = await this.projectService.getEntityById(task.projectId);
            await this.notificationService.sendNotificationTaskAssignement(user, project, task.id, task.title);
          }
          this.gridOptions.api.refreshServerSide({ purge: false });
        }
      });
  }

  openTask(id: number) {
    this.box.showEditDialog(TaskDetail, id, this.i18n.tr('taskjob.editTask'), (VM: TaskDetail) => {
    },
      {
        canSave: false,
        actions: [
          EditDialogAction.GetSaveAction(this.i18n)
        ]
      }).whenClosed(async result => {
        if (!result.wasCancelled) {
          if (result.output?.id != null) {
            if (this.selectedTask.responsibleId != result.output?.responsibleId) {
              let user = await this.userService.getEntityById(result.output.responsibleId);
              let project = await this.projectService.getEntityById(result.output.projectId);
              await this.notificationService.sendNotificationTaskAssignement(user, project, result.output.id, result.output.title);
            }
            await this.fetchRowData(result.output?.id);
          }
          this.refreshCounter();
          if ((<TaskDTO>result.output).parentTaskId != null) {
            this.fetchRowData(result.output.parentTaskId);
          }
          else {
            this.selectedTask = null;
            this.gridOptions.api.refreshServerSide({ purge: true });
          }

        }
      });
  }

  async addSubTask(id: number) {
    var task = this.selectedTask;
    if (task == null) {
      task = await this.api.fetchTask(id);
    }

    this.box.showEditDialog(TaskDetail, -100, this.i18n.tr('taskjob.addSubTask') + ': ' + task?.title, (VM: TaskDetail) => {
      VM.entity.isSubTask = true;
      VM.fromPopup = true;
      VM.parentTaskId = id;
    },
      {
        canSave: false,
        actions: [
          EditDialogAction.GetSaveAction(this.i18n)
        ]
      }).whenClosed(async result => {
        if (!result.wasCancelled) {
          let node = this.gridOptions.api?.getRowNode(id.toString());
          if (node == null) return;
          await this.fetchRowData(node.data.id);
          await this.refreshCounter();
          let route = node.getRoute();
          if (!route) route = node.parent?.getRoute();

          if (node.group != true) {
            node.setGroup(true);
            node.setExpanded(true);
            this.gridOptions.api.redrawRows({ rowNodes: [node] });
          } else {
            this.gridOptions.api?.refreshServerSide({ route: route, purge: true });
          }
        }
      });
  }

  async openDeadlineDate(params, id: number) {
    await this.dialogService.open({
      viewModel: PopUpMenuItem,
      model: {
        title: this.i18n.tr("moduletask.changeDeadlineDate"),
        date: params.data.deadlineDate,
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
    }).whenClosed(async result => {
      if (!result.wasCancelled) {
        let date = result.output;
        params.data.deadlineDate = date;
        await this.api.patch(id, "DeadlineDate", date);
        await this.fetchRowData(params.data.id);
      }
    });
  }

  async deleteTask(id: number) {

    let buttonDelete: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.remove", { ns: "common" }),
      title: this.i18n.tr("general.remove", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok();
      }
    };
    let buttonCancel: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.cancel", { ns: "common" }),
      title: this.i18n.tr("general.cancel", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.cancel();
      }
    };
    await this.box.showQuestion(this.i18n.tr('moduletask.confirmeDelete'), this.i18n.tr('moduletask.delete'), [buttonCancel, buttonDelete]).whenClosed(
      async (result) => {
        if (!result.wasCancelled) {
          if (this.selectedTask?.id == id) {
            this.selectedTask = null;
          }
          await this.api.delete(id);
          await this.refreshCounter();
          let node = this.gridOptions.api?.getRowNode(id.toString());
          if (node.parent?.id != null) { // Deleting sub-task, refresh parent task.hasChildren.
            var data = await this.fetchRowData(parseInt(node.parent.id));
            var parentNode = this.gridOptions.api.getRowNode(node.parent.id);
            if (data?.hasChildren == false && parentNode.group == true) {// No more task, close group.
              parentNode.setData(data);
              parentNode.setExpanded(false);
              parentNode.setGroup(false);
              this.gridOptions.api.redrawRows({ rowNodes: [parentNode] });
              return;
            }
          }
          this.gridOptions.api?.refreshServerSide({ route: node?.parent?.getRoute(), purge: true });
        }
      }
    )
  }

  async fetchRowData(id: number) {
    let task = await this.api.fetchTask(id);
    let node = this.gridOptions.api.getRowNode(id.toString());
    node?.setData(task);
    if (task.id == this.selectedTask?.id) {
      this.selectedTask = task;
    }
    return task;
  }

  async refreshCounter() {
    this.counter = await this.api.getCounter();
  }

  setLabel() {
    this.todoLabel = this.i18n.tr('moduletask.toDo');
    this.inProcessLabel = this.i18n.tr('moduletask.inProcess');

    switch (this.filterTask) {
      case FilterTask.myTask:
        this.todoLabel += ' (' + this.counter.myTaskOpenCounter + ')';
        this.inProcessLabel += ' (' + this.counter.myTaskInProcessCounter + ')';
        break;
      case FilterTask.assignedTask:
        this.todoLabel += ' (' + this.counter.assignTaskOpenCounter + ')';
        this.inProcessLabel += ' (' + this.counter.assignTaskInProcessCounter + ')';
        break;
      case FilterTask.allTask:
        this.todoLabel += ' (' + this.counter.allTaskOpenCounter + ')';
        this.inProcessLabel += ' (' + this.counter.allTaskInProcessCounter + ')';
        break;
    }
  }

  //#region  data
  private createServerSideDatasource() {
    const dataSource: IServerSideDatasource = {
      getRows: async (params: IServerSideGetRowsParams) => {
        let result = { rowData: null, rowCount: null };
        if (params.parentNode?.data?.id != null) { // Fetch by line
          let fetchResult = await this.fetchDataLine(params.parentNode.data.id);
          if (fetchResult?.lines != null) {
            result.rowData = fetchResult.lines;
            result.rowCount = fetchResult.lines.length;
          } else {
            params.fail();
            return;
          }
        } else { // Initial fetch
          await this.fetchData(params.request.filterModel);
          result.rowData = this.tasks;
        }
        params.success(result);
      },
    };

    return dataSource;
  }
  private async fetchData(filterParams: any = null): Promise<boolean> {
    try {
      this.gridOptions.api.showLoadingOverlay();

      this.globalLoaderService.allow();
      let userSetting = (await this.userSettingService.getUserSetting());
      let lines = await this.api.fetch(filterParams, this.quickFilter, userSetting.customSettings.taskSetting);

      this.manuallyHandleFetchChildren = true;
      this.searchTasksResult = lines;
      this.tasks = lines.filter(l => !l.isSubTask);

      if (this.selectedTask != null && !this.tasks.some(t => t.id == this.selectedTask.id)) {
        this.gridOptions.api.deselectAll();
        this.selectedTask = null;
      }

      if (this.taskIdToSelect != null) {
        this.gridOptions.api?.deselectAll();
        this.selectedTask = null;
        UIInternal.queueTask(() => {
          let node = this.gridOptions.api.getRowNode(this.taskIdToSelect.toString());
          if (node != null) {
            node.setSelected(true);
            this.selectedTask = node.data;
          }
        });
      }

      return true;
    } catch (error) {
      toastr.error("Error during getting data");
    } finally {
      this.gridOptions.api?.hideOverlay();
    }
  }

  private async fetchDataLine(id: number): Promise<{ lines: any }> {
    if (id != null) {
      if (this.manuallyHandleFetchChildren && this.searchTasksResult != null) {
        let lines = this.searchTasksResult.filter((l: any) => l.parentTaskId == id);
        if (lines.length < 1) {
          lines = await this.api.getChilren(id);
        }
        return { lines };
      } else {
        let lines = await this.api.getChilren(id);
        return { lines };
      }
    }
    return null;
  }
  //#endregion


  //#region filter
  async initCustomFilter() {
    let userSetting = await this.userSettingService.getUserSetting();
    this.filterTask = userSetting.customSettings.taskSetting.filterTaskSelected;
    this.toDoSelected = userSetting.customSettings.taskSetting.toDoSelected;
    this.inProcessSelected = userSetting.customSettings.taskSetting.inProcessSelected;
    this.doneSelected = userSetting.customSettings.taskSetting.doneSelected;
    this.cancelSelected = userSetting.customSettings.taskSetting.cancelSelected;
    this.fullScreen = userSetting.customSettings.taskSetting.fullScreen;
    if (!this.fullScreen && this.showClassicList) {
      this.switchFullScreen(false)
    }
    if (userSetting.customSettings.taskSetting.taskSortBy != null) {
      this.currentSort = userSetting.customSettings.taskSetting.taskSortBy;
    }
    if (this.fullScreen) {
      this.gridOptions.rowHeight = this.ROW_HEIGHT_FULL_SCREEN;
    } else {
      this.gridOptions.rowHeight = this.ROW_HEIGHT;
    }
    this.gridOptions.api?.resetRowHeights();
    this.setColumnWith();
    this.setLabel();
  }

  async filterTaskSelected(number: FilterTask) {
    this.filterTask = number;
    await this.userSettingService.setfilterTaskSelected(this.filterTask);
    this.reloadData(true);
    this.setLabel();
  }

  async clickTodo() {
    this.toDoSelected = !this.toDoSelected;
    await this.userSettingService.setTodoSelected(this.toDoSelected);
    this.reloadData();
  }
  async clickInProcess() {
    this.inProcessSelected = !this.inProcessSelected;
    await this.userSettingService.setInProcessSelected(this.inProcessSelected);
    this.reloadData();
  }
  async clickDone() {
    this.doneSelected = !this.doneSelected;
    await this.userSettingService.setDoneSelected(this.doneSelected);
    this.reloadData();
  }
  async clickCancel() {
    this.cancelSelected = !this.cancelSelected;
    await this.userSettingService.setCancelSelected(this.cancelSelected);
    this.reloadData();
  }

  async setFilterFromStatus() {
    let filter = this.gridOptions.api.getFilterInstance('taskStatusId');
    if (((filter as any).filterValues as Array<ValueSelected>) == null || ((filter as any).filterValues as Array<ValueSelected>).length == 0) {
      await (filter as any).getValues();
    }
    let filterValues = ((filter as any).filterValues as Array<ValueSelected>)


    filterValues.find(x => x.id == Constants.TaskStatusId.Open).selected = this.toDoSelected ?? false;
    filterValues.find(x => x.id == Constants.TaskStatusId.InProgress).selected = this.inProcessSelected ?? false;
    filterValues.find(x => x.id == Constants.TaskStatusId.Finalized).selected = this.doneSelected ?? false;
    filterValues.find(x => x.id == Constants.TaskStatusId.Cancelled).selected = this.cancelSelected ?? false;
  }

  async setStatusFromFilter(model) {
    if (model.taskStatusId != null) {
      this.toDoSelected = model.taskStatusId.filter.find(x => x.id == Constants.TaskStatusId.Open).selected;
      this.inProcessSelected = model.taskStatusId.filter.find(x => x.id == Constants.TaskStatusId.InProgress).selected;
      this.doneSelected = model.taskStatusId.filter.find(x => x.id == Constants.TaskStatusId.Finalized).selected;
      this.cancelSelected = model.taskStatusId.filter.find(x => x.id == Constants.TaskStatusId.Cancelled).selected;
    }
    else {
      if (this.toDoSelected) {
        this.toDoSelected = false;
        await this.userSettingService.setTodoSelected(this.toDoSelected);
      }
      if (this.inProcessSelected) {
        this.inProcessSelected = false;
        await this.userSettingService.setInProcessSelected(this.inProcessSelected);
      }
      if (this.doneSelected) {
        this.doneSelected = false;
        await this.userSettingService.setDoneSelected(this.doneSelected);
      }
      if (this.cancelSelected) {
        this.cancelSelected = false;
        await this.userSettingService.setCancelSelected(this.cancelSelected);
      }
    }
  }

  private reloadData(purge: boolean = false) {
    this.setFilterFromStatus();
    if (purge) {
      this.gridOptions.api.refreshServerSide({ purge: purge });
    }
  }

  async switchFullScreen(save = true) {
    this.fullScreen = !this.fullScreen;
    if (this.fullScreen) {
      this.gridOptions.api.deselectAll();
      this.selectedTask = null;
      this.gridOptions.api.forEachNode(node => {
        node.setRowHeight(this.ROW_HEIGHT_FULL_SCREEN);
      });
    } else {
      this.gridOptions.api.forEachNode(node => {
        node.setRowHeight(this.ROW_HEIGHT);
      })
    }
    this.gridOptions.api.onRowHeightChanged();

    this.setColumnWith();
    if (save)
      await this.userSettingService.setFullScreen(this.fullScreen);
  }

  setColumnWith() {
    UIInternal.queueTask(() => {
      let widthPath = 0;
      if (this.fullScreen) {
        let parentWidth = (document.getElementsByClassName('ui-viewport-center')[0] as HTMLElement)?.offsetWidth;
        widthPath = parentWidth - 75;
      } else {
        let parentWidth = (document.getElementsByClassName('task-container-ag-grid')[0] as HTMLElement)?.offsetWidth;
        widthPath = parentWidth - 40;
      }
      this.gridOptions.columnApi?.getColumn('path')?.setActualWidth(widthPath);
    });
  }
  //#endregion

  //#region vue without resize column and withour multiple column
  public async initAgGridView() {
    if (this.router.currentInstruction.config.moduleId != null) {
      this.agGridViewList = new Array<AgGridView>();
      let defaultView = await this.gridViewService.getDefaultViewFromModuleId(this.router.currentInstruction.config.moduleId);
      if (defaultView == null) {
        defaultView = await this.gridViewService.createEntity({
          id: -100,
          name: this.i18n.tr("general.default", { ns: "common" }), moduleId: this.router.currentInstruction.config.moduleId,
          fixedFilters: false, columnState: JSON.stringify(this.gridOptions.columnApi.getColumnState()), filterModel: null,
          sortModel: null, visibility: "System", author: null, authorId: this.authService.currentUser.id,
          isDefaultView: true, createdBy: null, createdById: null, createdTime: null, updatedBy: null, updatedById: null,
          updatedTime: null, entityAspect: null, entityType: null, hide: false, used: false
        });
        defaultView.entityAspect.setDetached();
        defaultView.hide = false;
        defaultView.used = false;
      }
      this.agGridViewList.push(defaultView);
    }
  }

  private async setAgGridView() {
    let customGridSettings: any;
    if (this.authService.currentUser) {
      customGridSettings = this.authService.currentUser.settings.dataGridSettings[this.routeName];
    }
    if (customGridSettings != null) {
      if (this.router.currentInstruction.queryParams["usedViewId"] != null || customGridSettings.usedView?.id != null) {
        this.usedView = await this.gridViewService.getEntityById(this.router.currentInstruction.queryParams["usedViewId"] ?? this.authService.currentUser.settings.dataGridSettings[this.routeName].usedView.id);
        if (this.usedView != null && this.agGridViewList != null) {
          this.usedView.used = true;
          this.agGridViewList.push(this.usedView);
          this.gridOptions.api.setFilterModel(JSON.parse(this.usedView.filterModel));
        }
        else if (this.agGridViewList?.length > 0) {
          this.gridOptions.api.setFilterModel(JSON.parse(this.agGridViewList[0].filterModel));
        }
      } else {
        if (customGridSettings.filterModel) {
          this.gridOptions.api.setFilterModel(customGridSettings.filterModel);
        }
      }
    } else if (this.agGridViewList?.length > 0) {
      this.gridOptions.api.setFilterModel(JSON.parse(this.agGridViewList[0].filterModel));
    }
  }

  async afterSetAgGridView(agGridView) {
    this.setColumnWith();
    this.gridOptions.api.redrawRows();
    this.usedView = this.agGridViewList?.find(x => x.used);
  }
  public async saveGridColumnsState() {
    if (this.authService.currentUser) {
      // Selected view is the currently selected view. If no select, it is the default. If no views used, it is null.
      let selectedView = this.agGridViewList?.find(x => x.used) ?? this.agGridViewList?.find(x => x.isDefaultView);

      let savedSettings = this.authService.currentUser.settings.dataGridSettings[this.routeName];
      let currentSettings: DataGridSetting = {
        usedView: selectedView && selectedView.id > 0 ? { id: selectedView.id, name: selectedView.name } : null,
        filterModel: JSON.parse(JSON.stringify(this.gridOptions.api?.getFilterModel()))
      };

      // If there is a view selected, only save cols, fixed and filter if different from view
      if (selectedView != null) {
        if (this.isFilterModelEqual(currentSettings, { filterModel: JSON.parse(selectedView.filterModel) })) {
          currentSettings.filterModel = null;
        }
      }

      if (Object.keys(currentSettings.filterModel || {}).length == 0) {
        currentSettings.filterModel = null;
      }
      if (savedSettings == null || this.isSavedDifferentFromCurrent(savedSettings, currentSettings)) {
        await this.saveUserSettings(currentSettings)
      }
    }

  }
  protected isSavedDifferentFromCurrent(settingsA: DataGridSetting, settingsB: DataGridSetting) {
    return settingsA.usedView?.id != settingsB.usedView?.id
      || false == this.isFilterModelEqual(settingsA, settingsB)
  }
  protected isFilterModelEqual(settingsA: DataGridSetting, settingsB: DataGridSetting) {
    return JSON.stringify(settingsA.filterModel ?? {}) == JSON.stringify(settingsB.filterModel ?? {});
  }
  protected async saveUserSettings(settings: DataGridSetting) {
    if (this.authService.currentUser) {
      this.authService.currentUser.settings.dataGridSettings[this.routeName] = settings;
      await this.datacontext.saveUserSettings(this.authService.currentUser);
    }
  }

  public arrayOfObjectsMatch(arr1: Array<Object>, arr2: Array<Object>, excludedProperties?: Array<string>) {
    return (arr1.length === arr2.length) && arr1.every((val, index) => {
      for (let key of Object.keys(val)) {
        if ((excludedProperties == null || !excludedProperties.includes(key)) && val[key] !== arr2[index][key]) {
          return false;
        }
      }
      return true;
    });
  }
  //#endregion

  public async navigateToList() {
    if (!this.showClassicList) {
      this.router.navigate(this.router.baseUrl + 'tasks' + (this.projectPhaseId != null ? `?projectPhaseId=${this.projectPhaseId}` : ''))
    }
    else {
      this.router.navigate(this.router.baseUrl + 'module-tasks' + (this.projectPhaseId != null ? `?projectPhaseId=${this.projectPhaseId}` : ''));
    }

  }

  public headerCellRendererSelectAll(): HTMLElement {
    // let newChk = document.createElement('div');
    let eGui = document.createElement('div');
    eGui.style.width = '100%';
    eGui.style.height = '100%';
    let content: string = `<span class="select-all-container"><ui-checkbox if.bind="canSelectAll" checked.bind="selectAll"></ui-checkbox></span>`;
    let html: string = `<template>${content}</template>`;

    let viewCompiler = Container.instance.get(ViewCompiler);
    let viewResources = Container.instance.get(ViewResources);
    let viewFactory = viewCompiler.compile(html, viewResources);
    let view = viewFactory.create(Container.instance);
    let viewSlot = new ViewSlot(eGui, true);
    viewSlot.add(view);
    view.bind(this, createOverrideContext(this));
    viewSlot.attached();
    return eGui;
  }

  protected isSelectedChanged(entity) {
    if (entity != null && (entity as any).isSelected) {
      (entity as any).isSelected = false;
    }
  }

  public calculateBodyHeight(tries: number = 0) {
    // If heightStyle is provided through bind, we must keep it
    let availableHeight = this.element.parentElement.clientHeight;

    // Ignore division by 0
    if (availableHeight <= 0) {
      this.calculatedHeight = 'height: 500px';
      return;
    }

    // Remove padding top/bottom
    let parentStyle = getComputedStyle(this.element.parentElement);
    availableHeight -= this.getPadding(parentStyle, 'top') + this.getPadding(parentStyle, 'bottom')

    let headerHeight = this.element.getElementsByTagName('ag-grid-aurelia')[0].offsetTop;
    let footerHeight = this.element.getElementsByClassName("grid-footer-bar")[0].offsetHeight;

    if (footerHeight <= 0 && tries < 5) {
      // If height is 0, footer might not be fully attached
      // Can only be the case if all of the following are true:
      // 1. There is only a single page
      // 2. showSelectedElemCount is False
      // 3. showRowsCount is False
      // 4. left-paging / right-paging slots are empty OR not rendered (custom content)
      this.taskQueue.queueTask(() => this.calculateBodyHeight(++tries));
    } else {
      this.calculatedHeight = `height: ${availableHeight - headerHeight - footerHeight}px`;
    }
  }

  private getPadding(styles: CSSStyleDeclaration, side: 'top' | 'bottom'): number {
    return parseFloat(styles.getPropertyValue(`padding-${side}`).replace(/[^0-9.]/g, ''));
  }

}
