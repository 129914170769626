import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, FieldType, Various, EnumerationTypeService } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class ProjectList extends ListViewModelBase<Merlin.Web.Model.TaskJob> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.TaskJob>(Constants.EntityTypeNames.TaskJob));
    this.service.gridDataSource.expands = ['taskType', 'taskStatus', 'taskCriticityLevel', 'parentTask', 'responsible', 'subTasks', 'taskTags'];
  }

  public ressourceName: string = Constants.EntityTypeNames.TaskJob;
  public buttonLabel: string = this.i18n.tr("taskjob.addTask");
  public pathDetail: string;
  public taskTagsService: EnumerationTypeService


  public getDetailsUrl(_self, entity: Merlin.Web.Model.TaskJob) {
    if (entity?.id) {
      return this.pathDetail + entity.id;
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = '/tasks/';

    super.initializeGridOptions(false, false);
  }
  public getDataGridColumns() {
    this.taskTagsService = new EnumerationTypeService(Constants.EnumerationTypes.TaskTag);
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("taskjob.responsibleId"),
        field: "responsible.lastName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.taskTypeId"),
        field: "taskType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.TaskType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.TaskType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.title"),
        field: "title",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.description"),
        field: "description",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.deadlineDate"),
        field: "deadlineDate",
        valueFormatter: (data) => { return this.cellRenderer.dateRenderer(data, Various.DateFormatWithHours); },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.startDateTime"),
        field: "startDateTime",
        valueFormatter: (data) => { return this.cellRenderer.dateRenderer(data, Various.DateFormatWithHours); },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.taskStatusId"),
        field: "taskStatus.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.TaskStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.TaskStatus,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.taskResultComment"),
        field: "taskResultComment",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.taskCriticityLevelId"),
        field: "taskCriticityLevel.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.TaskCriticityLevel,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.TaskCriticityLevel,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.isSubTask"),
        field: "isSubTask",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.parentTaskId"),
        field: "parentTask.title",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("taskjob.taskTags"),
        field: "subTasks.taskTag.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.taskTagsService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.taskTagsService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
}

