// ATTENTION Horizontalement si le texte prend plus d'une ligne revenir vers Morgane
@import "../css/scss/variables";
.step-timeline {
  &__container {
    display: flex;
    > span {
      display: flex;
      &.actif,
      &.valid {
        > .step-timeline__el {
          > .step-timeline__el__link {
            &.step-link-disabled {
              cursor: not-allowed;
            }
            > .step-timeline__round {
              background-color: $primary;
            }
            > p {
              color: $black-100;
            }
          }
        }
        > .step-timeline__line {
          background-color: $primary;
        }
      }
      &.disabled {
        > .step-timeline__el {
          > .step-timeline__round {
            background-color: $black-50;
          }
          > p {
            color: $black-50;
          }
        }
        > .step-timeline__line {
          background-color: $black-50;
        }
      }
      > .step-timeline__el {
        > .step-timeline__el__link {
          > .step-timeline__round {
            display: inline-block;
            color: $black-0;
            //width: 30px;
            //height: 30px;
            margin-right: 5px;
            text-align: center;
            border-radius: 60px;
          }
          > p {
            font-size: 15px;
            font-weight: 400;
            margin: 0;
          }
        }
      }
      > .step-timeline__line {
        background-color: $primary;
      }
    }
  }
  &__container.horizontal {
    flex-direction: row;
    //min-height: 60px;
    background-color: $purple-lightest;
    border-radius: 60px;
    > span {
      flex-direction: row;
      padding: 5px 15px;
      border-radius: 60px;
      height: 100%;
      flex: auto;
      &.actif {
        border-radius: 60px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 0;
        background-color: $purple-mid;
        &:first-child {
          background-color: transparent;
        }
        > .step-timeline__el {
          background-color: $blue-mid;
          padding: 5px 15px;
          border-radius: 60px;
          flex: auto;
          > .step-timeline__el__link {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $black-0;
          }
        }
      }
      &.valid {
        background-color: $purple-mid;
        border-radius: 0;
        &:first-child {
          border-top-left-radius: 60px;
          border-bottom-left-radius: 60px;
        }
        > .step-timeline__el > .step-timeline__el__link {
          color: $black-0;
        }
      }
      &.disabled {
        background-color: $purple-lightest;
        > .step-timeline__el > .step-timeline__el__link {
          .step-timeline__round,
          p {
            color: $gray-light;
          }
        }
      }
      > .step-timeline__el {
        display: flex;
        align-items: center;
        > .step-timeline__el__link {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: $black-0;
          > .step-timeline__round {
            background-color: transparent;
          }
          > p {
            //margin-top: 12px;
            //position: absolute;
            color: $black-0;
          }
        }
      }
      > .step-timeline__line {
        width: 50px;
        height: 2px;
        margin-top: 15px;
        display: none;
      }
    }
  }
  &__container.vertical {
    display: flex;
    flex-direction: column;
    > span {
      flex-direction: column;
      > .step-timeline__el {
        display: flex;
        align-items: center;
        > .step-timeline__el__link {
          > p {
            margin-left: 12px;
          }
        }
      }
      > .step-timeline__line {
        width: 2px;
        height: 50px;
        margin-left: 15px;
      }
    }
  }
}
