
import { Router } from 'aurelia-router';

export class IndexHelper {
  public static setFocusRoute(router: Router) {
    let focusRoute = router.currentInstruction.config.focusRoute;
    router.navigation.forEach(x => x.config.subRouteActive = false);
    if (focusRoute != null) {
      let route = router.navigation.find(x => x.config.name == focusRoute);
      if (route != null) {
        route.config.subRouteActive = true;
      }
    }
  }
}
