import { Merlin } from 'generated';
import { TranslatedProperty } from 'digiwall-lib';


export class ItemDTO {
  public id: number;
  public articleCode: string;
  public defaultUnitId: number;
  public defaultBuyingPrice: number;
  public itemDeliveryTypes: number[];
  public itemTypeId: number;
  public unitTypeId: number;
  public activityCodes: number[];
  public itemFamilies: number[];
  public features: number[];
  public description: TranslatedProperty;
  public detailedDescriptionMultiline: TranslatedProperty;
  public secondaryDescriptionMultiline: TranslatedProperty;
  public isDeprecated: boolean;
  public internalNoteMultiline: string;
  public suppliers: string;
  public detailsVisible: boolean;
  public supplierWarrantyInMonth: number;
  public clientWarrantyInMonth: number;
  public defaultMarginCoefficient: number;
  public defaultSellingPrice: number;
}

export class ItemPriceDTO {
  id: number;
  supplierId: number | null;
  fromQuantity: number;
  unitId: number;
  // unit: Merlin.Web.Model.Unit;
  isDefaultPrice: boolean | null;
  itemId: number;
  // item: Merlin.Web.Model.Item;
  currentBuyingPrice: number | null;
  currentSellingPrice: number | null;
  itemPriceFromDates: ItemPriceFromDateDTO[];

  constructor() { }

  public static fromItemPrice(item: Merlin.Web.Model.ItemPrice): ItemPriceDTO {
    let dto = new ItemPriceDTO();
    dto.id = item.id;
    dto.supplierId = item.supplierId;
    dto.fromQuantity = item.fromQuantity;
    dto.unitId = item.unitId;
    dto.isDefaultPrice = item.isDefaultPrice;
    dto.itemId = item.itemId;
    dto.currentBuyingPrice = item.currentBuyingPrice;
    dto.currentSellingPrice = item.currentSellingPrice;
    dto.itemPriceFromDates = item.itemPriceFromDates?.map(i => ItemPriceFromDateDTO.fromItemPriceFromDate(i)) ?? [];
    return dto;
  }
}

export class ItemCurrentPrice {
  totalBuyingPrice: number | null;
  totalSellingPrice: number | null;
}

export class ItemPriceFromDateDTO {
  id: number;
  unitSellingPrice: number;
  unitBuyingPrice: number;
  defaultMarginCoefficientPrice: number;
  fromDate: Date;
  itemPriceId: number;
  // itemPrice: ItemPrice;

  public static fromItemPriceFromDate(item: Merlin.Web.Model.ItemPriceFromDate): ItemPriceFromDateDTO {
    let dto = new ItemPriceFromDateDTO();
    dto.id = item.id;
    dto.unitBuyingPrice = item.unitBuyingPrice;
    dto.unitSellingPrice = item.unitSellingPrice;
    dto.defaultMarginCoefficientPrice = item.defaultMarginCoefficientPrice;
    dto.fromDate = item.fromDate;
    dto.itemPriceId = item.itemPriceId;
    return dto;
  }
}

export class ItemCompositionDTO {
  id: number;
  fromQuantity: number;
  marginCoefficient: number | null;
  isDefaultPrice: boolean | null;
  totalSellingPrice: number | null;
  itemId: number;
  itemCompositionLines: ItemCompositionLineDTO[];

  public static fromItemComposition(compo: Merlin.Web.Model.ItemComposition): ItemCompositionDTO {
    let dto = new ItemCompositionDTO();
    dto.id = compo.id;
    dto.fromQuantity = compo.fromQuantity;
    dto.marginCoefficient = compo.marginCoefficient;
    dto.isDefaultPrice = compo.isDefaultPrice;
    dto.totalSellingPrice = compo.totalSellingPrice;
    dto.itemId = compo.itemId;
    dto.itemCompositionLines = compo.itemCompositionLines?.map(x => ItemCompositionLineDTO.fromItemCompositionLine(x)) ?? [];
    return dto;
  }
}

export class ItemCompositionLineDTO {
  id: number;
  parentId: number;
  hasChildren: boolean;
  itemUnitTypeId: number;
  unitId: number;
  unitTranslatedString: string;
  quantity: number;
  quantityFormula: string;
  originalQuantityFormula: string;
  itemId: number;
  supplierId: number;
  itemCompositionId: number;
  label: string;
  compositionLineCategoryId?: number;
  order?: number;
  lineLevel?: number;
  lineRef: string;

  unitSellingPrice: number | null;
  unitBuyingPrice: number | null;
  totalBuyingPrice: number | null;
  totalSellingPrice: number | null;
  marginCoefficient: number | null;

  public static fromItemCompositionLine(line: Merlin.Web.Model.ItemCompositionLine): ItemCompositionLineDTO {
    let dto = new ItemCompositionLineDTO();
    dto.id = line.id;
    dto.unitId = line.unitId;
    dto.quantity = line.quantity;
    dto.itemId = line.itemId;
    dto.supplierId = line.supplierId;
    dto.itemCompositionId = line.itemCompositionId;
    dto.quantityFormula = line.quantityFormula;
    dto.originalQuantityFormula = line.originalQuantityFormula;
    dto.compositionLineCategoryId = line.compositionLineCategoryId;
    dto.lineLevel = line.lineLevel;
    dto.lineRef = line.lineRef;
    dto.order = line.order;
    dto.label = line.label;
    dto.parentId = line.parentId;

    dto.unitSellingPrice = line.unitSellingPrice;
    dto.unitBuyingPrice = line.unitBuyingPrice;
    dto.totalBuyingPrice = line.totalBuyingPrice;
    dto.totalSellingPrice = line.totalSellingPrice;
    dto.marginCoefficient = line.marginCoefficient;
    return dto;
  }
}

export class ItemCurrentPriceRequest {
  itemId: number;
  unitId?: number
  quantity?: number;

  public static map(entity: Merlin.Web.Model.ItemCompositionLine): ItemCurrentPriceRequest {
    let req = new ItemCurrentPriceRequest();
    req.itemId = entity.itemId;
    req.quantity = entity.quantity;
    req.unitId = entity.unitId;
    return req;
  }
}

export class ItemCurrentPriceResponse extends ItemCurrentPriceRequest {
  buyingPrice?: number;
  sellingPrice?: number;
  marginCoefficient?: number;
  totalSellingPrice?: number;
  totalBuyingPrice?: number;
}

