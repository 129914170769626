import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import * as Constants from '../constants';
@autoinject
export class PontoCallback {
  /**
   *
   */
  constructor(private router: Router, private httpClient: HttpClient) {


  }
  async activate() {
    //http://localhost:9000/ponto/callback?code=qYx0A9mIjgZDktNj5hCbHnNNppdIwq6J84OLy6AmY4Y.gl6whr9cNb0kQbA6QSCjUg51OkPWMKJjnuPA6zbb8LU&scope=ai&state=123456789
    const params = new URLSearchParams(window.location.search);
    const authCode = params.get('code');
    let success = true;
    if (authCode != null) {
      let result = await this.httpClient.post(Constants.Application.PontoController.Onboarding + "/" + authCode);
      if (!result.ok) {
        success = false;
      }
    }
    else {
      success = false;
    }
    const channel = new BroadcastChannel('arthur-channel');

    channel.postMessage({ pontoLinkedSuccessfuly: success });
    window.close();

  }
}
