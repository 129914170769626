import { Router } from 'aurelia-router';
import { autoinject } from "aurelia-framework";
import { FloatingBoxViewModel } from "digiwall-lib";
import { ProjectResumeIntern } from "../project-resume";

@autoinject
export class FloatingIntern extends FloatingBoxViewModel {
  constructor(private projectResumeIntern: ProjectResumeIntern, private navigateToEditProject: () => void, private router: Router) {
    super('./floating-intern.html', 'sm');

  }

  async activate(config: any): Promise<void> {
    await super.activate(config);
  }
  public async close() {

    await this.dialogController.close(false);
  }
}
