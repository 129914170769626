@import "../../css/scss/variables";
custom-ui-files {
  .title,
  .file {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 40px auto;
  }
  .title {
    color: $blue-mid;
    > div {
      margin-bottom: 10px;
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  .title,
  .body .file {
    > div {
      &:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        > ui-checkbox {
          > label {
            grid-template-columns: auto;
          }
        }
      }
    }
  }

  .body {
    .file {
      height: 100px;
      &:first-child {
        > div {
          &:not(:first-child) {
            border-top: 1px solid $gray-lighter;
          }
        }
      }
      &:hover {
        > div {
          &:first-child {
            > ui-button {
              display: flex;
              border: solid 1px $purple-lightest;
              > .ui-btn__inner {
                background-color: $purple-lightest;
                > .ui-btn {
                  > .ui-btn__icon {
                    > ui-icon {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
      > div {
        height: inherit;
        border-right: 1px solid $gray-lighter;
        display: flex;
        &:first-child {
          > ui-button {
            background: $black-0;
            border: none !important;
            border-radius: 60px;
            > .ui-btn__inner {
              border-radius: 60px;
              > .ui-btn {
                background-color: transparent;
                width: 35px;
                height: 35px;
                > .ui-btn__icon {
                  > ui-icon {
                    display: none;
                  }
                }
              }
            }
            &:focus {
              ui-icon {
                display: block;
              }
            }
          }
        }

        &:not(:first-child) {
          border-bottom: 1px solid $gray-lighter;
        }

        &:not(:last-child) {
          align-items: center;
          justify-content: center;
        }
        &:last-child {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 15px 20px;
          gap: 21px;
          .file-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 5px;
            cursor: pointer;
            > ui-icon {
              color: $blue-mid;
            }
          }
          .file-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0px;
            gap: 10px;
            width: 100%;
            > div {
              display: flex;
              align-items: center;
              height: 100%;
              > .file-created-time {
                :first-child {
                  color: $black-100;
                }
                :last-child {
                  color: $gray-mid;
                }
              }
              &:first-child {
                gap: 30px;
                > div {
                  height: 100%;
                  display: flex;
                  align-items: center;
                }
              }
              &:last-child {
                gap: 12px;
              }
            }
          }
        }
      }
    }
  }
}
