$grid-header-bar-height: 180px;
$grid-header-text-color: $blue-mid;
$grid-header-bg-color: white;
$grid-color-hover: $purple-lightest;

$grid-border-color: $gray-very-light;
.theme-light {
  custom-ag-grid,
  * {
    .grid-header-bar {
      margin: 0 40px;
      border-bottom: 1px solid $gray-lighter;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-title {
        grid-area: title;
        padding: 10px 0;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        .title {
          font-size: 40px;
          font-weight: 300;
        }
      }

      $base: unquote(".grid-header-bar");
      > #{$base}__btn {
        display: flex;
        align-items: start;
        height: auto;
        width: 100%;
        gap: 10px;
        .button-container {
          display: flex;
          margin-left: auto;
          align-items: center;
          gap: 10px;
          > div {
            display: flex !important;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            height: 36px;
            > .input-search {
              display: flex;
              align-items: center;
            }
            > ui-button {
              border: 1px solid $purple-light;
              border-radius: 8px;
              > .ui-btn__inner {
                &:focus-within,
                &:hover {
                  background-color: transparent !important;
                }
                .ui-btn {
                  color: $blue-darkest;
                  &:hover,
                  &:focus,
                  &:focus-within {
                    color: $blue-mid;
                    background-color: transparent !important;
                  }
                }
              }
            }
          }
          ui-button {
            width: 40px;
            height: 40px;
          }
          .ag-grid-button-reset-filter {
            display: none;
          }
        }

        .center-button {
          height: 40px;
          border: 1px solid $grid-border-color !important;
          border-radius: 5px;
          justify-content: space-between;
          > * + * {
            border-right: solid 1px $grid-border-color;
          }
          :last-child {
            border: none;
          }
        }
        .button-space {
          > ui-button {
            width: 40px;
            height: 40px;
            > .ui-btn__inner > .ui-btn {
              &:hover {
                background-color: transparent !important;
                color: $blue-mid;
              }
              &:focus-within,
              &:active {
                background-color: transparent !important;
                box-shadow: none !important;
              }
            }
          }
        }
      }

      .ui-btn__wrapper.ui-theme--secondary .ui-btn__inner {
        color: $black-100;

        &:hover {
          background-color: transparent;
          color: $blue-mid;
        }
        > a:hover {
          background-color: inherit;
        }
      }
    }
    .ag-theme-material {
      .ag-menu-list {
        padding: 8px 10px;
      }
      .ag-menu {
        border-radius: 12px !important;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15) !important;
        .ag-menu-separator {
          height: 5px;
        }
      }
      .ag-ltr {
        .ag-menu-option-icon {
          padding-left: 5px;
        }
      }

      .ag-menu-option-part {
        padding: 7.5px 0;
      }
      .ag-menu-option-text {
        padding-left: 10px;
        padding-right: 10px;
      }
      .ag-menu-option-popup-pointer {
        padding-right: 5px;
      }

      .ag-icon-small-right::before {
        content: "\eddb";
        font-family: "Merlin-Icons";
        font-size: 14px;
      }
    }
  }

  ag-grid-aurelia.datagrid.ag-theme-material {
    padding-right: 40px;
    font-family: $font-family;
    min-height: 240px;

    .ag-root-wrapper {
      .ag-popup {
        .ag-popup-child {
          box-shadow: none;
          > div > .ui-field {
            .ui-select--focus {
              border-color: $gray-lighter;
            }
            .ui-select {
              border-color: transparent;
              background-color: $purple-lightest;
              padding: 0 15px;
            }
          }
        }
        .ag-tabs {
          top: 45px !important;
        }
      }
      .ag-root-wrapper-body > .ag-root {
        > .ag-overlay {
          > .ag-overlay-panel {
            > .ag-overlay-wrapper {
              > div {
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
        > .ag-body-viewport {
          .ag-center-cols-clipper > .ag-center-cols-viewport > .ag-center-cols-container > .ag-row {
            .ag-cell-focus,
            .ag-pinned-left-cols-container > .ag-row > .ag-cell-focus {
              border-right: solid 1px $blue-mid !important;
              border-top: solid 1px $blue-mid !important;
              border-bottom: solid 1px $blue-mid !important;
              border-left: solid 1px $blue-mid !important;
            }
          }
        }
      }
    }
    .ag-header {
      background-color: $grid-header-bg-color;
      border-bottom: none;
      color: $grid-header-text-color;
      font-size: 13px;
      font-weight: 400;
      z-index: 1;

      .ag-header-icon.ag-header-label-icon.ag-filter-icon {
        display: none;
      }

      .ag-cell-label-container:hover > .ag-header-icon {
        position: relative !important;
      }

      .ag-pinned-left-header
        > .ag-header-row
        > .ag-header-cell
        > .ag-header-cell-comp-wrapper
        > .ag-cell-label-container
        > .ag-header-icon,
      .ag-header-viewport
        > .ag-header-container
        > .ag-header-row
        > .ag-header-cell
        > .ag-header-cell-comp-wrapper
        > .ag-cell-label-container
        > .ag-header-icon {
        padding-right: 15px;
        position: absolute;
        &:hover {
          background: $purple-lighter !important;
          border-radius: 60px;
          padding: 5px;
          margin-right: 10px;
        }
        .ag-icon-menu {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMDI0IDEwMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojM0M1NEU0O30KPC9zdHlsZT4KPGcgaWQ9Imljb21vb24taWdub3JlIj4KPC9nPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNDI2LjcsNzY4aDE3MC43di04NS4zSDQyNi43Vjc2OHogTTEyOCwyNTZ2ODUuM2g3NjhWMjU2SDEyOHogTTI1Niw1NTQuN2g1MTJ2LTg1LjNIMjU2VjU1NC43eiIvPgo8L3N2Zz4K");
          padding: 10px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          &::before {
            content: "";
          }
        }
      }
      .ag-header-viewport > .ag-header-container > .ag-header-row > .ag-header-cell {
        padding: 0 15px;
        padding-right: 0;
        border-bottom: none;
        &:hover {
          background-color: $purple-lightest !important;
        }
      }
    }

    .ag-cell-label-container {
      color: $grid-header-text-color;
    }
  }
  .ag-body-viewport {
    .ag-pinned-left-cols-container {
      left: 0;
      .ag-row {
        .ag-cell-last-left-pinned {
          border-left: solid 1px $gray-very-light !important;
          .ag-cell-value {
            height: inherit;
          }
        }
        .ag-cell {
          padding: 0;
          height: 40px;
          > div > ui-button > .ui-btn__inner {
            box-shadow: none;
            > .ui-btn {
              &:focus-within {
                background-color: transparent !important;
              }
              &:hover {
                background-color: transparent !important;
              }
              &:active {
                background-color: transparent !important;
                box-shadow: none !important;
              }
            }
          }
        }
      }
    }
    .ag-center-cols-clipper > .ag-center-cols-viewport > .ag-center-cols-container {
      > .ag-row > .ag-cell {
        padding: 0 15px;
        .ui-tag {
          > .ui-tag__label {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .ag-row-inline-editing {
        .ag-cell-inline-editing {
          height: 40px;
          box-shadow: none;
          > input {
            height: fit-content;
            background-color: transparent;
          }
        }
      }
    }
  }

  & .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:hover,
  & .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell.ag-column-resizing,
  & .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:hover,
  & .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell.ag-column-resizing,
  & .ag-header-group-cell:first-of-type:hover,
  .ag-theme-material .ag-header-group-cell:first-of-type.ag-column-resizing,
  & .ag-header-cell:first-of-type:hover,
  .ag-theme-material .ag-header-cell:first-of-type.ag-column-resizing {
    background-color: $grid-color-hover;
  }

  .ag-header-group-cell {
    padding-left: 10px;
    padding-right: 0px;
  }
}

.ag-header-cell-text {
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
}

.ag-pinned-right-cols-container {
  border-left: 1px solid $grid-border-color !important;
}

.ag-row {
  border-bottom: none !important;
  > .ag-cell {
    border-bottom: 1px solid $grid-border-color;
    &:first-child {
      border-bottom: none;
    }
  }
}

.ag-row-last:not(.ag-row-last-row) {
  // :not => Special rule for price-offer-lines-grid
  border: none !important;
}

.row-tools {
  opacity: 0;
  scale: 0;
  transition:
    opacity 0.2s,
    scale 0.2s;
}

.ag-row-hover {
  background-color: $grid-color-hover;

  .row-tools {
    scale: 1;
    opacity: 1;
  }
}
.ag-row-focus {
  background-color: $grid-color-hover;
}

.ag-pinned-left-header,
.ag-pinned-right-header {
  border: none !important;
}
.ag-pinned-left-cols-container,
.ag-pinned-left-header {
  ui-checkbox {
    > label {
      ui-icon.ui-icon {
        color: $blue-mid;
      }
      input:hover {
        ~ .ui-icon {
          color: $blue-mid;
        }
      }
    }
  }
}

.ag-cell {
  font-size: 14px;
  border-right: 1px solid $grid-border-color !important;
}
.ag-header-cell-resize {
  cursor: col-resize;
}

.ag-body-horizontal-scroll,
.ag-horizontal-left-spacer,
.ag-body-horizontal-scroll-viewport,
.ag-horizontal-right-spacer {
  border: none !important;
}

.ag-pinned-left-cols-container {
  .ag-row-level-0 {
    border: none !important;
    > :first-child {
      border: none !important;
    }
    > :not(:first-child) {
      border-left: 1px solid $grid-border-color;
      border-bottom: 1px solid $grid-border-color !important;
    }
    > :last-child {
      border-right: 1px solid $grid-border-color !important;
    }
  }

  .ag-row-level-0.ag-row-first {
    > :not(:first-child) {
      border-top: 1px solid $grid-border-color !important;
    }
  }

  .ag-row-hover,
  .ag-row-level-0,
  .ag-row-level-1,
  .ag-row-focus {
    > :first-child {
      background-color: white !important;
      &.ag-cell-value,
      .ag-cell-value {
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          ui-button {
            border: none !important;
            div {
              box-shadow: none;
              a {
                box-shadow: none;
                background-color: transparent;
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
    > :first-child:hover,
    :first-child:active {
      ui-button {
        background: $purple-lightest !important;
        border-radius: 60px !important;
        .ui-btn__inner > .ui-btn {
          color: $blue-mid;
        }
      }
    }
  }

  .ag-cell-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .ag-cell-value {
      display: flex;
      justify-content: center;
      align-items: center;
      .ui-option__control {
        display: block;
      }
    }
  }
}

.ag-center-cols-container {
  .ag-row {
    border-bottom: 1px solid $grid-border-color !important;
    border-right: 1px solid $grid-border-color !important;
  }
  .ag-row.ag-row-first {
    border-top: 1px solid $grid-border-color !important;
  }
  > .ag-row > .ag-cell {
    border-bottom: none;
  }
}

ui-pagination {
  > div {
    > .ui-pagination {
      > .ui-pagination-el {
        border-radius: 4px;
        border: 1px solid $gray-very-light;
        &.active,
        &:hover {
          background-color: $purple-very-light;
          > .ui-pagination-el--link {
            color: $blue-mid;
          }
        }
        > .ui-pagination-el--link {
          color: $black-100;
        }
      }
    }
  }
}
.ui-btn::before {
  background-image: none !important;
}

.ag-theme-material .ag-icon-arrows::before {
  display: none;
}
.ag-dnd-ghost {
  cursor: grabbing !important;
}

.between-header-grid {
  .filter-slot {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: start;
    margin: 16px 40px;
    .filter {
      gap: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      > div.filter-view {
        display: flex;
        gap: 10px;
        align-items: center;
        color: $blue-mid;
        background-color: $purple-very-light;
        border-radius: 20px;
        padding: 5px 10px;
        height: fit-content;
      }
      .filter-filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2px 20px;
        border-right: 1px solid $gray-lighter;
        .filter-label {
          color: $blue-mid;
          margin-right: 10px;
          font-size: 12px;
          line-height: 15px;
          font-weight: 400;
        }
        .filter-value {
          > span {
            padding-right: 20px;
            &:last-child {
              padding-right: 0;
            }
          }
          .ui-tag {
            background-color: $purple-very-light !important;
            color: $blue-darkest !important;
            border-radius: 6px;
            font-size: 12px;
            padding: 5px 8px 4px 10px;
            box-shadow: none;
            margin: 0;
            font-weight: 400;
            min-height: 30px;
            > div > .digi-cross {
              font-size: 10px;
            }
          }
          ui-button {
            border-color: $purple-light;
            color: $black-100;
            &:hover {
              background-color: transparent;
              color: $blue-mid;
            }
            .ui-btn {
              min-height: 25px;
            }
          }
        }
      }
    }
    .filter-reset {
      margin-left: 10px;
      display: flex;
      align-items: center;
      &__result {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $gray-darker;
      }
      > ui-button {
        .ui-btn__inner {
          color: $gray-mid;
          box-shadow: none;
          &:hover {
            background-color: transparent;
            color: $black-100;
          }
          .ui-btn {
            background-color: transparent;
            > .ui-btn__icon {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}
