import { autoinject, computedFrom } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { FloatingBoxViewModel } from "digiwall-lib";
import { ProjectResumeDates } from "../project-resume";
import moment = require('moment');

@autoinject
export class FloatingDate extends FloatingBoxViewModel {

  constructor(private projectResumeDate: ProjectResumeDates, private navigateToEditProject: () => void, private router: Router) {
    super('./floating-date.html', 'sm');

  }

  async activate(config: any): Promise<void> {
    await super.activate(config);
  }
  public async close() {

    await this.dialogController.close(false);
  }

  @computedFrom("projectResumeDate.offerDeadlineDate")
  get getUtcOfferDeadlineDate() {
    return moment.utc(this.projectResumeDate.offerDeadlineDate).local().format("DD/MM/YYYY HH:mm");
  }
}
