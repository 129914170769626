import { DialogController } from 'aurelia-dialog';
import { Merlin } from 'generated';
import { Router } from 'aurelia-router';
import { EditingModeEnum, ServiceBase, CustomLogger, EntityDetailViewModelBase, EnumerationType, Various, IMenuGroup, EnumerationTypeService, DialogBoxViewModel, ActionDialogBoxInputParameters } from 'digiwall-lib';
import { autoinject, observable, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { DataFormat } from 'select2';
import { FilterQueryOp, Predicate } from 'breeze-client';

@autoinject
export class FileTypeDetail extends EntityDetailViewModelBase<Merlin.Web.Model.FileType> {
  private enumerationTypeColorService: ServiceBase<Merlin.Web.Model.EnumerationTypeColor>;
  private fileTypeEnumService: EnumerationTypeService;
  public menuItems: Array<IMenuGroup>;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController) {
    super(router, logger);
    this.enumerationTypeColorService = new ServiceBase<Merlin.Web.Model.EnumerationTypeColor>(Constants.EntityTypeNames.EnumerationTypeColor);
    this.fileTypeEnumService = new EnumerationTypeService(Constants.EnumerationTypes.FileType);

    super.initialize(new ServiceBase<Merlin.Web.Model.FileType>(Constants.EntityTypeNames.FileType));
  }

  public async activate(params): Promise<void> {
    let id = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.type = await this.fileTypeEnumService.createEntity();
      this.entity.type.active = true;
      this.entity.type.default = false;
      this.entity.type.backgroundColor = "#F2F4FE";
      this.entity.type.textColor = "#3C54E4";
      this.entity.isVisibleFromLancelot = false;
      if (params.callback != null) {
        params.callback(this.entity);
      }
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'type');
      this.controller.addObject(this.entity);
    }
    this.setMenuItems();
  }

  @computedFrom('editingMode', 'entity.type.denomination._translation')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("filetype.addFileType");
    } else {
      if (this.entity)
        return (this.entity.type.denomination as any)._translation;
    }
  }

  public get ressourceName(): string {
    return Constants.EntityTypeNames.EnumerationType;
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, 'enumerationTypeColor').subscribe(async (newValue, oldValue) => {
        if (newValue != null) {
          let enumerationTypeColor = await this.enumerationTypeColorService.firstEntity(new Predicate("id", FilterQueryOp.Equals, newValue));
          this.entity.type.textColor = enumerationTypeColor.textColor;
          this.entity.type.backgroundColor = enumerationTypeColor.backgroundColor;
        }
      })
    )
  }

  public formatEnumerationTypeColor(event: any) {
    if (event.data != null) {
      return `<div style="width: fit-content; margin-right: 10px; border: 1px solid #6d7277; border-radius: 10px; color: ${event.data.textColor}; background-color: ${event.data.backgroundColor};"><span style="margin: 0 10px; font-size: 15px;">${event.data.description._translation}<span></div>`;
    }
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      },
    ];
  }
}
