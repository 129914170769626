import { ServiceBase } from 'digiwall-lib';
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { Merlin } from 'generated';
import * as Constants from '../../constants';
import { DataFormat } from 'select2';

@autoinject
export class PreparationTemplate {
  title: string = this.i18n.tr("workingsitepreparationtemplate.choosePreparationTemplate")
  private workingSitePreparationTemplateService: ServiceBase<Merlin.Web.Model.WorkingSitePreparationTemplate>;
  private selectedTemplate: DataFormat


  constructor(private controller: DialogController, private i18n: I18N) {
    this.workingSitePreparationTemplateService = new ServiceBase<Merlin.Web.Model.WorkingSitePreparationTemplate>(Constants.EntityTypeNames.WorkingSitePreparationTemplate);
  }

  async activate(params) {

  }

  async close() {
    await this.controller.cancel(false);
  }

  async confirm() {
    await this.controller.close(true, this.selectedTemplate)
  }
}
