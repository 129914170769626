import { DialogController } from 'aurelia-dialog';
import { Merlin } from 'generated';
import { Router } from 'aurelia-router';
import { EditingModeEnum, ServiceBase, CustomLogger, EntityDetailViewModelBase, EnumerationType, Various, IMenuGroup, validateEnumerationType } from 'digiwall-lib';
import { autoinject, observable, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { DataFormat } from 'select2';
import { FilterQueryOp, Predicate } from 'breeze-client';

@autoinject
export class EnumerationTypeDetail extends EntityDetailViewModelBase<EnumerationType> {
  private enumerationTypeColorService: ServiceBase<Merlin.Web.Model.EnumerationTypeColor>;

  @observable
  private enumerationTypeColor: DataFormat;
  private menuItems: Array<IMenuGroup>;
  private errors: string;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController) {
    super(router, logger);
    this.enumerationTypeColorService = new ServiceBase<Merlin.Web.Model.EnumerationTypeColor>(Constants.EntityTypeNames.EnumerationTypeColor);
    super.initialize(new ServiceBase<EnumerationType>(Constants.EntityTypeNames.EnumerationType));
  }

  public paramType: string;

  public async activate(params): Promise<void> {
    let id = params.param1;
    this.paramType = params.param2;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.active = true;
      this.entity.default = false;
      this.entity.backgroundColor = "#F2F4FE";
      this.entity.textColor = "#3C54E4";
      if (params.callback != null) {
        params.callback(this.entity);
        this.setParamType();
      }
      else {
        this.setCategory();
      }
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
      this.setParamType();
      this.controller.addObject(this.entity);
    }
    this.setMenuItems();
    validateEnumerationType(this.entity);

  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            },
            hidden: () => {
              return this.entity.uniqueCode != null
            }
          }
        ]
      },
    ];
  }

  @computedFrom('editingMode', 'entity.denomination._translation')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      switch (this.paramType) {
        case Constants.EnumerationTypes.Civility.toString():
          return this.i18n.tr("enumerationtype.addCivility");
        case Constants.EnumerationTypes.CompanyType.toString():
          return this.i18n.tr("enumerationtype.addCompanyType");
        case Constants.EnumerationTypes.UnitType.toString():
          return this.i18n.tr("enumerationtype.unitType");
        case Constants.EnumerationTypes.ThirdPartyType.toString():
          return this.i18n.tr("enumerationtype.addThirdPartyType");
        case Constants.EnumerationTypes.PartnerType.toString():
          return this.i18n.tr("enumerationtype.addPartnerType");
        case Constants.EnumerationTypes.ThirdPartyTag.toString():
          return this.i18n.tr("enumerationtype.addThirdPartyTag");
        case Constants.EnumerationTypes.CompanyFunction.toString():
          return this.i18n.tr("enumerationtype.addCompanyFunction");

        case Constants.EnumerationTypes.ActivityCode.toString():
          return this.i18n.tr("enumerationtype.addActivityCode");
        case Constants.EnumerationTypes.ItemDeliveryType.toString():
          return this.i18n.tr("enumerationtype.addItemDeliveryType");
        case Constants.EnumerationTypes.ItemFeature.toString():
          return this.i18n.tr("enumerationtype.addItemFeature");
        case Constants.EnumerationTypes.ContainerType.toString():
          return this.i18n.tr("enumerationtype.addContainerType");
        case Constants.EnumerationTypes.FileType.toString():
          return this.i18n.tr("enumerationtype.addFileType");
        case Constants.EnumerationTypes.EmailType.toString():
          return this.i18n.tr("enumerationtype.addEmailType");
        case Constants.EnumerationTypes.CompanyFunction.toString():
          return this.i18n.tr("enumerationtype.addCompanyFunction");
        case Constants.EnumerationTypes.UnitType.toString():
          return this.i18n.tr("enumerationtype.addUnitType");
        case Constants.EnumerationTypes.ItemDeliveryType.toString():
          return this.i18n.tr("enumerationtype.addItemDeliveryType");
        case Constants.EnumerationTypes.ActivityCode.toString():
          return this.i18n.tr("enumerationtype.addActivityCode");
        case Constants.EnumerationTypes.TaskType.toString():
          return this.i18n.tr("enumerationtype.addTaskType");
        case Constants.EnumerationTypes.TaskCriticityLevel.toString():
          return this.i18n.tr("enumerationtype.addCriticityLevel");
        case Constants.EnumerationTypes.PriceOfferLineTag.toString():
          return this.i18n.tr("enumerationtype.addPriceOfferLineTag");
        case Constants.EnumerationTypes.TaskTag.toString():
          return this.i18n.tr("enumerationtype.addTaskTag");
        case Constants.EnumerationTypes.DocumentTag.toString():
          return this.i18n.tr("enumerationtype.addTaskTag");
        case Constants.EnumerationTypes.DocumentType.toString():
          return this.i18n.tr("enumerationtype.addTaskType");
        case Constants.EnumerationTypes.UserFunctions.toString():
          return this.i18n.tr("enumerationtype.addUserFunction");
        default:
          return this.i18n.tr("enumerationtype.addDocumentTag");

      }
    } else {
      if (this.entity)
        return (this.entity.denomination as any)._translation;
    }
  }

  public get ressourceName(): string {
    return Constants.EntityTypeNames.EnumerationType;
  }

  public setCategory() {
    this.entity.category = parseInt(this.paramType);
  }

  public setParamType() {
    this.paramType = this.entity.category.toString();
  }

  public async save() {
    await super.save();
    if (this.dialogController?.controller != null)
      this.dialogController.ok(this.entity);
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, 'enumerationTypeColor').subscribe(async (newValue, oldValue) => {
        if (newValue != null) {
          let enumerationTypeColor = await this.enumerationTypeColorService.firstEntity(new Predicate("id", FilterQueryOp.Equals, newValue));
          if (enumerationTypeColor != null) {
            this.entity.textColor = enumerationTypeColor.textColor;
            this.entity.backgroundColor = enumerationTypeColor.backgroundColor;
          }
        }
      })
    )
  }
  public formatEnumerationTypeColor(event: any) {
    if (event.data != null) {
      return `<div style="width: fit-content; margin-right: 10px; border-radius: 10px; color: ${event.data.textColor}; background-color: ${event.data.backgroundColor};"><span style="margin: 0 10px; font-size: 15px;">${event.data.description._translation}<span></div>`;
    }
  }
}
