import { I18N } from 'aurelia-i18n';
import { autoinject, bindable, Container, customElement } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { Merlin } from "generated";

@autoinject
@customElement("add-request-supplier-offer-step-4")
export class AddRequestSupplierOfferStep4 {
  @bindable
  public entity: Merlin.Web.Model.RequestSupplierOffer;
  constructor() {

  }
}
