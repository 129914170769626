import { I18N } from 'aurelia-i18n';
import { DialogController } from 'aurelia-dialog';
import { Callback } from '../auth/callback';
import { Router } from 'aurelia-router';
import { CustomLogger, EntityDetailViewModelBase, EnumerationTypeService, FileFlow, PictureHelper, ServiceBase, GlobalLoaderService } from 'digiwall-lib';
import { Merlin } from 'generated';
import * as Constants from '../constants';
import { autoinject } from 'aurelia-framework';

@autoinject
export class AddNotificationSentFile {
  private Constants: any = Constants;
  private files: Array<Merlin.Web.Model.NotificationSentFile>;
  private fileTypeService: EnumerationTypeService;
  private service: ServiceBase<Merlin.Web.Model.NotificationSentFile>

  private columnsInfo: Array<{ columnName: string, columnVisibility: boolean }> = [
    { columnName: 'description', columnVisibility: false },
    { columnName: 'fileTypeId', columnVisibility: false }
  ]


  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController, private fileHelper: PictureHelper, private i18n: I18N, private globalLoaderService: GlobalLoaderService) {
    this.fileTypeService = new EnumerationTypeService(Constants.EnumerationTypes.FileType);
    this.service = new ServiceBase<Merlin.Web.Model.NotificationSentFile>(Constants.EntityTypeNames.NotificationSentFile)
  }
  public async activate(params: any) {
    this.files = new Array();
    if (params.files) {
      this.files.push(...params.files);
    }
    if (params.callback) {
      params.callback(this);
    }
  }


  public async uploadFile(file: FileFlow) {
    this.globalLoaderService.allow(true);
    let fileEntity: Merlin.Web.Model.NotificationSentFile = await this.service.createEntity();
    fileEntity.name = file.name;
    fileEntity.size = file.size;
    return fileEntity;
  }

  public async uploadedFile(fileUploaded: FileFlow) {
    let fileEntity: Merlin.Web.Model.NotificationSentFile = fileUploaded.linkEntity;
    fileEntity.url = fileUploaded.url;
    fileEntity.fileType = await this.fileTypeService.getDefault();
    this.files.push(fileEntity);
  }
  public async deleteFile(file: Merlin.Web.Model.NotificationSentFile) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  public getFileMenuItem(currentThis: Merlin.Web.Model.NotificationSentFile) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.download"),
            icon: "digi-download",
            handler: async () => {
              this.fileHelper.downloadFile(currentThis);
            }
          },
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: async () => {
              await this.deleteFile(currentThis);
            }
          }
        ]
      }
    ]
  }

  public async close() {
    await this.dialogController.ok(this.files);
  }

}
