import { autoinject, bindable } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { RouteByGroupName } from "parameters/parameters";

@autoinject
export class SubGroup {
  @bindable
  group: RouteByGroupName;

  constructor(private router: Router) {

  }

  handleClick() {
    this.group = null;
  }
}
