@import "../css/scss/variables";
.module-task.hide-parameter {
  .button-view {
    > :nth-child(2) {
      display: none;
    }
  }
}
.module-task {
  height: 100%;
  display: flex;
  flex-direction: column;

  .custom-filter {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .tasks-filter-1,
    .tasks-filter-2 {
      margin: 0 40px;
    }

    .tasks-filter-1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      border-bottom: 1px solid #cbd3ff !important;
      > div.filter-counter {
        height: 40px;
        display: flex;
        align-items: center;
        gap: 20px;

        div {
          width: 180px;
          height: inherit;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $gray-light;
          gap: 5px;
          cursor: pointer;
          &.isSelected {
            border-bottom: 2px solid $primary;
            color: $black-100;
          }
          > span.counter {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: $black-50;
          }
        }
      }
    }
    .tasks-filter-2 {
      display: flex;
      justify-content: space-between;
      ui-button {
        .ui-btn {
          padding: 5px 20px;
        }
      }
    }
  }

  .filters-container {
    display: flex;
    gap: 10px;
    margin: 0 40px;
    align-items: center;

    ui-select {
      flex: 0;
      min-width: 145px;
      margin: 16px 0;
      border: none;

      .select2-container span.select2-selection--single .select2-selection__rendered {
        font-size: 12px;
        color: $gray-mid;
      }
      .select2 > .selection > .select2-selection > .select2-selection__arrow {
        margin-top: -3.5px;
        b {
          width: 6px;
          height: 6px;
          // border-bottom-color: $gray-mid !important;
          // border-right-color: $gray-mid !important;
        }
      }
    }
    filter {
      flex: 1;
      .filter-slot {
        margin-left: 0;
      }
    }
  }

  .tasks-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex: 1;

    &.classic-list {
      flex-direction: column;
    }

    &.full-screen {
      > .task-container-ag-grid {
        > ag-grid-aurelia {
          .ag-pinned-left-cols-container {
            .ag-row-last-row {
              > .ag-cell-value:not(:first-child) > div > .metering-line-color {
                &::before {
                  height: 56% !important;
                }
              }
            }
            .ag-row > .ag-cell > div > .task-cell {
              padding-right: 15px;
              .task-button-expand {
                align-items: center;
              }
              .task-title {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
    > div {
      &.task-container-ag-grid {
        width: 510px;
        border-right: 1px solid $gray-lighter;
        position: relative;

        > ag-grid-aurelia {
          padding: 0;

          .ag-pinned-left-cols-container {
            width: 100% !important;
            min-width: unset !important;
            max-width: unset !important;

            .task-button-expand {
              border-left: solid 4px transparent;
            }

            .ag-row-selected {
              .task-button-expand {
                border-left-color: $blue-mid;
              }
            }

            .ag-row {
              height: 120px;
              border-left-style: none;

              .ag-cell-focus {
                border-top: 1px solid $gray-very-light;
                border-bottom: 1px solid $gray-very-light;
              }

              .ag-cell-last-left-pinned {
                width: calc(100% - 40px) !important;
              }

              .ag-cell {
                .task-header {
                  display: flex;
                  justify-content: space-between;
                  align-items: start;
                  padding-top: 5px;
                  .task-link-name,
                  .task-title {
                    line-height: initial;
                  }
                  .task-link-name {
                    color: $gray-mid;
                    font-size: 12px;
                  }
                  .task-title {
                    font-size: 16px;
                  }
                  ui-icon {
                    color: $gray-light;
                  }
                }
              }

              &.ag-row-level-0 {
                .ag-cell-last-left-pinned {
                  .task-cell > .task-button-expand {
                    min-width: 42px;
                  }
                }
              }
              &.ag-row-level-1 {
                > .ag-cell-value:not(:first-child) {
                  padding-right: 34px;
                  margin-left: calc(1 * 34px);
                  overflow: visible;

                  .metering-line-color {
                    &::before {
                      content: "";
                      background-color: #6b7cea;
                      width: 1px;
                      position: absolute;
                      top: -2px;
                      //margin-left: 23px;
                      margin-left: -18px;
                      height: calc(100% + 2px);
                    }
                    &::after {
                      content: "";
                      position: absolute;
                      display: block;
                      top: calc(50% - 5px);
                      //margin-left: 22px;
                      margin-left: -19px;
                      border-color: #6b7cea;
                      border-width: 1px;
                      width: 18px;
                      border-style: solid;
                      border-bottom-left-radius: 5px;
                      height: 10px;
                      border-top: none;
                      border-right: none;
                      border-left: 1px solid transparent;
                    }
                  }
                }
                > .ag-cell-value:not(:last-child) {
                  border-left: none;
                  border-right: none !important;
                }
              }
              &.ag-row-last-row {
                > .ag-cell-value:not(:first-child) > div > .metering-line-color {
                  &::before {
                    height: 54%;
                  }
                }
              }
              .ag-cell {
                height: 120px;
                border-right: 1px solid $gray-light;
                .task-cell {
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  height: inherit;
                  > div {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    &:first-child {
                      padding-left: 10px;
                    }
                    ui-button {
                      border-radius: $radius;
                      border: solid 1px $gray-very-light !important;
                      width: 24px;
                      height: 24px;
                      .ui-btn__icon {
                        padding: 0;
                      }
                    }
                    > div > .task-link-name {
                      font-size: 12px;
                      line-height: 15px;
                      color: $gray-mid;
                    }
                    > div > .task-title,
                    > .task-info {
                      display: flex;
                      line-height: initial;
                    }
                    > div > .task-title {
                      padding-bottom: 15px;
                      ui-icon {
                        color: $gray-light;
                        font-size: 15px;
                      }
                    }
                    &:first-child {
                      justify-content: center;
                    }
                    &:not(:first-child) {
                      width: 100%;
                      overflow: hidden;
                      margin: 10px;
                      margin-right: 15px;
                    }
                    > div > .task-title {
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 28px;
                      display: flex;
                      gap: 5px;
                      justify-content: space-between;
                      align-items: center;

                      > span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                    }
                    > .task-info {
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      .avatar {
                        max-height: 24px;
                        max-width: 24px;
                        margin-right: 2.5px;
                      }
                      > div {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        > div {
                          display: flex;
                          align-items: center;
                          overflow: hidden;
                          > .task-date,
                          .task-type,
                          .task-status {
                            overflow: hidden;
                            .ui-tag__label {
                              overflow: inherit;
                              text-overflow: ellipsis;
                            }
                          }
                        }
                        .task-date {
                          padding: 0 2.5px;
                          .ui-tag {
                            display: flex;
                            align-items: center;
                            flex-direction: row-reverse;
                            padding: 4px 15px;
                            margin: 0;
                            .ui-tag__label {
                              padding: 0;
                              font-size: 12px;
                              padding-left: 3px;
                            }
                            .ui-tag__icon {
                              ui-icon {
                                padding: 0;
                                font-size: 15px;
                                padding-left: 0;
                              }
                            }
                          }
                        }
                        .task-type,
                        .task-status {
                          padding: 0 2.5px;
                          .ui-tag {
                            // background-color: $purple-very-light !important;
                            // color: $blue-mid !important;
                            padding: 4px 15px;
                            margin: 0;
                            .ui-tag__label {
                              padding: 0;
                              font-size: 12px;
                              gap: 2px;
                              line-height: 15px;
                            }
                          }
                        }
                        &.task-diff {
                          display: flex;
                          justify-content: flex-end;
                          font-size: 10px;
                          color: $gray-light;
                          padding-top: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        > ui-button {
          position: absolute;
          top: 10px;
          right: -16px;
          border: solid 1px $gray-lighter;
          border-radius: $radius;
          &:hover {
            background-color: $white !important;
          }
          ui-icon {
            font-size: 10px !important;
          }
        }
      }
      &.task-container-detail {
        overflow: scroll;
        //padding: 40px;
        width: 80%;
        // margin-bottom: 50px;
        .task-container-detail-unselected {
          margin-top: 20%;
          font-size: 30px;
          text-align: center;
        }
        .task-component {
          height: 100%;
        }
        .task-component__desc {
          padding: 40px;
          .task-component__desc__header__left {
            display: flex;
            align-items: center;
            gap: 5px;
            .avatar {
              max-width: 24px;
              max-height: 24px;
            }
            > span {
              font-size: 12px;
            }
          }
          .task-component__desc__header__right {
            font-weight: 500;
            font-size: 10px;
            display: flex;
            align-items: center;
            > span {
              padding-right: 10px;
              > span {
                &:last-child {
                  color: $gray-mid;
                }
              }
            }
            > ui-button {
              border-radius: $radius;
              font-size: 14px;
              > div,
              > div > a {
                background-color: transparent;
              }
            }
            > ui-button:last-child {
              padding: 5px 15px;
              margin-left: 10px;
            }
          }
          .task-component__desc__body {
            padding-top: 30px;
            .task-component__desc__body__title {
              margin-bottom: 20px;
            }
            .task-component__desc__body__description {
              line-height: 21px;
            }
          }
        }
        .task-component__edit-column {
          > div > div {
            &:nth-child(2) {
              display: flex;
              align-items: center;
            }
            .ui-tag {
              box-shadow: none;
              .ui-tag__label {
                padding: 4px 15px;
                line-height: 15px;
              }
            }
          }
          .task-component__edit-column__sub-tasks > div {
            > .task-component__edit-column__title {
              margin-bottom: 10px;
            }
            // > span:not(:first-child) {
            //   text-decoration: underline;
            // }
            > ui-button {
              margin-top: 20px;
            }
          }
        }
      }
    }
    &.full-screen {
      > div {
        &.task-container-ag-grid {
          width: 98%;
          > ui-button {
            transform: rotate(180deg);
          }
          > ag-grid-aurelia {
            .ag-row {
              height: 77px;
              border-left: none !important;
              &.ag-row-level-1 {
                width: 100%;
                > div {
                  &:first-child {
                    border-left: none !important;
                  }
                }
              }

              .ag-cell {
                height: 77px;
                .task-cell {
                  > div {
                    flex-direction: row;
                    justify-content: space-between;
                    > div:first-child {
                      display: flex;
                      align-items: center;
                    }
                    > .task-title {
                      justify-content: flex-start;
                    }
                    > .task-info {
                      flex-direction: column;
                      > :first-child {
                        flex-direction: row-reverse;
                      }
                    }
                  }
                  .task-header {
                    gap: 10px;
                  }
                }
              }
            }
          }
        }
        &.task-classic-ag-grid {
          width: 98%;
          > ui-button {
            transform: rotate(180deg);
          }
          > ag-grid-aurelia {
            .ag-row {
              height: 40px;
              &.ag-row-level-1 {
                width: 100%;
              }

              .ag-cell {
                height: 40px;
                .task-cell {
                  > div {
                    flex-direction: row;
                    justify-content: space-between;
                    > div:first-child {
                      display: flex;
                      align-items: center;
                    }
                    > .task-title {
                      justify-content: flex-start;
                    }
                    > .task-info {
                      flex-direction: column;
                      > :first-child {
                        flex-direction: row-reverse;
                      }
                    }
                  }
                  .task-header {
                    gap: 10px;
                  }
                }
              }
            }
          }
        }
        &.task-container-detail {
          display: none;
        }
      }
    }
    // .ag-paging-panel {
    //   display: none;
    // }
  }
}
.ag-center-cols-container {
  height: inherit !important;
}
