.theme-light {
  .ui-padding--xs.ui-padding--xs.ui-padding--xs,
  .ui-padding--lg.ui-padding--lg.ui-padding--lg {
    padding: 0;
  }
  ui-page {
    > .ui-page__ribbon-content {
      padding: 0 40px !important;
      > div {
        padding: 0;
      }
    }
    .ui-page__body {
      grid-area: body;
      > ui-content {
        padding: 0.5rem 0;
        > div {
          .ui-panel {
            padding: 0 40px;
          }
          > div {
            .ui-panel {
              padding: 0 40px;
            }
          }
        }
      }
    }
    ui-footer-page {
      grid-area: footer;
      width: 100%;
      color: white;
      text-align: center;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 30px;
      padding: 10px 20px;
    }
    .multiple-footer {
      padding: 0;
    }
  }
}
