import { HttpClient } from 'aurelia-fetch-client';
import { GridOptions, IGetRowsParams } from 'ag-grid-community';
import { AGGridColumnsWidth, CustomAgGridOptions, CustomLogger, EditingModeEnum, EnumerationTypeService, FieldType, ListViewModelBase, SelectAllHeaderComponent, ServiceBase, UIInternal } from 'digiwall-lib';
import { autoinject, bindable, BindingEngine, customElement, Disposable, TaskQueue } from "aurelia-framework";
import { Merlin } from "generated";
import { Router } from 'aurelia-router';
import * as Constants from '../../constants';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';


@autoinject
@customElement("add-request-supplier-offer-step-2")
export class AddRequestSupplierOfferStep2 extends ListViewModelBase<Merlin.Web.Model.ThirdParty> {
  currentfilter: { [key: string]: any; };
  currentRowData: any;
  initFilter: boolean;
  get ressourceName(): string {
    throw new Error('Method not implemented.');
  }
  @bindable
  public entity: Merlin.Web.Model.RequestSupplierOffer;

  private thirdPartyTypeService: EnumerationTypeService;
  private thirdPartyTagService: EnumerationTypeService;
  private activityCodeService: EnumerationTypeService;

  @bindable
  private selectedEntitiesWithoutFilter: Array<Merlin.Web.Model.ThirdParty> = [];
  @bindable
  private showRowSelected: boolean = false;
  @bindable
  private editingMode: EditingModeEnum = EditingModeEnum.Create;

  public gridOptions2: GridOptions;

  private isFistFilterByDefault: boolean = true;
  @bindable()
  private type: any;


  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private httpClient: HttpClient, private taskQueue: TaskQueue) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty));
    this.service.gridDataSource.expands = ['civility', 'companyType', 'spokenLanguage',
      'thirdPartyTypes.thirdPartyTypeEnum', 'thirdPartyTags.thirdPartyTagEnum', 'thirdPartyActivityCodes.activityCode',
      'subType', 'addresses.country', 'addresses.locality'];
    UIInternal.subscribe("end init filter thirdPartyActivityCodes.activityCode.denomination._translation", () => {
      if (this.isFistFilterByDefault) {
        this.taskQueue.queueTask(() => {
          this.filterByDefault(true);
          this.isFistFilterByDefault = false;
        })
      }
    })
  }

  async attached(): Promise<void> {
    if (this.editingMode == EditingModeEnum.Create) {
      this.service.gridDataSource.queryParameters = { thirdPartyTypeIds: [Constants.ThirdPartyType.Supplier, Constants.ThirdPartyType.SubContractor], supplierDeliveryId: Constants.SupplierDeliveryId.SubContractor };
    }
    else {
      this.service.gridDataSource.queryParameters = { thirdPartyTypeIds: [Constants.ThirdPartyType.Supplier, Constants.ThirdPartyType.SubContractor], supplierDeliveryId: Constants.SupplierDeliveryId.SubContractor, requestSupplierOfferId: this.entity.id };
    }
    this.isFistFilterByDefault = true;
    switch (this.entity.entityType.shortName) {
      case 'WorkQuotationVersionRequestSupplierOffer':
        this.type = DocumentTypeDiscriminator.WorkQuotationVersion;
        break;
      case 'BuyingRequestSupplierOffer':
        this.type = DocumentTypeDiscriminator.Buying;
        break;
    }

    this.initFilter = false;
    await super.attached();
    let superonModelUpdated = this.gridOptions.onModelUpdated;
    this.gridOptions.onModelUpdated = (event) => {
      superonModelUpdated(event);
      if (this.gridOptions?.api) {
        this.gridOptions.api.forEachNode(rowNode => {
          this.disposables.push(
            this.bindingEngine.propertyObserver(rowNode.data, "isSelected").subscribe((newV, oldV) => {
              this.matchSelectedEntities(this.gridOptions.api.isAnyFilterPresent());
            }));

          if (rowNode.data != null) {
            let index = this.selectedEntitiesWithoutFilter.findIndex(x => x.id == rowNode.data.id);
            if (index > -1) {
              rowNode.data.isSelected = true;
            }
            else {
              rowNode.data.isSelected = false;
            }
          }
        })
      }
    }

    let onGridReady = this.gridOptions.onGridReady
    this.gridOptions.onGridReady = (event) => {
      this.taskQueue.queueTask(() => {
        this.filterByDefault(true);
      })
      onGridReady(event);
    }
    this.selectedEntities.push(...this.selectedEntitiesWithoutFilter);
    this.initializeGridOptions2();
  }
  private initializeGridOptions2() {
    this.gridOptions2 = <GridOptions>{};

    this.gridOptions2.rowModelType = 'clientSide';
    this.gridOptions2.pagination = true;
    this.gridOptions2.paginationAutoPageSize = true;
    this.gridOptions2.rowHeight = 40;
    this.gridOptions2.headerHeight = 45;
    this.gridOptions2.floatingFiltersHeight = 45;
    this.gridOptions2.animateRows = true;

    this.gridOptions2.rowSelection = 'multiple';
    this.gridOptions2.suppressRowClickSelection = true;
    this.gridOptions2.enableBrowserTooltips = true;
    this.gridOptions2.multiSortKey = 'ctrl';
    this.gridOptions2.components = CustomAgGridOptions.Components;
    this.gridOptions2.columnTypes = (CustomAgGridOptions.ColumnTypeDefinitions(this.i18n, this.config.globalConfig.defaultLocale) as any);
    this.gridOptions2.columnDefs = this.getDataGridColumns();

    this.gridOptions2.columnDefs.forEach(columnDef => {
      columnDef.headerTooltip = columnDef.headerName;
    });
    this.gridOptions2.onSelectionChanged = (event?) => { this.onSelectionChanged(event); }
    this.gridOptions2.onGridReady = (event?) => {
      this.gridOptions2.api.setRowData(this.selectedEntitiesWithoutFilter);
    }
    this.gridOptions2.getLocaleText = (input) => this.i18n.tr("grid." + input.key, { ns: "common" });
    if (this.config.agGridConfig.suppressMenu) {
      this.gridOptions2.defaultColDef = {
        suppressMenu: true
      };
    }
    else {
      this.gridOptions2.defaultColDef = {
        suppressMenu: false,
        menuTabs: ['filterMenuTab']
      };
    }

    this.gridOptions2.enableCellTextSelection = true;
  }

  private matchSelectedEntities(isFiltering: boolean) {
    if (isFiltering) {
      this.selectedEntities.forEach(selected => {
        if (this.selectedEntitiesWithoutFilter.findIndex(x => x.id == selected.id) < 0) {
          this.selectedEntitiesWithoutFilter.push(selected);
        }
      });
      this.gridOptions.api.forEachNode(rowNode => {
        if (rowNode.data != null && !rowNode.data.isSelected) {
          let index = this.selectedEntitiesWithoutFilter.findIndex(x => x.id == rowNode.data.id)
          if (index > -1) {
            this.selectedEntitiesWithoutFilter.splice(index, 1);
          }
        }
      })
    } else {
      this.selectedEntitiesWithoutFilter.splice(0);
      this.selectedEntities.forEach(x => {
        this.selectedEntitiesWithoutFilter.push(x);
      });
    }
  }

  async onGridReady(event) {
    await super.onGridReady(event);
    //if (!this.initFilter) {
    let instance = this.gridOptions.api.getFilterInstance("thirdPartyActivityCodes.activityCode.denomination._translation");
    instance.setModel(instance.getModel());

    //}
  }

  private async filterByDefault(force: boolean = false) {
    //if (force || (!this.isFistFilterByDefault && !this.initFilter)) {
    this.initFilter = true;

    let requestUri = Constants.Application.RequestSupplierController.GetActivityCodes.format(this.type.toString(), this.entity.id.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      let result: Array<number> = await query.json();
      this.currentSetModelRetries = 0;
      this.applyFilter(result);
    }
    //}
  }
  private currentSetModelRetries: number = 0;
  private applyFilter(result) {
    let instance = this.gridOptions.api.getFilterInstance("thirdPartyActivityCodes.activityCode.denomination._translation");
    this.currentSetModelRetries++;
    if ((instance as any).filterValues.length > 0) {
      this.currentSetModelRetries = 0;
      if ((instance as any).filterValues.length > 0) {
        result.forEach(id => {
          let value = (instance as any).filterValues.find(x => x.id == id);
          if (value != null) {
            value.selected = true;
          }
        })
      }
    } else {
      if (this.currentSetModelRetries <= 50) {
        setTimeout(() => {
          this.applyFilter(result);
        }, 200);
      }
    }
  }
  private createServerSideDS() {
    const ds: any = {
      getRows: (params: any) => {
        params.success({
          rowData: this.selectedEntitiesWithoutFilter,
          rowCount: this.selectedEntitiesWithoutFilter.length
        });
      }
    };
    return ds;
  }

  private observableShowSelected: Array<Disposable> = [];
  private observableNotShowSelected: Disposable;

  showRowSelectedChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      if (newValue) {
        let coldDef = this.gridOptions.api.getColumnDefs();
        (this.gridOptions2 as any).columnDefs = coldDef;
        this.observableNotShowSelected?.dispose();
        this.selectedEntitiesWithoutFilter.forEach(selected => {
          (selected as any).isSelected = true;
          this.observableShowSelected.push(
            this.bindingEngine.propertyObserver(selected, 'isSelected').subscribe((newValue, oldValue) => {
              if (this.showRowSelected && newValue != oldValue && !newValue) {
                let index = this.selectedEntitiesWithoutFilter.findIndex(x => x == selected);
                this.selectedEntitiesWithoutFilter.splice(index, 1);
                this.gridOptions2.api.setRowData(this.selectedEntitiesWithoutFilter);
              }
            })
          )
        });
      } else {
        let coldDef = this.gridOptions2?.api?.getColumnDefs()
        if (coldDef != null) {
          (this.gridOptions as any).columnDefs = coldDef;
        }

        let i = this.observableShowSelected?.length;
        while (i > 0) {
          let temp = this.observableShowSelected[i - 1];
          temp.dispose();
          i--;
        }

      }
    }
  }
  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    this.thirdPartyTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ThirdPartyType);
    this.thirdPartyTagService = new EnumerationTypeService(Constants.EnumerationTypes.ThirdPartyTag);
    this.activityCodeService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode)

    let defs: ColDef[] = [
      new GridHelperMerlin().selectedColDef(this),
      {
        headerName: this.i18n.tr("thirdparty.isCompany"),
        field: "isCompany",
        type: FieldType.Boolean,
        hide: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.companyTypeId"),
        field: "companyType.denomination._translation",
        type: FieldType.Enumeration,
        hide: true,
        filterParams: {
          category: Constants.EnumerationTypes.CompanyType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.CompanyType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.vatNumber"),
        field: "vatNumber",
        type: FieldType.String,
        hide: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.lastNameCompanyName"),
        field: "lastNameCompanyName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.firstName"),
        field: "firstName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.civilityId"),
        field: "civility.denomination._translation",
        type: FieldType.Enumeration,
        hide: true,
        filterParams: {
          category: Constants.EnumerationTypes.Civility,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.Civility,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.spokenLanguageId"),
        field: "spokenLanguage.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.Language,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.Language,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.phoneNumber"),
        field: "phoneNumber",
        type: FieldType.String,
        hide: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.mobilePhoneNumber"),
        field: "mobilePhoneNumber",
        type: FieldType.String,
        hide: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.emailAddress"),
        field: "emailAddress",
        hide: true,
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.website"),
        field: "website",
        hide: true,
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.thirdPartyTypeId"),
        field: "thirdPartyTypes.thirdPartyTypeEnum.denomination._translation",
        type: FieldType.ManyToMany,
        hide: true,
        filterParams: {
          service: this.thirdPartyTypeService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.thirdPartyTypeService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.thirdPartyTagId"),
        field: "thirdPartyTags.thirdPartyTagEnum.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.thirdPartyTagService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.thirdPartyTagService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.defaultMarginCoefficient"),
        field: "defaultMarginCoefficient",
        hide: true,
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.cotations"),
        field: "cotations",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.thirdPartyActivityCodes"),
        field: "thirdPartyActivityCodes.activityCode.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.activityCodeService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.activityCodeService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.partnerTypeId"),
        field: "subType.denomination._translation",
        type: FieldType.Enumeration,
        hide: true,
        filterParams: {
          category: Constants.EnumerationTypes.PartnerType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.PartnerType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdparty.bankAccount"),
        field: "bankAccount",
        hide: true,
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("address.streetMain"),
        field: "streetMain",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("address.postalCode"),
        field: "zipCode",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("address.localityId"),
        field: "locality",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("address.countryId"),
        field: "country",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }

  public async detached() {
    await super.detached();
    this.observableNotShowSelected?.dispose();
    this.observableShowSelected.forEach((disposable: Disposable) => {
      disposable.dispose();
    });

  }
}
