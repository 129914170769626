import { autoinject } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { FloatingBoxViewModel } from "digiwall-lib";
import { ProjectResumeCompany } from "../project-resume";

@autoinject
export class FloatingCompany extends FloatingBoxViewModel {

  constructor(private projectResumeCompany: ProjectResumeCompany, private navigateToEditProject: () => void, private router: Router) {
    super('./floating-company.html', 'sm');
  }

  async activate(config: any): Promise<void> {
    await super.activate(config);
  }

  public async close() {

    await this.dialogController.close(false);
  }
}
