import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from "aurelia-framework";
import * as Constants from '../constants';

@autoinject
export class ThirdPartyApiService {

  constructor(private HttpClient: HttpClient) {

  }

  async inviteThirdParty(thirdPartyId: number): Promise<boolean> {
    let result = await this.HttpClient.post(Constants.Application.ThirdPartyController.InviteToLancelot.format(thirdPartyId.toString()));
    return result.ok;
  }

  async cancelInvite(thirdPartyId: number, invitationId: number): Promise<boolean> {
    let result = await this.HttpClient.delete(Constants.Application.ThirdPartyController.CancelInviteToLancelot.format(thirdPartyId.toString(), invitationId.toString()));
    return result.ok;
  }
}
