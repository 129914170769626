// $size: 4;
// $picture-height: 300px;
// #gallery {
//   display: grid;
//   min-height: 50px;
//   grid-template: repeat(1, 1fr) / repeat($size * 1.5, 1fr);
//   grid-gap: 0.5em;
//   @media (max-width: 800px) {
//     display: flex;
//     align-items: flex-start;
//     flex-wrap: wrap;
//     & > div {
//       width: 48%;
//       margin: 1%;
//       @media (max-width: 350px) {
//         width: 98%;
//       }
//     }
//   }
//   & > h4 {
//     grid-column: span ($size * 1.5);
//   }
//   & > div {
//     //Grid Structure
//     grid-column: span 1;
//     grid-row: span 1;
//     max-height: $picture-height;
//     overflow: hidden;
//     position: relative;
//     box-shadow: 0 2px 8px 0 rgba(#000, 0.2), 0 3px 20px 0 rgba(#000, 0.19);
//     & > picture-element {
//       max-height: $picture-height;
//       & img {
//         max-width: 100%;
//         min-height: 50px;
//         max-height: $picture-height;
//         object-fit: cover;
//         transition: all ease 1s;
//       }
//       & > a {
//         width: 40px;
//         height: 40px;
//       }
//       & > a.delete-btn {
//         opacity: 0;
//         position: absolute;
//         right: 2%;
//         bottom: 2%;
//       }
//       & > a.rotate-left-btn {
//         opacity: 0;
//         position: absolute;
//         left: 2%;
//         top: 2%;
//       }
//       & > a.rotate-right-btn {
//         opacity: 0;
//         position: absolute;
//         right: 2%;
//         top: 2%;
//       }
//     }
//     &:hover {
//       & > picture-element > a {
//         opacity: 1;
//         transition-property: opacity;
//         transition-duration: 0.2s;
//       }
//     }
//   }
//   div,
//   h4 {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     text-decoration: none;
//   }
// }

// #gallery-file {
//   h4 {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     text-decoration: none;
//   }
// }

// .add-gallery {
//   color: #4e4e4e !important;
//   font-weight: bold;
// }

// .add-gallery:hover {
//   color: #66bb6a !important;
// }

// .icon-delete-button {
//   line-height: 35px !important;
// }

.user-picture {
  > img {
    max-width: 100px;
    max-height: 100px;
    width: 100px;
    height: 100px;
  }
}
