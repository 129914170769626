import { ICellRendererParams } from "ag-grid-community";
import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { PriceOfferLinesGrid } from "price-offer-lines/price-offer-lines-grid";
import { IDataLineApiService, MeteringTotalPrices } from "services/i-data-line-api-service";
import * as Constants from '../../../constants';
import { GlobalLoaderService, IMenuItems } from "digiwall-lib";
@autoinject
export class MenuDeleteLine {
  constructor() { }
  public getMenu(params: ICellRendererParams, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): IMenuItems {
    let i18n = Container.instance.get(I18N);
    let menuItem = {
      id: Constants.CommonMeterginDataLineMenuItems.DELETE,
      label: i18n.tr("versionmetering.deleteLine"),
      icon: "digi-trash",
      disabled: () => params.node.data.originalMeasurementXlsLineNumber != null,
      handler: async () => {
        await this.deleteLine(params.node.data.id, dataLineApiService, priceOfferLinesGrid, versionId, getMeteringTotalPrices, _this)
      }
    }
    return menuItem;
  }
  private async deleteLine(id: number, dataLineApiService: IDataLineApiService, priceOfferLinesGrid: PriceOfferLinesGrid, versionId: string, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any) {
    let globalLoaderService = Container.instance.get(GlobalLoaderService);
    globalLoaderService.allow();
    priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();

    let idsToDelete = priceOfferLinesGrid.gridOptions.api.getSelectedNodes().map(x => x.data.id);
    if (idsToDelete.length == 0 || idsToDelete.findIndex(x => x == id) == -1)
      idsToDelete.push(id);

    let ids = await dataLineApiService.delete(parseInt(versionId), idsToDelete);
    priceOfferLinesGrid.gridOptions.api.hideOverlay();
    let refreshAllTable = false;
    for (const id of idsToDelete) {
      let node = priceOfferLinesGrid.gridOptions.api.getRowNode(id.toString());
      refreshAllTable = node?.data?.parentId == null
      if (refreshAllTable) {
        break;
      }
    }

    priceOfferLinesGrid.refreshServerSideRows(ids, refreshAllTable);
    priceOfferLinesGrid.refreshVisibleNodes(ids.filter(id => !idsToDelete.includes(id)));
    await getMeteringTotalPrices(versionId, dataLineApiService, _this);
  }
}
