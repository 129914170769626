import { autoinject, bindingMode } from "aurelia-framework";
import { bindable } from "aurelia-templating";

@autoinject
export class QuillDialog {
  @bindable({ bindingMode: bindingMode.twoWay })
  public bodyText: string;

  activate(params) {
    if (params.bodyText != null)
      this.bodyText = params.bodyText;
  }
}
