import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, FieldType } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class MarketTypeList extends ListViewModelBase<Merlin.Web.Model.MarketType> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.MarketType>(Constants.EntityTypeNames.MarketType));
  }

  public ressourceName: string = Constants.EntityTypeNames.MarketType;
  public buttonLabel: string = this.i18n.tr("markettype.addMarketTypeAlias");
  public pathDetail: string;


  public getDetailsUrl(self: any, entity: Merlin.Web.Model.MarketType) {
    if (entity?.id) {
      return this.pathDetail + entity.id;
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = this.router.baseUrl + '/market-types/';
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("markettype.typeLang1"),
        field: "type.lang1",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("markettype.typeLang2"),
        field: "type.lang2",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("markettype.typeLang3"),
        field: "type.lang3",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      }
    ];
    return defs;
  }
} 
