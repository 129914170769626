import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject, Container, TaskQueue } from 'aurelia-framework';
import { ActionDialogBoxInputParameters, CustomLogger, DialogBoxViewModel, FieldType, IMenuGroup, IMenuItems, ListViewModelBase, ServiceBase, EnumerationType, EnumerationTypeService, KeyboardShortcut, Box, UIInternal, ValueSelected } from 'digiwall-lib';
import { Merlin } from 'generated';
import * as Constants from '../../../constants';
import { ColDef, RowNode } from 'ag-grid-community';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import moment = require('moment');
import { ProjectApiService } from 'services/project-api-service';
import { ContractSupplierOfferApiService } from 'services/contract-supplier-offer-api-service';
import { ThirdPartyContractMenuItems } from './third-party-contract-menu-item';
import { AmendmentCreation } from './amendment/amendment-creation';
import { debounce } from 'debounce';
import { FilterContract, UserSettingService } from 'module-tasks/service/user-setting-service';
import { ThirdPartyContractDetail } from './third-party-contract-detail';


@autoinject
export class ThirdPartyContractList extends ListViewModelBase<Merlin.Web.Model.BuyingContract> {
  public pathDetail: string;
  public ressourceName: string = Constants.EntityTypeNames.ThirdPartyContract;
  public projectId: number;
  public buttonLabel = this.i18n.tr("thirdparty.addThirdParty");
  private thirdPartyContractStatusService: EnumerationTypeService;
  public thirdPartyContractStatus: Array<EnumerationType>;
  public canCreateContract = true;
  private nbDecimalForPriceDisplay: number
  private calculatedHeight: string;
  public filterContract: number;

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private projectApiService: ProjectApiService, private contractSupplierOfferVersionApiService: ContractSupplierOfferApiService, public taskQueue: TaskQueue, public element: Element, public userSettingService: UserSettingService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.BuyingContract>(Constants.EntityTypeNames.BuyingContract));
    this.thirdPartyContractStatusService = new EnumerationTypeService(Constants.EnumerationTypes.ThirdPartyContractStatus);
  }

  public async activate(params: any) {
    this.projectId = params.projectId;
    this.service.gridDataSource.queryParameters = { projectId: this.projectId };
    this.thirdPartyContractStatus = await this.thirdPartyContractStatusService.getAll();

    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
  }

  public getDetailsUrl(self: any, entity: Merlin.Web.Model.BuyingContract): string {
    if (entity?.thirdPartyContractId) {
      if (entity.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract)
        return (this.pathDetail + entity.thirdPartyContractId);
      else
        return (this.pathDetail + entity.parentContractId + "?amendmentId=" + entity.thirdPartyContractId);
    }
    return this.pathDetail + "-100?supplierOfferId=" + entity.supplierOfferId;
  }

  async attached() {
    await super.attached()
    window.addEventListener('resize', debounce(() => {
      this.calculateBodyHeight();
    }));
  }

  public initializeGridOptions() {
    this.pathDetail = 'contract/';
    super.initializeGridOptions(false, false);
    this.gridOptions.rowClassRules = {
      'can-create-amendment': (params) => {
        return !(params.data == null || params.data?.thirdPartyContractId == null || !params.data?.canCreateAmendment || params.data?.statusId != Constants.ThirdPartyContractStatusId.Validated)
      },
    }
    UIInternal.subscribe("Custom-Paging-Attached", () => this.calculateBodyHeight());
    setTimeout(() => {
      this.calculateBodyHeight();
    }, 1000)
  }

  public getDataGridColumns() {
    let gh = new GridHelperMerlin();
    gh.getMenuItems = (params) => this.getMenuItems(params, this) as any;

    let defs: ColDef[] = [
      ...gh.contextMenuColdDef(this),
      // {
      //   hide: true,
      //   headerName: this.i18n.tr("buyingcontract.id"),
      //   field: "id",
      //   type: FieldType.String,
      //   showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      // },
      {
        headerName: this.i18n.tr("buyingcontract.contractName"),
        field: "contractName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("buyingcontract.thirdPartyContractNumber"),
        field: "thirdPartyContractNumber",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("buyingcontract.supplierName"),
        field: "supplierName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("buyingcontract.statusId"),
        field: "status.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ThirdPartyContractStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ThirdPartyContractStatus,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr('buyingcontract.signedDate'),
        field: "signedDate",
        type: FieldType.Date,
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        comparator(valueA, valueB, nodeA: RowNode, nodeB: RowNode, isDescending) {
          if (nodeA?.data == null || nodeB?.data == null) return 0;
          if (nodeA.data.deadlineDate == null) return -1;
          if (nodeB.data.deadlineDate == null) return 1;
          return isDescending ?
            (moment(nodeA.data.deadlineDate).isBefore(nodeB.data.deadlineDate) ? 1 : -1) :
            (moment(nodeA.data.deadlineDate).isAfter(nodeB.data.deadlineDate) ? 1 : -1)
        },
      },
      {
        headerName: this.i18n.tr("buyingcontract.totalPrice"),
        field: "totalPrice",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: 0, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("buyingcontract.typeId"),
        field: "contractType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ThirdPartyContractType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ThirdPartyContractType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("buyingcontract.parentNumContract"),
        field: "parentNumContract",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("thirdpartycontract.supplierType"),
        field: "supplierType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ThirdPartyType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ThirdPartyType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...gh.baseEntityColDef(this),
    ];
    return defs;
  }

  private getMenuItems(params, currentThis): Array<IMenuGroup> {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            disabled: () => params.data?.thirdPartyContractId == null,
            label: this.i18n.tr("menu.open"),
            icon: "digi-arrow-expand",
            handler: () => {
              currentThis.router.navigate(this.getDetailsUrl(null, params.data))
            },
          },
          {
            disabled: () => params.data?.thirdPartyContractId != null,
            label: this.i18n.tr("thirdpartycontract.create"),
            icon: "digi-file-paper-2-line",
            handler: async () => {
              if (!currentThis.canCreateContract) return;

              let thirdPartyTypeId = await ThirdPartyContractDetail.getSupplierTypeId(params.data.supplierOfferId);

              let buttonYes: ActionDialogBoxInputParameters =
              {
                label: this.i18n.tr("general.ok", { ns: "common" }),
                title: this.i18n.tr("general.ok", { ns: "common" }),
                theme: 'primary',
                type: 'solid',
                disabled: false,
                fn: (thisBox: DialogBoxViewModel) => {
                  thisBox.controller.ok(true);
                },
                keyboardShortcut: KeyboardShortcut.Enter
              };
              let buttonNo: ActionDialogBoxInputParameters =
              {
                label: this.i18n.tr("general.cancel", { ns: "common" }),
                title: this.i18n.tr("general.cancel", { ns: "common" }),
                theme: 'dark',
                type: 'ghost',
                disabled: false,
                fn: (thisBox: DialogBoxViewModel) => {
                  thisBox.cancel();
                },
                keyboardShortcut: KeyboardShortcut.Escape
              };

              this.box.showQuestion(this.i18n.tr('thirdpartycontract.askCreate'), this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes]).whenClosed(async (result) => {
                if (!result.wasCancelled) {
                  let id = await this.contractSupplierOfferVersionApiService.save(this.projectId, params.data.supplierOfferId, thirdPartyTypeId);
                  if (id != null) {
                    this.router.navigateToRoute("third-party-contract-detail", { contractId: id });
                  }
                }
              });
            },
          },
          {
            label: this.i18n.tr("thirdpartycontract.status"),
            icon: "digi-price-tag-3-line",
            disabled: () => {
              return params.data == null || params.data?.thirdPartyContractId == null;
            },
            items: ThirdPartyContractMenuItems.getStatusMenuItems(params.data, this.thirdPartyContractStatus, this.gridOptions, null)
          }
        ]
      },
      AmendmentCreation.getCreateAmendmentMenuItem(this.projectId, null, params.data?.thirdPartyContractId, params.data == null || params.data?.thirdPartyContractId == null || !params.data?.canCreateAmendment || params.data?.statusId != Constants.ThirdPartyContractStatusId.Validated,
        () => {
          this.gridOptions.api.refreshInfiniteCache();
        }
      ),
    ];
  }

  async filterContractSelected(number: FilterContract) {
    if (this.filterContract == number) number = 0
    this.filterContract = number;
    switch (this.filterContract) {
      case FilterContract.signedContract:
        this.service.gridDataSource.queryParameters = { projectId: this.projectId, contractType: FilterContract.signedContract };
        break;
      case FilterContract.proposedContract:
        this.service.gridDataSource.queryParameters = { projectId: this.projectId, contractType: FilterContract.proposedContract };
        break;
      default:
        this.service.gridDataSource.queryParameters = { projectId: this.projectId };
        break;
    }
    this.gridOptions.api.refreshInfiniteCache()
  }

  public calculateBodyHeight(tries: number = 0) {
    // If heightStyle is provided through bind, we must keep it
    let availableHeight = this.element.parentElement.clientHeight;

    // Ignore division by 0
    if (availableHeight <= 0) {
      this.calculatedHeight = 'height: 500px';
      return;
    }

    // Remove padding top/bottom
    let parentStyle = getComputedStyle(this.element.parentElement);
    availableHeight -= this.getPadding(parentStyle, 'top') + this.getPadding(parentStyle, 'bottom')

    let headerHeight = this.element.getElementsByTagName('ag-grid-aurelia')[0].offsetTop;
    let footerHeight = this.element.getElementsByClassName("grid-footer-bar")[0].offsetHeight;

    if (footerHeight <= 0 && tries < 5) {
      // If height is 0, footer might not be fully attached
      // Can only be the case if all of the following are true:
      // 1. There is only a single page
      // 2. showSelectedElemCount is False
      // 3. showRowsCount is False
      // 4. left-paging / right-paging slots are empty OR not rendered (custom content)
      this.taskQueue.queueTask(() => this.calculateBodyHeight(++tries));
    } else {
      this.calculatedHeight = `height: ${availableHeight - headerHeight - footerHeight - 160}px`;
    }
  }

  private getPadding(styles: CSSStyleDeclaration, side: 'top' | 'bottom'): number {
    return parseFloat(styles.getPropertyValue(`padding-${side}`).replace(/[^0-9.]/g, ''));
  }
}
