@import "../../../css/scss/variables";

$ag-cell-comment-box-font-size: 14px !default;
$ag-cell-comment-box-font-color: $gray-darker !default;
$ag-cell-comment-box-border-radius: 12px !default;
$ag-cell-comment-box-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15) !default;
$ag-cell-comment-box-background: $black-0 !default;
$ag-cell-comment-box-width: 400px !default;

$ag-cell-comment-box-badge-size: 30px !default;
$ag-cell-comment-box-badge-color: $gray-mid !default;
$ag-cell-comment-box-badge-font-size: 12px !default;

$ag-cell-comment-box-header-border-bottom-color: $gray-very-light !default;
$ag-cell-comment-box-header-date-color: $gray-mid !default;
$ag-cell-comment-box-header-date-font-size: 12px !default;
$ag-cell-comment-box-header-fullname-color: $black-100 !default;
$ag-cell-comment-box-footer-background: $background-footer !default;

.ui-viewport__floating-container > .ui-drop > .ui-drop__body ag-cell-comment-box {
  margin: -5px -10px;
}

ag-cell-comment-box {
  font-size: $ag-cell-comment-box-font-size;
  color: $ag-cell-comment-box-font-color;
  z-index: 200;
  border-radius: $ag-cell-comment-box-border-radius;
  position: relative;
  display: block;
  width: $ag-cell-comment-box-width;
  background: $ag-cell-comment-box-background;
  max-height: 500px;

  .ag-cell-comment-box-overlay {
    border-radius: $ag-cell-comment-box-border-radius;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      color: blue;
    }
  }

  header {
    margin: 20px 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid $ag-cell-comment-box-header-border-bottom-color;
    display: flex;
    align-items: center;

    aside {
      height: auto;
      width: $ag-cell-comment-box-badge-size;
      margin-right: 10px;
      display: flex;
      align-items: center;

      .user-avatar {
        display: flex;
        align-items: center;

        .user-img {
          width: $ag-cell-comment-box-badge-size;
          height: $ag-cell-comment-box-badge-size;
          border-radius: $ag-cell-comment-box-badge-size;
        }

        avatar img {
          width: $ag-cell-comment-box-badge-size;
          height: $ag-cell-comment-box-badge-size;
          border-radius: $ag-cell-comment-box-badge-size;
          opacity: 0.18;
        }
      }
    }

    .user-info {
      display: flex;
      flex-direction: column;

      .user-fullname {
        font-weight: 400;
        color: $ag-cell-comment-box-header-fullname-color;
      }

      .comment-date {
        color: $ag-cell-comment-box-header-date-color;
        font-size: $ag-cell-comment-box-header-date-font-size;
      }
    }

    ui-button {
      margin-left: auto !important;
      border-radius: $radius;
    }
  }

  header.aurelia-hide ~ .ag-cell-comment-box-text {
    margin-top: 20px;
  }

  ui-textarea,
  .ag-cell-comment-box-text {
    margin: 0px 20px 20px;
    max-height: 150px;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }
  .ag-cell-comment-box-text {
    overflow: auto;

    p {
      white-space: break-spaces;
      margin: 0;
    }
  }

  footer {
    padding: 5px 10px;
    background-color: $ag-cell-comment-box-footer-background;
    display: flex;
    justify-content: space-between;
    border-radius: 0 0 $ag-cell-comment-box-border-radius $ag-cell-comment-box-border-radius;
  }
}
