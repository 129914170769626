import { DialogService } from 'aurelia-dialog';
import { Box, CustomLogger, Extensions, FilePreviewer, PictureHelper } from 'digiwall-lib';
import { bindable, autoinject, customElement, bindingMode, BindingEngine, processContent, children, Disposable, observable, Container } from 'aurelia-framework';
import { EnumerationTypeService, IFile } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';
import { Merlin } from "../../generated";
import { DocumentService } from 'documents/document-service';
import { EditDocument } from 'documents/edit-document';
import { HttpClient } from 'aurelia-fetch-client';

@autoinject
@customElement('custom-ui-files')
export class CustomUIFiles {
  private fileTypeService: EnumerationTypeService;

  @bindable({ bindingMode: bindingMode.twoWay }) files: IFile[];
  @bindable selectedFiles: Array<IFile>;
  @bindable({ bindingMode: bindingMode.oneTime }) uploadBtn: HTMLElement;
  @bindable({ bindingMode: bindingMode.oneTime }) folderName: string;

  @bindable({ bindingMode: bindingMode.oneTime }) fileAddedFn: () => {};
  @bindable({ bindingMode: bindingMode.oneTime }) fileUploadedFn: () => {};
  @bindable({ bindingMode: bindingMode.oneTime }) deleteFileFn: (file) => {};
  @bindable({ bindingMode: bindingMode.oneTime }) refreshData: () => {};

  @observable
  private selectAll: boolean = false;
  private disposables: Disposable[] = [];

  constructor(private pictureHelper: PictureHelper, private dialogService: DialogService, private bindingEngine: BindingEngine, private i18n: I18N, private box: Box) {

  }

  bind() {
    if (this.selectedFiles == null) {
      this.selectedFiles = [];
    }

    this.disposables.forEach(d => d.dispose());
    this.disposables.splice(0);

    this.files.forEach(file => {
      let observer = this.bindingEngine.propertyObserver(file, 'isSelected');
      let disposable = observer.subscribe((newVal, oldVal) => {
        if (newVal === true) {
          this.selectedFiles.push(file);
        }
        else {
          let index = this.selectedFiles.findIndex(x => x == file);
          this.selectedFiles.splice(index, 1);
          // this.selectedFiles.remove(file);
        }
      });
      this.disposables.push(disposable);

    });
  }

  selectAllChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      this.files?.forEach((file: any) => { file.isSelected = newValue });
    }
  }

  getFileType(file) {
    return this.pictureHelper.getFileType(file.url);
  }

  previewFile(file) {
    let fileExtension: string = this.pictureHelper.getFileExtension(file.url);
    if (Extensions.file.includes(fileExtension)) {
      this.dialogService.open({
        viewModel: FilePreviewer,
        model: { fileExtension: fileExtension, fileUrl: file.url },
        lock: false,
        position(dialogContainer, dialogOverlay) {
          dialogContainer.classList.add("file-previewer-container");
          dialogOverlay.classList.add("file-previewer-container");
        }
      });
    }
    else {
      this.pictureHelper.downloadFile(file);
    }
  }

  public editDocument(entity: Merlin.Web.Model.Document) {
    if (entity?.id) {
      let documentService = new DocumentService(
        Container.instance.get(HttpClient),
        Container.instance.get(I18N),
        Container.instance.get(Box),
        Container.instance.get(CustomLogger),
      )
      this.box.showEditDialog(EditDocument, entity.id, this.i18n.tr('document.editDocument'),
        {
          canSave: false,
          size: "md",
          model: { id: entity.id, service: documentService },
          actions: [
            EditDialogAction.GetSaveAction(this.i18n)
          ],
          overlayClass: "z-index-level-3",
          containerClass: "z-index-level-3"
        }).whenClosed(async result => {
          if (typeof this.refreshData == 'function') {
            this.refreshData();
          }
        });
    }
    return false;
  }

  download(file) {
    this.pictureHelper.downloadFile(file);

  }

  async delete(file) {
    await this.deleteFileFn(file);
    this.files.splice(this.files.findIndex(x => x.id == file.id), 1);
  }


  setMenuItems(file) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.open"),
            icon: "digi-arrow-expand",
            handler: () => {
              // this.previewFile(file);
              this.editDocument(file);
            },
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.download"),
            icon: "digi-download-line",
            handler: () => {
              this.download(file);
            },
          }
        ]
      },
      {
        group: "3",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-delete-bin-6-line",
            handler: async () => {
              await this.delete(file);
            },
          }
        ]
      }
    ];
  }
}
