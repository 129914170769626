/*#region Main layout*/

/* Rule only for Chrome 29+ */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  md-sidenav .input-field {
    margin-top: 0;
  }
}
/*#region Global Search */
div.sidenav .input-field {
  display: flex;
  margin-bottom: 0;
}
div.sidenav .input-field input[type="search"] {
  display: block;
  line-height: inherit;
  padding-left: 4rem;
  width: calc(100% - 4rem);
  align-self: flex-end;
}
div.sidenav .input-field input[type="search"],
div.sidenav .input-field input[type="search"]:focus {
  border-bottom: 1px solid #ffeb91;
}

div.sidenav .input-field input[type="search"]:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

div.sidenav .input-field input[type="search"]:focus + label ~ i.material-icons {
  color: white;
  font-size: 1.3em;
}

div.sidenav .input-field input[type="search"] + label {
  top: 0.8rem;
}

div.sidenav .input-field input[type="search"] + label ~ i.material-icons {
  font-size: 1.3em;
}

/*#endregion*/

div.sidenav.fixed li.logo:hover,
div.sidenav.fixed li.logo #logo-container:hover {
  background-color: transparent;
}

input:not([type]):disabled,
input:not([type])[readonly="readonly"],
input[type="text"]:disabled,
input[type="text"][readonly="readonly"],
input[type="password"]:disabled,
input[type="password"][readonly="readonly"],
input[type="email"]:disabled,
input[type="email"][readonly="readonly"],
input[type="url"]:disabled,
input[type="url"][readonly="readonly"],
input[type="time"]:disabled,
input[type="time"][readonly="readonly"],
input[type="date"]:disabled,
input[type="date"][readonly="readonly"],
input[type="datetime"]:disabled,
input[type="datetime"][readonly="readonly"],
input[type="datetime-local"]:disabled,
input[type="datetime-local"][readonly="readonly"],
input[type="tel"]:disabled,
input[type="tel"][readonly="readonly"],
input[type="number"]:disabled,
input[type="number"][readonly="readonly"],
input[type="search"]:disabled,
input[type="search"][readonly="readonly"] {
  color: rgba(0, 0, 0, 0.5);
}

/* Pour aligner les boutons sur le date-picker */
.picker__close,
.picker__today {
  padding-left: 0;
  padding-right: 0;
}

.bold-text {
  font-weight: bold;
}

table#tableDocuments td:first-child {
  width: 100px !important;
}

table#tableDocuments td:last-child {
  width: 200px !important;
}

.rotatedCol {
  padding: 0.625rem 1.825rem 0.625rem 2.5rem !important;
  border: none;
  position: relative;
}

.rotatedCol:before {
  position: absolute;
  content: attr(data-text);
  text-transform: uppercase;
  letter-spacing: 2px;
  left: 1.5rem;
  top: 15rem;
  color: gray;
  display: block;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1rem;
  height: 1rem;
  text-align: right;
  white-space: nowrap;
  direction: ltr;
  width: 12.5rem;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

/* Dans une boite de dialogue: masquer les éléments du ribbon
   qui sont destinés uniquement à être affichés dans le header */
ux-dialog div[slot="ribbon-content"] {
  display: none;
}

ul.pagination {
  text-align: center;
}

.collapsible-accordion .collapsible-header span {
  font-weight: bold;
  flex-grow: 1;
}

.collapsible-accordion .collapsible-body ul li a {
  font-weight: normal;
}

.flat-link-underlined,
.flat-link-not-underlined {
  cursor: pointer;
}

.flat-link-underlined:hover {
  text-decoration: underline;
}

.flat-link-not-underlined:hover {
  text-decoration: none;
}

.v-align-middle {
  vertical-align: middle;
}

div.collection {
  border: none !important;
}

md-collection-item.collection-item {
  border-bottom: none !important;
}

textarea.materialize-textarea {
  height: 6.4rem;
}

hr {
  border: 2px #f1f1f1 dashed;
  margin-bottom: 20px;
}

.toast-message {
  word-break: normal;
}
.toast {
  top: initial;
}
@media only screen and (min-width: 993px) {
  #toast-container.toast-bottom-right {
    top: initial;
    right: 12px;
    bottom: 40px;
  }
}

.align-center {
  text-align: center;
}

.strike-out {
  position: relative;
}

.strike-out::after {
  content: "";
  border-bottom: 2px solid;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
}

.input-button + md-input .input-field {
  max-width: calc(100% - 3rem);
}
.input-button {
  float: right;
  margin-top: 25px;
}

/*#endregion*/

/*#region Drag'n Drop*/

.highlightRow {
  background-color: #e3e3e3 !important;
  color: #c5c5c5 !important;
}

.overlay {
  display: table;
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  background-color: rgb(89, 204, 225);
  opacity: 0;
  height: 70px;
  z-index: 2001;
  color: whitesmoke;
  transition: all 0.4s ease;
}
.overlay.hover {
  opacity: 0.9;
  top: 0;
}

.dropzone {
  -ms-border-radius: 5px;
  line-height: 34px;
}

.dropzone.hover {
  color: black;
  background-color: #59cce1 !important;
  background-color: rgba(89, 204, 225, 0.4) !important;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -ms-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.dropzone.hover.in {
  background-color: #59cce1 !important;
  color: black;
  background-color: rgba(89, 204, 225, 1) !important;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -ms-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.drop-buttons {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
/*#endregion*/

/*#region Alarms */

.box {
  border-top: 10px solid white;
}

.box > .heading {
  height: auto;
  padding: 15px;
  color: white;
  border-style: solid;
  border-width: 0.5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.box > .heading.blue {
  border-color: #337ab7;
}

.box > .heading.yellow {
  border-color: #ecd40d;
}

.box > .heading.red {
  border-color: #c50606;
}

.box > .heading .right-side {
  float: right;
}

.box > .heading h3,
.box > .heading h6 {
  line-height: 40%;
  color: white;
  text-align: right;
}

.box > .content {
  padding: 10px;
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-style: none;
  box-shadow: inset 0px 6px 5px -4px rgba(0, 0, 0, 0.35);
}

.box > .content i.right-align {
  float: right;
  font-size: 1.5em;
}

.alarm-items .right-align {
  position: absolute;
  right: 10px;
}

.confidential {
  background-color: red;
}

.confidential > a > i {
  float: left;
}

.confidential > a > span {
  font-weight: bold;
  padding-left: 20px;
}

/*#endregion*/

/*#region Logo */

#appSwitcher > .logo {
  border-right: 1px solid #ffeb91;
  margin-right: 10px;
}

#appSwitcher > .logo:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#appSwitcher > .logo > .logo-container > img {
  width: 70%;
  vertical-align: middle;
}

/*#endregion*/

/*#region Switch apps panel */

.switch-apps-panel {
  display: none;
  z-index: 9999;
  position: fixed;
  top: 45px;
  left: 10px;
  background-color: white;
  border: 1px solid #b6b6b6;
  box-sizing: content-box;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.4);
  padding: 10px 10px 10px 10px;
}

.switch-apps-panel .border-arrow {
  border-bottom-color: #b6b6b6;
  top: -9px;
}

.switch-apps-panel .arrow {
  display: block;
  position: absolute;
  top: -8px;
  left: 6px;
  margin: 0;
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 0;
  padding: 0;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}

.panel-visible {
  display: flex;
}

.app-box {
  width: 110px;
  height: 110px;
  display: table-cell;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  border: 5px solid white;
}

.app-box:hover {
  text-decoration: none;
  padding: 2px;
  border: 3px solid white;
  cursor: pointer;
}

.app-box > .logo {
  display: block;
  text-align: center;
  padding: 15px;
}

.app-box > .label {
  color: white;
  display: block;
  text-align: center;
}

.box-merlin {
  background-color: #fdd835;
}

.box-transportboek {
  background-color: #26a69a;
}

.box-paris {
  background-color: #fcb359;
}

.box-platon {
  background-color: gray;
}

.box-commontte {
  background-color: #3d2770;
}

.tabConstatation li a {
  font-weight: normal;
  color: rgba(89, 89, 89, 0.7) !important;
}
/*#endregion*/

.errorMetering {
  background-color: #c50606;
  color: #f1f1f1;
  font-weight: bold;
}
