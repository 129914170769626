import { autoinject } from "aurelia-framework";
import { CustomLogger, ServiceBase, ViewModelBase } from "digiwall-lib";
import { Merlin } from "generated";
import * as Constants from '../../constants';
import { DialogController } from "aurelia-dialog";
import { Router } from 'aurelia-router';

@autoinject
export class EditRequestSupplierOffer extends ViewModelBase {
  ribbonHeaderText = '';
  ressourceName = '';
  public workQuotationVersionId: number;
  public projectId: number;
  public requestSupplierOfferId: number;
  public showAllRowSelected: boolean = false;
  public selectedLineCount: number = 0;

  public entity: Merlin.Web.Model.RequestSupplierOffer;
  public service: ServiceBase<Merlin.Web.Model.RequestSupplierOffer>;

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController) {
    super(router, logger);

  }

  public async activate(params: any) {
    this.workQuotationVersionId = params.param1.workQuotationVersionId;
    this.projectId = params.param1.projectId;
    this.requestSupplierOfferId = params.param1.requestSupplierOfferId;
    if (this.workQuotationVersionId != null) {
      this.service = new ServiceBase<Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer>(Constants.EntityTypeNames.WorkQuotationVersionRequestSupplierOffer);
    }
    else if (this.projectId != null) {
      this.service = new ServiceBase<Merlin.Web.Model.BuyingRequestSupplierOffer>(Constants.EntityTypeNames.BuyingRequestSupplierOffer);
    }
    this.entity = await this.service.getEntityById(this.requestSupplierOfferId);
  }

  showRowSelected() {
    this.showAllRowSelected = !this.showAllRowSelected;
  }

  async close() {
    await this.dialogController.close(false);
  }

  async validate() {
    await this.dialogController.close(true);
  }
}
