import { HttpClient } from 'aurelia-fetch-client';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, IMenuItems, ServiceBase, Various } from 'digiwall-lib';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { AddInvoice } from './add-invoice/add-invoice';

@autoinject
export class BankTransactionDetail extends EntityDetailViewModelBase<Merlin.Web.Model.BankTransaction> {
  public ressourceName: string = Constants.EntityTypeNames.BankTransaction;

  private paymentTagService: EnumerationTypeService;
  public refresh: boolean = false;
  public get documentTitle() {
    return `Paiement ${this.entity.id}`;
  }
  public get amountPanelTitle() {
    if (this.entity.amount >= 0) {
      return 'Reçu';
    }
    else {
      return 'Payé';
    }
  }
  // public invoices: Array<Merlin.Web.Model.Invoice>;
  @computedFrom("entity.amount")
  public get absoluteAmount() {
    return Math.abs(this.entity?.amount);
  }
  public statusMenu: IMenuItems[];

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.BankTransaction>(Constants.EntityTypeNames.BankTransaction));
    // this.paymentTagService = new EnumerationTypeService(Constants.EnumerationTypes.TagPayment);    
  }

  public async activate(params: any) {
    await super.activate(params);
    let id: number = params.param1;

    if (id == Various.NewId) {
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      await this.loadEntity(id);
    }
  }


  public async changeStatus() {
    let result = await this.httpClient.post(Constants.Application.PontoController.IgnoreTransactions,
      JSON.stringify([this.entity.id])
    )
    if (result.ok) {
      await this.loadEntity(this.entity.id);
    }
    else {
    }
  }

  async linkInvoice() {
    await this.box.showCustomDialog(AddInvoice, null, null, {
      model: {
        transactionId: this.entity.id
      },
      size: "xl"
    }).whenClosed(async (result) => {
      if (result.output) {
        //Todo refresh getEntity
        await this.loadEntity(this.entity.id);
      }
    });
  }


  public async unlinkInvoice(reconciliationId: number) {
    this.entity.reconciliations.forEach(r => {
      r.entityAspect.setDetached();
    });

    let result = await this.httpClient.delete(Constants.Application.ReconciliationController.UnlinkInvoice.format(reconciliationId.toString()))
    if (result.ok) {
      this.refresh = !this.refresh;
      await this.loadEntity(this.entity.id);
    }
  }

  private async loadEntity(id: number) {
    this.entity = await this.service.getEntityById(id, 'status', 'bankAccount', 'reconciliations'/*, 'tags.tagBankTransaction'*/, 'reconciliations.invoice.client');

    // if (this.entity.statusId == 550) {
    //   this.statusMenu = [];
    //   this.statusMenu.push(
    //     {
    //       "label": this.i18n.tr("informationmessage.ignorePaiment"),
    //       "handler": async () => {
    //         await this.box.showQuestion(this.i18n.tr("informationmessage.messageStatusChanged"), this.i18n.tr("informationmessage.ignoreMessage"),
    //           [
    //             {
    //               label: this.i18n.tr('general.yes'),
    //               fn: async (dialogBox) => {
    //                 await this.changeStatus();
    //                 dialogBox.controller.ok();
    //               }
    //             },
    //             {
    //               label: this.i18n.tr('general.no'),
    //               fn: (dialogBox) => {
    //                 dialogBox.controller.ok();
    //               }
    //             }
    //           ]);
    //       }
    //     }
    //   );
    // }
    // else if (this.entity.statusId == 551 && (this.authService.currentUser.roles.some(role => role == Constants.RoleId.SuperAdmin || role == Constants.RoleId.Accountant))) {
    //   this.statusMenu = [];
    //   this.statusMenu.push(
    //     {
    //       "label": this.i18n.tr("informationmessage.reconciliedPaiment"),
    //       "handler": async () => {
    //         await this.box.showQuestion(this.i18n.tr("informationmessage.messageStatusChanged"), this.i18n.tr("informationmessage.reconciledMessage"),
    //           [
    //             {
    //               label: this.i18n.tr('general.yes'),
    //               fn: async (dialogBox) => {
    //                 this.entity.statusId = 552;
    //                 await this.service.save();
    //                 this.statusMenu = null;
    //                 dialogBox.controller.ok();
    //               }
    //             },
    //             {
    //               label: this.i18n.tr('general.no'),
    //               fn: (dialogBox) => {
    //                 dialogBox.controller.ok();
    //               }
    //             }
    //           ]);
    //       }
    //     }
    //   );
    // }
    // else {
    this.statusMenu = null;
    // }
  }



  // public async showInvoicesHistory() {
  //   let person = this.entity.reconciliations.firstOrDefault<Reconciliation>()?.invoice.person;
  //   await this.dialogService.open({ viewModel: TransactionHistory, model: { person: person }, lock: false, rejectOnCancel: false });
  // }

  // public get canDisplayTransactionHistory(): boolean {
  //   return this.entity.reconciliations.firstOrDefault<Reconciliation>()?.invoice.person != null;
  // }
}
