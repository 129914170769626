import { Router } from 'aurelia-router';
import { autoinject, computedFrom, Container } from 'aurelia-framework';
import { IAnchorTab, IMenuGroup, ServiceBase } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { Merlin } from 'generated';
import * as Constants from '../../constants';
import { DialogService } from 'aurelia-dialog';
import { PreparationTemplate } from './preparation-template';
import * as toastr from 'toastr';
import { HttpClient } from 'aurelia-fetch-client';


@autoinject
export class PreparationSummary {
  projectId: any;
  menuItems: Array<IMenuGroup>;
  tabList: IAnchorTab[];

  private projectService: ServiceBase<Merlin.Web.Model.Project>
  private project: Merlin.Web.Model.Project

  constructor(public router: Router, public i18n: I18N, public httpClient: HttpClient) {
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  public async activate(params: any) {
    this.projectId = params.projectId;
    this.project = await this.projectService.getEntityById(this.projectId);
    this.setTabList()
    this.setMenuItems();
  }

  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: "tab1",
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      }
    );
  }
  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("workingsitepreparationtemplate.choosePreparationTemplate"),
            icon: "digi-task-line",
            handler: async () => {
              this.chooseWorkingTemplate();
            },
            hidden: this.project.workingSitePreparationTemplateName != null
          }
        ]
      }
    ];
  }

  private async chooseWorkingTemplate() {
    let dialogService = Container.instance.get(DialogService);
    await dialogService.open({
      viewModel: PreparationTemplate,
      lock: true,
      keyboard: true,
      rejectOnCancel: true,
    }).whenClosed(async x => {
      if (!x.wasCancelled) {
        let result = await this.httpClient.get(Constants.Application.ProjectController.ApplyTemplate.format(this.projectId, x.output));
        if (result.ok) {
          toastr.success(this.i18n.tr("workingsitepreparationtemplate.templateApplied"));
          this.project = await this.projectService.getEntityById(this.projectId);
          this.setMenuItems();
        }
        else {
          toastr.error(await result.text());
        }
      }
    })
  }

  @computedFrom("projectId")
  get ribbonHeaderText(): string {
    return this.i18n.tr("preparationindex.summary")
  }

}
