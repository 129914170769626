import { PriceOfferLineCategory } from './../../../constants';
import { Container } from "aurelia-framework";
import * as Constants from '../../../constants';
import { ICellRendererParams } from "ag-grid-enterprise";
import { ActionDialogBoxInputParameters, Box, DialogBoxViewModel, IMenuItems, KeyboardShortcut } from "digiwall-lib";
import { I18N } from "aurelia-i18n";
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';

export class MenuLineCategory {
  public static getMenu(params: ICellRendererParams, dataLineApiService: IMeterigSwitchType, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid): IMenuItems {
    let i18n = Container.instance.get(I18N);
    let menuItems = {
      id: Constants.CommonMeterginDataLineMenuItems.LINE_CATEGORY,
      label: i18n.tr("versionmetering.lineCategory"),
      icon: "digi-price-tag-3-line",
      items: [
        {
          group: i18n.tr("versionmetering.lineCategory"),
          items: MenuLineCategory.getLineCategories(params, i18n, dataLineApiService, versionId, priceOfferLinesGrid)
        }
      ]
    } as IMenuItems;
    return menuItems;
  }

  public static getLineCategories(params: ICellRendererParams, i18n: I18N, dataLineApiService: IMeterigSwitchType, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid): Array<IMenuItems> {
    return [
      {
        label: i18n.tr("versionmetering.categoryPost"),
        icon: "digi-folder-add-line",
        disabled: () => params.node.parent.data?.priceOfferLineCategoryId == PriceOfferLineCategory.Data || params.node.data.hasChildren || params.node.data.isDetailedLine || params.node.data.isSubItem || params.node.data.priceOfferLineCategoryId == PriceOfferLineCategory.Data,
        handler: async () => {
          await MenuLineCategory.switchToPost(params.node.data, dataLineApiService, versionId);
          priceOfferLinesGrid.refreshVisibleNodes([parseInt(params.node.data.id)]);
        }
      },
      {
        label: i18n.tr("versionmetering.categoryChapter"),
        icon: "digi-folder-add-line",
        disabled: () => params.node.parent.data?.priceOfferLineCategoryId == PriceOfferLineCategory.Data || params.node.data.hasChildren || params.node.data.isDetailedLine || params.node.data.isSubItem || params.node.data.priceOfferLineCategoryId == PriceOfferLineCategory.Chapter,
        handler: async () => {
          await MenuLineCategory.switchToChapter(params.node.data, dataLineApiService, versionId);
          priceOfferLinesGrid.refreshVisibleNodes([parseInt(params.node.data.id)]);
        }
      }, {
        label: i18n.tr("versionmetering.categoryComment"),
        icon: "digi-folder-add-line",
        disabled: () => params.node.parent.data?.priceOfferLineCategoryId == PriceOfferLineCategory.Data || params.node.data.hasChildren || params.node.data.isDetailedLine || params.node.data.isSubItem || params.node.data.priceOfferLineCategoryId == PriceOfferLineCategory.Comment,
        handler: async () => {
          await MenuLineCategory.switchToComment(params.node.data, dataLineApiService, versionId);
          priceOfferLinesGrid.refreshVisibleNodes([parseInt(params.node.data.id)]);
        }
      }
    ];
  }
  static async switchToComment(data: any, dataLineApiService: IMeterigSwitchType, versionId: string) {
    if (await this.confirmePost(data)) {
      await dataLineApiService.switchToComment(parseInt(versionId), data.id);
    }

  }
  static async switchToChapter(data: any, dataLineApiService: IMeterigSwitchType, versionId: string) {
    if (await this.confirmePost(data)) {
      await dataLineApiService.switchToChapter(parseInt(versionId), data.id);
    }
  }
  static async switchToPost(data: any, dataLineApiService: IMeterigSwitchType, versionId: string) {
    await dataLineApiService.switchToPost(parseInt(versionId), data.id);
  }

  private static async confirmePost(data) {
    if (data.proposedQuantity != null || data.unitId != null || data.buyingUnitPrice) {
      let box = Container.instance.get(Box);
      let i18n = Container.instance.get(I18N);
      let confirm = false;
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: i18n.tr("general.ok", { ns: "common" }),
        title: i18n.tr("general.ok", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(true);
        },
        keyboardShortcut: KeyboardShortcut.Enter
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: i18n.tr("general.cancel", { ns: "common" }),
        title: i18n.tr("general.cancel", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(false);
        },
        keyboardShortcut: KeyboardShortcut.Escape
      };
      await box.showQuestion(i18n.tr("versionmetering.changePost"), i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes])
        .whenClosed(async (result) => {
          if (!result.wasCancelled && result.output) {
            confirm = result.output;
          }
        });
      return confirm;
    }
    else {
      return true
    }
  }
}

export interface IMeterigSwitchType {
  switchToPost(versionId: number, lineId: number): Promise<void>;
  switchToChapter(versionId: number, lineId: number): Promise<void>;
  switchToComment(versionId: number, lineId: number): Promise<void>;
}
