import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { Box } from "digiwall-lib";

@autoinject()
export abstract class BaseApiService {
  /**
   *
   */
  constructor(public i18n: I18N, public box: Box) {}

  protected async manageError(query: Response | string): Promise<void> {
    if (query == null) return;
    let error = await this.getErrorMessage(query);
    this.box.showWarning(this.i18n.tr(error), this.i18n.tr('general.errorTitle'));
  }

  protected async getErrorMessage(query: Response | string): Promise<string> {
    if (typeof query == 'string') {
      return query;
    } else {
      let value = await query.json();
      return value?.message ?? value?.Message ?? query.statusText;
    }
  }
}
