import { ViewModelBase } from 'digiwall-lib';
import { autoinject } from "aurelia-framework";

@autoinject
export class Metadata extends ViewModelBase {
  public get ribbonHeaderText(): string {
    throw new Error('Method not implemented.');
  }
  public get ressourceName(): string {
    throw new Error('Method not implemented.');
  }
}
