import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class UnitList extends ListViewModelBase<Merlin.Web.Model.Unit> {
  constructor(router: Router, logger: CustomLogger) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit));
    this.service.gridDataSource.expands = ['unitType'];
  }

  public ressourceName: string = Constants.EntityTypeNames.Unit;
  public buttonLabel: string = this.i18n.tr("unit.addUnit");
  public pathDetail: string;


  public getDetailsUrl(_self, entity: Merlin.Web.Model.Unit) {
    if (entity?.id) {
      return this.pathDetail + entity.id;
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = this.router.baseUrl + '/units/';
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsFR"),
        field: "unitName.lang1",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsNL"),
        field: "unitName.lang2",
        hide: true,
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsEN"),
        field: "unitName.lang3",
        hide: true,
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("unit.unitTypeId"),
        field: "unitType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.UnitType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.UnitType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
} 
