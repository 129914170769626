import { RouterConfiguration, Router } from 'aurelia-router';
import { ServiceBase, UIInternal } from 'digiwall-lib';
import * as Constants from '../../constants';
import { Merlin } from 'generated';
import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { IndexHelper } from 'resources/elements/index-helper';

@autoinject
export class RealizationIndex {
  router: Router;
  entityId: number = null;
  priceOfferId: number;
  projectService: ServiceBase<Merlin.Web.Model.Project>;
  refreshMenu = true;

  get baseUrl() {
    return this.router.parent.currentInstruction?.fragment;
  };

  constructor(private i18n: I18N) {
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }
  attached() {
    UIInternal.subscribe('router:navigation:complete', () => {
      IndexHelper.setFocusRoute(this.router);
    });
  }

  async activate(params) {
    this.entityId = params.projectId;
    if (this.entityId > 0) {
      let ent = await this.projectService.getEntityById(this.entityId);
      this._ribbonHeaderText = ent.projectName + ' (' + this.entityId + ')';
    } else {
      this._ribbonHeaderText = this.i18n.tr('project.newProject');
    }

    this.router.routes.forEach(r => r.disabled = this.entityId < 0);
  }

  private _ribbonHeaderText: string;

  @computedFrom("_ribbonHeaderText")
  public get ribbonHeaderText(): string {
    return this._ribbonHeaderText;
  }

  get currentRoute() {
    return this.router?.currentInstruction?.config.route;
  }

  configureRouter(config: RouterConfiguration, router: Router, params, routeConfig, currentInstruction): void {
    this.router = router;

    config.map([
      {
        route: "",
        redirect: 'summary'
      },
      {
        route: "summary",
        moduleId: "projects/realization/realization-summary",
        name: "summary",
        title: this.i18n.tr('realizationindex.summary'),
        nav: true
      },
      {
        route: "metering",
        moduleId: "projects/realization/realization-metering",
        name: "metering",
        title: this.i18n.tr('realizationindex.metering'),
        nav: true
      },
      {
        route: "progress-statement",
        moduleId: "projects/realization/progress-statements/progress-statement",
        name: "progress-statement",
        title: this.i18n.tr('realizationindex.progressStatementInProgress'),
        nav: true
      },
      {
        route: "progress-statement-subcontractor",
        moduleId: "projects/realization/progress-statements/progress-statement-subcontractor",
        name: "progress-statement-subcontractor",
        title: this.i18n.tr('realizationindex.subContractorProgressStatementInProgress'),
        nav: true
      },
      {
        route: "progress-statement/all",
        moduleId: "projects/realization/progress-statements/progess-statement-list",
        name: "progress-statement/all",
        title: this.i18n.tr('progressstatement.progressStatementList'),
        nav: true
      },
      {
        route: "progress-statement/:progressStatementId",
        moduleId: "projects/realization/progress-statements/progress-statement-detail",
        name: "progress-statement-detail",
        title: this.i18n.tr('realizationindex.progressStatementDetail'),
        nav: false,

        focusRoute: "progress-statement/all"
      },
      {
        route: "progress-statement-subcontractor/all",
        moduleId: "projects/realization/progress-statements/progess-statement-subcontractor-list",
        name: "progress-statement-subcontractor/all",
        title: this.i18n.tr('progressstatement.progressStatementSubContractorList'),
        nav: true
      },
      {
        route: "progress-statement-subcontractor/:progressStatementId",
        moduleId: "projects/realization/progress-statements/progress-statement-detail",
        name: "progress-statement-subcontractor-detail",
        title: this.i18n.tr('realizationindex.progressStatementDetail'),
        nav: false,

        focusRoute: "progress-statement-subcontractor/all"
      },
      {
        route: "client-additional-work/all",
        moduleId: "projects/realization/additional-work/additional-work-list",
        name: "client-additional-work",
        title: this.i18n.tr('realizationindex.clientAdditionalWorks'),
        nav: true,
      },
      {
        route: "document",
        moduleId: "documents/document-list",
        name: "document",
        title: this.i18n.tr('realizationindex.documents'),
        nav: true
      },
      {
        route: "additional-work/:additionalWorkId",
        moduleId: "projects/realization/additional-work/additional-work",
        name: "additional-work-detail",
        title: this.i18n.tr('realizationindex.clientAdditionalWork'),
        nav: false,
        focusRoute: "client-additional-work"
      }
    ]);
  }

  public goToFirstDevis() {
    this.router.navigate(`quote`);
  }

}
