
import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, FieldType, Various, IMenuGroup, IMenuItem, IMenuItems } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../../generated";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import * as Constants from '../../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { PriceOfferVersionApiService } from 'services/price-offer-version-api-service';
import { ProjectApiService } from 'services/project-api-service';

@autoinject
export class PriceOfferVersionList extends ListViewModelBase<Merlin.Web.Model.PriceOfferVersion> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private priceOfferVersionApiService: PriceOfferVersionApiService, private projectApiService: ProjectApiService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.PriceOfferVersion>(Constants.EntityTypeNames.PriceOfferVersion));
    this.service.gridDataSource.expands = ['priceOfferStatus', 'offerMail', 'vatLevel', 'priceOfferMobilizationDelay', 'priceOfferPaymentDelay', 'priceOfferDeposit', 'priceOfferPriceRevisionTechnic'];
  }

  public ressourceName: string = Constants.EntityTypeNames.Project;
  public buttonLabel: string = this.i18n.tr("priceofferversion.addNewPriceOfferVersion");
  public workQuotationVersionId: number;
  public disabledButton: boolean = false;

  private nbDecimalForPriceDisplay: number


  public getDetailsUrl(_self, entity: Merlin.Web.Model.PriceOfferVersion) {
    if (entity?.id) {
      this.goToDetail(entity.id);
    }
    return null;
  }
  async activate(params: any): Promise<void> {
    this.workQuotationVersionId = params.workQuotationVersionId;
    this.service.gridDataSource.queryParameters = { workQuotationVersionId: this.workQuotationVersionId };
    await super.activate(params);


    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;

    let wqService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersion>(Constants.EntityTypeNames.WorkQuotationVersion);
    let wq = await wqService.getEntityById(this.workQuotationVersionId);
    this.disabledButton = wq.needToBeValidated;
  }

  goToDetail(id: number | null) {
    if (id == null) {
      id = -100;
    }
    let url = this.router.generate("price-offer-version-detail", { priceOfferVersionId: id });
    if (this.router?.currentInstruction?.queryString?.length > 0) {
      url += '?' + this.router.currentInstruction.queryString;
    }
    this.router.navigate(url);
  }

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public getGridMenuItems(params: ICellRendererParams): Array<IMenuItems> {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: (params as any).i18n.tr("menu.open"),
            icon: "digi-open",
            handler: () => {
              (params as any).goToDetail(params.data.id);
            }
          },
          {
            label: (params as any).i18n.tr("general.duplicate"),
            icon: "digi-duplicate",
            handler: () => {
              (<any>params).duplicateOffer(params?.data?.id)
            }
          }
        ]
      },
      {
        label: (params as any).i18n.tr("menu.delete"),
        icon: "digi-delete-bin-line",
        handler: async () => {
          let result = await (params as any).service.deleteEntities([params.data], true);
          if (result) {
            (params as any).gridOptions.api.forEachNode((rowNode) => {
              if (rowNode.data != null)
                if (rowNode.data.isSelected == true)
                  rowNode.data.isSelected = false;
            });
            (params as any).gridOptions.api.refreshInfiniteCache();
          }
        },
        disabled: () => params.data.priceOfferStatusId != Constants.PriceOfferStatusId.Open
      }];
  }

  private async duplicateOffer(id: number) {
    if (id == null) return;
    let newId = await this.priceOfferVersionApiService.duplicateVersion(id);
    if (newId != null && typeof newId == 'number')
      this.goToDetail(newId);
  }

  public getDataGridColumns() {

    let vatService: ServiceBase<Merlin.Web.Model.VATLevel> = new ServiceBase<Merlin.Web.Model.VATLevel>(Constants.EntityTypeNames.VATLevel);
    let ctxMenu = new GridHelperMerlin().contextMenuColdDef(this);
    ctxMenu[0].cellRendererParams.menuItems = this.getGridMenuItems;
    ctxMenu[0].cellRendererParams.duplicateOffer = (id: number) => this.duplicateOffer(id);
    ctxMenu[0].cellRendererParams.goToDetail = (id: number) => this.goToDetail(id);

    let defs: ColDef[] = [
      ...ctxMenu,
      {
        headerName: this.i18n.tr("priceofferversion.priceOfferVersionID"),
        field: "priceOfferVersionID",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("priceofferversion.priceOfferStatusId"),
        field: "priceOfferStatus.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.PriceOfferStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.PriceOfferStatus,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("priceofferversion.sentToClientDate"),
        field: "offerMail.date",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("priceofferversion.acceptationDate"),
        field: "acceptationDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("priceofferversion.defaultMarginCoefficient"),
        field: "defaultMarginCoefficient",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("priceofferversion.vatLevelId"),
        field: "vatLevel.code",
        type: FieldType.OneToMany,
        filterParams: {
          service: vatService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: vatService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("priceofferversion.priceOfferMobilizationDelayId"),
        field: "priceOfferMobilizationDelay.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.PriceOfferMobilizationDelay,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.PriceOfferMobilizationDelay,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("priceofferversion.priceOfferPaymentDelayId"),
        field: "priceOfferPaymentDelay.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.PriceOfferPaymentDelay,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.PriceOfferPaymentDelay,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("priceofferversion.priceOfferDepositId"),
        field: "priceOfferDeposit.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.PriceOfferDeposit,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.PriceOfferDeposit,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },

      {
        headerName: this.i18n.tr("priceofferversion.priceOfferPriceRevisionTechnicId"),
        field: "priceOfferPriceRevisionTechnic.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },

      {
        headerName: this.i18n.tr("priceofferversion.sumTotalBuyingPrice"),
        field: "sumTotalBuyingPrice",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("priceofferversion.sumTotalSellingPrice"),
        field: "sumTotalSellingPrice",
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
}

