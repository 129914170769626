import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, User, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class CheckPointTemplateList extends ListViewModelBase<Merlin.Web.Model.CheckPointTemplate> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.CheckPointTemplate>(Constants.EntityTypeNames.CheckPointTemplate));
    this.service.gridDataSource.expands = ['project', 'activityCode', 'projectStage'];
  }

  public ressourceName: string = Constants.EntityTypeNames.CheckPointTemplate;

  public buttonLabel: string = this.i18n.tr("checkpointtemplate.addCheckpointTemplate");
  public pathDetail: string;

  public getDetailsUrl(self, entity: Merlin.Web.Model.CheckPointTemplate) {
    if (entity?.id) {
      return this.router.baseUrl + this.pathDetail + entity.id;
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = '/checkpoint-templates/';
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("checkpointtemplate.projectId"),
        field: "project.projectName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("checkpointtemplate.checkpointName"),
        field: "checkpointName._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("checkpointtemplate.descriptionMultiline"),
        field: "descriptionMultiline._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("checkpointtemplate.deadlineAfterNbDaysOfStageStart"),
        field: "deadlineAfterNbDaysOfStageStart",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("checkpointtemplate.activityCodeId"),
        field: "activityCode.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ActivityCode,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ActivityCode,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("checkpointtemplate.projectStageId"),
        field: "projectStage.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.CheckpointProjectStage,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.CheckpointProjectStage,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
} 
