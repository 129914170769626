import { ServiceBase, BaseEntityWithId } from 'digiwall-lib';
import { singleton } from 'aurelia-framework';
import { EnumerationTypeService } from 'digiwall-lib';
import * as breeze from 'breeze-client';

@singleton(false)
export class FiltersUtils {
  public async forceEnumFilterValues(filterParams: any, filterName: string, idsSelected: Array<any>, enumCategory: number) {
    let activities = await new EnumerationTypeService(enumCategory).getEntities(null, null, { category: enumCategory });
    if (filterParams[filterName] == null) {
      filterParams[filterName] = {
        filter: activities.map(x => {
          return {
            id: x.id,
            name: x.denomination._translation,
            selected: idsSelected.map(idSelected => idSelected.toString()).includes(x.id.toString()),
            hide: false
          }
        }),
        filterType: "enumeration"
      }
    }
    else {
      filterParams[filterName].filter.forEach(x => {
        x.selected = idsSelected.map(idSelected => idSelected.toString()).includes(x.id.toString())
      })
    }
  }

  public async forceRelationFilterValues(filterParams: any, filterName: string, idsSelected: Array<any>, entityName: string) {
    let entities = await new ServiceBase(entityName).getAllEntities();
    if (filterParams[filterName] == null) {
      filterParams[filterName] = {
        filter: entities.map(x => {
          return {
            id: x["id"],
            name: "",
            selected: idsSelected.map(idSelected => idSelected.toString()).includes(x["id"].toString()),
            hide: false
          }
        }),
        filterType: "oneToMany"
      }
    }
    else {
      filterParams[filterName].filter.forEach(x => {
        x.selected = idsSelected.map(idSelected => idSelected.toString()).includes(x.id.toString())
      })
    }
  }
}
